import "./style/QuizEdit.css";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useActionParam } from "../../../../../hooks";
import { H2, H4 } from "../../../../../components/text";
import { Icon, ButtonIcon } from "../../../../../components/buttons";
import { ToolTip, BoxFlex } from "../../../../../components/boxes";
import {
  changeLayout,
  openSnackbar,
  changeModal,
} from "../../../../../store/actions";
import { QuestionForm } from "../../../../../components/form";
import { LoadCircle } from "../../../../../components/animation";
import {
  getLessonEducator,
  deleteQuestion,
} from "../../../../../services/action/LessonAction";
import { validateStatus, validateArray } from "../../../../../utils/Validation";
import { transformTextJson } from "../../../../../utils/ProcessData";
const QuizEdit = ({ match, changeLayout, openSnackbar, changeModal }) => {
  const { qid } = match.params;
  const [newQuiz, setnewQuiz] = useState(false);
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getLessonEducator,
    param: qid,
  });
  useEffect(() => {
    changeLayout("FullScreen");
  }, [changeLayout]);
  function deleteQuiz(qid, questionId) {
    deleteQuestion(qid, questionId).then((response) => {
      if (validateStatus(response.status)) {
        openSnackbar(`Se eliminó la pregunta`, true, true);
        reload();
      } else {
        openSnackbar(`Se pudo eliminar la pregunta`, true, true);
      }
    });
  }
  function GenerateInquiries({ inquiries }) {
    const { responses, rightAnswer, type } = inquiries;
    const answers = responses ? transformTextJson(responses) : [];
    function ValidateRightAnswer(id) {
      let boolean = false;
      if (type === "multipleChoice") {
        const answerList = rightAnswer.split(";");
        answerList.forEach((element) => {
          if (parseInt(element) === id) {
            boolean = true;
          }
        });
      } else {
        boolean = parseInt(rightAnswer) === id;
      }
      return boolean;
    }
    return (
      <div className="QuizEdit__Inquiries">
        {validateArray(answers)
          ? answers.map((answer) =>
              answer.FileId ? (
                <div
                  className={`QuizEdit__Inquiry ${
                    ValidateRightAnswer(answer.id) ? "corrent" : ""
                  }`}
                  key={answer.id}
                >
                  <center>
                    <img
                      id="image"
                      src={answer.response}
                      alt="imagen de respuesta"
                    />
                  </center>
                </div>
              ) : (
                <h5
                  className={`QuizEdit__Inquiry ${
                    ValidateRightAnswer(answer.id) ? "corrent" : ""
                  }`}
                  key={answer.id}
                >
                  {answer.response}
                </h5>
              )
            )
          : null}
      </div>
    );
  }
  function GenerateRelation({ inquiries }) {
    const { responses, inquiry } = inquiries;
    const answers = transformTextJson(responses);
    const concepts = transformTextJson(inquiry);
    return (
      <div className="QuizEdit__Inquiries">
        {validateArray(answers)
          ? answers.map((answer, index) => (
              <div className="QuizEdit__Relation" key={answer.id}>
                {concepts[index].FileId ? (
                  <div className="QuizEdit__Inquiry">
                    <center>
                      <img
                        id="image"
                        src={concepts[index].concept}
                        alt="imagen de concepto"
                      />
                    </center>
                  </div>
                ) : (
                  <h5 className="QuizEdit__Inquiry">{`${answer.id}. ${concepts[index].concept}`}</h5>
                )}
                {answer.FileId ? (
                  <div className="QuizEdit__Inquiry">
                    <center>
                      <img
                        id="image"
                        src={answer.response}
                        alt="imagen de respuesta"
                      />
                    </center>
                  </div>
                ) : (
                  <h5 className="QuizEdit__Inquiry">{`${answer.id}. ${answer.response}`}</h5>
                )}
              </div>
            ))
          : null}
      </div>
    );
  }
  function GenerateQuestion({ inquiry, quizId }) {
    const [form, setform] = useState(true);
    if (form) {
      return (
        <div className="QuizEdit__question">
          <BoxFlex justify="end">
            <H4 border="1px solid gray">
              {
                {
                  singleChoice: "Selección unica",
                  multipleChoice: "Selección multiple",
                  conceptRelation: "Relación de concepto",
                  openQuestion: "Pregunta abierta",
                }[inquiry.type || "singleChoice"]
              }
            </H4>
          </BoxFlex>
          <div className="QuizEdit__header">
            <H4 innerHTML>
              {inquiry.type === "conceptRelation"
                ? "Relación de concepto"
                : inquiry.inquiry}
            </H4>
            <ToolTip
              interactive={true}
              trigger="click"
              placement="right"
              content={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <ButtonIcon
                    content="Editar pregunta"
                    onClick={() => setform(!form)}
                    icon="edit"
                  />
                  <ButtonIcon
                    content="Eliminar pregunta"
                    onClick={() =>
                      changeModal(
                        true,
                        "Estás seguro que quieres eliminar esta pregunta",
                        "",
                        "Eliminar",
                        () => deleteQuiz(quizId, inquiry.id)
                      )
                    }
                    icon="trash"
                  />
                </div>
              }
            >
              <i className="fas fa-ellipsis-h QuizEdit__icon"></i>
            </ToolTip>
          </div>
          {inquiry.url && <img src={inquiry.url} alt="imagen de la pregunta" />}
          {inquiry.type === "conceptRelation" ? (
            <GenerateRelation inquiries={inquiry} />
          ) : inquiry.type !== "openQuestion" ? (
            <GenerateInquiries inquiries={inquiry} />
          ) : null}
        </div>
      );
    } else {
      let aux = [];
      const answerList =
        inquiry.type !== "openQuestion"
          ? inquiry.rightAnswer
            ? inquiry.rightAnswer.split(";")
            : ["1"]
          : [];
      answerList.forEach((element) => {
        aux.push(parseInt(element) - 1);
      });

      return (
        <QuestionForm
          changeModal={changeModal}
          openSnackbar={openSnackbar}
          reload={reload}
          quizId={quizId}
          handleCancel={() => setform(!form)}
          lessonType={dataRequest.lessonType}
          inquiryEdit={{
            ...inquiry,
            rightAnswer: aux,
            concepts:
              inquiry.type === "conceptRelation" ? inquiry.inquiry : null,
            inquiry:
              inquiry.type === "conceptRelation" ? null : inquiry.inquiry,
          }}
        />
      );
    }
  }
  function GenerateQuestions({ quizToList }) {
    return (
      <div className="QuizEdit__questions">
        {validateArray(quizToList?.questionForEducatorToListDtos)
          ? quizToList.questionForEducatorToListDtos.map((inquiry) => (
              <GenerateQuestion
                key={inquiry.id}
                quizId={quizToList.id}
                inquiry={inquiry}
              />
            ))
          : null}
      </div>
    );
  }
  return (
    <div className="QuizEdit">
      <H2>Editar examen</H2>
      {validateStatus(statusRequest) ? (
        <GenerateQuestions quizToList={dataRequest.quizForEducatorDto} />
      ) : (
        <LoadCircle />
      )}
      {newQuiz && (
        <QuestionForm
          changeModal={changeModal}
          openSnackbar={openSnackbar}
          reload={reload}
          quizId={dataRequest ? dataRequest.quizForEducatorDto.id : null}
          lessonType={dataRequest.lessonType}
          handleCancel={() => setnewQuiz(!newQuiz)}
        />
      )}
      <br />
      <Icon
        handleModal={() => setnewQuiz(!newQuiz)}
        ico={newQuiz ? "times" : "plus"}
      >
        {newQuiz ? "Cancelar" : "Añadir pregunta"}
      </Icon>
      <br />
      <br />
    </div>
  );
};

export default connect(null, { changeLayout, openSnackbar, changeModal })(
  QuizEdit
);
