import { CircleBar, LineBar } from '../../../components/progress'
import { dataLock, convertD } from '../../../utils/ConvertDate'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { ButtonNavigation } from '../../../components/buttons'
import { InformationTeacher } from '../../../components/card'
import { getAction } from '../../../services/action/ActionAuthorization'
import { useState } from 'react'
import { TemplateAgenda } from '../../../components/calendar'
import { useActionParam } from '../../../hooks'
import { H3 } from '../../../components/text'
import { orderDates } from '../../../utils/ConvertDate'
import { LoadCircle } from '../../../components/animation'
import { H2 } from '../../../components/text'
import './ModulesAside.css'
const ModulesAside = (props) => {
  const { courseModule, pid } = props
  const [btns, setbtns] = useState({
    btn: [
      { id: 1, name: 'Eventos importantes' },
      { id: 2, name: 'Docentes' },
    ],
    activate: {
      id: 1,
    },
  })
  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }))
  }
  function GeneratingTeachers() {
    const { dataRequest, statusRequest } = useActionParam({
      action: getAction,
      param: `program/${pid}/teachers`,
    })
    return (
      <>
        {validateStatus(statusRequest) ? (
          validateArray(dataRequest) ? (
            dataRequest.map((t, i) => (
              <InformationTeacher key={i} teacher={t} />
            ))
          ) : (
            <H3>Aún no hay docentes asignados</H3>
          )
        ) : (
          <LoadCircle />
        )}
      </>
    )
  }
  function GeneratingImportantDates({ cid }) {
    const { dataRequest, statusRequest } = useActionParam({
      action: getAction,
      param: `/course/${cid}/ImportantDates`,
    })
    return (
      <>
        {validateStatus(statusRequest) ? (
          validateArray(dataRequest) ? (
            <TemplateAgenda calendarDates={orderDates(dataRequest)} />
          ) : null
        ) : (
          <LoadCircle />
        )}
      </>
    )
  }
  return (
    <div className="ModulesAside">
      <H2 textAlign="center">Publicación de módulos</H2>
      {validateArray(courseModule) ? (
        <div className="CourseModule__unitBar">
          <div className="CourseModule__progressCircles">
            {courseModule.map(function (l, index) {
              let p = 40 / 100
              let c = Math.round(p * courseModule.length)
              return (
                <div key={l.id} className="CourseModule__progDate">
                  <CircleBar
                    content={l.name}
                    status={c >= index + 1 ? true : false}
                    order={index + 1}
                    lock={dataLock(l.startDate)}
                    date={convertD(l.startDate, 'DD/MM')}
                  />
                </div>
              )
            })}
          </div>
          <LineBar border={false} progressline={40} />
        </div>
      ) : (
        <h3>Aún no hay módulos en el curso</h3>
      )}
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {btns.activate ? (
        <>
          {btns.activate.id === 1 && (
            <>
              {validateArray(courseModule) ? (
                courseModule.map((l) => (
                  <GeneratingImportantDates key={l.id} cid={l.id} />
                ))
              ) : (
                <H3>No tienes actividades pendientes</H3>
              )}
            </>
          )}
          {btns.activate.id === 2 && <GeneratingTeachers />}
        </>
      ) : null}
    </div>
  )
}

export default ModulesAside
