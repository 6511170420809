import { useState } from 'react'
import { H2, H4 } from '../../../components/text'
import { RenderModal } from '../../../components/modal'
import { TableOptions } from '../../../components/table'
import { GlobalForm } from '../../../components/form'
import { postAction } from '../../../services/action/ActionAuthorization'
import { validateStatus } from '../../../utils/Validation'
const FormAddUser = (props) => {
  const { openSnackbar, changeModal, reload, institution } = props
  const [contact, setcontact] = useState({
    modal: false,
    user: null,
  })
  const [load, setload] = useState(true)
  function handleSelect(item) {
    setcontact({
      modal: true,
      user: item,
    })
  }
  async function onSubmit(values) {
    try {
      setload(false)
      await postAction(`/contact/contactInstitution`, {
        InstitutionId: institution.id,
        ContactId: contact.user.contactId,
        Position: values.Position,
        isMain: values.isMain,
      }).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          setcontact({
            modal: false,
            user: null,
          })
          reload()
          changeModal(false, '', '', '', null)
          openSnackbar('Se adiciono al contacto', true, true)
        } else {
          openSnackbar('No se pudo completar', true, false)
        }
      })
    } catch (e) {
      setload(false)
    }
  }
  return (
    <>
      <H2 textAlign="center" margin="0">
        Elige al contacto que deseas adicionar
      </H2>
      <br />
      <div style={{ height: '450px', overflowY: 'scroll' }}>
        <TableOptions
          param={`/contact/${window.localStorage.getItem('id')}/contacts`}
          choice={true}
          header={[
            { key: 'name', name: 'Nombres' },
            { key: 'lastname', name: 'Apellidos' },
          ]}
          handleInfo={handleSelect}
        />
      </div>
      {contact.modal && (
        <RenderModal
          onClose={() => {
            setcontact({
              modal: false,
              user: null,
            })
          }}
          closeOutside
          width="400px"
        >
          <H4 textAlign="center">
            Estás seguro de que quieres adicionar como contacto a:
          </H4>
          <strong>
            <H4 fontSize="2rem" textAlign="center">
              {contact.user.name} {contact.user.lastname}
            </H4>
          </strong>
          <GlobalForm
            InputsJson={[
              {
                name: 'Position',
                initial: '',
                validate: null,
                type: 'text',
                label: 'Cargo en la institucion',
              },
              {
                name: 'isMain',
                initial: false,
                validate: null,
                type: 'checkbox',
                label: 'Contacto principal',
              },
            ]}
            textBtn="Adicionar"
            title=""
            data={{}}
            load={load}
            onSubmit={onSubmit}
            onCancel={() =>
              setcontact({
                modal: false,
                user: null,
              })
            }
          />
        </RenderModal>
      )}
    </>
  )
}

export default FormAddUser
