import { H2, H3 } from '../../../components/text'
import {
  ActionButton,
  DefaultBtn,
  RenderButton,
} from '../../../components/buttons'
import { FormDefault, GlobalForm } from '../../../components/form'
import {
  inputAddSchedule,
  inputAddVirtualClass,
} from '../../../utils/FormParameters'
import { convertD } from '../../../utils/ConvertDate'
import { useAxiosAuth } from '../../../hooks'
import { validateArray, validateStatus } from '../../../utils/Validation'
import TableMain from '../../../components/table/TableMain'
import {
  deleteAction,
  postAction,
} from '../../../services/action/ActionAuthorization'
import { capitalizeJson } from '../../../utils/ProcessData'
import { TableDefault } from '../../../components/table'
import { Loading } from '../../../components/animation'
import { BoxFlex } from '../../../components/boxes'
import DataSubtitle from '../../../components/dataTitle/DataSubtitle'
import ScheduleView from './ScheduleView'
import { useState } from 'react'
import ScheduleEdit from './scheduleContainer/ScheduleEdit'
import AttendanceContainer from './scheduleContainer/AttendanceContainer'
const FormSchedule = (props) => {
  const {
    startDate,
    finishDate,
    changeInfo,
    changeModal,
    openSnackbar,
    match: { params },
  } = props

  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `courseSchedule/course/${params.pid}`,
  })

  const {
    response: virtualClassResponse,
    reload: reloadVirtualClass,
  } = useAxiosAuth({
    method: 'GET',
    url: `virtualClassRoom`,
  })
  function realoadAll() {
    reload()
  }
  function handleAdd() {
    changeModal(
      true,
      '',
      <FormAddSchedule
        {...props}
        changeModal={changeModal}
        handleReload={realoadAll}
        onClose={() => changeModal(false, '')}
      />,
      null,
    )
  }
  function handleReloadVirtualClass() {
    reloadVirtualClass()
    changeModal(false, null)
  }

  function handleSheduleEdit(item, reloadTable) {
    changeModal(
      true,
      'Editar horario',
      <ScheduleEdit
        openSnackbar={openSnackbar}
        item={item}
        changeModal={changeModal}
        realoadAll={reloadTable}
      />,
      null,
    )
  }
  function TableVirtualClass() {
    const buttonAddVirtualClass = (
      <DefaultBtn
        onClick={() =>
          changeModal(
            true,
            '',
            <FormDefault
              inputs={inputAddVirtualClass}
              onCancel={() => changeModal(true, '', <TableVirtualClass />)}
              method="POST"
              url={`/virtualClassRoom`}
              openSnackbar={openSnackbar}
              title="Agregar aula virtual"
              handleReload={handleReloadVirtualClass}
            />,
            null,
            null,
          )
        }
      >
        Adicionar Aula Virtual
        <i style={{ fontSize: '1.4rem' }} className="fas fa-plus"></i>
      </DefaultBtn>
    )

    return (
      <>
        <H3 textAlign="center" fontSize="2.3rem">
          Salas virtuales
        </H3>
        <TableDefault
          buttonAdd={buttonAddVirtualClass}
          header={headerVirtualClass}
          main={virtualClassResponse}
        />
      </>
    )
  }
  function handleVirtualClassEdit(item) {
    changeModal(
      true,
      '',
      <FormDefault
        inputs={inputAddVirtualClass}
        onCancel={() => changeModal(true, <TableVirtualClass />)}
        method="PUT"
        url={`virtualClassRoom/${item.id}/update`}
        openSnackbar={openSnackbar}
        title="Editar clase virtual"
        handleReload={handleReloadVirtualClass}
        data={capitalizeJson(item)}
      />,
      null,
    )
  }

  async function onDeleteShedule(courseScheduleId, reloadTable) {
    try {
      await deleteAction(`courseSchedule/${courseScheduleId}/delete`).then(
        (response) => {
          if (validateStatus(response.status)) {
            changeModal(false, null)
            openSnackbar('Se elimino con exito', true, true)
            reloadTable()
          } else {
            openSnackbar('No se pudo eliminar', true, false)
          }
        },
      )
    } catch (e) {
      console.log(e)
    }
  }
  async function onDeleteVirtualClass(virtualClassId) {
    try {
      await deleteAction(`virtualClassRoom/${virtualClassId}/delete`).then(
        (response) => {
          if (validateStatus(response.status)) {
            handleReloadVirtualClass()
            openSnackbar('Se elimino con exito', true, true)
          } else {
            openSnackbar('No se pudo eliminar', true, false)
          }
        },
      )
    } catch (e) {
      console.log(e)
    }
  }
  function handleSheduleDelete(item, reloadTable) {
    changeModal(
      true,
      '¿Estás seguro que quieres eliminar este horario?',
      <ActionButton
        onCancel={() => changeModal(false, null)}
        onClick={() => onDeleteShedule(item.id, reloadTable)}
        textBtn="Eliminar"
      />,
      null,
    )
  }
  function handleVirtualClassDelete(item) {
    changeModal(
      true,
      '¿Estás seguro que quieres eliminar esta clase virtual?',
      <ActionButton
        onCancel={() => changeModal(true, <TableVirtualClass />)}
        onClick={() => onDeleteVirtualClass(item.id)}
        textBtn="Eliminar"
      />,
      null,
    )
  }

  function handleVirtualClass() {
    changeModal(true, '', <TableVirtualClass />)
  }

  function handleViewSchedule() {
    changeModal(
      true,
      'Horarios',
      <ScheduleView
        // events={ResponseCourseAllSchedule}
        handleSheduleEdit={handleSheduleEdit}
        virtualClass={virtualClassResponse}
        currentEvents={response.schedulesPerCourse}
        showCircles={true}
        showOnlyAllSchedules={true}
        changeView={false}
      />,
      null,
      null,
      '80%',
    )
  }

  async function handleDeleteAllSchudles() {
    async function deleteAllSchudles() {
      try {
        await deleteAction(`courseSchedule/course/${params.pid}/delete`).then(
          (response) => {
            if (validateStatus(response.status)) {
              changeModal(false, null)
              openSnackbar('Se elimino con exito', true, true)
              realoadAll()
            } else {
              openSnackbar('No se pudo eliminar', true, false)
            }
          },
        )
      } catch (e) {
        console.log(e)
      }
    }

    changeModal(
      true,
      '¿Estás seguro que quieres eliminar todos los horarios de este curso?',
      <ActionButton
        onCancel={() => changeModal(false, null)}
        onClick={deleteAllSchudles}
        textBtn="Eliminar"
      />,
      null,
    )
  }
  function handleAttendance(item) {
    changeModal(
      true,
      'Lista de asistencia',
      <AttendanceContainer {...props} schedule={item} />,
      null,
    )
  }

  const actionShedule = [
    {
      id: 1,
      label: 'Lista de asistencia',
      action: handleAttendance,
      icon: 'fas fa-tasks',
    },
    {
      id: 2,
      label: 'Editar horario',
      action: handleSheduleEdit,
      icon: 'fas fa-edit',
    },
    {
      id: 3,
      label: 'Eliminar horario',
      action: handleSheduleDelete,
      icon: 'fas fa-times',
    },
  ]
  const actionVirtualClass = [
    {
      id: 1,
      label: 'Editar clase virtual',
      action: handleVirtualClassEdit,
      icon: 'fas fa-edit',
    },
    {
      id: 2,
      label: 'Eliminar clase virtual',
      action: handleVirtualClassDelete,
      icon: 'fas fa-times',
    },
  ]

  const header = [
    { key: 'classDay', name: 'Dia' },
    { key: 'hourClass', name: 'Hora de la clase' },
    { key: 'classDayDate', name: 'Dia de la clase', type: 'onlyDate' },
    { key: 'classRoomName', name: 'Nombre de la sala' },
    { key: 'link', name: 'Enlace', type: 'a' },

    {
      key: 'action',
      name: 'Acciones',
      type: 'actions',
      actions: actionShedule,
    },
  ]
  const headerVirtualClass = [
    { key: 'roomType', name: 'Tipo de sala' },
    { key: 'roomName', name: 'Nombre de la sala' },
    { key: 'link', name: 'Enlace de reunión' },
    { key: 'colour', name: 'Color', type: 'circleColor' },

    {
      key: 'action',
      name: 'Acciones',
      type: 'actions',
      actions: actionVirtualClass,
    },
  ]
  function preconfig() {
    return response.schedulesPerCourse.map((item) => ({
      ...item,
      classRoomName: item.virtualClassRoom.roomName || '',
      hourClass: `${item.startClass.substring(
        0,
        5,
      )} - ${item.finishClass.substring(0, 5)}`,
    }))
  }

  function handlePastSchedule() {
    changeInfo(<PastSchedule {...props} />)
  }

  const PastSchedule = (props2) => {
    const { changeInfo } = props2
    const { response, loading, reload } = useAxiosAuth({
      method: 'GET',
      url: `/courseSchedule/course/${params.pid}/pastSchedules`,
    })

    if (loading) {
      return <Loading />
    }
    return (
      <>
        <H2 textAlign="center" color="var(--gray)">
          Lista de horarios pasados
        </H2>
        <BoxFlex justify="flex-start">
          <RenderButton
            background="#04AC8A"
            borderRadius="10px"
            marginRight="5px"
            fontSize="1.4rem"
            onClick={() => changeInfo(<FormSchedule {...props2} />)}
          >
            <i className="fas fa-reply-all"></i> Volver
          </RenderButton>
        </BoxFlex>
        <TableMain header={header} main={response} reload={reload} forMobile />
      </>
    )
  }

  return (
    <div>
      <H2 textAlign="center">Horarios</H2>
      <RenderButton
        background="#04AC8A"
        borderRadius="10px"
        marginRight="5px"
        fontSize="1.4rem"
        onClick={handleAdd}
      >
        Adicionar horario <i className="fas fa-plus-circle"></i>
      </RenderButton>
      <RenderButton
        background="#762296AA"
        borderRadius="10px"
        marginLeft="5px"
        fontSize="1.4rem"
        onClick={handleViewSchedule}
      >
        Ver horarios disponibles <i className="fas fa-calendar"></i>
      </RenderButton>
      <RenderButton
        background="var(--orange2)"
        borderRadius="10px"
        margin="5px 15px"
        fontSize="1.4rem"
        onClick={handleVirtualClass}
      >
        Aulas virtuales <i className="fas fa-chalkboard-teacher"></i>
      </RenderButton>
      <DataSubtitle title="Fecha de inicio" type="date">
        {startDate}
      </DataSubtitle>
      <DataSubtitle title="Fecha de finalización" type="date">
        {finishDate}
      </DataSubtitle>
      {!loading ? (
        <>
          {validateArray(response.schedulesPerCourse) ? (
            <RenderButton
              background="#f44336"
              borderRadius="10px"
              margin="10px 0"
              fontSize="1.4rem"
              onClick={handleDeleteAllSchudles}
            >
              <i className="fas fa-trash"></i> Eliminar todos los horarios
            </RenderButton>
          ) : null}
          <BoxFlex justify="flex-start">
            <RenderButton
              background="#04AC8A"
              borderRadius="10px"
              marginRight="5px"
              fontSize="1.4rem"
              onClick={handlePastSchedule}
            >
              <i className="fas fa-reply-all"></i> Ver horarios pasados
            </RenderButton>
          </BoxFlex>
          {validateArray(response.schedulesPerCourse) ? (
            <TableMain
              header={header}
              main={preconfig()}
              reload={realoadAll}
              forMobile
            />
          ) : (
            <H3>No hay horarios creados </H3>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  )
}

const FormAddSchedule = (props) => {
  const { openSnackbar, handleReload, match, changeModal } = props
  const [load, setload] = useState(true)

  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction(`/courseSchedule/course/${match.params.pid}/create`, [
        {
          ...values,
          ClassDay: values.ClassDay,
          StartClass: convertD(values.StartClass, 'HH:mm'),
          FinishClass: convertD(values.FinishClass, 'HH:mm'),
        },
      ]).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Horarios agregados correctamente', true, true)
          handleReload()
          changeModal(false, '')
        } else {
          openSnackbar('El horario ya está ocupado', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }

  return (
    <div className="FormAddSchedule">
      <H2 textAlign="center">Nuevo horario</H2>
      <GlobalForm
        load={load}
        textBtn="Crear horario"
        onSubmit={onSubmit}
        InputsJson={inputAddSchedule}
        data={{}}
        widthBtn="100%"
        onCancel={() => changeModal(false, null)}
      />
      ,
    </div>
  )
}

export default FormSchedule
