import "./ControlSVG.css";
import styled from "styled-components";

const ControlStyle = styled.div`
  width: ${(props) => props.width};

  /* @media screen and (min-width: 1600px) {
    .ControlSVG__svg {
      transform: scale(1.2);
    }
  } */
  @media screen and (max-width: ${(props) => props.width}) {
    width: 100%;
    .Section {
      text-align: start !important;
      width: 100%;
      font-weight: 600 !important;
      margin: 0 !important;
    }
    .ControlSVG__text {
      position: relative;
    }
    .ControlSVG__svg {
      display: none;
    }
  }
`;
const ControlSVG = ({ children, figure, width, widthText, color }) => {
  const figures = {
    message1: Message1,
    message2: Message2,
    message3: Message3,
    message4: Message4,
    rectangle1: Rectangle1,
    rectangle2: Rectangle2,
    rectangle3: Rectangle3,
    rectangle4: Rectangle4,
  };
  const Content = figures[figure] || Message1;
  return (
    <ControlStyle className="ControlSVG" width={width}>
      <div className="ControlSVG__text" style={{ width: widthText }}>
        {children}
      </div>
      <Content color={color} />
    </ControlStyle>
  );
};
function Message1({ color }) {
  return (
    <Message1Style
      color={color}
      className="ControlSVG__svg"
      viewBox="0 0 639.047 506.68"
    >
      <defs>
        <filter id="Path_127" x="0" y="0" filterUnits="userSpaceOnUse">
          <feOffset dy="5" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_2239" data-name="Group 2239" transform="translate(15 10)">
        <g className="cls-3" transform="matrix(1, 0, 0, 1, -15, -10)">
          <path
            id="Path_127-2"
            data-name="Path 127"
            className="cls-1"
            d="M117.587,239.21,611.754,214.26a57.435,57.435,0,0,1,59.791,65.2l-45.918,333.3a57.429,57.429,0,0,1-63.833,49.172L306.157,630.811c-10.62,9.756-23.487,21.425-35.1,31.544-25.581,22.3-37.436,30.112-59.9,28.243s-21.839-28.243-21.839-28.243V616.591l-45.035-5.482A57.435,57.435,0,0,1,94.2,560.9L63.459,303.379A57.434,57.434,0,0,1,117.587,239.21Z"
            transform="translate(-48.05 -204.18)"
          />
        </g>
        <path
          id="Path_128"
          data-name="Path 128"
          className="cls-2"
          d="M120.906,241.608l458.907-23.164a53.327,53.327,0,0,1,55.526,60.532L592.7,588.447A53.331,53.331,0,0,1,533.419,634.1L284.571,601.389c-9.863,9.058-25.657,27.143-36.435,36.537-23.759,20.7-27.911,25.477-40.332,22.8s-13.835-30.5-13.835-30.5l-2.694-35.391L145.7,586.916A53.329,53.329,0,0,1,99.19,540.305L70.64,301.189A53.331,53.331,0,0,1,120.906,241.608Z"
          transform="translate(-51.057 -204.282)"
        />
      </g>
    </Message1Style>
  );
}
function Message2({ color }) {
  return (
    <Message2Style
      color={color}
      className="ControlSVG__svg"
      viewBox="0 0 1135.189 542.135"
    >
      <defs>
        <filter
          id="Path_5447"
          x="0"
          y="0"
          width="1135.189"
          height="542.135"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_2284"
        data-name="Group 2284"
        transform="translate(-11087.297 -5921)"
      >
        <g className="cls-3" transform="matrix(1, 0, 0, 1, 11087.3, 5921)">
          <path
            id="Path_5447-2"
            data-name="Path 5447"
            className="cls-1"
            d="M85.209,27.6l1022.829.225c25.78-1.649,46.387,26.823,42.863,59.23l-25.709,404.614c-3.071,28.229-23.442,48.113-45.764,44.673L227.6,485.173c-7.615,8.864-16.837,19.466-25.159,28.656-18.34,20.257-26.84,27.358-42.944,25.659s-15.657-25.659-15.657-25.659V472.255l-32.282-4.98c-18.832-2.9-33.659-21.737-35.908-45.607L46.4,85.888C43.574,55.881,61.39,29.118,85.209,27.6Z"
            transform="translate(-31.11 -15.6)"
          />
        </g>
        <path
          id="Path_5448"
          data-name="Path 5448"
          className="cls-2"
          d="M89.667,32.378l991.62,2.123c23.588-1.512,42.44,24.58,39.217,54.274l-22.919,379.289c-2.81,25.865-21.447,44.089-41.87,40.934L212.459,456.775c-6.965,8.122-18.12,24.336-25.733,32.757-16.779,18.564-19.714,22.843-28.486,20.441s-9.772-27.344-9.772-27.344l-1.9-31.73-32.187-7.1c-17.229-2.663-30.8-19.92-32.853-41.794L54.163,85.795C51.576,58.3,67.877,33.775,89.667,32.378Z"
          transform="translate(11065.29 5913.724)"
        />
      </g>
    </Message2Style>
  );
}
function Message3({ color }) {
  return (
    <Message3Style
      color={color}
      className="ControlSVG__svg"
      viewBox="0 0 911.262 454.467"
    >
      <defs>
        <filter
          id="Path_5449"
          x="0"
          y="0"
          width="911.262"
          height="454.467"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="10" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_2285"
        data-name="Group 2285"
        transform="translate(-7562.738 -6130.255)"
      >
        <g className="cls-3" transform="matrix(1, 0, 0, 1, 7562.74, 6130.26)">
          <path
            id="Path_5449-2"
            data-name="Path 5449"
            className="cls-1"
            d="M867.248,27.6l-787.824.173C59.567,26.5,43.695,48.428,46.409,73.389l19.8,311.65c2.365,21.743,18.056,37.059,35.249,34.409L757.571,380.04c5.865,6.828,12.969,14.993,19.379,22.072,14.126,15.6,20.673,21.072,33.077,19.764s12.06-19.764,12.06-19.764V370.09l24.865-3.836c14.505-2.237,25.926-16.742,27.658-35.128L897.14,72.494C899.317,49.382,885.594,28.768,867.248,27.6Z"
            transform="translate(-16.11 -0.6)"
          />
        </g>
        <path
          id="Path_5450"
          data-name="Path 5450"
          className="cls-2"
          d="M848.163,32.378,84.377,34.013c-18.168-1.165-32.689,18.932-30.207,41.8L71.823,367.961c2.164,19.922,16.52,33.959,32.25,31.529l649.51-40.224c5.365,6.256,13.957,18.745,19.821,25.231,12.924,14.3,15.185,17.595,21.941,15.744s7.527-21.061,7.527-21.061l1.466-24.439,24.791-5.467c13.27-2.051,23.72-15.343,25.3-32.191l21.075-243.56C877.5,52.344,864.946,33.454,848.163,32.378Z"
          transform="translate(7555.276 6134.969)"
        />
      </g>
    </Message3Style>
  );
}
function Message4({ color }) {
  return (
    <Message4Style
      color={color}
      className="ControlSVG__svg"
      viewBox="0 0 793.994 349.815"
    >
      <defs>
        <filter
          id="Path_5426"
          x="0"
          y="0"
          width="793.994"
          height="349.815"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="10" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_2286"
        data-name="Group 2286"
        transform="translate(-7599.038 -6645)"
      >
        <g className="cls-4" transform="matrix(1, 0, 0, 1, 7599.04, 6645)">
          <path
            id="Path_5426-2"
            data-name="Path 5426"
            className="cls-1"
            d="M1033.52,490.407H458.529c-20.289,0-36.738-15.143-36.738-33.825V234.417c0-18.682,16.449-33.825,36.738-33.825h660.518c20.29,0,36.738,15.143,36.738,33.825v141.95Z"
            transform="translate(-391.79 -173.59)"
          />
        </g>
        <path
          id="Path_5427"
          data-name="Path 5427"
          className="cls-2"
          d="M1030.366,461.046H463c-19.423,0-35.166-13.245-35.166-29.583V237.152c0-16.338,15.743-29.581,35.166-29.581H1095.19c19.42,0,35.166,13.243,35.166,29.581V371.72Z"
          transform="translate(7216.94 6482.601)"
        />
        <path
          id="Path_5428"
          data-name="Path 5428"
          className="cls-3"
          d="M637.906,381.177V304.1a35.486,35.486,0,0,1,35.486-35.486h77.076Z"
          transform="translate(7612.091 6580.5)"
        />
      </g>
    </Message4Style>
  );
}
function Rectangle1({ color }) {
  return (
    <Rectangle1Style
      color={color}
      className="ControlSVG__svg"
      viewBox="0 0 590.52 313.523"
    >
      <g
        id="Group_2284"
        data-name="Group 2284"
        transform="translate(-1029.742 -1062)"
      >
        <g
          id="Group_91"
          data-name="Group 91"
          transform="translate(846.167 626)"
        >
          <rect
            id="Rectangle_90"
            data-name="Rectangle 90"
            className="cls-1"
            width="546.805"
            height="285.311"
            transform="translate(183.574 464.212)"
          />
          <rect
            id="Rectangle_91"
            data-name="Rectangle 91"
            className="cls-2"
            width="538.341"
            height="262.179"
            transform="translate(204.679 460.945)"
          />
          <g
            id="Group_75"
            data-name="Group 75"
            transform="translate(224.28 436)"
          >
            <path
              id="Path_227"
              data-name="Path 227"
              className="cls-3"
              d="M285,561.845v-229.9H510.23V322H277.695V561.845Z"
              transform="translate(-277.695 -322.003)"
            />
            <g id="Group_74" data-name="Group 74" transform="translate(35.981)">
              <path
                id="Path_228"
                data-name="Path 228"
                className="cls-3"
                d="M793.817,360.253V475.661H287.548v9.559H801.378V360.253Z"
                transform="translate(-287.548 -211.277)"
              />
              <path
                id="Path_229"
                data-name="Path 229"
                className="cls-3"
                d="M614.185,408.841h7.3V322H348.783v9.559h265.4Z"
                transform="translate(-107.658 -322.003)"
              />
            </g>
          </g>
        </g>
      </g>
    </Rectangle1Style>
  );
}
function Rectangle2({ color }) {
  return (
    <Rectangle2Style
      color={color}
      className="ControlSVG__svg"
      viewBox="0 0 1074.943 477.829"
    >
      <defs>
        <filter
          id="Rectangle_269"
          x="0"
          y="2.964"
          width="1074.943"
          height="474.865"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="10" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_2285"
        data-name="Group 2285"
        transform="translate(-22249.088 -3599.18)"
      >
        <g className="cls-3" transform="matrix(1, 0, 0, 1, 22249.09, 3599.18)">
          <rect
            id="Rectangle_269-2"
            data-name="Rectangle 269"
            className="cls-1"
            width="1014.943"
            height="414.865"
            transform="translate(30 29.96)"
          />
        </g>
        <g
          id="Group_2265"
          data-name="Group 2265"
          transform="translate(22304.436 3599.18)"
        >
          <path
            id="Path_227"
            data-name="Path 227"
            className="cls-2"
            d="M289.735,689.277V337.231h371.3V322H277.695V689.277Z"
            transform="translate(-277.695 -322.003)"
          />
          <g id="Group_74" data-name="Group 74" transform="translate(59.316 0)">
            <path
              id="Path_228"
              data-name="Path 228"
              className="cls-2"
              d="M1233.309,360.253V540.342H287.548v14.917h959.887V360.253Z"
              transform="translate(-287.548 -134.771)"
            />
            <path
              id="Path_229"
              data-name="Path 229"
              className="cls-2"
              d="M786.31,457.51h12.041V322H348.783v14.916H786.31Z"
              transform="translate(161.536 -322.003)"
            />
          </g>
        </g>
      </g>
    </Rectangle2Style>
  );
}
function Rectangle3({ color }) {
  return (
    <Rectangle3Style
      color={color}
      className="ControlSVG__svg"
      viewBox="0 0 664.982 387.88"
    >
      <defs>
        <filter
          id="Path_5466"
          x="0"
          y="0"
          width="664.982"
          height="329.419"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="10" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_2286"
        data-name="Group 2286"
        transform="translate(-22424.121 -4216.12)"
      >
        <path
          id="Path_5467"
          data-name="Path 5467"
          className="cls-1"
          d="M323.427,385.409l-23.916-91.475H441.349Z"
          transform="translate(22617.754 4218.591)"
        />
        <g className="cls-4" transform="matrix(1, 0, 0, 1, 22424.12, 4216.12)">
          <path
            id="Path_5466-2"
            data-name="Path 5466"
            className="cls-2"
            d="M675.915,430.393H112.991L70.933,160.973H600.194Z"
            transform="translate(-40.93 -133.97)"
          />
        </g>
        <path
          id="Path_5465"
          data-name="Path 5465"
          className="cls-3"
          d="M691.445,421.216H128.52L86.463,151.8H615.724Z"
          transform="translate(22388.125 4071.723)"
        />
      </g>
    </Rectangle3Style>
  );
}
function Rectangle4({ color }) {
  return (
    <Rectangle4Style
      color={color}
      className="ControlSVG__svg"
      viewBox="0 0 590.518 313.522"
    >
      <rect
        id="Rectangle_90"
        data-name="Rectangle 90"
        className="cls-1"
        width="546.804"
        height="285.31"
        transform="translate(0 0)"
      />
      <rect
        id="Rectangle_91"
        data-name="Rectangle 91"
        className="cls-2"
        width="538.34"
        height="262.179"
        transform="translate(21.105 26.399)"
      />
      <g
        id="Group_75"
        data-name="Group 75"
        transform="translate(40.707 39.579)"
      >
        <path
          id="Path_227"
          data-name="Path 227"
          className="cls-3"
          d="M285,322V551.9H510.229v9.944H277.695V322Z"
          transform="translate(-277.695 -287.901)"
        />
        <g id="Group_74" data-name="Group 74" transform="translate(35.981)">
          <path
            id="Path_228"
            data-name="Path 228"
            className="cls-3"
            d="M793.816,485.22V369.812H287.548v-9.559h513.83V485.22Z"
            transform="translate(-287.548 -360.253)"
          />
          <path
            id="Path_229"
            data-name="Path 229"
            className="cls-3"
            d="M614.184,322h7.3v86.838H348.783v-9.559h265.4Z"
            transform="translate(-107.658 -134.898)"
          />
        </g>
      </g>
    </Rectangle4Style>
  );
}
const Rectangle4Style = styled.svg`
  .cls-1,
  .cls-3 {
    fill: #6a3081;
  }

  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    opacity: 0.98;
  }
`;
const Rectangle3Style = styled.svg`
  .cls-1 {
    fill: ${(props) => props.color};
  }

  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    fill: none;
    stroke: ${(props) => props.color};
    stroke-miterlimit: 10;
    stroke-width: 10px;
  }

  .cls-4 {
    filter: url(#Path_5466);
  }
`;
const Rectangle2Style = styled.svg`
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: ${(props) => props.color};
    opacity: 0.98;
  }
  .cls-3 {
    filter: url(#Rectangle_269);
  }
`;
const Rectangle1Style = styled.svg`
  .cls-1,
  .cls-3 {
    fill: ${(props) => props.color};
  }

  .cls-2 {
    fill: #fff;
  }

  .cls-3 {
    opacity: 0.98;
  }
`;
const Message4Style = styled.svg`
  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: none;
    stroke: ${(props) => props.color};
    stroke-miterlimit: 10;
    stroke-width: 5px;
  }

  .cls-3 {
    fill: ${(props) => props.color};
  }

  .cls-4 {
    filter: url(#Path_5426);
  }
`;
const Message3Style = styled.svg`
  .cls-1 {
    fill: #fff;
  }

  .cls-2 {
    fill: none;
    stroke: ${(props) => props.color};
    stroke-miterlimit: 10;
    stroke-width: 5px;
  }

  .cls-3 {
    filter: url(#Path_5449);
  }
`;
const Message2Style = styled.svg`
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: none;
    stroke: ${(props) => props.color};
    stroke-miterlimit: 10;
    stroke-width: 5px;
  }
  .cls-3 {
    filter: url(#Path_5447);
  }
`;
const Message1Style = styled.svg`
  .cls-1 {
    fill: #fff;
  }
  .cls-2 {
    fill: none;
    stroke: ${(props) => props.color};
    stroke-miterlimit: 10;
    stroke-width: 7px;
  }
  .cls-3 {
    filter: url(#Path_127);
  }
`;
export default ControlSVG;
