import axios from 'axios'
const instancia = axios.create()
instancia.CancelToken = axios.CancelToken
instancia.isCancel = axios.isCancel

export const putShopp = (data) => {
  return new Promise((resolve, eject) => {
    instancia
      .put('/order/payCalculate', data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        resolve(error.response)
      })
  })
}
