import React from 'react'
import './style/InputSelect.css'
function InputSelectTime(props) {
  return (
    <div className="InputSelect">
      {props.children}

      <select value={props.value} onChange={props.onChange} name={props.name}>
        <option value={1}>1 día</option>
        <option value={7}>1 semana</option>
        <option value={30}>1 mes</option>
        {/* <option value={0}>No cerrar sesión</option> */}
      </select>
    </div>
  )
}

export default InputSelectTime
