import * as Yup from 'yup'
import { dropdownGender, dropdownDepartament } from './Constant'
export const HomeworkSubmitUrl = [
  {
    name: 'Url',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Enlace',
  },
  {
    name: 'Description',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Descripción del enlace',
  },
]
// {
//   "Name" : "coupon1",
//   "Type" : "descuento",
//   "Amount" : 20.0,
//   "Status" : "public",
//   "MinimumExpense" : 10,
//   "DateExpiry" : "2021-01-01"
// }
export const CouponInput = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre del cupón',
  },
  {
    name: 'Type',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Tipo de descuento',
  },
  {
    name: 'Amount',
    initial: 0,
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(0, 'La cantidad mínima de nota es 0'),
    type: 'number',
    label: 'Precio',
  },
  {
    name: 'Status',
    initial: false,
    validate: Yup.boolean().required('Campo requerido'),
    type: 'checkbox',
    label: 'Cupón publico',
  },
  {
    name: 'Amount',
    initial: 0,
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(0, 'La cantidad mínima de nota es 0'),
    type: 'number',
    label: 'Precio',
  },
  {
    name: 'StartDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de inicio',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
]

export const HomeworkSubmit = [
  {
    name: 'VideoFile',
    control: 'file',
    initial: null,
    validate: null,
    label: 'Video',
    type: 'video',
  },
]
export const PricesInput = [
  {
    name: 'Amount',
    initial: 0,
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(0, 'La cantidad mínima de nota es 0'),
    type: 'number',
    label: 'Precio',
  },
]
export const ParticipationQualify = [
  {
    name: 'Valoration',
    initial: 0,
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .max(100, 'La cantidad máxima de nota es 100')
      .min(0, 'La cantidad mínima de nota es 0'),
    type: 'number',
    label: 'Calificación',
  },
]
export const CommentInput = [
  {
    name: 'Description',
    initial: null,
    control: 'editorSimple',
    validate: null,
    label: 'Agregar comentario',
  },
]
export const InputForum = [
  {
    name: 'CommentText',
    initial: null,
    control: 'editorSimple',
    validate: null,
    label: 'Agregar participación',
  },
]
export const InputsCheckIt = [
  {
    name: 'score',
    initial: 0,
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .max(100, 'La cantidad máxima de nota es 100')
      .min(0, 'La cantidad mínima de nota es 0'),
    type: 'number',
    label: 'Calificación',
  },
  {
    name: 'Description',
    initial: null,
    control: 'editor',
    validate: null,
    label: 'Recomendación',
  },
]
export const InputsModule = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre del módulo',
  },
  {
    name: 'StartDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de inicio',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
  // {
  //   name: 'FinishDate',
  //   initial: null,
  //   validate: Yup.string().required('Campo requerido').nullable(),
  //   control: 'date',
  //   label: 'Fecha de conclusión',
  //   dateFormat: 'MMMM d, yyyy HH:mm',
  //   showTimeSelect: true,
  //   timeIntervals: 15,
  // },
  {
    name: 'Type',
    initial: 'normal',
    validate: Yup.string().required('Campo requerido'),
    control: 'select',
    label: 'Tipo de módulo',
    options: [
      { key: 'Normal', value: 'normal' },
      { key: 'Recuperatorio', value: 'recuperatory' },
    ],
  },
  {
    name: 'description',
    initial: '',
    validate: Yup.string(),
    control: 'textarea',
    label: 'Descripción',
  },
  {
    name: 'PhotoUrl',
    control: 'file',
    initial: null,
    validate: null,
    label: 'Selecciona una imagen',
    type: 'image/*',
  },
]
export const InputsModuleEdit = [
  ...InputsModule,
  {
    name: 'UpdateUser',
    initial: true,
    validate: Yup.boolean().required('Campo requerido'),
    type: 'checkbox',
    label: 'Modificar para estudiantes',
  },
]
export const InputsExtend = [
  {
    name: 'StartDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de inicio',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: 'FinishDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de conclusión',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
]

export const InputsCourse = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre del curso',
  },
  {
    name: 'StartDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de inicio',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: 'FinishDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de conclusión',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: 'Description',
    initial: '',
    validate: Yup.string(),
    control: 'textarea',
    label: 'Descripción',
  },
]
export const InputsCategory = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre de la categoría',
  },
  {
    name: 'Description',
    initial: '',
    validate: Yup.string(),
    control: 'textarea',
    label: 'Descripción',
  },
]
export const InputsAddInstitution = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre de la institución (*)',
  },
  {
    name: 'Email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
  {
    name: 'IdentifucationCard',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número de teléfono'),
    type: 'text',
    label: 'Número de identificacion',
  },
  {
    name: 'SiteName',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Nombre de su pagina',
  },
  {
    name: 'Url',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Enlace de su pagina',
  },
]
export const InputsAddContact = [
  {
    name: 'Phone',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número de teléfono')
      .required('Campo requerido'),
    type: 'text',
    label: 'Número de celular',
  },
  {
    name: 'InformationMedium',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Medio de información:',
  },
  {
    name: 'Name',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Nombres (*)',
  },
  {
    name: 'Lastname',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Apellidos (*)',
  },

  {
    name: 'Email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
]

export const InputsEditContact = [
  {
    name: 'Phone',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número de teléfono'),
    type: 'text',
    label: 'Número de celular',
  },
  {
    name: 'Morning',
    initial: false,
    type: 'checkbox',
    label: 'Mañana',
  },
  {
    name: 'Afternoon',
    initial: false,
    type: 'checkbox',
    label: 'Tarde',
  },
  {
    name: 'Night',
    initial: false,
    type: 'checkbox',
    label: 'Noche',
  },
  {
    name: 'Name',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Nombres (*)',
  },
  {
    name: 'Lastname',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Apellidos (*)',
  },
  {
    name: 'Ci',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Carnet de identidad',
  },
  {
    name: 'ExpeditionCi',
    initial: '',
    validate: Yup.string(),
    control: 'select',
    label: 'Expedida en:',
    options: [
      { key: 'Expedida en:', value: '' },
      { key: 'Chuquisaca', value: 'CH' },
      { key: 'La Paz', value: 'LP' },
      { key: 'Cochabamba', value: 'CB' },
      { key: 'Oruro', value: 'OR' },
      { key: 'Potosí', value: 'PT' },
      { key: 'Tarija', value: 'TJ' },
      { key: 'Santa Cruz', value: 'SC' },
      { key: 'Beni', value: 'BE' },
      { key: 'Pando', value: 'PD' },
    ],
  },
  {
    name: 'InformationMedium',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Medio de información:',
  },
  {
    name: 'City',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Ciudad',
  },

  {
    name: 'Email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
  {
    name: 'Age',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Edad',
  },
  {
    name: 'CurrentInstitution',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Institución perteneciente',
  },
  {
    name: 'Priority',
    initial: '',
    validate: Yup.string(),
    control: 'select',
    label: 'Prioridad',
    options: [
      { key: 'Prioridad', value: '' },
      { key: 'No definido', value: 'no definidos' },
      { key: 'Baja', value: '3-rojo' },
      { key: 'Media', value: '2-amarillo' },
      { key: 'Alta', value: '1-verde' },
    ],
  },
  {
    name: 'InterestLevel',
    initial: '1',
    validate: Yup.string(),
    control: 'select',
    label: 'Nivel de interes',
    options: [
      { key: 'Nivel de interes', value: '' },
      { key: 'Baja', value: '1' },
      { key: 'Media', value: '2' },
      { key: 'Alta', value: '3' },
    ],
  },
  {
    name: 'Profession',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Profesión',
  },
  {
    name: 'Description',
    initial: '',
    validate: null,
    control: 'textarea',
    label: 'Descripción',
  },
  {
    name: 'Observation',
    initial: '',
    validate: null,
    control: 'textarea',
    label: 'Observaciones',
  },
]
export const InputsFollowUp = [
  {
    name: 'Description',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'textarea',
    label: 'Descripción',
  },
  {
    name: 'FollowDate',
    initial: null,
    validate: null,
    control: 'date',
    label: 'Fecha',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
]
export const InputsCoupon = [
  {
    name: 'CouponCode',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Código del cupón',
  },
]
export const InputsFile = [
  {
    name: 'ImageFile',
    control: 'file',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    label: 'Selecciona una imagen',
    type: 'image/*',
  },
]
export const inputreallocateSell = [
  {
    control: 'getSelect',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Seleccione el vendedor',
    name: 'ReasignToId',
    url: '/contact/sellers',
    keyOption: 'name',
    value: 'userId',
  },
]
export const InputWonderMath = [
  {
    name: 'username',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Usuario *',
  },
  {
    name: 'Password',
    initial: '',
    validate: Yup.string()
      .required('Campo requerido')
      .min(4, 'La contraseña debe tener al menos 4 caracteres'),
    type: 'password',
    label: 'Contraseña *',
  },
]
export const InputPreRegister = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre',
  },
  {
    name: 'LastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellido',
  },
  {
    name: 'Email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
  {
    name: 'IdDocument',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número'),
    type: 'text',
    label: 'Carnet de identidad',
  },
  {
    name: 'ExpeditionCi',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'select',
    label: 'Expedida en:',
    options: [
      { key: 'Expedida en:', value: '' },
      { key: 'Chuquisaca', value: 'CH' },
      { key: 'La Paz', value: 'LP' },
      { key: 'Cochabamba', value: 'CB' },
      { key: 'Oruro', value: 'OR' },
      { key: 'Potosí', value: 'PT' },
      { key: 'Tarija', value: 'TJ' },
      { key: 'Santa Cruz', value: 'SC' },
      { key: 'Beni', value: 'BE' },
      { key: 'Pando', value: 'PD' },
    ],
  },
  {
    name: 'PhoneNumber',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    type: 'number',
    label: 'Número de celular',
  },
]
export const InputChildWonderMath = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre *',
  },
  {
    name: 'LastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellido *',
  },
  {
    name: 'Character',
    initial: '1',
    validate: Yup.string().required('Campo requerido'),
    control: 'select',
    label: '¿Para quién es esta cuenta? *',
    options: [
      { key: 'Elegir', value: '' },
      { key: 'Niño', value: 'boy' },
      { key: 'Niña', value: 'girl' },
    ],
  },
  {
    name: 'BirthDate',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'date',
    label: 'Fecha de nacimiento *',
  },
  ...InputWonderMath,
]
export const InputChildRegister = [
  ...InputWonderMath,
  {
    name: 'FirstName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombres *',
  },
  {
    name: 'LastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellidos *',
  },
  {
    name: 'Gender',
    initial: '1',
    validate: Yup.string().required('Campo requerido'),
    control: 'select',
    label: '¿Para quién es esta cuenta? *',
    options: [
      { key: 'Elegir', value: '' },
      { key: 'Niño', value: 'M' },
      { key: 'Niña', value: 'F' },
    ],
  },
  {
    name: 'BirthDate',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'date',
    label: 'Fecha de nacimiento',
  },
]

export const inputFile = [
  {
    name: 'Description',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Descripción del archivo',
  },
  {
    name: 'File',
    control: 'file',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    label: 'Selecciona el archivo',
    type: '',
  },
  {
    name: 'Nitname',
    initial: '',
    validate: Yup.string().required('Campo requerido').nullable(),
    type: 'text',
    label: 'Nombre del NIT',
  },
  {
    name: 'NIT',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    type: 'text',
    label: 'Número de NIT',
  },
  {
    name: 'Observation',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    control: 'textarea',
    label: 'Observación',
  },
]
export const inputEditFile = [
  {
    name: 'NitName',
    initial: '',
    validate: Yup.string().required('Campo requerido').nullable(),
    type: 'text',
    label: 'Nombre del NIT',
  },
  {
    name: 'Nit',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    type: 'text',
    label: 'Número de NIT',
  },
  {
    name: 'Observation',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    control: 'textarea',
    label: 'Observación',
  },
]
export const inputEditCourse = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre del curso',
  },
  {
    name: 'VersionName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre del paralelo',
  },
  {
    name: 'StartDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de inicio',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: 'FinishDate',
    initial: null,
    validate: null,
    control: 'date',
    label: 'Fecha de conclusión',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: 'ImgFile',
    control: 'file',
    initial: null,
    validate: null,
    label: 'Selecciona una imagen',
    type: 'image/*',
  },
  {
    name: 'AddressedTo',
    initial: '',
    validate: null,
    control: 'textarea',
    label: '¿Para quien es este diplomado?',
  },
  {
    name: 'CourseAchievements',
    initial: '',
    validate: null,
    control: 'textarea',
    label: 'Lo que aprenderás',
  },
  {
    name: 'Description',
    initial: '',
    validate: null,
    control: 'textarea',
    label: 'Descripción',
  },
  {
    name: 'WhatsappGroupUrl',
    initial: '',
    validate: null,
    type: 'text',
    label: 'Enlace de WhatsApp',
  },
]
export const Contact = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre',
  },
  {
    name: 'Phone',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'Phone',
    type: 'text',
    label: 'Teléfono',
  },
]
export const SetGoal = [
  {
    name: 'GoalSet',
    initial: '',
    validate: Yup.number().required('Campo requerido'),
    type: 'text',
    label: 'Meta: ',
  },
]
export const inputUpdateUser = [
  {
    name: 'LastName',
    initial: '',
    type: 'text',
    label: 'Apellidos: ',
  },
  {
    name: 'Name',
    initial: '',

    type: 'text',
    label: 'Nombres: ',
  },
  {
    name: 'Ci',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número'),
    type: 'text',
    label: 'CI: ',
  },
  {
    name: 'ExpeditionCi',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    control: 'select',
    label: 'Expedida en:',
    options: dropdownDepartament,
  },
  {
    name: 'Email',
    initial: '',
    validate: Yup.string().email('Formato de correo inválido'),
    type: 'text',
    label: 'Correo Electrónico: ',
  },
  {
    name: 'NationalNumber',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número de teléfono'),

    type: 'text',
    label: 'Teléfono de Contacto',
  },
  {
    name: 'DateOfBirth',
    initial: '',
    type: 'date',
    label: 'Fecha de nacimiento',
  },
  {
    name: 'Gender',
    initial: '',
    type: 'text',
    control: 'select',
    label: 'Seleccionar género:',
    options: dropdownGender,
  },
  {
    initial: '',
    validate: null,
    label: 'Rol del usuario',
    control: 'getCheckbox',
    name: 'Rol',
    url: '/rol',
    keyOption: 'label',
    value: 'id',
  },
]

export const inputEditTeacher = [
  {
    name: 'Firstname',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombres',
  },
  {
    name: 'LastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellidos',
  },
  {
    name: 'Profession',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Profesión',
  },
  {
    name: 'Phone',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'Phone',
    type: 'text',
    label: 'Teléfono',
  },
  {
    name: 'Description',
    initial: '',
    validate: Yup.string(),
    control: 'textarea',
    label: 'Descripción',
  },
]
export const inputEditPhoto = [
  {
    name: 'PhotoUrl',
    control: 'file',
    initial: null,
    validate: null,
    label: 'Selecciona una imagen de perfil',
    type: 'image/*',
  },
]
export const inputAddSchedule = [
  {
    name: 'ClassDay',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'select',
    label: 'Día:',
    options: [
      { key: 'Dia:', value: '' },
      { key: 'Lunes', value: 'Lunes' },
      { key: 'Martes', value: 'Martes' },
      { key: 'Miércoles', value: 'Miércoles' },
      { key: 'Jueves', value: 'Jueves' },
      { key: 'Viernes', value: 'Viernes' },
      { key: 'Sábado', value: 'Sábado' },
      { key: 'Domingo', value: 'Domingo' },
    ],
  },
  {
    name: 'StartClass',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Hora de inicio',
    dateFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 30,
  },
  {
    name: 'FinishClass',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Hora de finalización',
    dateFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 30,
  },
  /* {
    name: 'VirtualClassRoomId',
    initial: '',
    validate: Yup.string(),
    control: 'select',
    label: 'Número de sala:',
    options: [
      { key: 'Escoja número de sala:', value: '' },
      { key: 'Sala 1', value: '1' },
      { key: 'Sala 2', value: '2' },
      { key: 'Sala 3', value: '3' },
    ],
  }, */
  {
    name: 'VirtualClassRoomId',
    control: 'getSelect',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Seleccione una sala',
    url: '/virtualClassRoom',
    keyOption: 'roomName',
    value: 'id',
  },
]
export const inputAddOneSchedule = [
  {
    name: 'ClassDayDate',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'date',
    label: 'Fecha:',
  },
  {
    name: 'StartClass',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Hora de inicio',
    dateFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 30,
  },
  {
    name: 'FinishClass',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Hora de finalización',
    dateFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 30,
  },

  {
    name: 'VirtualClassRoomId',
    control: 'getSelect',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Seleccione una sala',
    url: '/virtualClassRoom',
    keyOption: 'roomName',
    value: 'id',
  },
]
export const inputEditSchedule = [
  {
    name: 'StartClass',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Hora de inicio',
    dateFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 30,
  },
  {
    name: 'FinishClass',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Hora de finalización',
    dateFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 30,
  },
  {
    name: 'ClassDayDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de clase',
  },
  {
    name: 'Link',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    label: 'Enlace',
  },
]
export const inputReschedule = [
  {
    name: 'Reason',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'textarea',
    label: 'Motivo de la reprogramación',
  },
  {
    name: 'ClassDayDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de clase de recuperatorio',
  },
  {
    name: 'StartClass',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Hora de inicio',
    dateFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 30,
  },
  {
    name: 'FinishClass',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Hora de finalización',
    dateFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: 30,
  },
  {
    name: 'VirtualClassRoomId',
    control: 'getSelect',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Seleccione una sala',
    url: '/virtualClassRoom',
    keyOption: 'roomName',
    value: 'id',
  },
  // {
  //   name: 'Link',
  //   initial: null,
  //   validate: Yup.string().required('Campo requerido').nullable(),
  //   label: 'Enlace',
  // },
]
export const inputAddVirtualClass = [
  {
    name: 'RoomName',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    label: 'Nombre de la sala',
  },
  {
    name: 'RoomType',
    initial: '1',
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'select',
    label: 'Tipo de sala',
    options: [
      { key: 'Seleccione tipo de sala', value: '' },
      { key: 'Zoom', value: 'Zoom' },
      { key: 'Meet', value: 'Meet' },
      { key: 'Jitsi', value: 'Jitsi' },
      { key: 'Curso Presencial', value: 'classroomCourse' },
    ],
  },
  {
    name: 'Link',
    initial: null,
    validate: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Ingrese un link válido',
    ),
    label: 'Enlace de reunión',
  },
  {
    name: 'Colour',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'color',
    label: 'Escoja su color',
  },
]
export const inputEditParallel = [
  {
    name: 'VersionName',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    label: 'Nombre del paralelo',
  },
  {
    name: 'DefaultEnroll',
    initial: false,
    validate: Yup.boolean().required('Campo requerido'),
    type: 'checkbox',
    label: 'Inscripción predeterminada',
  },
  {
    name: 'IsRoot',
    initial: false,
    validate: Yup.boolean().required('Campo requerido'),
    type: 'checkbox',
    label: 'Plantilla principal',
  },
]
export const inputsAddCourse = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombres',
  },
  {
    name: 'LastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellidos',
  },
  {
    name: 'Ci',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Carnet de identidad',
  },
  {
    name: 'ExpeditionCi',
    initial: '',
    validate: Yup.string(),
    control: 'select',
    label: 'Expedida en:',
    options: [
      { key: 'Expedida en:', value: '' },
      { key: 'Chuquisaca', value: 'CH' },
      { key: 'La Paz', value: 'LP' },
      { key: 'Cochabamba', value: 'CB' },
      { key: 'Oruro', value: 'OR' },
      { key: 'Potosí', value: 'PT' },
      { key: 'Tarija', value: 'TJ' },
      { key: 'Santa Cruz', value: 'SC' },
      { key: 'Beni', value: 'BE' },
      { key: 'Pando', value: 'PD' },
    ],
  },
  {
    name: 'NationalNumber',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número de teléfono')
      .required('Campo Requerido')
      .max(99999999, 'Tu número de teléfono tiene muchos dígitos')
      .min(10000000, 'Tu número de teléfono tiene pocos dígitos'),
    type: 'text',
    label: 'Teléfono',
  },
  {
    name: 'Email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
]
export const inputChangePass = [
  {
    name: 'NewPassword',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'password',
    label: 'Nueva contraseña',
  },
]

export const InputRegisterTutor = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre',
  },
  {
    name: 'LastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellido',
  },
  {
    name: 'Email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
  {
    name: 'NationalNumber',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    type: 'number',
    label: 'Número de celular',
  },
  {
    name: 'Ci',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número'),
    type: 'text',
    label: 'Carnet de identidad',
  },
  {
    name: 'ExpeditionCi',
    initial: '',
    validate: Yup.string(),
    control: 'select',
    label: 'Expedida en:',
    options: [
      { key: 'Expedida en:', value: '' },
      { key: 'Chuquisaca', value: 'CH' },
      { key: 'La Paz', value: 'LP' },
      { key: 'Cochabamba', value: 'CB' },
      { key: 'Oruro', value: 'OR' },
      { key: 'Potosí', value: 'PT' },
      { key: 'Tarija', value: 'TJ' },
      { key: 'Santa Cruz', value: 'SC' },
      { key: 'Beni', value: 'BE' },
      { key: 'Pando', value: 'PD' },
    ],
  },
]

export const InputRegisterChild = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombre',
  },
  {
    name: 'LastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellido',
  },
  {
    name: 'Ci',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número'),
    type: 'text',
    label: 'Carnet de identidad',
  },
  {
    name: 'ExpeditionCi',
    initial: '',
    validate: null,
    control: 'select',
    label: 'Expedida en:',
    options: [
      { key: 'Expedida en:', value: '' },
      { key: 'Chuquisaca', value: 'CH' },
      { key: 'La Paz', value: 'LP' },
      { key: 'Cochabamba', value: 'CB' },
      { key: 'Oruro', value: 'OR' },
      { key: 'Potosí', value: 'PT' },
      { key: 'Tarija', value: 'TJ' },
      { key: 'Santa Cruz', value: 'SC' },
      { key: 'Beni', value: 'BE' },
      { key: 'Pando', value: 'PD' },
    ],
  },
  {
    name: 'DateOfBirth',
    initial: '',
    validate: Yup.string(),
    type: 'date',
    label: 'Fecha de nacimiento',
  },
  {
    name: 'NationalNumber',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número'),
    type: 'number',
    label: 'Número de celular',
  },
]

export const InputCreateTracking = [
  {
    name: 'Label',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Nombre del seguimiento',
  },
  {
    name: 'type',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Tipo del campo',
    control: 'select',
    options: [
      { key: 'Selecciona el campo:', value: '' },
      { key: 'Campo de texto', value: 'text' },
      { key: 'Campo de fecha', value: 'date' },
      { key: 'Casilla de verificación', value: 'checkbox' },
      { key: 'Casilla de numero', value: 'number' },
      { key: 'Caja de texto multi-línea', value: 'textarea' },
      { key: 'Tipo archivo', value: 'file' },
    ],
  },
]
/*FORMULARIO DE INSCRIPCION A LOS ESTUDIANTES*/
export const inputEnrollCourses = [
  {
    name: 'name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombres',
  },
  {
    name: 'lastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellidos',
  },
  {
    name: 'ci',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(0, 'La cantidad mínima de nota es 0'),
    type: 'text',
    label: 'Cédula de Identidad',
  },
  {
    name: 'expeditionCI',
    initial: '',
    validate: Yup.string(),
    control: 'select',
    label: 'Expedida en : ',
    options: [
      { key: 'Expedida en:', value: '' },
      { key: 'Chuquisaca', value: 'CH' },
      { key: 'La Paz', value: 'LP' },
      { key: 'Cochabamba', value: 'CB' },
      { key: 'Oruro', value: 'OR' },
      { key: 'Potosí', value: 'PT' },
      { key: 'Tarija', value: 'TJ' },
      { key: 'Santa Cruz', value: 'SC' },
      { key: 'Beni', value: 'BE' },
      { key: 'Pando', value: 'PD' },
    ],
  },
  {
    name: 'email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
  {
    name: 'phone',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(8, 'La longitud minima es de 8 numeros'),
    type: 'text',
    label: 'Teléfono',
  },
  {
    name: 'numIndex',
    // control: 'getSelect',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Seleccione los cursos',
    // url: '/institution/3/programs',
    type: 'text',
    // keyOption: 'roomName',
    // value: 'name',
  },
  {
    name: 'PaymentPlan',
    control: 'select',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Seleccione el Plan',
    // url: 'PaymentPlan/onlyplan/104',
    options: [
      { key: 'Seleccione el plan', value: '' },
      { key: 'Plan Estudiante (Bs 100)', value: 'Plan Estudiante (Bs 100)' },
      { key: 'Plan Estudiante (Bs 120)', value: 'Plan Estudiante (Bs 120)' },
    ],
  },
  {
    name: 'amount',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      // .required('Campo Requerido')
      .min(0, 'El monto minimo es de 0'),
    type: 'text',
    label: 'Monto',
  },
  {
    name: 'invoice',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número'),
    // .required('Campo Requerido')
    // .min(0, 'La cantidad mínima de nota es 0'),
    type: 'text',
    label: 'Factura y/o Recibo',
  },
  {
    name: 'homeUniversity',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Universidad de Origen',
  },
  {
    name: 'author',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Autor',
  },
  {
    name: 'institution',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'select',
    label: 'Institucion : ',
    options: [
      { key: 'Institucion:', value: '' },
      { key: 'Universidad Tecnológica Boliviana', value: 'UTB' },
      { key: 'Capacita Global Internacional', value: 'CGI' },
    ],
  },
]
/*FORMULARIO PARA INSCRIBIR A DIPLOMADOS */
export const inputEnrollGraduates = [
  {
    name: 'name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombres',
  },
  {
    name: 'lastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellidos',
  },
  {
    name: 'ci',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(0, 'La cantidad mínima de nota es 0'),
    type: 'text',
    label: 'Cédula de Identidad',
  },
  {
    name: 'expeditionCI',
    initial: '',
    validate: Yup.string(),
    control: 'select',
    label: 'Expedida en : ',
    options: [
      { key: 'Expedida en:', value: '' },
      { key: 'Chuquisaca', value: 'CH' },
      { key: 'La Paz', value: 'LP' },
      { key: 'Cochabamba', value: 'CB' },
      { key: 'Oruro', value: 'OR' },
      { key: 'Potosí', value: 'PT' },
      { key: 'Tarija', value: 'TJ' },
      { key: 'Santa Cruz', value: 'SC' },
      { key: 'Beni', value: 'BE' },
      { key: 'Pando', value: 'PD' },
    ],
  },
  {
    name: 'email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
  {
    name: 'phone',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(8, 'La longitud minima es de 8 numeros'),
    type: 'text',
    label: 'Teléfono',
  },

  {
    name: 'PaymentPlan',
    control: 'getSelectList',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Seleccione el Plan',
    url: 'PaymentPlan/onlyplan/104',
  },
  {
    name: 'programName',
    // control: 'getSelect',
    control: 'getSelect',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    label: 'Seleccione los diplomados',
    url: '/category/1/courses',
    type: 'text',
    // keyOption: 'roomName',
    value: 'name',
    nameList: 'programs',
  },
  {
    name: 'amount',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número')
      // .required('Campo Requerido')
      .min(0, 'El monto minimo es de 0'),
    type: 'text',
    label: 'Monto',
  },
  {
    name: 'invoice',
    initial: '',
    validate: Yup.number().typeError('Eso no parece un número'),
    // .required('Campo Requerido')
    // .min(0, 'La cantidad mínima de nota es 0'),
    type: 'text',
    label: 'Factura y/o Recibo',
  },
  {
    name: 'homeUniversity',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Universidad de Origen',
  },
  {
    name: 'author',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Autor',
  },
  {
    name: 'institution',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    control: 'select',
    label: 'Institucion : ',
    options: [
      { key: 'Institucion:', value: '' },
      { key: 'Universidad Tecnológica Boliviana', value: 'UTB' },
      { key: 'Capacita Global Internacional', value: 'CGI' },
    ],
  },
]

/*EDITAR ENLACE DE WHATSAPP */
export const editWhatsAppCourseProgram = [
  {
    name: 'rlWhatsAppGroup',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Enlace de whatsapp',
  },
]

export const InputsReportWM = [
  {
    name: 'InitDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de inicio',
    dateFormat: 'MMMM d, yyyy',
  },
  {
    name: 'FinishDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de inicio',
    dateFormat: 'MMMM d, yyyy',
  },
]
export const InputsRegisterWebinar = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombres',
  },
  {
    name: 'LastName',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Apellidos',
  },
  {
    name: 'email',
    initial: '',
    validate: Yup.string()
      .email('Esto no parece un correo')
      .required('Campo requerido'),
    type: 'text',
    label: 'Correo electrónico',
  },
]

export const Preregister = [
  {
    name: 'name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombres (*)',
  },
  {
    name: 'lastname',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Apellidos',
  },
  {
    name: 'age',
    initial: '',
    validate: Yup.number(),
    type: 'text',
    label: 'Edad',
  },
  {
    name: 'ci',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Carnet',
  },
  {
    name: 'phone',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número de teléfono')
      .required('Campo requerido'),
    type: 'text',
    control: 'Phone',
    label: 'Número de celular (*)',
  },
  {
    name: 'email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
  {
    name: 'SchoolOfOrigin',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Colegio de procedencia',
  },
  {
    name: 'GraduationYear',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Año de graduación',
  },
  {
    name: 'ReferenceOfAcademicInterest',
    initial: 'todos',
    validate: Yup.string().required('Campo requerido'),
    control: 'select',
    label: 'Cursos de interes (*)',
    options: [
      { key: 'Me interesan ambos cursos', value: 'Todos' },
      { key: 'Contaduría General', value: 'Contaduría General' },
      { key: 'Comercio Internacional', value: 'Comercio Internacional' },
    ],
  },
]

export const GeneralPreregister = [
  {
    name: 'name',
    initial: '',
    validate: Yup.string().required('Campo requerido'),
    type: 'text',
    label: 'Nombres (*)',
  },
  {
    name: 'lastname',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Apellidos',
  },
  {
    name: 'age',
    initial: '',
    validate: Yup.number(),
    type: 'text',
    label: 'Edad',
  },
  {
    name: 'ci',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Carnet',
  },
  {
    name: 'phone',
    initial: '',
    validate: Yup.number()
      .typeError('Eso no parece un número de teléfono')
      .required('Campo requerido'),
    type: 'text',
    control: 'Phone',
    label: 'Número de celular (*)',
  },
  {
    name: 'email',
    initial: '',
    validate: Yup.string().email('Esto no parece un correo'),
    type: 'text',
    label: 'Correo electrónico',
  },
  {
    name: 'SchoolOfOrigin',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Colegio de procedencia',
  },
  {
    name: 'GraduationYear',
    initial: '',
    validate: Yup.string(),
    type: 'text',
    label: 'Año de graduación',
  }
]
