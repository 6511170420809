import { H4 } from '../text'
import { colors } from '../../utils/Constant'
const ColoredBoxes = ({ boxes }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '5px',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      {boxes.map((box, index) => (
        <div
          key={index}
          style={{
            background: colors[box.status],
            padding: '10px',
            borderRadius: '8px',
          }}
        >
          <H4
            margin="0 0 5px"
            fontSize="2rem"
            textAlign="center"
            fontWeight="700"
          >
            {box.title}
          </H4>
          <H4 margin="0" textAlign="center">
            {box.subTitle}
          </H4>
        </div>
      ))}
    </div>
  )
}

export default ColoredBoxes
