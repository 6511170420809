import { H4, H2 } from '../../components/text'
import { connect } from 'react-redux'
import { changeLayout } from '../../store/actions'
import { useEffect } from 'react'
var CryptoJS = require('crypto-js')
const Confirmation = (props) => {
  const { inputData, buildDataToSign } = props.location.state
  useEffect(() => {
    props.changeLayout('FullScreen')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function sign(params) {
    const keyData =
      '40e1b4abd032450c9803080cf36c65c460180316b8674604a5c2979816f179bc16746a15d8be4e4b857512f4a052315dce855815029b403ca5e240b6f77a479a32e84b23db1d45e08382b6bbb73e7cba50a95cb58857429e9997ae2e25ce88fe39bb9fc689b747efb037499e001ab0f8add16b7825f04d769f6d9f57d95ac608'
    var hash = CryptoJS.HmacSHA256(params, keyData)
    var base64 = CryptoJS.enc.Base64.stringify(hash)
    return base64
  }
  return (
    <div>
      <H2>Confirmar pedido</H2>
      <form
        id="payment_confirmation"
        action="https://testsecureacceptance.cybersource.com/pay"
        method="post"
      >
        {Object.keys(inputData).map((jsonKey, index) => (
          <H4 key={index}>
            {jsonKey}: {inputData[jsonKey]}
          </H4>
        ))}
        {Object.keys(inputData).map((jsonKey, index) => (
          <input
            type="hidden"
            id={jsonKey}
            name={jsonKey}
            value={inputData[jsonKey]}
          />
        ))}
        <input
          type="hidden"
          id="signature"
          name="signature"
          value={sign(buildDataToSign)}
        />
        <input type="hidden" id="submit" name="submit" value="Submit" />
        <input type="submit" id="submit" value="Confirm" />
      </form>
    </div>
  )
}

export default connect(null, { changeLayout })(Confirmation)
