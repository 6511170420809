import { ModalRegister } from "../../containers/shopping/ShoppingAside";
import { changeLayout, openSnackbar, changeModal } from "../../store/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
const OnlyPayment = (props) => {
  const { changeLayout } = props;

  useEffect(() => {
    changeLayout("FullScreen");
  }, []);

  return (
    <div style={{ maxWidth: "800px", margin: "0 auto" }}>
      <ModalRegister
        {...props}
        dataShopping={{ total: 144, currency: "USD" }}
        urlPost="/membership"
        orderResult="only-payment-result"
        dataPayment={{ Plan: "Monthly" }}
        disabledPaymentType={true}
      />
      <br />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, {
  changeLayout,
  openSnackbar,
  changeModal,
})(OnlyPayment);
