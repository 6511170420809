import { validateArray } from '../../utils/Validation'
import DataType from './DataType'
const CellMobile = (props) => {
  const {
    cell,
    id,
    HandleActivate,
    header,
    activate,
    colorCell,
    reload,
  } = props
  return (
    <>
      <div className="TableDefault__container">
        <div
          style={
            colorCell.validate(cell, header)
              ? {
                  background: colorCell.background,
                  color: colorCell.color,
                }
              : {}
          }
          className={`TableDefault__cell ${
            activate === id ? 'TableDefault__cell-activate' : ''
          }`}
        >
          {validateArray(header)
            ? header.map((a, i) => {
                return (
                  <div
                    onClick={
                      a.type === 'actions'
                        ? () => {}
                        : () => HandleActivate(id, cell)
                    }
                    className="TableDefault__column"
                    key={i}
                  >
                    <h2 className="TableDefault__head">{a.name}</h2>
                    <DataType a={a} key={i} head={cell} reload={reload} />
                  </div>
                )
              })
            : null}
        </div>
      </div>
      <hr />
    </>
  )
}

export default CellMobile
