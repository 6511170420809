import { GlobalForm } from '../../../components/form'
import { InputsEditProgram } from '../../../utils/Constant'
import { capitalizeJson } from '../../../utils/ProcessData'
import { UseOnSubmit } from '../../../hooks/useOnSubmit'
import { useState } from 'react'
const FormEditProgram = (props) => {
  const { program, openSnackbar, reload, changeInfo, pid } = props
  const [load, setload] = useState(true)
  const onSubmit = async (value) => {
    setload(false)
    try {
      await UseOnSubmit({
        axiosParams: {
          method: 'PUT',
          url: `/program/${pid}`,
          data: {
            ...value,
            IsRoot: program.isRoot,
            VersionName: program.versionName,
            ProgramManagerId: program.programManager.id,
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: 'Se editó la programa',
        textWrong: 'No se pudo editar',
        functionSuccess: reload,
        process: true,
      })
    } catch (error) {
      console.log(error)
    }
    setload(true)
  }
  return (
    <div>
      <GlobalForm
        InputsJson={InputsEditProgram}
        textBtn="Guardar cambios"
        title="Editar programa"
        data={capitalizeJson({
          ...program,
          LanguageCode: program.language?.code,
        })}
        load={load}
        onSubmit={onSubmit}
        onCancel={() => changeInfo(<></>)}
      />
    </div>
  )
}

export default FormEditProgram
