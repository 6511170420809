import { useAxiosAuth } from '../../../../hooks'
import { UseOnSubmit } from '../../../../hooks/useOnSubmit'
import { validateArray, validateStatus } from '../../../../utils/Validation'
import {
  inputEditTeacher,
  inputEditPhoto,
} from '../../../../utils/FormParameters'
import { capitalizeJson } from '../../../../utils/ProcessData'
import { BoxFlex } from '../../../../components/boxes'
import { Loading } from '../../../../components/animation'
import { RenderButton } from '../../../../components/buttons'
import { H3, H4 } from '../../../../components/text'
import { FormDefault } from '../../../../components/form'
import { useState } from 'react'
import { parsePhoneNumber } from 'react-phone-number-input'
const ListTeachers = (props) => {
  const { cid, changeModal, changeInfo, openSnackbar } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/course/${cid}/teachers`,
  })
  function handleDelete(item) {
    changeModal(
      true,
      '¿Estás seguro que quieres eliminar al docente del curso?',
      '',
      'Eliminar',
      () => onDelete(item),
    )
  }
  function processData(values) {
    const codeNumber = parsePhoneNumber(values.Phone)
    const data = {
      ...values,
      Country: codeNumber.country,
      CountryCode: codeNumber.countryCallingCode,
      NationalNumber: codeNumber.nationalNumber,
    }
    return data
  }

  function processDataImg(values) {
    let formData = new FormData()
    formData.append('File', values.PhotoUrl)
    return formData
  }
  function handleReload() {
    reload()
    changeInfo(<></>)
  }
  function handleEdit(item) {
    changeInfo(
      <FormDefault
        inputs={inputEditTeacher}
        onCancel={() => changeInfo(<></>)}
        method="PUT"
        url={`/users/${item.id}/professorUpdate`}
        openSnackbar={openSnackbar}
        title="Editar curso"
        handleReload={handleReload}
        data={capitalizeJson({ ...item, Firstname: item.name })}
        processData={processData}
      />,
    )
  }
  function handleEditImg(item) {
    changeInfo(
      <FormDefault
        inputs={inputEditPhoto}
        onCancel={() => changeInfo(<></>)}
        method="POST"
        url={`/users/${item.id}/photos`}
        openSnackbar={openSnackbar}
        title="Editar foto de perfil"
        handleReload={handleReload}
        data={{}}
        processData={processDataImg}
      />,
    )
  }

  async function onDelete(item) {
    try {
      await UseOnSubmit({
        axiosParams: {
          method: 'PUT',
          url: `/userRol/professor/${item.id}/course/${cid}/deleted`,
        },
        openSnackbar: props.openSnackbar,
        textSuccess: 'Programa Eliminado',
        textWrong: 'No se pudo eliminar',
        functionSuccess: props.reloadPedido,
        process: true,
      }).then((res) => {
        if (validateStatus(res.status)) {
          reload()
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  if (loading) {
    return <Loading />
  } else {
    return validateArray(response) ? (
      response.map((item) => (
        <CardTeacher
          item={item}
          key={item.id}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleEditImg={handleEditImg}
        />
      ))
    ) : (
      <H3>El curso no tiene docentes asignados</H3>
    )
  }
}

export const CardTeacher = (props) => {
  const { item, handleDelete, handleEdit, handleEditImg } = props
  const [view, setview] = useState(false)

  return (
    <div style={{ display: 'flex', gap: '8px', margin: '10px 0' }}>
      <div
        style={{
          width: '100%',
          padding: '10px',
          borderRadius: '10px',
          background: 'rgb(230, 230, 230)',
          cursor: 'pointer',
        }}
        onClick={() => setview(!view)}
      >
        <BoxFlex justify="flex-start">
          <div
            style={{
              width: '80px',
              height: '80px',
              backgroundImage: `url('${item.photoUrl}')`,
              backgroundSize: 'cover',
              borderRadius: '50%',
              border: '1px solid gray',
            }}
          ></div>
          <div className="">
            <H3 margin="0 0 10px">
              {item.name} {item.lastName}
            </H3>
            <H4 margin="0 0 5px">{item.profession}</H4>
            {item.nationalNumber && item.nationalNumber !== '0' ? (
              <H4 margin="0">Celular: {item.nationalNumber}</H4>
            ) : null}
          </div>
        </BoxFlex>
        {item.description ? (
          view ? (
            <>
              <H4 margin="5px 0" innerHTML>
                {item.description}
              </H4>
              <H3 textAlign="center" margin="0">
                Ver menos...
              </H3>
            </>
          ) : (
            <H3 textAlign="center" margin="0">
              Ver mas...
            </H3>
          )
        ) : null}
      </div>
      <RenderButton
        border="1px solid #458e7f"
        color="#458e7f"
        fontSize="2rem"
        background="#458e7f77"
        onClick={() => handleEditImg(item)}
      >
        <i className="fas fa-portrait"></i>
      </RenderButton>
      <RenderButton
        border="1px solid #e3ad2d"
        color="#e3ad2d"
        fontSize="1.8rem"
        background="#e3ad2d77"
        onClick={() => handleEdit(item)}
      >
        <i className="fas fa-edit"></i>
      </RenderButton>
      <RenderButton
        border="1px solid #c93f3fa1"
        color="#c93f3f"
        fontSize="2rem"
        background="#c93f3fa1"
        onClick={() => handleDelete(item)}
      >
        <i className="fas fa-times"></i>
      </RenderButton>
    </div>
  )
}

export default ListTeachers
