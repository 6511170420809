import { useState } from 'react'
import { BoxFlex } from '../../components/boxes'

import { H3, H4 } from '../../components/text'
import { convertDate } from '../../utils/ConvertDate'
import { capitalizeFirstLetter } from '../../utils/Validation'
import UserInstitutionAdmin from '../institutions/UserInstitution/UserInstitutionAdmin'

const Users = (props) => {
  const preConfig = (list) => {
    list.forEach((l) => {
      l.firstName_ = (
        <>
          <H4 color="var(--purple)">
            {capitalizeFirstLetter(l.firstName)}{' '}
            {l.lastName && capitalizeFirstLetter(l.lastName)}
          </H4>{' '}
          <H3 color="gray">{l.Telephone}</H3>
        </>
      )
      l.directionAndPhone = (
        <>
          <BoxFlex justify="space-around">
            <span>{l.email}</span>
          </BoxFlex>
          <BoxFlex justify="space-around">
            <span>{l.telephone}</span>
          </BoxFlex>
        </>
      )
      l.ci_ = (
        <>
          <BoxFlex justify="space-between">
            <span>
              {l.ci === 0 ? (
                '--'
              ) : (
                <>
                  {l.ci} {l.expeditionCi}
                </>
              )}
            </span>
          </BoxFlex>
        </>
      )
      l.lastActive_ = <span>{convertDate(l.lastActive)}</span>
      l.dateOfBirth_ = <span>{convertDate(l.dateOfBirth, 'YYYY-MM-DD')}</span>
    })
    return list
  }
  return (
    <div>
      <UserInstitutionAdmin preConfig={preConfig} {...props} />
    </div>
  )
}

export default Users
