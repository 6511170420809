import { RenderModal } from '../../../../../../components/modal'
import { H2, SubtitleData } from '../../../../../../components/text'
import { GlobalForm } from '../../../../../../components/form'
import { LoadCircle } from '../../../../../../components/animation'
import { useAxiosAuth } from '../../../../../../hooks'
import { UseOnSubmit } from '../../../../../../hooks/useOnSubmit'
import { validateArray } from '../../../../../../utils/Validation'
import { ParticipationQualify } from '../../../../../../utils/FormParameters'
import RenderComment from './RenderComment'
import { useState } from 'react'
import './ForumReview.css'
const ForumReview = (props) => {
  const {
    forumId,
    idUser,
    closeReview,
    openSnackbar,
    isEducator,
    match,
  } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/forum/${forumId}/user/${idUser}/participations`,
  })
  const { response: score, loading: load2, reload: reloadScore } = useAxiosAuth(
    {
      method: 'GET',
      url: `/forum/userScore/${idUser}/lesson/${match.params.id}`,
    },
  )
  function handleReload() {
    reload()
    reloadScore()
  }
  return (
    <RenderModal closeOutside onClose={closeReview}>
      <br />
      <H2 textAlign="center">Calificar aporte</H2>
      {load2 ? (
        <LoadCircle />
      ) : (
        <>
          <SubtitleData subtitle="Correo electrónico:">
            {score.email}
          </SubtitleData>
          <SubtitleData subtitle="Nombres:">{score.name}</SubtitleData>
          <SubtitleData subtitle="Apellidos:">{score.lastName}</SubtitleData>
          <SubtitleData subtitle="Nota final:">{score.score}</SubtitleData>
        </>
      )}
      {loading ? (
        <LoadCircle />
      ) : (
        <>
          {validateArray(response.mainComments)
            ? response.mainComments
                .map((comment) => (
                  <ComentCheck
                    key={comment.participationId}
                    comment={comment}
                    openSnackbar={openSnackbar}
                    isEducator={isEducator}
                    reload={handleReload}
                    url={`/participation/${comment.participationId}/qualify`}
                  />
                ))
                .reverse()
            : null}
          <hr />
          {validateArray(response.subComments)
            ? response.subComments.map((comment, index) => (
                <div key={index} className="ComentCheck">
                  <RenderComment myComment={false} commentData={comment} />
                  {comment.subComments
                    .map((subComment) => (
                      <ComentCheck
                        key={subComment.replyParticipationId}
                        comment={subComment}
                        openSnackbar={openSnackbar}
                        isEducator={isEducator}
                        reload={handleReload}
                        url={`/participation/replyParticipation/${subComment.replyParticipationId}/qualify`}
                      />
                    ))
                    .reverse()}
                </div>
              ))
            : null}
        </>
      )}
    </RenderModal>
  )
}
function ComentCheck({ comment, openSnackbar, isEducator, reload, url }) {
  const [viewForm, setviewForm] = useState(false)
  const [load, setload] = useState(true)
  function handleViewForm() {
    setviewForm(!viewForm)
  }

  async function onSubmitCheck(values) {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: 'PUT',
        url: url,
        data: values,
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Se califico el aporte',
      textWrong: 'No se pudo calificar',
      functionSuccess: reload,
    })
    setload(true)
  }
  return (
    <div className="ComentCheck">
      <RenderComment
        review
        isEducator={isEducator}
        myComment={false}
        commentData={comment}
      />
      {viewForm ? (
        <div className="ComentCheck__form">
          <i onClick={handleViewForm} className="fas fa-times"></i>
          <GlobalForm
            InputsJson={ParticipationQualify}
            textBtn="Calificar participación"
            title=""
            data={{ Valoration: comment.valoration }}
            load={load}
            onSubmit={onSubmitCheck}
            onCancel={handleViewForm}
          />
        </div>
      ) : (
        <h5 onClick={handleViewForm} className="Comment__Manchor">
          <i className="fas fa-spell-check"></i> <span>Calificar</span>
        </h5>
      )}
    </div>
  )
}
export default ForumReview
