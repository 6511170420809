import './ListDocument.css'
import { H2, H3, SubtitleData } from '../../components/text'
import { FormDefault, GlobalForm } from '../../components/form'
import { DefaultBtn, ActionButton } from '../../components/buttons'
import { Loading } from '../../components/animation'
import { validateArray, validateStatus } from '../../utils/Validation'
import { inputEditFile, inputFile } from '../../utils/FormParameters'
import { UseOnSubmit } from '../../hooks/useOnSubmit'
import { useAxiosAuth } from '../../hooks'
import { useState } from 'react'
import { CardInformation } from '../institutions/InstitutionPrereg'
import { capitalizeJson } from '../../utils/ProcessData'
import { putAction } from '../../services/action/ActionAuthorization'
const ListDocument = (props) => {
  const {
    course,
    changeModal,
    openSnackbar,
    onCancel = () => changeModal(false, null, null),
  } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/preregisterquiz/${course.preregisterId}/files`,
  })
  function processData(values) {
    let formData = new FormData()
    formData.append('File', values.File)
    formData.append('Description', values.Description)
    formData.append('Type', 'payment_boucher')
    formData.append('PreregisterQuizFileId', values.PreregisterQuizFileId)
    formData.append('Observation', values.Observation)
    formData.append('Nit', values.NIT)
    formData.append('Nitname', values.Nitname)
    return formData
  }

  const [load] = useState(true)

  function handleOnSubmit(values, preregisterQuizFileId) {
    putAction(`/preregisterquiz/PreregisterQuizFile`, {
      ...values,
      PreregisterQuizFileId: preregisterQuizFileId,
    }).then((response) => {
      if (response && validateStatus(response.status)) {
        openSnackbar('Datos editados', true, true)
        changeModal(true, '', <ListDocument {...props} />, null)
      } else {
        openSnackbar('Hubo un error', true, false)
      }
    })
  }
  function handleFormFile(data) {
    changeModal(
      true,
      '',
      data.preregisterQuizFileId === undefined ? (
        <FormDefault
          inputs={inputFile}
          onCancel={() =>
            changeModal(true, '', <ListDocument {...props} />, null)
          }
          data={capitalizeJson(data)}
          method={data.PreregisterQuizFileId ? 'PUT' : 'POST'}
          url={`/preregisterquiz/${course.preregisterId}/uploadFile`}
          openSnackbar={openSnackbar}
          processData={processData}
          title="Adicionar documento"
        />
      ) : (
        <GlobalForm
          InputsJson={inputEditFile}
          textBtn="Editar"
          title="Editar Datos "
          data={capitalizeJson(data)}
          load={load}
          onSubmit={(values) =>
            handleOnSubmit(values, data.preregisterQuizFileId)
          }
          onCancel={() =>
            changeModal(true, '', <ListDocument {...props} />, null)
          }
        />
      ),
      null,
    )
  }

  return (
    <>
      <H2 color="var(--gray)" margin="0">
        Documentos adjuntos
      </H2>
      <div className="ListDocument__list">
        {loading ? (
          <Loading />
        ) : validateArray(response) ? (
          response.map((item) => (
            <>
              <br />
              <CardInformation title="">
                <SubtitleData subtitle="NIT">{item.nit}</SubtitleData>
                <SubtitleData subtitle="Nombre de NIT">
                  {item.nitName}
                </SubtitleData>
                <SubtitleData subtitle="Observación: ">
                  {item.observation}
                </SubtitleData>
                <SubtitleData subtitle="Observacion: "></SubtitleData>

                <SubtitleData subtitle="">
                  <div className="ListDocument__item">
                    <a
                      key={item.id}
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.description}
                    </a>
                    <EditBtn
                      item={item}
                      openSnackbar={openSnackbar}
                      reload={reload}
                      onClick={() => handleFormFile(item)}
                    />
                    <DeleteBtn
                      item={item}
                      openSnackbar={openSnackbar}
                      reload={reload}
                    />
                  </div>
                </SubtitleData>
              </CardInformation>
            </>
          ))
        ) : (
          <H3 margin="5px 0">No tienes documentos adjuntos</H3>
        )}
      </div>
      <ActionButton
        onClick={handleFormFile}
        onCancel={onCancel}
        textBtn="Subir documento"
        textCancel="Volver"
      />
    </>
  )
}
const DeleteBtn = ({ item, openSnackbar, reload }) => {
  const [load, setload] = useState(true)
  async function handleDeleteFile(file) {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: 'DELETE',
        url: `/file/${file.id}`,
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Se elimino el documento con éxito',
      textWrong: 'No se pudo eliminar',
      functionSuccess: reload,
    })
    setload(true)
  }
  if (load) {
    return (
      <DefaultBtn onClick={() => handleDeleteFile(item)}>
        <i className="fas fa-trash"></i>
      </DefaultBtn>
    )
  } else {
    return <Loading />
  }
}

const EditBtn = ({ onClick }) => {
  return (
    <DefaultBtn onClick={onClick}>
      <i className="fas fa-pen"></i>
    </DefaultBtn>
  )
}

export default ListDocument
