import Homeworkcheckit from './Homeworkcheckit'
import { H2 } from '../../../../../components/text'
import { useAxiosAuth } from '../../../../../hooks'
import { urlAvatar } from '../../../../../utils/Constant'
import { DefaultBtn } from '../../../../../components/buttons'
import { ColoredBoxes } from '../../../../../components/boxes'
import { LoadCircle } from '../../../../../components/animation'
import { ListExtensionsLesson } from '../../../../../containers/programs'
import { TableOptions } from '../../../../../components/table'
const header = [
  // { key: 'photoUrl', name: 'Avatar', type: 'img' },
  { key: 'name', name: 'Nombre Completo' },
  { key: 'email', name: 'Correo' },
  {
    key: 'presented',
    name: 'Estado',
    type: 'textColor',
    color: {
      'Sin presentar': '#c93f3f75',
      Presentado: '#46958575',
    },
  },
  {
    key: 'score',
    name: 'Calificación',
    type: 'textColor',
    color: {
      'Sin calificar': '#c93f3f75',
    },
  },
]
const headerSearch = [
  { key: 'name', name: 'Nombre ' },
  { key: 'email', name: 'Correo' },
]
const TableHomework = (props) => {
  const { lid, changeInfo, openSnackbar, isAdmin, changeModal, ...rest } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `lesson/${lid}/homeworksAccountant`,
  })
  function preConfig(list) {
    let auxList = []
    list.forEach((u) => {
      if (u.name === ' ') {
        u.name = 'N/A'
      }
      if (!u.photoUrl) {
        u.photoUrl = urlAvatar
      }
      if (u.score === -1) {
        u.score = 'Sin calificar'
      }
      let aux = 'Sin presentar'
      if (u.homeworks) {
        if (u.homeworks.length > 0) {
          aux = 'Presentado'
        }
      }
      auxList.push({ ...u, presented: aux })
    })
    return auxList
  }
  function handleReload() {
    reload()
    changeInfo(<></>)
  }
  function qualify(user, reload) {
    changeInfo(
      <Homeworkcheckit
        openSnackbar={openSnackbar}
        lid={lid}
        reload={reload}
        user={user}
        changeInfo={changeInfo}
        isAdmin={isAdmin}
        changeModal={changeModal}
        
        {...rest}
      />,
    )
  }
  const buttonExtensions = (
    <DefaultBtn
      onClick={() =>
        changeInfo(
          <ListExtensionsLesson
            users={response}
            lid={lid}
            changeInfo={changeInfo}
            openSnackbar={openSnackbar}
            changeModal={changeModal}
          />,
        )
      }
    >
      Listar prórrogas{' '}
      <i style={{ fontSize: '1.4rem' }} className="fas fa-hourglass-half"></i>
    </DefaultBtn>
  )
  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <H2>Entregas</H2>
      <ColoredBoxes
        boxes={[
          {
            title: response.finished,
            subTitle: 'Presentados',
            status: 'green',
          },
          {
            title: response.scored,
            subTitle: 'Calificados',
            status: 'green',
          },
          {
            title: response.unfinished,
            subTitle: 'No presentados',
            status: 'red',
          },
          {
            title: response.total,
            subTitle: 'Estudiantes',
            status: 'gray',
          },
        ]}
      />
      <br />
      {/* <TableDefault
        handleInfo={qualify}
        header={header}
        main={preConfig(response.homework)}
        buttonAdd={buttonExtensions}
      /> */}
      <TableOptions
        param={`/lesson/${lid}/Homeworks`}
        header={header}
        /* param={`/lesson/${lid}/homeworksAccountant`} */
        /*listStatus={listStatus} */
        /* listOrder={listOrder} */
        choice={true}
        handleReload={reload}
        handleInfo={qualify}
        headerSearch={headerSearch}
        buttonAdd={buttonExtensions}
        preConfig={preConfig}
      />
    </>
  )
}

export default TableHomework
