import { useEffect } from 'react'
import { putAction } from '../../services/action/ActionAuthorization'
const OrderResult = (props) => {
  const params = new URLSearchParams(props.location.search)

  useEffect(() => {
    async function onSubmit() {
      await putAction('/order/paymentComplete', {
        Order_id: params.get('result'),
        Transaction_id: params.get('order_id'),
        UserId: window.localStorage.getItem('id'),
      }).then(() => {
        // setuseAxios({
        //   response: response.data,
        //   loading: false,
        //   status: response.status,
        // })
        props.history.push('/programs')
      })
    }
    if (params) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return <></>
}

export default OrderResult
