import { GlobalForm } from '../../../components/form'
import { InputsEditCourse } from '../../../utils/Constant'
import { validateStatus } from '../../../utils/Validation'
import { capitalizeJson } from '../../../utils/ProcessData'
import { convertD, isValidFormat } from '../../../utils/ConvertDate'
import { useState } from 'react'
import { putAction } from '../../../services/action/ActionAuthorization'
const EditCourse = (props) => {
  const { course, changeInfo, openSnackbar, reload } = props
  const [load, setload] = useState(true)
  const onSubmit = (values) => {
    setload(false)
    let formData = new FormData()
    for (let key in values) {
      if (values[key]) {
        if (isValidFormat(values[key])) {
          formData.append(key, convertD(values[key]))
        } else {
          formData.append(key, values[key])
        }
      }
    }
    putAction(`/course/${course.id}`, formData).then((response) => {
      setload(true)
      if (response && validateStatus(response.status)) {
        openSnackbar('Se guardaron los cambios', true, true)
        reload()
      } else {
        openSnackbar('No se pudo guardar', true, false)
      }
    })
  }
  return (
    <GlobalForm
      InputsJson={InputsEditCourse}
      textBtn="Guardar cambios"
      title="Editar curso"
      data={capitalizeJson(course)}
      load={load}
      onSubmit={onSubmit}
      onCancel={() => changeInfo(<></>)}
    />
  )
}

export default EditCourse
