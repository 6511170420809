import './EditorView.css'
import { convertString2 } from '../../utils/Validation'
import { deleteCharacteres2, removeQuote } from '../../utils/ProcessData'

import Blocks from 'editorjs-blocks-react-renderer'
const EditorView = ({ data, cutText }) => {
  function validateData(data) {
    try {
      const auxConvert = deleteCharacteres2(data)
      const objeto = convertString2(auxConvert)
      if (cutText && objeto.blocks.length > 1) {
        return (
          <Blocks
            config={{
              table: {
                className: 'table',
              },
            }}
            data={{ ...objeto, blocks: [objeto.blocks[0], objeto.blocks[1]] }}
          />
        )
      } else {
        return (
          <Blocks
            config={{
              table: {
                className: 'table',
              },
            }}
            data={objeto}
          />
        )
      }
    } catch (error) {
      if (cutText) {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.substring(0, 150) + `${data.length > 150 ? '...' : ''} `,
            }}
          ></div>
        )
      } else {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: removeQuote(data),
            }}
          ></div>
        )
      }
    }
  }
  return <div className="EditorView">{validateData(data)}</div>
}

export default EditorView
