import { FormDefault } from '../../../components/form'
import { H3, H2 } from '../../../components/text'
import { BoxFlex, ToolTip } from '../../../components/boxes'
import { DefaultBtn, RenderButton } from '../../../components/buttons'
import * as Yup from 'yup'
import { useState } from 'react'
import { validateArray } from '../../../utils/Validation'
import { FormIncriptionCourse } from './FormIncriptionCourse'
import { FormInscriptionDiplomat } from './FormInscriptionDiplomat'
const FormInscription = (props) => {
  const { changeInfo, openSnackbar } = props
  const [result, setresult] = useState(null)
  function processDataImg(values) {
    let formData = new FormData()
    formData.append('File', values.File)
    return formData
  }
  function handleSuccess(data) {
    setresult(data)
  }
  function formSignOn() {
    changeInfo(
      <div
        className="addEstudentsDiplomados"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexFlow: 'column',
          gap: '10px',
        }}
      >
        <DefaultBtn
          onClick={() =>
            changeInfo(
              <FormIncriptionCourse
                {...props}
                onClose={() => changeInfo(<FormInscription {...props} />)}
              />,
            )
          }
        >
          Inscribir a cursos
        </DefaultBtn>
        <DefaultBtn
          onClick={() =>
            changeInfo(
              <FormInscriptionDiplomat
                {...props}
                onClose={() => changeInfo(<FormInscription {...props} />)}
              />,
            )
          }
        >
          Inscribir a diplomados{' '}
        </DefaultBtn>
        <DefaultBtn
          onClick={() => changeInfo(<FormInscription {...props} />)}
          type="button"
          background={true}
        >
          {'Cancelar'}
        </DefaultBtn>
      </div>,
    )
  }
  return (
    <div>
      <BoxFlex wrap="wrap-noreverse" justify="space-between">
        <H2 margin="0" color="var(--gray)">
          Formulario de inscripción
        </H2>
        <a
          href="https://res.cloudinary.com/pataformacgi/raw/upload/v1665589682/assets/Plantilla_LMS_fuboql.xlsx"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'var(--orange2)' }}
        >
          <H3
            textAlign="start"
            margin="0"
            fontSize="1.4rem"
            color="var(--orange2)"
          >
            Descargar plantilla
          </H3>
        </a>
        <a
          download="Lista de Cursos"
          href="https://res.cloudinary.com/pataformacgi/image/upload/v1663266132/assets/photo_4974431608014744082_y_hs8sqj.jpg"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'var(--orange2)' }}
        >
          <H3
            textAlign="start"
            margin="0"
            fontSize="1.4rem"
            color="var(--orange2)"
          >
            Descargar Lista de Cursos
          </H3>
        </a>
        <ToolTip content="Inscribir Estudiante">
          <div className="">
            <RenderButton
              onClick={() => formSignOn()}
              background="var(--green)"
            >
              <i className="fas fa-edit"></i>
            </RenderButton>
          </div>
        </ToolTip>
      </BoxFlex>
      <br />
      <FormDefault
        inputs={[
          {
            name: 'File',
            control: 'file',
            initial: null,
            validate: Yup.string().required('Campo requerido').nullable(),
            label: 'Selecciona un documento excel',
            // type: 'image/*',
          },
        ]}
        onCancel={() => changeInfo(<></>)}
        method="POST"
        url="/program/excelDataReader"
        openSnackbar={openSnackbar}
        handleReload={handleSuccess}
        data={{}}
        processData={processDataImg}
      />
      {result && (
        <>
          <BoxFlex justify="space-between" margin="10px 0">
            <H2 textAlign="center" margin="0" color="var(--gray)">
              Resultados del registro
            </H2>
            <RenderButton
              onClick={() => setresult(null)}
              padding="8px 10px 4px"
              borderRadius="50%"
              background="var(--red)"
            >
              <i className="fas fa-times"></i>
            </RenderButton>
          </BoxFlex>
          {validateArray(result.error) && (
            <>
              <H3 margin="0">
                Errores{' '}
                <span
                  style={{
                    background: 'var(--red)',
                    color: 'var(--white)',
                    padding: '2px 5px 0',
                    borderRadius: '5px',
                  }}
                >
                  {result.error.length}
                </span>
              </H3>
              {result.error.map((item, index) => (
                <div
                  key={index}
                  style={{
                    border: '1px solid var(--gray)',
                    padding: '5px',
                    borderRadius: '5px',
                    margin: '5px 0 10px',
                  }}
                >
                  <BoxFlex justify="flex-end" margin="0 0 10px">
                    <H3
                      margin="0"
                      padding="2px"
                      background="var(--red)"
                      color="var(--white)"
                      fontWeight="500"
                      borderRadius="10px"
                    >
                      {item.userEnroll}
                    </H3>
                    <H3
                      margin="0"
                      padding="2px"
                      background="var(--red)"
                      fontWeight="500"
                      color="var(--white)"
                      borderRadius="10px"
                    >
                      {item.programUserEnroll}
                    </H3>
                  </BoxFlex>
                  <H3 margin="0">{item.programName}</H3>
                  <H3 margin="5px 0 0">
                    {item.name} {item.lastname}
                  </H3>
                </div>
              ))}
            </>
          )}
          {validateArray(result.success) && (
            <>
              <H3 margin="0">
                Aciertos{' '}
                <span
                  style={{
                    background: 'var(--green)',
                    color: 'var(--white)',
                    padding: '2px 5px 0',
                    borderRadius: '5px',
                  }}
                >
                  {result.success.length}
                </span>
              </H3>
              {result.success.map((item, index) => (
                <div
                  key={index}
                  style={{
                    border: '1px solid var(--gray)',
                    padding: '5px',
                    borderRadius: '5px',
                    margin: '5px 0 10px',
                  }}
                >
                  <BoxFlex justify="flex-end" margin="0 0 10px">
                    <H3
                      margin="0"
                      padding="2px"
                      background="var(--green)"
                      color="var(--white)"
                      fontWeight="500"
                      borderRadius="10px"
                    >
                      {item.userEnroll}
                    </H3>
                    <H3
                      margin="0"
                      padding="2px"
                      background="var(--green)"
                      fontWeight="500"
                      color="var(--white)"
                      borderRadius="10px"
                    >
                      {item.programUserEnroll}
                    </H3>
                  </BoxFlex>
                  <H3 margin="0">{item.programName}</H3>
                  <H3 margin="5px 0 0">
                    {item.name} {item.lastname}
                  </H3>
                </div>
              ))}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default FormInscription
