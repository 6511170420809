import { validateArray } from "../../../../../utils/Validation";
import { LinkCard } from "../../../../../components/card";
import { H3 } from "../../../../../components/text";
import { useActionParam } from "../../../../../hooks";
import { getAction } from "../../../../../services/action/ActionAuthorization";
const LessonMaterial = ({ params }) => {
  const { dataRequest } = useActionParam({
    action: getAction,
    param: `course/${params.cid}/Material`,
  });
  return (
    <div className="ComentContent__Mlinks">
      {validateArray(dataRequest) ? (
        dataRequest.map((mod) => {
          if (mod.moduleId + "" === params.mid) {
            return validateArray(mod.files) ? (
              mod.files.map((file, index) => (
                <LinkCard key={index} type={file.type} dataLink={file} />
              ))
            ) : (
              <H3>No hay materiales complementarios</H3>
            );
          } else {
            return null;
          }
        })
      ) : (
        <H3>No hay materiales complementarios</H3>
      )}
    </div>
  );
};

export default LessonMaterial;
