import './Kids.css'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  changeLayout,
  changeInfo,
  changeModal,
  openSnackbar,
} from '../../store/actions'
import { H2, H3 } from '../../components/text'
import { Loading } from '../../components/animation'
import { useAxiosAuth } from '../../hooks'
import { validateArray } from '../../utils/Validation'
import { avatarChild } from '../../utils/Constant'
import ContainerKid from '../../containers/kids/ContainerKid'
const Kids = (props) => {
  const { changeLayout, changeInfo } = props
  const [user, setuser] = useState(null)
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/users/detailChildForUser?userId=${window.localStorage.getItem(
      'id',
    )}`,
  })
  useEffect(() => {
    changeLayout('Information')
  }, [changeLayout])

  useEffect(() => {
    if (!loading && validateArray(response)) {
      setuser(response[0])
      changeInfo(<ContainerKid kid={response[0]} {...props} />)
    }
  }, [loading, response])

  function handleKid(kid) {
    setuser(kid)
    changeInfo(<ContainerKid kid={kid} {...props} />)
  }
  return (
    <div>
      <H2 color="var(--black)">Mis niños</H2>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((kid) => (
          <CardKid
            onClick={() => handleKid(kid)}
            user={user}
            key={kid.id}
            kid={kid}
          />
        ))
      ) : (
        <H3>No tienes a nadie en tu lista</H3>
      )}
    </div>
  )
}

const CardKid = ({ kid, onClick, user }) => {
  return (
    <div
      onClick={onClick}
      style={{
        border: `1px solid ${
          user && user.id === kid.id ? 'var(--green)' : 'var(--white)'
        }`,
      }}
      className="CardKid"
    >
      <div
        className="CardKid__photo"
        style={{
          backgroundImage: `url("${
            kid.photoUrl ? kid.photoUrl : avatarChild
          }")`,
        }}
      ></div>
      <div className="CardKid__main">
        <H3 margin="0">
          {kid.name} {kid.lastName}
        </H3>
        <H3 margin="10px 0 0" fontWeight="500">
          Usuario: {kid.username}
        </H3>
      </div>
    </div>
  )
}

export default connect(null, {
  changeLayout,
  changeInfo,
  changeModal,
  openSnackbar,
})(Kids)
