import { useState } from 'react'
import { validateStatus } from '../../../../../../utils/Validation'
import { urlAvatar } from '../../../../../../utils/Constant'
import EditorView from '../../../../../../components/editorjs/EditorView'
import { ButtonIcon } from '../../../../../../components/buttons'
import { H4 } from '../../../../../../components/text'
import { ImgDefault } from '../../../../../../components/image'
import { IconOptions } from '../../../../../../components/buttonsIcon'
import {
  deleteAction,
  putAction,
} from '../../../../../../services/action/ActionAuthorization'
import FormComment from './FormComment'
import ForumReview from './ForumReview'

const RenderComment = (props) => {
  const { myComment, match, ...rest } = props
  const [review, setreview] = useState({
    idUser: 0,
    modal: false,
  })
  function closeReview() {
    setreview({
      idUser: 0,
      modal: false,
    })
  }
  function openReview(uid) {
    setreview({
      idUser: uid,
      modal: true,
    })
  }
  return (
    <>
      {myComment ? (
        <ViewCommentUser {...rest} />
      ) : (
        <ViewComment {...rest} openReview={openReview} />
      )}
      {review.modal && (
        <ForumReview
          idUser={review.idUser}
          closeReview={closeReview}
          forumId={props.forumId}
          openSnackbar={props.openSnackbar}
          isEducator={props.isEducator}
          match={match}
        />
      )}
    </>
  )
}
function ViewCommentUser(props) {
  const {
    main = false,
    sizeResponse = 0,
    commentData,
    upReload,
    reload,
    sesionUser,
    changeModal,
    openSnackbar,
    isFinishDate,
  } = props
  const [edit, setedit] = useState(false)
  function handleEdit() {
    setedit(!edit)
  }
  function handleValidation() {
    if (sizeResponse > 0) {
      changeModal(
        true,
        'No puedes eliminar un comentario que ya tiene respuestas',
        '',
        '',
      )
    } else {
      changeModal(
        true,
        '¿Estas seguro que quieres eliminar este comentario?',
        '',
        'Eliminar',
        main
          ? () =>
              handleDelete(
                `participation/${commentData.participationId}`,
                upReload,
              )
          : () =>
              handleDelete(
                `/participation/${commentData.replyParticipationId}/replyParticipation`,
                reload,
              ),
      )
    }
  }
  function handleDelete(url, reloadComponent) {
    deleteAction(url).then((response) => {
      if (response && validateStatus(response.status)) {
        openSnackbar('Comentario eliminado', true, true)
        reloadComponent()
      } else {
        openSnackbar('No se pudo eliminar', true, false)
      }
    })
  }
  return edit ? (
    <FormComment
      url={
        main
          ? `/participation/${commentData.participationId}`
          : `/participation/replyParticipation/${commentData.replyParticipationId}`
      }
      reload={main ? upReload : reload}
      handleClose={handleEdit}
      textBtn="Editar aporte"
      openSnackbar={openSnackbar}
      action={putAction}
    />
  ) : (
    <>
      <div className="ViewComment__head">
        <div className="Comment__Mheader">
          <ImgDefault
            url={commentData?.photoUrl ? commentData.photoUrl : urlAvatar}
            alt="avatar"
            currentSize={{ width: 35 }}
          />
          <H4 margin="0">
            {commentData.name} {commentData.lastName}
          </H4>
        </div>
        <div className="Comment__Mheader">
          {commentData.userId === sesionUser.user.id && !isFinishDate ? (
            <IconOptions
              noBackground={!main}
              content={
                <>
                  <ButtonIcon
                    onClick={handleEdit}
                    content="Editar"
                    icon="edit"
                  />
                  <ButtonIcon
                    onClick={() => handleValidation()}
                    content="Eliminar"
                    icon="trash"
                  />
                </>
              }
              icon={`ellipsis-${main ? 'h' : 'v'}`}
            />
          ) : null}
        </div>
      </div>
      {commentData.commentText && <EditorView data={commentData.commentText} />}
    </>
  )
}

function ViewComment({ commentData, isEducator, openReview, review = false }) {
  return (
    <>
      <div className="ViewComment__head">
        <div className="Comment__Mheader">
          <ImgDefault
            url={commentData?.photoUrl ? commentData.photoUrl : urlAvatar}
            alt="avatar"
            currentSize={{ width: 35 }}
          />
          <H4 margin="0">
            {commentData.name} {commentData.lastName}
          </H4>
        </div>
        <div className="Comment__Mheader">
          {isEducator &&
            (!review ? (
              <ButtonIcon
                onClick={() => openReview(commentData.userId)}
                content="Calificar"
                icon="tasks"
              />
            ) : (
              <H4 border="1px solid var(--gray)" margin="0" fontSize="1.4rem">
                Calificación {commentData.valoration}
              </H4>
            ))}
        </div>
      </div>
      {commentData.commentText && <EditorView data={commentData.commentText} />}
    </>
  )
}
export default RenderComment
