import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormikControl } from "../input";
import "./style/GraduateSignUp.css";
import { NormalButton } from "../buttons";
import { getPreSignUp } from "../../services/action/CourseAction";
import { Loading } from "../animation";
import { openSnackbar } from "../../store/actions";
import { connect } from "react-redux";
import { useState } from "react";
import {
  postSeminarAuth,
  postSeminar,
} from "../../services/action/SeminarsAction";
import { useActionParam } from "../../hooks";
import { validateStatus } from "../../utils/Validation";
import { calculateAge } from "../../utils/ConvertDate";
const dropdownOptions = [
  { key: "Expedida en:", value: "" },
  { key: "Chuquisaca", value: "CH" },
  { key: "La Paz", value: "LP" },
  { key: "Cochabamba", value: "CB" },
  { key: "Oruro", value: "OR" },
  { key: "Potosí", value: "PT" },
  { key: "Tarija", value: "TJ" },
  { key: "Santa Cruz", value: "SC" },
  { key: "Beni", value: "BE" },
  { key: "Pando", value: "PD" },
];
const CourseSignUp = (props) => {
  const {
    sesionUser: { user },
    // gid,
    id,
    openSnackbar,
    // handleProgress,
    // graduate,
    handelSend,
  } = props;
  const [load, setload] = useState(true);
  const { dataRequest } = useActionParam({
    action: getPreSignUp,
    param: id,
  });
  // useEffect(() => {
  //   if (dataRequest) {
  //     handleProgress(0);
  //     if (dataRequest.files.length >= 3) {
  //       handleProgress(1);
  //     }
  //   }
  // }, [dataRequest]);

  function validate(param, json) {
    let aux = "";
    if (json) {
      if (json[param]) {
        aux = json[param];
      }
    }
    return aux;
  }
  function save(res) {
    setload(true);
    let msj = "register_successful";
    if (validateStatus(res.status)) {
      openSnackbar(msj, true, true);
      handelSend(res.data);
    } else {
      if (res.data.length < 30) {
        msj = res.data;
      } else {
        msj = "save_failed";
      }
      openSnackbar(msj, true, false);
    }
  }

  const onSubmit = async (values) => {
    try {
      setload(false);
      const { Age, ...rest } = values;
      const userId = window.localStorage.getItem("id");
      if (userId) {
        await postSeminarAuth({
          ...rest,
          userId,
        }).then((response) => {
          save(response);
        });
      } else {
        await postSeminar(rest).then((response) => {
          save(response);
        });
      }
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };
  const initialValues = {
    Name: validate("name", dataRequest) || validate("firstName", user),
    LastName: validate("lastName", dataRequest) || validate("lastName", user),
    Age: validate("idDocument", dataRequest) || validate("idDocument", user),
    Zone:
      validate("idExpedition", dataRequest) || validate("idExpedition", user),
    PhoneNumber:
      validate("phoneNumber", dataRequest) || validate("phoneNumber", user),
    Email: validate("email", dataRequest) || validate("email", user),
    BirthDate:
      validate("BirthDate", dataRequest) || validate("BirthDate", user),
    Profession:
      validate("Profession", dataRequest) || validate("Profession", user),
    IdDocument:
      validate("idDocument", dataRequest) || validate("idDocument", user),
    IdExpedition:
      validate("idExpedition", dataRequest) || validate("idExpedition", user),
    SignedCourseId: id,
  };
  const validationSchema = Yup.object({
    Name: Yup.string().required("Campo Requerido"),
    LastName: Yup.string().required("Campo Requerido"),
    Age: Yup.number().typeError("Eso no parece un número"),
    // .required("Campo Requerido"),
    Zone: Yup.string().required("Campo Requerido"),
    PhoneNumber: Yup.number()
      .typeError("Eso no parece un número de teléfono")
      .required("Campo Requerido"),
    Email: Yup.string()
      .email("Formato de correo inválido")
      .required("Campo Requerido"),
    BirthDate: Yup.date().required("Required").nullable(),
    Profession: Yup.string().required("Campo Requerido"),
    IdDocument: Yup.number()
      .typeError("Eso no parece un número")
      .required("Campo Requerido"),
    IdExpedition: Yup.string().required("Campo Requerido"),
  });
  return (
    <div className="GraduateSignUp">
      <center>
        <h2 className="GraduateSignUp__title">Formulario de registro</h2>
      </center>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form className="GraduateSignUp__form">
              <h4>Datos del seminario</h4>
              {/* <h5>Seminario al que se esta Inscribiendo:</h5> */}
              <center>
                <h3 style={{ fontSize: "2rem", color: "var(--orange2)" }}>
                  WORKSHOP DE LÍDERES LA PAZ 2021
                </h3>
              </center>
              <h4>Datos Generales</h4>
              <div className="GraduateSignUp__Fname">
                <FormikControl
                  control="input"
                  type="text"
                  label="Apellidos"
                  name="LastName"
                  disabled={
                    validate("lastName", dataRequest) ||
                    validate("lastName", user)
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Nombres"
                  name="Name"
                  disabled={
                    validate("name", dataRequest) || validate("firstName", user)
                      ? true
                      : false
                  }
                />
              </div>
              <div className="GraduateSignUp__Fci">
                <FormikControl
                  control="input"
                  type="text"
                  label="Documento de Identidad"
                  name="IdDocument"
                  disabled={
                    validate("idDocument", dataRequest) ||
                    validate("idDocument", user)
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="select"
                  label="Expedida en"
                  name="IdExpedition"
                  options={dropdownOptions}
                  disabled={
                    validate("idExpedition", dataRequest) ||
                    validate("idExpedition", user)
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Número de Whatsapp"
                  name="PhoneNumber"
                  disabled={
                    validate("phoneNumber", dataRequest) ||
                    validate("phoneNumber", user)
                      ? true
                      : false
                  }
                />
              </div>
              <div className="GraduateSignUp__Fonly">
                <FormikControl
                  control="input"
                  type="email"
                  label="Correo Electrónico"
                  name="Email"
                  disabled={
                    validate("email", dataRequest) || validate("email", user)
                      ? true
                      : false
                  }
                />
              </div>
              <h4>Datos Complementarios</h4>
              <div className="GraduateSignUp__Fname">
                <FormikControl
                  control="input"
                  type="text"
                  label="Formación académica"
                  name="Profession"
                  disabled={
                    validate(
                      "profession",
                      dataRequest ? dataRequest.contact : undefined
                    )
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Zona en la que vive"
                  name="Zone"
                  disabled={
                    validate("idDocument", dataRequest) ||
                    validate("idDocument", user)
                      ? true
                      : false
                  }
                />
              </div>
              <div className="GraduateSignUp__Fname">
                <FormikControl
                  control="input"
                  type="date"
                  label="Fecha de nacimiento"
                  name="BirthDate"
                  disabled={
                    validate(
                      "dateOfBirth",
                      dataRequest ? dataRequest.contact : undefined
                    )
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="input"
                  label="Edad"
                  name="Age"
                  disabled={true}
                  value={calculateAge(formik.values.BirthDate)}
                />
              </div>
              {load ? (
                <center>
                  <br />
                  <NormalButton type="submit">
                    Enviar Datos de Registro
                  </NormalButton>
                </center>
              ) : (
                <Loading />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, { openSnackbar })(CourseSignUp);
