import { connect } from 'react-redux'
import { changeLayout, changeInfo } from '../../store/actions'
import { useAxios } from '../../hooks'
import { validateArray } from '../../utils/Validation'
import { convertD } from '../../utils/ConvertDate'
import { LoadCircle } from '../../components/animation'
import { H2, H3, H4, SubtitleData } from '../../components/text'
import { NormalLink } from '../../components/buttons'
import { useEffect } from 'react'
import './Certificate.css'
const Certificate = (props) => {
  const {
    changeLayout,
    changeInfo,
    match: { params },
  } = props
  useEffect(() => {
    changeLayout('BigInformation')
  }, [changeLayout])
  const { response, loading } = useAxios({
    method: 'GET',
    url: `/certificate/lessonUser/${params.luid}`,
  })
  useEffect(() => {
    if (response?.urlCertificate) {
      changeInfo(<CertificateAside urlCertificate={response.urlCertificate} />)
    }
  }, [changeInfo, response])

  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <br />
      <H2 textAlign="center">Verificación de certificado</H2>
      {response ? (
        <>
          <br />
          <H2>Datos del usuario</H2>
          <SubtitleData color="var(--green)" subtitle="Nombres">
            {response.name}
          </SubtitleData>
          <SubtitleData color="var(--green)" subtitle="Apellidos">
            {response.lastName}
          </SubtitleData>
          <br />
          <H2>Detalles del curso</H2>
          <SubtitleData color="var(--green)" subtitle="Nombre del curso">
            {response.programManagerName}
          </SubtitleData>
          <SubtitleData color="var(--green)" subtitle="Fecha de inicio">
            {convertD(response.startedDate, 'LL')}
          </SubtitleData>
          <SubtitleData color="var(--green)" subtitle="Fecha de conclusión">
            {convertD(response.finishedDate, 'LL')}
          </SubtitleData>
          <br />
          <H2>Certificado</H2>
          <SubtitleData color="var(--green)" subtitle="Enlace del certificado">
            <a
              style={{ color: 'var(--green)' }}
              href={response.urlCertificate}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ver el certificado
            </a>
          </SubtitleData>
          {validateArray(response.moduleOrCourseList) ? (
            <>
              <br />
              <H2>Contenido del curso</H2>
              {response.moduleOrCourseList.map((course, index) => (
                <div
                  style={{
                    border: '1px solid var(--green)',
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '4px',
                  }}
                  key={index}
                >
                  <H4 margin="0">{course.name}</H4>
                </div>
              ))}
            </>
          ) : null}
        </>
      ) : (
        <>
          <H3 margin="10px 0 20px">Certificado no encontrado</H3>
          <center>
            <NormalLink to="/">Volver al inicio</NormalLink>
          </center>
        </>
      )}
    </>
  )
}
const CertificateAside = ({ urlCertificate }) => {
  return (
    <div className="CertificateAside">
      <iframe
        src={urlCertificate}
        width="640"
        height="480"
        title="frame"
      ></iframe>
      {/* <PDFViewer
        document={{
          url: urlCertificate,
          withCredentials: false,
        }}
      /> */}
    </div>
  )
}
export default connect(null, {
  changeLayout,
  changeInfo,
})(Certificate)
