import { useState } from "react";
import { validateArray, validateStatus } from "../../../../utils/Validation";
import { SimpleInput, TextError } from "../../../../components/input";
import { ActionButton } from "../../../../components/buttons";
import { Loading } from "../../../../components/animation";
import { H2 } from "../../../../components/text";
import { putAction } from "../../../../services/action/ActionAuthorization";
const EditWeighting = (props) => {
  const { lessons, openSnackbar, cid, onCancel } = props;
  const [weighting, setweighting] = useState(lessons);
  const [load, setload] = useState(true);
  const onSubmit = async (values) => {
    let body = [];
    setload(false);
    values.forEach((lesson) => {
      body.push({ LessonId: lesson.id, Percentage: lesson.scorePercentage });
    });
    putAction(`/Course/${cid}/ScorePercentage`, body).then((response) => {
      setload(true);
      if (validateStatus(response.status)) {
        openSnackbar("Se guardaron los cambios", true, true);
        onCancel();
      } else {
        openSnackbar("No se pudo guardar", true, false);
      }
    });
  };
  function handleWeighting(e) {
    const { name, value } = e.target;
    let aux = weighting;
    aux[name] = {
      ...aux[name],
      scorePercentage: value,
    };
    setweighting(() => [...aux]);
  }
  function handleDisabled() {
    let booleanDisabled = false;
    let total = 0;
    weighting.forEach((lesson) => {
      if (lesson.scorePercentage > 100) {
        booleanDisabled = true;
      }
      let numberScore =
        lesson.scorePercentage === "" ? 0 : parseFloat(lesson.scorePercentage);
      total = total + numberScore;
    });
    if (total > 100) {
      booleanDisabled = true;
    }
    if (total < 100) {
      booleanDisabled = true;
    }
    return booleanDisabled;
  }
  return (
    <>
      <H2 textAlign="center">Editar ponderaciones</H2>
      {validateArray(weighting)
        ? weighting.map((lesson, index) => {
            return (
              <SimpleInput
                key={lesson.id}
                name={index}
                type="number"
                label={lesson.name}
                onChange={(event) => handleWeighting(event)}
                value={lesson.scorePercentage}
                err={
                  lesson.scorePercentage === "" ||
                  lesson.scorePercentage > 100 ||
                  lesson.scorePercentage < 0
                    ? "El ponderación debe estar en el rango de 0 a 100 "
                    : ""
                }
              />
            );
          })
        : null}
      {handleDisabled() && (
        <TextError>
          La suma de las ponderaciones es mayor o menor a 100
        </TextError>
      )}
      {load ? (
        <ActionButton
          disabled={handleDisabled() ? true : false}
          onClick={() => onSubmit(weighting)}
          //   changeInfo(
          //     <EditWeighting
          //       cid={cid}
          //       openSnackbar={openSnackbar}
          //       changeInfo={changeInfo}
          //       lessons={dataRequest}
          //     />
          //   )
          // }
          onCancel={onCancel}
          textBtn="Editar ponderación"
          textCancel="Cancelar"
        />
      ) : (
        <Loading />
      )}
    </>
  );
};
export default EditWeighting;
