import "./Programs.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import {
  changeInfo,
  changeLayout,
  changeModal,
  openSnackbar,
} from "../../store/actions";
import { validateArray, validateEducator } from "../../utils/Validation";
import { H2, H3 } from "../../components/text";
import { BoxFlex, ToolTip } from "../../components/boxes";
import { Loading } from "../../components/animation";
import { Icon, DefaultBtn } from "../../components/buttons";
import { withRouter } from "react-router-dom";
import { useAxiosAuth } from "../../hooks";
import { processUrlImage } from "../../utils/ProcessData";
import { convertD, dataLock } from "../../utils/ConvertDate";
import config from "../../config.json";
import { ContentSchedule } from "../../containers/programs";

const Programs = (props) => {
  const {
    changeLayout,
    sesionUser: { user, rols },
    changeModal,
  } = props;

  const [pag, setpag] = useState(1);
  const [pag2, setpag2] = useState(1);
  const [search, setsearch] = useState("");
  const {
    response: respProgram,
    loading: respLoad,
    reload: respReload,
    header: respHeader,
  } = useAxiosAuth({
    method: "GET",
    url: `/program/user/${user.id}/pag?PageNumber=${pag}${
      search ? `&ForQuery=${search}&TypeQuery=name` : ""
    }${
      config.domainURL === "https://utbaulavirtual.com"
        ? "&institutionId=3"
        : ""
    }`,
  });

  const { response, loading, reload, header } = useAxiosAuth({
    method: "GET",
    url: `/course/user/${user.id}/pag?PageNumber=${pag2}${
      search ? `&ForQuery=${search}&TypeQuery=name` : ""
    }${
      config.domainURL === "https://utbaulavirtual.com"
        ? "&institutionId=3"
        : ""
    }`,
  });

  function handleReload() {
    reload();
    respReload();
  }
  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pag2]);

  useEffect(() => {
    respReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pag]);

  function generatePage(header) {
    if (header && header.pagination) {
      return JSON.parse(header.pagination);
    }
    return null;
  }
  const headerInfo = generatePage(respHeader);
  const headerInfo2 = generatePage(header);
  useEffect(() => {
    changeLayout("FullScreen");
  }, [changeLayout]);

  const credendtials = {
    token: localStorage.getItem("token_seguridad"),
    id: localStorage.getItem("id"),
  };

  return (
    <div className="Programs">
      <BoxFlex>
        <SearchContainer setsearch={setsearch} onClick={handleReload} />
        <Icon
          handleModal={() =>
            changeModal(
              true,
              "",
              <ContentSchedule onClose={() => changeModal(false, "")} />,
              null
            )
          }
          ico="calendar-day"
        />
      </BoxFlex>
      <br />
      {respLoad ? (
        <Loading />
      ) : (
        validateArray(respProgram) && (
          <>
            <BoxFlex justify="flex-end">
              <div style={{ width: "90%" }}>
                <H2 textAlign="center">MIS PROGRAMAS</H2>
              </div>
              <BoxFlex justify="flex-end" direction="row">
                <Icon
                  handleModal={() => setpag(pag - 1)}
                  disabled={headerInfo.currentPage === 1 ? true : false}
                  ico="chevron-left"
                />
                <Icon
                  disabled={headerInfo.totalPages <= pag ? true : false}
                  handleModal={() => setpag(pag + 1)}
                  ico="chevron-right"
                />
              </BoxFlex>
            </BoxFlex>
            <br />
            <div className="Programs__list">
              {validateArray(respProgram) &&
                respProgram.map((course) => (
                  <CardProgram
                    {...props}
                    data={course}
                    key={course.id}
                    to={
                      course.institutionName === "UTB"
                        ? `${config.domainURL2}/program/${course.id}?iduser=${credendtials.id}&token=${credendtials.token}`
                        : `/program/${course.id}`
                    }
                    redirect={course.institutionName === "UTB"}
                  />
                ))}
            </div>
          </>
        )
      )}
      <br />
      {loading ? (
        <Loading />
      ) : (
        validateArray(response) && (
          <>
            <BoxFlex justify="flex-end">
              <div style={{ width: "90%" }}>
                <H2 textAlign="center">MIS CURSOS</H2>
              </div>
              <BoxFlex justify="flex-end" direction="row">
                <Icon
                  handleModal={() => setpag2(pag2 - 1)}
                  disabled={headerInfo2.currentPage === 1 ? true : false}
                  ico="chevron-left"
                />
                <Icon
                  disabled={headerInfo2.totalPages <= pag2 ? true : false}
                  handleModal={() => setpag2(pag2 + 1)}
                  ico="chevron-right"
                />
              </BoxFlex>
            </BoxFlex>
            <br />
            <div className="Programs__list">
              {response.map((course) => (
                <CardCourses
                  {...props}
                  data={course}
                  key={course.id}
                  // to={`/program/course/${course.id}/module/1${
                  //   validateEducator(rols, course.id) ? '/educator' : ''
                  // }`}
                  to={
                    course.institutionName === "UTB"
                      ? `${config.domainURL2}/program/course/${
                          course.id
                        }/module/${
                          validateArray(course.moduleToList)
                            ? course.moduleToList[0].id
                            : "1"
                        }${
                          validateEducator(rols, course.id) ? "/educator" : ""
                        }?iduser=${credendtials.id}&token=${credendtials.token}`
                      : `/program/course/${course.id}/module/${
                          validateArray(course.moduleToList)
                            ? course.moduleToList[0].id
                            : "1"
                        }${
                          validateEducator(rols, course.id) ? "/educator" : ""
                        }`
                  }
                  redirect={course.institutionName === "UTB"}
                />
              ))}
            </div>
          </>
        )
      )}
      {!loading &&
      !respLoad &&
      !validateArray(response) &&
      !validateArray(respProgram) ? (
        <H3>No se encontraron cursos</H3>
      ) : null}
    </div>
  );
};
const SearchContainer = ({ setsearch, onClick }) => {
  function onChange(e) {
    const { value } = e.target;
    setsearch(value);
  }
  return (
    <div className="SearchContainer__main">
      <div className="searchContainer">
        <i className="fas fa-search"></i>
        <input
          type="text"
          name="search"
          className="searchContainer__input"
          placeholder="Buscar..."
          onChange={onChange}
        />
      </div>
      <DefaultBtn onClick={onClick} color="var(--green)">
        Buscar <i className="fas fa-search"></i>
      </DefaultBtn>
    </div>
  );
};
const CardProgram = (props) => {
  const { data, to, redirect } = props;
  const [modal] = useState(false);
  const ProgramContent = ({ children }) => {
    if (redirect) {
      return (
        <a target="_self" href={to} rel="noreferrer">
          {children}
        </a>
      );
    } else {
      return <Link to={to}>{children}</Link>;
    }
  };
  return (
    <div className="CardCourses">
      <ProgramContent>
        <div
          style={{
            backgroundImage: `url('${processUrlImage(
              data.photoUrl
                ? data.photoUrl
                : "https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986593/diplomados/fotos/06GestionTributaria_hadppb.png",
              250
            )}')`,
          }}
          className="CardCourses__head"
        >
          <H3
            position="relative"
            color="var(--white)"
            margin="0"
            fontSize="2rem"
            textTransform="uppercase"
          >
            {data.name}
          </H3>
          <H3
            fontSize="2rem"
            position="relative"
            color="var(--white)"
            opacity="0.6"
          >
            {data.versionName}
          </H3>
        </div>
      </ProgramContent>
      {/*ICONO DE WHATSAPP PARA LOS ESTUDIANTES PROGRAMAS*/}
      {data.whatsappGroupUrl === null ? null : (
        <div className="">
          <button className="CardCourses__btn">
            <ToolTip
              content={`${
                data.whatsappGroupUrl === null
                  ? "No tiene enlace de whatsApp"
                  : "Enalace de whatsApp"
              }`}
            >
              <a
                href={`${
                  data.whatsappGroupUrl === null ? " " : data.whatsappGroupUrl
                }`}
                target={`${data.whatsappGroupUrl === null ? "" : "_blank"}`}
              >
                <i
                  style={{ fontSize: "24px", color: "white" }}
                  className="fab fa-whatsapp"
                ></i>
              </a>
            </ToolTip>
          </button>
        </div>
      )}
      <ProgramContent>
        <div className="CardCourses__main">
          {modal ? (
            <div className="CardCourses__modal">
              {validateArray(data.moduleToList) ? (
                data.moduleToList.map((mod, index) => (
                  <Fragment key={mod.id}>
                    <BoxFlex justify="flex-start" wrap="no">
                      <div className="CardCourses__Mnumber">
                        <H3
                          margin="5px 0 0"
                          textAlign="center"
                          fontWeight="900"
                          color="var(--purple)"
                        >
                          {index + 1}
                        </H3>
                      </div>
                      <H3 margin="0" color="var(--black)">
                        {mod.name}
                      </H3>
                    </BoxFlex>
                    <div style={{ height: "5px" }}></div>
                    <BoxFlex justify="flex-start" gap="20px">
                      <H3 fontWeight="500" margin="0" color="var(--black)">
                        <i className="fas fa-film"></i> {mod.countVideos} videos
                      </H3>
                      <H3 fontWeight="500" margin="0" color="var(--black)">
                        <i className="far fa-clock"></i> {mod.durationVideos}{" "}
                        min
                      </H3>
                    </BoxFlex>
                    <Progress
                      width="95%"
                      margin="5px 0 0"
                      percentage={mod.percentage}
                    />
                    <br />
                  </Fragment>
                ))
              ) : (
                <H3 margin="0" color="var(--black)">
                  No tienes módulos
                </H3>
              )}
            </div>
          ) : (
            <div>
              <H3 margin="10px">Progreso:</H3>
              <Progress percentage={data.programeUserToList.percentage} />
            </div>
          )}
        </div>
      </ProgramContent>
    </div>
  );
};
const CardCourses = (props) => {
  const { data, to, redirect } = props;
  const [modal, setmodal] = useState(false);

  const CourseContent = ({ children }) => {
    if (redirect) {
      return (
        <a target="_self" href={to} rel="noreferrer">
          {children}
        </a>
      );
    } else {
      return <Link to={to}>{children}</Link>;
    }
  };

  return (
    <div className="CardCourses">
      <CourseContent>
        <div
          style={{
            backgroundImage: `url('${processUrlImage(
              data.photoUrl
                ? data.photoUrl
                : "https://res.cloudinary.com/dzxmzxvbr/image/upload/v1607986593/diplomados/fotos/06GestionTributaria_hadppb.png",
              250
            )}')`,
          }}
          className="CardCourses__head"
        >
          <H3
            position="relative"
            color="var(--white)"
            margin="0"
            fontSize="2rem"
            textTransform="uppercase"
          >
            {data.name}
          </H3>
          {!dataLock(data.startDate) && (
            <H3
              position="relative"
              color="#d6d6d6"
              margin="0"
              fontSize="1.6rem"
            >
              Fecha de inicio: {convertD(data.startDate, "L")}
            </H3>
          )}
        </div>
      </CourseContent>
      {/* <div className="">
        <button onClick={() => setmodal(!modal)} className="CardCourses__btn">
          <i className={`fas fa-chevron-${!modal ? 'down' : 'up'}`}></i>
        </button>
      </div> */}
      {/*ICONO DE WHATSAPP PARA LOS ESTUDIANTES*/}
      {data.whatsappGroupUrl === null ? null : (
        <div className="">
          <button className="CardCourses__btn">
            <ToolTip
              content={`${
                data.whatsappGroupUrl === null
                  ? "No tiene enlace de whatsApp"
                  : "Enalace de whatsApp"
              }`}
            >
              <a
                href={`${
                  data.whatsappGroupUrl === null ? " " : data.whatsappGroupUrl
                }`}
                target={`${data.whatsappGroupUrl === null ? "" : "_blank"}`}
              >
                <i
                  style={{ fontSize: "24px", color: "white" }}
                  className="fab fa-whatsapp"
                ></i>
              </a>
            </ToolTip>
          </button>
        </div>
      )}
      <CourseContent>
        <div className="CardCourses__main">
          {modal ? (
            <div className="CardCourses__modal">
              {validateArray(data.moduleToList) ? (
                data.moduleToList.map((mod, index) => (
                  <Fragment key={mod.id}>
                    <BoxFlex justify="flex-start" wrap="no">
                      <div className="CardCourses__Mnumber">
                        <H3
                          margin="5px 0 0"
                          textAlign="center"
                          fontWeight="900"
                          color="var(--purple)"
                        >
                          {index + 1}
                        </H3>
                      </div>
                      <H3 margin="0" color="var(--black)">
                        {mod.name}
                      </H3>
                    </BoxFlex>
                    <div style={{ height: "5px" }}></div>
                    <BoxFlex justify="flex-start" gap="20px">
                      <H3 fontWeight="500" margin="0" color="var(--black)">
                        <i className="fas fa-film"></i> {mod.countVideos} videos
                      </H3>
                      <H3 fontWeight="500" margin="0" color="var(--black)">
                        <i className="far fa-clock"></i> {mod.durationVideos}{" "}
                        min
                      </H3>
                    </BoxFlex>
                    <Progress
                      width="95%"
                      margin="5px 0 0"
                      percentage={mod.percentage}
                    />
                    <br />
                  </Fragment>
                ))
              ) : (
                <H3 margin="0" color="var(--black)">
                  No tienes módulos
                </H3>
              )}
            </div>
          ) : (
            <div>
              <H3 margin="10px">Progreso:</H3>
              <Progress percentage={data.userRolToList.percentage} />
            </div>
          )}
        </div>
      </CourseContent>
    </div>
  );
};
const Progress = ({ percentage, width = "80%", margin = "0 auto 10px" }) => {
  return (
    <div
      style={{
        width: width,
        margin: margin,
        background: `linear-gradient(90deg, var(--green) ${percentage}%, #F8F8F8 ${percentage}%)`,
      }}
      className="ProgressBar"
    ></div>
  );
};
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, {
  changeInfo,
  changeLayout,
  changeModal,
})(withRouter(Programs));
