import GlobalForm from './GlobalForm'
import { InputsFile } from '../../utils/FormParameters'
const FormFile = (props) => {
  const { onClose, handleInquiry } = props
  return (
    <div>
      <GlobalForm
        InputsJson={InputsFile}
        textBtn="Elegir"
        title="Elegir imagen"
        
        data={{}}
        load={true}
        onSubmit={handleInquiry}
        onCancel={onClose}
      />
    </div>
  )
}

export default FormFile
