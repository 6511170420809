import './Catalogue.css'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeLayout } from '../../store/actions'
import { NavCatalogue } from '../../components/links'
import { H3 } from '../../components/text'
import { validateArray } from '../../utils/Validation'
import { LoadCircle } from '../../components/animation'
import RouteCatalogue from '../../routes/RouteCatalogue'
import { useAxios } from '../../hooks'
// import {
//   SimpleCard,
//   SimpleCardWithSimpleImage,
//   SimpleCard2,
// } from './SubCategories/SimpleCard'

const Catalogue = ({ changeLayout }) => {
  const { response, loading } = useAxios({
    method: 'GET',
    url: '/category',
  })
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])
  // const listColor = ['#458e7f', '#6a3081', '#d17742']
  return (
    <div className="Catalogue">
      <div className="Catalogue__head">
        {/* <div className="Catalogue__navigation"></div> */}
        {/* {loading ? (
            <LoadCircle />
          ) : validateArray(response) ? (
            response
              .map((category, index) =>
                category.url !== 'courses-children' ? (
                  <NavCatalogue
                    key={category.id}
                    color={listColor[index % 3]}
                    url={
                      category.url === 'courses-children'
                        ? 'https://www.capacitakids.com/'
                        : `/catalogue/${category.url}/${category.id}`
                    }
                  >
                    {category.name}
                  </NavCatalogue>
                ) : null,
              )
              .reverse()
          ) : (
            <H3>Contenido no disponible</H3>
          )} */}
        {/* <NavCatalogue color="#d17742" url="/catalogue/graduatesUTB">
            Postgrados
          </NavCatalogue>
          <NavCatalogue
            color="#6a3081"
            type="anchor"
            url="https://aulacapacitaglobal.com/cursos-cortos-utb/"
          >
            PREGRADOS
          </NavCatalogue>
          <NavCatalogue url="/catalogue/courses-children">
            CURSOS - NIÑ@S
          </NavCatalogue> */}
        {/* <NavCatalogue color="#d17742" url="/catalogue/seminars">
            Seminarios
          </NavCatalogue> */}
      </div>
      {loading ? <LoadCircle /> : <RouteCatalogue response={response} />}
      {/* <SimpleCard />

      <SimpleCardWithSimpleImage /> */}
    </div>
  )
}
export default connect(null, { changeLayout })(Catalogue)
