import { H2, H3 } from '../../components/text'
import { Loading } from '../../components/animation'
import { useAxiosAuth } from '../../hooks'
import { validateArray } from '../../utils/Validation'
import { convertD } from '../../utils/ConvertDate'
const ContentSchedule = () => {
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/courseSchedule/student`,
  })

  return (
    <div>
      <H2 textAlign="center" color="var(--gray)">
        Horarios
      </H2>
      <H3 fontWeight="500">*Clases virtuales pendientes</H3>
      {loading ? (
        <Loading />
      ) : (
        validateArray(response) && (
          <>
            {response.map((item) => {
              if (validateArray(item.schedules)) {
                return <ScheduleCourse key={item.courseId} item={item} />
              } else {
                return null
              }
            })}
          </>
        )
      )}
    </div>
  )
}

const ScheduleCourse = ({ item }) => {
  const AnchorRender = ({ children, link }) => {
    if (link) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="ScheduleCourse__classDate"
        >
          {children}
        </a>
      )
    } else {
      return <div className="ScheduleCourse__classDate">{children}</div>
    }
  }
  return (
    <>
      <H3 color="var(--gray)">{item.courseName}</H3>
      <div className="ScheduleCourse__list">
        {item.schedules.map((item, index) => {
          if (index < 4) {
            return (
              <AnchorRender link={item.link}>
                <H3 margin="0 0 5px" fontWeight="600">
                  {convertD(item.classDayDate, 'LL')}
                </H3>
                <div className="ScheduleCourse__classTime">
                  <H3 margin="0" fontWeight="500">
                    {item.classDay}
                  </H3>
                  <H3 margin="0" fontWeight="500">
                    {convertD(item.startClass, 'HH:mm', 'HH:mm:ss')} -{' '}
                    {convertD(item.finishClass, 'HH:mm', 'HH:mm:ss')}
                  </H3>
                  <H3 textAlign="cen" margin="0" fontWeight="500">
                    {item.classDay}
                  </H3>
                </div>
                <div className="ScheduleCourse__classTime">
                  <H3 margin="0" fontWeight="500">
                    Enlace:
                  </H3>
                  {item.link ? (
                    <a href="http://" target="_blank" rel="noopener noreferrer">
                      <H3 color="var(--orange2)" margin="0" fontWeight="500">
                        Enlace de la reunión
                      </H3>
                    </a>
                  ) : (
                    <H3 margin="0" fontWeight="500">
                      Sin enlace
                    </H3>
                  )}
                </div>
              </AnchorRender>
            )
          } else {
            return null
          }
        })}
      </div>
    </>
  )
}

export default ContentSchedule
