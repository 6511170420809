import './UsersUTB.css'
import { connect } from 'react-redux'
import { TableOptions } from '../../../components/table/'
import { changeInfo, openSnackbar } from '../../../store/actions'
import { InstitutionPrereg } from '../index'

const UsersUTB = ({
  changeInfo,
  openSnackbar,
  param,
  title = 'Usuarios pre-registrados',
  hideTitle = false,
  header: headerProp,
}) => {
  const header = [
    { key: 'name', name: 'Nombre' },
    { key: 'lastName', name: 'Apellidos' },
    { key: 'authorName', name: 'Pregistrado Por' },
    { key: 'signedProgramManagerName', name: 'Programa', type: 'list' },
    { key: 'status', name: 'Estado' },
  ]
  const headerSearch = [
    { key: 'email', name: 'Correo' },
    { key: 'name', name: 'Nombre' },
    { key: 'lastName', name: 'Apellidos' },
  ]
  const listOrder = [
    { key: 'created', name: 'Fecha de pre-inscripción' },
    { key: 'lastName', name: 'Apellido' },
    { key: 'program', name: 'Programa' },
  ]
  const listStatus = [
    { key: '', name: 'Todos' },
    { key: 'completed', name: 'Completado' },
    { key: 'delisted', name: 'Cancelado' },
    { key: 'in_process', name: 'En proceso' },
    { key: 'receipt', name: 'Con comprobante' },
    { key: 'receiptInProcess', name: 'En proceso con comprobante' },
    { key: 'receiptCompleted', name: 'Completado con comprobante' },
  ]

  function preConfig(list) {
    return list.map((item) => ({
      ...item,
      signedProgramManagerName: item.coursesName
        ? item.coursesName
        : item.signedProgramManagerName,
    }))
  }
  return (
    <div className="UserInstitution">
      {!hideTitle && <h2 className="UserInstitution__title">{title}</h2>}

      <TableOptions
        param={param ? param : '/PreRegisterQuiz'}
        header={headerProp ? headerProp : header}
        listOrder={listOrder}
        choice={true}
        handleInfo={(user, handlereload) =>
          changeInfo(
            <InstitutionPrereg
              reload={handlereload}
              user={user}
              openSnackbar={openSnackbar}
              changeInfo={changeInfo}
            />,
          )
        }
        preConfig={preConfig}
        headerSearch={headerSearch}
        listStatus={listStatus}
      />
    </div>
  )
}

export default connect(null, { changeInfo, openSnackbar })(UsersUTB)
