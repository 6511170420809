import { useEffect } from 'react'
import { changeLayout } from '../../store/actions'
import { connect } from 'react-redux'
import error404 from '../../assets/img/error-404.png'
import { Link } from 'react-router-dom'
import './404.css'

const CourseNotFound = ({ changeLayout }) => {
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])

  return (
    <div className="page404__home">
      <div className="page404__home-img">
        <img src={error404} alt="text-error" />
      </div>
      <div className="page404__home-text">
        No estás inscrito al curso, Prueba nuevamente o pulsa el siguiente
        botón.
        <br />
        <br />
        <Link to="/programs">Mis cursos</Link>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  changeLayout,
}
export default connect(null, mapDispatchToProps)(CourseNotFound)
