import React from 'react'
import './style/ExternalLogin.css'

import GoogleLogin from 'react-google-login'
import { postAction } from '../../services/action/Action'
import { openSnackbar, startSession } from '../../store/actions'
import config from '../../config.json'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const Google = ({ openSnackbar, startSession, history }) => {
  const responseGooglef = () => {
    openSnackbar('error_start', true, false)
  }

  const responseGoogle = async (response) => {
    const usuario = {
      RegisterOption: 'Google',
      AccessToken: response.accessToken,
      UserID: response.googleId,
      ExpiresIn: response.tokenObj.expires_in,
      ReauthorizeRequiredIn: '',
      Email: response.profileObj.email,
      FirstName: response.profileObj.givenName,
      LastName: response.profileObj.familyName,
      PhotoUrl: response.profileObj.imageUrl,
    }
    await postAction(
      '/auth/registerExternalLogin',
      config.domainURL === 'https://utbaulavirtual.com'
        ? { ...usuario, institutionId: 3 }
        : usuario,
    ).then((res) => {
      if (res.status === 200 && res) {
        openSnackbar('login_successful', true, true)
        const { institutions, courses, user, token, father } = res.data
        window.localStorage.setItem('id', user.id)
        window.localStorage.setItem('token_seguridad', token)
        startSession({ ...user, father: father }, courses, institutions, true)
        history.push('/')
      } else {
        openSnackbar('save_failed', true, false)
      }
    })
  }
  return (
    <GoogleLogin
      clientId="1091756036227-gel7jr3i1daoaqmo1u0gq3lfn45cdp10.apps.googleusercontent.com"
      render={(renderProps) => (
        <button className="btnExternal" onClick={renderProps.onClick}>
          Google
          <i className="fab fa-google"></i>
        </button>
      )}
      buttonText="Google"
      onSuccess={responseGoogle}
      onFailure={responseGooglef}
      cookiePolicy={'single_host_origin'}
    />
  )
}
const mapDispatchToProps = {
  openSnackbar,
  startSession,
}
export default connect(null, mapDispatchToProps)(withRouter(Google))
