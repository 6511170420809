import { useAxios } from '../../hooks'
import { validateArray } from '../../utils/Validation'
import { H2, SubtitleData } from '../../components/text'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeLayout } from '../../store/actions'
import { LoadCircle } from '../../components/animation'
import { Link } from 'react-router-dom'
const ListCertificate = (props) => {
  const {
    changeLayout,
    match: { params },
  } = props
  const { response, loading } = useAxios({
    method: 'GET',
    url: `/certificate/lessonUser/${params.luid}/all`,
  })
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])
  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <H2 textAlign="center">Certificados</H2>
      <H2>Datos del usuario</H2>
      {validateArray(response) ? (
        <>
          <SubtitleData color="var(--green)" subtitle="Nombres">
            {response[0].name}
          </SubtitleData>
          <SubtitleData color="var(--green)" subtitle="Apellidos">
            {response[0].lastName}
          </SubtitleData>
          <br />
          <H2>Lista de certificados</H2>
          <div style={{ textAlign: 'center' }}>
            {response.map((item) => (
              <Link
                style={{
                  color: 'var(--green)',
                  fontSize: '1.6rem',
                  textDecoration: 'none',
                  textAlign: 'center',
                }}
                to={`/certificate/${item.lessonUserId}`}
              >
                {item.programManagerName}
              </Link>
            ))}
          </div>
        </>
      ) : null}
    </>
  )
}

export default connect(null, { changeLayout })(ListCertificate)
