import { validateStatus } from '../utils/Validation'
import { isValid, convertDate } from '../utils/ConvertDate'
import HttpAuth from '../services/HttpAuth'
import axios from 'axios'

const instancia = axios.create()
instancia.CancelToken = axios.CancelToken
instancia.isCancel = axios.isCancel

export const UseOnSubmit = async (props) => {
  const {
    axiosParams,
    openSnackbar,
    textSuccess,
    textWrong,
    functionSuccess,
    functionWrong,
    process = false,
    tokenAuth = true,
  } = props

  let status = null
  let error = ''
  let loading = true

  const actionHttp = tokenAuth ? HttpAuth : instancia

  try {
    const result = await actionHttp.request(
      process ? ProcessBody(axiosParams) : axiosParams,
    )
    status = result.status || 400
    if (validateStatus(status)) {
      openSnackbar(textSuccess, true, true)
      validateFunction(functionSuccess, result.data)
    } else {
      openSnackbar(textWrong, true, false)
      validateFunction(functionWrong, result.data)
    }
  } catch (e) {
    validateFunction(functionWrong)
    openSnackbar(textWrong, true, false)
    error = e
  } finally {
    loading = false
  }

  return { status, error, loading }
}

function validateFunction(action, data = null) {
  if (action) {
    action(data)
  }
}

function ProcessBody(params) {
  const { method, url, data } = params
  for (let key in data) {
    if (isValid(data[key])) {
      data[key] = convertDate(data[key])
    }
  }
  return { method, url, data }
}
