import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FooterComponent } from '../Home/Home'
import { changeLayout, openSnackbar } from "../../store/actions";
import { Link } from 'react-router-dom';
import './Advancedtechnician.css';
import { DefaultBtn } from "../../components/buttons";
import DefaultRenderModal from "../../components/modal/DefaultRenderModal";
import DefaultPreregisterModal from "../../components/modal/DefaultPreregisterModal";

const InternationalTrade = (props) => {
  const { changeLayout } = props;
  useEffect(() => {
    changeLayout("ContentOnly");
  }, [changeLayout]);

  const [dImageOne, setDImageOne] = useState(true)
  const [dImageTwo, setDImageTwo] = useState(false)
  const [dImageThree, setDImageThree] = useState(false)
  const [modal, setmodal] = useState(false)

  const handleButton = (num) => {
    if (num === 1) {
      setDImageOne(!dImageOne)
      setDImageTwo(false)
      setDImageThree(false)
    }
    if (num === 2) {
      setDImageOne(false)
      setDImageTwo(!dImageTwo)
      setDImageThree(false)
    }
    if (num === 3) {
      setDImageOne(false)
      setDImageTwo(false)
      setDImageThree(!dImageThree)
    }
  }

  return (
    <div className="">
      <div id="Accounting" className="tecnique__head">
      {modal && (
          <DefaultRenderModal
            onClose={() => setmodal(false)}
            width="500px"
            maxWidht="100%"
            closeOutside={true}
            background="linear-gradient(135deg,rgba(92, 214, 189, 1) 0%, var(--purple) 100%)"
          >
            <DefaultPreregisterModal
              openSnackbar={openSnackbar}
              onClose={() => setmodal(false)}
              interest="Comercio Internacional"
            />
          </DefaultRenderModal>
        )}
        <div className="tecnique_section" style={{ backgroundImage: "url('https://res.cloudinary.com/ahsoizdev/image/upload/v1675718074/imagesCapacita/COMERCIO/header_td0aoq.png')" }}>
          <div className="main mainco">
            <h1 style={{ color: "rgb(248, 112, 62)" }}>COMERCIO INTERNACIONAL Y ADMINISTRACION DE ADUANAS</h1>
            <h3>APRENDE SOBRE LA IMPORACIÓNY EXPORTACIÓN ADUANERA DE NUESTRO PAIS</h3>
            <div className="ShortCourses__Hbtns">
              <DefaultBtn onClick={() => setmodal(!modal)}>
                Regístrate gratis
              </DefaultBtn>
            </div>
          </div>
        </div>
      </div>
      <div className="tecnique__head">
        <div className="tecnique__head">
          <div className="main_section">
            <div className="content-section">
              <div className="content-description">
                <span>TECNICO SUPERIOR</span>
                <h3>COMERCIO INTERNACIONAL Y ADMINISTRACION DE ADUANAS</h3>
                <p>En la carrera de Comercio Internacional y Administración Aduanera aprenderán técnicas de comercio internacional, Leyes de Aduanas y diferentes Acuerdos Internacionales, que le permiten desarrollar su trabajo en cualquier tipo de empresa dedicada a este
                  rubro, como también el de abrir su propio negocio de importaciones y exportaciones de acuerdo a la normativa vigente.</p>
              </div>
            </div>
            <div className="img-main-section">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675718072/imagesCapacita/COMERCIO/comercio_internacional_kjd6m0.png" alt="imagen" />
            </div>
          </div>
        </div>
      </div>

      <div className="tecnique__head">
        <div className="list-items">
          <div className="item side-item">
            <div className="img-item side">
              <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676674967/assets/orange-arrow_pevhq8.png" alt="icon" />
            </div>
            <div className="content-item">
              <div className="title-item side" ><span style={{ backgroundColor: "rgb(248, 112, 62)" }}>OBJETIVO</span></div>
              <div className="description-item side" style={{ backgroundColor: "rgb(248, 112, 62)" }}> <p > Formar a nuestros estudiantes para que tengan la capacidad de generar, analizar e
                interpretar información financiera cuantitativa y cualitativa para la toma de
                decisiones en las organizaciones; con actitudes y valores, visión y generador del
                cambio en su ámbito social, económico y laboral, asumiendo una actitud de
                servicio al cliente, a la sociedad y con disposición al trabajo colaborativo.</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="img-item">
              <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676674967/assets/orange-user_zzgwqp.png" alt="icon" />
            </div>
            <div className="content-item">
              <div className="title-item" ><span style={{ backgroundColor: "rgb(248, 112, 62)" }}>PÚBLICO OBJETIVO</span></div>
              <div className="description-item" style={{ backgroundColor: "rgb(248, 112, 62)" }}> <p >A todas las personas que sean bachilleres y deseen desempeñarse en empresas y
                organizaciones de distintos tamaños y sectores económicos, con o sin fines de
                lucro, en general en toda institución que requiera desarrollar procesos contables.
                Asimismo, podrá generar sus propios espacios de trabajo a través de la creación y
                gestión de microempresas de prestación de servicios técnicos en las áreas
                contable y tributaria.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tecnique__head">
        <div className="main_characteristic">
          <div className="title-section-body">CARACTERISTICAS DEL CURSO</div>
          <div className="characteristic">
            <div className="col-character">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675718089/imagesCapacita/COMERCIO/icon3_saeb65.png" alt="icon" />
              <h3>MODALIDAD</h3>
              <p className="page-target" style={{ backgroundColor: "rgb(248, 112, 62)" }}>Presencial o virtual</p>
            </div>
            <div className="characterSpace col-character">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675718089/imagesCapacita/COMERCIO/icon4_xvbxyw.png" alt="icon" />
              <h3>METODOLOGIA</h3>
              <p className="page-target" style={{ backgroundColor: "rgb(248, 112, 62)" }}>Exposiciones teórico – práctico combinando ambos
                métodos de enseñanza, así como la implementación de
                estudios de casos y resolución de problemas vinculados
                con la realidad, que permitirán a los participantes la
                discusión, reflexión y trabajo grupal y desarrollar
                aptitudes para aplicar y transferir los conocimientos a su
                campo laboral.
                La carrera es modular es decir que por mes llevarás una
                materia con el afán de enfocarte en aprender y
                comprender a su totalidad.</p>
            </div>
            <div className="col-character">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675718089/imagesCapacita/COMERCIO/icon5_youjtx.png" alt="icon" />
              <h3>DURACION</h3>
              <p className="page-target" style={{ backgroundColor: "rgb(248, 112, 62)" }}>3 Años</p>
            </div>
            <div className="characterSpace col-character">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675718089/imagesCapacita/COMERCIO/icon6_gz7vec.png" alt="icon" />
              <h3>TITULACION</h3>
              <p className="page-target" style={{ backgroundColor: "rgb(248, 112, 62)" }}>Técnico Superior en Contaduría General,
                extendido por el Ministerio de Educación.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="tecnique__head">
        <div className="main_curricula">
          <div className="title-section-body">MALLA CURRICULAR</div>
          <img className="image-large" src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675718115/imagesCapacita/COMERCIO/PENSUM_COMERCIIO_at14lk.png" alt="" />
          <div className="list-image-display">
            <div className="nav-display nav-d-one" onClick={() => handleButton(1)}>1er. año</div>
            <div className="nav-display nav-d-two" onClick={() => handleButton(2)}>2do. año</div>
            <div className="nav-display nav-d-three" onClick={() => handleButton(3)}>3er. año</div>
          </div>
          <div className="img-section">
            <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676673497/assets/ci-one_ujntew.png" alt="img1" className={`img-d${dImageOne ? "-block" : ""}`} />
            <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676673497/assets/ci-two_bngfwg.png" alt="img2" className={`img-d${dImageTwo ? "-block" : ""}`} />
            <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676673497/assets/ci-three_uqi9n5.png" alt="img3" className={`img-d${dImageThree ? "-block" : ""}`} />
          </div>
          <button className="button-download"><a href="https://res.cloudinary.com/pataformacgi/image/upload/v1676674578/assets/MALLA_COMERCIO_EXTERIOR_LM_vb9tzr.pdf" target="black"><i className="fa fa-download" /> DESCARGAR MALLA CURRICULAR</a> </button>
        </div>
      </div>
      <div className="tecnique__head">
        <div className="main_hora">
          <div className="title-section-body">HORARIOS</div>
          <img className="imgHorario" src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675718070/imagesCapacita/COMERCIO/Horarios_xk9ii8.png" alt="" />
        </div>
      </div>
      <div className="sub-content">
        <div className="image-section">
          <a href={`https://wa.me/59170140353?text=Hola%20Capacita%20Global%20`}
            target="_blank"
            rel="noopener noreferrer">
            <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675807047/imagesCapacita/whatsap_hnoivb.png" alt="enlace whatssap" />
          </a>
        </div>
        <div className="section-text">
          <div className="title-section-body">CURSOS CORTOS</div>
          <p>¡TE INVITAMOS A CONOCER NUESTROS CURSOS CORTOS!</p>
          <Link to="/catalogue/short-courses" className="button-redirect">Cursos Cortos</Link>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default connect(null, {
  changeLayout,
})(InternationalTrade);
