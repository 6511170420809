import "./SimpleCard.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { H3 } from "../../../components/text";
import { DefaultBtn } from "../../../components/buttons";
import { UseOnSubmit } from "../../../hooks/useOnSubmit";
import { validateInstitution, validateArray } from "../../../utils/Validation";
import { connect } from "react-redux";
import { changeModal } from "../../../store/actions";
import config from '../../../config.json'

const SimpleCard = (props) => {
  const {
    course,
    shopping = [],
    openSnackbar,
    reloadShopping,
    url,
    membership,
    history,
    status,
  } = props;

  const { institutions } = props.sesionUser;
  const isAffiliate = validateInstitution(institutions, "affiliate");

  const [shop, setshop] = useState(status);

  // const [affiliate, setAffiliate] = useState(true);

  // console.log(isAffiliate);

  async function handleShopping(course) {
    if (window.localStorage.getItem("id")) {
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: "Shoppingcart",
          data: {
            ProgramManagerId: course.programManagerId,
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: "Se adiciono al carrito con exito",
        textWrong: "No se pudo adicionar",
        functionSuccess: () => setshop(!shop),
      });
    } else {
      window.localStorage.setItem(
        "shopping",
        JSON.stringify([
          ...shopping,
          { programManagerId: course.programManagerId, name: course.name },
        ])
      );
      openSnackbar("Se adiciono al carrito con exito", true, true);
      setshop(!shop);
    }
    reloadShopping();
  }
  async function handleRemove() {
    const filterShopping = shopping.filter(
      (f) => f.programManagerId === course.programManagerId
    );
    if (window.localStorage.getItem("id")) {
      await UseOnSubmit({
        axiosParams: {
          method: "DELETE",
          url: `/Shoppingcart/${filterShopping[0].id}`,
        },
        openSnackbar: openSnackbar,
        textSuccess: "Se elimino del carrito",
        textWrong: "No se pudo eliminar",
        functionSuccess: () => setshop(!shop),
      });
    } else {
      const shoppingList = shopping.filter(
        (f) => f.programManagerId !== course.programManagerId
      );
      window.localStorage.setItem(
        "shopping",
        JSON.stringify([...shoppingList])
      );
      openSnackbar("Se elimino del carrito", true, true);
      setshop(!shop);
    }
    reloadShopping();
  }

  const onSubmitAfiliado = async (course) => {
    try {
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: `/affiliate/programManager/${course.id}`,
          data: {
            UrlPage: config.domainURL === 'http://localhost:3000' ? `http://localhost:3000/graduates/${course.id}/course` : `https://capacitaglobal.org/graduates/${course.id}/course`,
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: "Afiliado con exito",
        textWrong: "No se pudo Afiliar, intentelo de nueo",
        // functionSuccess: props.reloadPedido,
        process: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="SimpleCard__container">
      <div className={course.new ? 'badge-card' : ''}>
      </div>
      <Link
        style={{
          textDecoration: "none",
          color: "#e47a33",
        }}
        to={`/${url}${course.programManagerId ? "/" + course.programManagerId : ""
          }/course`}
      >
        <div className="SimpleCard__main">
          <div
            className="SimpleCard__image"
            style={{
              backgroundImage: `url(${course.photoUrl})`,
            }}
          ></div>
          <div className="course_info">
            <H3 textTransform="uppercase" color="#757a83">
              {course.name ? course.name : ""}
            </H3>
            {course.professor && (
              <H3 fontWeight="500" color="#1d1a1a" fontSize="1.2rem">
                {course.professor}
              </H3>
            )}
            {course.description && (
              <H3 fontWeight="500" color="#1d1a1a" fontSize="1.4rem">
                {course.description}
              </H3>
            )}
            {!membership && (
              <H3 color="#e47a33" fontSize="1.4rem">
                <b>Ir al curso</b> <i className="fas fa-chevron-right"></i>
              </H3>
            )}
          </div>
        </div>
      </Link>

      <div className="SimpleCard__description">
        {course.duration ? (
          <H3 fontWeight="500" color="#686868" fontSize="1.5rem">
            <i className="far fa-clock"></i> <b>{course.duration}</b> Horas
          </H3>
        ) : null}
        {course.numberModules ? (
          <H3 fontWeight="500" color="#686868" fontSize="1.5rem">
            <i className="fas fa-book"></i> <b>{course.numberModules}</b>{" "}
            Módulos
          </H3>
        ) : null}
        {membership ? (
          <DefaultBtn
            onClick={() =>
              history.push(
                `/${url}${course.programManagerId ? "/" + course.programManagerId : ""
                }/course`
              )
            }
          >
            Tomar el curso
          </DefaultBtn>
        ) : (
          <DefaultBtn
            onClick={() =>
              shop ? handleRemove(course, shop) : handleShopping(course)
            }
          >
            {shop ? "Eliminar del carrito" : "Añadir al carrito"}
          </DefaultBtn>
        )}
        {validateArray(isAffiliate) && (
          <DefaultBtn
            onClick={() => {
              // console.log(props);
              onSubmitAfiliado(course);
              // changeModal(
              //   true,
              //   "¿Estás seguro que quieres eliminar ese programa del pedido?",
              //   "",
              //   "Eliminar",
              //   () => onSubmitAfiliado(course)
              // );
            }}
          >
            Afiliarse al Curso
          </DefaultBtn>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};

export default connect(mapStateToProps, { changeModal })(SimpleCard);
