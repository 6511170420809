import { useState } from 'react'
import { GlobalForm } from '../../../../../../components/form'
import { postAction } from '../../../../../../services/action/ActionAuthorization'
import { validateStatus } from '../../../../../../utils/Validation'
import { InputForum } from '../../../../../../utils/FormParameters'

const FormComment = (props) => {
  const {
    url,
    reload,
    data = {},
    handleClose,
    openSnackbar,
    textBtn = 'Guardar',
    action = postAction,
  } = props

  const [load, setload] = useState(true)
  function onSubmitComment(values) {
    if (values.CommentText) {
      setload(false)
      action(url, {
        CommentText: JSON.stringify(values.CommentText).replace(/"/g, "'"),
      }).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          openSnackbar('Comentario enviado', true, true)
          reload()
          handleClose()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    }
  }
  return (
    <div className="FormComent">
      <i onClick={handleClose} className="fas fa-times"></i>
      <GlobalForm
        InputsJson={InputForum}
        textBtn={textBtn}
        title=""
        data={data}
        load={load}
        onSubmit={onSubmitComment}
      />
      <br />
    </div>
  )
}
export default FormComment
