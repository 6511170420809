import './Payment.css'
import { connect } from 'react-redux'
import { useAxios } from '../../hooks'
import { useEffect, useState } from 'react'
import { H2, H4 } from '../../components/text'
import {
  changeLayout,
  changeModal,
  openSnackbar,
  startSession,
} from '../../store/actions'
import { Loading } from '../../components/animation'
import { DefaultBtn } from '../../components/buttons'
import { RenderModal } from '../../components/modal'
import ModalPayment from '../../containers/payment/ModalPayment'

const ben = {
  free: ['Vista solo del catalogo de cursos'],
  Monthly: [
    'Acceso total a nuestro cursos',
    'Asistencia tecnica',
    'Ayuda personalizada',
  ],
  Yearly: [
    'Acceso total a nuestro cursos',
    'Asistencia tecnica',
    'Ayuda personalizada',
    'Descuento en cursos para niños',
    'Beneficios adicionales por ser parte de Capacita Global Internacional',
  ],
}
/* const freeCard = {
  id: 0,
  type: 'free',
} */
const Payment = (props) => {
  const { changeLayout } = props
  const [payment, setPayment] = useState(null)
  const { response, loading } = useAxios({
    method: 'GET',
    url: '/paymentPlan/shortCourses',
  })
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])
  function handleModal(item) {
    setPayment(item)
  }
  return (
    <>
      <H2 margin="0 0 25px" fontWeight="500" textAlign="center">
        Sea parte de Capacita Global Internacional
      </H2>
      <div className="Payment__list">
        {loading ? (
          <Loading />
        ) : (
          response.map((item) => {
            return (
              <CardPayment
                key={item.id}
                data={{ ...item, benefits: ben[item.type || 'free'] }}
                onClick={() => handleModal(item)}
              />
            )
          })
        )}
      </div>
      {payment !== null && (
        <RenderModal
          width="700px"
          onClose={() => setPayment(null)}
          closeOutside
        >
          <ModalPayment
            {...props}
            payment={payment}
            onCancel={() => setPayment(null)}
          />
          ,
        </RenderModal>
      )}
    </>
  )
}
const CardPayment = ({ data, onClick }) => {
  return (
    <div
      className={`CardPayment ${
        {
          free: 'free',
          Monthly: 'monthly',
          Yearly: 'yearly',
        }[data.type]
      }`}
    >
      <div className="">
        {/* {data.type === 'Monthly' && (
          <H4 fontSize="1.4rem" textAlign="center">
            Recomendado
          </H4>
        )} */}
        <H2 fontWeight="500" textAlign="center">
          {
            {
              free: 'PLAN BÁSICO',
              Monthly: 'PLAN MENSUAL',
              Yearly: 'PLAN ANUAL',
            }[data.type]
          }
        </H2>
        {data.amount ? (
          <H4 textAlign="center">
            {data.amount} {data.currency} /{' '}
            {
              {
                Monthly: 'Mensual',
                Yearly: 'Anual',
              }[data.type]
            }
          </H4>
        ) : (
          <>
            <br />
            <br />
          </>
        )}
        <center>
          <DefaultBtn disabled={data.type === 'free'} onClick={onClick}>
            {
              {
                free: 'Plan actual',
                Monthly: 'COMPRAR',
                Yearly: 'COMPRAR',
              }[data.type]
            }
          </DefaultBtn>
        </center>
      </div>
      <div className="">
        <H4>Incluye</H4>
        {data.benefits.map((item, index) => (
          <H4 key={index}>
            <i className="fas fa-check"></i> {item}
          </H4>
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  changeModal,
  openSnackbar,
  startSession,
})(Payment)
