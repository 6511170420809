const reducer = (state, action) => {
  switch (action.type) {
    // Snackbar
    case 'OPEN_SNACKBAR':
      return {
        ...state,
        snackbarReducer: {
          open: action.status,
          message: action.content,
          kind: action.kind,
        },
      }
    case 'CHANGE_MODAL':
      return {
        ...state,
        modalReducer: {
          title: action.title,
          status: action.status,
          message: action.message,
          butttonText: action.butttonText,
          onClick: action.onClick,
          width: action.width,
        },
      }
    case 'CHANGE_LAYOUT':
      return {
        ...state,
        layoutReducer: {
          areas: action.content,
          responsive: action.responsive,
          navigation: action.navigation,
        },
      }
    case 'CHANGE_INFO':
      return {
        ...state,
        informationComponent: action.data,
      }
    case 'CHANGE_THEME':
      return {
        ...state,
        themeReducer: action.colors,
      }
    case 'CHANGE_HEADER':
      return {
        ...state,
        headerTitle: action.data,
      }
    case 'CHANGE_MEMBERSHIP':
      return {
        ...state,
        membership: action.data,
      }
    // UsuarioAuth
    case 'START_SESSION':
      return {
        ...state,
        sesionUser: {
          ...state.sesionUser,
          user: action.user,
          rols: action.rols,
          institutions: action.institutions,
          authenticate: action.status,
        },
      }
    case 'CLOSE_SESSION':
      return {
        ...state,
        sesionUser: {
          ...state.sesionUser,
          user: {},
          authenticate: false,
        },
        membership: null,
      }
    case 'UPDATE_USER':
      return {
        ...state,
        sesionUser: {
          ...state.sesionUser,
          user: action.user,
        },
      }
    case 'CHANGE_LANGUAGE':
      return {
        ...state,
        sesionUser: {
          ...state.sesionUser,
          user: {
            ...state.sesionUser.user,
            language: action.newLang,
          },
        },
      }
    default:
      return state
  }
}

export default reducer
