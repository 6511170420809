import { postAction, putActionWithException } from '../../../services/action/ActionAuthorization'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { convertD } from '../../../utils/ConvertDate'
import { useEffect, useState } from 'react'
import { TableFilterContainer } from '../../../components/table'
import { Loading } from '../../../components/animation'
import { SubtitleData, H2, H3 } from '../../../components/text'
import { ListTracing } from '../../../components/card'
import { BoxFlex } from '../../../components/boxes'
import { urlAvatar } from '../../../utils/Constant'
import {
  DefaultBtn,
  ActionButton,
  ButtonIcon,
} from '../../../components/buttons'
import { NewUserProgram, FormDefault } from '../../../components/form'
import FormAddUser from './FormAddUser'
import AddChildren from '../CoursesInstitution/AddChildren'
import './style/UsersProgram.css'
import { setColorByValue } from '../../../utils/ProcessData'
import * as Yup from 'yup'
import { useAxiosAuth } from '../../../hooks'
import { Field, Form, Formik } from 'formik'

const UsersProgram = (props) => {
  const {
    pid,
    changeInfo,
    openSnackbar,
    changeModal,
    url,
    type,
    iid = 1,
    program,
    startSession,
    history,
  } = props

  const header = [
    {
      key: 'nameAndLastName',
      name: 'Nombres y apellidos',
      type: 'preProcess',
      validate: handleFullName,
    },
    { key: 'email', name: 'Correo' },
    { key: 'lastProgress', name: 'Última conexión', type: 'date' },
    {
      key: 'progress',
      name: 'Porcentaje de avance',
      type: 'preProcess',
      validate: handleProgress,
    },
  ]

  function handleFullName(data) {
    return data.name || data.firstName + ' ' + data.lastName
  }
  function handleProgress(data) {
    return <H3 color={setColorByValue(data.percentage)}>{data.percentage} %</H3>
  }

  const [reload, setreload] = useState(false)
  function handleReload() {
    setreload(!reload)
    changeInfo(<></>)
  }

  const ViewUser = ({ user, programManager, pid }) => {
    const [load, setload] = useState(true)
    const [loading, setloading] = useState(true)

    const { response, reload } = useAxiosAuth({
      method: 'GET',
      url: `/users/UserTutorId/${user?.id}`,
    })

    const { response: responseData, reload: reloadData } = useAxiosAuth({
      method: 'GET',
      url: `/program/${pid}/user/${user?.id}/modules/califications`,
    })

    const allReload = () => {
      reload()
      reloadData()
    }

    useEffect(() => {
      allReload()

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])


    const onSubmit = async () => {
      try {
        setload(false)
        await postAction(
          `/${type === 'course' ? 'course' : 'program'}/${pid}/DelistUser`,
          {
            UserId: user.id,
            ProgramId: pid,
            CourseId: pid,
          },
        ).then((response) => {
          setload(true)
          if (response && validateStatus(response.status)) {
            openSnackbar('Se dio de baja al usuario', true, true)
            handleReload()
          } else {
            openSnackbar('No se pudo completar', true, false)
          }
        })
      } catch (e) {
        setload(true)
      }
    }

    function resendUser(user) {
      changeModal(
        true,
        '¿Estás seguro que quieres reenviar las credenciales del usuario?',
        '',
        'Enviar',
        () => handleResendUser(user),
      )
    }

    function handleResendUser(user) {
      setloading(false)
      postAction(`/email/${user.id}/ResendCredentials`).then((response) => {
        if (response && validateStatus(response.status)) {
          openSnackbar('Se volvio a enviar el mensaje', true, true)
          changeModal(
            true,
            'Se Reenviaron las credenciales exitosamente...',
            '',
            '',
            null,
          )
        } else {
          openSnackbar('No se pudo enviar el mensaje', true, false)
          changeModal(
            true,
            'No se Reenviaron las credenciales...',
            '',
            '',
            null,
          )
        }
        setloading(true)
      })
    }

    function changeParallel(user) {
      changeInfo(
        <FormDefault
          inputs={[
            {
              control: 'getSelect',
              initial: '',
              validate: Yup.string().required('Campo requerido'),
              label: 'Seleccione el paralelo',
              name: 'NewCourseId',
              url: `/programManager/${programManager.id}/parallels`,
              keyOption: 'versionName',
              value: 'id',
            },
          ]}
          onCancel={() => changeInfo(<></>)}
          method="POST"
          url={`/course/switchParallel`}
          openSnackbar={openSnackbar}
          title="Cambiar de paralelo"
          handleReload={handleReload}
          processData={(values) => ({
            ...values,
            UserId: user.id,
            CourseId: pid,
          })}
        />,
      )
    }

    function handleTracing(user) {
      changeModal(
        true,
        'Lista de seguimiento',
        <ListTracing {...props} user={user} />,
        null,
      )
    }

    function validation() {
      changeModal(
        true,
        '¿Estás seguro que quieres dar de baja a este usuario?',
        '',
        'Dar de baja',
        () => onSubmit(),
      )
    }
    function handleLogin(user) {
      console.log(user)
      changeModal(
        true,
        '¿Estás seguro? Cerrarás tu sesión actual',
        `Iniciarás sesión con la cuenta de ${user.firstName || user.name} ${user.lastName
        }?`,
        'Iniciar sesión',
        () => processLogin(user),
      )
    }

    function processLogin(user) {
      changeModal(
        true,
        '¿Estás seguro? Cerrarás tu sesión actual',
        <Loading />,
        null,
      )
      postAction(`/auth/login/replaced/${user.id}`).then((resp) => {
        console.log(resp)
        if (validateStatus(resp.status)) {
          openSnackbar('Inicio Exitoso', true, true)
          changeModal(false, null)
          const { institutions, courses, user, token, father } = resp.data
          window.localStorage.setItem('id', user.id)
          window.localStorage.setItem('token_seguridad', token)
          startSession({ ...user, father: father }, courses, institutions, true)
          history.push('/programs')
        } else {
          openSnackbar('No se pudo iniciar sesión', true, true)
        }
      })
    }

    const styleButton = {
      backgroundColor: '#4695851a',
      borderRadius: '5px',
      padding: '5px 10px',
    }
    return (
      <div className="ViewUser">
        <BoxFlex justify="space-between" wrap="wrap-reverse">
          <H2>Información del usuario</H2>
          {loading ? (
            <BoxFlex direction="row">
              {type === 'course' && (
                <>
                  <ButtonIcon
                    onClick={() => handleTracing(user)}
                    style={styleButton}
                    content="Lista de seguimiento"
                    icon="address-book"
                  />
                  <ButtonIcon
                    onClick={() => changeParallel(user)}
                    style={styleButton}
                    content="Cambiar de paralelo"
                    icon="exchange-alt"
                  />
                </>
              )}
              <ButtonIcon
                onClick={() => resendUser(user)}
                content="Enviar credenciales de acceso"
                icon="address-card"
                style={styleButton}
              />
              <ButtonIcon
                type="anchor"
                onClick={`/program/${pid}/academic/${user.id}/${type === 'course' ? 'course' : 'Program'
                  }`}
                style={styleButton}
                content="Ver su progreso"
                icon="tasks"
              />
              <ButtonIcon
                onClick={() => handleLogin(user)}
                content="Iniciar sesión"
                icon="sign-in-alt"
                style={styleButton}
              />
            </BoxFlex>
          ) : (
            <Loading />
          )}
        </BoxFlex>
        <SubtitleData subtitle="Correo electrónico">{user.email}</SubtitleData>
        <SubtitleData subtitle="Nombres">{user.firstName}</SubtitleData>
        <SubtitleData subtitle="Nombres">{user.name}</SubtitleData>
        <SubtitleData subtitle="Apellidos">{user.lastName}</SubtitleData>
        {user.ci && user.expeditionCi ? (
          <SubtitleData subtitle="Carnet de identidad">
            {user.ci} {user.expeditionCi}
          </SubtitleData>
        ) : null}
        <SubtitleData subtitle="Fecha de nacimiento">
          {user.dateOfBirth && user.dateOfBirth !== '0001-01-01T00:00:00'
            ? convertD(user.dateOfBirth, 'LL')
            : 'N/A'}
        </SubtitleData>
        <SubtitleData subtitle="Genero">
          {user.gender
            ? user.gender === 'male'
              ? 'Masculino'
              : 'Femenino'
            : 'N/A'}
        </SubtitleData>
        {user.nameFather || user.lastNameFather ? (
          <SubtitleData subtitle="Nombre del tutor">
            {`${user.nameFather} ${user.lastNameFather}`}
          </SubtitleData>
        ) : null}
        <SubtitleData subtitle="Teléfono del tutor">
          {user.telephoneFather}
        </SubtitleData>
        <SubtitleData subtitle="Teléfono">{user.telephone}</SubtitleData>
        {user.lastActive === '0001-01-01T00:00:00' ? null : (
          <SubtitleData subtitle="Última conexión ">
            {convertD(user.lastActive, 'LLLL')}
          </SubtitleData>
        )}
        {load ? (
          <ActionButton
            onClick={() =>
              changeInfo(
                <>
                  <H2 textAlign="center">Editar usuario</H2>
                  <NewUserProgram
                    edit={true}
                    user={{
                      ...user,
                      firstName: user.name || user.firstName,
                      gender:
                        user.gender === 'M'
                          ? 'male'
                          : user.gender === 'F'
                            ? 'female'
                            : user.gender,
                    }}
                    textBtn="Guardar Cambios"
                    program={{ id: pid }}
                    onClose={handleReload}
                    changeInfo={changeInfo}
                    changeModal={changeModal}
                  />
                </>,
              )
            }
            onCancel={validation}
            textBtn="Editar información"
            textCancel="Dar de baja"
          />
        ) : (
          <Loading />
        )}
        {validateArray(response?.fathers) && (
          <BoxFlex direction="row">
            <H2 margin="20px 0 0" color="var(--gray)">
              Lista de tutores de {user.firstName}
            </H2>
            {response.fathers.map((father) => (
              <CardUser key={father.id} user={father} />
            ))}
          </BoxFlex>
        )}
        {validateArray(responseData) && (
          <BoxFlex direction="row">
            <H2 margin="20px 0 0" color="var(--gray)">
              Lista de notas oficiales por curso
            </H2>
            {responseData.map((data) => (
              <CardRating key={data.id} data={data} reloadData={reloadData} openSnackbar={openSnackbar} />
            ))}
          </BoxFlex>
        )}
      </div>
    )
  }

  function handleAddUser() {
    const categoryDto = program.programManager.programManagerCategories
    changeInfo(
      validateArray(categoryDto) &&
        validateArray(
          categoryDto.filter((category) => category.categoryId === 8),
        ) ? (
        <AddChildren
          iid={iid}
          type={type}
          changeInfo={changeInfo}
          handleReload={handleReload}
          pid={pid}
          changeModal={changeModal}
          openSnackbar={openSnackbar}
        />
      ) : (
        <FormAddUser
          iid={iid}
          type={type}
          changeInfo={changeInfo}
          onClose={handleReload}
          pid={pid}
          changeModal={changeModal}
          openSnackbar={openSnackbar}
        />
      ),
    )
  }

  return (
    <>
      <div className="UsersProgram__btn">
        <DefaultBtn onClick={handleAddUser}>
          Adicionar Usuario{' '}
          <i style={{ fontSize: '1.4rem' }} className="fas fa-plus"></i>
        </DefaultBtn>
      </div>
      <br />
      <TableFilterContainer
        param={url}
        header={header}
        addHeader={[
          { key: 'firstName', name: 'Nombres' },
          { key: 'lastName', name: 'Apellidos' },
        ]}
        boxFilters={[
          {
            type: 'select',
            label: 'Ordenar por:',
            name: 'OrderBy',
            options: [
              { label: 'Ultima conexión', name: '' },
              { label: 'Fecha de creación', name: 'created' },
              { label: 'Nombres', name: 'name' },
              { label: 'Apellidos', name: 'lastName' },
            ],
          },
        ]}
        handleReload={reload}
        onClick={(user) => changeInfo(<ViewUser user={user} {...props} />)}
      />
      {/* <TableOptions
        param={url}
        header={header}
        choice={true}
        preConfig={preConfig}
        handleReload={reload}
        handleInfo={(user) => changeInfo(<ViewUser user={user} {...props} />)}
      /> */}
    </>
  )
}
const CardUser = ({ user }) => {
  return (
    <BoxFlex
      width="100%"
      wrap="nowrap"
      padding="5px"
      borderRadius="10px"
      justify="flex-start"
      border="1px solid gray"
    >
      <div
        style={{
          height: '80px',
          width: '80px',
          backgroundImage: `url("${user.photoUrl ? user.photoUrl : urlAvatar
            }")`,
          backgroundSize: 'cover',
          border: '2px solid var(--white)',
          borderRadius: '50%',
        }}
      ></div>
      <div className="">
        <H3>
          {user.name} {user.lastName}
        </H3>
        {user.email && (
          <H3 fontSize="1.4rem" fontWeight="500">
            <strong>Correo:</strong> {user.email}
          </H3>
        )}
        <H3 fontSize="1.4rem" fontWeight="500" margin="0">
          <strong>Usuario:</strong> {user.username}
        </H3>
      </div>
    </BoxFlex>
  )
}

const CardRating = ({ data, reloadData, openSnackbar }) => {

  return (
    <div
      className='card-mini'
    // width="100%"
    // wrap="nowrap"
    // padding="5px"
    // borderRadius="10px"
    // justify="flex-start"
    // border="1px solid gray"
    >
      <div className="">
        <Formik
          initialValues={{
            rating: data.officialRating,
            courseId: data.courseId
          }}
          onSubmit={async (values) => {
            const reload = () => {
              openSnackbar('Actualizado!', true, true)
              reloadData()
            }
            await putActionWithException(`/course/${data.course.id}/user/${data.userId}/rating/${values.rating}/officialRating`, values, reload, () => openSnackbar('Error al guardar', true, false))
          }}
          validationSchema={Yup.object().shape({
            rating: Yup.number().required("Campo requerido!").min(0, "Debe ser mayor o igual a cero (0)!").typeError("Debe ser un número!")
          })}
        >
          {({ errors }) => (
            <Form >
              {
                errors.rating
                &&
                <div className='label-text-error'>{errors.rating}</div>
              }
              <div className="card-form">
                <span style={{ display: 'none' }}>
                  <Field id="labelCourse" name="courseId" type="number" step="any" />
                </span>
                <div className='card-form-title'>
                  {data.course.name}
                </div>
                <Field id="labelRating" name="rating" placeholder="rating" className="text-field" />
                <button type="submit" className="fa fa-check-circle input-button-icon" title='guardar cambios'></button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default UsersProgram
