import { useState } from 'react'
import { H3 } from '../../../../components/text'
import { convertD } from '../../../../utils/ConvertDate'
import { GlobalForm } from '../../../../components/form'
import { validateStatus } from '../../../../utils/Validation'
import { inputReschedule } from '../../../../utils/FormParameters'
import { putAction } from '../../../../services/action/ActionAuthorization'
const ScheduleReschedule = (props) => {
  const { item, openSnackbar, changeModal, reload } = props
  const [load, setload] = useState(true)

  // function getFormattedDate(dateConvert) {
  //   const getHoursAndMinutes = dateConvert.split(':')
  //   var date = new Date()
  //   date.setHours(getHoursAndMinutes[0], getHoursAndMinutes[1], 0)
  //   return date
  // }

  const onSubmit = async (values) => {
    try {
      setload(false)
      await putAction(`/courseSchedule/${item.id}/unSuscribe`, {
        ...values,
        StartClass: convertD(values.StartClass, 'HH:mm'),
        FinishClass: convertD(values.FinishClass, 'HH:mm'),
      }).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          changeModal(false, '')
          openSnackbar('Reprogramado correctamente', true, true)
          reload()
        } else {
          if (response.data.type === 'Exist Schedule') {
            openSnackbar(
              'Ya existe una clase programada en esa fecha',
              true,
              false,
            )
          } else {
            openSnackbar('Error al reprogramado', true, false)
          }
        }
      })
    } catch (e) {
      setload(true)
    }
  }
  return (
    <>
      <H3 color="var(--gray)" margin="0">
        Horario a reprogramar
      </H3>
      <div
        style={{
          border: '1px solid gray',
          padding: '5px',
          borderRadius: '5px',
        }}
      >
        <H3 fontSize="1.4rem" fontWeight="500">
          Fecha: {convertD(item.classDayDate, 'LLL')}
        </H3>
        <H3 fontSize="1.4rem" fontWeight="500">
          Horario de inicio: {item.startClass}
        </H3>
        <H3 fontSize="1.4rem" fontWeight="500" margin="0">
          Horario de finalización: {item.finishClass}
        </H3>
      </div>
      <br />
      <GlobalForm
        load={load}
        textBtn="Editar"
        onSubmit={onSubmit}
        InputsJson={inputReschedule}
        data={{}}
        widthBtn="100%"
        onCancel={() => changeModal(false, '')}
      />
    </>
  )
}

export default ScheduleReschedule
