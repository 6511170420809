import { InformationTeacher } from '../../../../components/card'
import { LoadCircle } from '../../../../components/animation'
import { H3, H4 } from '../../../../components/text'
import { getAction } from '../../../../services/action/ActionAuthorization'
import { validateArray, validateStatus } from '../../../../utils/Validation'
import { orderDates } from '../../../../utils/ConvertDate'
import { useActionParam } from '../../../../hooks'
import './CourseAside.css'
import { TemplateAgenda } from '../../../../components/calendar'
import { ButtonNavigation } from '../../../../components/buttons'
import { useState } from 'react'
import { LinkCard, GraduateCard } from '../../../../components/card'
// const fakeData = {
//   addressedTo:
//     'El Diplomado está dirigido a todos aquellos profesionales que cuenten con aptitud y habilidad para la investigación y enseñanza sin discriminación de área formativa o perfil profesional, haciendo énfasis en el área de derecho, contabilidad, y auditoria.',
//   bestSeller: false,
//   courseAchievements:
//     'Desarrollar analíticamente los distintos órganos y procedimientos administrativos que pautan las distintas fases de aplicación de los tributos y los procedimientos jurídicos en defensa de la ciudadanía y de la institución.',
//   description:
//     'El diplomado desarrolla, actualiza y refuerza los conocimientos del profesional en progresar el estudio de problemas y casos prácticos de Derecho Tributario, relacionando sus normas reguladoras, requerimientos administrativos y ubicarlos en cada fase procedimental mediante el conocimiento exhaustivo y completo de cada procedimiento tributario, además de desarrollar las distintas vías de impugnación para iniciar un procedimiento administrativo o un procedimiento jurisdiccional en el sistema impositivo nacional.',
//   finishDate: '2021-01-17T00:00:00',
//   // id: 1,
//   mainPrice: null,
//   name: 'DIPLOMADO EN BIG DATA Y MACHINE LEARNING',
//   paymentUrl:
//     'https://aulacapacitaglobal.com/finalizar-compra/?add-to-cart=19133',
//   photoUrl:
//     'https://res.cloudinary.com/dsgaf5zoj/image/upload/h_600,c_scale,q_auto,f_auto/v1622226794/assets/gk20ly7fkp1zrb5yze5j.png',
//   startDate: '2021-01-10T00:00:00',
//   unlimitedAccess: true,
//   videoUrl: '',
// }
const CourseAside = ({ cid, mid }) => {
  const [btns, setbtns] = useState({
    btn: [
      { id: 1, name: 'Eventos importantes' },
      { id: 2, name: 'Docentes' },
      { id: 3, name: 'Materiales' },
    ],
    activate: {
      id: 1,
    },
  })
  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }))
  }
  function GeneratingImportantDates() {
    const { dataRequest, statusRequest } = useActionParam({
      action: getAction,
      param: `/course/${cid}/ImportantDates`,
    })
    return (
      <>
        {validateStatus(statusRequest) ? (
          validateArray(dataRequest) ? (
            <TemplateAgenda calendarDates={orderDates(dataRequest)} />
          ) : (
            <H3>No tienes actividades pendientes</H3>
          )
        ) : (
          <LoadCircle />
        )}
      </>
    )
  }
  function GeneratingTeachers() {
    const { dataRequest, statusRequest } = useActionParam({
      action: getAction,
      param: `course/${cid}/teachers`,
    })
    return (
      <>
        {validateStatus(statusRequest) ? (
          validateArray(dataRequest) ? (
            dataRequest.map((t, i) => (
              <InformationTeacher key={i} teacher={t} />
            ))
          ) : (
            <H3>Aún no hay docentes asignados</H3>
          )
        ) : (
          <LoadCircle />
        )}
      </>
    )
  }
  function GeneratingMaterials() {
    const { dataRequest, statusRequest } = useActionParam({
      action: getAction,
      param: `course/${cid}/Material`,
    })
    return (
      <>
        {validateStatus(statusRequest) ? (
          dataRequest.map((mod) => <ModuleLink key={mod.moduleId} mod={mod} />)
        ) : (
          <LoadCircle />
        )}
      </>
    )
  }
  function ModuleLink({ mod }) {
    const [view, setview] = useState(
      parseInt(mid) === mod.moduleId ? true : false,
    )
    return (
      <>
        <div onClick={() => setview(!view)} className="ModuleLink__header">
          <H4 fontSize="1.4rem">{mod.moduleName}</H4>
          <i className={`fas fa-chevron-${view ? 'up' : 'down'}`}></i>
        </div>
        {view && (
          <>
            {validateArray(mod.files) ? (
              mod.files.map((file, index) => (
                <LinkCard key={index} type={file.type} dataLink={file} />
              ))
            ) : (
              <H3>No hay materiales</H3>
            )}
          </>
        )}
        <br />
      </>
    )
  }
  return (
    <>
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {btns.activate ? (
        <>
          {btns.activate.id === 1 && (
            <>
              {parseInt(cid) === 99 && (
                <>
                  <H4 fontSize="1.8rem">
                    ¿Si quieres seguir aprendiendo?, ingresa a nuestro diplomado
                    en big data y machine learning
                  </H4>
                  <GraduateCard
                    url="UAGRM/big-data"
                    course={{
                      name: 'DIPLOMADO EN BIG DATA Y MACHINE LEARNING',
                      photoUrl:
                        'https://res.cloudinary.com/dsgaf5zoj/image/upload/h_600,c_scale,q_auto,f_auto/v1622226794/assets/gk20ly7fkp1zrb5yze5j.png',
                    }}
                    color="#193765"
                  />
                </>
              )}
              <GeneratingImportantDates />
            </>
          )}
          {btns.activate.id === 2 && <GeneratingTeachers />}
          {btns.activate.id === 3 && <GeneratingMaterials />}
        </>
      ) : null}
    </>
  )
}

export default CourseAside
