import './style/HomeworkContent.css'
import { DefaultBtn, Icon } from '../../../../../components/buttons'
import { LoadCircle } from '../../../../../components/animation'
import { H4, H3, H2 } from '../../../../../components/text'
import { ColoredBoxes } from '../../../../../components/boxes'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  changeInfo,
  changeLayout,
  openSnackbar,
  changeModal,
} from '../../../../../store/actions'
import { HomeworkAside } from '../../../../../containers/programs'
import { getAction } from '../../../../../services/action/ActionAuthorization'
import { withRouter } from 'react-router-dom'
import { convertD, dataLock } from '../../../../../utils/ConvertDate'
import { filesExtension } from '../../../../../utils/Constant'
import { VideoAside } from '../../../../../containers/programs'
import {
  validateArray,
  validateEducator,
  validateStatus,
  validateInstitution,
} from '../../../../../utils/Validation'
import { TableHomework, ListReviews } from '../../../../../containers/programs'
import { useActionParam } from '../../../../../hooks'
import EditorView from '../../../../../components/editorjs/EditorView'
const HomeworkContent = (props) => {
  const {
    changeInfo,
    changeLayout,
    openSnackbar,
    sesionUser,
    changeModal,
    match: { params },
  } = props
  const isEducator = validateEducator(sesionUser.rols, params.cid)
  const isAdmin =
    validateInstitution(sesionUser.institutions, 'admin').length > 0
      ? true
      : false
  const [extension, setExtension] = useState(false)
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: `lesson/${params.id}`,
  })

  useEffect(() => {
    if (dataRequest && validateStatus(statusRequest)) {
      changeInfo(
        <VideoAside filesToList={dataRequest.filesToList} params={params} />,
      )
      if (dataRequest.id.toString() !== params.id) {
        reload()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRequest, statusRequest, params, changeInfo])
  useEffect(() => {
    changeLayout()
  }, [changeLayout])
  function ValidateDeadline() {
    let deadline = false
    if (dataLock(dataRequest.finishDate)) {
      if (validateArray(dataRequest.noteWeighings)) {
        dataRequest.noteWeighings.forEach((weighing) => {
          if (!dataLock(weighing.finishDate)) {
            deadline = true
          }
        })
      }
    } else {
      deadline = true
    }
    return deadline
  }
  const UpdateButton = () => {
    if (ValidateDeadline()) {
      changeInfo(
        <HomeworkAside
          homeworks={dataRequest.homeworks}
          fileExtension={dataRequest.fileExtension}
          numberFiles={dataRequest.numberFiles}
          id={params.id}
          openSnackbar={openSnackbar}
          reloadRequest={reload}
          changeInfo={changeInfo}
          changeModal={changeModal}
          uploadHomework={uploadHomework}
        />,
      )
    } else {
      changeModal(
        true,
        'El plazo para entregar la tarea a finalizado',
        'Usted ya no puede realizar cambios en su presentación por que ya pasó la fecha límite',
      )
    }
  }
  function uploadHomework() {
    changeInfo(
      <VideoAside filesToList={dataRequest.filesToList} params={params} />,
    )
  }
  function ListExtensions({ noteWeighings }) {
    return (
      <div className="ListExtensions">
        {noteWeighings.map((weighing) => {
          if (!dataLock(weighing.finishDate)) {
            return (
              <div key={weighing.id} className="ListExtensions__weighing">
                <div>
                  <H4 margin="0" fontSize="1.4rem">
                    Inicio: {convertD(weighing.startDate, 'LLL')}
                  </H4>
                  <br />
                  <H4 margin="0" fontSize="1.4rem">
                    Fin: {convertD(weighing.finishDate, 'LLL')}
                  </H4>
                </div>
                <div>
                  Porcentaje de la nota: <strong>{weighing.percentage}</strong>%
                </div>
              </div>
            )
          } else {
            return null
          }
        })}
      </div>
    )
  }
  return (
    <div className="HomeworkContent">
      {validateStatus(statusRequest) ? (
        <>
          <div className="HomeworkContent__head">
            <div className="HomeworkContent__btns">
              {validateArray(dataRequest.noteWeighings) && (
                <Icon
                  ico={`chevron-${extension ? 'up' : 'down'}`}
                  handleModal={() => setExtension(!extension)}
                >
                  Mostrar prórrogas
                </Icon>
              )}
              <Icon ico="bars" handleModal={uploadHomework}>
                Menu
              </Icon>
            </div>
            <H3 margin="0">
              Fecha Limite: {convertD(dataRequest.finishDate, 'LLL')}
            </H3>
          </div>
          <br />
          {extension && (
            <ListExtensions noteWeighings={dataRequest.noteWeighings} />
          )}
          <div className="HomeworkContent__status">
            <ColoredBoxes
              boxes={[
                {
                  title: filesExtension[dataRequest.fileExtension],
                  subTitle: 'Tipo de archivo',
                  status: 'gray',
                },
                {
                  title: `${dataRequest.numberFiles} archivo ${
                    dataRequest.numberFiles > 1 ? 's' : ''
                  }`,
                  subTitle: 'Cantidad máxima',
                  status: 'gray',
                },
              ]}
            />
            {!isEducator && !isAdmin ? (
              <ColoredBoxes
                boxes={[
                  {
                    title: validateArray(dataRequest.homeworks)
                      ? dataRequest.homeworks[0].lessonUser.score !== -1
                        ? dataRequest.homeworks[0].lessonUser.score
                        : 'No calificado'
                      : 'No calificado',
                    subTitle: 'Calificación',
                    status: validateArray(dataRequest.homeworks)
                      ? dataRequest.homeworks[0].lessonUser.score > 50
                        ? 'green'
                        : 'red'
                      : 'red',
                  },
                  {
                    title:
                      dataRequest?.status === 'available'
                        ? 'Sin Entregar'
                        : 'Entregado',
                    subTitle: 'Estado',
                    status:
                      dataRequest?.status === 'available' ? 'red' : 'green',
                  },
                ]}
              />
            ) : null}
          </div>
          <br />
          <div className="HomeworkContent__main">
            <H2>{dataRequest.name}</H2>
            <br />
            {dataRequest.description &&
            dataRequest.description !== "''" &&
            dataRequest.description !== 'null' ? (
              <EditorView data={dataRequest.description} />
            ) : null}
            <br />
            {isEducator || isAdmin ? (
              <TableHomework
                lid={params.id}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                changeModal={changeModal}
                isAdmin={isAdmin}
                startDate={dataRequest.startDate}
                finishDate={dataRequest.finishDate}
              />
            ) : validateArray(dataRequest.homeworks) ? (
              <>
                <div className="HomeworkContent__btn">
                  <DefaultBtn onClick={UpdateButton}>
                    Ver archivos entregados
                  </DefaultBtn>
                </div>
                <ListReviews
                  type="student_review"
                  homeworks={dataRequest.homeworks}
                  openSnackbar={openSnackbar}
                />
              </>
            ) : (
              <div className="HomeworkContent__btn">
                <DefaultBtn onClick={UpdateButton}>Subir Archivo</DefaultBtn>
              </div>
            )}
          </div>
        </>
      ) : (
        <LoadCircle />
      )}
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeInfo,
  changeLayout,
  openSnackbar,
  changeModal,
})(withRouter(HomeworkContent))
