import { processUrlImage } from "../../utils/ProcessData";
const ImgDefault = ({ url, alt, currentSize, className }) => {
  if (currentSize.width) {
    return (
      <img
        className={className}
        src={processUrlImage(url, currentSize.width)}
        alt={alt ? alt : ""}
      />
    );
  } else {
    return null;
  }
};

export default ImgDefault;
