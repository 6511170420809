import { H2 } from '../../components/text'
import { CardIcon } from '../../components/buttons'
import { connect } from 'react-redux'
import { validateArray } from '../../utils/Validation'
import { changeLayout } from '../../store/actions'
import './Institutions.css'
import { useEffect } from 'react'
const Institutions = ({ sesionUser, changeLayout, param = 'institution' }) => {
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])
  return (
    <div className="Institutions">
      <H2 textAlign="center" color="var(--gray)">
        Tus Instituciones
      </H2>
      <div className="Institutions__btn">
        {validateArray(sesionUser.institutions)
          ? sesionUser.institutions.map((l) => {
              return (
                <CardIcon
                  key={l.id}
                  url={`/${param}/${l.id}/${l.name}`}
                  ico="university"
                >
                  {l.name}
                </CardIcon>
              )
            })
          : null}
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { changeLayout })(Institutions)
