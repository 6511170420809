import { useState } from 'react'
import { GlobalForm } from '../../../components/form'
import { postAction } from '../../../services/action/ActionAuthorization'
import { capitalizeJson } from '../../../utils/ProcessData'
import { validateStatus } from '../../../utils/Validation'
import { InputPreRegister } from '../../../utils/FormParameters'
import ListChildren from './ListChildren'
import * as Yup from 'yup'
const FormChildrenRegister = (props) => {
  const { onCancel, user, openSnackbar, reload, course, changeModal } = props
  const [load, setload] = useState(true)
  async function handleOnSubmit(values) {
    try {
      setload(false)
      await postAction(`/contact/register`, {
        ...values,
        ContactId: user.contactId,
        ProgramManagerId: course.programManagerId,
        Username: values.Email,
      }).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          reload()
          openSnackbar('Se guardo con exito', true, true)
          changeModal(
            true,
            '',
            <ListChildren
              {...props}
              tutor={response.data}
              onCancel={() => changeModal(false, null)}
            />,
            null,
          )
        } else {
          openSnackbar(
            response?.data ? response.data : 'No se pudo completar',
            true,
            false,
          )
        }
      })
    } catch (e) {
      setload(true)
    }
  }
  return (
    <div style={{ marginRight: '10px' }}>
      <GlobalForm
        InputsJson={[
          ...InputPreRegister,
          {
            name: 'Password',
            initial: '',
            validate: Yup.string()
              .required('Campo requerido')
              .min(4, 'La contraseña debe tener al menos 4 caracteres'),
            type: 'password',
            label: 'Contraseña',
          },
        ]}
        textBtn="Registrar"
        title="Registrar datos del tutor o apoderado"
        data={
          user
            ? capitalizeJson({
                ...user,
                LastName: user.lastname,
                IdDocument: user.ci,
                Email: user.email,
                PhoneNumber: user.phone,
              })
            : {}
        }
        load={load}
        onSubmit={handleOnSubmit}
        onCancel={onCancel}
      />
    </div>
  )
}

export default FormChildrenRegister
