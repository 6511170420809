import { useEffect } from 'react'
import { TabbedNavigation } from '../../components/navigation'
import {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
} from '../../store/actions'
import Users from './Users'
import { Institutions } from './Institutions'
import { connect } from 'react-redux'

const Admin = (props) => {
  const { changeLayout } = props
  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])
  return (
    <TabbedNavigation
      navigation={[
        {
          id: 1,
          component: <Users {...props} />,
          param: 'users',
          label: 'Gestionar usuarios',
          icon: 'fas fa-users',
        },
        {
          id: 2,
          component: <Institutions {...props} />,
          param: 'institutions',
          label: 'Gestionar instituciones',
          icon: 'fas fa-university',
        },
      ]}
    />
  )
}
export default connect(null, {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
})(Admin)
