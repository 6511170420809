import { useEffect } from "react";
import { TabbedNavigation } from "../../components/navigation";
import {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
} from "../../store/actions";

import { connect } from "react-redux";
import General from "./General";
import { CardRegister } from "../../view/Manager/Reports";
import { Doughnut, Pie } from "react-chartjs-2";
import { BoxFlex } from "../../components/boxes";

const Affiliate = (props) => {
  const {
    changeLayout,
    changeInfo,
    openSnackbar,
    // sesionUser,
    changeModal,
    match: {
      params: { iid, name },
    },
  } = props;

  useEffect(() => {
    changeInfo(
      <>
    
      </>
    );

    changeLayout();
  }, [changeLayout, changeInfo]);
  return (
    <TabbedNavigation
      navigation={[
        {
          id: 1,
          component: <General {...props}/>,

          param: "general",
          label: "General",
          icon: "fas fa-house-medical",
        },
   
      ]}
    />
  );
};
export default connect(null, {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
})(Affiliate);
