import './style/ContentVIdeo.css'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { convertSeconds, convertD } from '../../utils/ConvertDate'
import { ButtonIcon } from '../buttons'
import { CardMainOptions } from '../card'
import { H2, IconText } from '../text'
import { processUrlImage } from '../../utils/ProcessData'
import { sendMail } from './SendMail'
const ContentVideoRol = (props) => {
  const {
    contentData: { name, id, imageUrl, duration, startDatePerUser },
    match: {
      params: { cid, mid, pid },
    },
    overt,
    ChangePublic,
    PutLesson,
    ViewFiles,
    changeModal,
    openSnackbar,
    deleteLesson,
  } = props
  const image = {
    backgroundImage: imageUrl ? `url(${processUrlImage(imageUrl, 300)})` : '',
  }
  const copyCurrentLink = () => {
    const url = pid
      ? `/program/${pid}/course/${cid}/module/${mid}/video/${id}`
      : `/program/course/${cid}/module/${mid}/video/${id}`
    navigator.clipboard.writeText(`${window.location.origin}${url}`)
    openSnackbar('Enlace copiado', true, true)
  }

  const op = (
    <>
      <ButtonIcon onClick={PutLesson} content="Editar" icon="edit" />
      {overt && (
        <ButtonIcon
          onClick={() => sendMail(id, changeModal, openSnackbar)}
          content="Enviar correo"
          icon="envelope"
        />
      )}
      <ButtonIcon
        content={overt ? 'Anular publicación' : 'Publicar'}
        onClick={ChangePublic}
        icon={overt ? 'eye' : 'eye-slash'}
      />
      <ButtonIcon
        onClick={ViewFiles}
        content="Materiales complementarios"
        icon="folder"
      />
      <ButtonIcon
        onClick={copyCurrentLink}
        content="Copiar enlace"
        icon="copy"
      />
      <ButtonIcon
        onClick={() => deleteLesson(id, name)}
        content="Eliminar lección"
        icon="trash"
        style={{ color: 'var(--red)' }}
      />
    </>
  )
  return (
    <div className="ContentVIdeo__act">
      <CardMainOptions options={op}>
        <Link
          to={
            pid
              ? `/program/${pid}/course/${cid}/module/${mid}/video/${id}`
              : `/program/course/${cid}/module/${mid}/video/${id}`
          }
          className="ContentVIdeo"
        >
          <div className="ContentVIdeo__img" style={image}>
            <i className="fas fa-play-circle"></i>
          </div>
          <div className="ContentVIdeo__main" style={{ color: 'var(--black)' }}>
            <H2>{name}</H2>
            <IconText icon="clock">
              {convertSeconds(duration, 'mm:ss')} (Duración)
            </IconText>
            {/* <IconText icon={overt ? "eye" : "eye-slash"}>
              {overt ? "Publicado" : "No publicado"}
            </IconText> */}
            <IconText icon="calendar-day">
              {convertD(startDatePerUser, 'LLLL')} (Fecha Inicio)
            </IconText>
          </div>
        </Link>
      </CardMainOptions>
    </div>
  )
}

export default withRouter(ContentVideoRol)
