import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import { DropzoneInput, FormikControl, Select } from "../../../components/input";
import { convertDate, convertD } from "../../../utils/ConvertDate";
import {
  getAction,
  postAction,
  putAction,
} from "../../../services/action/ActionAuthorization";
import { useActionParam } from "../../../hooks";
import { Loading } from "../../../components/animation";
import { ActionButton, Icon, DefaultBtn } from "../../../components/buttons";
import { validateArray, validateStatus } from "../../../utils/Validation";
import { H2 } from "../../../components/text";
import { CompModal } from "../../../components/modal";
import EditorJs from "react-editor-js";
import { EDITOR_JS_TOOLS } from "../../../components/editorjs/Tools";
import { UseOnSubmit } from "../../../hooks/useOnSubmit";

const FormRelease = (props) => {
  const { openSnackbar, handleView, view, courses, programs } = props;
  const dropdownRol = programs
    ? [
      { key: "Rol:", value: "" },
      { key: "Educador", value: 1 },
      { key: "Estudiante", value: 2 },
    ]
    : [
      { key: "Rol:", value: "" },
      { key: "Estudiante", value: 2 },
    ];
  const [load, setload] = useState(true);
  const [modal, setmodal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const { dataRequest: requestPrograms } = useActionParam({
    action: getAction,
    param: view.advert ? null : programs,
  });
  const { dataRequest: requestCourses } = useActionParam({
    action: getAction,
    param: view.advert ? null : courses,
  });
  function handleInitial(body, constant, empty) {
    if (body) {
      if (body[constant]) {
        return body[constant];
      } else {
        return empty;
      }
    } else {
      return empty;
    }
  }
  const initialValues = {
    Title: handleInitial(view.advert, "title", ""),
    Content: view.advert
      ? view.advert.content
        ? JSON.parse(view.advert.content.replace(/'/g, '"'))
        : ""
      : "",
    StartDate: view.advert
      ? convertD(view.advert.startDate, "utc")
      : null || null,
    FinishDate: view.advert
      ? convertD(view.advert.finishDate, "utc")
      : null || null,
    Public: handleInitial(view.advert, "Public", false),
    RolId: handleInitial(view.advert, "rolId", ""),
    ProgramIds: handleInitial(view.advert, "programIds", programs ? [""] : []),
    CourseIds: handleInitial(view.advert, "courseIds", courses ? [""] : []),
    File: null,
    file: ""
  };
  const validationSchema = Yup.object({
    Title: Yup.string().required("Campo requerido"),
    StartDate: Yup.string().required("Campo requerido").nullable(),
    FinishDate: Yup.string().required("Campo requerido").nullable(),
    ProgramIds:
      programs && !view.advert
        ? Yup.array().of(Yup.string().required("Campo requerido"))
        : null,
    CourseIds:
      courses && !view.advert
        ? Yup.array().of(Yup.string().required("Campo requerido"))
        : null,
    RolId: Yup.string().required("Campo Requerido"),
  });
  const onSubmit = async (values) => {
    try {
      setload(false);
      console.log(values.File)
      setload(true);
      // uploadImg
      const uploadFile = async (announceId) => {
        setUploading(true)
        let formData = new FormData();
        formData.append("ModuleId", values.module);
        formData.append("Content", values.content);
        formData.append("Name", values.Name);
        formData.append("StartDate", values.StartDate.toISOString());
        formData.append("FinishDate", values.FinishDate.toISOString());
        formData.append("Description", values.Description);
        formData.append("File", values.File);

        // console.log(formData)
        await UseOnSubmit({
          axiosParams: {
            method: 'PUT',
            url: `/announce/${announceId}/file`,
            data: formData,
          },
          openSnackbar: openSnackbar,
          textSuccess: 'Lección creada',
          textWrong: 'No se pudo crear',
          process: true,
        }).then(() => {
          setUploading(false)
          handleView(!view.status, view.advert);
        }).catch(() => {
          setUploading(false)
          handleView(!view.status, view.advert);
          console.log("error on upload file")
        })
      }

      if (view.advert) {
        await putAction(`/announce/${view.advert.id}`, {
          ...values,
          StartDate: convertDate(values.StartDate),
          FinishDate: convertDate(values.FinishDate),
          ProgramIds: values.ProgramIds,
          Content: values.Content
            ? JSON.stringify(values.Content).replace(/"/g, "'")
            : "",
        }).then((response) => {
          setload(true);
          if (response && validateStatus(response.status)) {
            if (values.File != null) {
              console.log("uploading...")
              uploadFile(view.advert.id)
            }
            else {
              handleView(!view.status, view.advert);
            }
            openSnackbar("Se edito el anuncio", true, true);
          } else {
            openSnackbar("No se pudo editar", true, false);
          }
        });
      } else {
        await postAction(`/announce`, {
          ...values,
          StartDate: convertDate(values.StartDate),
          FinishDate: convertDate(values.FinishDate),
          ProgramIds: values.ProgramIds,
          Content: values.Content
            ? JSON.stringify(values.Content).replace(/"/g, "'")
            : "",
        }).then((response) => {
          setload(true);
          if (response && validateStatus(response.status)) {
            if (values.File != null) {
              console.log("uploading...")
              uploadFile(response.data.id)
            }
            else {
              handleView(!view.status, view.advert);
            }
            openSnackbar("Se creo el anuncio", true, true);
          } else {
            openSnackbar("No se pudo guardar", true, false);
          }
        });
      }
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };
  function dropdownGenerate(list, title) {
    let dropdown = [{ key: `${title}:`, value: "" }];
    if (validateArray(list)) {
      list.forEach((l) => {
        dropdown.push({
          key: `${l.name} - ${l.versionName}`,
          value: l.id,
        });
      });
    }
    return dropdown;
  }
  function EditContent({ onClose, manageContent, name, title, initial }) {
    function handleChange(_, value) {
      manageContent((a) => ({
        ...a,
        [name]: value,
      }));
    }
    return (
      <CompModal onClose={onClose} closeOutside={true}>
        <div className="StatementMain">
          <div className="StatementMain__header">
            <img
              src="https://res.cloudinary.com/dzxmzxvbr/image/upload/v1614701400/si3r613tgi34yhdnbcug.png"
              alt=""
            />
            <img
              src="https://i1.wp.com/aulacapacitaglobal.com/wp-content/uploads/2020/08/cropped-LOGOTIPO-VECTOR-COLORES.png?resize=1024%2C368&ssl=1"
              alt=""
            />
          </div>
          <h2 className="StatementMain__title">
            {title ? title : "COMUNICADO"}
          </h2>
          <div className="StatementMain__editorJs">
            <EditorJs
              data={initial}
              onChange={handleChange}
              tools={EDITOR_JS_TOOLS}
            />
          </div>
          <ActionButton
            onClick={onClose}
            onCancel={onClose}
            textBtn="Guardar comunicado"
            textCancel="Cancelar"
          />
        </div>
      </CompModal>
    );
  }
  return (
    <div className="">
      <H2 textAlign="center">{view.advert ? "Editar" : "Crear"} anuncio</H2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Título del anuncio"
                name="Title"
              />
              <FormikControl
                control="select"
                label="Roles de usuario:"
                name="RolId"
                options={dropdownRol}
                disabled={view.advert}
              />
              {!view.advert && programs ? (
                <FieldArray
                  name="ProgramIds"
                  render={(arrayHelpers) => {
                    const programIds = formik.values.ProgramIds;
                    return (
                      <>
                        {validateArray(programIds)
                          ? programIds.map((_, index) => (
                            <div
                              style={{
                                display: index > 0 ? "flex" : "block",
                                alignItems: "center",
                              }}
                              key={index}
                            >
                              <Select
                                label="Programa:"
                                name={`ProgramIds[${index}]`}
                                options={dropdownGenerate(
                                  requestPrograms,
                                  "Programas"
                                )}
                              />
                              {index > 0 && (
                                <button
                                  className="FormRelease__delete"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              )}
                            </div>
                          ))
                          : null}
                        <DefaultBtn
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                        >
                          Adicionar programa
                        </DefaultBtn>
                      </>
                    );
                  }}
                />
              ) : null}
              {!view.advert && courses ? (
                <FieldArray
                  name="CourseIds"
                  render={(arrayHelpers) => {
                    const courseIds = formik.values.CourseIds;
                    return (
                      <>
                        {validateArray(courseIds)
                          ? courseIds.map((_, index) => (
                            <div
                              style={{
                                display: index > 0 ? "flex" : "block",
                                alignItems: "center",
                              }}
                              key={index}
                            >
                              <Select
                                label="Curso:"
                                name={`CourseIds[${index}]`}
                                options={dropdownGenerate(
                                  requestCourses,
                                  "Cursos"
                                )}
                              />
                              {index > 0 && (
                                <button
                                  className="FormRelease__delete"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              )}
                            </div>
                          ))
                          : null}
                        <DefaultBtn
                          type="button"
                          onClick={() => arrayHelpers.push("")}
                        >
                          Adicionar curso
                        </DefaultBtn>
                      </>
                    );
                  }}
                />
              ) : null}
              <FormikControl
                control="date"
                label="Fecha de inicio"
                name="StartDate"
                placeholderText="Fecha de inicio"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormikControl
                control="date"
                label="Fecha de conclusión"
                name="FinishDate"
                placeholderText="Fecha de conclusión"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormikControl
                control="input"
                type="checkbox"
                label="Publicar anuncio"
                name="Public"
              />
              <Icon handleModal={() => setmodal(!modal)} ico="edit">
                {view.advert ? "Editar" : "Crear"} anuncio
              </Icon>
              {modal && (
                <EditContent
                  name="Content"
                  manageContent={formik.setValues}
                  title={formik.values.Title}
                  initial={formik.initialValues.Content}
                  onClose={() => setmodal(!modal)}
                />
              )}
              <br />
              <br />
              <DropzoneInput
                name="File"
                small={true}
                label="Subir archivo"
                uploadFiles={formik.setValues}
              />
              {load ? (
                <ActionButton
                  disabled={uploading}
                  type="submit"
                  // onClick={onSubmit}
                  onCancel={() => handleView(!view.status, view.advert)}
                  textBtn="Guardar"
                  textCancel="Cancelar"
                />
              ) : (
                <Loading />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default FormRelease;
