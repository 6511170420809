import './HomeHero.css'
import { useEffect } from 'react'
import { H2, H3 } from '../../components/text'
import { RenderButton, ButtonFloating } from '../../components/buttons'
import { changeLayout } from '../../store/actions'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
const HomeHero = ({ changeLayout }) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])
  //
  return (
    <div className="HomeHero">
      <Link to="/catalogue/short-courses" className="HomeHero__cgi">
        <div className="HomeHero__cgiimg">
          <div className="HomeHero__cgiCard">
            <CardHero background="var(--purple)" rotate="15">
              <CardHero background="var(--orange2)" rotate="-15">
                <CardHero background="var(--green)" rotate="-15">
                  <img
                    className="HomeHero__cgiCardImg"
                    src="https://res.cloudinary.com/pataformacgi/image/upload/h_200,c_scale,q_auto,f_auto/v1655472643/assets/WhatsApp_Image_2022-06-03_at_12.01.19_PM-removebg-preview_stxeln_btwcnv.png"
                    alt=""
                  />
                </CardHero>
              </CardHero>
            </CardHero>
          </div>
          <RenderButton background="#773686">Ver cursos</RenderButton>
          <br />
          <div className="">
            <H2 fontSize="3.5rem" textAlign="center">
              ¡Aprende sin límites!
            </H2>
            <H3
              fontSize="2rem"
              fontWeight="500"
              color="var(--black)"
              textAlign="center"
            >
              Con más de 80 cursos online
            </H3>
          </div>
          <center>
            <img
              src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472641/assets/logo_1_fjsiuz_s0oyov.png"
              alt="logotipo"
              className="HomeHero__logo"
            />
          </center>
        </div>
      </Link>
      <a
        href="https://www.capacitakids.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="HomeHero__cgikids"
      >
        <div className="HomeHero__cgikidsimg">
          <img
            src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472644/assets/Screenshot_2022-06-03_174634_umb8zc_tbkpvs.png"
            alt=""
            className="HomeHero__kidsImg"
          />
          <H2
            fontSize="4.5rem"
            fontWeight="900"
            color="var(--white)"
            textAlign="center"
          >
            CURSOS <br /> PARA NIÑ@S
          </H2>
          <div className="HomeHero__kidsRenderBtn">
            <RenderButton background="#773686">Ver cursos</RenderButton>
          </div>
          <div className="HomeHero__kidsBtn">
            <img
              src="https://res.cloudinary.com/wmcgi/image/upload/v1655473173/assets/girl_afuera_pkzttp_ml0g8u.png"
              alt=""
            />
            <RenderButton background="#773686">Ver cursos</RenderButton>
            <img
              src="https://res.cloudinary.com/wmcgi/image/upload/v1655473165/assets/boy_afuera_i1gdyn_zd9bmw.png"
              alt=""
            />
          </div>
        </div>
      </a>
      <ButtonFloating />
    </div>
  )
}
const CardHero = ({ children, background, rotate }) => {
  return (
    <div
      style={{ background: background, transform: `rotateZ(${rotate}deg)` }}
      className="CardHero"
    >
      {children}
    </div>
  )
}
export default connect(null, {
  changeLayout,
})(HomeHero)
