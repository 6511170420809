import './CoursesInterested.css'
import ListDocument from '../ListDocument'
import { useAxiosAuth } from '../../../hooks'
import FormAddCourse from '../FormAddCourse'
import { H2, H3 } from '../../../components/text'
import FormPreRegister from '../FormPreRegister'
import { Loading } from '../../../components/animation'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { getAction } from '../../../services/action/ActionAuthorization'
import { ActionButton, DefaultBtn } from '../../../components/buttons'
import FormChildrenRegister from './FormChildrenRegister'
import ListChildren from './ListChildren'
const CoursesInterested = (props) => {
  const { onClose, user, changeModal, handleReload } = props

  const { response, loading, reload: reloadInterests, error } = useAxiosAuth({
    method: 'GET',
    url: `/contact/${user.contactId}/interests`,
  })
  function handleAddCourse() {
    changeModal(
      true,
      '',
      <FormAddCourse
        {...props}
        reload={handleReload}
        onCancel={() =>
          changeModal(true, '', <CoursesInterested {...props} />, null)
        }
      />,
      null,
    )
  }

  return (
    <>
      <H2 textAlign="center">Cursos interesados</H2>
      {loading ? (
        <Loading />
      ) : error !== 200 ? (
        <H3 color="var(--red)">No se pudo cargar</H3>
      ) : validateArray(response.courseInterest) ? (
        response.courseInterest.map((courses) => (
          <ListCourses
            {...props}
            key={courses.contactInterestId}
            user={{
              ...user,
              userExternalId: response.preregisterFatherExternal,
            }}
            courses={courses}
            reloadInterests={reloadInterests}
          />
        ))
      ) : (
        <H3>No hay cursos de interes</H3>
      )}
      <ActionButton
        onClick={handleAddCourse}
        onCancel={onClose}
        textBtn="Adicionar curso"
      />
    </>
  )
}

const ListCourses = (props) => {
  const { courses, changeModal, handleReload, reloadInterests, user } = props

  function handlePreRegister(course) {
    changeModal(
      true,
      '',
      <FormPreRegister
        {...props}
        course={course}
        reload={handleReload}
        onCancel={() =>
          changeModal(true, '', <CoursesInterested {...props} />, null)
        }
        reloadInterests={reloadInterests}
      />,
      null,
    )
  }

  async function childrenPreRegister(course) {
    await getAction(`/users/email/${user.email}`).then((response) => {
      if (response && validateStatus(response.status)) {
        changeModal(
          true,
          '',
          <ListChildren
            {...props}
            course={course}
            reload={handleReload}
            tutor={response.data}
            onCancel={() =>
              changeModal(true, '', <CoursesInterested {...props} />, null)
            }
          />,
          null,
        )
      } else {
        changeModal(
          true,
          '',
          <FormChildrenRegister
            {...props}
            course={course}
            reload={handleReload}
            onCancel={() =>
              changeModal(true, '', <CoursesInterested {...props} />, null)
            }
          />,
          null,
        )
      }
    })
  }

  function handleFile(course) {
    changeModal(
      true,
      '',
      <ListDocument
        {...props}
        course={course}
        reload={handleReload}
        onCancel={() =>
          changeModal(true, '', <CoursesInterested {...props} />, null)
        }
      />,
      null,
    )
  }
  return (
    <div
      style={{
        display: 'flex',
        gap: '5px',
        justifyContent: 'space-between',
        marginBottom: '10px',
      }}
    >
      <div className="ListCourses__list">
        {courses.programManagerList.map((program) => (
          <H3
            key={program.programManagerId}
            fontWeight="500"
            color="var(--gray)"
            width="fit-content"
            margin="0"
          >
            {program.name}
          </H3>
        ))}
      </div>
      <div
        className="ListCourses__btn"
        style={{ display: 'flex', gap: '5px', width: 'fit-content' }}
      >
        <DefaultBtn
          width="fit-content"
          onClick={
            courses.typeCourse === 'childCourse'
              ? () => childrenPreRegister(courses)
              : () => handlePreRegister(courses)
          }
        >
          <i className="fas fa-tag"></i>
        </DefaultBtn>
        {courses.preregisterId && courses.typeCourse === 'normalCourse' ? (
          <DefaultBtn onClick={() => handleFile(courses)}>
            <i className="fas fa-money-check-alt"></i>
          </DefaultBtn>
        ) : null}
      </div>
    </div>
  )
}
export default CoursesInterested
