import { ButtonIcon } from '../buttons'

export const ShowIconType = ({ type, icon, content }) => {
  return parseInt(type) === 1 ? (
    <ButtonIcon content={content} icon={icon} />
  ) : (
    <>_</>
  )
}
export const showIcon = (index, type) => {
  
  switch (index) {
    case 0:
      return <ShowIconType type={type} icon="sun" content="Mañana" />
    case 1:
      return <ShowIconType type={type} icon="cloud-moon" content="Tarde" />
    case 2:
      return <ShowIconType type={type} icon="moon" content="Noche" />
    default:
      return <>_</>
  }
}
