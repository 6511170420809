export function isAuthenticated(user) {
  return { bool: user ? user.authenticate : false, route: '/auth/login' }
}
export function accessCourse(user) {
  return { bool: user ? user.authenticate : false, route: '/courses' }
}
export function noAuthenticate(user) {
  return { bool: user ? !user.authenticate : true, route: '/' }
}
export function accessInstitution(user) {
  if (user.institutions.length > 0) {
    return { bool: true, route: '/' }
  } else {
    return { bool: false, route: '/' }
  }
}
