import { changeLayout, changeInfo } from '../../store/actions'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { useActionParam } from '../../hooks'
import { getAction } from '../../services/action/ActionAuthorization'
import { TableDefault } from '../../components/table'
import { H2, SubtitleData } from '../../components/text'
import { LoadCircle } from '../../components/animation'
const header = [
  { key: 'photoUrl', name: 'Avatar', type: 'img' },
  { key: 'firstName', name: 'Nombres' },
  { key: 'lastName', name: 'Apellidos' },
  { key: 'totalScore', name: 'Nota final' },
]
const CourseNotes = (props) => {
  const {
    changeLayout,
    changeInfo,
    match: {
      params: { cid },
    },
  } = props
  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: `/course/${cid}/scores`,
  })
  useEffect(() => {
    changeLayout('BigInformation')
    changeInfo(<></>)
  }, [changeLayout, changeInfo])
  function preConfig(data) {
    let aux = []
    data.forEach((element) => {
      const {
        userData: { ...rest },
        lessonScoreList,
        totalScore,
      } = element
      aux.push({ ...rest, lessonScoreList, totalScore })
    })
    console.log(aux)
    return aux
  }
  function LessonNotes({ user }) {
    const headerNotes = [
      { key: 'name', name: 'Lección' },
      { key: 'lessonType', name: 'Tipo' },
      {
        key: 'status',
        name: 'Estado',
        type: 'textColor',
        color: {
          Inconcluso: '#c93f3f75',
          Concluido: '#46958575',
        },
      },
      { key: 'percentage', name: 'Porcentaje' },
      { key: 'finalScore', name: 'Nota' },
    ]
    function mainConfig(list) {
      list.forEach((u) => {
        if (u.status === 'available' || u.status === 'Inconcluso') {
          u.status = 'Inconcluso'
        } else {
          u.status = 'Concluido'
        }
        if (u.finalScore === -1) {
          u.finalScore = 0
        }
      })
      return list
    }
    return (
      <div>
        <H2 textAlign="center">Datos del estudiantes</H2>
        <SubtitleData subtitle="Nombres">{user.firstName}</SubtitleData>
        <SubtitleData subtitle="Apellidos">{user.lastName}</SubtitleData>
        <SubtitleData subtitle="Correo">{user.email}</SubtitleData>
        <SubtitleData subtitle="Nota Final">{user.totalScore}</SubtitleData>
        <TableDefault
          header={headerNotes}
          main={mainConfig(user.lessonScoreList)}
        />
      </div>
    )
  }
  return (
    <>
      {statusRequest ? (
        <TableDefault
          handleInfo={(user) => changeInfo(<LessonNotes user={user} />)}
          header={header}
          main={preConfig(dataRequest)}
        />
      ) : (
        <LoadCircle />
      )}
    </>
  )
}

export default connect(null, { changeLayout, changeInfo })(CourseNotes)
