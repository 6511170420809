import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormikControl } from "../input";
import { NormalButton } from "../buttons";
import { useState } from "react";
import { Loading } from "../animation";
import { connect } from "react-redux";
import { openSnackbar } from "../../store/actions";
import { getSearchUser } from "../../services/action/InstitutionAction";
const SearchUser = ({ handelStep, openSnackbar, handelCancel }) => {
  const [load, setload] = useState(true);
  const { t } = useTranslation("Register");
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object({
    // email: Yup.string().email(t("invalidEmail")).required(t("fieldObligatory")),
  });
  function save(res, values) {
    setload(true);
    if (res.status === 200 && res) {
      openSnackbar("Usuario Encontrado", true, true);
      handelStep({ ...values, ...res.data }, 2);
    } else {
      openSnackbar("Usuario no encontrado", true, false);
    }
  }
  const onSubmit = async (values) => {
    try {
      setload(false);
      await getSearchUser(values.email).then((response) => {
        save(response, values);
      });
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form>
            <FormikControl
              control="input"
              type="email"
              label={t("email")}
              name="email"
            />
            {load ? (
              <>
                <NormalButton type="submit" disabled={!formik.isValid}>
                  Buscar
                </NormalButton>
                {handelCancel && (
                  <NormalButton
                    disabled={!formik.isValid}
                    onClick={() =>
                      handelStep({ email: formik.values.email }, 2)
                    }
                    background={true}
                    type="button"
                  >
                    Usuario nuevo
                  </NormalButton>
                )}
              </>
            ) : (
              <Loading />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { openSnackbar })(SearchUser);
