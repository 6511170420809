import * as Yup from 'yup'
import { useState, useEffect } from 'react'
import { H3, SubtitleData } from '../../../components/text'
import { useAxiosAuth } from '../../../hooks'
import { GlobalForm } from '../../../components/form'

const SearchLesson = (props) => {
  const { changeInfo } = props
  const [lid, setlid] = useState(null)

  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/lesson/${lid}/origin`,
  })

  useEffect(() => {
    if (!loading) {
      reload()
    }
  }, [lid])

  return (
    <div>
      <GlobalForm
        InputsJson={[
          {
            name: 'lesson',
            initial: '',
            validate: Yup.string().required('Campo requerido'),
            type: 'number',
            label: 'Id de la lección a buscar',
          },
        ]}
        textBtn="Buscar"
        title="Buscar lección por ID"
        data={{}}
        load={!loading}
        onSubmit={(values) => setlid(values.lesson)}
        onCancel={() => changeInfo(<></>)}
      />
      {lid ? (
        response ? (
          <>
            <H3
              color="var(--gray)"
              textTransform="uppercase"
              margin="15px 0 10px"
            >
              Lección encontrada
            </H3>
            <div className="">
              {response.programId && (
                <SubtitleData subtitle="Programa:">
                  <a
                    style={{ color: 'var(--gray)' }}
                    href={`/program/${response.programId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {response.programName} - {response.versionaName}
                  </a>
                </SubtitleData>
              )}
              <SubtitleData subtitle="Curso:">
                <a
                  style={{ color: 'var(--gray)' }}
                  href={`/program${
                    response.programId ? '/' + response.programId : ''
                  }/course/${response.courseId}/module/1/admin`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {response.courseName}
                  {!response.programId && ` - ${response.versionaName}`}
                </a>
              </SubtitleData>
              <SubtitleData subtitle="Módulo:">
                <a
                  style={{ color: 'var(--gray)' }}
                  href={`/program${
                    response.programId ? '/' + response.programId : ''
                  }/course/${response.courseId}/module/${
                    response.moduleId
                  }/admin`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {response.moduleName}
                </a>
              </SubtitleData>
              <H3>Lección:</H3>
              <div
                style={{
                  border: '1px solid gray',
                  padding: '5px',
                  borderRadius: '10px',
                }}
              >
                <a
                  style={{
                    textDecoration: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  href={`/program${
                    response.programId ? '/' + response.programId : ''
                  }/course/${response.courseId}/module/${response.moduleId}/${
                    response.lessonType !== 'homework'
                      ? response.lessonType
                      : 'home-work'
                  }/${response.lessonId}${
                    response.lessonType === 'quiz' ? '/ratings' : ''
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <H3
                    fontWeight="500"
                    border="1px solid var(--green)"
                    width="fit-content"
                    alignSelf="flex-end"
                    padding="5px"
                    borderRadius="5px"
                    color="var(--green)"
                    margin="0"
                  >
                    {response.lessonType}
                  </H3>
                  <H3 color="var(--green)" margin="5px 0 0" fontWeight="500">
                    {response.lessonName}
                  </H3>
                </a>
              </div>
            </div>
          </>
        ) : (
          <H3 margin="10px 0 0">No se encontró la lección</H3>
        )
      ) : null}
    </div>
  )
}

export default SearchLesson
