import { NavLink } from "react-router-dom";
import styled from "styled-components";
const NavStyle = styled.div`
  text-transform: uppercase;
  .NavCatalogue__btn {
    background: ${(props) => props.color + "1A"};
    color: ${(props) => props.color};
    border: 1px solid ${(props) => props.color};
    padding: 10px 15px;
    border-radius: 10px;
    margin-top: 10px;
    font-size: 1.4rem;
    text-decoration: none;
    font-weight: 500;
  }
  .NavCatalogue__btn--activate {
    background: ${(props) => props.color};
    color: var(--white);
  }
`;
const NavCatalogue = ({ children, url, type, color = "#458e7f" }) => {
  return (
    <NavStyle color={color} className="NavCatalogue">
      {type ? (
        <a
          className="NavCatalogue__btn"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      ) : (
        <NavLink
          exact
          to={url}
          className="NavCatalogue__btn"
          activeClassName="NavCatalogue__btn--activate"
        >
          {children}
        </NavLink>
      )}
    </NavStyle>
  );
};

export default NavCatalogue;
