import { H3, H2 } from '../../components/text'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
export const CardsContents = () => {
  const cards = [
    {
      img:
        'https://res.cloudinary.com/pataformacgi/image/upload/c_scale,q_auto,f_auto/v1655472641/assets/CapacitaGlobal_grmetd.png',
      text: 'Cursos cortos',
      color: 'var(--purple)',
      url: '/catalogue/short-courses',
      type: 'link',
    },
    {
      img:
        'https://res.cloudinary.com/pataformacgi/image/upload/c_scale,q_auto,f_auto/v1655472641/assets/CapacitaGlobal_grmetd.png',
      text: 'Webinar Midas.li',
      color: '#033b8e',
      url: '/webinar',
      type: 'link',
    },
    {
      img:
        'https://res.cloudinary.com/pataformacgi/image/upload/c_scale,q_auto,f_auto/v1655472641/assets/CapacitaGlobal_grmetd.png',
      text: 'Matemáticas para niños',
      color: 'var(--green)',
      url: 'https://wondermath.capacitaglobal.org/',
    },
  ]

  return (
    <>
      <H2 textAlign="center" margin="60px 0 10px" fontSize="2.5rem">
        Nuestro Contenidos
      </H2>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          flexWrap: 'wrap',
          gap: '10px',
        }}
      >
        {cards.map((value, key) =>
          value.type === 'link' ? (
            <Link key={key} to={value.url} style={{ textDecoration: 'none' }}>
              <CardContent className="Cards" color={value.color}>
                <img src={value.img} alt="Logotipo" />
                <H3 fontSize="2rem" margin="0" color="var(--black)">
                  {value.text}
                </H3>
              </CardContent>
            </Link>
          ) : (
            <a
              key={key}
              href={value.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <CardContent className="Cards" color={value.color}>
                <img src={value.img} alt="Logotipo" />
                <H3 fontSize="2rem" margin="0" color="var(--black)">
                  {value.text}
                </H3>
              </CardContent>
            </a>
          ),
        )}
      </div>
    </>
  )
}
const CardContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;
  align-items: center;
  width: 250px;
  border-radius: 10px;
  padding: 15px;
  border: ${(props) => `2px solid ${props.color}`};
  & img {
    width: 80px;
  }
  &:hover {
    background: ${(props) => `${props.color}`};
    cursor: pointer;
  }
  &:hover .H3 {
    color: var(--white) !important;
  }
`
