import { useAxios } from '../../hooks'
import { useEffect, useState } from 'react'
import { LoadCircle } from '../../components/animation'
import { H2, SubtitleData } from '../../components/text'
import { validateArray } from '../../utils/Validation'

const Geolocation = () => {
  const [position, setposition] = useState({
    latitude: null,
    longitude: null,
  })
  const { response, loading, reload } = useAxios({
    method: 'GET',
    url:
      position.latitude && position.longitude
        ? `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.latitude},${position.longitude}&key=AIzaSyBpryRjZq881pzHF62NDj0RHL41pFmplzo`
        : null,
  })
  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position])
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setposition(position.coords)
      },
      function (error) {
        console.error('Error Code = ' + error.code + ' - ' + error.message)
      },
    )
  }, [])
  return (
    <div className="">
      <H2>Ubicación</H2>
      {position.latitude && position.longitude && !loading && response ? (
        <>
          {validateArray(response.results) && (
            <SubtitleData subtitle="Dirección">
              {response.results[0].formatted_address}
            </SubtitleData>
          )}
        </>
      ) : (
        <LoadCircle />
      )}
    </div>
  )
}

export default Geolocation
