import { H2, H3 } from '../../../../components/text'
import { validateArray, validateStatus } from '../../../../utils/Validation'
import { RenderButton, ActionButton } from '../../../../components/buttons'
import { BoxFlex } from '../../../../components/boxes'
import { Loading } from '../../../../components/animation'
import { RenderModal } from '../../../../components/modal'
import { useAxiosAuth } from '../../../../hooks'
import {
  deleteAction,
  putAction,
} from '../../../../services/action/ActionAuthorization'
import { useState } from 'react'
const Deletelessons = (props) => {
  const { cid, openSnackbar } = props
  const [modal, setmodal] = useState(null)
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/course/${cid}/deleteLessonsList`,
  })

  async function handleDelete() {
    await deleteAction(`/lesson/${modal.id}/deletepermanently`).then(
      (response) => {
        if (validateStatus(response.status)) {
          openSnackbar('Se elimino la lección permanentemente', true, true)
          setmodal(null)
          reload()
        } else {
          openSnackbar('No se pudo eliminar', true, false)
        }
      },
    )
  }

  async function handleUndo() {
    await putAction(`/lesson/${modal.id}/resetLesson`).then((response) => {
      if (validateStatus(response.status)) {
        openSnackbar('Se guardaron los cambios', true, true)
        setmodal(null)
        reload()
      } else {
        openSnackbar('No se pudo guardar', true, false)
      }
    })
  }

  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        {modal !== null && (
          <RenderModal width="500px">
            <H2 textAlign="center">
              {modal.typeModal === 'trash'
                ? '¿Estas seguro que quieres eliminar esta lección permanentemente?'
                : '¿Estas seguro que quieres restablecer la lección?'}
            </H2>
            <H3 textAlign="center" margin="0" fontSize="1.8rem">
              {modal.name}
            </H3>
            <ActionButton
              onClick={modal.typeModal === 'trash' ? handleDelete : handleUndo}
              textBtn={modal.typeModal === 'trash' ? 'Eliminar' : 'Restablecer'}
              onCancel={() => setmodal(null)}
            />
          </RenderModal>
        )}
        <div>
          <H2 textAlign="center">Lecciones eliminadas</H2>
          {validateArray(response) &&
            response.map((item) => (
              <BoxFlex justify="space-between" margin="0 0 5px">
                <H3 margin="0" fontSize="1.8rem">
                  {item.name}
                </H3>
                <BoxFlex gap="6px">
                  <H3
                    margin="0"
                    fontWeight="500"
                    border="1px solid gray"
                    padding="4px 8px"
                    borderRadius="5px"
                  >
                    {item.lessonType}
                  </H3>
                  <RenderButton
                    onClick={() => setmodal({ ...item, typeModal: 'undo' })}
                    background="var(--green)"
                  >
                    <i className="fas fa-undo"></i>
                  </RenderButton>
                  <RenderButton
                    onClick={() => setmodal({ ...item, typeModal: 'trash' })}
                    background="var(--red)"
                  >
                    <i className="fas fa-trash"></i>
                  </RenderButton>
                </BoxFlex>
              </BoxFlex>
            ))}
        </div>
      </>
    )
  }
}

export default Deletelessons
