import { DefaultBtn } from '../buttons'
import { BoxFlex, CirclePointer, ToolTip } from '../boxes'
import { Search } from '../inputs'
import { H3, H4 } from '../text'
import './TableHeader.css'
import { rolName } from '../../utils/Constant'
import { filterByColors, setColorPoint } from '../../utils/ProcessData'
import { useState } from 'react'
const TableHeader = (props) => {
  const {
    buttonAdd,
    choice,
    // header,
    search,
    onChange,
    options,
    onSearch,
    listOrder,
    seturl,
    url,
    reload,
    listStatus,
    setstatus,
    status,
    listProgram,
    listRols,
    listInstitution,
    setInstitutionId,
    setcategory0,
    category0,
    setFilterColor,
    filterColor,
    showPriorityfilter,
  } = props

  const [labelIndicator, setlabelIndicator] = useState({
    category: 'Todas las categorias',
    status: 'Por estado',
    rolName: 'Seleccione un rol',
  })
  function handleSetUrl(value) {
    seturl((a) => ({
      ...a,
      OrderBy: value,
    }))
    reload()
  }
  function handleSetRol(value) {
    seturl((a) => ({
      ...a,
      rolId: value.key,
    }))
    setlabelIndicator({
      ...labelIndicator,
      rolName: rolName[value.name],
    })
    reload()
  }
  function handleInstitution(value) {
    setInstitutionId(value)
    reload()
  }
  function handleSetStatus(value) {
    setlabelIndicator({
      ...labelIndicator,
      status: value.name,
    })
    setstatus(value.key)
    reload()
  }
  function handleSetColor(value) {
    setFilterColor(value)
    reload()
  }

  function ContentOrder() {
    return (
      <div className="ContentOrder">
        <H3>Ordenar por:</H3>
        {listOrder.map((element) => (
          <H4
            color={url === element.key ? 'var(--green)' : ''}
            onClick={() => handleSetUrl(element.key)}
            key={element.key}
            cursor={'pointer'}
          >
            {element.name}
          </H4>
        ))}
      </div>
    )
  }
  function ContentStatus() {
    return (
      <div className="ContentStatus">
        <H3>Por Estados:</H3>
        {listStatus.map((element) => (
          <H4
            color={status === element.key ? 'var(--green)' : ''}
            onClick={() => handleSetStatus(element)}
            key={element.key}
            cursor={'pointer'}
          >
            {element.name}
          </H4>
        ))}
      </div>
    )
  }
  function FilterByColors() {
    return (
      <div className="ContentStatus">
        <H3 textAlign="center"> Por Colores</H3>
        {filterByColors.map((element) => (
          <H4
            color={filterColor === element.filter ? 'var(--green)' : ''}
            onClick={() => handleSetColor(element)}
            key={element.colorValue}
            cursor={'pointer'}
          >
            <div
              style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '0.5rem',
              }}
            >
              {element.colorName}{' '}
              <CirclePointer background={setColorPoint(element.priority)} />
            </div>
          </H4>
        ))}
      </div>
    )
  }
  function handleSetCategory(value) {
    setlabelIndicator({
      ...labelIndicator,
      category: value.name,
    })
    setcategory0(value.id)

    reload()
  }
  function ContentCategory() {
    return (
      <div className="ContentStatus">
        <H3>Categorias:</H3>
        {listProgram.map((element) => (
          <H4
            color={category0 === element.id ? 'var(--green)' : ''}
            onClick={() => handleSetCategory(element)}
            key={element.id}
            cursor={'pointer'}
          >
            {element.name}
          </H4>
        ))}
      </div>
    )
  }
  function ContentRolList() {
    return (
      <div className="ContentOrder">
        {listRols.map((element) => {
          element.key = element.key ? element.key : element.id

          return (
            <H4
              color={url === element.key ? 'var(--green)' : ''}
              onClick={() => handleSetRol(element)}
              key={element.key}
              cursor={'pointer'}
            >
              {rolName[element.name]}
            </H4>
          )
        })}
      </div>
    )
  }
  function ContentInstitucionList() {
    return (
      <div className="ContentOrder">
        {[
          {
            id: 1,
            name: 'Capacita global internacional',
          },
          ...listInstitution,
        ].map((element) => {
          element.key = element.key ? element.key : element.id

          return (
            <H4
              color={url === element.key ? 'var(--green)' : ''}
              onClick={() => handleInstitution(element.key)}
              key={element.key}
              cursor={'pointer'}
            >
              {element.name}
            </H4>
          )
        })}
      </div>
    )
  }

  return (
    <div className="TableHeader">
      {choice ? (
        <BoxFlex>
          <Search
            onClick={onSearch}
            search={search}
            onChange={onChange}
            options={options}
          />

          {listStatus ? (
            <ToolTip
              interactive={true}
              trigger="click"
              placement="bottom"
              content={<ContentStatus />}
            >
              {listStatus.length !== 0 ? (
                <div>
                  <DefaultBtn color="var(--green)">
                    {labelIndicator.status}{' '}
                    <i className="fas fa-sort-amount-down"></i>
                  </DefaultBtn>
                </div>
              ) : null}
            </ToolTip>
          ) : null}

          {listProgram && listProgram.length > 1 ? (
            <ToolTip
              interactive={true}
              trigger="click"
              placement="bottom"
              content={<ContentCategory />}
            >
              {listProgram.length > 1 ? (
                <div>
                  <DefaultBtn color="var(--green)">
                    {labelIndicator.category}{' '}
                    <i className="fas fa-sort-amount-down"></i>
                  </DefaultBtn>
                </div>
              ) : null}
            </ToolTip>
          ) : null}

          {listRols && listRols.length > 1 ? (
            <ToolTip
              interactive={true}
              trigger="click"
              placement="bottom"
              content={<ContentRolList />}
            >
              {listRols.length > 1 ? (
                <div>
                  <DefaultBtn color="var(--green)">
                    {labelIndicator.rolName}{' '}
                    <i className="fas fa-user-shield"></i>
                  </DefaultBtn>
                </div>
              ) : null}
            </ToolTip>
          ) : null}
          {/* FilterByColors */}
          {showPriorityfilter && (
            <ToolTip
              interactive={true}
              trigger="click"
              placement="bottom"
              content={<FilterByColors />}
            >
              <div>
                <DefaultBtn color="var(--green)">
                  {filterColor.colorName} <i className="fas fa-palette"></i>
                </DefaultBtn>
              </div>
            </ToolTip>
          )}

          {listInstitution && listInstitution.length > 1 ? (
            <ToolTip
              interactive={true}
              trigger="click"
              placement="bottom"
              content={<ContentInstitucionList />}
            >
              {listInstitution.length > 1 ? (
                <div>
                  <DefaultBtn color="var(--green)">
                    Institución
                    <i className="fas fa-university"></i>
                  </DefaultBtn>
                </div>
              ) : null}
            </ToolTip>
          ) : null}

          <ToolTip
            interactive={true}
            trigger="click"
            placement="bottom"
            content={<ContentOrder />}
          >
            {listOrder.length !== 0 ? (
              <div>
                <DefaultBtn color="var(--green)">
                  Ordenar <i className="fas fa-sort-amount-down"></i>
                </DefaultBtn>
              </div>
            ) : null}
          </ToolTip>
        </BoxFlex>
      ) : (
        <div></div>
      )}
      <div className="TableHeader__add">{buttonAdd}</div>
    </div>
  )
}

export default TableHeader
