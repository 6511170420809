import { useEffect } from "react";
import { TableOptions } from "../../components/table";
import { useAxiosAuth } from "../../hooks";
import { LoadCircle } from "../../components/animation";
import { H2, SubtitleData, H4 } from "../../components/text";
import { convertD } from "../../utils/ConvertDate";
import { connect } from "react-redux";
import { changeLayout, changeInfo, changeModal } from "../../store/actions";
const header = [
  { key: "name", name: "Nombre" },
  { key: "lastName", name: "Apellidos" },
  {
    key: "status",
    name: "Estado",
    type: "textColor",
    color: {
      pending: "#c93f3f75",
      completed: "#46958575",
    },
  },
  // { key: 'totalAmount', name: 'Precio total' },
  { key: "creationDate", name: "Fecha del pedido", type: "date" },
];
const listStatus = [
  { key: "", name: "Todos" },
  { key: "cancelled", name: "Cancelado" },
  { key: "completed", name: "Completado" },
  { key: "pending", name: "Pendiente" },
];
const OrdersList = (props) => {
  const { changeInfo, changeModal, changeLayout } = props;

  useEffect(() => {
    changeInfo(<></>);
    changeLayout();
  }, [changeLayout, changeInfo]);

  function handleOrder(user) {
    changeInfo(
      <OrderAside
        user={user}
        // handleReload={handleReload}
        {...props}
        changeModal={changeModal}
      />
    );
  }
  const listOrder = [
    { key: "", name: "Fecha de Pedido" },
    { key: "amount", name: "Precio de Pedidos" },
    { key: "lastName", name: "Apellidos" },
  ];
  return (
    <>
      <TableOptions
        param={"/order/viewerOrders"}
        header={header}
        listOrder={listOrder}
        choice={true}
        // handleReload={reload}
        handleInfo={handleOrder}
        listStatus={listStatus}
      />
    </>
  );
};

const OrderAside = (props) => {
  const { user, changeInfo, changeModal } = props;
  const { loading, response, reload } = useAxiosAuth({
    method: "GET",
    url: `/order/${user.orderId}/detailed`,
  });
  const listStatus = {
    pending: "Pendiente",
    completed: "Completado",
    delisted: "Excluido",
  };
  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <H2 textAlign="center">Detalles del pedido</H2>
      <SubtitleData subtitle="Nombres">{response.name}</SubtitleData>
      <SubtitleData subtitle="Apellidos">{response.lastName}</SubtitleData>
      <SubtitleData subtitle="Correo">{response.email}</SubtitleData>
      <SubtitleData subtitle="Fecha del pedido">
        {convertD(response.creationDate, "LLLL")}
      </SubtitleData>
      <SubtitleData subtitle="Estado del pedido">
        {listStatus[response.status]}
      </SubtitleData>
      <SubtitleData subtitle="Celular">{response.telephone}</SubtitleData>
      <SubtitleData subtitle="Precio total">
        {response.totalAmount} {response.currency}
      </SubtitleData>
      {response.type === "suscription" ? (
        <H2 color="var(--green)" textAlign="center">
          Plan de cursos cortos
        </H2>
      ) : (
        <>
          <H2>Cursos del pedido</H2>
          {response.paymentPlanProgramManagers.map((program) => (
            <div
              style={{
                padding: "10px",
                border: "1px solid var(--green)",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            >
              <H4 color="var(--green)" textAlign="center" margin="0">
                {program.name}
                {/* <ButtonIconDelete
                  icon={'trash-alt'}
                  idprogram={program.programManagerId}
                  idorder={response.orderId}
                  reloadPedido={reload}
                  changeModal={changeModal}
                  {...props}
                /> */}
              </H4>
            </div>
          ))}
        </>
      )}

      {/* <ActionButton
        onClick={() =>
          changeInfo(<EditOrder dataOrder={response} {...props} />)
        }
        onCancel={() => changeInfo(<></>)}
        textBtn="Editar estado"
      /> */}
    </>
  );
};

export default connect(null, {
  changeLayout,
  changeInfo,
  changeModal,
})(OrdersList);
