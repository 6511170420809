import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
const RouteSafe = ({ component: Component, ...rest }) => {
  const validate = (props) =>
    props.sesionUser ? (
      props.sesionUser.authenticate === true ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to="/auth/login" />
      )
    ) : window.localStorage.getItem("id") ? (
      <Component {...props} {...rest} />
    ) : (
      <Redirect to="/auth/login" />
    );

  return <Route {...rest} render={(props) => validate(props)} />;
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, null)(RouteSafe);
