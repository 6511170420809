import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { changeInfo, changeLayout } from '../../../../../store/actions'
import { VideoPlayer } from '../../../../../components/common'
import { H2, H4, H3 } from '../../../../../components/text'
import { VideoAside } from '../../../../../containers/programs'
import {
  getAction,
  putAction,
} from '../../../../../services/action/ActionAuthorization'
import { completeLection } from '../../../../../services/action/ProgramAction'
import { withRouter } from 'react-router-dom'
import { LoadCircle } from '../../../../../components/animation'
import { ToolTip } from '../../../../../components/boxes'
import { DefaultBtn } from '../../../../../components/buttons'
import { CircleProgress } from '../../../../../components/progress'
import { validateStatus } from '../../../../../utils/Validation'
import { useActionParam } from '../../../../../hooks'
import EditorView from '../../../../../components/editorjs/EditorView'
import './style/VideoContent.css'

const VideoContent = (props) => {
  const {
    changeLayout,
    match: { params },
  } = props
  const [videoModal, setvideoModal] = useState(false)
  const [videoProgress, setvideoProgress] = useState(0)
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: `lesson/${params.id}`,
  })

  useEffect(() => {
    if (dataRequest) {
      if (dataRequest.id !== parseInt(params.id)) {
        reload()
      }
    }
  }, [params, dataRequest, reload])

  /* useEffect(() => {
    if (dataRequest && dataRequest.status === 'available') {
      completeLection(params.id)
    }
  }, [params.id, dataRequest]) */

  // useEffect(() => {
  //   changeInfo(<VideoAside params={params} />)
  // }, [params, changeInfo])

  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])

  function handleOnEnded() {
    setvideoModal(!videoModal)
    // if (dataRequest && dataRequest.status === 'available') {
    //   completeLection(params.id)
    // }
  }
  /* function goBackModule() {
    let currentModule = props.history.location.pathname
    return currentModule
      .replace(props.match.params.id, '')
      .replace('/video', '')
  }
 */
  function onProgress(progress) {
    if (
      progress.playedSeconds - videoProgress > 60 ||
      (progress.played > 0.9 && progress.played < 0.92)
    ) {
      setvideoProgress(progress.playedSeconds)
      putAction(`/lesson/${params.id}/status`, {
        Status:
          dataRequest.status === 'completed' || progress.played > 0.9
            ? 'completed'
            : 'inProgress',
        Duration: progress.playedSeconds,
      })
    }
  }

  return (
    <div className="VideoContent">
      <div className="VideoContent__video">
        {validateStatus(statusRequest) ? (
          dataRequest && (
            <>
              <br />
              <H2>{dataRequest.name}</H2>
              {dataRequest.videoToListDto && dataRequest.videoToListDto.url ? (
                <>
                  {videoModal ? (
                    <div className="VideoContent__controls">
                      <H2>Siguiente lección</H2>
                      <H3 fontWeight="600" color="var(--black)">
                        {dataRequest.nextLessonName}
                      </H3>
                      <VideoControls
                        {...props}
                        data={dataRequest}
                        handleOnEnded={handleOnEnded}
                      />
                    </div>
                  ) : (
                    <div className="VideoContent__container">
                      <VideoPlayer
                        url={dataRequest.videoToListDto.url}
                        width="100%"
                        height="100%"
                        controls={true}
                        loop={false}
                        onEnded={handleOnEnded}
                        onProgress={onProgress}
                        startSecond={dataRequest.executionTime}
                        // playing={true}
                      />
                    </div>
                  )}
                  {dataRequest.videoToListDto.description && (
                    <>
                      <H4>Descripcion de la unidad</H4>
                      <EditorView
                        data={dataRequest.videoToListDto.description}
                      />
                    </>
                  )}
                </>
              ) : (
                <H3>El video no esta disponible</H3>
              )}
            </>
          )
        ) : (
          <LoadCircle />
        )}
      </div>
      <div className="VideoContent__aside">
        <VideoAside params={params} />
      </div>
    </div>
  )
}
const VideoControls = (props) => {
  const {
    history,
    data: { nextLesson, previousLesson, nextLessonType, previousLessonType },
    match: {
      params: { cid, mid, pid },
    },
    handleOnEnded,
  } = props
  const [time, settime] = useState(0)

  useEffect(() => {
    let interval
    if (time !== null) {
      interval = setTimeout(function () {
        if (time >= 5) {
          settime(null)
          handleLesson(nextLesson, nextLessonType)
        } else {
          settime(time + 0.01)
        }
      }, 10)
    }
    return function cleanup() {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])
  function handleCancel() {
    settime(null)
    handleOnEnded()
  }
  function handleLesson(lid, lessonType) {
    handleOnEnded()
    const type = {
      video: `/video/${lid}`,
      forum: `/forum/${lid}`,
      homework: `/home-work/${lid}`,
    }
    history.push(
      pid
        ? `/program/${pid}/course/${cid}/module/${mid}${type[lessonType] || ''}`
        : `/program/course/${cid}/module/${mid}${type[lessonType] || ''}`,
    )
  }
  return (
    <>
      <div className="VideoContent__btns">
        {previousLesson ? (
          <ToolTip content="Lección anterior">
            <i
              onClick={() => handleLesson(previousLesson, previousLessonType)}
              className="fas fa-step-backward"
            ></i>
          </ToolTip>
        ) : (
          <div style={{ width: '50px' }}></div>
        )}
        <div
          onClick={() => handleLesson(nextLesson, nextLessonType)}
          className="VideoControls__play"
        >
          <CircleProgress
            progress={(100 * time) / 5}
            colorCircle="var(--orange2)"
            colorProgress="var(--purple)"
            colorBackground="#bbbbbb"
          >
            <i className="fas fa-play"></i>
          </CircleProgress>
        </div>
        {nextLesson ? (
          <ToolTip content="Lección siguiente">
            <i
              onClick={() => handleLesson(nextLesson, nextLessonType)}
              className="fas fa-step-forward"
            ></i>
          </ToolTip>
        ) : (
          <div style={{ width: '50px' }}></div>
        )}
      </div>
      <H3 margin="15px 0" fontWeight="700" fontSize="2rem" color="var(--black)">
        {parseInt(6 - time)} segundos...
      </H3>
      <DefaultBtn onClick={handleCancel}>Cancelar</DefaultBtn>
    </>
  )
}
export default connect(null, {
  changeInfo,
  changeLayout,
})(withRouter(VideoContent))
