import React from 'react'
import { Formik, Form, Field, FieldArray } from 'formik'
import { validateArray } from '../../utils/Validation'
import { H4 } from '../text'
import { DefaultBtn } from '../buttons'
import { BoxFlex } from '../boxes'
import Input from './Input'

const ListInput = (props) => {
  const { label, name, buttonLabel = 'Adicionar' } = props

  return (
    <div>
      <H4 fontWeight="600">{label}</H4>
      <FieldArray
        name={name}
        render={(arrayHelpers) => {
          const fieldList = arrayHelpers.form.values[name]
          return (
            <div>
              {validateArray(fieldList)
                ? fieldList.map((_, index) => (
                    <BoxFlex wrap="nowrap" key={index}>
                      <Input name={`${name}.${index}`} />
                      {/* <Field name={`${name}.${index}`} /> */}
                      <DefaultBtn
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        -
                      </DefaultBtn>
                      {/* <button
                        type="button"
                        onClick={() => arrayHelpers.insert(index, '')}
                      >
                        +
                      </button> */}
                    </BoxFlex>
                  ))
                : null}
              <BoxFlex justify="flex-end">
                <DefaultBtn type="button" onClick={() => arrayHelpers.push('')}>
                  {buttonLabel} +
                </DefaultBtn>
              </BoxFlex>
            </div>
          )
        }}
      />
    </div>
  )
}
export default ListInput
