import HttpClient from '../HttpClient'
// export const getUploadHomework = (lid) => {
//   return new Promise((resolve, eject) => {
//     HttpClient.get(`lesson/${lid}/homeworks`)
//       .then((response) => {
//         resolve(response);
//       })
//       .catch((error) => {
//         console.log(error.response);
//         resolve(error.response);
//       });
//   });
// };
export const putLessonPublic = (lid, status) => {
  return new Promise((resolve, eject) => {
    HttpClient.putEmpty(`lesson/${lid}/${status}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const postGeneratecertificate = (lid) => {
  return new Promise((resolve, eject) => {
    HttpClient.postEmpty(`/certificate/generateCertificate/lesson/${lid}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const putLesson = (lid, file) => {
  return new Promise((resolve, eject) => {
    HttpClient.put(`/lesson/${lid}`, file)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const putHomeworkCalification = (data) => {
  return new Promise((resolve, eject) => {
    HttpClient.put(`/lesson/homeworkCalification`, data)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const getLessonEducator = (lid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/lesson/${lid}/lessonForEducator`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export function isFile(input) {
  if ('File' in window && input instanceof File) return true
  else return false
}
export const postNewQuestion = (qid, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(`/quiz/${qid}/newQuestion`, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const putUpdateQuestion = (qid, questionId, body) => {
  return new Promise((resolve) => {
    HttpClient.put(`/quiz/${qid}/updateQuestion/${questionId}`, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const deleteQuestion = (qid, questionId) => {
  return new Promise((resolve, eject) => {
    HttpClient.delete(`/quiz/${qid}/deleteQuestion/${questionId}`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const getQuizRatings = (lid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/lesson/${lid}/quizzes`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const postUploadFileForLesson = (lessonId, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(`/lesson/${lessonId}/UploadFileForLesson`, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const putOrderLessons = (moduleId, body) => {
  return new Promise((resolve, eject) => {
    HttpClient.put(`/module/${moduleId}/OrderLessons`, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
