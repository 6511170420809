import './ListChildren.css'
import FormChildren from './FormChildren'
import ListDocument from './ListDocument'
import { useAxiosAuth } from '../../hooks'
import { H2, H3 } from '../../components/text'
import config from '../../config.json'
import { Loading } from '../../components/animation'
import { DefaultBtn } from '../../components/buttons'
import { validateArray } from '../../utils/Validation'
import { BoxFlex, ToolTip } from '../../components/boxes'
import { useState } from 'react'
const ListChildren = (props) => {
  const { user, changeModal } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `${config.requestURL2}/users/${user.userExternalId}/listChild`,
  })

  async function handleFormChildren() {
    changeModal(
      true,
      '',
      <FormChildren
        {...props}
        onCancel={() =>
          changeModal(true, '', <ListChildren {...props} />, null)
        }
      />,
      null,
    )
  }

  return (
    <div className="">
      <BoxFlex justify="space-between" gap="20px">
        <H2 color="var(--green)" margin="0">
          NIÑ@S REGISTRADOS
        </H2>
        <DefaultBtn onClick={handleFormChildren}>
          Registrar niñ@ <i className="fas fa-user-plus"></i>
        </DefaultBtn>
      </BoxFlex>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((child) => (
          <CardChild {...props} child={child} key={child.id} reload={reload} />
        ))
      ) : (
        <H3 margin="15px 0 0">Este contacto no tiene niños registrados.</H3>
      )}
    </div>
  )
}
const CardChild = (props) => {
  const {
    child,
    user,
    reload,
    onSubmit,
    course,
    changeModal,
    openSnackbar,
  } = props
  const [load, setload] = useState(false)
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/preregisterquiz/child/${child.id}`,
  })

  async function handlePreRegister(child) {
    setload(true)
    onSubmit(
      {
        Email: user.Email || user.email,
        ExpeditionCi: user.ExpeditionCi || user.expeditionCi,
        IdDocument: user.IdDocument || user.ci,
        LastName: user.LastName || user.lastname,
        Name: user.Name || user.name,
        PhoneNumber: user.PhoneNumber || user.phone,
        RegisteredExternalUserId: child.id,
        RegisteredExternalFatherId: user.userExternalId,
        176: 357,
      },
      changeModal(true, '', <ListChildren {...props} />, null),
    )
    setload(false)
    reload()
  }

  function handleFile() {
    changeModal(
      true,
      '',
      <ListDocument
        user={user}
        course={{ ...course, preregisterId: response.id }}
        openSnackbar={openSnackbar}
        changeModal={changeModal}
        reload={reload}
        onCancel={() =>
          changeModal(true, '', <ListChildren {...props} />, null)
        }
      />,
      null,
    )
  }
  return (
    <div className="ListChildren__child">
      <div className="">
        <img
          src={
            child.character === 'boy'
              ? 'https://res.cloudinary.com/wmcgi/image/upload/v1655473173/assets/boy_feliz_jp5bbf_vnjy7y.png'
              : 'https://res.cloudinary.com/wmcgi/image/upload/v1655473177/assets/girl_feliz_tnkmki_dmd5lw.png'
          }
          alt="avatar"
        />
        <div className="">
          <H3 color="var(--green)" fontSize="2rem" margin="0">
            {child.firstName} {child.lastname}
          </H3>
          <H3 color="var(--gray)" fontSize="2rem" fontWeight="500" margin="0">
            {child.username}
          </H3>
        </div>
      </div>
      {load ? (
        <Loading />
      ) : (
        <div className="">
          {!loading && !response ? (
            child.statusPayment !== 'completed' && (
              <ToolTip content="Pre-registrar">
                <div>
                  <DefaultBtn onClick={() => handlePreRegister(child)}>
                    <i className="fas fa-tag"></i>
                  </DefaultBtn>
                </div>
              </ToolTip>
            )
          ) : (
            <ToolTip content="Documentos adjuntos">
              <div>
                <DefaultBtn onClick={handleFile}>
                  <i className="fas fa-money-check-alt"></i>
                </DefaultBtn>
              </div>
            </ToolTip>
          )}
        </div>
      )}
    </div>
  )
}
export default ListChildren
