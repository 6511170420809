import { useState } from 'react'
import { H2 } from '../../components/text'

import { BoxFlex } from '../../components/boxes'
import { Loading } from '../../components/animation'
import { DefaultBtn } from '../../components/buttons'
import { TableOptions, TableFilterContainer } from '../../components/table'
import { postAction } from '../../services/action/ActionAuthorization'
import { validateArray, validateStatus } from '../../utils/Validation'

const FormAddCourse = (props) => {
  const { user, openSnackbar, reload, onCancel } = props
  const [list, setlist] = useState([])
  const [load, setload] = useState(false)

  function handleSelect(item) {
    if (list.indexOf(item) <= -1) {
      setlist([...list, item])
    }
  }
  function handleRemove(item) {
    const listAux = remove(item)
    setlist([...listAux])
  }
  function remove(value) {
    let arr = list
    let index = arr.indexOf(value)
    if (index > -1) {
      arr.splice(index, 1)
    }
    return arr
  }
  async function onSubmit() {
    try {
      setload(true)
      await postAction(`/contact/${user.contactId}/interests`, list).then(
        (response) => {
          setload(false)
          if (response && validateStatus(response.status)) {
            reload()
            onCancel()
            openSnackbar('Se adicionaron los cursos', true, true)
          } else {
            openSnackbar('No se pudo completar', true, false)
          }
        },
      )
    } catch (e) {
      setload(false)
    }
  }

  return (
    <>
      <BoxFlex justify="space-between">
        <H2 color="var(--gray)" margin="0">
          Elige los cursos que deseas adicionar
        </H2>
        {load ? (
          <Loading />
        ) : (
          <DefaultBtn onClick={onSubmit} disabled={!validateArray(list)}>
            Adicionar
          </DefaultBtn>
        )}
      </BoxFlex>
      <br />
      <>
        <BoxFlex>
          {validateArray(list) &&
            list.map((item) => (
              <div
                style={{
                  border: '1px solid gray',
                  padding: '2px 10px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                }}
                key={item.programManagerId}
                onClick={() => handleRemove(item)}
              >
                {item.name[0].toUpperCase() +
                  item.name.slice(1, 30).toLowerCase()}
                {item.name.length > 30 && '...'}{' '}
                <i style={{ color: 'var(--red)' }} className="fas fa-times"></i>
              </div>
            ))}
        </BoxFlex>
        <br />
        <div style={{ height: '450px', overflowY: 'scroll' }}>
          <TableFilterContainer
            param={`/contact/${user.contactId}/programManagerList`}
            choice={true}
            header={[{ key: 'name', name: 'Nombre del curso' }]}
            onClick={handleSelect}
            boxFilters={[
              // {
              //   type: 'select',
              //   label: 'Ordenar por:',
              //   name: 'OrderBy',
              //   options: [
              //     { label: 'Nombre', name: '' },
              //     { label: 'Fecha de creación', name: 'created' },
              //   ],
              // },
              {
                type: 'getSelect',
                label: 'Categoría:',
                name: 'CategoryId',
                url: '/category',
                urlKey: 'id',
                urlLabel: 'name',
                initial: 'Todas las categorías',
              },
            ]}
          />
        </div>
      </>
    </>
  )
}

export default FormAddCourse
