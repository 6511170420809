import { useState } from 'react'
import './style/UnitsAside.css'
import { Link, withRouter } from 'react-router-dom'
import { validateArray } from '../../utils/Validation'
import { dataLock, convertD, subtractDate } from '../../utils/ConvertDate'
import { QuizLesson } from '../../containers/programs'
import { NormalButton } from '../../components/buttons'
import { CompModal } from '../../components/modal'
import { ContentCertificate } from '../links'
const UnitsAside = (props) => {
  const { name, lessons } = props.dataComment
  const {
    params: { cid, mid, pid, id: lid },
    history,
  } = props

  function validateID(lid) {
    let boolean = false
    lessons.forEach((l) => {
      if (l.id.toString() === lid) {
        boolean = true
      }
    })
    return boolean
  }

  function preValidate(les) {
    let jsonLesson = {}
    les.forEach((l) => {
      if (l.lessonType === 'quiz' || l.lessonType === 'virtualclass') {
        jsonLesson = {
          ...jsonLesson,
          [l.id]: false,
        }
      }
    })
    return jsonLesson
  }
  const [status, setstatus] = useState(validateID(lid))
  const [modal, setmodal] = useState(preValidate(lessons))

  function handeLink({ lessonType, id, startDatePerUser }) {
    if (dataLock(startDatePerUser)) {
      let link = `/program${pid ? '/' + pid : ''}/course/${cid}/module/${mid}`

      switch (lessonType) {
        case 'video':
          return link + `/video/${id}`
        case 'homework':
          return link + `/home-work/${id}`
        case 'forum':
          return link + `/forum/${id}`
        default:
          return link
      }
    } else {
      return `/program${
        pid ? '/' + pid : ''
      }/course/${cid}/module/${mid}/video/${lid}`
    }
  }
  function handelModal(id) {
    setmodal((a) => ({ ...a, [id]: !modal[id] }))
  }
  function handelQuiz(qid) {
    history.push(
      `/program${pid ? '/' + pid : ''}/course/${cid}/module/${mid}/quiz/${qid}`,
    )
  }
  function handleEducator(qid) {
    history.push(
      `/program${
        pid ? '/' + pid : ''
      }/course/${cid}/module/${mid}/quiz/${qid}/ratings`,
    )
  }
  function handelAnswer(quizId) {
    history.push(
      `/program${
        pid ? '/' + pid : ''
      }/course/${cid}/module/${mid}/answer/${quizId}`,
    )
  }
  function VerifyLesson(l, index) {
    switch (l.lessonType) {
      case 'quiz':
        return (
          <div
            onClick={() =>
              l.status
                ? l.status === 'available'
                  ? handelModal(l.id)
                  : handelAnswer(l.id)
                : handleEducator(l.id)
            }
            className="Lection"
            style={{ cursor: 'pointer' }}
          >
            <div
              className={`Lection__header${
                l.id.toString() === lid ? '-activate' : ''
              }`}
            >
              <div className="Lection__progress">
                <div className="Lection__line"></div>
                <div className="Lection__circle"></div>
                <div
                  className={
                    index < lessons.length - 1
                      ? 'Lection__line'
                      : 'Lection__lineT'
                  }
                ></div>
              </div>
              <div className="Lection__title">{l.name}</div>
              <QuizLesson
                dataLock={dataLock}
                handelQuiz={handelQuiz}
                convertD={convertD}
                contentData={l}
                handelModal={handelModal}
                modal={modal[l.id]}
              />
            </div>
          </div>
        )

      case 'certificate':
        return (
          <ContentCertificate
            type="aside"
            contentData={l}
            activate={l.id.toString() === lid}
            finalAside={index < lessons.length - 1}
          />
        )
      case 'virtualclass':
        return (
          <>
            {modal[l.id] && (
              <CompModal onClose={() => handelModal(l.id)} closeOutside={true}>
                <div className="ContentVirtualClass__modal">
                  <h3>
                    La reunión inicia el: {convertD(l.startDatePerUser, 'LLLL')}
                  </h3>
                  <h2>
                    Debes esperar el enlace estará habilitado 30 minutos antes
                    de la fecha indicada
                  </h2>
                  <div className="ContentQuiz__btns">
                    <NormalButton
                      onClick={() => handelModal(l.id)}
                      background={true}
                    >
                      Cerrar
                    </NormalButton>
                  </div>
                </div>
              </CompModal>
            )}
            {dataLock(subtractDate(l.startDatePerUser, 30, 'minutes')) ? (
              <a
                href={l.aditionalUrlResources}
                target="_blank"
                rel="noopener noreferrer"
                className="Lection"
              >
                <div
                  className={`Lection__header${
                    l.id.toString() === lid ? '-activate' : ''
                  }`}
                >
                  <div className="Lection__progress">
                    <div className="Lection__line"></div>
                    <div className="Lection__circle"></div>
                    <div
                      className={
                        index < lessons.length - 1
                          ? 'Lection__line'
                          : 'Lection__lineT'
                      }
                    ></div>
                  </div>
                  <div className="Lection__title">{l.name}</div>
                </div>
              </a>
            ) : (
              <div
                onClick={() => handelModal(l.id)}
                className="Lection"
                style={{ cursor: 'pointer' }}
              >
                <div
                  className={`Lection__header${
                    l.id.toString() === lid ? '-activate' : ''
                  }`}
                >
                  <div className="Lection__progress">
                    <div className="Lection__line"></div>
                    <div className="Lection__circle"></div>
                    <div
                      className={
                        index < lessons.length - 1
                          ? 'Lection__line'
                          : 'Lection__lineT'
                      }
                    ></div>
                  </div>
                  <div className="Lection__title">{l.name}</div>
                </div>
              </div>
            )}
          </>
        )
      default:
        return (
          <Link to={handeLink(l)} className="Lection">
            <div
              className={`Lection__header${
                l.id.toString() === lid ? '-activate' : ''
              }`}
            >
              <div className="Lection__progress">
                <div className="Lection__line"></div>
                <div className="Lection__circle"></div>
                <div
                  className={
                    index < lessons.length - 1
                      ? 'Lection__line'
                      : 'Lection__lineT'
                  }
                ></div>
              </div>
              <div className="Lection__title">{l.name}</div>
            </div>
          </Link>
        )
    }
  }
  return (
    <div className="UnitsAside">
      <div className="UnitsAside__header">
        <div className="UnitsAside__head">
          <div className="UnitsAside__progress">
            <div className="UnitsAside__circle"></div>
            {status && <div className="UnitsAside__line"></div>}
          </div>
          <div className="UnitsAside__title" onClick={() => setstatus(!status)}>
            {name}
          </div>
        </div>
        <i
          className="fas fa-chevron-down"
          onClick={() => setstatus(!status)}
        ></i>
      </div>
      {status && (
        <div className="UnitsAside__lections">
          {validateArray(lessons) ? (
            lessons
              .sort((a, b) => a.orderNumber - b.orderNumber)
              .map((l, index) => {
                return (
                  <div className="UnitsAside__lockcontent" key={index}>
                    {VerifyLesson(l, index)}
                    {!dataLock(l.startDatePerUser) && (
                      <i className="fas fa-lock"></i>
                    )}
                  </div>
                )
              })
          ) : (
            <h3>Aún no hay contenido en el curso</h3>
          )}
        </div>
      )}
    </div>
  )
}

export default withRouter(UnitsAside)
