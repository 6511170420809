import { FieldArray, Form, Formik } from 'formik'
import React from 'react'
import { useState, useEffect } from 'react'
import * as Yup from 'yup'
import { Loading } from '../../../components/animation'
import { BoxFlex, ToolTip } from '../../../components/boxes'
import {
  DefaultBtn,
  RenderButton,
  ActionButton,
} from '../../../components/buttons'
import { FormikControl } from '../../../components/input'
import { TableFilterContainer } from '../../../components/table'
import { H2, H3 } from '../../../components/text'
import { useAxiosAuth } from '../../../hooks'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { postAction } from '../../../services/action/ActionAuthorization'

export const FormIncriptionCourse = (props) => {
  const { openSnackbar, changeModal, onClose } = props
  const [load, setload] = useState(true)
  const [view, setview] = useState({
    status: true,
    advert: null,
  })

  const expeditionSelect = [
    {
      key: 'Expedicion:',
      value: '',
    },
    { key: 'Chuquisaca', value: 'CH' },
    { key: 'La Paz', value: 'LP' },
    { key: 'Cochabamba', value: 'CB' },
    { key: 'Oruro', value: 'OR' },
    { key: 'Potosí', value: 'PT' },
    { key: 'Tarija', value: 'TJ' },
    { key: 'Santa Cruz', value: 'SC' },
    { key: 'Beni', value: 'BE' },
    { key: 'Pando', value: 'PD' },
  ]
  const planSelect = [
    { key: 'Seleccione el plan', value: '' },
    { key: 'Plan Estudiante (Bs 100)', value: 'Plan Estudiante (Bs 100)' },
    { key: 'Plan Estudiante (Bs 120)', value: 'Plan Estudiante (Bs 120)' },
  ]
  const institutionSelect = [
    { key: 'Institucion:', value: '' },
    { key: 'Universidad Tecnológica Boliviana', value: 'UTB' },
    { key: 'Capacita Global Internacional', value: 'CGI' },
  ]
  function handleInitial(body, constant, empty) {
    if (body) {
      if (body[constant]) {
        return body[constant]
      } else {
        return empty
      }
    } else {
      return empty
    }
  }
  const initialValues = {
    name: handleInitial(view.advert, 'firstName', ''),
    lastName: handleInitial(view.advert, 'lastName', ''),
    ci: handleInitial(view.advert, 'ci', ''),
    expeditionCI: handleInitial(view.advert, 'expeditionCi', ''),
    email: handleInitial(view.advert, 'email', ''),
    phone: handleInitial(view.advert, 'telephone', ''),
    index: [''],
    PaymentPlan: '',
    amount: '',
    invoice: '',
    homeUniversity: '',
    author: '',
    institution: '',
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('campo requerido'),
    lastName: Yup.string().required('campo requerido'),
    ci: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    expeditionCI: Yup.string().required('campo requerido'),
    email: Yup.string()
      .email('Formato de correo inválido')
      .required('Campo Requerido'),
    phone: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    index: Yup.array().of(Yup.string().required('Campo requerido')),
    PaymentPlan: Yup.string().required('campo requerido'),
    amount: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    invoice: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    homeUniversity: Yup.string().required('campo requerido'),
    author: Yup.string().required('campo requerido'),
    institution: Yup.string().required('campo requerido'),
  })
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/Program/FormDataRegister', {
        ...values,
        numIndex: validateArray(values.index) ? values.index.join(';') : '',
        programName: '',
      }).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          if (response.data && !validateArray(response.data.error)) {
            onClose()
            openSnackbar('Estudiante Inscrito', true, true)
          } else {
            openSnackbar('Hubo errores al inscribir al estudiante', true, true)
          }
        } else {
          openSnackbar('No se pudo inscribir al estudiante', true, false)
        }
      })
    } catch (e) {
      setload(true)
    }
  }

  function searchCourse(courses, index, setValues) {
    changeModal(
      true,
      null,
      <TableFilterContainer
        param={`/category/4/programManagerList`}
        header={[
          { key: 'name', name: 'Nombre de Curso' },
          { key: 'professor', name: 'Profesor' },
        ]}
        onClick={(course) => handleAddCourse(course, courses, index, setValues)}
      />,
      null,
    )
  }
  function handleAddCourse(course, courses, index, setValues) {
    let newCourses = courses
    newCourses[index] = course.index
    setValues((a) => ({
      ...a,
      index: newCourses,
    }))

    changeModal(false, null)
  }
  function searchUser(onClick) {
    changeModal(
      true,
      null,
      <TableFilterContainer
        param={`/institution/1/users`}
        header={[
          { key: 'firstName', name: 'Nombres' },
          { key: 'lastName', name: 'Apellidos' },
          { key: 'email', name: 'Correo' },
          { key: 'telephone', name: 'Teléfono' },
        ]}
        onClick={onClick}
      />,
      null,
    )
  }
  function handleAddUser(user) {
    changeModal(false, null)
    setview((a) => ({
      ...a,
      advert: {
        ...a.advert,
        ...user,
      },
    }))
  }

  return (
    <>
      <BoxFlex justify="space-between" wrap="nowrap">
        <H2 margin="0" color="var(--gray)">
          Inscribir a cursos
        </H2>
        <ToolTip content="Buscar Estudiante">
          <div className="">
            <RenderButton onClick={() => searchUser(handleAddUser)}>
              <i className="fas fa-solid fa-user-tag"></i>
            </RenderButton>
          </div>
        </ToolTip>
      </BoxFlex>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Nombres"
                name="name"
                disabled={view.advert && view.advert.firstName}
              />
              <FormikControl
                control="input"
                type="text"
                label="Apellidos"
                name="lastName"
                disabled={view.advert && view.advert.lastName}
              />
              <FormikControl
                control="input"
                type="text"
                label="Cèdula de identidad"
                name="ci"
                disabled={view.advert && view.advert.ci}
              />
              <FormikControl
                control="select"
                label="Expedición"
                name="expeditionCI"
                options={expeditionSelect}
                disabled={view.advert && view.advert.expeditionCi}
              />
              <FormikControl
                control="input"
                type="text"
                label="Correo electrónico"
                name="email"
                disabled={view.advert && view.advert.email}
              />
              <FormikControl
                control="input"
                type="text"
                label="Télefono"
                name="phone"
                disabled={view.advert && view.advert.telephone}
              />
              <FieldArray
                name="index"
                render={(arrayHelpers) => {
                  const coursesIds = formik.values.index
                  return (
                    <>
                      {validateArray(coursesIds)
                        ? coursesIds.map((course, indexx) => (
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                gap: '15px',
                                marginBottom: '10px',
                              }}
                              key={indexx}
                            >
                              <BoxFlex gap="20px" wrap="nowrap">
                                <div style={{ width: course ? '30px' : '' }}>
                                  <FormikControl
                                    control="input"
                                    type="text"
                                    label="Nro."
                                    name={`index[${indexx}]`}
                                  />
                                </div>
                                {course > 0 ? (
                                  <SelectCourse courseIndex={course} />
                                ) : (
                                  <H3 margin="15px 0 0 0">--</H3>
                                )}
                              </BoxFlex>
                              <BoxFlex wrap="nowrap" marginTop="20px">
                                <RenderButton
                                  padding="5px 8px"
                                  content="Buscar curso"
                                  onClick={() =>
                                    searchCourse(
                                      coursesIds,
                                      indexx,
                                      formik.setValues,
                                    )
                                  }
                                >
                                  <i className="fas fa-search"></i>
                                </RenderButton>
                                {indexx > 0 && (
                                  <button
                                    className="FormRelease__delete"
                                    type="button"
                                    onClick={() => arrayHelpers.remove(indexx)}
                                  >
                                    <i className="fas fa-times"></i>
                                  </button>
                                )}
                              </BoxFlex>
                            </div>
                          ))
                        : null}
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row nowrap',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        <DefaultBtn
                          type="button"
                          onClick={() => arrayHelpers.push('')}
                        >
                          Adicionar cursos
                        </DefaultBtn>
                      </div>
                    </>
                  )
                }}
              />
              <FormikControl
                control="select"
                label="Plan"
                name="PaymentPlan"
                options={planSelect}
              />
              <FormikControl
                control="input"
                type="text"
                label="Monto"
                name="amount"
              />
              <FormikControl
                control="input"
                type="text"
                label="Factura y/o Recibo"
                name="invoice"
              />
              <FormikControl
                control="input"
                type="text"
                label="Universidad de Origen"
                name="homeUniversity"
              />
              <FormikControl
                control="input"
                type="text"
                label="Autor"
                name="author"
              />
              <FormikControl
                control="select"
                label="Institución"
                name="institution"
                options={institutionSelect}
              />
              {load ? (
                <ActionButton
                  type="submit"
                  onCancel={onClose}
                  textBtn="Inscribir"
                  textCancel="Cancelar"
                />
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const SelectCourse = ({ courseIndex }) => {
  const { response, loading, reload, error } = useAxiosAuth({
    method: 'GET',
    url: `/programManager/dictionary/${courseIndex}`,
  })
  useEffect(() => {
    reload()
  }, [courseIndex])
  if (loading) {
    return <Loading />
  }

  if (courseIndex) {
    return (
      <>
        {error === 200 ? (
          <H3 margin="20px 0 0 0">{response.name ? response.name : null}</H3>
        ) : (
          <H3 margin="20px 0 0 0">No existe ningún curso</H3>
        )}
      </>
    )
  } else {
    return null
  }
}

