import axios from "axios";
import HttpClient from "../HttpClient";
const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const postSeminar = (data) => {
  return new Promise((resolve, eject) => {
    instancia
      .post("/webinarpreregister", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
export const postSeminarAuth = (data) => {
  return new Promise((resolve, eject) => {
    HttpClient.post("/webinarpreregister", data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
