// import { useAxiosAuth } from '../../../../hooks'
// import TableMain from '../../../../components/table/TableMain'
// import { Loading } from '../../../../components/animation'
// import { H3 } from '../../../../components/text'
// import { FormDefault } from '../../../../components/form'
// import { BoxFlex } from '../../../../components/boxes'
// import { RenderButton } from '../../../../components/buttons'
// import { validateArray } from '../../../../utils/Validation'
// import { InputCreateTracking } from '../../../../utils/FormParameters'
// const InstitutionTracking = (props) => {
//   const { iid, changeInfo, openSnackbar, changeModal } = props
//   const { response, loading, reload } = useAxiosAuth({
//     method: "GET",
//     // url: `/tracking/Institution/2`,
//     // url: `/tracking/Institution/${iid}`,
//   });

//   // const actionSeg = [
//   //   {
//   //     id: 1,
//   //     label: "Borrar",
//   //     action: delTracking,
//   //     icon: "fas fa-trash",
//   //   },
//   //   {
//   //     id: 2,
//   //     label: "Editar",
//   //     action: editTracking,
//   //     icon: "fas fa-edit",
//   //   },
//   // ];

//   function createTracking() {
//     changeInfo(
//       <FormDefault
//         inputs={InputCreateTracking}
//         onCancel={() => changeInfo(null)}
//         method="POST"
//         url="/tracking/create"
//         openSnackbar={openSnackbar}
//         handleReload={() => {
//           reload()
//           changeInfo(null)
//         }}
//         processData={(values) => ({ ...values, name: values.Label })}
//       />,
//     )
//   }

//   if (loading) {
//     return <Loading />
//   }
//   return (
//     <div>
//       <BoxFlex justify="flex-end" margin="0 0 10px">
//         <RenderButton onClick={createTracking}>
//           Crear seguimiento +
//         </RenderButton>
//       </BoxFlex>
//       {validateArray(response) ? (
//         <TableMain
//           header={[
//             { key: 'label', name: 'Nombre' },
//             { key: 'type', name: 'Tipo de dato' },
//           ]}
//           main={response}
//         />
//       ) : (
//         <H3>No tienes lista de seguimiento</H3>
//       )}
//     </div>
//   )
// }

// export default InstitutionTracking

import React from 'react'

const InstitutionTracking = () => {
  return <div>InstitutionTracking</div>
}
export default InstitutionTracking
