import { Switch, Route } from 'react-router-dom'
import { GraduatesUTB, Seminars, CoursesChildren, SubCategories } from '../view'
const RouteCatalogue = () => {
  return (
    <Switch>
      <Route exact path="/catalogue/graduates/:id?" component={GraduatesUTB} />
      <Route exact path="/catalogue/seminars/:id?" component={Seminars} />
      <Route
        exact
        path="/catalogue/courses-children/:id?"
        component={CoursesChildren}
      />
      <Route exact path="/catalogue/:url/:id" component={SubCategories} />
    </Switch>
  )
}

export default RouteCatalogue
