import { postAction } from '../../../services/action/ActionAuthorization'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { convertD } from '../../../utils/ConvertDate'
import { useEffect, useState } from 'react'
import { TableFilterContainer } from '../../../components/table'
import { Loading } from '../../../components/animation'
import { SubtitleData, H2, H3 } from '../../../components/text'
import { CardPunctuation, ListTracing } from '../../../components/card'
import { BoxFlex } from '../../../components/boxes'
import { urlAvatar } from '../../../utils/Constant'
import { ButtonIcon, RenderButton } from '../../../components/buttons'
import { NewUserProgram, FormDefault } from '../../../components/form'
import FormAddUser from './FormAddUser'
import AddChildren from '../CoursesInstitution/AddChildren'
import './style/UsersProgram.css'
import * as Yup from 'yup'
import { useAxiosAuth } from '../../../hooks'
import { CardInformation } from '../InstitutionPrereg'

// import { setColorByValue } from '../../../utils/ProcessData'
// import { CardRegister } from '../../../view/Manager/Reports'

const UsersReprobados = (props) => {
  const {
    pid,
    changeInfo,
    openSnackbar,
    changeModal,
    url,
    type,
    iid = 1,
    program,
    startSession,
    history,
  } = props

  const header = [
    {
      key: 'names',
      name: 'Nombres',
    },
    {
      key: 'ci',
      name: 'Carnet',
    },
    {
      key: 'progress',
      name: 'Cursos Reprobados',
      type: 'preProcess',
      validate: failedCourses,
    },
  ]

  function failedCourses(data) {
    return (
      <BoxFlex justify="flex-start" gap="4px">
        {data.calificationPerCourse.map((failed) => (
          <RenderButton
            key={failed.courseId}
            content={failed.courseName}
            color="var(--green)"
            backgroundColor="#4695851a"
          >
            {failed.score}
          </RenderButton>
        ))}
      </BoxFlex>
    )
  }

  const [reload, setreload] = useState(false)
  function handleReload() {
    setreload(!reload)
    changeInfo(<></>)
  }

  const ViewUser = ({ user, programManager, pid }) => {
    const [load, setload] = useState(true)
    const [loading, setloading] = useState(true)

    const { response, loading: loadUser, reload } = useAxiosAuth({
      method: 'GET',
      url: `/program/${pid}/califications/reprobate/${user?.userId}`,
    })

    useEffect(() => {
      reload()
    }, [user])

    const onSubmit = async () => {
      try {
        setload(false)
        await postAction(
          `/${type === 'course' ? 'course' : 'program'}/${pid}/DelistUser`,
          {
            UserId: user.id,
            ProgramId: pid,
            CourseId: pid,
          },
        ).then((response) => {
          setload(true)
          if (response && validateStatus(response.status)) {
            openSnackbar('Se dio de baja al usuario', true, true)
            handleReload()
          } else {
            openSnackbar('No se pudo completar', true, false)
          }
        })
      } catch (e) {
        setload(true)
      }
    }

    function resendUser(user) {
      changeModal(
        true,
        '¿Estás seguro que quieres reenviar las credenciales del usuario?',
        '',
        'Enviar',
        () => handleResendUser(user),
      )
    }

    function handleResendUser(user) {
      setloading(false)
      postAction(`/email/${user.userId}/ResendCredentials`).then((response) => {
        if (response && validateStatus(response.status)) {
          openSnackbar('Se volvio a enviar el mensaje', true, true)
          changeModal(
            true,
            'Se Reenviaron las credenciales exitosamente...',
            '',
            '',
            null,
          )
        } else {
          openSnackbar('No se pudo enviar el mensaje', true, false)
          changeModal(
            true,
            'No se Reenviaron las credenciales...',
            '',
            '',
            null,
          )
        }
        setloading(true)
      })
    }

    function changeParallel(user) {
      changeInfo(
        <FormDefault
          inputs={[
            {
              control: 'getSelect',
              initial: '',
              validate: Yup.string().required('Campo requerido'),
              label: 'Seleccione el paralelo',
              name: 'NewCourseId',
              url: `/programManager/${programManager.id}/parallels`,
              keyOption: 'versionName',
              value: 'id',
            },
          ]}
          onCancel={() => changeInfo(<></>)}
          method="POST"
          url={`/course/switchParallel`}
          openSnackbar={openSnackbar}
          title="Cambiar de paralelo"
          handleReload={handleReload}
          processData={(values) => ({
            ...values,
            UserId: user.id,
            CourseId: pid,
          })}
        />,
      )
    }

    function handleTracing(user) {
      changeModal(
        true,
        'Lista de seguimiento',
        <ListTracing {...props} user={user} />,
        null,
      )
    }

    function validation() {
      changeModal(
        true,
        '¿Estás seguro que quieres dar de baja a este usuario?',
        '',
        'Dar de baja',
        () => onSubmit(),
      )
    }
    function handleLogin(user) {
      changeModal(
        true,
        '¿Estás seguro? Cerrarás tu sesión actual',
        `Iniciarás sesión con la cuenta de ${user.firstName || user.name} ${
          user.lastName
        }?`,
        'Iniciar sesión',
        () => processLogin(user),
      )
    }

    function processLogin(user) {
      changeModal(
        true,
        '¿Estás seguro? Cerrarás tu sesión actual',
        <Loading />,
        null,
      )
      postAction(`/auth/login/replaced/${user.userId}`).then((resp) => {
        if (validateStatus(resp.status)) {
          openSnackbar('Inicio Exitoso', true, true)
          changeModal(false, null)
          const { institutions, courses, user, token, father } = resp.data
          window.localStorage.setItem('id', user.userId)
          window.localStorage.setItem('token_seguridad', token)
          startSession({ ...user, father: father }, courses, institutions, true)
          history.push('/programs')
        } else {
          openSnackbar('No se pudo iniciar sesión', true, true)
        }
      })
    }

    const styleButton = {
      backgroundColor: '#4695851a',
      borderRadius: '5px',
      padding: '5px 10px',
    }

    if (loadUser) {
      return <Loading />
    }
    return (
      <div className="ViewUser">
        <BoxFlex justify="space-between" wrap="wrap-reverse">
          <H2>Información del usuario</H2>
          {loading ? (
            <BoxFlex direction="row">
              {type === 'course' && (
                <>
                  <ButtonIcon
                    onClick={() => handleTracing(user)}
                    style={styleButton}
                    content="Lista de seguimiento"
                    icon="address-book"
                  />
                  <ButtonIcon
                    onClick={() => changeParallel(user)}
                    style={styleButton}
                    content="Cambiar de paralelo"
                    icon="exchange-alt"
                  />
                </>
              )}
              <ButtonIcon
                onClick={() => resendUser(user)}
                content="Enviar credenciales de acceso"
                icon="address-card"
                style={styleButton}
              />
              <ButtonIcon
                type="anchor"
                onClick={`/program/${pid}/academic/${user.userId}/${
                  type === 'course' ? 'course' : 'Program'
                }`}
                style={styleButton}
                content="Ver su progreso"
                icon="tasks"
              />
              <ButtonIcon
                onClick={() => handleLogin(user)}
                content="Iniciar sesión"
                icon="sign-in-alt"
                style={styleButton}
              />
            </BoxFlex>
          ) : (
            <Loading />
          )}
        </BoxFlex>
        <SubtitleData subtitle="Nombres">{response.name}</SubtitleData>
        <SubtitleData subtitle="Apellidos">{response.lastName}</SubtitleData>
        <SubtitleData subtitle="Correo electrónico">
          {response.email}
        </SubtitleData>
        <SubtitleData subtitle="Carnet de identidad">{user.ci}</SubtitleData>
        {user.dateOfBirth && user.dateOfBirth !== '0001-01-01T00:00:00' ? (
          <SubtitleData subtitle="Fecha de nacimiento">
            {convertD(user.dateOfBirth, 'LL')}
          </SubtitleData>
        ) : null}
        <SubtitleData subtitle="Teléfono">{user.telephone}</SubtitleData>
        {user.lastActive === '0001-01-01T00:00:00' ? null : (
          <SubtitleData subtitle="Última conexión ">
            {convertD(user.lastActive, 'LLLL')}
          </SubtitleData>
        )}
        {validateArray(response.calificationPerCourse) ? (
          <>
            <br />
            <H2>Cursos Reprobados</H2>
            <br />
            {response.calificationPerCourse.map((course) => (
              <CardInformation
                key={course.courseId}
                title={
                  <H3>
                    {/* <BoxFlex direction="row">
                      <ButtonIcon
                        content={'Recuperatorio'}
                        // onClick={ChangePublic}
                        icon={'list'}
                        style={styleButton}
                      />
                      <ButtonIcon
                        content={'Ampliar tarea'}
                        // onClick={ChangePublic}
                        icon={'edit'}
                        style={styleButton}
                      />
                      <ButtonIcon
                        content={'Cambiar de paralelo'}
                        // onClick={ChangePublic}
                        icon={'dice'}
                        style={styleButton}
                      />
                      <ButtonIcon
                        content={'Dar de baja'}
                        // onClick={ChangePublic}
                        icon={'sitemap'}
                        style={styleButton}
                      />
                    </BoxFlex> */}
                  </H3>
                }
              >
                <SubtitleData subtitle="Curso: ">
                  {course.courseName}
                </SubtitleData>
                <SubtitleData subtitle="Docente: ">
                  {course.teacher}
                </SubtitleData>
                <SubtitleData subtitle="Nota: ">
                  {course.score ? course.score : '0'}
                </SubtitleData>
              </CardInformation>
            ))}
          </>
        ) : null}

        {validateArray(response?.fathers) && (
          <BoxFlex direction="row">
            <H2 margin="20px 0 0" color="var(--gray)">
              Lista de tutores de {user.firstName}
            </H2>
            {response.fathers.map((father) => (
              <CardUser key={father.id} user={father} />
            ))}
          </BoxFlex>
        )}
      </div>
    )
  }

  function handleAddUser() {
    const categoryDto = program.programManager.programManagerCategories
    changeInfo(
      validateArray(categoryDto) &&
        validateArray(
          categoryDto.filter((category) => category.categoryId === 8),
        ) ? (
        <AddChildren
          iid={iid}
          type={type}
          changeInfo={changeInfo}
          handleReload={handleReload}
          pid={pid}
          changeModal={changeModal}
          openSnackbar={openSnackbar}
        />
      ) : (
        <FormAddUser
          iid={iid}
          type={type}
          changeInfo={changeInfo}
          onClose={handleReload}
          pid={pid}
          changeModal={changeModal}
          openSnackbar={openSnackbar}
        />
      ),
    )
  }

  return (
    <>
      <br />
      <TableFilterContainer
        param={url}
        header={header}
        addHeader={[
          { key: 'firstName', name: 'Nombres' },
          { key: 'lastName', name: 'Apellidos' },
        ]}
        boxFilters={[
          {
            type: 'select',
            label: 'Ordenar por:',
            name: 'OrderBy',
            options: [
              { label: 'Ultima conexión', name: '' },
              { label: 'Fecha de creación', name: 'created' },
              { label: 'Nombres', name: 'name' },
              { label: 'Apellidos', name: 'lastName' },
            ],
          },
        ]}
        handleReload={reload}
        onClick={(user) => changeInfo(<ViewUser user={user} {...props} />)}
      />
      {/* <TableOptions
        param={url}
        header={header}
        choice={true}
        preConfig={preConfig}
        handleReload={reload}
        handleInfo={(user) => changeInfo(<ViewUser user={user} {...props} />)}
      /> */}
    </>
  )
}

const CardUser = ({ user }) => {
  return (
    <BoxFlex
      width="100%"
      wrap="nowrap"
      padding="5px"
      borderRadius="10px"
      justify="flex-start"
      border="1px solid gray"
    >
      <div
        style={{
          height: '80px',
          width: '80px',
          backgroundImage: `url("${
            user.photoUrl ? user.photoUrl : urlAvatar
          }")`,
          backgroundSize: 'cover',
          border: '2px solid var(--white)',
          borderRadius: '50%',
        }}
      ></div>
      <div className="">
        <H3>
          {user.name} {user.lastName}
        </H3>
        {user.email && (
          <H3 fontSize="1.4rem" fontWeight="500">
            <strong>Correo:</strong> {user.email}
          </H3>
        )}
        <H3 fontSize="1.4rem" fontWeight="500" margin="0">
          <strong>Usuario:</strong> {user.username}
        </H3>
      </div>
    </BoxFlex>
  )
}

export default UsersReprobados
