import { FormikControl } from "../../../../../../components/input";
import { transformTextJson } from "../../../../../../utils/ProcessData";
import ConceptRelation from "./ConceptRelation";
const QuizControl = ({ data, options, setValues, initialValues, index }) => {
  switch (data.type) {
    case "singleChoice":
      return (
        <>
          <div className="QuizContent__header">
            <h3>Selecciona la respuesta correcta</h3>
            <h3>Pregunta {index + 1}</h3>
          </div>
          <FormikControl
            control="radio"
            name={data.id}
            label={data.inquiry}
            url={data.url}
            fileId={data.fileId}
            options={options}
            innerHTML={true}
          />
        </>
      );
    case "openQuestion":
      return (
        <>
          <div className="QuizContent__header">
            {/* <h3>Selecciona la respuesta correcta</h3> */}
            <h3>Pregunta {index + 1}</h3>
          </div>
          <FormikControl
            control="textarea"
            name={data.id}
            label={data.inquiry}
            url={data.url}
            fileId={data.fileId}
            options={options}
            innerHTML={true}
          />
        </>
      );
    case "multipleChoice":
      return (
        <>
          <div className="QuizContent__header">
            <h3>Pregunta de selección multiple</h3>
            <h3>Pregunta {index + 1}</h3>
          </div>
          <FormikControl
            control="checkbox"
            label={data.inquiry}
            name={data.id}
            options={options}
            innerHTML={true}
            url={data.url}
            fileId={data.fileId}
          />
        </>
      );
    case "conceptRelation":
      let concepts = [];
      try {
        concepts = transformTextJson(data.inquiry);
      } catch (error) {
        console.log(error);
      }
      return (
        <>
          <div className="QuizContent__header">
            <h3>Relación de conceptos</h3>
            <h3>Pregunta {index + 1}</h3>
          </div>
          <ConceptRelation
            name={data.id}
            concepts={concepts}
            initialValues={initialValues}
            setValues={setValues}
          />
        </>
      );
    default:
      return null;
  }
};

export default QuizControl;
