import React from "react";
import "./style/InformationPersonal.css";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { validate } from "../../../utils/Validation";
import { CardPink } from "../../../components/card";
import { convertDate } from "../../../utils/ConvertDate";
function InformationPersonal(props) {
  const { sesionUser } = props;
  const { t } = useTranslation("account");
  return (
    <div className="">
      <CardPink
        title={t("Birthdate")}
        data={
          sesionUser
            ? validate(sesionUser.user.dateOfBirth) < 150
              ? validate(sesionUser.user.dateOfBirth)
              : (sesionUser.user.dateOfBirth ? convertDate(sesionUser.user.dateOfBirth, 'LL') : '--')
            : "--"
        }
      />
      <CardPink
        title={t("gender")}
        data={sesionUser ? validate(sesionUser.user.gender) : "--"}
      />
      <CardPink
        title={t("address")}
        data={sesionUser ? validate(sesionUser.user.Address) : "--"}
      />
      {/* <CardPink
        title={t("postalCode")}
        data={sesionUser ? validate(sesionUser.user.postalCode) : "--"}
      /> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, null)(InformationPersonal);
