import React, { useEffect } from "react";
import { connect } from "react-redux";
import { changeInfo } from "../../store/actions";
import { DivContent } from "../../components/layout";
const Calendar = (props) => {
  useEffect(() => {
    props.changeInfo(<DivContent>Calendar</DivContent>);
  }, [props]);
  return (
    <div>
      Calendar
      <div
        style={{
          background: "white",
          borderRadius: "10px",
          padding: "10px 0",
          fontSize: "1.4rem",
        }}
      ></div>
    </div>
  );
};

const mapDispatchToProps = {
  changeInfo,
};
export default connect(null, mapDispatchToProps)(Calendar);
