import { useAnswers, useAxiosAuth } from "../../../../../hooks";
import { SubtitleData, H2, H4 } from "../../../../../components/text";
import { Icon } from "../../../../../components/buttons";
import { LoadCircle } from "../../../../../components/animation";
import { convertD } from "../../../../../utils/ConvertDate";
import { validateArray } from "../../../../../utils/Validation";
import { transformTextJson } from "../../../../../utils/ProcessData";
import "./style/AnswersUserAside.css";
import ExtendDatesLesson from "./ExtendDatesLesson";

const AnswersUserAside = (props) => {
  const { user, qid, changeInfo } = props;
  const { response, loading } = useAxiosAuth({
    method: "GET",
    url: `/lesson/${qid}/user/${user.userId}/datetimes`,
  });
  function extendDates() {
    changeInfo(
      <ExtendDatesLesson
        {...props}
        lid={qid}
        response={response}
        onClose={() => changeInfo(<AnswersUserAside {...props} />)}
      />
    );
  }
  return (
    <div className="AnswersUserAside">
      {user.status === "Inconcluso" && (
        <>
          <Icon ico="calendar-day" handleModal={extendDates}>
            Ampliar fechas para el usuario
          </Icon>
          <br />
          <br />
        </>
      )}
      <H2 textAlign="center">Detalles del usuario</H2>
      <SubtitleData subtitle="Nombres">{user.userName}</SubtitleData>

      <SubtitleData subtitle="Apellidos">{user.userLastName}</SubtitleData>
      {loading ? (
        <LoadCircle />
      ) : (
        <>
          <SubtitleData subtitle="Fecha de inicio:">
            {convertD(response.startDate, "LLL")}
          </SubtitleData>
          <SubtitleData subtitle="Fecha final:">
            {convertD(response.finishDate, "LLL")}
          </SubtitleData>
        </>
      )}
      <SubtitleData subtitle="Estado">{user.status}</SubtitleData>
      <SubtitleData subtitle="Nota">{user.score}</SubtitleData>

      <SubtitleData subtitle="Fecha de calificación">
        {user.updateDate !== "0001-01-01T00:00:00"
          ? convertD(user.updateDate, "LLL")
          : null}
      </SubtitleData>
      {user.status === "Concluido" && (
        <GetAnswer userId={user.userId} qid={qid} />
      )}
    </div>
  );
};

export default AnswersUserAside;

export function GetAnswer({ userId, qid }) {
  const { dataRequest } = useAnswers(userId, qid);
  return (
    <div className="GetAnswer">
      <H2 textAlign="center">Respuestas del usuario</H2>
      {dataRequest && dataRequest.lessonUserAnswersDto
        ? validateArray(dataRequest.lessonUserAnswersDto.verifyAnswers)
          ? dataRequest.lessonUserAnswersDto.verifyAnswers.map((question) => {
              let userResponses;
              if (question.type === "conceptRelation") {
                userResponses = question.userResponses.split("-");
                return (
                  <div
                    className={`GetAnswer__query ${
                      question.rightAnswer ? "correct" : "wrong"
                    }`}
                    key={question.id}
                  >
                    <QuestionRelation
                      question={question}
                      userResponses={userResponses}
                    />
                  </div>
                );
              } else if (question.type === "multipleChoice") {
                userResponses = question.userResponses.split(";");
              } else {
                userResponses = [question.userResponses];
              }
              return (
                <div
                  className={`GetAnswer__query ${
                    question.rightAnswer ? "correct" : "wrong"
                  }`}
                  key={question.id}
                >
                  <Question question={question} userResponses={userResponses} />
                </div>
              );
            })
          : null
        : null}
    </div>
  );
}
const Question = ({ question, userResponses }) => {
  function validateRightAnswer(respose) {
    let classResp = "";
    if (userResponses.indexOf(respose + "") > -1) {
      classResp = question.rightAnswer ? "correct" : "wrong";
    }
    return classResp;
  }
  return (
    <>
      <H4 innerHTML>{question.inquiry}</H4>
      {question.fileId && (
        <center>
          <img
            style={{ height: "100px" }}
            className="Question__img"
            src={question.url}
            alt=""
          />
        </center>
      )}
      {question.type === "openQuestion" ? (
        <div className="AnswersContent__questions">
          <div className="AnswersContent__question correct">
            <h3 style={{ fontSize: "1.6rem", fontWeight: "500" }}>
              {question.userResponses}
            </h3>
          </div>
        </div>
      ) : (
        <div className="GetAnswer__questions">
          {validateArray(transformTextJson(question.responses))
            ? transformTextJson(question.responses).map((a) => (
                <div
                  className={`AnswersContent__question ${validateRightAnswer(
                    a.id
                  )}`}
                  key={a.id}
                >
                  {a.FileId ? (
                    <img src={a.response} alt="" />
                  ) : (
                    <h2>{a.response}</h2>
                  )}
                  {a.id.toString() === question.userResponses && (
                    <i
                      className={`fas fa-${
                        question.rightAnswer ? "check" : "times"
                      }`}
                    ></i>
                  )}
                </div>
              ))
            : null}
        </div>
      )}
    </>
  );
};

function QuestionRelation({ question, userResponses }) {
  const inquiry = transformTextJson(question.inquiry);
  const responses = transformTextJson(question.responses);
  return (
    <>
      <h2>Relación de concepto</h2>
      <div className="GetAnswer__questions">
        {validateArray(inquiry)
          ? inquiry.map((a, index) => {
              const usResp = userResponses[index].split(";")[1];
              return (
                <div className="" key={a.id}>
                  <div className="AnswersContent__question">
                    {a.FileId ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <h2>{index + 1}. </h2> <img src={a.concept} alt="" />{" "}
                      </div>
                    ) : (
                      <h2>
                        {index + 1}. {a.concept}
                      </h2>
                    )}
                  </div>
                  <div
                    className={`AnswersContent__question ${
                      usResp === index + 1 + "" ? "correct" : "wrong"
                    }`}
                  >
                    {responses[usResp - 1].FileId ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <h2>{index + 1}. </h2>{" "}
                        <img src={responses[usResp - 1].response} alt="" />{" "}
                      </div>
                    ) : (
                      <h2>
                        {index + 1}. {responses[usResp - 1].response}
                      </h2>
                    )}
                  </div>
                  <hr />
                </div>
              );
            })
          : null}
      </div>
    </>
  );
}
