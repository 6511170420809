import * as Yup from 'yup'
import { validateStatus } from '../../../utils/Validation'
import { GlobalForm } from '../../../components/form'
import { useState } from 'react'
import { postAction } from '../../../services/action/ActionAuthorization'
import { convertDate } from '../../../utils/ConvertDate'
import { H2, SubtitleData } from '../../../components/text'
const InputsJson = [
  {
    name: 'StartDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha inicio a recorrer',
    dateFormat: 'MMMM d, yyyy',
    timeIntervals: 15,
  },
]

const FormEditDatesCourse = ({ cid, course, reload, openSnackbar, changeInfo }) => {
  const [load, setload] = useState(true)
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction(`course/${cid}/changeDates`, {
        StartDate: convertDate(values.StartDate, 'YYYY-MM-DD'),
      }).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          openSnackbar('Se recorrieron las fechas', true, true)
          reload()
        } else {
          openSnackbar('No se pudo cambiar las fechas', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <>
      <H2 color="var(--gray)">Recorrer la fecha de Inicio</H2>
      <SubtitleData subtitle="Fecha de inicio actual:">
        {convertDate(course.startDate, 'll')}
      </SubtitleData>
      <SubtitleData subtitle="Fecha fin  actual:">
        {convertDate(course.finishDate, 'll')}
      </SubtitleData>
      <GlobalForm
        InputsJson={InputsJson}
        textBtn="Recorrer"
        data={{
          StartDate: new Date(),
          // StartDate: new Date(program.startDate),
        }}
        onSubmit={onSubmit}
        load={load}
        onCancel={() => changeInfo(<></>)}
      />
    </>
  )
}

export default FormEditDatesCourse
