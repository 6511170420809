import FormChildren from './FormChildren'
import ListDocument from '../ListDocument'
import { useAxiosAuth } from '../../../hooks'
import { H2, H3 } from '../../../components/text'
import { Loading } from '../../../components/animation'
import { RenderButton, ActionButton } from '../../../components/buttons'
import { validateArray } from '../../../utils/Validation'
import { ToolTip } from '../../../components/boxes'
import PreRegisterChildren from './PreRegisterChildren'

const ListChildren = (props) => {
  const { changeModal, tutor, user, course, onCancel } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/users/${tutor.id}/childs`,
  })
  function handleFormChildren() {
    changeModal(
      true,
      '',
      <FormChildren
        {...props}
        onCancel={() =>
          changeModal(true, '', <ListChildren {...props} />, null)
        }
      />,
      null,
    )
  }

  function handleFile(preregister) {
    changeModal(
      true,
      '',
      <ListDocument
        {...props}
        user={user}
        course={{ ...course, preregisterId: preregister.id }}
        onCancel={() =>
          changeModal(true, '', <ListChildren {...props} />, null)
        }
      />,
      null,
    )
  }
  function handlePreRegister(child) {
    changeModal(
      true,
      '',
      <PreRegisterChildren
        {...props}
        child={child}
        onCancel={() =>
          changeModal(true, '', <ListChildren {...props} />, null)
        }
      />,
      null,
    )
  }
  return (
    <div>
      <H2 color="var(--green)" margin="0 0 5px">
        Niñ@s inscritos
      </H2>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((child) => (
          <CardChildren
            child={child}
            key={child.id}
            handleFile={handleFile}
            handlePreRegister={handlePreRegister}
            course={course}
            // programManagerId={.programManagerId}
            // courseName={course.name}
          />
        ))
      ) : (
        <H3 margin="15px 0 0">Este usuario no tiene niños inscritos.</H3>
      )}
      <ActionButton
        onClick={handleFormChildren}
        textBtn={
          <>
            Registrar niñ@ <i className="fas fa-user-plus"></i>
          </>
        }
        textCancel="Volver"
        onCancel={onCancel}
      />
    </div>
  )
}

const CardChildren = (props) => {
  const { child, handlePreRegister, handleFile, course } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `users/${child.id}/contactInterest/${course.contactInterestId}`,
  })

  if (loading) {
    return <Loading />
  } else {
    return (
      <div
        style={{
          background: response ? '#458e7f1c' : '#f8f8f8',
          padding: '10px',
          margin: '8px 0',
          borderRadius: '10px',
        }}
      >
        <H3
          margin="0 0 5px"
          fontWeight="600"
          textAlign="end"
          color={response ? 'var(--gray)' : 'var(--gray)'}
        >
          {response
            ? response.status === 'completed'
              ? 'Inscripción completa'
              : 'Inscripción en progreso'
            : 'No inscrito'}
        </H3>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="">
            <H3
              margin="0"
              fontSize="1.8rem"
              color={response ? 'var(--green)' : 'var(--green)'}
            >
              {child.name} {child.lastName}
            </H3>
            <H3
              margin="5px 0 0"
              fontWeight="500"
              color={response ? 'var(--gray)' : 'var(--gray)'}
            >
              Usuario: {child.username}
            </H3>
          </div>
          {response ? (
            response.status === 'completed' ? null : (
              <ToolTip content="Documentos adjuntos">
                <div>
                  <RenderButton
                    background="var(--green)"
                    // background="var(--white)"
                    // color="var(--green)"
                    onClick={() => handleFile(response)}
                  >
                    <i className="fas fa-money-check-alt"></i>
                  </RenderButton>
                </div>
              </ToolTip>
            )
          ) : (
            <ToolTip content="Pre-inscribir">
              <div>
                <RenderButton
                  background="var(--green)"
                  onClick={() => handlePreRegister(child)}
                >
                  <i className="fas fa-tag"></i>
                </RenderButton>
              </div>
            </ToolTip>
          )}
        </div>
      </div>
    )
  }
}

export default ListChildren
