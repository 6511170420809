import './style/ButtonIcon.css'
import { ToolTip } from '../boxes'
const ButtonIcon = (props) => {
  const {
    backButton = false,
    style,
    type = 'button',
    prefix = 'fas',
    color = '#494949',
  } = props
  if (props.content) {
    if (type === 'button') {
      return (
        <ToolTip content={props.content}>
          <button
            onClick={props.onClick}
            style={{ ...style, color: backButton ? 'var(--white)' : color }}
            className={backButton ? 'BackButton' : 'ButtonIcon'}
          >
            <i className={`${prefix} fa-${props.icon}`}></i>
          </button>
        </ToolTip>
      )
    } else {
      return (
        <ToolTip content={props.content}>
          <center>
            <a
              href={props.onClick}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                ...style,
                color: backButton ? 'var(--white)' : color,
              }}
              className={backButton ? 'BackButton' : 'ButtonIcon'}
            >
              <i className={`${prefix} fa-${props.icon}`}></i>
            </a>
          </center>
        </ToolTip>
      )
    }
  } else {
    return (
      <button
        onClick={props.onClick}
        style={{ ...style }}
        className={backButton ? 'BackButton' : 'ButtonIcon'}
      >
        <i className={`${prefix} fa-${props.icon}`}></i>
      </button>
    )
  }
}

export default ButtonIcon
