const IconText = ({ children, icon }) => {
  return (
    <div>
      <i
        style={{ fontSize: "1.8rem", marginRight: "8px" }}
        className={`fas fa-${icon}`}
      ></i>
      <span style={{ fontSize: "1.4rem" }}>{children}</span>
    </div>
  );
};

export default IconText;
