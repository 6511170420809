import { convertD, isValidFormat } from './ConvertDate'
import { validateArray } from './Validation'
export function processUrlImage(url, width, height) {
  let parameter = 'c_scale,q_auto,f_auto'
  if (url) {
    if (width && width > 0) {
      parameter = `w_${width},` + parameter
    } else if (height && height > 0) {
      parameter = `h_${height},` + parameter
    }
    const splitUrl = url.split('/')
    let urlChange = ''
    splitUrl.forEach((e, index) => {
      if (index === 5) {
        urlChange = urlChange + e + '/' + parameter + '/'
      } else {
        if (index < splitUrl.length - 1) {
          urlChange = urlChange + e + '/'
        } else {
          urlChange = urlChange + e
        }
      }
    })
    return urlChange
  } else {
    return ''
  }
}
export function processDrag(list, order) {
  let aux = []
  if (validateArray(list)) {
    list
      .sort((a, b) => a[order] - b[order])
      .forEach((element) => {
        aux.push({ id: `${element.id}`, content: element.name })
      })
  }
  return aux
}
export function ConvertBaMB(Bytes) {
  return Bytes / 1024 / 1024
}
export function ConvertIntArray(list) {
  // let aux = [];
  if (list && list.length > 0) {
    list.forEach((l, index) => {
      // aux.push(parseInt());
      l = parseInt(l)
    })
  }
  return list
}
export function capitalizeJson(JsonData) {
  let aux = {}
  if (JsonData) {
    for (let key in JsonData) {
      let data = JsonData[key]
      if (isValidFormat(data)) {
        data = convertD(data, 'utc')
      }
      aux = {
        ...aux,
        [key.charAt(0).toUpperCase() + key.slice(1)]: data,
      }
    }
  }
  return aux
}
export function processDragMess(list) {
  let aux = []
  list.forEach((element) => {
    aux.push({
      id: `${element.id}`,
      content: element.response,
      FileId: element.FileId,
    })
  })
  let ListMess = aux.sort(function (a, b) {
    return Math.random() - 0.5
  })
  return ListMess
}
export function makeid(length) {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
export function createOption(list, label, key, value = 'id', init = true) {
  let aux = init ? [{ key: label, value: '' }] : []
  // console.log(validateArray(list.programs));
  if (validateArray(list)) {
    list.forEach((item) => {
      // console.log(item[value]);
      aux.push({ key: item[key], value: item[value] + '' })
    })
  }

  return aux
}
export function deleteCharacteres(str) {
  let aux = ''
  for (let i = 0; i < str.length; i++) {
    const caracter = str.charAt(i)
    switch (caracter) {
      case '\\':
        if (str.charAt(i + 1) === '"') {
          aux = aux + '*'
          i++
        }
        break
      case '\\':
        if (str.charAt(i + 1) === 'n') {
          aux = aux + ' '
          i++
        }
        break
      case '/':
        if (str.charAt(i + 1) === 'n') {
          aux = aux + ' '
          i++
        }
        break
      case '¦':
        aux = aux + '`'
        break
      default:
        aux = aux + str.charAt(i)
        break
    }
  }
  return aux
}
export function deleteCharacteres2(str) {
  let aux = ''
  for (let i = 0; i < str.length; i++) {
    const caracter = str.charAt(i)
    switch (caracter) {
      case 'h':
        if (str.substring(i, i + 4) === 'http') {
          let j = i + 4
          do {
            j++
          } while (
            str.charAt(j) !== ' ' &&
            str.charAt(j) !== "'" &&
            j < str.length
          )
          aux = aux + str.substring(i, j + 1)
          i = j
        } else {
          aux = aux + str.charAt(i)
        }
        break
      case '\\':
        if (str.charAt(i + 1) === "'") {
          aux = aux + '*'
          i++
        } else if (str.charAt(i + 1) === 'n') {
          aux = aux + ' '
          i++
        }
        break
      case '/':
        if (str.charAt(i + 1) === 'n') {
          aux = aux + ' '
          i++
        }
        break
      case '¦':
        aux = aux + '`'
        break
      default:
        aux = aux + caracter
        break
    }
  }
  return aux
}
export const removeQuote = (str) => {
  do {
    if (
      (str[0] === "'" && str[str.length - 1] === "'") ||
      (str[0] === '"' && str[str.length - 1] === '"')
    ) {
      str = str.substring(1, str.length - 1)
    }
  } while (str[0] === "'" && str[str.length - 1] === "'")

  return str
}
export const transformJsonText = (str) => {
  try {
    return JSON.stringify(str).replace(/'/g, '¦').replace(/"/g, "'")
  } catch (error) {
    return ''
  }
}
export const transformTextJson = (json) => {
  try {
    return JSON.parse(json.replace(/'/g, '"').replace(/¦/g, "'"))
  } catch (error) {
    return null
  }
}
export function jsonToArray(list, key = 'id') {
  let aux = []
  list.forEach((item) => {
    aux.push(item[key] + '')
  })

  return aux
}

export function arrayToJson(list) {
  let aux = []
  list.forEach((item) => {
    aux.push({ Id: item + '' })
  })
  return aux
}
export function setColorByValue(number) {
  let numberInt = parseInt(number)
  const red = '#F44336'
  const orange = '#FFC000'
  const green2 = '#00B050'

  if (numberInt >= 0 && numberInt <= 33) return red
  if (numberInt >= 34 && numberInt <= 66) return orange
  if (numberInt >= 67 && numberInt <= 100) return green2
}
export function setColorPoint(prioridad) {
  if (prioridad === '1-verde') return '#00B050'
  if (prioridad === '2-amarillo') return '#FFA500'
  if (prioridad === '3-rojo') return '#F44336'
  if (prioridad === 'No interesado') return '#F44336'
  if (prioridad === 'no definidos') return 'grey'
  if (
    prioridad === null ||
    prioridad === undefined ||
    prioridad === '' ||
    prioridad.length === 0
  )
    return '#000000'
}

export const filterByColors = [
  {
    filter: 'todos',
    priority: 'Todos',
    colorName: 'Todos',
  },
  {
    filter: '1-verde',
    priority: '1-verde',
    colorName: 'Verde',
  },
  {
    filter: '2-amarillo',
    priority: '2-amarillo',
    colorName: 'Amarillo',
  },
  {
    filter: '3-rojo',
    priority: '3-rojo',
    colorName: 'Rojo',
  },
  {
    filter: 'no definidos',
    priority: 'no definidos',
    colorName: 'No definidos',
  },
]
