import './style/PaymentInfo.css'
import { UseOnSubmit } from '../../hooks/useOnSubmit'
import { Link } from 'react-router-dom'
const PaymentInfo = ({ graduate, gid }) => {
  async function handleShopping() {
    if (window.localStorage.getItem('id')) {
      await UseOnSubmit({
        axiosParams: {
          method: 'POST',
          url: 'Shoppingcart',
          data: {
            ProgramManagerId: graduate.programManagerId,
          },
        },
        openSnackbar: () => { },
        textSuccess: 'Se adiciono al carrito con exito',
        textWrong: 'No se pudo adicionar',
      })
    } else {
      const storageShop = window.localStorage.getItem('shopping')
        ? JSON.parse(window.localStorage.getItem('shopping'))
        : [] || []
      const filterShopping = storageShop.filter(
        (f) => f.programManagerId === graduate.programManagerId,
      )
      if (filterShopping?.length === 0) {
        window.localStorage.setItem(
          'shopping',
          JSON.stringify([
            ...storageShop,
            {
              programManagerId: graduate.programManagerId,
              name: graduate.name,
            },
          ]),
        )
      }
    }
  }

  return (
    <div className="PaymentInfo">
      <div className="PaymentInfo__payOnline">
        <p>Paga online desde nuestra página.</p>
        <Link to="/shopping-cart" onClick={handleShopping}>
          Proceder al pago
        </Link>
      </div>
      <h3>o paga en nuestras cuentas</h3>
      <div className="PaymentInfo__pay">
        <h4 className="PaymentInfo__Ah4">
          A nombre de: Capacita Global Internacional
        </h4>
        <br />
        <h4 className="PaymentInfo__Ah4">Banco Unión</h4>
        <div className="PaymentInfo__nro">
          <span>Número de cuenta: 10000019222970 </span>
        </div>
        <br />
        <h4 className="PaymentInfo__Ah4">Banco Económico</h4>
        <div className="PaymentInfo__nro">
          <span>Número de cuenta: 2081518492 </span>
        </div>
        <br />
        <h4 className="PaymentInfo__Ah4">Banco Fíe</h4>
        <div className="PaymentInfo__nro">
          <span>Número de cuenta: 40013171401 </span>
        </div>
        <br />
        <h4 className="PaymentInfo__Ah4">Banco Fassil</h4>
        <div className="PaymentInfo__nro">
          <span>Número de cuenta: 40555153</span>
        </div>
      </div>
      <p>*Para que tu pago este completo debe ser verificado</p>
      <h3 style={{ marginBottom: '5px' }}>Contacto</h3>
      <div className="PaymentInfo__contact">
        <p>
          Si realizaste el pago a través de nuestras cuentas en el BCP
          contáctenos.
        </p>
        <div className="PaymentInfo__phone">
          <i className="fas fa-headset"></i>
          <span>+591 2-2312135</span>
        </div>
        <div className="PaymentInfo__phone">
          <i className="fab fa-whatsapp"></i>
          <span>+591 77712429</span>
        </div>
        <div className="PaymentInfo__phone">
          <i className="fab fa-whatsapp"></i>
          <span>+591 76702369</span>
        </div>
      </div>
    </div>
  )
}

export default PaymentInfo
