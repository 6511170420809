import "./QuestionForm.css";
import { useState, useEffect } from "react";
import { Loading } from "../../animation";
import { SimpleInput, SimpleSelect } from "../../input";
import { DefaultBtn } from "../../buttons";
import FormFile from "../FormFile";
import { validateArray, validateStatus } from "../../../utils/Validation";
import { transformJsonText } from "../../../utils/ProcessData";
import { postAction } from "../../../services/action/ActionAuthorization";
import {
  postNewQuestion,
  putUpdateQuestion,
  isFile,
} from "../../../services/action/LessonAction";
import SingleChoice from "./SingleChoice";
import MultipleChoice from "./MultipleChoice";
import ConceptRelation from "./ConceptRelation";

const QuestionForm = (props) => {
  const {
    changeModal,
    handleCancel,
    inquiryEdit,
    quizId,
    openSnackbar,
    reload,
    lessonType,
  } = props;

  function validateEdit(inquiry, data, init) {
    if (inquiry && inquiry[data]) {
      if (data === "responses" || data === "concepts") {
        const answers = JSON.parse(inquiry[data].replace(/'/g, '"'));
        let initialArray = [];
        answers.forEach((a) => {
          if (a.FileId) {
            initialArray.push("");
          } else {
            initialArray.push(a.response || a.concept);
          }
        });
        return initialArray;
      } else {
        return inquiry[data];
      }
    }
    return init;
  }
  function validateImg(inquiry, data, init) {
    if (inquiry && inquiry[data]) {
      const answers = JSON.parse(inquiry[data].replace(/'/g, '"'));
      let initialArray = [];
      answers.forEach((a) => {
        if (a.FileId) {
          initialArray.push({
            isImage: true,
            file: a.response || a.concept,
            FileId: a.FileId,
          });
        } else {
          initialArray.push({ isImage: false, file: null });
        }
      });
      return initialArray;
    }
    return init;
  }
  const [load, setload] = useState(true);
  const [question, setquestion] = useState({
    inquiry: validateEdit(inquiryEdit, "inquiry", ""),
    inquiryImage: validateEdit(inquiryEdit, "url", null),
    correctId: validateEdit(inquiryEdit, "rightAnswer", [0]),
    Type: validateEdit(inquiryEdit, "type", "singleChoice"),
    responses: validateEdit(inquiryEdit, "responses", [""]),
    concepts: validateEdit(inquiryEdit, "concepts", [""]),
    respImg: validateImg(inquiryEdit, "responses", [
      { isImage: false, file: null },
    ]),
    concepImg: validateImg(inquiryEdit, "concepts", [
      { isImage: false, file: null },
    ]),
  });
  function validateBtn() {
    if (question.inquiry !== "" || question.Type === "conceptRelation") {
      let boolean = false;
      question.responses.forEach((res, index) => {
        if (!question.respImg[index].isImage) {
          if (res === "") {
            boolean = true;
          }
        }
      });
      if (question.Type === "conceptRelation") {
        question.concepts.forEach((res, index) => {
          if (!question.concepImg[index].isImage) {
            if (res === "") {
              boolean = true;
            }
          }
        });
      }
      return boolean;
    }
    return true;
  }
  function save(res, text, text2) {
    setload(true);
    if (validateStatus(res.status)) {
      openSnackbar(`Se ${text} la pregunta`, true, true);
      handleCancel();
      reload();
    } else {
      openSnackbar(
        res.data === "exist_user_scored"
          ? res.data
          : `No se pudo ${text2} la pregunta`,
        true,
        false
      );
    }
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        responses,
        inquiryImage,
        correctId,
        inquiry,
        Type,
        respImg,
        concepImg,
      } = question;
      let sendAux = { inquiry, responses: [], Type };
      setload(false);
      let fileId = { FileId: null, img: false };
      if (inquiryImage) {
        if (isFile(inquiryImage)) {
          let formData = new FormData();
          formData.append("file", inquiryImage);
          formData.append("description", "inquiryImage");
          formData.append("Type", "question");
          fileId = await postAction("/file", formData).then((res) => {
            if (validateStatus(res.status)) {
              return { FileId: res.data.id, img: true };
            } else {
              return { FileId: null, img: false };
            }
          });
        } else {
          fileId = { FileId: inquiryEdit.fileId, img: true };
        }
      }
      sendAux = { ...sendAux, ...fileId };
      const result = await Promise.all(
        responses.map(async (res, index) => {
          if (respImg[index].isImage) {
            if (isFile(respImg[index].file)) {
              const formData = new FormData();
              formData.append("file", respImg[index].file);
              formData.append("description", "responseImage");
              formData.append("Type", "question");
              const fileContents = await postAction("/file", formData).then(
                (res) => {
                  if (validateStatus(res.status)) {
                    return {
                      id: index + 1,
                      response: res.data.url,
                      FileId: res.data.id,
                    };
                  } else {
                    return { id: index + 1, response: "" };
                  }
                }
              );
              return fileContents;
            } else {
              return {
                id: index + 1,
                response: respImg[index].file,
                FileId: respImg[index].FileId,
              };
            }
          } else {
            return { id: index + 1, response: res };
          }
        })
      );
      sendAux.responses = transformJsonText(result);
      switch (Type) {
        case "singleChoice":
          sendAux = { ...sendAux, RightAnswer: correctId[0] + 1 };
          break;
        case "multipleChoice":
          let aux = "";
          correctId.forEach((c) => {
            aux = aux + (c + 1) + ";";
          });
          sendAux = {
            ...sendAux,
            RightAnswer: aux.substring(0, aux.length - 1),
          };
          break;
        case "conceptRelation":
          let RightAnswer = "";
          const concept = await Promise.all(
            question.concepts.map(async (res, index) => {
              RightAnswer = RightAnswer + `${index + 1};${index + 1}-`;
              if (concepImg[index].isImage) {
                if (isFile(concepImg[index].file)) {
                  const formData = new FormData();
                  formData.append("file", concepImg[index].file);
                  formData.append("description", "responseImage");
                  formData.append("Type", "question");
                  const fileContents = await postAction("/file", formData).then(
                    (res) => {
                      if (validateStatus(res.status)) {
                        return {
                          id: index + 1,
                          concept: res.data.url,
                          FileId: res.data.id,
                        };
                      } else {
                        return { id: index + 1, concept: "" };
                      }
                    }
                  );
                  return fileContents;
                } else {
                  return {
                    id: index + 1,
                    concept: concepImg[index].file,
                    FileId: concepImg[index].FileId,
                  };
                }
              } else {
                return { id: index + 1, concept: res };
              }
            })
          );
          sendAux = {
            ...sendAux,
            inquiry: transformJsonText(concept),
            RightAnswer: RightAnswer.substring(0, RightAnswer.length - 1),
          };
          break;
        default:
          break;
      }
      if (inquiryEdit) {
        await putUpdateQuestion(quizId, inquiryEdit.id, sendAux).then(
          (response) => {
            save(response, "editó", "editar");
          }
        );
      } else {
        await postNewQuestion(quizId, sendAux).then((response) => {
          save(response, "creó", "crear");
        });
      }
    } catch (e) {
      setload(true);
    }
  };
  const handleChange = (e, index, type) => {
    const { name, value, checked } = e.target;
    if (name === "correctId") {
      if (type) {
        if (checked) {
          setquestion((a) => ({ ...a, correctId: [...a.correctId, index] }));
        } else {
          let newCorrectId = question.correctId;
          newCorrectId.splice(newCorrectId.indexOf(index), 1);
          setquestion((a) => ({ ...a, correctId: newCorrectId }));
        }
      } else {
        setquestion((a) => ({ ...a, [name]: [index] }));
      }
    } else {
      setquestion((a) => ({ ...a, [name]: value }));
    }
  };
  const handleImgChange = (e, index, type, status) => {
    let newResponses = question[type];
    newResponses[index] = { isImage: status, file: e };
    setquestion((a) => ({ ...a, [type]: newResponses }));
  };

  const handleResponse = (event) => {
    const { name, value } = event.target;
    let newResponses = question.responses;
    newResponses[name] = value;
    setquestion((a) => ({ ...a, responses: newResponses }));
  };
  function remove(index) {
    let newResponses = question.responses;
    let newRespImg = question.respImg;
    let newConcepts = question.concepts;
    let newConcepImg = question.concepImg;
    newResponses.splice(index, 1);
    newRespImg.splice(index, 1);
    newConcepts.splice(index, 1);
    newConcepImg.splice(index, 1);
    setquestion((a) => ({
      ...a,
      responses: newResponses,
      respImg: newRespImg,
      concepts: newConcepts,
      concepImg: newConcepImg,
      correctId: a.correctId === index ? 0 : a.correctId,
    }));
  }
  function add() {
    setquestion((a) => ({
      ...a,
      responses: [...a.responses, ""],
      concepts: [...a.concepts, ""],
      respImg: [...a.respImg, { isImage: false, file: null }],
      concepImg: [...a.concepImg, { isImage: false, file: null }],
    }));
  }
  function handleInquiryImage(value) {
    setquestion((a) => ({
      ...a,
      inquiryImage: value.ImageFile,
    }));
    changeModal(false, "");
  }

  const handleImg = (handleInquiry) => {
    changeModal(
      true,
      "",
      <FormFile
        handleInquiry={handleInquiry}
        onClose={() => changeModal(false, "")}
      />,
      null
    );
  };

  function handleText() {
    setquestion((a) => ({
      ...a,
      inquiryImage: null,
    }));
  }

  return (
    <div className="QuestionForm">
      <form action="">
        <SimpleSelect
          name="Type"
          label="Tipo de pregunta"
          options={
            lessonType === "quizTeacher"
              ? [
                  { key: "Selección unica", value: "singleChoice" },
                  { key: "Selección multiple", value: "multipleChoice" },
                  { key: "Relación de concepto", value: "conceptRelation" },
                  { key: "Pregunta abierta", value: "openQuestion" },
                ]
              : [
                  { key: "Selección unica", value: "singleChoice" },
                  { key: "Selección multiple", value: "multipleChoice" },
                  { key: "Relación de concepto", value: "conceptRelation" },
                ]
          }
          value={question.Type}
          onChange={handleChange}
        />
        {question.Type !== "conceptRelation" && (
          <div className="QuestionForm__inquiry">
            <SimpleInput
              name="inquiry"
              type="textArea"
              label="Pregunta"
              onChange={handleChange}
              value={question.inquiry}
              err={question.inquiry === "" ? "Campo requerido" : ""}
            />
            <DefaultBtn
              type="button"
              padding="0 10px"
              onClick={
                question.inquiryImage
                  ? handleText
                  : () => handleImg(handleInquiryImage)
              }
            >
              <i
                className={
                  question.inquiryImage ? "fas fa-times-circle" : "far fa-image"
                }
              ></i>
            </DefaultBtn>
          </div>
        )}
        {question.inquiryImage && <RenderImage file={question.inquiryImage} />}
        {validateArray(question.responses)
          ? question.responses.map((response, index) => {
              switch (question.Type) {
                case "multipleChoice":
                  return (
                    <MultipleChoice
                      key={index}
                      index={index}
                      question={question}
                      handleChange={handleChange}
                      handleResponse={handleResponse}
                      remove={remove}
                      response={response}
                      handleImg={handleImg}
                      handleImgChange={handleImgChange}
                      onClose={() => changeModal(false, "")}
                    />
                  );
                case "conceptRelation":
                  return (
                    <ConceptRelation
                      key={index}
                      index={index}
                      question={question}
                      handleChange={handleChange}
                      remove={remove}
                      response={response}
                      setquestion={setquestion}
                      handleImg={handleImg}
                      handleImgChange={handleImgChange}
                      onClose={() => changeModal(false, "")}
                    />
                  );
                case "openQuestion":
                  return null;
                case "singleChoice":
                default:
                  return (
                    <SingleChoice
                      key={index}
                      index={index}
                      question={question}
                      handleChange={handleChange}
                      handleResponse={handleResponse}
                      remove={remove}
                      response={response}
                      handleImg={handleImg}
                      handleImgChange={handleImgChange}
                      onClose={() => changeModal(false, "")}
                    />
                  );
              }
            })
          : null}
        {load ? (
          <div className="QuestionForm__btns">
            <DefaultBtn type="button" onClick={add}>
              Añadir{" "}
              {question.Type === "conceptRelation" ? "relación" : "respuesta"} +
            </DefaultBtn>
            <div className="QuestionForm__btns">
              <DefaultBtn
                background={true}
                onClick={handleCancel}
                type="button"
              >
                Cancelar
              </DefaultBtn>
              <DefaultBtn
                onClick={onSubmit}
                type="submit"
                disabled={
                  question.Type === "openQuestion" ? false : validateBtn()
                }
              >
                Guardar
              </DefaultBtn>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </form>
    </div>
  );
};

export const RenderImage = ({ file }) => {
  const [image, setimage] = useState(null);
  useEffect(() => {
    function renderImage(selected) {
      const ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        let reader = new FileReader();
        reader.onloadend = () => {
          setimage(reader.result);
        };
        reader.readAsDataURL(selected);
      }
    }
    renderImage(file);
  }, [file]);

  return (
    <div className="RenderImage">
      <img src={isFile(file) ? image : file} alt="" />
    </div>
  );
};
export default QuestionForm;
