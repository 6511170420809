import './style/ContentVIdeo.css'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { dataLock, convertD, convertSeconds } from '../../utils/ConvertDate'
import { ToolTip } from '../boxes'
import { processUrlImage } from '../../utils/ProcessData'
const ContentVIdeo = (props) => {
  const {
    contentData: { name, id, imageUrl, status, duration, startDatePerUser },
    match: {
      params: { cid, mid, pid },
    },
  } = props
  const statusVideo = status === 'completed' ? true : false
  const image = {
    backgroundImage: imageUrl ? `url(${processUrlImage(imageUrl, 300)})` : '',
    height: '140px',
  }

  return (
    <div className="ContentVIdeo__act">
      {!dataLock(startDatePerUser) && (
        <div className="ContentVIdeo__block">
          <h3>Fecha de inicio: {convertD(startDatePerUser, 'LL')}</h3>
          <i className="fas fa-lock"></i>
          <div></div>
        </div>
      )}
      <ToolTip content="Video">
        <Link
          to={
            pid
              ? `/program/${pid}/course/${cid}/module/${mid}/video/${id}`
              : `/program/course/${cid}/module/${mid}/video/${id}`
          }
          className="ContentVIdeo"
          style={{
            background: `${statusVideo ? '#469585A1' : 'var(--white)'}`,
          }}
        >
          <div className="ContentVIdeo__img" style={image}>
            <i className="fas fa-play-circle"></i>
          </div>
          <div
            className="ContentVIdeo__main"
            style={{
              color: `${statusVideo ? 'var(--white)' : 'var(--black)'}`,
            }}
          >
            <h3 className="ContentVIdeo__title">{name}</h3>
            <div className="ContentVIdeo__time">
              <i className="far fa-clock"></i>
              <span>{convertSeconds(duration, 'mm:ss')}</span>
            </div>
            <div className="ContentVIdeo__time">
              {statusVideo ? (
                <>
                  <i className="fas fa-eye-slash"></i>
                  <span>Completado</span>
                </>
              ) : (
                <>
                  <i className="fas fa-eye"></i>
                  <span>No completado</span>
                </>
              )}
            </div>
          </div>
        </Link>
      </ToolTip>
    </div>
  )
}

export default withRouter(ContentVIdeo)
