import { DefaultBtn } from "../../../components/buttons";
import { H4, H2, H3 } from "../../../components/text";
import { useState, useEffect } from "react";
import { putShopp } from "../../../services/action/Shopping";
import { postAction } from "../../../services/action/Action";
import {
  validateArray,
  validateStatus,
  validateInstitution,
} from "../../../utils/Validation";
import { RenderModal } from "../../../components/modal";
import { ActionButton } from "../../../components/buttons";
import { Loading } from "../../../components/animation";
import { SimpleInput, SimpleSelect } from "../../../components/input";
import { makeid } from "../../../utils/ProcessData";
import { useAxios } from "../../../hooks";
import "./ModalRegisterOEA.css";
import config from "../../../config.json";

export const ModalRegisterOEA = (props) => {
  const {
    onClose,
    dataShopping,
    orders,
    startSession,
    handleCoupon,
    sesionUser,
    changeModal,
    history,
    urlPost = "/order",
    textClose = "Volver al pedido",
    dataPayment = {},
    orderResult = "order-result",
  } = props;
  const { institutions } = sesionUser;
  const isAdmin = validateArray(validateInstitution(institutions, "admin"));

  const [dataModal, setDataModal] = useState({
    status: false,
    user: {
      name: isAdmin ? "" : sesionUser?.user?.firstName || "",
      lastname: isAdmin ? "" : sesionUser?.user?.lastName || "",
      email: isAdmin ? "" : sesionUser?.user?.email || "",
      paisIndex: "",
      estado_lbl: "",
      phone: "",
      Amount: dataShopping.total,
      Currency: dataShopping.currency ? dataShopping.currency : "BOB",
      Order_id: makeid(8),
    },
    paymentType: 1,
  });
  const [load, setload] = useState(false);
  const [view, setview] = useState(1);
  const [order, setOrder] = useState("");
  function handleModal(name, data) {
    setDataModal((a) => ({
      ...a,
      [name]: data,
    }));
  }
  const { response: respCountry, loading: loadCountry } = useAxios({
    method: "GET",
    url: `/Country`,
  });
  function handleDisabled() {
    let disabled = false;
    if (dataModal.user) {
      for (let key in dataModal.user) {
        if (dataModal.user[key] === "") {
          disabled = true;
        }
      }
    }
    return disabled;
  }

  async function handleOnSubmit() {
    const {
      name,
      lastname,
      email,
      paisIndex,
      estado_lbl,
      phone,
      Amount,
      Currency,
      Order_id,
      academicDegree,
      position,
      address,
    } = dataModal.user;
    setload(true);
    const uid = window.localStorage.getItem("id");
    await postAction(urlPost, {
      ...dataPayment,
      Name: name,
      LastName: lastname,
      Email: email,
      Country: respCountry[paisIndex].code,
      City: estado_lbl,
      Telephone: phone,
      PaymentMethod: dataModal.paymentType ? "onlinePayment" : "transfer",
      Amount: Amount,
      Currency: Currency,
      Order_id: Order_id,
      CouponId: handleCoupon?.id ? handleCoupon.id : null,
      ProgramManagers: orders,
      AcademicDegree: academicDegree,
      Position: position,
      Address: address,
    }).then((response) => {
      setload(false);
      if (response && validateStatus(response.status)) {
        if (response.data !== "already_exists") {
          const { rolsUserToReturn, user, token, institutions } = response.data;
          if (!uid) {
            window.localStorage.setItem("id", user.id);
            window.localStorage.setItem("token_seguridad", token);
            startSession(
              user,
              rolsUserToReturn ? rolsUserToReturn : institutions,
              [],
              true
            );
          }
        } else {
          if (response.data === "already_exists") {
            changeModal(
              true,
              "Tu cuenta ya existe",
              "Debes iniciar sesion para poder continuar con el pago",
              "Iniciar sesion",
              () => history.push("/auth/login")
            );
          }
        }
        if (dataModal.paymentType) {
          document.getElementById("id-page").submit();
          changeModal(false, null);
          // history.push(`/order-result?result=${Order_id}&order_id=TAR-2132`)
        } else {
          history.push(`/order/${response.data.order}`);
        }
        setview(!view);
      }
    });
  }

  async function handleNextStep() {
    const {
      name,
      lastname,
      email,
      paisIndex,
      estado_lbl,
      phone,
      Amount,
      Currency,
      Order_id,
      academicDegree,
      position,
      address,
    } = dataModal.user;
    setload(true);
    const uid = window.localStorage.getItem("id");
    await postAction(urlPost, {
      ...dataPayment,
      Name: name,
      LastName: lastname,
      Email: email,
      Country: respCountry[paisIndex].code,
      City: estado_lbl,
      Telephone: phone,
      PaymentMethod: "transfer",
      Amount: Amount,
      Currency: Currency,
      Order_id: Order_id,
      CouponId: handleCoupon?.id ? handleCoupon.id : null,
      ProgramManagers: orders,
      AcademicDegree: academicDegree,
      Position: position,
      Address: address,
    }).then((response) => {
      setload(false);
      if (response && validateStatus(response.status)) {
        if (response.data !== "already_exists") {
          const { rolsUserToReturn, user, token, institutions } = response.data;
          if (!uid) {
            window.localStorage.setItem("id", user.id);
            window.localStorage.setItem("token_seguridad", token);
            startSession(
              user,
              rolsUserToReturn ? rolsUserToReturn : institutions,
              [],
              true
            );
          }
        } else {
          if (response.data === "already_exists") {
            changeModal(
              true,
              "Tu cuenta ya existe",
              "Debes iniciar sesion para poder continuar con el pago",
              "Iniciar sesion",
              () => history.push("/auth/login")
            );
          }
        }
        setOrder(response.data.order);
        setview(view + 1);
      }
    });
  }
  function handlePayment() {
    if (dataModal.paymentType === 1) {
      document.getElementById("id-page").submit();
      changeModal(false, null);
      setview(view + 1);
      // history.push(
      //   `/order-result?result=${dataModal.user.Order_id}&order_id=TAR-2132`,
      // )
    } else {
      history.push(`/order/${order}/oea`);
    }
  }

  return (
    <>
      {/* <H2 textAlign="center">Finalizar compra</H2> */}
      <form
        target="form-page"
        action="https://www.livees.net/Checkout/api4"
        method="POST"
        id="id-page"
      >
        <div className="ShoppingAside ModalRegisterOEA">
          <div
            className="ShoppingAside__form"
            style={{ display: view === 1 ? "" : "none" }}
          >
            <H2 textAlign="center">Formulario de pre-registro</H2>
            <UserInput
              dataModal={dataModal}
              setDataModal={setDataModal}
              respCountry={respCountry}
              loadCountry={loadCountry}
              orderResult={orderResult}
              orders={orders}
            />
          </div>
          {/* {response?.plus_code &&
              response.plus_code.compound_code.includes('Bolivia') ? ( */}
          <div
            style={{ display: view === 2 ? "" : "none" }}
            className="ShoppingAside__paymentType"
          >
            <H2 textAlign="center">Método de pago</H2>
            <div
              className={`ShoppingAside__option ${
                dataModal.paymentType === 1 ? "checked" : ""
              }`}
              onClick={() => handleModal("paymentType", 1)}
            >
              <H4 fontWeight="600" margin="10px 0 5px">
                Pagar usando tarjeta
              </H4>
              <div className="ShoppingAside__paymentImage">
                <img
                  src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472643/assets/1024px-Old_Visa_Logo.svg_af0gri_idfw4f.png"
                  alt="Logo de Visa"
                />
                <img
                  src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472642/assets/MasterCard_early_1990s_logo_akmuca_afg1xs.png"
                  alt="Logo de MasterCard"
                />
              </div>
            </div>
            <div
              className={`ShoppingAside__option ${
                dataModal.paymentType === 2 ? "checked" : ""
              }`}
              onClick={() => handleModal("paymentType", 2)}
            >
              <H4 fontWeight="600" margin="10px 0 5px">
                Realizar una trasferencia o QR
              </H4>
              <H4 margin="0">
                Al realizar el pedido podrá ver las cuentas a las cuales puede
                realizar el deposito bancario y posteriormente se le pasará los
                enlaces correspondientes.
              </H4>
            </div>
            <div
              className={`ShoppingAside__option ${
                dataModal.paymentType === 3 ? "checked" : ""
              }`}
              onClick={() => handleModal("paymentType", 3)}
            >
              <H4 fontWeight="600" margin="10px 0 5px">
                Pedir mas información
              </H4>
              <H4 margin="0">
                Se te mostraran los datos de contacto para que puedas realizar
                cualquier consulta
              </H4>
            </div>
          </div>
          {/* ) : null} */}
        </div>
        <div style={{ display: view === 3 ? "none" : "" }}>
          {load ? (
            <Loading />
          ) : (
            <ActionButton
              // onClick={handleOnSubmit}
              onClick={
                view === 1 ? () => handleNextStep() : () => handlePayment()
              }
              // onClick={() => setview(view + 1)}
              onCancel={onClose}
              textBtn="Siguiente paso"
              textCancel="Cerrar"
              type="button"
              disabled={handleDisabled()}
            />
          )}
        </div>
      </form>
      <div
        style={{ display: view === 3 ? "" : "none" }}
        //   style={{ display: !view ? '' : 'none' }}
        className="ShoppingAside__iframe"
      >
        {dataShopping.currency === "USD" && (
          <H3 fontWeight="500" margin="0">
            Nota: Si se encuentra en Bolivia tiene la opción de cambiar a su
            moneda local
          </H3>
        )}
        <iframe
          title="iframe"
          id="form-page"
          name="form-page"
          // scrolling="no"
        ></iframe>
        <div className="PaymentMethods__btn"></div>
      </div>
    </>
  );
};

const UserInput = (props) => {
  const {
    dataModal,
    setDataModal,
    respCountry,
    loadCountry,
    orderResult,
    orders,
  } = props;

  function handleOnChange(e) {
    const { name, value } = e.target;
    setDataModal((a) => ({
      ...a,
      user: {
        ...a.user,
        [name]: value,
      },
    }));
  }
  const {
    response: respCities,
    loading: loadCities,
    reload,
  } = useAxios({
    method: "GET",
    url: dataModal.user.paisIndex
      ? `/Country/${respCountry[dataModal.user.paisIndex].code}/cities`
      : null,
  });
  function handleDropdown(list, label, key, value) {
    let aux = [{ key: `${label}:`, value: "" }];
    if (validateArray(list)) {
      list
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((element, index) => {
          aux.push({
            key: element[key],
            value: value ? element[value] : index,
          });
        });
    }
    return aux;
  }
  useEffect(() => {
    if (dataModal.user.paisIndex) {
      reload();
    }
  }, [dataModal.user.paisIndex]);
  return (
    <>
      <input
        type="hidden"
        name="_"
        defaultValue="6n254pj3q89mth8c81f5u9yo9as2xbdglw46fvz7k908er5i0"
      />
      <input
        type="hidden"
        name="__"
        defaultValue="ae8jg10ayld1pb7fcf7an1tm21b5burz943992hsq6vkwo6ix"
      />
      <input
        type="hidden"
        name="postURL"
        defaultValue={`${config.domainURL}/${orderResult}`}
      />
      <input
        type="hidden"
        name="amt2"
        defaultValue={`${dataModal.user.Amount}.00`}
      />
      <input
        type="hidden"
        name="currency"
        defaultValue={dataModal.user.Currency}
      />
      <input
        type="hidden"
        name="invno"
        defaultValue={dataModal.user.Order_id}
      />
      <SimpleInput
        name="name"
        type="text"
        label="Nombres *"
        onChange={handleOnChange}
        value={dataModal.user.name}
        err={dataModal.user.name === "" ? "Campo requerido" : ""}
      />
      <SimpleInput
        label="Apellidos *"
        type="text"
        name="lastname"
        onChange={handleOnChange}
        value={dataModal.user.lastname}
        err={dataModal.user.lastname === "" ? "Campo requerido" : ""}
      />
      <SimpleInput
        label="Correo electrónico *"
        type="email"
        name="email"
        onChange={handleOnChange}
        value={dataModal.user.email}
        err={dataModal.user.email === "" ? "Campo requerido" : ""}
      />
      {!loadCountry && (
        <SimpleSelect
          name="paisIndex"
          label="País *"
          options={handleDropdown(respCountry, "País", "name")}
          onChange={handleOnChange}
          err={dataModal.user.paisIndex === "" ? "Campo requerido" : ""}
        />
      )}
      <input
        type="hidden"
        name="pais"
        defaultValue={
          respCountry?.length > 0 && dataModal.user.paisIndex
            ? respCountry[dataModal.user.paisIndex].code
            : ""
        }
      />
      {!loadCities && (
        <SimpleSelect
          name="estado_lbl"
          label="Departamento *"
          options={handleDropdown(respCities, "Departamento", "name", "name")}
          onChange={handleOnChange}
          err={dataModal.user.estado_lbl === "" ? "Campo requerido" : ""}
        />
      )}
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <span
          style={{
            background: "#b3b3b3",
            padding: "0 10px",
            height: "40px",
            textAlign: "center",
            lineHeight: "40px",
            borderRadius: "5px",
            fontSize: "1.6rem",
          }}
        >
          {respCountry?.length > 0 && dataModal.user.paisIndex
            ? respCountry[dataModal.user.paisIndex].phoneCode
            : null}
        </span>
        <SimpleInput
          label="Teléfono o celular *"
          type="text"
          name="phone"
          onChange={handleOnChange}
          value={dataModal.user.phone}
          err={dataModal.user.phone === "" ? "Campo requerido" : ""}
        />
      </div>
      {orders.find((element) => element.ProgramManagerId === 223) ? (
        <>
          <SimpleInput
            label="Título/grado académico"
            type="text"
            name="academicDegree"
            onChange={handleOnChange}
            value={dataModal.user.academicDegree}
          />
          <SimpleInput
            label="Cargo/posición"
            type="text"
            name="position"
            onChange={handleOnChange}
            value={dataModal.user.position}
          />
          <SimpleInput
            label="Dirección"
            type="text"
            name="address"
            onChange={handleOnChange}
            value={dataModal.user.address}
          />
        </>
      ) : null}
    </>
  );
};

export default ModalRegisterOEA;
