// import { useState } from "react";
import './style/ButtonNavigation.css'
import { validateArray } from '../../utils/Validation'
const ButtonNavigation = (props) => {
  const { btns, handlebtn, greenStyle = false } = props
  function bntStyle(num) {
    if (btns.activate.id === num) {
      return greenStyle
        ? 'ButtonNavigation__activate2'
        : 'ButtonNavigation__activate'
    }
    return ''
  }
  const btnStyle = {
    width: `calc(98% / ${btns.btn.length})`,
  }
  return (
    <div className="ButtonNavigation">
      {validateArray(btns)
        ? btns.btn.map((l) => (
            <button
              style={btnStyle}
              onClick={() => handlebtn(l)}
              key={l.id}
              className={bntStyle(l.id)}
            >
              {l.name}
            </button>
          ))
        : null}
    </div>
  )
}

export default ButtonNavigation
