import React from 'react'
import { ToolTip } from '../boxes'

export const LinkEnlace = ({
  content,
  href = window.location.href,
  prefix = 'fas',
  icon,
  fontSize = '20',
  color = 'black',
}) => {
  // console.log(href)
  return (
    <ToolTip content={content}>
      <a
        href={`${href === null || href === '' ? window.location.href : href}`}
        style={{
          fontSize: `${fontSize}px`,
          color: `${color}`,
          padding: '1px 6px 1px 6px',
          textAlign:'center'
        }}
        target={`${href === null || href === '' ? '' : '_blank'}`}
      >
        <i className={`${prefix} fa-${icon}`}></i>
      </a>
    </ToolTip>
  )
}
