import { validateArray } from '../../../utils/Validation'
import ProgramCourse from './ProgramCourse'
const CourseProgram = ({
  courses,
  changeInfo,
  openSnackbar,
  reload,
  changeModal,
}) => {
  return (
    <div className="CourseProgram">
      {validateArray(courses) ? (
        courses
          .sort((a, b) => a.order - b.order)
          .map((c) => (
            <ProgramCourse
              changeInfo={changeInfo}
              openSnackbar={openSnackbar}
              changeModal={changeModal}
              course={c}
              key={c.id}
              reload={reload}
            />
          ))
      ) : (
        <h3>Aún no hay cursos</h3>
      )}
    </div>
  )
}

export default CourseProgram
