import { convertD, dataLock } from '../../utils/ConvertDate'
import { CompModal } from '../../components/modal'
import { Loading } from '../../components/animation'
import { NormalButton } from '../../components/buttons'
import EditorView from '../editorjs/EditorView'
import { useState } from 'react'
import { ToolTip } from '../boxes'
import './style/ContentCertificate.css'
import { changeModal, openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { validateDataArray, validateStatus } from '../../utils/Validation'
import { H4 } from '../../components/text'
import { SimpleInput } from '../../components/input'
import { postGeneratecertificate } from '../../services/action/LessonAction'
const ContentCertificate = (props) => {
  const {
    contentData,
    changeModal,
    openSnackbar,
    history,
    sesionUser,
    reload,
    type,
    activate,
    finalAside,
    typeCourse,
  } = props

  const [modal, setmodal] = useState(false)
  const [load, setload] = useState(true)
  const [checkbox, setcheckbox] = useState(false)
  function handleCheckbox(e) {
    const { checked } = e.target
    setcheckbox(checked)
  }
  if (contentData) {
    const {
      id,
      name,
      description,
      startDatePerUser,
      status,
      aditionalUrlResources,
    } = contentData
    const handleModal = () => {
      if (dataLock(startDatePerUser)) {
        if (validateDataArray(status, ['available', null])) {
          if (sesionUser.user.firstName || sesionUser.user.lastName) {
            setmodal(!modal)
          } else {
            changeModal(
              true,
              'Tus datos no están completos',
              'Debes completar tus datos personales para que se genere el certificado correctamente, luego puedes volver',
              'Ir al perfil',
              () => history.push('/account'),
            )
          }
        } else {
          postGeneratecertificate(id).then((response) => {
            if (validateStatus(response.status)) {
              openSnackbar('Certificado generado con éxito', true, true)
              window.open(response.data.certificate.url, '_blank')
            } else {
              openSnackbar('No se pudo generar', true, false)
            }
            reload()
          })
        }
      } else {
        changeModal(
          true,
          'El certificado aún no está disponible',
          'Por el momento le invitamos a revisar y completar sus datos personales para que se genere el certificado correctamente',
          'Ir al perfil',
          () => history.push('/account'),
        )
      }
    }
    const onSubmit = async () => {
      try {
        setload(false)
        await postGeneratecertificate(id).then((response) => {
          if (validateStatus(response.status)) {
            window.open(response.data, '_blank')
            openSnackbar('Certificado generado con éxito', true, true)
          } else {
            openSnackbar('No se pudo generar', true, false)
          }
          setload(true)
          reload()
          setmodal(!modal)
        })
      } catch (e) {
        setload(true)
        console.log(e)
      }
    }
    function CardChildren() {
      return (
        <div className="ContentCertificate__content">
          <div className="ContentCertificate__head">
            {typeCourse !== 'asynchronous' && (
              <h3>
                Disponible a partir del {convertD(startDatePerUser, 'LL')}
              </h3>
            )}
            <div className="ContentCertificate__imgs">
              {/* <img
                src="https://res.cloudinary.com/dzxmzxvbr/image/upload/h_40,c_scale,q_auto,f_auto/v1615502886/ceci-logo_ppickb.gif"
                alt=""
              />
              <img
                src="https://res.cloudinary.com/dzxmzxvbr/image/upload/h_40,c_scale,q_auto,f_auto/v1615169311/hbyrsin1hwlcfchgqbaf.png"
                alt="Logotipo Capacita"
              />
              <img
                src="https://res.cloudinary.com/dzxmzxvbr/image/upload/h_40,c_scale,q_auto,f_auto/v1614797906/DHvrNJpN_400x400_g8st0w.jpg"
                alt="Logotipo de La Paz"
              />
              <img
                src="https://res.cloudinary.com/dzxmzxvbr/image/upload/h_40,c_scale,q_auto,f_auto/v1615502886/logo-canada_nlkamq.png"
                alt=""
              /> */}
            </div>
          </div>
          <h2>{name}</h2>
          {description && <EditorView cutText={true} data={description} />}
        </div>
      )
    }
    function LinkChildren() {
      return (
        <div className={`Lection__header${activate ? '-activate' : ''}`}>
          <div className="Lection__progress">
            <div className="Lection__line"></div>
            <div className="Lection__circle"></div>
            <div
              className={finalAside ? 'Lection__line' : 'Lection__lineT'}
            ></div>
          </div>
          <div className="Lection__title">{name}</div>
        </div>
      )
    }
    return (
      <>
        {type === 'aside' ? (
          dataLock(startDatePerUser) && status === 'completed' ? (
            <a
              style={{ textDecoration: 'none' }}
              className="Lection"
              href={aditionalUrlResources}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LinkChildren />
            </a>
          ) : (
            <div
              onClick={handleModal}
              className="Lection"
              style={{ cursor: 'pointer' }}
            >
              <LinkChildren />
            </div>
          )
        ) : (
          <ToolTip content="Certificado">
            {dataLock(startDatePerUser) && status === 'completed' ? (
              <a
                style={{ textDecoration: 'none' }}
                className="ContentCertificate"
                href={aditionalUrlResources}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardChildren />
              </a>
            ) : (
              <div className="ContentCertificate" onClick={handleModal}>
                <CardChildren />
              </div>
            )}
          </ToolTip>
        )}
        {modal && (
          <CompModal onClose={() => setmodal(!modal)}>
            <div className="ContentVirtualClass__modal">
              <h2>Verifique su nombre</h2>
              <p style={{ fontSize: '1.6rem' }}>
                Si tu nombre es correcto presiona generar certificado si no es
                así ve a tu perfil y corrígelo.{' '}
                <Link to="/account">Corregir mi nombre</Link>
                <H4
                  textAlign="center"
                  margin="20px 0"
                  fontSize="2.5rem"
                  fontWeight="600"
                >
                  {sesionUser.user.firstName} {sesionUser.user.lastName}
                </H4>
                <br />
                <strong>
                  <span style={{ margin: '10px', color: 'var(--red)' }}>
                    <i className="fas fa-exclamation-circle"></i> Una vez
                    generado el certificado el nombre del mismo no se podrá
                    modificar
                  </span>
                </strong>
                <br />
              </p>
              <div className="ContentCertificate__check">
                <SimpleInput
                  name="verification"
                  type="checkbox"
                  label={`Confirmo que mi certificado se genere como: ${sesionUser.user.firstName} ${sesionUser.user.lastName}`}
                  onChange={(event) => handleCheckbox(event)}
                  value={checkbox}
                  // err={response === '' ? 'Campo requerido' : ''}
                />
              </div>
              <div style={{ textAlign: 'center' }}>
                {load ? (
                  <NormalButton disabled={!checkbox} onClick={onSubmit}>
                    Generar certificado
                    {/* <strong>
                      {sesionUser.user.firstName} {sesionUser.user.lastName}
                    </strong> */}
                  </NormalButton>
                ) : (
                  <Loading />
                )}
                <NormalButton
                  onClick={() => setmodal(!modal)}
                  background={true}
                >
                  Cerrar
                </NormalButton>
              </div>
            </div>
          </CompModal>
        )}
      </>
    )
  } else {
    return null
  }
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { changeModal, openSnackbar })(
  withRouter(ContentCertificate),
)
