import { changeLayout, changeInfo } from '../../../store/actions'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { useActionParam } from '../../../hooks'
import { getAction } from '../../../services/action/ActionAuthorization'
import { TableDefault } from '../../../components/table'
import { H2, SubtitleData } from '../../../components/text'
import { LoadCircle } from '../../../components/animation'
import { validateStatus } from '../../../utils/Validation'
const header = [{ key: 'name', name: 'Nombre' }]
const Score = (props) => {
  const {
    changeLayout,
    changeInfo,
    match: {
      params: { pid },
    },
  } = props

  useEffect(() => {
    changeLayout('BigInformation')
    changeInfo(<></>)
  }, [changeLayout, changeInfo])

  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: `Users/ScoresPerProgram/${pid}`,
  })
  function preConfig(data) {
    let aux = []
    data.forEach((element) => {
      aux.push({
        ...element.courseData,
        lessonScoreList: element.lessonScoreList,
        totalScore: element.totalScore,
      })
    })
    return aux
  }
  return (
    <>
      <H2 textAlign="center">Notas del programa</H2>
      {validateStatus(statusRequest) ? (
        <TableDefault
          handleInfo={(course) =>
            changeInfo(<LessonScoreList course={course} />)
          }
          header={header}
          main={preConfig(dataRequest)}
          keyOrder="order"
        />
      ) : (
        <LoadCircle />
      )}
    </>
  )
}
const LessonScoreList = ({ course }) => {
  const headerLesson = [
    { key: 'name', name: 'Lección' },
    {
      key: 'status',
      name: 'Estado',
      type: 'textColor',
      color: {
        Inconcluso: '#c93f3f75',
        Presentado: '#e3ad2d75',
        Calificado: '#46958575',
      },
    },
    { key: 'percentage', name: 'Porcentaje de la calificación' },
    { key: 'score', name: 'Nota total' },
  ]
  function mainConfig(list) {
    list.forEach((u) => {
      if (u.status === 'available' || u.status === 'Inconcluso') {
        u.status = 'Inconcluso'
      }
      if (u.status === 'completed' || u.status === 'Presentado') {
        u.status = 'Presentado'
      }
      if (u.status === 'scored' || u.status === 'Calificado') {
        u.status = 'Calificado'
      }
      if (u.score === -1) {
        u.score = 0
      }
    })
    return list
  }
  return (
    <div className="">
      <H2 textAlign="center">Notas del curso</H2>
      <SubtitleData subtitle="Nombre del curso">{course.name}</SubtitleData>
      <SubtitleData subtitle="Nota del curso">{course.totalScore}</SubtitleData>
      <TableDefault
        header={headerLesson}
        main={mainConfig(course.lessonScoreList)}
      />
    </div>
  )
}
export default connect(null, { changeLayout, changeInfo })(Score)
