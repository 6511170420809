import { validateArray } from '../../../utils/Validation'
import { DefaultBtn } from '../../../components/buttons'
import { H3 } from '../../../components/text'
import { FormNewModule } from '../../../components/form'
import { BoxFlex } from '../../../components/boxes'
import { withRouter } from 'react-router-dom'
import CardModule from '../institution/CardModule'
const ModulesCourse = (props) => {
  const { changeInfo, openSnackbar, course, reload, history } = props
  return (
    <div className="CourseModules">
      <BoxFlex justify="space-between">
        <div className=""></div>
        <DefaultBtn
          onClick={() =>
            changeInfo(
              <FormNewModule
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                cid={course.id}
                reload={reload}
              />,
            )
          }
        >
          Crear módulos
          <i className="fas fa-plus"></i>
        </DefaultBtn>
      </BoxFlex>
      {validateArray(course.modules) ? (
        course.modules
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((m) => (
            <CardModule
              module={m}
              key={m.id}
              reload={reload}
              cid={course.id}
              history={history}
              changeInfo={changeInfo}
              openSnackbar={openSnackbar}
            />
          ))
      ) : (
        <H3>No hay módulos en este curso</H3>
      )}
    </div>
  )
}

export default withRouter(ModulesCourse)
