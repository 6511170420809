import { useState } from 'react'
import { Loading } from '../../../components/animation'
import { H2, H3, SubtitleData } from '../../../components/text'
import { BoxFlex } from '../../../components/boxes'
import { ActionButton, RenderButton } from '../../../components/buttons'
import { useAxiosAuth } from '../../../hooks'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { urlAvatar, avatarChild } from '../../../utils/Constant'
import { postEmptyAction } from '../../../services/action/ActionAuthorization'
import FormNewChild from './FormNewChild'
const AddUserExist = (props) => {
  const { user, onCancel, pid, openSnackbar, handleReload, changeModal } = props
  const [load, setload] = useState(false)
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/users/UserTutorId/${user.id}`,
  })
  function handleAddUser(child, setLoading) {
    changeModal(
      true,
      `¿Estás seguro que quieres inscribir a ${child.firstName || child.name}?`,
      '',
      'Inscribir',
      () => confirmAddUser(child, setLoading),
    )
  }
  function confirmAddUser(child, setLoading) {
    setLoading(true)
    postEmptyAction(`/Course/${pid}/EnrollChildUser/${child.id}`).then(
      (resp) => {
        setLoading(false)
        if (validateStatus(resp.status)) {
          openSnackbar(
            `Se inscribió a ${child.firstName || child.name} exitosamente`,
            true,
            true,
          )
          handleReload()
        } else {
          openSnackbar(
            `No se pudo inscribir a ${child.firstName || child.name}`,
            true,
            false,
          )
        }
      },
    )
  }

  function handleNewChild() {
    changeModal(
      true,
      null,
      <FormNewChild
        {...props}
        father={user}
        onClose={() => changeModal(false, null)}
      />,
      null,
    )
  }

  if (loading) {
    return <Loading />
  }

  return validateArray(response.fathers) ? (
    <>
      <H3 textAlign="center" fontSize="1.4rem" fontWeight="500">
        * El usuario que escogiste es un niño
      </H3>
      <H2 color="var(--gray)">Datos del niño</H2>
      <SubtitleData subtitle="Nombre:">
        {user.firstName} {user.lastName}
      </SubtitleData>
      <H2 color="var(--gray)">Lista de tutores de {user.firstName}</H2>
      {response.fathers.map((father) => (
        <CardUser key={father.id} user={father} type="father" />
      ))}
      {load ? (
        <Loading />
      ) : (
        <ActionButton
          textBtn={`Inscribir a ${user.firstName}`}
          onClick={() => handleAddUser(user, setload)}
          onCancel={onCancel}
        />
      )}
    </>
  ) : (
    <>
      <H3 textAlign="center" fontSize="1.4rem" fontWeight="500">
        * El usuario que escogiste es un tutor
      </H3>
      <H2 color="var(--gray)">Datos del tutor</H2>
      <SubtitleData subtitle="Nombres:">
        {user.firstName} {user.lastName}
      </SubtitleData>
      <SubtitleData subtitle="Correo:">{user.email}</SubtitleData>
      <SubtitleData subtitle="Teléfono:">{user.telephone}</SubtitleData>
      <BoxFlex justify="flex-start" margin="0 0 10px">
        <H2 margin="0" color="var(--gray)">
          Lista de niños de {user.firstName}
        </H2>
        <RenderButton onClick={handleNewChild}>
          <i className="fas fa-plus"></i>
        </RenderButton>
      </BoxFlex>
      {response.childs.map((child) => (
        <CardUser
          key={child.id}
          user={child}
          type="child"
          handleAddUser={handleAddUser}
        />
      ))}
    </>
  )
}

const CardUser = ({ user, type, handleAddUser }) => {
  const [load, setload] = useState(false)
  return (
    <BoxFlex wrap="nowrap" margin="0 0 10px">
      <BoxFlex
        width="100%"
        wrap="nowrap"
        padding="5px"
        borderRadius="10px"
        justify="flex-start"
        border="1px solid gray"
      >
        <div
          style={{
            height: '80px',
            width: '80px',
            backgroundImage: `url("${
              user.photoUrl
                ? user.photoUrl
                : type === 'child'
                ? avatarChild
                : urlAvatar
            }")`,
            backgroundSize: 'cover',
            border: '2px solid var(--white)',
            borderRadius: '50%',
          }}
        ></div>
        <div className="">
          <H3>
            {user.name} {user.lastName}
          </H3>
          {user.email && (
            <H3 fontSize="1.4rem" fontWeight="500">
              <strong>Correo:</strong> {user.email}
            </H3>
          )}
          {user.telephone ? (
            <H3 fontSize="1.4rem" fontWeight="500" margin="0">
              <strong>Teléfono:</strong> {user.telephone}
            </H3>
          ) : user.username ? (
            <H3 fontSize="1.4rem" fontWeight="500" margin="0">
              <strong>Usuario:</strong> {user.username}
            </H3>
          ) : null}
        </div>
      </BoxFlex>
      {type === 'child' &&
        (load ? (
          <Loading />
        ) : (
          <RenderButton
            content={`Inscribir a ${user.name}`}
            onClick={() => handleAddUser(user, setload)}
            background="var(--green)"
          >
            <i className="fas fa-check"></i>
          </RenderButton>
        ))}
    </BoxFlex>
  )
}

export default AddUserExist
