import './style/Header.css'
import { Link, withRouter } from 'react-router-dom'
import { Sidebar } from '../navigation'
import { ButtonModalS, NormalLink } from '../buttons'
import { H3 } from '../text'
const Header = (props) => {
  const { headerTitle, location, membership, sesionUser, history } = props

  function clickHandler(e, element) {
    history.push('/')
    if (location.pathname === '/') {
      e.preventDefault()
      document
        .getElementById(element)
        .scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }
  return (
    <>
      <div className="Header">
        {sesionUser.authenticate ? <Sidebar /> : null}
        <Link to="/">{headerTitle}</Link>
        <div className="Header__option">
          {sesionUser.authenticate ? null : (
            <ul className="Header__list">
              <li>
                <a onClick={(e) => clickHandler(e, 'contents')} href="#">
                  INICIO
                </a>
              </li>
              <li>
                <Link to="/about-us">¿QUIENES SOMOS?</Link>
              </li>
              {location.pathname === '/' ? (
                <li>
                  <a onClick={(e) => clickHandler(e, 'contents')} href="#">
                    CONTENIDO
                  </a>
                </li>
              ) : null}
              {location.pathname === '/' ? (
                <li>
                  <a onClick={(e) => clickHandler(e, 'contact')} href="#">
                    CONTACTANOS
                  </a>
                </li>
              ) : null}
            </ul>
          )}
          <ButtonModalS {...props} />
        </div>
      </div>
      {(location.pathname.includes('/catalogue/short-courses') &&
        membership?.status !== 'completed') ||
        (location.pathname.includes('/graduates/') &&
          location.pathname.includes('/course') &&
          membership?.status !== 'completed') ? (
        <HeaderBanner />
      ) : null}
    </>
  )
}
const HeaderBanner = () => {
  return (
    <Link style={{ textDecoration: 'none' }} to="/payment">
      <div className="HeaderBanner">
        <div className="HeaderBanner__main">
          <H3 margin="0" fontSize="5rem" color="var(--white)" fontWeight="500">
            100<span style={{ fontSize: '2.5rem' }}></span> Bs
          </H3>
          <div className="HeaderBanner__text">
            <H3 margin="0" color="var(--white)" fontWeight="700">
              Por el mes de Septiembre en honor al día del Estudiante
            </H3>
            <H3 margin="0" color="var(--white)" fontWeight="500">
              Adquiere una suscripción de 2 meses
            </H3>
          </div>
        </div>
        <NormalLink to="/payment" background="var(--white)">
          Ver planes
        </NormalLink>
      </div>
    </Link>
  )
}
export default withRouter(Header)
