import "./style/LineBar.css";
const LineBar = (props) => {
  const progress = {
    width: `${props.progressline ? props.progressline + "%" : "0%"}`,
  };
  const back = {
    height: `${props.border ? "10px" : "5px"}`,
  };
  return (
    <div className="LineBar" style={back}>
      <div className="LineBar__background"></div>
      <div className="LineBar__progress" style={progress}></div>
    </div>
  );
};

export default LineBar;
