import HttpClient from "../HttpClient";
export const getProgramManager = () => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/ProgramManager`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
