import './style/HomeworkAside.css'
import { useState } from 'react'
import { H4 } from '../../../../../components/text'
import { validateArray } from '../../../../../utils/Validation'
import { ConvertBaMB, capitalizeJson } from '../../../../../utils/ProcessData'
import { HomeworkSubmitUrl } from '../../../../../utils/FormParameters'
import { GlobalForm } from '../../../../../components/form'
import { UseOnSubmit } from '../../../../../hooks/useOnSubmit'

const HomeworkAside = (props) => {
  const { homeworks, numberFiles } = props
  const numberFileTotal =
    numberFiles - (validateArray(homeworks) ? homeworks.length : 0)
  return (
    <div className="HomeworkAside">
      <h2>Subir archivos</h2>
      <div className="HomeworkAside__links">
        <h3>Documentación presentada:</h3>
        {validateArray(homeworks) ? (
          homeworks.map((file) => (
            <LinkHomework {...props} file={file} key={file.id} />
          ))
        ) : (
          <h3 className="HomeworkAside__h3">Aún no subiste un archivo</h3>
        )}
      </div>
      {numberFileTotal > 0 ? (
        <>
          <H4 fontWeight="600" margin="15px 0 10px" textAlign="center">
            Subir nuevos archivos
          </H4>
          <HandleSubmit {...props} />
        </>
      ) : null}
    </div>
  )
}

function LinkHomework(props) {
  const { file } = props
  const [edit, setedit] = useState(false)
  return (
    <>
      <div key={file.id} className="HomeworkAside__link">
        <a href={file.file.url} target="_blank" rel="noopener noreferrer">
          {file.file.description}
        </a>
        <i
          className={`fas fa-${edit ? 'times' : 'edit'}`}
          onClick={() => setedit(!edit)}
        ></i>
      </div>
      {edit && (
        <>
          <H4 margin="10px 0" textAlign="center">
            Reemplazar archivo
          </H4>
          <HandleSubmit
            {...props}
            file={file.file}
            fileId={file.id}
            handleClose={() => setedit(!edit)}
          />
        </>
      )}
    </>
  )
}
const HandleSubmit = (props) => {
  const [load, setload] = useState(true)
  const {
    fileExtension,
    changeInfo,
    openSnackbar,
    id,
    fileId,
    file,
    handleClose,
  } = props
  const HomeworkSubmit = [
    {
      name: 'File',
      control: 'file',
      initial: null,
      validate: null,
      label: '',
      type: fileExtension,
    },
  ]
  function onSubmitHomework(values) {
    let boolMaxSize = true
    if (fileExtension !== 'url') {
      if (values.File) {
        let formData = new FormData()
        formData.append('File', values.File)
        formData.append('Description', values.File.name)
        formData.append('Type', 'homework')
        if (ConvertBaMB(values.File.size) > 8) {
          boolMaxSize = false
          props.changeModal(
            true,
            'El archivo que intenta subir es muy grande',
            'Sólo puede subir archivos menores a 8MB',
          )
        }
        values = formData
      } else {
        boolMaxSize = false
      }
    } else {
      if (values.Url.substring(0, 4) !== 'http') {
        boolMaxSize = false
        props.changeModal(
          true,
          'El enlace que intenta subir no parece una url',
          '',
        )
      }
    }
    if (boolMaxSize) {
      if (fileId) {
        props.changeModal(
          true,
          'Reemplazar el archivo',
          'Se eliminará el archivo anteriormente presentado de forma permanente',
          'Reemplazar',
          () =>
            uploadFiles(
              values,
              fileExtension === 'url'
                ? `/homework/${fileId}/ReplaceHomeworkUrl`
                : `/homework/replaceHomework/${fileId}`,
            ),
        )
      } else {
        uploadFiles(
          values,
          `/lesson/${id}/UploadHomework${fileExtension === 'url' ? 'Url' : ''}`,
        )
      }
    }
  }
  async function uploadFiles(file, url) {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: 'PUT',
        url: url,
        data: file,
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Se presento tu tarea con éxito',
      textWrong: 'No se pudo presentar',
      functionSuccess: functionSuccess,
    })
    setload(true)
  }
  function functionSuccess() {
    props.uploadHomework()
    props.reloadRequest()
    props.changeModal(true, 'Se envió y presentó la tarea con éxito', '')
  }
  return (
    <GlobalForm
      InputsJson={fileExtension === 'url' ? HomeworkSubmitUrl : HomeworkSubmit}
      textBtn="Enviar tarea"
      title=""
      data={file ? capitalizeJson(file) : {}}
      load={load}
      onSubmit={onSubmitHomework}
      onCancel={file ? handleClose : () => changeInfo(<></>)}
    />
  )
}
export default HomeworkAside
