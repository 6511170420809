import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LinkNav } from './index'
import { connect } from 'react-redux'
import {
  validateInstitution,
  validateArray,
  validateEducator,
} from '../../utils/Validation'
import './style/Sidebar.css'
import { FaBars, FaTimes } from 'react-icons/fa'
import { IconContext } from 'react-icons'
const Sidebar = (props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('sidebarT')
  const { institutions, authenticate, rols, user } = props.sesionUser
  const isAdmin = validateInstitution(institutions, 'admin')
  const isSeller = validateInstitution(institutions, 'sales')
  const isManager = validateInstitution(institutions, 'manager')
  const isHumanResources = validateInstitution(institutions, 'humanResources')
  const isAffiliate = validateInstitution(institutions, 'affiliate')

  const isSocialNetwork = validateInstitution(institutions, 'socialnetwork')
  const isViewerOrders = validateInstitution(institutions, 'viewerOrders')
  const isEducator = validateEducator(rols)

  function Burger({ open, setOpen }) {
    return (
      <IconContext.Provider
        value={{ className: 'Sidebar__icon1', size: '2.5rem' }}
      >
        <div onClick={() => setOpen(!open)}>
          {open ? <FaTimes /> : <FaBars />}
        </div>
      </IconContext.Provider>
    )
  }
  const Menu = ({ open, setOpen }) => {
    return (
      <>
        <nav
          className="Menu__nav"
          open={open}
          style={{
            transform: open ? 'translateX(0)' : 'translateX(-100%)',
          }}
        >
          <div className="Menu__liks" onClick={() => setOpen(!open)}>
            <LinkNav to="/" icon="home">
              {t('sidInicio')}
            </LinkNav>
            <LinkNav to="/about" icon="handshake">
              ¿Quienes somos?
            </LinkNav>
            <LinkNav to="/catalogue/short-courses" icon="book-open">
              Contenido
            </LinkNav>
            {user.father && (
              <LinkNav to="/kids" icon="users">
                Mis niñ@s
              </LinkNav>
            )}
            {authenticate && (
              <>
                <LinkNav to="/programs" icon="chalkboard-teacher">
                  Mis Programas
                </LinkNav>
                {validateArray(isEducator) && (
                  <LinkNav to="/courses" icon="briefcase">
                    Mis Cursos
                  </LinkNav>
                )}
                {validateArray(isAdmin) &&
                  (isAdmin.length === 1 ? (
                    <LinkNav
                      to={`/institution/${isAdmin[0].id}`}
                      icon="university"
                    >
                      Institución
                    </LinkNav>
                  ) : (
                    <LinkNav to="/institution" icon="university">
                      Institución
                    </LinkNav>
                  ))}
                {validateArray(isSeller) || validateArray(isSocialNetwork) ? (
                  <>
                    {/* <LinkNav to="/contacts" icon="users">
                      Contactos
                    </LinkNav> */}
                    <LinkNav to="/resume-contact" icon="users">
                      Contactos
                    </LinkNav>
                  </>
                ) : null}
                {validateArray(isSeller) ||
                validateArray(isSocialNetwork) ||
                validateArray(isManager) ||
                validateArray(isAdmin) ? (
                  <>
                    <LinkNav to="/calendar/viewSchule/" icon="calendar-alt">
                      Horarios
                    </LinkNav>
                  </>
                ) : null}
                {validateArray(isManager) && (
                  <LinkNav to="/manager" icon="chart-pie">
                    Reportes
                  </LinkNav>
                )}
                {validateArray(isHumanResources) ? (
                  <LinkNav to="/admin" icon="user-cog">
                    Administración
                  </LinkNav>
                ) : null}
                {validateArray(isAffiliate) ? (
                  <LinkNav to="/affiliate" icon="filter">
                    Afiliado
                  </LinkNav>
                ) : null}
                {validateArray(isViewerOrders) ? (
                  <LinkNav to="/orders/oea" icon="tasks">
                    Pedidos
                  </LinkNav>
                ) : null}
                <LinkNav to="/account" icon="user-circle">
                  {t('sidCuenta')}
                </LinkNav>
              </>
            )}
            <LinkNav to="/questions" icon="question-circle">
              {t('sidPreguntas')}
            </LinkNav>
          </div>
        </nav>
        {open && (
          <div onClick={() => setOpen(!open)} className="Menu__close"></div>
        )}
      </>
    )
  }

  return (
    <div>
      <Burger open={open} setOpen={setOpen} />
      <Menu open={open} setOpen={setOpen} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, null)(Sidebar)
