import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import global_de from "./de/globalDe.json";
import global_en from "./en/globalEn.json";
import global_es from "./es/globalEs.json";
import global_fr from "./fr/globalFr.json";
import global_pt from "./pt/globalPt.json";

import error_de from "./de/errorDe.json";
import error_en from "./en/errorEn.json";
import error_es from "./es/errorEs.json";
import error_fr from "./fr/errorFr.json";
import error_pt from "./pt/errorPt.json";

const resources = {
  de: { ...global_de, ...error_de },
  en: { ...global_en, ...error_en },
  es: { ...global_es, ...error_es },
  fr: { ...global_fr, ...error_fr },
  pt: { ...global_pt, ...error_pt },
};
i18n.use(initReactI18next).init({
  resources,
  lng: "es",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
