import { useAxiosAuth } from "../../../../hooks";
import TableMain from "../../../../components/table/TableMain";
import { Loading } from "../../../../components/animation";
import { H2, H3 } from "../../../../components/text";
import { FormDefault } from "../../../../components/form";
import { BoxFlex } from "../../../../components/boxes";
import { RenderButton, ActionButton } from "../../../../components/buttons";
import { validateArray } from "../../../../utils/Validation";
import { InputCreateTracking } from "../../../../utils/FormParameters";
import { UseOnSubmit } from "../../../../hooks/useOnSubmit";

const CourseTracking = (props) => {
  const { pid, iid, changeInfo, openSnackbar, changeModal } = props;
  const {
    response,
    loading,
    reload: reloadListtracking,
  } = useAxiosAuth({
    method: "GET",
    url: `/tracking/course/${pid}`,
  });
  const actionSeg = [
    {
      id: 1,
      label: "Borrar",
      action: handleSegDelete,
      icon: "fas fa-trash",
    },
    // {
    //   id: 2,
    //   label: "Editar",
    //   // action: handleSheduleEdit,
    //   icon: "fas fa-edit",
    // },
  ];
  function handleSegDelete(item) {
    changeModal(
      true,
      "¿Estás seguro que quieres eliminar este seguimiento al curso?",
      <ActionButton
        textBtn="Eliminar"
        onCancel={() => changeModal(false, <></>)}
        onClick={() => onSubmit(item)}
      />,
      null,
      null
    );
  }

  const onSubmit = async (item) => {
    await UseOnSubmit({
      axiosParams: {
        method: "PUT",
        url: `/tracking/${item.id}/course/${props.pid}`,
      },
      openSnackbar: openSnackbar,
      textSuccess: "Seguimiento Borrado",
      textWrong: "No se pudo borrar",
      functionSuccess: succes,
      process: true,
    });
  };
  function succes() {
    reloadListtracking();
    // changeInfo(<></>);
    changeModal(false, <></>);
  }
  const handleAddTracking = () => {
    changeModal(
      true,
      <AddTracking {...props} reloadListtracking={reloadListtracking} />,
      null,
      null
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="">
      <BoxFlex justify="space-between">
        <H2 color="var(--gray)" textAlign="center" margin="0">
          Lista de seguimiento
        </H2>
        <RenderButton onClick={handleAddTracking}>
          <i className="fas fa-plus"></i>
        </RenderButton>
      </BoxFlex>
      {validateArray(response) ? (
        <TableMain
          header={[
            { key: "label", name: "Nombre" },
            { key: "type", name: "Tipo de dato" },
            {
              key: "action",
              name: "Borrar",
              type: "actions",
              actions: actionSeg,
            },
          ]}
          main={response}
          reload={reloadListtracking}
        />
      ) : (
        <H3>No tienes lista de seguimiento</H3>
      )}
    </div>
  );
};
const AddTracking = (props) => {
  const { changeModal, openSnackbar, reloadListtracking } = props;
  const { response, loading } = useAxiosAuth({
    method: "GET",
    url: `/tracking/Institution/${props.iid}`,
  });

  const onSubmit = async (values) => {
    await UseOnSubmit({
      axiosParams: {
        method: "POST",
        url: `/tracking/${values.id}/course/${props.pid}`,
      },
      openSnackbar: openSnackbar,
      textSuccess: "Seguimiento agregado",
      textWrong: "No se pudo crear",
      functionSuccess: succes,
      process: true,
    });
  };
  function succes() {
    reloadListtracking();
    // changeInfo(<></>);
    changeModal(false, <></>);
  }

  function handleClick(values) {
    changeModal(
      true,
      "¿Estás seguro que quieres adicionar este seguimiento al curso?",
      <ActionButton
        textBtn="Adicionar"
        onCancel={() =>
          changeModal(true, <AddTracking {...props} />, null, null)
        }
        onClick={() => onSubmit(values)}
      />,
      null,
      null
    );
  }

  if (loading) {
    return <Loading />;
  } else {
    return (
      <>
        <H2 color="var(--gray)" textAlign="center" margin="0 0 5px">
          Lista de seguimiento
        </H2>
        <H3 color="var(--gray)" fontWeight="500" margin="0">
          * Seleccione el seguimiento que desee agregar al curso
        </H3>
        <br />
        {validateArray(response) ? (
          <TableMain
            header={[
              { key: "label", name: "Nombre" },
              { key: "type", name: "Tipo de dato" },
            ]}
            main={response}
            handleInfo={handleClick}
          />
        ) : (
          <H3>No tienes lista de seguimiento</H3>
        )}
      </>
    );
  }
};

export default CourseTracking;
