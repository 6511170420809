import { H2, H3 } from '../../../components/text'
import { IconAction } from '../../../components/buttonsIcon'
import { Loading } from '../../../components/animation'
import { BoxFlex } from '../../../components/boxes'
import { TableFilterContainer } from '../../../components/table'
import { RenderModal } from '../../../components/modal'
import { GlobalForm } from '../../../components/form'
import { validateStatus, validateArray } from '../../../utils/Validation'
import { InputsFile } from '../../../utils/FormParameters'
import { postAction } from '../../../services/action/ActionAuthorization'
import { useAxiosAuth } from '../../../hooks'
import { useState, useEffect } from 'react'

const UsersRecuperatory = (props) => {
  const { changeModal, cid, changeInfo, openSnackbar } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/course/${cid}/listrecuperatory`,
  })
  useEffect(() => {
    if (!loading) {
      reload()
    }
  }, [cid])

  function handleAddUser() {
    changeModal(
      true,
      '',
      <ListFailedUsers
        changeModal={changeModal}
        cid={cid}
        openSnackbar={openSnackbar}
        reload={reload}
      />,
      null,
    )
  }
  return (
    <div>
      <BoxFlex margin="0 0 5px" justify="flex-end">
        <IconAction
          content="Pre-registrar"
          color="#458e7f"
          icon="user-plus"
          onClick={handleAddUser}
        />
        <IconAction
          content="Cerrar"
          color="#c93f3f"
          icon="times"
          onClick={() => changeInfo(<></>)}
        />
      </BoxFlex>
      <H2 color="var(--gray)" textAlign="center" margin="0">
        Lista de usuarios inscritos
      </H2>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((user) => (
          <CardUser
            key={user.id}
            user={user}
            cid={cid}
            reload={reload}
            changeModal={changeModal}
            openSnackbar={openSnackbar}
          />
        ))
      ) : (
        <H3>No hay usuarios inscritos</H3>
      )}
    </div>
  )
}
const CardUser = (props) => {
  const { user, cid, changeModal, openSnackbar, reload } = props
  function hadleCompete() {
    changeModal(
      true,
      '¿Estás seguro que quieres completar la inscripción de este usuario?',
      '',
      'Completar inscripción',
      () => onSubmit(),
    )
  }
  function onSubmit() {
    postAction('/AditionalPayment/changePayment', {
      UserId: user.id,
      CourseId: cid,
    }).then((response) => {
      if (validateStatus(response.status)) {
        reload()
        openSnackbar('Inscripción completada', true, true)
      } else {
        openSnackbar('No se pudo completar inscripción', true, false)
      }
    })
  }

  return (
    <BoxFlex wrap="nowrap" margin="0 0 5px" justify="flex-end">
      <div
        style={{
          width: '100%',
          border: '1px solid #b1b1b1',
          margin: '10px 0',
          borderRadius: '10px',
          padding: '8px 5px',
        }}
      >
        <H3 fontWeight="500" color="var(--red)" textAlign="end">
          {user.statusPayment === 'completed' ? 'Completado' : 'Pre-registrado'}
        </H3>
        <H3 fontWeight="700" margin="0">
          {user.firstName} {user.lastName}
        </H3>
        <H3 margin="0">{user.telephone}</H3>
      </div>
      {user.statusPayment === 'completed' ? null : (
        <IconAction
          content="Completar registro"
          color="#458e7f"
          icon="user-check"
          onClick={hadleCompete}
        />
      )}
    </BoxFlex>
  )
}
const ListFailedUsers = (props) => {
  const { cid, changeModal, openSnackbar, reload } = props
  const [modal, setmodal] = useState(null)
  const [load, setload] = useState(true)

  function hadleRegister(values) {
    setload(false)
    let formData = new FormData()
    formData.append('userId', modal.id)
    formData.append('ImageFile', values.ImageFile)
    postAction(`/course/${cid}/EnrollUserRecuperatory`, formData).then(
      (response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Inscripción completada', true, true)
          reload()
          changeModal(false, null)
        } else {
          openSnackbar('No se pudo completar inscripción', true, false)
        }
      },
    )
  }
  function preConfig(list) {
    return list.map((item) => ({
      totalScore: item.totalScore,
      ...item.userData,
    }))
  }

  return (
    <>
      {modal && (
        <RenderModal onClose={() => setmodal(null)} closeOutside width="500px">
          <H2 color="var(--gray)" textAlign="center">
            Adjuntar documento de pago de:
          </H2>
          <H3 color="var(--green)" fontSize="1.8rem" textAlign="center">
            {modal.firstName} {modal.lastName}
          </H3>
          <GlobalForm
            InputsJson={InputsFile}
            textBtn="Inscribir"
            data={{}}
            load={load}
            onSubmit={hadleRegister}
            onCancel={() => setmodal(null)}
          />
        </RenderModal>
      )}
      <H2 color="var(--gray)" margin="0 15px 5px" textAlign="center">
        Adicionar usuarios al recuperatorio
      </H2>
      <TableFilterContainer
        preConfig={preConfig}
        param={`/course/${cid}/failList`}
        header={[
          { key: 'firstName', name: 'Nombres' },
          { key: 'lastName', name: 'Apellidos' },
          { key: 'email', name: 'Correo' },
          { key: 'totalScore', name: 'Nota', type: 'text' },
        ]}
        onClick={(user) => setmodal(user)}
      />
    </>
  )
}

export default UsersRecuperatory
