import { DefaultBtn } from '../../../components/buttons'
import DataSubtitle from '../../../components/dataTitle/DataSubtitle'
import { FormDefault } from '../../../components/form'
import { useAxiosAuth } from '../../../hooks'
import { changeInfo } from '../../../store/actions'
import {
  editWhatsApp,
  editWhatsAppCourseProgram,
} from '../../../utils/FormParameters'
import { capitalizeJson } from '../../../utils/ProcessData'
import './style/InformationProgram.css'
const InformationProgram = ({ typeContent, program, teachers }) => {
  return (
    <div className="InformationProgram">
      <DataSubtitle title="Programa al que pertenece" type="text">
        {program.programManager
          ? program.programManager.name
          : 'No esta asignado a un programa'}
      </DataSubtitle>
      <DataSubtitle title="Nombre de la versión" type="text">
        {program.programManager
          ? program.versionName
          : 'No esta asignado a un programa'}
      </DataSubtitle>
      <DataSubtitle title="Enlace de WhatsApp" type="text">
        {program?.whatsappGroupUrl !== null &&
        program?.whatsappGroupUrl !== '' ? (
          <a
            style={{ cursor: 'pointer', color: 'var(--black)' }}
            href={`${program?.whatsappGroupUrl}`}
            target="_blank"
          >
            Grupo de WhatsApp
          </a>
        ) : (
          'No tiene enlace de whatsApp'
        )}
      </DataSubtitle>
      <DataSubtitle title="Docentes" type="text">
        {teachers &&
          teachers
            .map((teacher) => `${teacher.name} ${teacher.lastName}`)
            .join(', ')}
      </DataSubtitle>
      {/* <DataSubtitle title="Enlace de video" type="anchor">
        {program.videoUrl}
      </DataSubtitle>
      <DataSubtitle title="Enlace de pago" type="anchor">
        {program.paymentUrl}
      </DataSubtitle> */}
      <DataSubtitle
        title={`¿Para quien es este ${
          typeContent ? typeContent : 'diplomado'
        }?`}
        type="section"
      >
        {program.addressedTo}
      </DataSubtitle>
      <DataSubtitle title="Lo que aprenderás" type="section">
        {program.courseAchievements}
      </DataSubtitle>
      <DataSubtitle title="Descripción" type="section">
        {program.description}
      </DataSubtitle>
      <DataSubtitle title="Fecha de inicio" type="date">
        {program.startDate}
      </DataSubtitle>
      <DataSubtitle title="Fecha de finalizacion" type="date">
        {program.finishDate}
      </DataSubtitle>
      <DataSubtitle title="Portada" type="img">
        {program.photoUrl}
      </DataSubtitle>
    </div>
  )
}

export default InformationProgram
