import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../../input'
import { useTranslation } from 'react-i18next'
import { RenderButton } from '../../buttons'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Loading } from '../../animation'
import { postAction } from '../../../services/action/Action'
import { validateInstitution, validateArray } from '../../../utils/Validation'
import { connect } from 'react-redux'
import { openSnackbar, startSession } from '../../../store/actions'
import '../style/Login.css'
import { errorsMessage } from '../../../utils/Constant'

const Login = (props) => {
  const { location, openSnackbar, startSession, history } = props
  const [load, setload] = useState(true)
  const { t } = useTranslation('loginF')
  const initialValues = {
    UsernameOrEmail: '',
    password: '',
  }

  const validationSchema = Yup.object({
    UsernameOrEmail: Yup.string().required(t('fieldObligatory')),
    password: Yup.string()
      .required(t('fieldObligatory'))
      .min(4, t('charactersRequired')),
  })

  function save(res) {
    setload(true)
    if (res.status === 200) {
      openSnackbar('Inicio Exitoso', true, true)
      const { institutions, courses, user, token, father } = res.data
      window.localStorage.setItem('id', user.id)
      window.localStorage.setItem('token_seguridad', token)
      startSession({ ...user, father: father }, courses, institutions, true)
      if (location.state && location.state.from.pathname !== '/') {
        history.push(location.state.from.pathname)
      } else {
        const isAdmin = validateInstitution(institutions, 'admin')
        history.push(
          validateArray(isAdmin)
            ? `/institution${isAdmin.length === 1 ? `/${isAdmin[0].id}` : ''}`
            : validateArray(validateInstitution(institutions, 'educator'))
            ? '/courses'
            : validateArray(validateInstitution(institutions, 'viewerOrders'))
            ? '/orders/oea'
            : '/programs',
        )
      }
    } else {
      openSnackbar(
        res.data.title ? res.data.title : errorsMessage[res.data],
        true,
        false,
      )
    }
  }

  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/auth/login', values).then((response) => {
        save(response)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }

  return (
    <div className="Login">
      {/* <span className="form__span">{t("lgIniciar")}</span> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={t('lgUsC')}
                name="UsernameOrEmail"
              />
              <FormikControl
                control="input"
                type="password"
                label={t('lgPass')}
                name="password"
              />
              {load ? (
                <RenderButton
                  type="submit"
                  fontSize="1.8rem"
                  background="#6a308180"
                  width="100%"
                  borderRadius="10px"
                >
                  {t('lgIniciar')}
                </RenderButton>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
      <br />
      <span className="forgotPassword">
        {t('lgForget')}{' '}
        <Link
          to={{
            pathname: '/auth/recover',
            state: {
              from: location.state ? location.state.from : '/',
            },
          }}
        >
          {t('lgPass')}
        </Link>{' '}
        ?
      </span>
    </div>
  )
}

const mapDispatchToProps = {
  openSnackbar,
  startSession,
}
export default connect(null, mapDispatchToProps)(Login)
