import { Link } from 'react-router-dom'
import './style/NormalLink.css'
const NormalLink = ({ to, children, background = 'var(--orange2)' }) => {
  return (
    <Link style={{ background: background }} className="NormalLink" to={to}>
      {children}
    </Link>
  )
}

export default NormalLink
