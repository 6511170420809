import { useEffect, useState } from 'react'
import { GlobalForm } from '../../components/form'
import { validateStatus } from '../../utils/Validation'
import { capitalizeJson } from '../../utils/ProcessData'
import { useAxiosAuth } from '../../hooks'
import config from '../../config.json'
import { InputPreRegister, InputWonderMath } from '../../utils/FormParameters'
import {
  postAction,
  putAction,
} from '../../services/action/ActionAuthorization'
import ListChildren from './ListChildren'
import * as Yup from 'yup'
import { Loading } from '../../components/animation'
const FormPreRegister = (props) => {
  const {
    changeModal,
    user,
    reload,
    openSnackbar,
    course,
    onCancel,
    reloadInterests,
  } = props
  const [load, setload] = useState(true)

  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/contact/${user.contactId}`,
  })

  async function onSubmit(values, handleCancel) {
    try {
      setload(false)
      await postAction(`/preregisterquiz/contact`, {
        ...values,
        ContactId: user.contactId,
        Email: values.Email,
        ExpeditionCi: values.ExpeditionCi,
        IdDocument: values.IdDocument,
        LastName: values.LastName,
        Name: values.Name,
        ContactInterestId: course.contactInterestId,
        PhoneNumber: values.PhoneNumber,
        SignedList: course.programManagerList.map((program) => ({
          ProgramManagerId: program.programManagerId,
          SignedId: values[program.programManagerId],
        })),
      }).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          reload()
          handleCancel()
          openSnackbar('Se guardo con exito', true, true)
        } else {
          openSnackbar(
            response?.data ? response.data : 'No se pudo completar',
            true,
            false,
          )
        }
      })
    } catch (e) {
      setload(true)
    }
  }
  const [errorField, setErrorField] = useState('Field')

  const element = document.getElementById(errorField)

  useEffect(() => {
    if (element) {
      element.style.border = '2px solid var(--red)'
    }
    return () => {
      if (element) {
        element.style.border = '1px solid #b1b1b1'
      }
    }
  }, [errorField, element])

  const associateExistingContact = (email, contactId) => {
    setload(false)
    postAction(`${config.requestURL2}/users/findEmail`, {
      email: email,
    }).then((response) => {
      setload(true)
      if (validateStatus(response.status)) {
        const { id } = response.data

        putAction(`/contact/${contactId}/associate/${id}/update`).then(
          (res) => {
            if (validateStatus(res.status)) {
              openSnackbar('Contacto asociado con exito', true, true)
              reload()
              onCancel()
              reloadInterests()
              changeModal(false, null)
            } else {
              openSnackbar(
                'Hubo un error al asociar el correo electrónico con el contacto',
                true,
                false,
              )
            }
          },
        )
      } else {
        openSnackbar('No se pudo adicionar', true, false)
      }
    })
  }
  function errorMessage(error, email, contactId) {
    switch (error) {
      case 'email_exists':
        setErrorField('Email')
        changeModal(
          true,
          '¿Deseas asociar el contacto con el usuario encontrado de wonderMath?',
          '',
          'Asociar',
          () => {
            associateExistingContact(email, contactId)
          },
        )
        return `El correo electrónico ya se encuentra asociado a una cuenta de wonderMath`
      case 'username_exists':
        setErrorField('username')
        return `EL usuario ya se encuentra asociado a una cuenta de WonderMath`
      case 'phone_exists':
        setErrorField('Phone')
        return `EL Telefono ya se encuentra asociado a una cuenta`
      default:
        return 'No se pudo registrar'
    }
  }
  async function handleWonderMath(values) {
    try {
      setload(false)
      let resp = await postAction(`${config.requestURL2}/auth/register`, {
        email: values.Email,
        password: values.Password,
        password_confirmation: values.Password,
        telephone: values.PhoneNumber,
        username: values.username,
      }).then((response) => response)
      if (resp?.data && validateStatus(resp.status)) {
        await handleUserExternal(values, resp.data)
      } else {
        setload(true)
        openSnackbar(
          errorMessage(resp.data, values.Email, user.contactId),
          true,
          false,
        )
      }
    } catch (e) {
      setload(true)
    }
  }
  async function handleUserExternal(values, userResp) {
    try {
      setload(false)
      await putAction(
        `/contact/${user.contactId}/userExternal/${userResp.userToReturn.id}`,
        values,
      ).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          reload()
          changeModal(
            true,
            '',
            <ListChildren
              {...props}
              user={{
                ...user,
                userExternalId: userResp.userToReturn.id,
                ...values,
              }}
              onSubmit={onSubmit}
            />,
            null,
          )
          openSnackbar('Contacto registrado', true, true)
        } else {
          openSnackbar(errorMessage(response?.data), true, false)
        }
      })
    } catch (e) {
      setload(true)
    }
  }

  if (course.typeCourse === 'wondermath' && user.userExternalId) {
    return <ListChildren {...props} onSubmit={onSubmit} />
  } else {
    return (
      <div style={{ marginRight: '10px' }}>
        {loading ? (
          <Loading />
        ) : (
          <GlobalForm
            InputsJson={
              course.typeCourse === 'wondermath'
                ? [
                    ...InputPreRegister,
                    ...InputWonderMath,
                    // ...course.programManagerList.map((program) => ({
                    //   control: 'getSelect',
                    //   initial: '',
                    //   validate: Yup.string().required('Campo requerido'),
                    //   label: `Elegir paralelo "${program.name}"`,
                    //   name: program.programManagerId,
                    //   url: `/programManager/${program.programManagerId}/parallels`,
                    //   keyOption: 'versionName',
                    //   value: 'id',
                    // })),
                  ]
                : [
                    ...InputPreRegister,
                    ...course.programManagerList.map((program) => ({
                      control: 'getSelect',
                      initial: '',
                      validate: Yup.string().required('Campo requerido'),
                      label: `Elegir paralelo "${program.name}"`,
                      name: program.programManagerId,
                      url: `/programManager/${program.programManagerId}/parallels`,
                      keyOption: 'versionName',
                      value: 'id',
                    })),
                  ]
            }
            textBtn="Pre-inscribir"
            title="Pre-inscribir al contacto"
            data={capitalizeJson({
              ...response,
              LastName: response.lastname,
              IdDocument: response.ci,
              Email: response.email,
              PhoneNumber: response.phone,
            })}
            load={load}
            onSubmit={
              course.typeCourse === 'wondermath'
                ? handleWonderMath
                : (values) => onSubmit(values, onCancel)
            }
            onCancel={onCancel}
          />
        )}
      </div>
    )
  }
}

export default FormPreRegister
