import './Questions.css'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { H2, H4, Section } from '../../components/text'
import { useActionParam } from '../../hooks'
import { changeLayout } from '../../store/actions'
import { getAction } from '../../services/action/Action'
import { ButtonFloating } from '../../components/buttons'
import { validateStatus, validateArray } from '../../utils/Validation'

const Questions = ({ changeLayout }) => {
  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: '/queries',
  })

  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])

  return (
    <div className="Questions">
      <H2 margin="20px 0" textAlign="center">
        Preguntas Frecuentes
      </H2>
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          dataRequest.map((l, index) => (
            <QuestionContainer
              open={index === 0 ? true : false}
              key={l.id}
              answer={l.answer}
            >
              {l.queryForum}
            </QuestionContainer>
          ))
        ) : (
          <h3>No hay Categorias</h3>
        )
      ) : null}
      <ButtonFloating />
    </div>
  )
}
export default connect(null, { changeLayout })(Questions)

function QuestionContainer({ answer, children, open }) {
  const [view, setview] = useState(open)
  return (
    <div className="QuestionContainer">
      <div onClick={() => setview(!view)} className="QuestionContainer__header">
        <H4>{children}</H4>
        <i className={`fas fa-chevron-${!view ? 'down' : 'up'}`}></i>
      </div>
      {view && <Section>{answer}</Section>}
    </div>
  )
}
