import './InstitutionCategory.css'
import { useEffect, useState } from 'react'
import { useAxiosAuth } from '../../../hooks'
import { H2, H4, H3 } from '../../../components/text'
import { GlobalForm } from '../../../components/form'
import { RenderModal } from '../../../components/modal'
import { TableDefault } from '../../../components/table'
import { UseOnSubmit } from '../../../hooks/useOnSubmit'
import { validateArray } from '../../../utils/Validation'
import { LoadCircle } from '../../../components/animation'
import { InputsCategory } from '../../../utils/FormParameters'
import {
  ActionButton,
  Icon,
  DefaultBtn,
  ButtonIcon,
} from '../../../components/buttons'
const header = [
  { key: 'name', name: 'Nombre' },
  { key: 'description', name: 'Descripción', type: 'textArea' },
]
const InstitutionCategory = (props) => {
  const { changeInfo } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: '/category',
  })
  function handleCategory(category) {
    changeInfo(
      <CategoryAside category={category} cid={category.id} {...props} />,
    )
  }
  return loading ? (
    <LoadCircle />
  ) : (
    <TableDefault
      header={header}
      main={response}
      handleInfo={handleCategory}
      buttonAdd={
        <DefaultBtn
          onClick={() =>
            changeInfo(<FormCategory reload={reload} {...props} />)
          }
        >
          Crear categoría{' '}
          <i style={{ fontSize: '1.4rem' }} className="fas fa-plus"></i>
        </DefaultBtn>
      }
    />
  )
}
const FormCategory = ({ changeInfo, openSnackbar, reload, cid }) => {
  const [load, setload] = useState(true)
  function functionSuccess() {
    changeInfo(<></>)
    reload()
  }
  async function onSubmit(values) {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: 'POST',
        url: '/category',
        data: cid ? { ...values, FatherCategoryId: cid } : values,
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Categoría creada',
      textWrong: 'No se pudo crear',
      functionSuccess: functionSuccess,
    })
    setload(true)
  }
  return (
    <GlobalForm
      InputsJson={InputsCategory}
      title={`Crear ${cid ? 'Subcategoría' : 'categoría'}`}
      textBtn="Crear categoría"
      data={{}}
      onSubmit={onSubmit}
      load={load}
      onCancel={() => changeInfo(<></>)}
    />
  )
}

const CategoryAside = (props) => {
  const { category, cid, changeInfo } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/category/${cid}/children`,
  })
  const [viewModal, setviewModal] = useState(false)
  const {
    response: coursesResp,
    loading: coursesLoad,
    reload: coursesReload,
  } = useAxiosAuth({
    method: 'GET',
    url: `/category/${cid}/programManagers`,
  })

  useEffect(() => {
    reload()
    coursesReload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cid])
  function handleReload() {
    reload()
    changeInfo(<CategoryAside {...props} />)
  }
  return (
    <>
      <Icon ico="comment-alt" handleModal={() => setviewModal(!viewModal)}>
        Lista de cursos
      </Icon>
      <H2 margin="10px 0" textAlign="center">
        {category.name}
      </H2>
      <H4>Subcategorías</H4>
      {loading ? (
        <LoadCircle />
      ) : (
        response.map((category) => (
          <SubCategory key={category.id} category={category} />
        ))
      )}
      <ActionButton
        onClick={() =>
          changeInfo(
            <FormCategory reload={handleReload} cid={cid} {...props} />,
          )
        }
        onCancel={() => changeInfo(<></>)}
        textBtn="Crear subcategoría"
        textCancel="Cancelar"
      />
      {viewModal && (
        <RenderModal
          onClose={() => setviewModal(!viewModal)}
          closeOutside={true}
        >
          <H2 textAlign="center">Cursos asignados a esta categoría</H2>
          {coursesLoad ? (
            <LoadCircle />
          ) : validateArray(coursesResp.programManagers) ? (
            coursesResp.programManagers.map((program) => (
              <H4 key={program.id}>{program.name}</H4>
            ))
          ) : (
            <H3>No tienes cursos</H3>
          )}
        </RenderModal>
      )}
      {/* <SubtitleData subtitle="Enlace">
        <Link to={'/' + category.url}>Ir a {category.name}</Link>
      </SubtitleData> */}
      {/* <SubtitleData subtitle="Descripción">{category.description}</SubtitleData> */}
    </>
  )
}
const SubCategory = ({ category }) => {
  return (
    <div className="SubCategory">
      <H4 margin="8px 0">{category.name}</H4>
      <ButtonIcon content="Eliminar" icon="fas fa-trash-alt" />
      {/* <div className=""></div> */}
      {/* <IconOptions
        noBackground
        content={
          <>
            <ButtonIcon content="Editar" icon="edit" />
            <ButtonIcon content="Eliminar" icon="trash" />
          </>
        }
        icon="ellipsis-v"
      /> */}
    </div>
  )
}

export default InstitutionCategory
