import { BoxFlex, ToolTip } from '../boxes'
import { H3, H4 } from '../text'
import { DefaultBtn, Icon } from '../buttons'
import { changeModal } from '../../store/actions'
import { connect } from 'react-redux'

function generatePage(header) {
  if (header && header.pagination) {
    return JSON.parse(header.pagination)
  }
  return null
}
const TableFooter = ({
  amount,
  pageInfo,
  seturl,
  url,
  reload,
  changeModal,
  showItemsByPage = false,
}) => {
  const header = generatePage(pageInfo)

  function handlePageNumber(number) {
    seturl((a) => ({ ...a, PageNumber: a.PageNumber + number }))
    reload()
  }
  let leftpages = []
  let rigthpages = []
  function updatePages() {
    if (url) {
      for (
        let i = url.PageNumber + 1;
        i <=
        (header.totalPages > url.PageNumber + 2
          ? url.PageNumber + 2
          : header.totalPages);
        i++
      ) {
        rigthpages.push({ index: i })
      }
      for (
        let i = url.PageNumber - 1 > 2 ? url.PageNumber - 3 : 0;
        i < url.PageNumber - 1;
        i++
      ) {
        leftpages.push({ index: i + 1 })
      }
    }
  }
  function changePage(index) {
    seturl((a) => ({ ...a, PageNumber: index }))
    reload()
  }
  updatePages()

  async function handlePageSize(values) {
    seturl((a) => ({ ...a, pageSize: values }))
    reload()
  }

  function ContentPageSize() {
    return (
      <div className="ContentOrder">
        <H3>Cantidad de elementos</H3>

        {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((element) => (
          <H4
            color={url === element.key ? 'var(--green)' : ''}
            onClick={() => handlePageSize(element)}
            key={element.key}
            cursor={'pointer'}
          >
            {element}
          </H4>
        ))}
      </div>
    )
  }
  if (header) {
    return (
      <BoxFlex>
        <H4 margin="0" fontSize="1.4rem">
          Mostrando del {(url.PageNumber - 1) * header.itemsPerPage + 1} -{' '}
          {(url.PageNumber - 1) * header.itemsPerPage + amount} de{' '}
          {header.totalItems}
        </H4>
        {showItemsByPage && (
          <>
            <H4 margin="0" border="auto" fontSize="1.2rem">
              Cantidad de registros por página:
            </H4>
            <ToolTip
              interactive={true}
              trigger="click"
              placement="bottom"
              content={<ContentPageSize />}
            >
              {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].length > 1 ? (
                <div>
                  <DefaultBtn color="var(--green)">{url.pageSize}</DefaultBtn>
                </div>
              ) : null}
            </ToolTip>
          </>
        )}

        <BoxFlex>
          {leftpages[0] ? (
            leftpages[0].index !== 1 ? (
              <Icon
                handleModal={() => changePage(1)}
                disabled={false}
                children={'1'}
              />
            ) : null
          ) : null}
          <Icon
            handleModal={() => handlePageNumber(-1)}
            disabled={header.currentPage === 1 ? true : false}
            ico="chevron-left"
          />
          {leftpages.map((element) => (
            <Icon
              handleModal={() => changePage(element.index)}
              disabled={false}
              key={element.index}
              children={element.index}
            />
          ))}
          <Icon disabled={true} children={url.PageNumber} />
          {rigthpages.map((element) => (
            <Icon
              handleModal={() => changePage(element.index)}
              key={element.index}
              disabled={false}
              children={element.index}
            />
          ))}
          <Icon
            disabled={header.totalPages === url.PageNumber ? true : false}
            handleModal={() => handlePageNumber(1)}
            ico="chevron-right"
          />
          {rigthpages[0] ? (
            rigthpages[rigthpages.length - 1].index !== header.totalPages ? (
              <Icon
                handleModal={() => changePage(header.totalPages)}
                disabled={false}
                children={header.totalPages}
              />
            ) : null
          ) : null}
        </BoxFlex>
      </BoxFlex>
    )
  } else {
    return (
      <H4 margin="0" fontSize="1.4rem">
        Total: {amount}
      </H4>
    )
  }
}

export default connect(null, {
  changeModal,
})(TableFooter)
