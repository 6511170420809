import Modal from '../../components/modal/Modal'
import ModalLog from '../../components/layout/ModalLog'
import img from '../../assets/img/lgoBl.png'
import './style/Auth.css'
import { H2 } from '../../components/text'
import styled from 'styled-components'

const Span = styled.span`
  line-height: 1.6;
  color: var(--white);
  font-size: 1.6rem;
  font-weight: 400;
  padding: 0 3rem;
`
const Auth = (props) => {
  const location = props.location.pathname

  return (
    <div>
      <Modal redirect={props.location.state}>
        <div className="containerLog">
          <div className="containerLogin__img">
            <img src={img} alt="Logo Capacita Global" />
            <H2 color="var(--white)">Bienvenido</H2>
            {location.includes('/auth/test-vocacional') ? (
              <Span>
                Bienvenido a nuestro curso preuniversitario, para tomar tu test
                vocacional y tus cursos regístrate a continuación.
              </Span>
            ) : (
              <ul className="containerLogin__ul">
                <li>Continuar creciendo</li>
                <li>Continuar capacitando</li>
                <li>Continuar Innovando</li>
              </ul>
            )}
          </div>
          <div className="containerLogin__form">
            <ModalLog redirect={props.location.state} />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Auth
