import "./UserInstitution.css";
import { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useAxiosAuth } from "../../../hooks";
import { convertD } from "../../../utils/ConvertDate";
import { TableFilterContainer } from "../../../components/table";
import {
  capitalizeFirstLetter,
  validateArray,
  validateStatus,
} from "../../../utils/Validation";
import { postAction } from "../../../services/action/ActionAuthorization";
import { LoadCircle, Loading } from "../../../components/animation";
import { Icon, DefaultBtn, ButtonIcon } from "../../../components/buttons";
import { H2, H3, H4, SubtitleData } from "../../../components/text";
import { ViewEditUserRols } from "../../../components/form/ViewEditUserRols";
import { gender, rolName } from "../../../utils/Constant";
import { CardRegister } from "../../../view/Manager/Reports";
import { BoxFlex } from "../../../components/boxes";

const UserInstitutionAffiliate = (props) => {
  const { changeInfo, changeModal, openSnackbar } = props;

  const header = [
    {
      key: "programManagerName",
      name: "Nombres del Producto",
      type: "preProcess",
      validate: validName,
    },
    {
      key: "count",
      name: "Numero de veces afiliado al curso",
      type: "preProcess",
      validate: validCode,
    },
    {
      key: "quantitySold",
      name: "Numero de compras de afiliados",
      type: "preProcess",
      validate: validSell,
    },
  ];

  function validCode(data) {
    return (
      <>
        <H3 textAlign="center" fontSize="1.4rem" fontWeight="500">
          {data.count}
        </H3>
      </>
    );
  }

  function validName(data) {
    return (
      <>
        <H3 fontSize="1.4rem" fontWeight="500">
          {data.programManagerName ? `${data.programManagerName || ""}` : "--"}
        </H3>
      </>
    );
  }

  function validSell(data) {
    return (
      <>
        <H3 textAlign="center" fontSize="1.4rem" fontWeight="500">
          {data.quantitySold}
        </H3>
      </>
    );
  }

  return (
    <div className="UserInstitution">
      <TableFilterContainer
        header={header}
        param={`/affiliate`}
        addHeader={[{ key: "programManagerName", name: "Nombre del Producto" }]}
        onClick={(data, reload) => {
          // console.log(data);
          changeInfo(
            <>
              <ViewCourseAfiliate
                data={data}
                changeModal={changeModal}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
              />
            </>
          );
        }}
      />
    </div>
  );
};
export default withRouter(UserInstitutionAffiliate);

function ViewCourseAfiliate({ data, changeModal, changeInfo, openSnackbar }) {
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `/affiliate/${data.programManagerId}`,
  });
  useEffect(() => {
    reload();
  }, [data.programManagerId]);

  const preConfig = (list) => {
    list.forEach((l) => {
      l.codeAffiliate = (
        <>
          <H4 color="var(--purple)">
            {capitalizeFirstLetter(l.codeAffiliate)}
          </H4>{" "}
        </>
      );
      l.url = (
        <>
          <BoxFlex justify="space-around">
            <span>{l.url}</span>
          </BoxFlex>
        </>
      );
      l.sell = (
        <>
          <BoxFlex justify="space-around">
            <span>{l.quantitySold}</span>
          </BoxFlex>
        </>
      );
      l.visited = (
        <>
          <BoxFlex justify="space-around">
            <span>
              <ViewNuberAffliate
                codeAffiliate={l.codeAffiliate}
                changeModal
                changeInfo
                openSnackbar
              />
            </span>
          </BoxFlex>
        </>
      );
    });
    return list;
  };

  const header = [
    {
      key: "codeAffiliate",
      name: "Codigo de Afiliado",
      type: "preProcess",
      validate: validName,
    },
    {
      key: "url",
      name: "Url de afiliado",
      type: "preProcess",
      validate: validCode,
    },
    {
      key: "quantitySold",
      name: "Cantidad de ventas",
      type: "preProcess",
      validate: validSell,
    },
    {
      key: "visited",
      name: "Cantidad de vistas",
      type: "preProcess",
      validate: validVisit,
    },
    {
      key: "creationDate",
      name: "Fecha de Creacion",
      type: "date",
    },
  ];

  function validCode(data) {
    const copyCurrentLink = () => {
      const url = data.url + "?afiliado=" + data.codeAffiliate;
      navigator.clipboard.writeText(`${url}`);
      openSnackbar("Enlace copiado", true, true);
    };
    return (
      <>
        <a href={`${data.url}?afiliado=${data.codeAffiliate}`} target="_blank">
          <H3 textAlign="center" fontSize="1.4rem" fontWeight="500">
            {data.url}?afiliado={data.codeAffiliate}
          </H3>
        </a>

        <ButtonIcon
          onClick={copyCurrentLink}
          content="Copiar enlace"
          icon="copy"
        />
      </>
    );
  }

  function validSell(data) {
    return (
      <>
        <H3 textAlign="center" fontSize="1.4rem" fontWeight="500">
          {data.quantitySold}
        </H3>
      </>
    );
  }

  function validVisit(data) {
    return (
      <>
        <ViewNuberAffliate
          changeModal
          changeInfo
          openSnackbar
          codeAffiliate={data.codeAffiliate}
        />
      </>
    );
  }

  function validName(data) {
    return (
      <>
        <H3 textAlign="center" fontSize="1.4rem" fontWeight="500">
          {data.codeAffiliate}
        </H3>
      </>
    );
  }

  function TableCodes() {
    return (
      <>
        <div className="UserInstitution">
          <TableFilterContainer
            header={header}
            param={`/affiliate/${data.programManagerId}`}
            handleReload={reload}
          />
        </div>
      </>
    );
  }
  return (
    <>
      <TableCodes />
    </>
  );
}

function ViewNuberAffliate({
  codeAffiliate,
  changeModal,
  changeInfo,
  openSnackbar,
}) {
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `/affiliate/view/${codeAffiliate}`,
  });
  useEffect(() => {
    reload();
  }, [codeAffiliate]);

  function NumberAfiliate(data) {
    return (
      <>
        <H3 textAlign="center" fontSize="1.4rem" fontWeight="500">
          {loading ? "--" : response}
        </H3>
      </>
    );
  }

  return (
    <>
      <NumberAfiliate />
    </>
  );
}
