import { useAxiosAuth } from '../../hooks'
import { H3 } from '../../components/text'
import { Loading } from '../../components/animation'
import { BoxFlex, ToolTip } from '../../components/boxes'
import { SimpleSelect } from '../../components/input'
import { validateArray } from '../../utils/Validation'
import { convertD } from '../../utils/ConvertDate'
import styled from 'styled-components'
import { useEffect, useState } from 'react'

const ListTracing = (props) => {
  const { user, pid } = props
  const [valueMonth, setvalueMonth] = useState(new Date().getMonth() + 1)

  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/followUp/Notes?userId=${user.id}&&courseId=${pid}&monthDate=${valueMonth}`,
  })
  useEffect(() => {
    if (!loading) {
      reload()
    }
  }, [valueMonth])

  const dropdownMonth = [
    { key: 'Enero', value: 1 },
    { key: 'Febrero', value: 2 },
    { key: 'Marzo', value: 3 },
    { key: 'Abril', value: 4 },
    { key: 'Mayo', value: 5 },
    { key: 'Junio', value: 6 },
    { key: 'Julio', value: 7 },
    { key: 'Agosto', value: 8 },
    { key: 'Septiembre', value: 9 },
    { key: 'Octubre', value: 10 },
    { key: 'Noviembre', value: 11 },
    { key: 'Diciembre', value: 12 },
  ]
  return (
    <>
      <div style={{ width: 'fit-content' }}>
        <SimpleSelect
          name="Month"
          label="Mes:"
          options={dropdownMonth}
          onChange={(e) => setvalueMonth(e.target.value)}
          value={valueMonth}
        />
      </div>
      {loading ? (
        <Loading />
      ) : validateArray(response.followUp) ? (
        <>
          <ListTracingStyle>
            {response.followUp.map((item, index) => (
              <CardTracing key={index} tracing={item} />
            ))}
          </ListTracingStyle>
        </>
      ) : (
        <H3 fontWeight="500" margin="0 0 10px">
          No se registros de seguimiento
        </H3>
      )}
    </>
  )
}
const CardTracing = ({ tracing }) => {
  function TextTracking({ children, status, content }) {
    return (
      <ToolTip content={content}>
        <div>
          <H3
            height="25px"
            width="25px"
            color="var(--white)"
            background={status ? 'var(--green)' : 'var(--red)'}
            margin="0"
            textAlign="center"
            lineHeight="25px"
            padding="5px 2.5px 0"
            borderRadius="50%"
          >
            {children}
          </H3>
        </div>
      </ToolTip>
    )
  }
  return (
    <div className="CardTracing">
      <H3 color="var(--gray)" margin="0">
        {convertD(tracing.dateCourse, 'LL')}
      </H3>
      <BoxFlex margin="10px 0" direction="row">
        <TextTracking content="Asistencia" status={tracing.assistance}>
          <i className="fas fa-check"></i>
        </TextTracking>
        <TextTracking
          content="Participation"
          status={tracing.participation !== 0}
        >
          <i className="fas fa-spell-check"></i>
        </TextTracking>
        <TextTracking content="Tarea" status={tracing.homework}>
          <i className="fas fa-seedling"></i>
        </TextTracking>
      </BoxFlex>
      {tracing.observation && (
        <H3 color="var(--gray)" fontWeight="500" margin="10px 0 0">
          <strong>Observación:</strong> {tracing.observation}
        </H3>
      )}
      {tracing.subjectEvaluation && (
        <H3 color="var(--gray)" fontWeight="500" margin="10px 0 0">
          <strong>Evaluación:</strong> {tracing.subjectEvaluation}
        </H3>
      )}
    </div>
  )
}

const ListTracingStyle = styled.div`
  width: 900px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  background: var(--white2);
  @media screen and (max-width: 900px) {
    width: auto;
  }
`

export default ListTracing
