import React from "react";
// import "./style/Circle.css";
import AnimateCircle from "./style/AnimateCircle";
const Circle = (props) => {
  return (
    <AnimateCircle
      size={props.size}
      semicircle={props.semicircle}
      time={props.time}
    ></AnimateCircle>
  );
};

export default Circle;
