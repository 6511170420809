import { MyDropzone } from "../input";
import "./style/SubmissionDoc.css";
import { connect } from "react-redux";
import { openSnackbar } from "../../store/actions";
import {
  preFileUpload,
  getPreSignUp,
} from "../../services/action/CourseAction";
import { useState, useEffect } from "react";
import { CompModal } from "../modal";
import { NormalButton } from "../buttons";
const send = {
  ci: {
    key: "ci",
    Description: "Documento de Identidad",
    Type: "identity_card",
  },
  cno: {
    key: "cno",
    Description: "Certificado de Nacimiento Original",
    Type: "birth_certificate",
  },
  da: {
    key: "da",
    Description: "Diploma Academico",
    Type: "academic_diploma",
  },
};
const documents = [
  {
    key: "ci",
    Description: "Documento de Identidad",
    Type: "identity_card",
  },
  {
    key: "cno",
    Description: "Certificado de Nacimiento Original",
    Type: "birth_certificate",
  },
  {
    key: "da",
    Description: "Diploma Academico",
    Type: "academic_diploma",
  },
];
const SubmissionDoc = ({ handleProgress, openSnackbar, eid }) => {
  const [load, setload] = useState({
    ci: true,
    cno: true,
    da: true,
    modal: {
      status: false,
      file: null,
      key: null,
    },
  });
  function save(res, key) {
    setload((a) => ({ ...a, [key]: true }));
    if (res.status === 200) {
      openSnackbar("Se envio el documento", true, true);
      setrequest(null);
    } else {
      openSnackbar("No envio el documento", true, false);
    }
  }
  const [request, setrequest] = useState(null);
  useEffect(() => {
    const uid = window.localStorage.getItem("id");
    if (eid && uid && !request) {
      getPreSignUp(eid)
        .then((r) => {
          setrequest(r.data);
          if (r.data.files.length >= 3) {
            handleProgress(1);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [eid, handleProgress, request]);

  const uploadFiles = async (file, key) => {
    try {
      if (eid) {
        setload((a) => ({ ...a, [key]: false }));
        const formData = new FormData();
        formData.append("File", file);
        formData.append("Description", send[key].Description);
        formData.append("Type", send[key].Type);
        console.log(formData);
        await preFileUpload(eid, formData)
          .then((res) => {
            console.log(res);
            save(res, key);
          })
          .catch((e) => console.log(e));
      } else {
        openSnackbar("Debe llenar el pre-registro primero", true, false);
      }
    } catch (e) {
      setload((a) => ({ ...a, [key]: true }));
      console.log(e);
    }
  };
  const openModal = async (file, key) => {
    await setload((a) => ({
      ...a,
      modal: { ...a.modal, status: true, file: file, key: key },
    }));
  };
  function validateUpload(key) {
    if (request && request.files) {
      const result = request.files.filter((r) => {
        return r.type === send[key].Type;
      });
      if (result.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  function validateDoc(type) {
    if (request && request.files) {
      const result = request.files.filter((r) => {
        return r.type === type;
      });
      if (result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  function onSubmit() {
    uploadFiles(load.modal.file, load.modal.key);
    setload((a) => ({
      ...a,
      modal: { ...a.modal, status: false },
    }));
  }
  return (
    <>
      <div className="SubmissionDoc">
        <p>
          *La presentación de documentos se podrá completar hasta que finalice
          el diplomado
        </p>
        <h2>Formulario de presentación de documentos</h2>
        {request
          ? documents.map((d, i) => (
              <div key={d.key}>
                <h3>{d.Description}</h3>
                {validateDoc(d.Type) ? (
                  <div className="SubmissionDoc__doc">
                    Documentación presentada:
                    <a
                      href={validateDoc(d.Type).url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{d.Description}</span>
                      <i className="fas fa-link"></i>
                    </a>
                  </div>
                ) : (
                  <div className="SubmissionDoc__doc">
                    Aún no has subido nada
                  </div>
                )}
                <MyDropzone
                  load={load[d.key]}
                  uploadFiles={validateUpload(d.key) ? uploadFiles : openModal}
                  name={d.key}
                />
              </div>
            ))
          : null}
      </div>
      {load.modal.status && (
        <CompModal>
          <div className="SubmissionDoc__modal">
            <h3>Seguro quieres reemplazar el documento que ya subiste.</h3>
            <div className="SubmissionDoc__Mbtns">
              <NormalButton
                background={true}
                onClick={() =>
                  setload((a) => ({
                    ...a,
                    modal: { ...a.modal, status: false },
                  }))
                }
              >
                Cancelar
              </NormalButton>
              <NormalButton onClick={() => onSubmit()}>Continuar</NormalButton>
            </div>
          </div>
        </CompModal>
      )}
    </>
  );
};

export default connect(null, { openSnackbar })(SubmissionDoc);
