import { useState } from 'react'
import { useAxiosAuth } from '../../../hooks'
import {
  postAction,
  putAction,
} from '../../../services/action/ActionAuthorization'
import { Loading } from '../../../components/animation'
import { TableDefault } from '../../../components/table'
import { DefaultBtn, Icon, ActionButton } from '../../../components/buttons'
import { H2, H3, SubtitleData } from '../../../components/text'
import { GlobalForm } from '../../../components/form'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { InputsAddInstitution } from '../../../utils/FormParameters'
import { capitalizeJson } from '../../../utils/ProcessData'
import FormAddUser from './FormAddUser'
const InstitutionsContainer = (props) => {
  const { changeModal } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: '/institution',
  })
  const buttonAddInstitution = (
    <DefaultBtn
      onClick={() =>
        changeModal(
          true,
          '',
          <FormInstitution
            {...props}
            onClose={() => changeModal(false, null)}
            handleReload={reload}
          />,
          null,
        )
      }
    >
      Adicionar institución{' '}
      <i style={{ fontSize: '1.4rem' }} className="fas fa-plus"></i>
    </DefaultBtn>
  )
  if (loading) {
    return <Loading />
  } else {
    return (
      <TableDefault
        main={response}
        header={[
          { key: 'name', name: 'Nombres' },
          { key: 'email', name: 'Correo electronico' },
        ]}
        buttonAdd={buttonAddInstitution}
        handleReload={reload}
        handleInfo={(institution) =>
          changeModal(
            true,
            '',
            <ViewInstitution
              {...props}
              handleReload={reload}
              institution={institution}
            />,
            null,
          )
        }
      />
    )
  }
}

const ViewInstitution = (props) => {
  const { institution, changeModal } = props
  return (
    <>
      <Icon
        handleModal={() =>
          changeModal(
            true,
            '',
            <InstitutionContacts
              {...props}
              institution={institution}
              onClose={() =>
                changeModal(true, '', <ViewInstitution {...props} />, null)
              }
            />,
            null,
          )
        }
      >
        Contacto de institución
      </Icon>
      <br />
      <br />
      <H2 textAlign="center">Detalles de la institución</H2>
      <SubtitleData subtitle="Nombres">{institution.name}</SubtitleData>
      <SubtitleData subtitle="Correo electronico">
        {institution.email}
      </SubtitleData>
      <ActionButton
        onClick={() =>
          changeModal(
            true,
            '',
            <FormInstitution
              {...props}
              institution={capitalizeJson(institution)}
              onClose={() =>
                changeModal(true, '', <ViewInstitution {...props} />, null)
              }
            />,
            null,
          )
        }
        onCancel={() => changeModal(false, null)}
        textBtn="Editar institución"
      />
    </>
  )
}

const FormInstitution = (props) => {
  const { openSnackbar, handleReload, institution, onClose } = props
  const [load, setload] = useState(true)
  const onSubmit = async (values, action) => {
    try {
      setload(false)
      await action(
        '/institution',
        institution ? { ...values, Id: institution.Id } : values,
      ).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Se guardo la institución', true, true)
          handleReload()
          onClose()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }

  return (
    <GlobalForm
      InputsJson={InputsAddInstitution}
      textBtn={institution ? 'Editar' : 'Adicionar'}
      title={institution ? 'Editar institución' : 'Adicionar institución'}
      data={institution ? institution : {}}
      onSubmit={(values) =>
        onSubmit(values, institution ? putAction : postAction)
      }
      load={load}
      onCancel={onClose}
    />
  )
}

const InstitutionContacts = (props) => {
  const { onClose, changeModal, institution } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/institution/${institution.id}/contacts`,
  })
  function handleAddCourse() {
    changeModal(true, '', <FormAddUser reload={reload} {...props} />)
  }
  return (
    <>
      <H2 textAlign="center">Contactos de la institución</H2>
      {loading ? (
        <Loading />
      ) : validateArray(response.contacts) ? (
        response.contacts.map((contact) => (
          <div className="CoursesInterested__card">
            <H3 fontWeight="500" color="black" margin="10px 5px">
              {contact.name} {contact.lastname}
            </H3>
          </div>
        ))
      ) : (
        <H3>No tienes un contacto</H3>
      )}
      <ActionButton
        onClick={handleAddCourse}
        onCancel={onClose}
        textBtn="Adicionar contacto"
      />
    </>
  )
}

export default InstitutionsContainer
