import { useState } from 'react'

import './style/NewUserProgram.css'
import {
  diffElements,
  validateArray,
  validateParam,
  validateStatus,
} from '../../utils/Validation'
import { convertD, convertDate } from '../../utils/ConvertDate'
import {
  deleteAction,
  putAction,
} from '../../services/action/ActionAuthorization'

import { GlobalForm } from '.'
import { inputUpdateUser } from '../../utils/FormParameters'
import { capitalizeJson, jsonToArray } from '../../utils/ProcessData'

const ViewEditUserRols = (props) => {
  const {
    user,
    onClose,
    edit,
    handleReload,
    setedit,
    openSnackbar,
    reload,
    iid,
  } = props

  const [load, setload] = useState(true)
  const initialValues = {
    LastName: validateParam('lastName', user),
    Name: validateParam('firstName', user),
    Ci: validateParam('ci', user),
    ExpeditionCi: validateParam('expeditionCi', user),
    Email: validateParam('email', user),
    NationalNumber: validateParam('telephone', user),
    DateOfBirth:
      user && user.dateOfBirth
        ? convertD(user.dateOfBirth, 'utc')
        : null || null,
    Gender: validateParam('gender', user),
  }

  function save(res) {
    setload(true)
    if (res.status === 200) {
      openSnackbar('Guardado Exitosamente', true, true)
      reload()
      handleReload()
    } else {
      openSnackbar(res.data.title ? res.data.title : res.data, true, false)
    }
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      await putAction(`/users/${user.id}/UpdateRegister`, {
        ...values,
        DateOfBirth: values.DateOfBirth
          ? convertDate(values.DateOfBirth)
          : values.DateOfBirth,
      }).then((response) => {
        save(response)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
    setedit(!edit)
    //resendUser();
  }
  function onValidate(values) {
    const { Rol, ...rest } = values

    onSubmit(rest)

    const convertCurrentRols = []
    user.rols.map((rol) => {
      return convertCurrentRols.push(rol.id.toString())
    })

    sendRols(convertCurrentRols, Rol)
  }
  const sendRols = async (currentRoles, selectedRoles) => {
    const diffRols = diffElements(currentRoles, selectedRoles)

    let addRoles = diffRols.filter((x) => !currentRoles.includes(x))

    let removeRoles = diffRols.filter((x) => !selectedRoles.includes(x))

    if (validateArray(addRoles)) {
      await Promise.all(
        addRoles.map(async (element) => {
          await putAction(
            `/UserInstitution/${user.id}/institution/${iid}/assignrol/${element}`,
          ).then((res) => {
            if (!validateStatus(res.status)) {
              openSnackbar('Hubo un error al añadir rol', true, false)
            }
          })
        }),
      )
    }
    if (validateArray(removeRoles)) {
      await Promise.all(
        removeRoles.map(async (element) => {
          await deleteAction(
            `/UserInstitution/${user.id}/institution/${iid}/rol/${element}`,
          ).then((res) => {
            if (!validateStatus(res.status)) {
              openSnackbar('Hubo un error al quitar rol', true, false)
            }
          })
        }),
      )
    }
    setload(true)
  }
  return (
    <div className="NewUserProgram">
      <GlobalForm
        load={load}
        title="Editar usuario"
        textBtn="Guardar"
        InputsJson={inputUpdateUser}
        data={capitalizeJson({ ...initialValues, Rol: jsonToArray(user.rols) })}
        onSubmit={onValidate}
        onCancel={onClose}
      />
    </div>
  )
}

export { ViewEditUserRols }
