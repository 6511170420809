import React from "react";
import { TableFilterContainer } from "../../components/table";
const color = {
  pending: "#c93f3f75",
  completed: "#46958575",
  delisted: "#80808075",
};
const textTranslate = {
  pending: "Pendiente",
  completed: "Completo",
  delisted: "Excluido",
};

const TablePreregistration = () => {
  return (
    <div>
      <TableFilterContainer
        param={`/order/list`}
        // choice={true}
        header={[
          { key: "programName", name: "Nombre del curso" },
          {
            key: "status",
            name: "Estado",
            type: "preProcess",
            validate: (head, key) => {
              return (
                <div className="TableDefault__textColor">
                  <h4
                    style={{
                      background: `${color[head[key]]}`,
                      color: color[head[key]] ? "" : "var(--black)",
                    }}
                  >
                    {textTranslate[head[key]]}
                  </h4>
                </div>
              );
            },
          },
          {
            key: "date",
            name: "Detalles",
            type: "preProcess",
            validate: (head) => {
              return (
                <a
                  href={`/order/${head.orderId}/oea`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ver detalles
                </a>
              );
            },
          },
          // { key: "date", name: "Fecha", type: "date" },
        ]}
        // onClick={handleSelect}
        // boxFilters={[
        //   // {
        //   //   type: 'select',
        //   //   label: 'Ordenar por:',
        //   //   name: 'OrderBy',
        //   //   options: [
        //   //     { label: 'Nombre', name: '' },
        //   //     { label: 'Fecha de creación', name: 'created' },
        //   //   ],
        //   // },
        //   {
        //     type: "getSelect",
        //     label: "Categoría:",
        //     name: "CategoryId",
        //     url: "/category",
        //     urlKey: "id",
        //     urlLabel: "name",
        //     initial: "Todas las categorías",
        //   },
        // ]}
      />
    </div>
  );
};

export default TablePreregistration;
