import { FormDefault } from '../../../components/form'
import * as Yup from 'yup'
const PreRegisterChildren = (props) => {
  const { onCancel, course, openSnackbar, user, tutor, child } = props
  function processData(values) {
    return {
      ContactId: user.contactId,
      TutorId: tutor.id,
      UserId: child.id,
      ContactInterestId: course.contactInterestId,
      SignedList: course.programManagerList.map((program) => ({
        ProgramManagerId: program.programManagerId,
        SignedId: values[program.programManagerId],
      })),
    }
  }

  return (
    <div>
      <FormDefault
        inputs={course.programManagerList.map((program) => ({
          control: 'getSelect',
          initial: '',
          validate: Yup.string().required('Campo requerido'),
          label: program.name,
          name: program.programManagerId,
          url: `/programManager/${program.programManagerId}/parallels`,
          keyOption: 'versionName',
          value: 'id',
        }))}
        onCancel={onCancel}
        method="POST"
        url="/preregisterquiz/child"
        openSnackbar={openSnackbar}
        title="Asignar paralelos"
        handleReload={onCancel}
        data={{}}
        processData={processData}
      />
    </div>
  )
}

export default PreRegisterChildren
