import { useAxiosAuth } from '../../../hooks'
import { ActionButton, ButtonIcon } from '../../../components/buttons'
import { IconOptions } from '../../../components/buttonsIcon'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { convertD, convertDate } from '../../../utils/ConvertDate'
import { InputsFollowUp } from '../../../utils/FormParameters'
import { capitalizeJson } from '../../../utils/ProcessData'
import {
  deleteAction,
  postAction,
  putAction,
} from '../../../services/action/ActionAuthorization'
import { Loading } from '../../../components/animation'
import { H2, H3, H4 } from '../../../components/text'
import { GlobalForm } from '../../../components/form'
import { BoxFlex } from '../../../components/boxes'
import FormNewFollow from './FormNewFollow'
import { useState } from 'react'

const FollowUp = (props) => {
  const { user } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/contact/${user.contactId}/followUps`,
  })
  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        <H2 textAlign="center">Lista de notas de seguimiento</H2>
        <BoxFlex alignItems="center">
          <FormNewFollow {...props} user={user} reload={reload} />
          <div style={{ width: '50%' }}>
            {validateArray(response) ? (
              response.map((notes) => (
                <CardFollowUp
                  {...props}
                  user={user}
                  notes={notes}
                  key={notes.id}
                />
              ))
            ) : (
              <H3>No tienes notas</H3>
            )}
          </div>
        </BoxFlex>

        {/* <ActionButton
          onClick={() =>
            changeModal(
              true,
              '',
              <FormFollowUp
                {...props}
                user={user}
                handleReload={() =>
                  changeModal(true, '', <FollowUp {...props} />, null)
                }
              />,
              null,
            )
          }
          onCancel={onCancel}
          textBtn="Crear nota"
        /> */}
      </>
    )
  }
}

const CardFollowUp = (props) => {
  const { notes, changeModal, openSnackbar } = props

  async function onDelete() {
    try {
      await deleteAction(`/followUp/${notes.id}`).then((response) => {
        if (validateStatus(response.status)) {
          changeModal(true, '', <FollowUp {...props} />, null)
          openSnackbar('Se elimino con exito', true, true)
        } else {
          openSnackbar('No se pudo eliminar', true, false)
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  function ShoIconType({ IconName }) {
    switch (IconName) {
      case 'Whatsapp':
        return <i className="fab fa-whatsapp"></i>
      case 'Phone':
        return <i className="fas fa-phone"></i>
      case 'Email':
        return <i className="fas fa-envelope"></i>

      default:
        return <i className="fas fa-id-badge"></i>
    }
  }
  return (
    <div className="CardFollowUp">
      <div
        style={{
          background: 'var(--white)',
          marginBottom: '20px',
          borderRadius: '5px',
          border: '1px solid var(--gray)',
          boxShadow: '0 0 3px rgba(0,0,0,0.2)',
          padding: '10px',
          marginLeft: '0px',
        }}
        className="CardFollowUp__card"
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <div>
            {notes.type === 'followup' ? (
              <BoxFlex justify="start">
                <BoxFlex justify="space-between">
                  <BoxFlex justify="start">
                    <img
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '5px',
                      }}
                      src={notes.creationUserPhotoUrl}
                      alt="notes"
                    />
                    <BoxFlex direction="column">
                      <span
                        style={{
                          color: '#8F8B8B',
                          fontWeight: 'bold',
                        }}
                      >
                        {notes.creationUserName} {notes.creationUserLastName}
                      </span>
                      {/* <span>{convertDate(notes.creationDate, 'LL')}</span> */}
                    </BoxFlex>

                    <IconOptions
                      content={
                        <>
                          <ButtonIcon
                            content="Editar"
                            icon="edit"
                            onClick={() =>
                              changeModal(
                                true,
                                '',
                                <FormFollowUp
                                  {...props}
                                  followUp={capitalizeJson(notes)}
                                  handleReload={() =>
                                    changeModal(
                                      true,
                                      '',
                                      <FollowUp {...props} />,
                                      null,
                                    )
                                  }
                                />,
                                null,
                              )
                            }
                          />
                          <ButtonIcon
                            content="Eliminar"
                            icon="trash"
                            onClick={() =>
                              changeModal(
                                true,
                                '¿Estás seguro que quieres eliminar la nota?',
                                <ActionButton
                                  onCancel={() =>
                                    changeModal(
                                      true,
                                      '',
                                      <FollowUp {...props} />,
                                      null,
                                    )
                                  }
                                  onClick={onDelete}
                                  textBtn="Eliminar"
                                />,
                                null,
                              )
                            }
                          />
                        </>
                      }
                      noBackground={true}
                      icon="ellipsis-v"
                    />
                  </BoxFlex>
                  <H4 margin="0">
                    <span
                      style={{
                        color: '#8F8B8B',
                        fontWeight: 'bold',
                        fontSize: '10px',
                      }}
                    >
                      {/* convertDate(notes.followDate, 'LL') */}
                      {convertDate(notes.creationDate, 'LL')}
                    </span>
                  </H4>
                </BoxFlex>

                <H4 margin="0">{notes.description}</H4>
                {notes.followDate && (
                  <BoxFlex justify="start">
                    <H4 margin="0" fontSize="1.3rem" border="2">
                      <strong>Fecha:</strong>{' '}
                      {convertDate(notes.followDate, 'LL')}
                    </H4>
                    <H4 margin="0" fontSize="1.3rem" border="2">
                      <strong>Hora:</strong>{' '}
                      {convertD(notes.followDate, 'hh:mm a')}
                    </H4>
                    <ShoIconType IconName={notes.contactType} />

                    {/* <i className="fas fa-phone"></i>
<i className="fas fa-envelope"></i> */}
                  </BoxFlex>
                )}
              </BoxFlex>
            ) : (
              <BoxFlex justify="space-between">
                <H2 fontSize="1.2rem" margin="0" border="2">
                  Envió de material exitoso
                </H2>

                <H4 margin="0" border="2">
                  <span
                    style={{
                      color: '#8F8B8B',
                      fontWeight: 'bold',
                      fontSize: '10px',
                    }}
                  >
                    {/* convertDate(notes.followDate, 'LL') */}
                    {convertDate(notes.creationDate, 'LL')}
                  </span>
                </H4>

                <IconOptions
                  content={
                    <>
                      <ButtonIcon
                        content="Eliminar"
                        icon="trash"
                        onClick={() =>
                          changeModal(
                            true,
                            '¿Estás seguro que quieres eliminar la nota?',
                            <ActionButton
                              onCancel={() =>
                                changeModal(
                                  true,
                                  '',
                                  <FollowUp {...props} />,
                                  null,
                                )
                              }
                              onClick={onDelete}
                              textBtn="Eliminar"
                            />,
                            null,
                          )
                        }
                      />
                    </>
                  }
                  noBackground={true}
                  icon="ellipsis-v"
                />
              </BoxFlex>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const FormFollowUp = (props) => {
  const { openSnackbar, handleReload, followUp, user } = props
  const [load, setload] = useState(true)
  const onSubmit = async (values, action) => {
    try {
      setload(false)
      await action(
        followUp
          ? `/followUp/${followUp.Id}`
          : `/contact/${user.contactId}/followup`,
        values,
      ).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Se guardo la nota', true, true)
          handleReload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }

  return (
    <GlobalForm
      InputsJson={InputsFollowUp}
      textBtn={followUp ? 'Editar' : 'Crear'}
      title={followUp ? 'Editar nota' : null}
      data={followUp ? followUp : {}}
      onSubmit={(values) => onSubmit(values, followUp ? putAction : postAction)}
      load={load}
      onCancel={handleReload}
    />
  )
}
export default FollowUp
