import { SimpleInput } from '../../input'
import { DefaultBtn } from '../../buttons'
import { RenderImage } from './QuestionForm'
const SingleChoice = (props) => {
  const {
    index,
    handleChange,
    question,
    handleResponse,
    remove,
    response,
    handleImg,
    onClose,
    handleImgChange,
  } = props
  function handleText() {
    handleImgChange(null, index, 'respImg', false)
  }
  function handleResp(values) {
    handleImgChange(values.ImageFile, index, 'respImg', true)
    onClose()
  }
  return (
    <div className="QuestionForm__response">
      <input
        type="radio"
        id={`radio${index}`}
        name="correctId"
        onChange={(event) => handleChange(event, index)}
        checked={index === question.correctId[0]}
      />
      {question.respImg[index].isImage ? (
        <RenderImage file={question.respImg[index].file} />
      ) : (
        <SimpleInput
          name={index}
          type="text"
          label="Respuesta"
          onChange={handleResponse}
          value={response}
          err={response === '' ? 'Campo requerido' : ''}
        />
      )}
      <DefaultBtn
        width="100%"
        type="button"
        background={true}
        onClick={
          question.respImg[index].isImage
            ? handleText
            : () => handleImg(handleResp)
        }
      >
        <i
          className={
            question.respImg[index].isImage ? 'fas fa-heading' : 'far fa-image'
          }
        ></i>
      </DefaultBtn>
      {index > 0 && (
        <button type="button" onClick={() => remove(index)}>
          <i className="fas fa-times"></i>
        </button>
      )}
    </div>
  )
}

export default SingleChoice
