import { useAxiosAuth } from "../../../hooks";
import { UseOnSubmit } from "../../../hooks/useOnSubmit";
import { LoadCircle } from "../../../components/animation";
import { TableOptions } from "../../../components/table";
import { H2, SubtitleData, H4 } from "../../../components/text";
import { ActionButton } from "../../../components/buttons";
import { GlobalForm } from "../../../components/form";
import { convertD } from "../../../utils/ConvertDate";
import { useEffect, useState } from "react";
import { ButtonIconDelete } from "../../../components/buttons";
import { FormDefault } from "../../../components/form";
import * as Yup from "yup";

const header = [
  { key: "name", name: "Nombre" },
  { key: "lastName", name: "Apellidos" },
  {
    key: "status",
    name: "Estado",
    type: "textColor",
    color: {
      pending: "#c93f3f75",
      completed: "#46958575",
    },
  },
  // { key: 'totalAmount', name: 'Precio total' },
  { key: "creationDate", name: "Fecha del pedido", type: "date" },
];
const listStatus = [
  { key: "", name: "Todos" },
  { key: "cancelled", name: "Cancelado" },
  { key: "completed", name: "Completado" },
  { key: "pending", name: "Pendiente" },
];
const OrdersInstitution = (props) => {
  const { changeInfo, changeModal } = props;
  const [reload, setreload] = useState(false);
  function handleReload() {
    setreload(!reload);
    changeInfo(<></>);
  }
  function handleOrder(user) {
    changeInfo(
      <OrderAside
        user={user}
        handleReload={handleReload}
        {...props}
        changeModal={changeModal}
      />
    );
  }
  const listOrder = [
    { key: "", name: "Fecha de Pedido" },
    { key: "amount", name: "Precio de Pedidos" },
    { key: "lastName", name: "Apellidos" },
  ];
  return (
    <>
      <TableOptions
        param={"/order"}
        header={header}
        listOrder={listOrder}
        choice={true}
        handleReload={reload}
        handleInfo={handleOrder}
        listStatus={listStatus}
      />
    </>
  );
};
const OrderAside = (props) => {
  const { user, changeInfo, changeModal, openSnackbar } = props;
  const { loading, response, reload } = useAxiosAuth({
    method: "GET",
    url: `/order/${user.orderId}/detailed`,
  });
  const listStatus = {
    pending: "Pendiente",
    completed: "Completado",
    delisted: "Excluido",
  };
  function handleVoucher() {
    console.log(openSnackbar);
    changeModal(
      true,
      <div className="UserInstitution">
        <FormDefault
          inputs={[
            {
              name: "File",
              control: "file",
              initial: null,
              validate: Yup.string().required("Campo requerido").nullable(),
              label: "Selecciona el comprobante",
              // type: 'image/*',
            },
          ]}
          onCancel={() => changeModal(false, <></>)}
          method="POST"
          url="/order/voucher"
          openSnackbar={openSnackbar}
          handleReload={() => {
            reload();
            changeModal(false, <></>);
          }}
          data={{}}
          processData={(values) => {
            let formData = new FormData();
            formData.append("OrderId", response.orderId);
            formData.append("Voucher", values.File);
            return formData;
          }}
        />
      </div>,
      () => {},
      null
    );
  }

  useEffect(() => {
    reload();
  }, [user]);

  return loading ? (
    <LoadCircle />
  ) : (
    <>
      <H2 textAlign="center">Detalles del pedido</H2>
      <SubtitleData subtitle="Nombres">{response.name}</SubtitleData>
      <SubtitleData subtitle="Apellidos">{response.lastName}</SubtitleData>
      <SubtitleData subtitle="Correo">{response.email}</SubtitleData>
      <SubtitleData subtitle="Fecha del pedido">
        {convertD(response.creationDate, "LLLL")}
      </SubtitleData>
      <SubtitleData subtitle="Estado del pedido">
        {listStatus[response.status]}
      </SubtitleData>
      <SubtitleData subtitle="Celular">{response.telephone}</SubtitleData>
      <SubtitleData subtitle="Precio total">
        {response.totalAmount} {response.currency}
      </SubtitleData>
      <SubtitleData subtitle="Comprobante de pago"> </SubtitleData>
      <H4
        onClick={handleVoucher}
        textAlign="center"
        color="var(--orange2)"
        cursor="pointer"
      >
        Subir/Editar comprobante
      </H4>
      <img style={{ width: "90%" }} src={response.voucher} alt="" />
      {response.type === "suscription" ? (
        <H2 color="var(--green)" textAlign="center">
          Plan de cursos cortos
        </H2>
      ) : (
        <>
          <H2>Cursos del pedido</H2>
          {response.paymentPlanProgramManagers.map((program) => (
            <div
              style={{
                padding: "10px",
                border: "1px solid var(--green)",
                marginBottom: "5px",
                borderRadius: "5px",
              }}
            >
              <H4 color="var(--green)" textAlign="left" margin="0">
                {program.name}
                <ButtonIconDelete
                  icon={"trash-alt"}
                  idprogram={program.programManagerId}
                  idorder={response.orderId}
                  reloadPedido={reload}
                  changeModal={changeModal}
                  {...props}
                />
              </H4>
            </div>
          ))}
        </>
      )}

      <ActionButton
        onClick={() =>
          changeInfo(<EditOrder dataOrder={response} {...props} />)
        }
        onCancel={() => changeInfo(<></>)}
        textBtn="Editar estado"
      />
    </>
  );
};
const EditOrder = (props) => {
  const { dataOrder, changeInfo, handleReload, openSnackbar } = props;
  const [load, setload] = useState(true);
  async function onSubmit(values) {
    setload(false);
    await UseOnSubmit({
      axiosParams: {
        method: "PUT",
        url: "/order/status",
        data: {
          OrderId: dataOrder.orderId,
          Status: values.Status,
        },
      },
      openSnackbar: openSnackbar,
      textSuccess: "Se modifico el estado",
      textWrong: "No se pudo guardar",
      functionSuccess: handleReload,
    });
    setload(true);
  }
  return (
    <GlobalForm
      InputsJson={[
        {
          name: "Status",
          initial: "pending",
          validate: null,
          control: "select",
          label: "Estado del pedido",
          options: [
            { key: "Pendiente", value: "pending" },
            { key: "Completar", value: "completed" },
            { key: "Excluir", value: "delisted" },
          ],
        },
      ]}
      textBtn="Guardar cambios"
      title="Editar pedido"
      data={{ Status: dataOrder.status }}
      load={load}
      onSubmit={onSubmit}
      onCancel={() => changeInfo(<></>)}
    />
  );
};
export default OrdersInstitution;
