import { convertHour } from '../../utils/ConvertDate'
import { useEffect } from 'react'
const Timer = ({ time, settime, onSaving, qid, qidDto }) => {
  useEffect(() => {
    let interval
    if (time === '00:00:00') {
      onSaving()
    } else {
      interval = setTimeout(function () {
        settime(convertHour(time))
      }, 1000)
    }
    return function cleanup() {
      clearInterval(interval)
      window.localStorage.setItem(
        `countdown${window.localStorage.getItem(
          'id',
        )}QuizId${qid}QuizDto${qidDto}`,
        JSON.stringify({ id: qidDto, time: time }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, qidDto, qid])
  return <h4>{time}</h4>
}

export default Timer
