import './FormNewFollow.css'
import { H4 } from '../../../components/text'
import { FormikControl } from '../../../components/input'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { NormalButton } from '../../../components/buttons'
import { Loading } from '../../../components/animation'
import {
  postAction,
  putAction,
} from '../../../services/action/ActionAuthorization'
import { validateStatus } from '../../../utils/Validation'
import { BoxFlex } from '../../../components/boxes'

const FormNewFollow = (props) => {
  const { openSnackbar, user, reload } = props
  const [load] = useState(true)
  const initialValues = {
    description: '',
    contactMedium: '',
    type: false,
    date: '',
  }
  const initialValuesCM = {
    interestLevel: '',
  }
  const validationSchema = Yup.object({
    contactMedium: Yup.string(),
    type: Yup.boolean(),
    date: Yup.string(),
    description: Yup.string().when('type', {
      is: false,
      then: Yup.string().required('Campo requerido.'),
    }),
  })
  const onSubmit = async (values, { resetForm }) => {
    await postAction(`/contact/${user.contactId}/followup`, {
      Description: values.description,
      ContactType: values.contactMedium,
      Type: values.type === true ? 'material' : 'followup',
      FollowDate: values.date,
    }).then((res) => {
      if (validateStatus(res.status)) {
        reload()
        openSnackbar('Nota de seguimiento creada', true, true)
        resetForm()
      } else {
        openSnackbar(
          'Hubo un error al crear la nota de seguimiento',
          true,
          false,
        )
      }
    })
  }
  const onSubmitColor = async (values) => {
    await putAction(`/contact/priorityModificated`, {
      contactId: user.contactId,
      priority: values.interestLevel,
    }).then((res) => {
      if (validateStatus(res.status)) {
        openSnackbar('Prioridad editada con exito', true, true)
      } else {
        openSnackbar('Hubo un error al editar la prioridad', true, false)
      }
    })
  }
  return (
    <div className="FormNewFollow">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ resetForm }) => {
          return (
            <Form>
              <div className="FormNewFollow__card">
                <H4 margin="0">Nueva nota</H4>
                <FormikControl
                  control="textarea"
                  label="Descripción"
                  name="description"
                />
                <BoxFlex direction="column">
                  <FormikControl
                    control="input"
                    type="date"
                    label="Fecha "
                    name="date"
                  />
                  <div className="FormNewFollow__card_information_medium">
                    <FormikControl
                      control="radio"
                      label="Medio de contacto"
                      name="contactMedium"
                      options={[
                        { key: 'No aplica:', value: '' },
                        {
                          key: <i className="fab fa-whatsapp"></i>,
                          value: 'Whatsapp',
                        },
                        {
                          key: <i className="fas fa-phone"></i>,
                          value: 'Phone',
                        },
                        {
                          key: <i className="fas fa-envelope"></i>,
                          value: 'Email',
                        },
                      ]}
                    />
                  </div>
                </BoxFlex>
              </div>
              <div className="FormNewFollow__card sendMaterial">
                <FormikControl
                  control="input"
                  type="checkbox"
                  label="Material enviado"
                  name="type"
                />
              </div>
              {load ? (
                <BoxFlex>
                  <NormalButton type="submit">Guardar nota</NormalButton>
                </BoxFlex>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>

      <Formik
        initialValues={initialValuesCM}
        /* validationSchema={validationSchema} */
        onSubmit={onSubmitColor}
      >
        {() => {
          return (
            <Form>
              <div className="FormNewFollow__card interes_level">
                <FormikControl
                  control="radio"
                  label="Nivel de interés"
                  name="interestLevel"
                  options={[
                    { key: 'Verde: Listo para pagar', value: '1-verde' },
                    {
                      key: 'Amarrillo: Pendiente de seguimiento',
                      value: '2-amarillo',
                    },
                    { key: 'Rojo: No esta interesado', value: '3-rojo' },
                    { key: 'Ninguno', value: '' },
                  ]}
                />
              </div>
              {load ? (
                <BoxFlex>
                  <NormalButton type="submit">
                    Guardar nivel de interes
                  </NormalButton>
                </BoxFlex>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default FormNewFollow
