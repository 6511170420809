import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FooterComponent } from '../Home/Home'
import { changeLayout, openSnackbar } from "../../store/actions";
import './Advancedtechnician.css';
import { Link } from 'react-router-dom'
import { DefaultBtn } from "../../components/buttons";
import DefaultRenderModal from "../../components/modal/DefaultRenderModal";
import DefaultPreregisterModal from "../../components/modal/DefaultPreregisterModal";

const GeneralAccounting = (props) => {
  const [dImageOne, setDImageOne] = useState(true)
  const [dImageTwo, setDImageTwo] = useState(false)
  const [dImageThree, setDImageThree] = useState(false)
  const [modal, setmodal] = useState(false)

  const { changeLayout } = props;
  useEffect(() => {
    changeLayout("ContentOnly");
  }, [changeLayout]);

  const handleButton = (num) => {
    if (num === 1) {
      setDImageOne(!dImageOne)
      setDImageTwo(false)
      setDImageThree(false)
    }
    if (num === 2) {
      setDImageOne(false)
      setDImageTwo(!dImageTwo)
      setDImageThree(false)
    }
    if (num === 3) {
      setDImageOne(false)
      setDImageTwo(false)
      setDImageThree(!dImageThree)
    }
  }

  return (
    <div className="">
      <div id="Accounting" className="tecnique__head">
        {modal && (
          <DefaultRenderModal
            onClose={() => setmodal(false)}
            width="500px"
            maxWidht="100%"
            closeOutside={true}
            background="linear-gradient(135deg,rgba(92, 214, 189, 1) 0%, var(--purple) 100%)"
          >
            <DefaultPreregisterModal
              openSnackbar={openSnackbar}
              onClose={() => setmodal(false)}
              interest="Contaduría General"
            />
          </DefaultRenderModal>
        )}
        <div className="tecnique_section" style={{ backgroundImage: "url('https://res.cloudinary.com/ahsoizdev/image/upload/v1675714848/imagesCapacita/head_r9vons.png')" }}>
          <div className="main">
            <h1 style={{ color: "#469585" }}>CONTADURÍA GENERAL</h1>
            <h3>LA CARRERA CON MAYOR DEMANDA LABORAL EN EL MERCADO</h3>
            <div className="ShortCourses__Hbtns">
              <DefaultBtn onClick={() => setmodal(!modal)}>
                Regístrate gratis
              </DefaultBtn>
            </div>
          </div>
        </div>
      </div>

      <div className="tecnique__head">
        <div className="main_section">
          <div className="content-section">
            <div className="content-description">
              <span>TECNICO SUPERIOR</span>
              <h3>CONTADURÍA GENERAL</h3>
              <p>El Contador General es un técnico de nivel superior capacitado
                para ejecutar los procesos contables, de remuneraciones y tributarios
                de distintas organizaciones de acuerdo con la normativa vigente, además
                de apoyar en la administración y gestión de organizaciones en un contexto
                económico con visión de país que participa de una economía global.</p>
            </div>
          </div>
          <div className="img-main-section">
            <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675714840/imagesCapacita/contabilidad_general_kwagqy.png" alt="imagen" />
          </div>
        </div>
      </div>

      <div className="tecnique__head">
        <div className="list-items">
          <div className="item side-item">
            <div className="img-item side">
              <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676644760/assets/arrowObjective_dcoqeg.png" alt="icon" />
            </div>
            <div className="content-item">
              <div className="title-item side"><span>OBJETIVO</span></div>
              <div className="description-item side"> <p > Formar a nuestros estudiantes para que tengan la capacidad de generar, analizar e
                interpretar información financiera cuantitativa y cualitativa para la toma de
                decisiones en las organizaciones; con actitudes y valores, visión y generador del
                cambio en su ámbito social, económico y laboral, asumiendo una actitud de
                servicio al cliente, a la sociedad y con disposición al trabajo colaborativo.</p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="img-item">
              <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676644760/assets/users_wkimo7.png" alt="icon" />
            </div>
            <div className="content-item">
              <div className="title-item" ><span>PÚBLICO OBJETIVO</span></div>
              <div className="description-item"> <p >A todas las personas que sean bachilleres y deseen desempeñarse en empresas y
                organizaciones de distintos tamaños y sectores económicos, con o sin fines de
                lucro, en general en toda institución que requiera desarrollar procesos contables.
                Asimismo, podrá generar sus propios espacios de trabajo a través de la creación y
                gestión de microempresas de prestación de servicios técnicos en las áreas
                contable y tributaria.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tecnique__head">
        <div className="main_characteristic">
          <div className="title-section-body">CARACTERISTICAS DEL CURSO</div>
          <div className="characteristic">
            <div className="col-character">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675714880/imagesCapacita/icon3_smmsuj.png" alt="icon" />
              <h3>MODALIDAD</h3>
              <p className="page-target">Presencial o virtual</p>
            </div>
            <div className="characterSpace col-character">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675714880/imagesCapacita/icon4_joswtn.png" alt="icon" />
              <h3>METODOLOGIA</h3>
              <p className="page-target" >Exposiciones teórico – práctico combinando ambos
                métodos de enseñanza, así como la implementación de
                estudios de casos y resolución de problemas vinculados
                con la realidad, que permitirán a los participantes la
                discusión, reflexión y trabajo grupal y desarrollar
                aptitudes para aplicar y transferir los conocimientos a su
                campo laboral.
                La carrera es modular es decir que por mes llevarás una
                materia con el afán de enfocarte en aprender y
                comprender a su totalidad.</p>
            </div>
            <div className="col-character">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675714880/imagesCapacita/icon5_n8zl5f.png" alt="icon" />
              <h3>DURACION</h3>
              <p className="page-target">3 Años</p>
            </div>
            <div className="characterSpace col-character">
              <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675714880/imagesCapacita/icon6_ml3iff.png" alt="icon" />
              <h3>TITULACION</h3>
              <p className="page-target">Técnico Superior en Contaduría General,
                extendido por el Ministerio de Educación.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="tecnique__head">
        <div className="main_curricula">
          <div className="title-section-body">MALLA CURRICULAR</div>
          <img className="image-large" src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675718122/imagesCapacita/PENSUM_CONTADURIA_k2gcm1.png" alt="" />
          <div className="list-image-display">
            <div className="nav-display nav-d-one" onClick={() => handleButton(1)}>1er. año</div>
            <div className="nav-display nav-d-two" onClick={() => handleButton(2)}>2do. año</div>
            <div className="nav-display nav-d-three" onClick={() => handleButton(3)}>3er. año</div>
          </div>
          <div className="img-section">
            <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676651954/assets/ges1_zyw3bb.png" alt="img1" className={`img-d${dImageOne ? "-block" : ""}`} />
            <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676651954/assets/ges2_i8umke.png" alt="img2" className={`img-d${dImageTwo ? "-block" : ""}`} />
            <img src="https://res.cloudinary.com/pataformacgi/image/upload/v1676651954/assets/ges3_u5qdse.png" alt="img3" className={`img-d${dImageThree ? "-block" : ""}`} />
          </div>
          <button className="button-download"><a href="https://res.cloudinary.com/pataformacgi/image/upload/v1676674578/assets/CONTADURIA_GENERAL_lo29ym.pdf" target="black"><i className="fa fa-download" /> DESCARGAR MALLA CURRICULAR</a> </button>
        </div>
      </div>

      <div className="tecnique__head">
        <div className="main_hora">
          <div className="title-section-body">HORARIOS</div>
          <img className="imgHorario" src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675714836/imagesCapacita/Horarios_jpbc9o.png" alt="" />
        </div>
      </div>
      <div className="sub-content">
        <div className="image-section">
          <a href={`https://wa.me/59170140353?text=Hola%20Capacita%20Global%20`}
            target="_blank"
            rel="noopener noreferrer">
            <img src="https://res.cloudinary.com/ahsoizdev/image/upload/v1675807047/imagesCapacita/whatsap_hnoivb.png" alt="enlace whatssap" />
          </a>
        </div>
        <div className="section-text">
          <div className="title-section-body">CURSOS CORTOS</div>
          <p>¡TE INVITAMOS A CONOCER NUESTROS CURSOS CORTOS!</p>
          <Link to="/catalogue/short-courses" className="button-redirect">Cursos Cortos</Link>
        </div>
      </div>



      <FooterComponent />
    </div>
  );
};


export default connect(null, {
  changeLayout,
})(GeneralAccounting);
