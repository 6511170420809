import * as Yup from 'yup'
import { useState } from 'react'
import { H2 } from '../../../components/text'
import { GlobalForm } from '../../../components/form'
import { Loading } from '../../../components/animation'
import { useAxiosAuth } from '../../../hooks'
import { inputsAddCourse } from '../../../utils/FormParameters'
import { capitalizeJson } from '../../../utils/ProcessData'
import { validateStatus } from '../../../utils/Validation'
import { postAction } from '../../../services/action/ActionAuthorization'
const FormAddCourse = (props) => {
  const { onCancel, program, user, reload, openSnackbar } = props
  const [load, setload] = useState(true)
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/programManager/${program.id}/parallels`,
  })

  function handleOnSubmit(values) {
    setload(false)
    const course = response.find((item) => item.id + '' === values.CourseId)
    postAction(`/${course.type}/${values.CourseId}/EnrollUser`, {
      ...values,
      ProgramId: values.CourseId,
    }).then((resp) => {
      setload(true)
      if (validateStatus(resp.status)) {
        openSnackbar('Se inscribió al usuario', true, true)
        onCancel()
        reload()
      } else {
        openSnackbar('No se pudo inscribir al usuario', true, false)
      }
    })
  }

  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        <H2 textAlign="center" color="var(--gray)">
          Formulario de inscripción de <br /> {program.name}
        </H2>
        <GlobalForm
          InputsJson={[
            ...inputsAddCourse,
            {
              name: 'CourseId',
              initial: '',
              validate: Yup.string().required('Campo requerido'),
              control: 'select',
              label: 'Seleccione el paralelo',
              options: [
                { key: 'Seleccione el paralelo', value: '' },
                ...response.map((item) => ({
                  key: item.versionName,
                  value: item.id,
                })),
              ],
            },
          ]}
          textBtn="Inscribir"
          data={capitalizeJson({
            ...user,
            name: user.firstName,
            nationalNumber: user.telephone,
          })}
          load={load}
          onSubmit={handleOnSubmit}
          onCancel={onCancel}
        />
      </>
    )
  }
}

export default FormAddCourse
