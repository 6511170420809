import { UseOnSubmit } from '../../hooks/useOnSubmit'
export function sendMail(lid, changeModal, openSnackbar) {
  changeModal(
    true,
    '¿Estás seguro que quieres enviar correos a los estudiantes?',
    'Se enviarán correos a los estudiantes indicandoles que se publicó la lección',
    'Enviar',
    () => onSubmit(lid, openSnackbar, changeModal),
  )
}
async function onSubmit(lid, openSnackbar, changeModal) {
  try {
    changeModal(true, 'Espere un momento...')
    await UseOnSubmit({
      axiosParams: {
        method: 'PUT',
        url: `/lesson/${lid}/notify`,
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Correos enviados',
      textWrong: 'No se pudo enviar',
    })
  } catch (e) {
    console.log(e)
  } finally {
    changeModal(false, '', '', '', null)
  }
}
