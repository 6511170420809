import { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  changeLayout,
  changeModal,
  openSnackbar,
  startSession,
} from '../../store/actions'
import { H2, H3, H4 } from '../../components/text'
import { RenderButton } from '../../components/buttons'
import { FormDefault } from '../../components/form'
import { InputsRegisterWebinar } from '../../utils/FormParameters'
import { withRouter } from 'react-router-dom'
import './Webinar.css'
const Webinar = ({
  changeLayout,
  changeModal,
  openSnackbar,
  startSession,
  history,
}) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  function handleRegister() {
    changeModal(
      true,
      '',
      <FormDefault
        inputs={InputsRegisterWebinar}
        onCancel={() => changeModal(false, null)}
        method="POST"
        url={`/webinarpreregister/inscription`}
        openSnackbar={openSnackbar}
        title="Registrarme al webinar"
        processData={(value) => ({ ...value, SignedProgramManagerId: 218 })}
        handleReload={handleSuccess}
        tokenAuth={false}
      />,
      null,
      null,
    )
  }
  function handleSuccess(data) {
    if (data && data !== 'already_enrolled') {
      const { institutions, courses, user, token, father } = data
      window.localStorage.setItem('id', user.id)
      window.localStorage.setItem('token_seguridad', token)
      startSession({ ...user, father: father }, courses, institutions, true)
    }
    changeModal(false, null)
    history.push('/webinar/registered')
  }

  return (
    <div className="Webinar">
      <div className="Webinar__header">
        <H3
          textAlign="center"
          fontSize="2.2rem"
          color="white"
          margin="0"
          fontWeight="600"
        >
          RESERVA TU LUGAR GRATIS A MI TALLER EN LÍNEA
        </H3>
      </div>
      <div className="Webinar__hero">
        <div className="Webinar__ImageContain">
          <div className="Webinar__HImage"></div>
        </div>
        {/* <img
          className="Webinar__HImage"
          src="https://images.unsplash.com/photo-1579621970588-a35d0e7ab9b6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
          alt=""
        /> */}
        <div className="Webinar__HDescription">
          <img
            src="https://res.cloudinary.com/pataformacgi/image/upload/v1670947593/assets/IMG-20221213-WA0003_owhwho.jpg"
            alt=""
          />
          <H3 textAlign="center" fontSize="1.8rem" fontWeight="500">
            En este taller compartiré contigo:
          </H3>
          <H3 textAlign="center" fontSize="3rem" color="#fb7420">
            UN SENCILLA ESTRATEGIA PARA QUE GENERES INGRESOS DESDE CASA EN MENOS
            DE 90 MINUTOS
          </H3>
          <H4 textAlign="center" fontSize="1.8rem" color="gray">
            Con esta valiosa información podrás trabajar desde donde estes
            además dispondrás de tu tiempo para estar con tus hijos, familiares
            o amigos solo necesitas internet y dispositivo móvil o una
            computadora
          </H4>
          <br />
          <RenderButton
            onClick={handleRegister}
            background="rgb(241, 246, 41)"
            color="black"
            border="1px solid black"
            padding="10px 25px"
            fontWeight="700"
          >
            QUIERO REGISTRARME GRATIS AHORA
          </RenderButton>
          <H4
            textAlign="center"
            fontSize="1.2rem"
            fontWeight="500"
            color="gray"
          >
            *Tu información está 100% segura y nunca compartiremos tus datos con
            nadie.
          </H4>
        </div>
      </div>
      <div className="Webinar__section">
        <div className="Webinar__SItem">
          <i className="far fa-calendar-check"></i>
          <H3 color="white">jueves 27 de diciembre 2022</H3>
        </div>
        <div className="Webinar__SItem">
          <i className="far fa-clock"></i>
          <H3 color="white">18:30 AM (hora de Bolivia)</H3>
        </div>
        <div className="Webinar__SItem">
          <div className="Webinar__SIImage"></div>
          <H3 color="white">Host del Taller - Lorna Medrano</H3>
        </div>
      </div>
      <div className="Webinar__description">
        <H2 textAlign="center">EN ESTE TALLER GRATUITO APRENDERÁS:</H2>
        <div className="Webinar__DList">
          <CardSkill
            icon="fas fa-coins"
            text="Por qué trabajar en una oficina es cosa del pasado y la fórmula para trabajar de online de una manera sencilla, práctica y segura. "
          />
          <CardSkill
            icon="fas fa-coins"
            text="Una alternativa muy atractiva para que puedas generar ingresos desde la comodidad de tu hogar."
          />
          <CardSkill
            icon="fas fa-coins"
            text="Cuales son los tres factores clave para alcanzar el triángulo de la libertad."
          />
          <CardSkill
            icon="fas fa-coins"
            text="El error que uno comete al incursionar en trabajos online sin un asesoramiento previo"
          />
          <CardSkill
            icon="fas fa-coins"
            text="La realidad sobre trabajar desde casa online que nadie te dice ¿Qué esperar?"
          />
        </div>
        <H2
          textAlign="center"
          color="orange"
          fontWeight="500"
          padding="10px 40px"
        >
          TE EXPLICARÉ TAMBIÉN PORQUÉ ES VITAL QUE CONOZCAS Y APLIQUES ESTA
          INFORMACIÓN ANTES DE QUE TRABAJES COMO NUESTRO SOCIO ESTRATEGICO.
        </H2>
      </div>
      <br />
      <br />
      <br />
      <H2 textAlign="center">
        ESTE TALLER ES PARA TI SI ESTAS ATRAVESANDO POR ALGUNO DE ESTOS
        ASPECTOS:
      </H2>
      <div className="Webinar__teacher">
        <img
          className="Webinar__TImage"
          src="https://mamaenlatorre.com/wp-content/uploads/2020/03/Home-office-para-mam%C3%A1s.jpg"
          alt=""
        />
        <div className="Webinar__TDescription">
          <H3>
            <i className="fas fa-check"></i> Si, tienes un bebe en casa que
            cuidar
          </H3>
          <H3>
            <i className="fas fa-check"></i> Si, estas de vacaciones y dispones
            de tiempo libre
          </H3>
          <H3>
            <i className="fas fa-check"></i> Si, deseas poder trabajar y desde
            casa
          </H3>
          <H3>
            <i className="fas fa-check"></i> Si, deseas poder trabajar y viajar
            a la vez
          </H3>
          <H3>
            <i className="fas fa-check"></i> Si, necesitas generar mayores
            ingresos
          </H3>
        </div>
        <img
          className="Webinar__TImage"
          src="https://uvn-brightspot.s3.amazonaws.com/assets/vixes/t/trabajar-home-office-en-la-playa.jpg"
          alt=""
        />
      </div>
      <br />
      <br />
      <br />
      <div className="Webinar__footer">
        <H3
          width="60%"
          textAlign="center"
          color="White"
          margin="0 auto"
          fontSize="2.5rem"
          fontWeight="500"
        >
          Tu mereces disfrutar y aprovechar de la era tecnológica, conoce los
          secretos que te permitirán generar ingresos desde donde estés.
        </H3>
        <br />
        <RenderButton
          onClick={handleRegister}
          background="rgb(241, 246, 41)"
          color="black"
          border="1px solid black"
          padding="10px 25px"
          fontWeight="700"
        >
          QUIERO REGISTRARME GRATIS AHORA
        </RenderButton>
        <br />
      </div>
    </div>
  )
}
const CardSkill = ({ icon, text }) => {
  return (
    <div className="CardSkill">
      <i className={icon}></i>
      <H4 textAlign="center" fontSize="1.8rem">
        {text}
      </H4>
    </div>
  )
}

export default connect(null, {
  changeLayout,
  changeModal,
  startSession,
  openSnackbar,
})(withRouter(Webinar))
