import { FormDefault } from '../../../components/form'
import { H2, H3 } from '../../../components/text'
import { BoxFlex } from '../../../components/boxes'
import { Loading } from '../../../components/animation'
import { InputRegisterChild } from '../../../utils/FormParameters'
import { validateStatus } from '../../../utils/Validation'
import { urlAvatar } from '../../../utils/Constant'
import { postEmptyAction } from '../../../services/action/ActionAuthorization'
const FormNewChild = (props) => {
  const {
    openSnackbar,
    onClose,
    father,
    changeModal,
    handleReload,
    pid,
  } = props

  function handleSuccess(child) {
    changeModal(
      true,
      `Inscribiendo a ${child.name} al curso`,
      <Loading />,
      null,
    )
    postEmptyAction(`/Course/${pid}/EnrollChildUser/${child.id}`).then(
      (resp) => {
        changeModal(false, null)
        if (validateStatus(resp.status)) {
          openSnackbar(
            `Se inscribió a ${child.firstName || child.name} exitosamente`,
            true,
            true,
          )
          handleReload()
        } else {
          openSnackbar(
            `No se pudo inscribir a ${child.firstName || child.name}`,
            true,
            false,
          )
        }
      },
    )
  }

  return (
    <>
      <H2 color="var(--gray)">Registra los datos del niño a inscribir</H2>
      <H3 margin="0">Datos del tutor:</H3>
      <BoxFlex
        width="100%"
        wrap="nowrap"
        padding="5px"
        borderRadius="10px"
        justify="flex-start"
        border="1px solid gray"
      >
        <div
          style={{
            height: '80px',
            width: '80px',
            backgroundImage: `url("${
              father.photoUrl ? father.photoUrl : urlAvatar
            }")`,
            backgroundSize: 'cover',
            border: '2px solid var(--white)',
            borderRadius: '50%',
          }}
        ></div>
        <div className="">
          <H3>
            {father.name || father.firstName} {father.lastName}
          </H3>
          {father.email && (
            <H3 fontSize="1.4rem" fontWeight="500">
              <strong>Correo:</strong> {father.email}
            </H3>
          )}
          {/* {father.username && (
            <H3 fontSize="1.4rem" fontWeight="500" margin="0">
              <strong>Usuario:</strong> {father.username}
            </H3>
          )} */}
          {father.telephone?.nationalNumber ? (
            <H3 fontSize="1.4rem" fontWeight="500" margin="0">
              <strong>Teléfono:</strong> {father.telephone.nationalNumber}
            </H3>
          ) : null}
          {father.telephone && father.telephone.nationalNumber === undefined ? (
            <H3 fontSize="1.4rem" fontWeight="500" margin="0">
              <strong>Teléfono:</strong> {father.telephone}
            </H3>
          ) : null}
        </div>
      </BoxFlex>
      <H3 margin="10px 0 0">Datos del niño:</H3>
      <FormDefault
        url={`/users/RegisterChild/${father.id}`}
        inputs={InputRegisterChild}
        data={{}}
        method="POST"
        onCancel={onClose}
        openSnackbar={openSnackbar}
        handleReload={handleSuccess}
      />
    </>
  )
}

export default FormNewChild
