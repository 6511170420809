import { processUrlImage } from '../../utils/ProcessData'
import { convertD } from '../../utils/ConvertDate'
import { rolName, urlAvatar } from '../../utils/Constant'
import { validateArray } from '../../utils/Validation'
import { DefaultBtn, RenderButton } from '../buttons'
import { BoxFlex, CirclePointer, ToolTip } from '../boxes'
const DataType = ({ a, head, reload }) => {
  switch (a.type) {
    case 'action':
      return (
        <DefaultBtn
          fontSize="1.4rem"
          fontWeight="500"
          background="#e2e4f3"
          color="var(--blue)"
          onClick={() => a.key(head, reload)}
          width="auto"
          border="1px solid var(--blue)"
        >
          {a.textBtn}
        </DefaultBtn>
      )
    case 'img':
      return (
        <img
          src={processUrlImage(head[a.key] ? head[a.key] : urlAvatar, 50)}
          alt="avatar"
        />
      )
    case 'a':
      if (head[a.key] === 'N/A') {
        return <div>{head[a.key]}</div>
      } else if (head[a.key]) {
        return (
          <a href={head[a.key]} target="_blank" rel="noopener noreferrer">
            {a.name ? a.name : 'Abrir Archivo'}
          </a>
        )
      } else {
        return <div>--</div>
      }
    case 'textColor':
      return (
        <div className="TableDefault__textColor">
          <h4
            style={{
              background: `${a.color[head[a.key]]}`,
              color: a.color[head[a.key]] ? '' : 'var(--black)',
            }}
          >
            {head[a.key]}
          </h4>
        </div>
      )
    case 'date':
      return <div>{head[a.key] ? convertD(head[a.key], 'LLLL') : '--'}</div>
    case 'onlyDate':
      return <div>{head[a.key] ? convertD(head[a.key], 'L') : '--'}</div>
    case 'circleColor':
      return (
        <div>
          {head[a.key] ? (
            <BoxFlex>
              <CirclePointer background={head[a.key]} />
            </BoxFlex>
          ) : (
            '--'
          )}
        </div>
      )
    case 'actions':
      return (
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '8px',
          }}
        >
          {validateArray(a.actions) &&
            a.actions.map((item, index) => (
              <ToolTip key={index} content={item.label}>
                <div>
                  <RenderButton
                    background="var(--green)"
                    fontSize="1.4rem"
                    onClick={() => item.action(head, reload)}
                  >
                    <i className={item.icon}></i>
                  </RenderButton>
                </div>
              </ToolTip>
            ))}
        </div>
      )
    case 'textArea':
      if (head[a.key]) {
        return (
          <div>
            {head[a.key].substring(0, 100) +
              `${head[a.key].length > 100 ? '...' : ''} `}
          </div>
        )
      } else {
        return <div>--</div>
      }
    case 'list':
      if (head[a.key]) {
        const list = head[a.key].split('; ')

        return (
          <div>
            {list.map((item, index) => (
              <div
                key={index}
                style={{
                  marginBottom: '5px',
                  border: '1px solid gray',
                  borderRadius: '5px',
                  padding: '2px 5px',
                }}
              >
                {item}
              </div>
            ))}
          </div>
        )
      } else {
        return <div>--</div>
      }
    case 'preProcess':
      if (a.validate) {
        return <div>{a.validate(head, a.key)}</div>
      } else {
        return <div>--</div>
      }
    case 'stringArray':
      if (head[a.key]) {
        const list = head[a.key]
        return (
          <div>
            {list.map((item, index) => (
              <div
                key={index}
                style={{
                  marginBottom: '5px',
                  border: '1px solid gray',
                  borderRadius: '5px',
                  padding: '2px 5px',
                }}
              >
                {rolName[item]}
              </div>
            ))}
          </div>
        )
      } else {
        return <div>--</div>
      }
    default:
      return <div>{head[a.key] ? head[a.key] : '--'}</div>
  }
}

export default DataType
