import React from 'react'

import { InstitutionsContainer } from '../contacts'

export const Institutions = (props) => {
  return (
    <>
      <InstitutionsContainer {...props} />
    </>
  )
}
