import { useState } from 'react'
import { GlobalForm } from '../../../components/form'
import { validateStatus } from '../../../utils/Validation'
import { InputChildRegister } from '../../../utils/FormParameters'
import { postAction } from '../../../services/action/ActionAuthorization'
const FormChildren = (props) => {
  const { onCancel, tutor, openSnackbar } = props
  const [load, setload] = useState(true)
  async function handleOnSubmit(values) {
    setload(false)
    await postAction(`/auth/register/${tutor.id}/child`, values).then(
      (response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Se adiciono al niñ@', true, true)
          onCancel()
        } else if (response.data === 'username_exist') {
          openSnackbar('El usuario ya esta siendo utilizado', true, false)
        } else {
          openSnackbar('No se pudo adicionar', true, false)
        }
      },
    )
  }
  return (
    <div style={{ marginRight: '10px' }}>
      <GlobalForm
        InputsJson={InputChildRegister}
        textBtn="Registrar"
        title="ADICIONAR NIÑ@"
        data={{}}
        load={load}
        onSubmit={handleOnSubmit}
        onCancel={onCancel}
      />
    </div>
  )
}

export default FormChildren
