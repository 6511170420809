import { H3, H4 } from '../../components/text'
import { Search } from '../../components/inputs'
import { Loading } from '../../components/animation'
import { DefaultBtn } from '../../components/buttons'
import { validateArray } from '../../utils/Validation'
import { BoxFlex, ToolTip } from '../../components/boxes'
import { useAxios, useAxiosAuth } from '../../hooks'

const HeaderFilter = (props) => {
  const { options, onSearch, urlParam, seturlParam } = props
  function onChange(e) {
    const { name, value } = e.target
    seturlParam((a) => ({
      ...a,
      search: {
        ...a.search,
        [name]: value,
      },
    }))
  }

  return (
    <BoxFlex justify="flex-start">
      {validateArray(options) && (
        <Search
          onClick={onSearch}
          search={urlParam.search}
          onChange={onChange}
          options={options}
        />
      )}
      <BoxFilters {...props} />
    </BoxFlex>
  )
}
const BoxFilters = (props) => {
  if (validateArray(props.boxFilters)) {
    return props.boxFilters.map((item, index) => (
      <Filter key={index} {...props} item={item} />
    ))
  }
  return null
}

const Filter = (props) => {
  const { item, filters, setfilters } = props
  function handleSetUrl(element) {
    setfilters((a) => ({
      ...a,
      [item.name]: element.name,
    }))
  }

  switch (item.type) {
    case 'select':
      return (
        <ToolTip
          interactive={true}
          trigger="click"
          placement="bottom"
          content={
            <>
              {item.options.map((element, index) => (
                <H4
                  color={
                    filters[item.name] === element.name ||
                    (!filters[item.name] && element.name === '')
                      ? 'var(--green)'
                      : ''
                  }
                  onClick={() => handleSetUrl(element)}
                  key={index}
                  cursor="pointer"
                >
                  {element.label}
                </H4>
              ))}
            </>
          }
        >
          <div>
            <DefaultBtn color="var(--green)">
              {item.label}{' '}
              <TextSelect
                item={item}
                filters={filters}
                options={item.options}
              />
            </DefaultBtn>
          </div>
        </ToolTip>
      )
    case 'getSelect':
      return (
        <FilterGetSelect
          {...props}
          useAxiosSelect={item.token ? useAxiosAuth : useAxios}
        />
      )
    default:
      return null
  }
}

const FilterGetSelect = (props) => {
  const { item, filters, setfilters, useAxiosSelect } = props
  const { response, loading } = useAxiosSelect({
    method: 'GET',
    url: item.url,
  })
  function onChange(e) {
    setfilters((a) => ({
      ...a,
      [item.name]: e[item.urlKey],
    }))
  }

  if (loading) {
    return <Loading />
  } else {
    return validateArray(response) ? (
      <ToolTip
        interactive={true}
        trigger="click"
        placement="bottom"
        content={
          <>
            {[
              { [item.urlKey]: '', [item.urlLabel]: item.initial },
              ...response,
            ].map((element, index) => (
              <H4
                color={
                  filters[item.name] === element[item.urlKey] ||
                  (!filters[item.name] && element[item.urlKey] === '')
                    ? 'var(--green)'
                    : ''
                }
                onClick={() => onChange(element)}
                key={index}
                cursor="pointer"
              >
                {item.translate
                  ? item.translate[element[item.urlLabel]]
                  : element[item.urlLabel]}
              </H4>
            ))}
          </>
        }
      >
        <div>
          <DefaultBtn color="var(--green)">
            {item.label}{' '}
            <TextSelect
              item={item}
              filters={filters}
              options={[
                { [item.urlKey]: '', [item.urlLabel]: item.initial },
                ...response,
              ]}
              keyOp={item.urlKey}
              label={item.urlLabel}
            />
          </DefaultBtn>
        </div>
      </ToolTip>
    ) : null
  }
}

const TextSelect = (props) => {
  const { item, filters, keyOp = 'name', label = 'label', options } = props
  const value = filters[item.name]
  const labelText = options.filter((option) =>
    value ? option[keyOp] === value : option[keyOp] === '',
  )

  if (validateArray(labelText)) {
    return (
      <H3
        margin="0"
        background="var(--white)"
        padding="3px 5px 1px 5px"
        fontSize="1.4rem"
        borderRadius="5px"
        color="var(--green)"
      >
        {item.translate
          ? item.translate[labelText[0][label]]
          : labelText[0][label]}
      </H3>
    )
  } else {
    return null
  }
}

export default HeaderFilter
