import React, { useState, useEffect } from 'react'
import { Loading } from '../../../components/animation'
import { ActionButton, RenderButton } from '../../../components/buttons'
import { BoxFlex } from '../../../components/boxes'
import { H2 } from '../../../components/text'
import { GlobalForm } from '../../../components/form'
import TableMain from '../../../components/table/TableMain'
import { useAxiosAuth } from '../../../hooks'
import {
  deleteAction,
  postAction,
  putAction,
} from '../../../services/action/ActionAuthorization'
import { convertD } from '../../../utils/ConvertDate'
import { inputAddOneSchedule } from '../../../utils/FormParameters'
import { validateStatus, validateArray } from '../../../utils/Validation'
import ScheduleView from './ScheduleView'
import AttendanceContainer from './scheduleContainer/AttendanceContainer'
import ScheduleReschedule from './scheduleContainer/ScheduleReschedule'

export const ViewCourseSchedule = (props) => {
  const {
    changeModal,
    openSnackbar,
    match: {
      params: { cid, pid },
    },
    changeInfo,
  } = props

  const { response, reload: reloadSchedule, loading } = useAxiosAuth({
    method: 'GET',
    url: cid
      ? `courseSchedule/course/${cid}`
      : pid
      ? `/courseSchedule/program/${pid}`
      : null,
  })

  useEffect(() => {
    if (!loading) {
      reloadSchedule()
    }
  }, [cid, pid])

  const { response: virtualClassResponse } = useAxiosAuth({
    method: 'GET',
    url: `virtualClassRoom`,
  })
  function handleSheduleEdit(item) {
    function getFormattedDate(dateConvert) {
      const getHoursAndMinutes = dateConvert.split(':')
      var date = new Date()
      date.setHours(getHoursAndMinutes[0], getHoursAndMinutes[1], 0)
      return date
    }

    const data = {
      StartClass: getFormattedDate(item.startClass),
      FinishClass: getFormattedDate(item.finishClass),
      ClassDayDate: new Date(item.classDayDate),
    }

    const onSubmit = async (values) => {
      try {
        setload(false)

        await putAction(`courseSchedule/${item.id}/update`, {
          ...values,
          StartClass: convertD(values.StartClass, 'HH:mm'),
          FinishClass: convertD(values.FinishClass, 'HH:mm'),
        }).then((response) => {
          setload(true)
          if (validateStatus(response.status)) {
            reloadSchedule()
            changeModal(false, '')
            openSnackbar('Editado correctamente', true, true)
          } else {
            openSnackbar(response.data, true, false)
          }
        })
      } catch (e) {
        setload(true)
      }
    }

    changeModal(
      true,
      'Editar horario',
      <GlobalForm
        load={load}
        textBtn="Editar"
        onSubmit={onSubmit}
        InputsJson={inputAddOneSchedule}
        data={data}
        widthBtn="100%"
        onCancel={() => changeModal(false, '')}
      />,
      null,
    )
  }

  function handleAttendance(item) {
    changeModal(
      true,
      'Lista de asistencia',
      <AttendanceContainer {...props} schedule={item} edit={true} />,
      null,
    )
  }
  function handleReschedule(item) {
    changeModal(
      true,
      'Reprogramar horario',
      <ScheduleReschedule {...props} item={item} reload={reloadSchedule} />,
      null,
    )
  }

  const actionShedule = [
    {
      id: 1,
      label: 'Lista de asistencia',
      action: handleAttendance,
      icon: 'fas fa-tasks',
    },
    {
      id: 2,
      label: 'Editar horario',
      action: handleSheduleEdit,
      icon: 'fas fa-edit',
    },
    {
      id: 3,
      label: 'Reprogramar horario',
      action: handleReschedule,
      icon: 'fas fa-history',
    },
    {
      id: 4,
      label: 'Eliminar horario',
      action: handleSheduleDelete,
      icon: 'fas fa-times',
    },
  ]

  const header = [
    { key: 'startClass', name: 'Hora de inicio' },
    { key: 'finishClass', name: 'Hora de salida' },
    { key: 'classDayDate', name: 'Dia de la clase', type: 'onlyDate' },
    { key: 'reason', name: 'Razón' },
    {
      key: 'action',
      name: 'Acciones',
      type: 'actions',
      actions: actionShedule,
    },
  ]
  const [load, setload] = useState(true)

  async function onSubmitOneSchedule(values) {
    try {
      setload(false)

      await postAction(`/courseSchedule/course/${cid}/createOnlyOneSchedule`, {
        ...values,
        ClassDay: values.ClassDay,
        StartClass: convertD(values.StartClass, 'HH:mm'),
        FinishClass: convertD(values.FinishClass, 'HH:mm'),
      }).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Horario agregado correctamente', true, true)
          reloadSchedule()
          changeModal(false, '')
        } else {
          openSnackbar('El horario ya está ocupado', true, false)
        }
      })
    } catch (e) {
      setload(true)
    }
  }
  function handleChangeModal() {
    changeModal(
      true,
      'Adicionar un horario',
      <GlobalForm
        load={true}
        textBtn="Crear horario"
        onSubmit={onSubmitOneSchedule}
        InputsJson={inputAddOneSchedule}
        data={{}}
        widthBtn="100%"
        onCancel={() => changeModal(false, null)}
      />,
      '',
      null,
    )
  }
  async function onDeleteShedule(courseScheduleId) {
    try {
      await deleteAction(`courseSchedule/${courseScheduleId}/delete`).then(
        (response) => {
          if (validateStatus(response.status)) {
            changeModal(false, null)
            openSnackbar('Se elimino con exito', true, true)
            reloadSchedule()
          } else {
            openSnackbar('No se pudo eliminar', true, false)
          }
        },
      )
    } catch (e) {}
  }
  function handleViewSchedule() {
    changeModal(
      true,
      'Horarios',
      <ScheduleView
        changeView={false}
        events={response.schedulesPerCourse}
        /* handleSheduleEdit={handleSheduleEdit} */
        virtualClass={virtualClassResponse}
        currentEvents={response.schedulesPerCourse}
        showCircles={true}
        showOnlyAllSchedules={true}
      />,
      null,
      null,
      '80%',
    )
  }
  function handleSheduleDelete(item) {
    changeModal(
      true,
      '¿Estás seguro que quieres eliminar este horario?',
      <ActionButton
        onCancel={() => changeModal(false, null)}
        onClick={() => onDeleteShedule(item.id)}
        textBtn="Eliminar"
      />,
      null,
    )
  }

  function handlePreProcess(list) {
    let aux = []
    if (validateArray(list)) {
      if (list.schedulesPerCourse) {
        aux = list.schedulesPerCourse
      } else {
        list.forEach((element) => {
          if (validateArray(element.schedule)) {
            aux = [...aux, ...element.schedule]
          }
        })
      }
    }
    return aux
  }

  function validateColor(data) {
    return !data.classRegistration ? true : false
  }

  function handlePastSchedule() {
    changeInfo(<PastSchedules {...props} />)
  }

  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        {cid && (
          <RenderButton
            background="#04AC8A"
            borderRadius="10px"
            marginRight="5px"
            fontSize="1.4rem"
            onClick={handleChangeModal}
          >
            Adicionar horario <i className="fas fa-plus-circle"></i>
          </RenderButton>
        )}
        <RenderButton
          background="#762296AA"
          borderRadius="10px"
          marginLeft="5px"
          fontSize="1.4rem"
          onClick={handleViewSchedule}
        >
          Ver horarios disponibles <i className="fas fa-calendar"></i>
        </RenderButton>
        {cid && (
          <>
            <RenderButton
              typeBtn="anchor"
              background="#d17742"
              borderRadius="10px"
              margin="10px auto"
              textDecoration="none"
              fontSize="1.4rem"
              href={`/courses/${cid}/user-tracking`}
            >
              Reporte del curso <i className="fas fa-clipboard-check"></i>
            </RenderButton>
            <BoxFlex justify="flex-start">
              <RenderButton
                background="#762296AA"
                borderRadius="10px"
                marginLeft="5px"
                fontSize="1.4rem"
                onClick={handlePastSchedule}
              >
                Ver horarios pasados
              </RenderButton>
            </BoxFlex>
          </>
        )}
        <TableMain
          header={header}
          main={handlePreProcess(response)}
          colorCell={{
            background: '#c93f3f37',
            color: 'var(--gray)',
            validate: validateColor,
          }}
        />
      </>
    )
  }
}

const PastSchedules = (props) => {
  const {
    changeInfo,
    changeModal,
    openSnackbar,
    match: {
      params: { cid, pid },
    },
  } = props
  function handleView() {
    changeInfo(<ViewCourseSchedule {...props} />)
  }

  const { response, reload: reloadSchedule, loading } = useAxiosAuth({
    method: 'GET',
    url: cid
      ? `/courseSchedule/course/${cid}/pastSchedules`
      : pid
      ? `/courseSchedule/program/${pid}/pastSchedules`
      : null,
  })

  useEffect(() => {
    if (!loading) {
      reloadSchedule()
    }
  }, [cid, pid])

  const { response: virtualClassResponse } = useAxiosAuth({
    method: 'GET',
    url: `virtualClassRoom`,
  })
  function handleSheduleEdit(item) {
    function getFormattedDate(dateConvert) {
      const getHoursAndMinutes = dateConvert.split(':')
      var date = new Date()
      date.setHours(getHoursAndMinutes[0], getHoursAndMinutes[1], 0)
      return date
    }

    const data = {
      StartClass: getFormattedDate(item.startClass),
      FinishClass: getFormattedDate(item.finishClass),
      ClassDayDate: new Date(item.classDayDate),
    }

    const onSubmit = async (values) => {
      try {
        setload(false)

        await putAction(`courseSchedule/${item.id}/update`, {
          ...values,
          StartClass: convertD(values.StartClass, 'HH:mm'),
          FinishClass: convertD(values.FinishClass, 'HH:mm'),
        }).then((response) => {
          setload(true)
          if (validateStatus(response.status)) {
            reloadSchedule()
            changeModal(false, '')
            openSnackbar('Editado correctamente', true, true)
          } else {
            openSnackbar(response.data, true, false)
          }
        })
      } catch (e) {
        setload(true)
      }
    }

    changeModal(
      true,
      'Editar horario',
      <GlobalForm
        load={load}
        textBtn="Editar"
        onSubmit={onSubmit}
        InputsJson={inputAddOneSchedule}
        data={data}
        widthBtn="100%"
        onCancel={() => changeModal(false, '')}
      />,
      null,
    )
  }

  function handleAttendance(item) {
    changeModal(
      true,
      'Lista de asistencia',
      <AttendanceContainer {...props} schedule={item} edit={true} />,
      null,
    )
  }
  function handleReschedule(item) {
    changeModal(
      true,
      'Reprogramar horario',
      <ScheduleReschedule {...props} item={item} reload={reloadSchedule} />,
      null,
    )
  }

  const actionShedule = [
    {
      id: 1,
      label: 'Lista de asistencia',
      action: handleAttendance,
      icon: 'fas fa-tasks',
    },
    {
      id: 2,
      label: 'Editar horario',
      action: handleSheduleEdit,
      icon: 'fas fa-edit',
    },
    {
      id: 3,
      label: 'Reprogramar horario',
      action: handleReschedule,
      icon: 'fas fa-history',
    },
    {
      id: 4,
      label: 'Eliminar horario',
      action: handleSheduleDelete,
      icon: 'fas fa-times',
    },
  ]

  const header = [
    { key: 'startClass', name: 'Hora de inicio' },
    { key: 'finishClass', name: 'Hora de salida' },
    { key: 'classDayDate', name: 'Dia de la clase', type: 'onlyDate' },
    { key: 'reason', name: 'Razón' },
    {
      key: 'action',
      name: 'Acciones',
      type: 'actions',
      actions: actionShedule,
    },
  ]
  const [load, setload] = useState(true)

  async function onSubmitOneSchedule(values) {
    try {
      setload(false)

      await postAction(`/courseSchedule/course/${cid}/createOnlyOneSchedule`, {
        ...values,
        ClassDay: values.ClassDay,
        StartClass: convertD(values.StartClass, 'HH:mm'),
        FinishClass: convertD(values.FinishClass, 'HH:mm'),
      }).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Horario agregado correctamente', true, true)
          reloadSchedule()
          changeModal(false, '')
        } else {
          openSnackbar('El horario ya está ocupado', true, false)
        }
      })
    } catch (e) {
      setload(true)
    }
  }
  function handleChangeModal() {
    changeModal(
      true,
      'Adicionar un horario',
      <GlobalForm
        load={true}
        textBtn="Crear horario"
        onSubmit={onSubmitOneSchedule}
        InputsJson={inputAddOneSchedule}
        data={{}}
        widthBtn="100%"
        onCancel={() => changeModal(false, null)}
      />,
      '',
      null,
    )
  }
  async function onDeleteShedule(courseScheduleId) {
    try {
      await deleteAction(`courseSchedule/${courseScheduleId}/delete`).then(
        (response) => {
          if (validateStatus(response.status)) {
            changeModal(false, null)
            openSnackbar('Se elimino con exito', true, true)
            reloadSchedule()
          } else {
            openSnackbar('No se pudo eliminar', true, false)
          }
        },
      )
    } catch (e) {}
  }
  function handleViewSchedule() {
    changeModal(
      true,
      'Horarios',
      <ScheduleView
        changeView={false}
        events={response.schedulesPerCourse}
        /* handleSheduleEdit={handleSheduleEdit} */
        virtualClass={virtualClassResponse}
        currentEvents={response.schedulesPerCourse}
        showCircles={true}
        showOnlyAllSchedules={true}
      />,
      null,
      null,
      '80%',
    )
  }
  function handleSheduleDelete(item) {
    changeModal(
      true,
      '¿Estás seguro que quieres eliminar este horario?',
      <ActionButton
        onCancel={() => changeModal(false, null)}
        onClick={() => onDeleteShedule(item.id)}
        textBtn="Eliminar"
      />,
      null,
    )
  }

  function handlePreProcess(list) {
    let aux = []
    if (validateArray(list)) {
      if (list.schedulesPerCourse) {
        aux = list.schedulesPerCourse
      } else {
        list.forEach((element) => {
          if (validateArray(element.schedule)) {
            aux = [...aux, ...element.schedule]
          }
        })
      }
    }
    return aux
  }

  function validateColor(data) {
    return !data.classRegistration ? true : false
  }

  function handlePastSchedule() {
    changeInfo(<PastSchedules {...props} />)
  }

  return (
    <div>
      <BoxFlex justify="flex-start">
        <RenderButton
          background="#762296AA"
          borderRadius="10px"
          marginLeft="5px"
          fontSize="1.4rem"
          onClick={handleView}
        >
          <i className="fas fa-reply"></i> Volver
        </RenderButton>
      </BoxFlex>
      <H2 textAlign="center" color="var(--gray)">
        Horarios pasados
      </H2>
      <TableMain
        header={header}
        main={response}
        colorCell={{
          background: '#c93f3f37',
          color: 'var(--gray)',
          validate: validateColor,
        }}
      />
    </div>
  )
}
