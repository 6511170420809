import { useEffect } from "react";
import { connect } from "react-redux";
import {
  changeInfo,
  changeLayout,
  changeModal,
} from "../../../../store/actions";
import { CourseAside } from "../../../../containers/programs";
import { withRouter } from "react-router-dom";
import { LoadCircle } from "../../../../components/animation";
import { H2 } from "../../../../components/text";
import { validateArray } from "../../../../utils/Validation";
import { ModulesContainer } from "../../../../containers/programs";
import { useAxiosAuth } from "../../../../hooks";

const Modules = (props) => {
  const {
    changeInfo,
    changeLayout,
    // changeModal,
    match: {
      params: { cid, mid },
    },
  } = props;
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `course/${cid}/LessonDetail`,
  });
  // const { response: responseSchedule } = useAxiosAuth({
  //   method: 'GET',
  //   url: `courseSchedule/course/${cid}`,
  // })
  useEffect(() => {
    changeInfo(<CourseAside cid={cid} mid={mid} />);
    changeLayout("");
  }, [changeInfo, changeLayout, cid, mid]);

  // function handleModal() {
  //   changeModal(
  //     true,
  //     'Horarios',
  //     <ScheduleView
  //       /* events={ResponseCourseAllSchedule}
  //       handleSheduleEdit={handleSheduleEdit}
  //       virtualClass={virtualClassResponse} */
  //       currentEvents={responseSchedule}
  //       showCircles={false}
  //       showOnlyAllSchedules={false}
  //     />,
  //     null,
  //     null,
  //     '80%',
  //   )
  // }

  return (
    <div className="Course">
      {!loading ? (
        <>
          <div className="divDescription">
            <H2 margin="0 0 20px" textAlign="center">
              {response.courseDetail.name}
            </H2>
            {/* <ToolTip content={'Grupo de whatsApp'}>
              <a href="https://www.google.com/" target="_blank">
                <i style={{ fontSize: '24px' }} className="fab fa-whatsapp"></i>
              </a>
            </ToolTip> */}
          </div>
          {/* <BoxFlex justify="end">
            {validateArray(responseSchedule) > 0 && (
              <Icon ico="calendar-day" handleModal={handleModal}>
                Horarios
              </Icon>
            )}
          </BoxFlex> */}
          {validateArray(response.lessonDetail)
            ? response.lessonDetail
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((mod) =>
                  validateArray(mod.lessons) ? (
                    <div key={mod.id}>
                      <ModulesContainer
                        type={response.courseDetail.type}
                        reload={reload}
                        mod={mod}
                        mid={mid}
                      />
                    </div>
                  ) : null
                )
            : null}
        </>
      ) : (
        <LoadCircle />
      )}
    </div>
  );
};
const mapDispatchToProps = {
  changeInfo,
  changeLayout,
  changeModal,
};
export default connect(null, mapDispatchToProps)(withRouter(Modules));
