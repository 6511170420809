import * as Yup from 'yup'
import { useState } from 'react'
import './AttendanceContainer.css'
import { useAxiosAuth } from '../../../../hooks'
import { validateArray } from '../../../../utils/Validation'
import { capitalizeJson } from '../../../../utils/ProcessData'
import { Loading } from '../../../../components/animation'
import { FormDefault } from '../../../../components/form'
import { RenderModal } from '../../../../components/modal'
import { H3 } from '../../../../components/text'
import { BoxFlex, ToolTip } from '../../../../components/boxes'

const AttendanceContainer = (props) => {
  const { schedule, openSnackbar, edit = false } = props
  const [modal, setmodal] = useState(null)
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/usertracking/course/${schedule.courseId}/courseSchedule/${schedule.id}/list`,
  })
  function processData(values) {
    console.log(values.Participation)
    return {
      ...values,
      UserId: modal.id,
      CourseScheduleId: schedule.id,
      Participation: values.Participation ? 1 : 0,
      ObservationPriority: 2,
    }
  }
  function handleReload() {
    reload()
    setmodal(null)
  }
console.log(modal?.userTracking);
  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        {modal && (
          <RenderModal
            onClose={() => setmodal(null)}
            width="500px"
            closeOutside
          >
            <FormDefault
              inputs={[
                {
                  name: 'Assistance',
                  initial: false,
                  validate: Yup.boolean().required('Campo requerido'),
                  type: 'checkbox',
                  label: 'Asistencia',
                },
                {
                  name: 'Participation',
                  initial: false,
                  validate: Yup.boolean().required('Campo requerido'),
                  type: 'checkbox',
                  label: 'Participación',
                },
                {
                  name: 'Homework',
                  initial: false,
                  validate: Yup.boolean().required('Campo requerido'),
                  type: 'checkbox',
                  label: 'Tarea entregada',
                },
                {
                  name: 'SubjectEvaluation',
                  initial: '',
                  validate: Yup.string(),
                  control: 'textarea',
                  label: 'Evaluación de la materia',
                },
                {
                  name: 'Observation',
                  initial: '',
                  validate: Yup.string(),
                  control: 'textarea',
                  label: 'Observación',
                },
              ]}
              data={capitalizeJson({
                ...modal,
                ...modal.userTracking,
                Participation: modal.userTracking
                  ? modal.userTracking.participation === '1' ||
                    modal.userTracking.participation === 1
                    ? true
                    : false
                  : false,
              })}
              onCancel={() => setmodal(null)}
              method={modal.userTracking ? 'PUT' : 'POST'}
              url={
                modal.userTracking
                  ? `/userTracking/${modal.userTracking.id}`
                  : '/userTracking'
              }
              openSnackbar={openSnackbar}
              title={`Asistencia de ${modal.name} ${modal.lastName}`}
              handleReload={handleReload}
              processData={processData}
            />
          </RenderModal>
        )}
        <BoxFlex>
          {validateArray(response) ? (
            response.map((user) => (
              <CardAttendance
                key={user.id}
                user={user}
                onClick={edit ? () => setmodal(user) : () => {}}
              />
            ))
          ) : (
            <H3>No hay estudiantes</H3>
          )}
        </BoxFlex>
      </>
    )
  }
}
const CardAttendance = ({ user, onClick }) => {
  const [description, setdescription] = useState(false)
  function TextTracking({ children, status, content }) {
    return (
      <ToolTip content={content}>
        <div>
          <H3
            height="25px"
            width="25px"
            color="var(--white)"
            background={status ? 'var(--green)' : 'var(--red)'}
            margin="0"
            textAlign="center"
            lineHeight="25px"
            padding="5px 2.5px 0"
            borderRadius="50%"
          >
            {children}
          </H3>
        </div>
      </ToolTip>
    )
  }

  return (
    <div className="CardAttendance">
      <div onClick={onClick} className="CardAttendance__link">
        <div
          style={{
            backgroundImage: `url(${
              user.photoUrl
                ? user.photoUrl
                : 'https://res.cloudinary.com/pataformacgi/image/upload/v1655472643/assets/pngegg_pmvwzb_vl86bj.png'
            })`,
          }}
          className="CardAttendance__img"
        ></div>
        <H3
          textTransform="capitalize"
          textAlign="center"
          fontWeight="500"
          margin="5px 0"
        >
          {user.name} {user.lastName}
        </H3>
        {user.userTracking ? (
          <>
            <BoxFlex margin="0 0 10px">
              <TextTracking
                content="Asistencia"
                status={user.userTracking.assistance}
              >
                <i className="fas fa-check"></i>
              </TextTracking>
              <TextTracking
                content="Participation"
                status={user.userTracking.participation !== 0}
              >
                <i className="fas fa-spell-check"></i>
              </TextTracking>
              <TextTracking content="Tarea" status={user.userTracking.homework}>
                <i className="fas fa-seedling"></i>
              </TextTracking>
            </BoxFlex>
            {description && (
              <>
                <H3 fontWeight="500" margin="5px 0">
                  <span style={{ fontWeight: '700' }}>Observación:</span>{' '}
                  {user.userTracking.observation}
                </H3>
                <H3 fontWeight="500" margin="5px 0">
                  <span style={{ fontWeight: '700' }}>Evaluación:</span>{' '}
                  {user.userTracking.subjectEvaluation}
                </H3>
              </>
            )}
          </>
        ) : (
          <H3 textAlign="center" margin="5px 0">
            Sin Registro
          </H3>
        )}
      </div>
      {user.userTracking &&
      (user.userTracking.observation || user.userTracking.subjectEvaluation) ? (
        <div
          onClick={() => setdescription(!description)}
          className="CardAttendance__btn"
        >
          <i className={`fas fa-angle-${description ? 'up' : 'down'}`}></i>
        </div>
      ) : (
        <div style={{ height: '25px' }}></div>
      )}
    </div>
  )
}
export default AttendanceContainer
