import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { FormikControl, DropzoneInput } from "../../input";
import { DefaultBtn } from "../../buttons";
import { Loading } from "../../animation";
import { BoxFlex } from "../../boxes";
import { postUploadFileForLesson } from "../../../services/action/LessonAction";
import { putAction } from "../../../services/action/ActionAuthorization";
import { validateStatus } from "../../../utils/Validation";
const dropdownOptions = [
  { key: "Tipo de contenido:", value: "" },
  { key: "Enlace", value: "videourl" },
  { key: "Archivo", value: "material" },
];
const FormMaterialLesson = (props) => {
  const { lid, openSnackbar, handleCancel, reload, data, changeModal } = props;
  const [load, setload] = useState(true);
  const initialValues = {
    Description: data && data.description ? data.description : "",
    Type: data && data.type ? data.type : "",
    Url: "",
    File: null,
  };
  const validationSchema = Yup.object({
    Type: Yup.string().required("Campo Requerido"),
    Description: Yup.string().required("Campo Requerido"),
    File: Yup.mixed().when("Type", {
      is: "material",
      then: Yup.mixed().required("Campo requerido.").nullable(),
    }),
    Url: Yup.string().when("Type", {
      is: "videourl",
      then: Yup.string().required("Campo requerido."),
    }),
  });
  const validationSchemaEdit = Yup.object({
    Description: Yup.string().required("Campo Requerido"),
  });

  const onSubmit = async (values) => {
    try {
      setload(false);
      let formData = new FormData();
      formData.append("Type", values.Type);
      formData.append("Description", values.Description);
      if (values.Type === "material") {
        formData.append("File", values.File);
      } else {
        formData.append("Url", values.Url);
      }

      await postUploadFileForLesson(lid, formData).then((response) => {
        setload(true);
        if (validateStatus(response.status)) {
          openSnackbar("Se guardo tu material complementario", true, true);
          handleCancel();
          reload();
        } else {
          openSnackbar("Algo salio mal", true, false);
        }
      });
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };

  const editarMaterial = async (values) => {
    try {
      setload(false);
      await putAction(`/Lesson/${data.lessonFileId}/material`, {
        Description: values.Description,
      }).then((response) => {
        setload(true);
        if (validateStatus(response.status)) {
          openSnackbar("Se edito con éxito", true, true);
          handleCancel();
          reload();
        } else {
          openSnackbar("Algo salio mal", true, false);
        }
      });
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };

  function onSubmitEdit(values) {
    changeModal(
      true,
      "¿Estas seguro que quieres editar este material?",
      "",
      "editar",
      () => editarMaterial(values)
    );
  }
  return (
    <div className="PutMaterialLesson">
      <Formik
        initialValues={initialValues}
        validationSchema={data ? validationSchemaEdit : validationSchema}
        onSubmit={data ? onSubmitEdit : onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="select"
                label="Tipo de material"
                name="Type"
                options={dropdownOptions}
                disabled={data && data.description ? true : false}
              />
              <FormikControl
                control="input"
                type="text"
                label="Descripción del material"
                name="Description"
              />
              {data ? null : (
                <>
                  {formik.values.Type === "videourl" && (
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enlace"
                      name="Url"
                    />
                  )}
                  {formik.values.Type === "material" && (
                    <DropzoneInput
                      name="File"
                      small={true}
                      label="Subir archivo"
                      uploadFiles={formik.setValues}
                    />
                  )}
                </>
              )}
              {load ? (
                <BoxFlex>
                  <DefaultBtn
                    background={true}
                    onClick={handleCancel}
                    type="button"
                  >
                    Cancelar
                  </DefaultBtn>
                  <DefaultBtn disabled={!formik.isValid} type="submit">
                    {data ? "Editar" : "Añadir"} material
                  </DefaultBtn>
                </BoxFlex>
              ) : (
                <Loading />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FormMaterialLesson;
