import './style/ContentVirtualClass.css'
import { convertD } from '../../utils/ConvertDate'
import { ButtonIcon } from '../buttons'
import { withRouter } from 'react-router-dom'
import { CardMainOptions } from '../card'
import { IconText, H2, H4 } from '../text'
import EditorView from '../editorjs/EditorView'
import { sendMail } from './SendMail'
const ContentVirtualJitsiClassRol = (props) => {
  const {
    contentData,
    overt,
    ChangePublic,
    PutLesson,
    changeModal,
    openSnackbar,
    match: {
      params: { cid, mid, pid },
    },
  } = props
  const {
    id,
    name,
    description,
    startDatePerUser,
    // aditionalUrlResources,
    deleteLesson,
  } = contentData

  

  const copyCurrentLink = () => {
    const url = `/program/${
      pid ? pid + '/' : ''
    }course/${cid}/module/${mid}/jitsi/${id}`
    navigator.clipboard.writeText(`${window.location.origin}${url}`)
    openSnackbar('Enlace copiado', true, true)
  }
  const op = (
    <>
      <ButtonIcon
        content={overt ? 'Anular publicación' : 'Publicar'}
        onClick={ChangePublic}
        icon={overt ? 'eye' : 'eye-slash'}
      />
      {overt && (
        <ButtonIcon
          onClick={() => sendMail(id, changeModal, openSnackbar)}
          content="Enviar correo"
          icon="envelope"
        />
      )}
      <ButtonIcon onClick={PutLesson} content="Editar" icon="edit" />
      <ButtonIcon
        onClick={copyCurrentLink}
        content="Copiar enlace"
        icon="copy"
      />
      <ButtonIcon
        onClick={() => deleteLesson(id, name)}
        content="Eliminar lección"
        icon="trash"
        style={{ color: 'var(--red)' }}
      />
    </>
  )

  return (
    <CardMainOptions options={op}>
      <a
        className="ContentVirtualClass"
        target="_blank"
        href={`/program/${
          pid ? pid + '/' : ''
        }course/${cid}/module/${mid}/jitsi/${id}`}
        rel="noreferrer"
      >
        <div className="ContentVirtualClass__content">
          <div className="ContentHomework__head">
            <H2>{name}</H2>
            <H4
              margin="0"
              fontSize="1.6rem"
              textAlign="end"
              border="1px solid var(--gray)"
            >
              Clase virtual Con Jitsi
            </H4>
          </div>
          <IconText icon="calendar-day">
            {convertD(startDatePerUser, 'LLLL')} (Fecha Inicio)
          </IconText>
          {description && <EditorView cutText={true} data={description} />}
        </div>
      </a>
    </CardMainOptions>
  )
}

export default withRouter(ContentVirtualJitsiClassRol)
