import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../input'
import { useState } from 'react'
import { ActionButton } from '../buttons'
import { Loading } from '../animation'
import './style/NewUserProgram.css'
import { validateParam } from '../../utils/Validation'
import { dropdownGender } from '../../utils/Constant'
import { convertD, convertDate } from '../../utils/ConvertDate'
import { putAction } from '../../services/action/ActionAuthorization'
const ViewEditUser = (props) => {
  const {
    user,
    onClose,
    textBtn,
    edit,
    changeModal,
    handleReload,
    setedit,
    openSnackbar,
    reload,
  } = props
  const [load, setload] = useState(true)
  const initialValues = {
    LastName: validateParam('lastName', user),
    Name: validateParam('firstName', user),
    Ci: validateParam('ci', user),
    ExpeditionCi: validateParam('expeditionCi', user),
    Email: validateParam('email', user),
    NationalNumber: validateParam('telephone', user),
    DateOfBirth:
      user && user.dateOfBirth
        ? convertD(user.dateOfBirth, 'utc')
        : null || null,
    Gender: validateParam('gender', user),
  }
  const validationSchema = Yup.object({
    LastName: Yup.string().required('Campo Requerido'),
    Name: Yup.string().required('Campo Requerido'),
    Ci: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido'),
    ExpeditionCi: Yup.string().required('Campo Requerido'),
    Email: Yup.string()
      .email('Formato de correo inválido')
      .required('Campo Requerido'),
    NationalNumber: Yup.number()
      .typeError('Eso no parece un número de teléfono')
      .required('Campo Requerido')
      .max(99999999, 'Tu número de teléfono tiene muchos dígitos')
      .min(10000000, 'Tu número de teléfono tiene pocos dígitos'),
  })
  const dropdownOptions = [
    { key: 'Expedida en:', value: '' },
    { key: 'Chuquisaca', value: 'CH' },
    { key: 'La Paz', value: 'LP' },
    { key: 'Cochabamba', value: 'CB' },
    { key: 'Oruro', value: 'OR' },
    { key: 'Potosí', value: 'PT' },
    { key: 'Tarija', value: 'TJ' },
    { key: 'Santa Cruz', value: 'SC' },
    { key: 'Beni', value: 'BE' },
    { key: 'Pando', value: 'PD' },
  ]
  function save(res) {
    setload(true)
    if (res.status === 200) {
      openSnackbar('Guardado Exitosamente', true, true)
      reload()
      handleReload()
    } else {
      openSnackbar(res.data.title ? res.data.title : res.data, true, false)
    }
    console.log(reload)
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      await putAction(`/users/${user.id}/UpdateRegister`, {
        ...values,
        DateOfBirth: values.DateOfBirth
          ? convertDate(values.DateOfBirth)
          : values.DateOfBirth,
      }).then((response) => {
        save(response)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
    setedit(!edit)
    //resendUser();
  }
  function onValidate(values) {
    changeModal(
      true,
      '¿Estás seguro que quieres editar la información de este usuario?',
      '',
      'Editar',
      () => onSubmit(values),
    )
  }
  return (
    <div className="NewUserProgram">
      <h1>Editar Usuario</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onValidate}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                label="Apellidos"
                type="text"
                name="LastName"
              />
              <FormikControl
                control="input"
                type="text"
                label="Nombres"
                name="Name"
              />
              <FormikControl
                control="input"
                type="text"
                label="Documento de Identidad"
                name="Ci"
              />
              <FormikControl
                control="select"
                label="Expedida en"
                name="ExpeditionCi"
                options={dropdownOptions}
              />
              <FormikControl
                control="input"
                type="email"
                label="Correo Electrónico"
                name="Email"
              />
              <FormikControl
                control="input"
                type="text"
                label="Telefono de Contacto"
                name="NationalNumber"
              />
              <FormikControl
                control="date"
                label="Fecha de nacimiento"
                name="DateOfBirth"
                placeholderText="Fecha de nacimiento"
                dateFormat="MMMM d, yyyy"
                showMonthDropdown
                showYearDropdown
              />
              <FormikControl
                control="select"
                label="Genero"
                name="Gender"
                options={dropdownGender}
              />
              {/* <NormalButton type="submit">Adicionar</NormalButton> */}
              {load ? (
                <ActionButton
                  disabled={!formik.isValid}
                  onCancel={() => onClose()}
                  textBtn={textBtn ? textBtn : 'Guardar'}
                  textCancel="Cancelar"
                  type="submit"
                />
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export { ViewEditUser }
