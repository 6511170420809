import { useEffect, useState } from 'react'
import {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
} from '../../store/actions'
import { connect } from 'react-redux'
import { TableOptions } from '../../components/table'
import { H2, H3, H4, SubtitleData } from '../../components/text'
import {
  DefaultBtn,
  ActionButton,
  Icon,
  ButtonIcon,
  ButtonNavigation,
} from '../../components/buttons'
import { IconOptions } from '../../components/buttonsIcon'
import { GlobalForm } from '../../components/form'
import { Loading } from '../../components/animation'
import {
  postAction,
  putAction,
  deleteAction,
} from '../../services/action/ActionAuthorization'
import {
  InputsAddContact,
  InputsEditContact,
  InputsFollowUp,
  // InputsAddInstitution,
} from '../../utils/FormParameters'
import {
  validateArray,
  validateStatus,
  validateInstitution,
} from '../../utils/Validation'
import { capitalizeJson } from '../../utils/ProcessData'
import { convertD, convertDate } from '../../utils/ConvertDate'
import { useAxiosAuth } from '../../hooks'
import { CoursesInterested, ReallocateSell } from '../../containers/contacts'
import InstitutionsContainer from '../../containers/contacts/institutions/InstitutionsContainer'
import './Contacts.css'
const Contacts = (props) => {
  const { changeLayout, changeInfo } = props
  const { institutions } = props.sesionUser
  const isSocialnetwork = validateInstitution(institutions, 'socialnetwork')

  const [reload, setreload] = useState(false)

  const header = [
    { key: 'name', name: 'Nombres' },
    { key: 'informationMedium', name: 'Medio de información' },
    { key: 'programNames', name: 'Cursos de interes', type: 'list' },
    { key: 'phone', name: 'Número de celular' },
    isSocialnetwork.length > 0 && isSocialnetwork[0].rol === 'socialnetwork'
      ? { key: 'assignedTo', name: 'Asignado a' }
      : { key: 'ci', name: 'Carnet de identidad' },
  ]

  const [btns, setbtns] = useState({
    btn: [
      { id: 1, name: 'Contactos' },
      { id: 2, name: 'Instituciones' },
    ],
    activate: {
      id: 1,
    },
  })
  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }))
    changeInfo(<></>)
  }

  useEffect(() => {
    changeLayout()
    changeInfo(<></>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLayout])

  const buttonAddContact = (
    <DefaultBtn
      onClick={() =>
        changeInfo(
          <FormContact
            {...props}
            onClose={() => changeInfo(<></>)}
            handleReload={handleReload}
          />,
        )
      }
    >
      Adicionar contacto{' '}
      <i style={{ fontSize: '1.4rem' }} className="fas fa-plus"></i>
    </DefaultBtn>
  )
  function handleReload() {
    setreload(!reload)
    changeInfo(<></>)
  }

  return (
    <>
      <H2>Lista de contactos</H2>
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {btns.activate && (
        <>
          {btns.activate.id === 1 && (
            <TableOptions
              param={`/contact/${window.localStorage.getItem('id')}/contacts`}
              header={header}
              buttonAdd={buttonAddContact}
              choice={true}
              handleReload={reload}
              handleInfo={(user, reload) =>
                changeInfo(
                  <ViewUser
                    {...props}
                    user={user}
                    handleReload={reload}
                    rol={
                      isSocialnetwork.length > 0 ? isSocialnetwork[0].rol : ''
                    }
                  />,
                )
              }
            />
          )}
          {btns.activate.id === 2 && <InstitutionsContainer {...props} />}
        </>
      )}
    </>
  )
}

export const ViewUser = (props) => {
  const { user, changeInfo, handleReload, rol, onClose } = props

  return (
    <>
      <Icon
        handleModal={() =>
          changeInfo(
            <FollowUp
              {...props}
              user={user}
              onCancel={() => changeInfo(<ViewUser {...props} />)}
            />,
          )
        }
      >
        Notas de seguimiento
      </Icon>
      <br />
      <br />
      {rol === 'socialnetwork' && (
        <>
          <Icon
            handleModal={() =>
              changeInfo(
                <ReallocateSell
                  {...props}
                  user={user}
                  onCancel={() => changeInfo(<ViewUser {...props} />)}
                />,
              )
            }
          >
            Reasignar vendedor
          </Icon>
          <br /> <br />
        </>
      )}
      <Icon
        handleModal={() =>
          changeInfo(
            <CoursesInterested
              {...props}
              user={user}
              handleReload={handleReload}
              onClose={() => changeInfo(<ViewUser {...props} />)}
            />,
          )
        }
      >
        Cursos interesados
      </Icon>
      <br />
      <br />
      <H2 textAlign="center">Detalles del contacto</H2>
      <SubtitleData subtitle="Nombres">{user.name}</SubtitleData>
      <SubtitleData subtitle="Apellidos">{user.lastname}</SubtitleData>
      <SubtitleData subtitle="Correo electronico">{user.email}</SubtitleData>
      <SubtitleData subtitle="Carnet de identidad">
        {user.ci ? `${user.ci} ${user.expeditionCi}` : ''}
      </SubtitleData>
      <SubtitleData subtitle="Número de celular">{user.phone}</SubtitleData>
      <SubtitleData subtitle="Edad">{user.age}</SubtitleData>
      <SubtitleData subtitle="Descripción">{user.description}</SubtitleData>

      {/* TODO cambiar solo para que el rol de redes sociales pueda ver */}
      {/* {rol === 'socialnetwork' && ( */}
      <SubtitleData subtitle="Fecha de creación">
        {convertDate(user.creationDate, 'LLLL')}
      </SubtitleData>
      <SubtitleData subtitle="Medio de información">
        {user.informationMedium}
      </SubtitleData>

      {/* {
        "contactId": 1001,
        "name": "beatriz",
        "lastname": "calle",
        "ci": "1313134131",
        "expeditionCi": "CB",
        "phone": "63975543",
        "email": "chavezbea16@gmail.com",
        "programNames": null,
        "description": "",
        "observation": "",
        "assignedTo": null,
        "assignedBy": null,
        "age": "33",
        "currentInstitution": "",
        "priority": "Alta",
        "interestLevel": "3",
        "profession": "",
        "informationMedium": null,
        "userExternalId": null,
        "creationDate": "2022-01-20T13:33:20.8421716",
        "updateDate": "2022-01-20T15:46:10.0554846",
        "availableTimes": "0;0;0",
        "city": ""
    } */}
      <ActionButton
        onClick={() =>
          changeInfo(
            <FormContact
              {...props}
              user={capitalizeJson(user)}
              onClose={() => changeInfo(<ViewUser {...props} user={user} />)}
            />,
          )
        }
        onCancel={onClose ? onClose : () => changeInfo(<></>)}
        textBtn="Editar contacto"
      />
    </>
  )
}

const FormContact = (props) => {
  const { openSnackbar, handleReload, user, onClose } = props
  const [load, setload] = useState(true)
  const [errorField, setErrorField] = useState('Field')

  const element = document.getElementById(errorField)

  useEffect(() => {
    if (element) {
      element.style.border = '2px solid var(--red)'
    }
    return () => {
      if (element) {
        element.style.border = '1px solid #b1b1b1'
      }
    }
  }, [errorField, element])
  function errorMessage(error) {
    switch (error) {
      case 'phone_or_email_required':
        setErrorField('Email')
        return `El correo electrónico o teléfono es requerido`
      case 'contact_email_exist':
        setErrorField('Email')
        return `EL correo electrónico ya fue registrado a otro contacto`
      case 'contact_phone_exist':
        setErrorField('Phone')
        return `EL teléfono ya fue registrado a otro contacto`
      case 'contactUser_notFound':
        setErrorField('')
        return `No puedes editar contactos de otros vendedores`
      default:
        return 'Hubo un error al registrar el contacto'
    }
  }

  const onSubmit = async (values, action) => {
    const changeType = (state) => {
      return state ? 1 : 0
    }
    const availableTimes = `${changeType(values.Morning)};${changeType(
      values.Afternoon,
    )};${changeType(values.Night)}`
    const data = {
      ...values,
      availableTimes,
    }
    try {
      setload(false)
      await action(user ? `/contact/${user.ContactId}` : '/contact', data).then(
        (response) => {
          setload(true)
          if (validateStatus(response.status)) {
            openSnackbar('Se guardo el contacto', true, true)
            handleReload()
          } else {
            openSnackbar(errorMessage(response.data), true, false)
          }
        },
      )
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }

  return (
    <GlobalForm
      InputsJson={user ? InputsEditContact : InputsAddContact}
      textBtn={user ? 'Editar' : 'Adicionar'}
      title={user ? 'Editar contacto' : 'Adicionar contacto'}
      data={user ? user : {}}
      onSubmit={(values) => onSubmit(values, user ? putAction : postAction)}
      load={load}
      onCancel={onClose}
    />
  )
}

const FollowUp = (props) => {
  const { user, changeInfo, onCancel } = props
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/contact/${user.contactId}/followUps`,
  })
  if (loading) {
    return <Loading />
  } else {
    return (
      <>
        <H2 textAlign="center">Lista de notas de seguimiento</H2>
        {validateArray(response) ? (
          response.map((notes) => (
            <CardFollowUp
              {...props}
              user={user}
              notes={notes}
              key={notes.id}
              reload={reload}
            />
          ))
        ) : (
          <H3>No tienes notas</H3>
        )}
        <ActionButton
          onClick={() =>
            changeInfo(
              <FormFollowUp
                {...props}
                user={user}
                handleReload={() =>
                  changeInfo(
                    <FollowUp
                      {...props}
                      onCancel={() => changeInfo(<ViewUser {...props} />)}
                    />,
                  )
                }
              />,
            )
          }
          onCancel={onCancel}
          textBtn="Crear nota"
        />
      </>
    )
  }
}

const CardFollowUp = (props) => {
  const { notes, changeInfo, changeModal, reload } = props

  async function onDelete() {
    try {
      await deleteAction(`/followUp/${notes.id}`).then((response) => {
        if (validateStatus(response.status)) {
          openSnackbar('Se elimino con exito', true, true)
          reload()
        } else {
          openSnackbar('No se pudo eliminar', true, false)
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <H4 margin="0 0 5px">{convertD(notes.creationDate, 'LLL')}</H4>
      <div className="CardFollowUp">
        <div
          style={{
            padding: '10px',
            background: 'var(--white)',
            marginBottom: '20px',
            borderRadius: '5px',
            boxShadow: '0 0 3px rgba(0,0,0,0.2)',
          }}
          className="CardFollowUp__card"
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <H4 margin="0">{notes.description}</H4>
              {notes.followDate && (
                <H4 margin="10px 0 0">
                  <strong>Fecha:</strong> {convertD(notes.followDate, 'LLLL')}
                </H4>
              )}
            </div>
            <IconOptions
              content={
                <>
                  <ButtonIcon
                    content="Editar"
                    icon="edit"
                    onClick={() =>
                      changeInfo(
                        <FormFollowUp
                          {...props}
                          followUp={capitalizeJson(notes)}
                          handleReload={() =>
                            changeInfo(
                              <FollowUp
                                {...props}
                                onCancel={() =>
                                  changeInfo(<ViewUser {...props} />)
                                }
                              />,
                            )
                          }
                        />,
                      )
                    }
                  />
                  <ButtonIcon
                    content="Eliminar"
                    icon="trash"
                    onClick={() =>
                      changeModal(
                        true,
                        '¿Estás seguro que quieres eliminar la nota?',
                        '',
                        'Eliminar',
                        () => onDelete(),
                      )
                    }
                  />
                </>
              }
              noBackground={true}
              icon="ellipsis-v"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const FormFollowUp = (props) => {
  const { openSnackbar, handleReload, followUp, user } = props
  const [load, setload] = useState(true)
  const onSubmit = async (values, action) => {
    try {
      setload(false)
      await action(
        followUp
          ? `/followUp/${followUp.Id}`
          : `/contact/${user.contactId}/followup`,
        values,
      ).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Se guardo la nota', true, true)
          handleReload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }

  return (
    <GlobalForm
      InputsJson={InputsFollowUp}
      textBtn={followUp ? 'Editar' : 'Crear'}
      title={followUp ? 'Editar nota' : 'Crear nota'}
      data={followUp ? followUp : {}}
      onSubmit={(values) => onSubmit(values, followUp ? putAction : postAction)}
      load={load}
      onCancel={handleReload}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
})(Contacts)
