import { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeLayout } from '../../../../store/actions'
import { ViewHeader } from '../../../../containers/catalogue'
const EnglishCourseData = {
  title: 'CURSOS DE INGLÉS PARA NIÑOS',
  videosUrl: [
    'https://capacitaglobal.org/media/assets/vid_ingles1.mp4',
    'https://capacitaglobal.org/media/assets/vid_ingles2.mp4',
  ],
  courses: [
    {
      id: 1,
      color: '#EE7C38',
      label: 'Cursos para niños de 4 a 6 años',
      image:
        'https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472656/assets/Group_2274_u24sj2_uafdpe.png',
    },
    {
      id: 2,
      color: '#6A3081',
      label: 'Cursos para niños de 7 a 9 años',
      image:
        'https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472657/assets/Group_2275_ecstlv_xeuzgb.png',
    },
    {
      id: 3,
      color: '#007E6C',
      label: 'Cursos para niños de 10 a 14 años',
      image:
        'https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472637/assets/Group_2391_nsmgkv_t0aujl.png',
    },
  ],
}
const EnglishCourse = ({ changeLayout }) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])
  return <ViewHeader dataCourse={EnglishCourseData} link="course-english" />
}

export default connect(null, { changeLayout })(EnglishCourse)
