import Circle from "./Circle";
const LoadCircle = () => {
  return (
    <div style={{ margin: "auto", textAlign: "center" }}>
      <Circle size="80px" semicircle={true} time="3s" />
    </div>
  );
};

export default LoadCircle;
