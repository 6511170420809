import './UserTracking.css'
import { useEffect } from 'react'
import { useAxiosAuth } from '../../../hooks'
import { H2, H3 } from '../../../components/text'
import { Loading } from '../../../components/animation'
import { BoxFlex } from '../../../components/boxes'
import { Icon } from '../../../components/buttons'
import { FormDefault } from '../../../components/form'
import { validateArray } from '../../../utils/Validation'
import { convertD } from '../../../utils/ConvertDate'
import { changeLayout, changeModal, openSnackbar } from '../../../store/actions'
import { connect } from 'react-redux'
const UserTracking = (props) => {
  const {
    match: { params },
    changeLayout,
    changeModal,
    openSnackbar,
  } = props

  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/userTracking/course/${params.id}`,
  })

  useEffect(() => {
    changeLayout('FullScreen')
  }, [changeLayout])

  function handleReload(data) {
    window.open(data, '_blank')
    changeModal(
      true,
      'Reporte del módulo',
      <a
        style={{ textAlign: 'center', color: 'var(--orange2)' }}
        href={data}
        target="_blank"
        rel="noopener noreferrer"
      >
        <H3 textAlign="center" color="var(--orange2)">
          Enlace del PDF
        </H3>
      </a>,
      null,
      null,
    )
  }

  function handleReport(moduleId) {
    changeModal(
      true,
      'Lista de los temas avanzados',
      <FormDefault
        inputs={[
          {
            name: 'Topics',
            initial: [''],
            validate: null,
            control: 'listInput',
            label: 'Temas avanzados',
            buttonLabel: 'Adicionar tema',
          },
        ]}
        onCancel={() => changeModal(false, '', null)}
        data={{}}
        // data={capitalizeJson(data)}
        method="POST"
        url={`/teacherReport/${moduleId}/reportPDF`}
        openSnackbar={openSnackbar}
        handleReload={handleReload}
        // processData={processData}
        // title="Adicionar documento"
      />,
      null,
      null,
    )
  }

  return (
    <div>
      <H2>Reporte del curso</H2>
      {loading ? (
        <Loading />
      ) : (
        <>
          <H2>{response.name}</H2>
          {validateArray(response.moduleList) ? (
            response.moduleList.map((item) => (
              <div key={item.id} className="">
                <BoxFlex>
                  <H3 margin="0" textAlign="center" textTransform="uppercase">
                    {item.name}
                  </H3>
                  <Icon
                    handleModal={() => handleReport(item.id)}
                    ico="download"
                  >
                    {/* Descargar informe */}
                  </Icon>
                </BoxFlex>
                <H3>Fecha de inicio: {convertD(item.startDate, 'LL')}</H3>
                <H3>
                  Fecha de finalización: {convertD(item.finishDate, 'LL')}
                </H3>
                <div className="UserTracking__list">
                  {validateArray(item.listUser) ? (
                    item.listUser.map((user) => (
                      <ReportUser key={user.id} user={user} />
                    ))
                  ) : (
                    <H3>No hay usuarios inscritos</H3>
                  )}
                </div>
                <br />
                <br />
              </div>
            ))
          ) : (
            <H3>No hay módulos</H3>
          )}
        </>
      )}
      <br />
    </div>
  )
}

const ReportUser = (props) => {
  const { user } = props

  return (
    <div className="ReportUser">
      <div className="CardAttendance__link">
        <div
          style={{
            backgroundImage: `url(${
              user.photoUrl
                ? user.photoUrl
                : 'https://res.cloudinary.com/pataformacgi/image/upload/v1655472643/assets/pngegg_pmvwzb_vl86bj.png'
            })`,
          }}
          className="CardAttendance__img"
        ></div>
        <H3
          textTransform="capitalize"
          textAlign="center"
          fontWeight="500"
          margin="5px 0"
        >
          {user.name}
          <br />
          {user.lastName}
        </H3>
        <H3 textAlign="center" margin="0 0 5px">
          Asistencia: {user.report.assistance}/{user.report.classTotal}
        </H3>
        <H3 textAlign="center" margin="0 0 5px">
          Faltas: {user.report.faultsTotal}
        </H3>
        <H3 textAlign="center" margin="0 0 5px">
          Participación: {user.report.participation}
        </H3>
        <H3 textAlign="center" margin="0 0 5px">
          Tarea: {user.report.taskTotal}
        </H3>
        {/* {description && ( */}
        {/* <>
            <H3 fontWeight="500" margin="5px 0">
              <span style={{ fontWeight: '700' }}>Observación:</span>{' '}
              {user.userTracking.observation}
            </H3>
            <H3 fontWeight="500" margin="5px 0">
              <span style={{ fontWeight: '700' }}>Evaluación:</span>{' '}
              {user.userTracking.subjectEvaluation}
            </H3>
          </> */}
      </div>
    </div>
  )
}

export default connect(null, {
  changeLayout,
  changeModal,
  openSnackbar,
})(UserTracking)
