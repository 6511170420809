import "./Account.css";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeInfo, changeLayout } from "../../store/actions";
import {
  NormalButton,
  RenderButton,
  ButtonNavigation,
} from "../../components/buttons";
import {
  Perfil,
  InformationPersonal,
  Preference,
  Certificates,
} from "./Perfil";
import {
  ChangePassword,
  ChangeImage,
  EditPerfil,
  TablePreregistration,
} from "../../containers/account";
import { useState } from "react";
import { H2 } from "../../components/text";
import { BoxFlex } from "../../components/boxes";
const Account = (props) => {
  const { changeLayout, changeInfo } = props;
  const { t } = useTranslation("account");
  useEffect(() => {
    changeInfo(<Certificates {...props} />);
    changeLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeInfo, changeLayout]);
  const [btns, setbtns] = useState({
    btn: [
      { id: 1, name: "Perfil" },
      { id: 2, name: "Preferencias" },
      { id: 3, name: "Pre-registros" },
    ],
    activate: {
      id: 1,
    },
  });
  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }));
  }
  function toCallE() {
    document
      .getElementById("infor")
      .scrollIntoView({ block: "start", behavior: "smooth" });
    props.changeInfo(
      <>
        {/* <ChangeImage />
        <br />
        <br /> */}
        <EditPerfil />
      </>
    );
  }
  function handleCertificate() {
    changeInfo(<Certificates {...props} />);
  }
  function toCall() {
    document
      .getElementById("infor")
      .scrollIntoView({ block: "start", behavior: "smooth" });
    props.changeInfo(<ChangePassword />);
  }

  return (
    <div className="Account">
      <BoxFlex margin="0 0 5px" justify="space-between">
        <H2
          margin="0"
          fontSize="2.5rem"
          color="var(--gray)"
          width="fit-content"
          className="Account__titleSpn"
        >
          Mi perfil
        </H2>
        <BoxFlex margin="0 0 10px">
          <RenderButton
            background="var(--white)"
            color="var(--orange2)"
            borderRadius="10px"
            border="1px solid var(--orange2)"
            onClick={handleCertificate}
          >
            Mis certificados
          </RenderButton>
          <RenderButton borderRadius="10px" onClick={toCall}>
            {t("changePassword")}
          </RenderButton>
        </BoxFlex>
      </BoxFlex>
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {btns.activate ? (
        <>
          {btns.activate.id === 1 && (
            <div className="Account__data">
              <div className="Account__perfil">
                <Perfil />
              </div>
              <div className="Account__info">
                <InformationPersonal />
              </div>
              <BoxFlex justify="flex-end" margin="0 20px 0">
                <RenderButton
                  borderRadius="10px"
                  padding="10px 20px"
                  fontSize="1.8rem"
                  onClick={toCallE}
                >
                  <i className="fas fa-edit"></i> {t("acEdit")}
                </RenderButton>
              </BoxFlex>
            </div>
          )}
          {btns.activate.id === 2 && (
            <>
              <h4 className="Account__span">{t("accountPreferences")}</h4>
              <Preference />
            </>
          )}
          {btns.activate.id === 3 && (
            <>
              <h4 className="Account__span">Tabla de Pre-registro</h4>
              <TablePreregistration />
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default connect(null, {
  changeInfo,
  changeLayout,
})(Account);
