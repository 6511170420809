import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../../components/input'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NormalButton } from '../../components/buttons'
import { changePassword } from '../../services/action/UsuarioAction'
import { useState } from 'react'
import { Loading } from '../../components/animation'
import { changeInfo, openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'
import './style/ChangePassword.css'

const ChangePassword = (props) => {
  const [load, setload] = useState(true)
  const { t } = useTranslation('Preference')
  const { changeInfo, openSnackbar, sesionUser } = props

  const initialValues = {
    CurrentPassword: '',
    NewPassword: '',
    ConfNewPassword: '',
  }
  const validationSchema = Yup.object({
    CurrentPassword: Yup.string()
      .required(t('fieldObligatory'))
      .min(4, t('charactersRequired')),
    NewPassword: Yup.string()
      .required(t('fieldObligatory'))
      .min(4, t('charactersRequired')),
    ConfNewPassword: Yup.string()
      .required(t('fieldObligatory'))
      .min(4, t('charactersRequired'))
      .oneOf([Yup.ref('NewPassword'), null], 'Las contraseñas no coinciden'),
  })

  function save(res) {
    setload(true)
    if (res.status === 200) {
      openSnackbar('Registro Exitoso', true, true)
      changeInfo(<></>)
    } else {
      openSnackbar('Error al guardar', true)
    }
  }

  const onSubmit = async (values) => {
    console.log('Form data', values)
    try {
      setload(false)
      const id = sesionUser.user.id
      await changePassword(id, {
        NewPassword: values.NewPassword,
        CurrentPassword: values.CurrentPassword,
        UsernameOrEmail: sesionUser.user.email,
      }).then((response) => {
        console.log(' response', response)
        save(response)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <div className="ChangePassword">
      <h2>{t('changePassword')}</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="password"
                label={t('oldPassword')}
                name="CurrentPassword"
              />
              <FormikControl
                control="input"
                type="password"
                label={t('newPassword')}
                name="NewPassword"
              />
              <FormikControl
                control="input"
                type="password"
                label={t('repeatPassword')}
                name="ConfNewPassword"
              />
              {load ? (
                <div className="ChangePassword__btn">
                  <NormalButton type="submit" disabled={!formik.isValid}>
                    {t('change')}
                  </NormalButton>
                  <button
                    type="reset"
                    onClick={() => changeInfo(<></>)}
                    className="ChangePassword__btnC"
                  >
                    {t('cancel')}
                  </button>
                </div>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
const mapDispatchToProps = {
  changeInfo,
  openSnackbar,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ChangePassword))
