import { useEffect } from 'react'
import { connect } from 'react-redux'
// import { FooterComponent } from './Home'
import { H3 } from '../../components/text'
import { changeLayout } from '../../store/actions'

const AboutUs = (props) => {
  const { changeLayout } = props
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  return (
    <div>
      <div id="aboutUs" className="NewHome__aboutUs">
        <div className="NewHome__AText">
          <H3 color="var(--green)" fontSize="2.5rem" fontWeight="700">
            ¿QUIENES SOMOS?
          </H3>
          <p>
            Capacita Global Internacional es una empresa multidisciplinaria. el
            objetivo principal es brindar una plataforma virtual donde las
            personas y las empresas puedan acceder a un sistema de capacitación
            y formación desde la web, de manera responsable, amigable y fácil.
            para este efecto, y en el transcurso de estos años, hemos
            establecido un equipo de docentes de gran trayectoria, cuya
            experiencia es conocida dentro del sistema universitario boliviano.
            trasciende las fronteras, aportando un excelente nivel de calidad en
            la resolución de problemas actuales. estamos dedicados al desarrollo
            profesional, personal y empresarial a través del servicio de
            capacitaciones virtuales y semipresenciales. creando y desarrollando
            competencias específicas en nuestros usuarios del pregrado, posgrado
            y formación integral del talento humano en las empresas que lo
            requieran.
          </p>
        </div>
        <img
          className="NewHome__AImage"
          src="https://res.cloudinary.com/pataformacgi/image/upload/v1674760871/assets/QUIENES_SOMOS_ev9azr.png"
          alt=""
        />
      </div>
      <div className="NewHome__listSection">
        <CardSection
          title="MISIÓN"
          color="var(--purple)"
          img="https://res.cloudinary.com/pataformacgi/image/upload/v1655472658/assets/Group_2288_as8jtu_e9cojg.png"
          text="Ofrecer servicios de capacitación virtual de alta calidad a personas y empresas en diferentes áreas (académicas y empresariales), personalizadas y acorde a sus necesidades para fortalecer y mejorar sus competencias laborales, favoreciendo el crecimiento profesional y humano."
        />
        <CardSection
          title="VISIÓN"
          color="var(--orange2)"
          img="https://res.cloudinary.com/pataformacgi/image/upload/v1655472636/assets/Group_2289_vmtver_svetd9.png"
          text="Ser la empresa líder en la oferta y desarrollo de procesos de capacitación virtual de excelencia en el mercado virtual y semipresencial, que contribuya con innovación constante y de alta calidad a la resolución de las necesidades del cliente de manera amigable, fácil y con la mejor tecnología disponible de acceso en el mercado."
        />
        <CardSection
          title="VALORES"
          color="var(--green)"
          img="https://res.cloudinary.com/pataformacgi/image/upload/v1655472636/assets/Group_2290_kq4tvf_kzrz1c.png"
          text={
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                listStyleType: 'none',
                gap: '3px',
              }}
            >
              <li>Profesionalismo</li>
              <li>Responsabilidad</li>
              <li>Colaboración</li>
              <li>Calidad</li>
              <li>Honestidad</li>
              <li>Servicio</li>
              <li>Respeto</li>
            </ul>
          }
        />
      </div>
    </div>
  )
}

const CardSection = ({ title, color, img, text }) => {
  return (
    <div className="CardSection">
      <div className="CardSection__head">
        <img src={img} alt="" />
        <h3 style={{ fontSize: '2rem', color: color }}>{title}</h3>
      </div>
      <div className="CardSection__text">{text}</div>
    </div>
  )
}

export default connect(null, {
  changeLayout,
})(AboutUs)
