import { NewUserProgram } from '../../../components/form'
import { BackIcon } from '../../../components/buttonsIcon'
import { useState } from 'react'
import { TableOptions } from '../../../components/table'
import NormalButton from '../../../components/buttons/NormalButton'
const FormAddUser = ({ pid, onClose, changeInfo, type, changeModal, iid }) => {
  const [state, setstate] = useState({ step: 1, user: '' })
  function handelStep(data, st) {
    setstate({
      user: data,
      step: st,
    })
  }

  const selectedUser = (user) => {
    handelStep({ ...user, name: user.firstName }, 2)
    changeModal(false, <></>)
  }
  const header = [
    { key: 'firstName', name: 'Nombres' },
    { key: 'lastName', name: 'Apellidos' },
    { key: 'email', name: 'Correo' },
    { key: 'telephone', name: 'Teléfono' },
  ]
  const listOrder = [
    { key: 'created', name: 'Fecha de creación' },
    { key: 'lastName', name: 'Apellidos' },
    { key: 'lastActive', name: 'Última conexión' },
  ]
  return (
    <div>
      {state.step === 2 && <BackIcon onClick={() => setstate({ step: 1 })} />}
      <h2>Adicionar Usuario</h2>
      {state.step === 1 && (
        <>
          <NormalButton
            fontSize="1.5rem"
            width="60%"
            onClick={() => {
              changeModal(
                true,
                <div className="UserInstitution">
                  <TableOptions
                    param={`/institution/${iid}/users`}
                    header={header}
                    listOrder={listOrder}
                    choice={true}
                    /* handleReload={reload} */
                    handleInfo={selectedUser}
                  />
                </div>,
                () => {},
              )
            }}
          >
            Buscar
          </NormalButton>
          <NormalButton
            fontSize="1.5rem"
            width="60%"
            /* disabled={!Formik.isValid} */
            onClick={() => handelStep({ ...state.user }, 2)}
            background={true}
            type="button"
          >
            Usuario nuevo
          </NormalButton>
        </>
      )}
      {state.step === 2 && (
        <NewUserProgram
          type={type}
          user={state.user}
          program={{ id: pid }}
          onClose={onClose}
          changeInfo={changeInfo}
        />
      )}
    </div>
  )
}

export default FormAddUser
