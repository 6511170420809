import { Link } from "react-router-dom";
import "./style/LinkNav.css";
const LinkNav = (props) => {
  const { to, icon, children } = props;
  return (
    <Link to={to} className="LinkNav">
      <i className={`fas fa-${icon} nav__linkIcon`}></i>
      <span className="nav__linkText">{children}</span>
    </Link>
  );
};

export default LinkNav;
