import "./style/CardIcon.css";
import { Link } from "react-router-dom";
const CardIcon = (props) => {
  return (
    <Link to={props.url} className="CardIcon">
      <i className={`fas fa-${props.ico}`}></i>
      <h4>{props.children}</h4>
    </Link>
  );
};

export default CardIcon;
