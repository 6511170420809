import * as Yup from "yup";
import { useState } from "react";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import { openSnackbar } from "../../../../../store/actions";
import { UseOnSubmit } from "../../../../../hooks/useOnSubmit";
import { Loading } from "../../../../../components/animation";
import { FormikControl } from "../../../../../components/input";
import { NormalButton, ActionButton } from "../../../../../components/buttons";
import { CompModal } from "../../../../../components/modal";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";

const QuizLesson = (props) => {
  const { modal, dataLock, convertD, handelQuiz, handelModal, contentData } =
    props;

  return (
    <>
      {modal ? (
        <CompModal onClose={() => handelModal()} closeOutside={true}>
          <div className="ContentQuiz__modal">
            {!dataLock(contentData.finishDatePerUser) ||
            !contentData.unlimited ? (
              <>
                {contentData.lessonType === "quizTeacher" ? (
                  <div>
                    <h2>Excelente, validarás el contenido del curso</h2>
                    <h2 style={{ color: "var(--green)" }}>
                      {contentData.name}
                    </h2>
                    <FormEditUser {...props} />
                  </div>
                ) : (
                  <>
                    <h2>Excelente, validarás todo lo aprendido</h2>
                    <h2>{contentData.name}</h2>
                    <ul>
                      <li>
                        {contentData?.attemps === "1" ||
                        contentData.attemps === undefined ? (
                          <>
                            Puedes presentar el examen{" "}
                            <strong style={{ color: "var(--green)" }}>
                              sólo una vez
                            </strong>
                            .
                          </>
                        ) : (
                          <>
                            Puedes presentar el examen{" "}
                            <strong style={{ color: "var(--green)" }}>
                              {contentData.attemps}
                            </strong>{" "}
                            veces.
                          </>
                        )}
                      </li>
                      <li>
                        Antes de dar el examen{" "}
                        <strong style={{ color: "var(--green)" }}>
                          debes ver los videos
                        </strong>
                        .
                      </li>
                      <li>
                        Te sugerimos que veas las{" "}
                        <strong style={{ color: "var(--green)" }}>
                          grabaciones de las clases
                        </strong>{" "}
                        para estar mejor preparado.
                      </li>
                      <li>
                        Tienes{" "}
                        <strong style={{ color: "var(--green)" }}>
                          {contentData.timeLimit} minutos
                        </strong>{" "}
                        para responder.
                      </li>
                      <li>
                        Para aprobar necesitas una{" "}
                        <strong style={{ color: "var(--green)" }}>
                          nota del {contentData.minimumGrade}%
                        </strong>
                      </li>
                    </ul>
                    <div className="ContentQuiz__btns">
                      <NormalButton
                        onClick={() => handelModal(contentData.id)}
                        background={true}
                      >
                        Regresar más tarde
                      </NormalButton>
                      <NormalButton onClick={() => handelQuiz(contentData.id)}>
                        {contentData.lessonType === "quizTeacher"
                          ? "Presentar"
                          : "Presentar examen"}
                      </NormalButton>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <h2 style={{ color: "var(--orange2)" }}>{contentData.name}</h2>
                <h2>
                  Fecha Limite:{" "}
                  {convertD(contentData.finishDatePerUser, "LLLL")}
                </h2>
                <h2>El tiempo para responder su examen ha expirado</h2>
                <div className="ContentQuiz__btns">
                  <NormalButton
                    onClick={() => handelModal(contentData.id)}
                    background={true}
                  >
                    Cerrar
                  </NormalButton>
                </div>
              </>
            )}
          </div>
        </CompModal>
      ) : null}
    </>
  );
};

const FormEditUser = (props) => {
  const {
    sesionUser,
    sesionUser: { user },
    handelModal,
    contentData,
    handelQuiz,
    openSnackbar,
  } = props;

  const [load, setload] = useState(false);
  const [phone, setPhone] = useState(undefined);

  async function onSubmit(values) {
    setload(true);
    // const codeNumber = parsePhoneNumber(phone);
    // console.log(codeNumber);
    const data = {
      ...values,
      // Country: values.Number,
      // Country: codeNumber.country,
      // CountryCode: values.Number,
      // CountryCode: codeNumber.countryCallingCode,
      // NationalNumber: values.Number,
      // NationalNumber: codeNumber.nationalNumber,
      // Number: codeNumber.number,
    };
    // console.log(data);
    await UseOnSubmit({
      axiosParams: {
        method: "PUT",
        url: `/users/${window.localStorage.getItem("id")}/UpdateUser`,
        data: data,
      },
      openSnackbar: openSnackbar,
      textSuccess: "Guardado con éxito",
      textWrong: "No se pudo guardar",
      functionSuccess: () => handelQuiz(contentData.id),
    });
    setload(false);
  }

  if (!user) {
    return null;
  }
  return (
    <div style={{ paddingRight: "10px" }}>
      <Formik
        initialValues={{
          // Email: user?.email ?? "",
          Name: user?.firstName ?? "",
          LastName: user?.lastName ?? "",
          // Gender: user?.gender ?? "",
          // DateOfBirth: Date.parse("15/01/2023"),
          // Nickname: validateVar(us.nickname),
          // Address1: user?.Address ?? "",
        }}
        validationSchema={Yup.object({
          Name: Yup.string().required("Campo requerido"),
          LastName: Yup.string().required("Campo requerido"),
          paisIndex: Yup.string().required("Campo requerido"),
          estado_lbl: Yup.string().required("Campo requerido"),
          Number: Yup.string().required("Campo requerido"),
          Gender: Yup.string().required("Campo requerido"),
        })}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Nombres"
                name="Name"
              />
              <FormikControl
                control="input"
                type="text"
                label="Apellidos"
                name="LastName"
              />
              <FormikControl
                control="getSelect"
                label="País"
                name="paisIndex"
                url={`/Country`}
                keyOption="name"
                value="code"
              />
              {formik.values.paisIndex ? (
                <FormikControl
                  control="getSelect"
                  label="Departamento"
                  name="estado_lbl"
                  url={`/Country/${formik.values.paisIndex}/cities`}
                  keyOption="name"
                  value="name"
                />
              ) : null}
              <FormikControl
                control="input"
                type="text"
                label="Número de celular"
                name="Number"
              />
              {/* <label className="EditPerfil__label">Número de celular</label>
              <PhoneInput
                placeholder="Ingresa tu numero"
                value={phone}
                onChange={setPhone}
              /> */}
              <FormikControl
                control="select"
                label="Genero"
                name="Gender"
                options={[
                  { key: "Seleccionar genero", value: "" },
                  { key: "Femenino", value: "female" },
                  { key: "Masculino", value: "male" },
                ]}
              />
              <FormikControl
                control="input"
                type="date"
                label="Fecha de nacimiento"
                name="DateOfBirth"
              />
              <FormikControl
                control="input"
                type="text"
                label="Título/grado académico"
                name="academicDegree"
              />
              <FormikControl
                control="input"
                type="text"
                label="Cargo/posición"
                name="position"
              />
              <FormikControl
                control="input"
                type="text"
                label="Dirección"
                name="address"
              />
              {!load ? (
                <ActionButton
                  // onClick={() => onSubmit(formik.values, InputsJson)}
                  type="submit"
                  onCancel={handelModal}
                  disabled={!formik.isValid}
                  textBtn="Guardar"
                  textCancel="Cancelar"
                />
              ) : (
                <Loading />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, { openSnackbar })(QuizLesson);
