import "./TableDefault.css";
import { useState } from "react";
import { validateArray } from "../../utils/Validation";
import { useSize } from "../../hooks";
import CellMobile from "./CellMobile";
import DataType from "./DataType";
const TableMain = ({
  header,
  main,
  handleInfo,
  reload,
  keyOrder = "",
  borderBottom = false,
  forMobile = false,
  colorCell = {
    validate: () => false,
  },
}) => {
  const [target, currentSize] = useSize();
  const [activate, setactivate] = useState(null);
  const limitSize = 425;
  function HandleActivate(index, us) {
    setactivate(index);
    if (handleInfo) {
      handleInfo(us, reload);
    }
  }
  function TableFordesk() {
    return (
      <>
        <div
          className="TableDefault__header"
          style={{ gridTemplate: `auto / repeat(${header.length}, 1fr)` }}
        >
          {validateArray(header)
            ? header.map((a, i) => (
                <h2 key={i} className="TableDefault__head">
                  {a.name}
                </h2>
              ))
            : null}
        </div>
        <div className="TableDefault__main">
          {validateArray(main)
            ? main
                .sort((a, b) => a[keyOrder] - b[keyOrder])
                .map((head, i) => (
                  <div
                    key={i}
                    style={
                      colorCell.validate(head, header)
                        ? {
                            background: colorCell.background,
                            color: colorCell.color,
                            gridTemplate: `auto / repeat(${header.length}, 1fr)`,
                          }
                        : {
                            gridTemplate: `auto / repeat(${header.length}, 1fr)`,
                          }
                    }
                    className={`TableDefault__cell ${
                      borderBottom ? "TableDefault__cell_borderBottom" : ""
                    }${activate === i ? "TableDefault__cell-activate" : ""}`}
                  >
                    {validateArray(header)
                      ? header.map((a, j) => (
                          <div
                            onClick={
                              a.type === "actions"
                                ? () => {}
                                : () => HandleActivate(i, head)
                            }
                            key={j}
                          >
                            <DataType a={a} head={head} reload={reload} />
                          </div>
                        ))
                      : null}
                  </div>
                ))
            : null}
        </div>
      </>
    );
  }

  function TableForMobile() {
    return (
      <>
        {validateArray(main)
          ? main.map((head, i) => {
              return (
                <CellMobile
                  key={i}
                  id={i}
                  cell={head}
                  header={header}
                  HandleActivate={HandleActivate}
                  activate={activate}
                  reload={reload}
                  colorCell={colorCell}
                />
              );
            })
          : null}
      </>
    );
  }
  return (
    <div ref={target} className="TableDefault">
      {forMobile ? (
        <TableForMobile />
      ) : currentSize.width ? (
        currentSize.width > limitSize ? (
          <TableFordesk />
        ) : (
          <TableForMobile />
        )
      ) : (
        <TableFordesk />
      )}
    </div>
  );
};
export default TableMain;
