import './style/ContentVirtualClass.css'
import { dataLock, subtractDate } from '../../utils/ConvertDate'

import { H2, H4 } from '../text'
import EditorView from '../editorjs/EditorView'

const ContentMaterial = (props) => {
  const { contentData } = props
  const {
    name,
    description,
    startDatePerUser,
    aditionalUrlResources,
  } = contentData

  const startDate = dataLock(subtractDate(startDatePerUser, 30, 'minutes'))

  return (
    <a
      className="ContentVirtualClass"
      href={startDate ? aditionalUrlResources : '#'}
      target={startDate ? '_blank' : ''}
      rel={startDate ? 'noopener noreferrer' : ''}
    >
      <div className="ContentVirtualClass__content">
        <div className="ContentHomework__head">
          <H2>{name}</H2>
          <H4
            margin="0"
            fontSize="1.6rem"
            textAlign="end"
            border="1px solid var(--gray)"
          >
            Material
          </H4>
        </div>
        {/* <IconText icon="calendar-day">
          {convertD(startDatePerUser, 'LLLL')} 
        </IconText> */}
        {description && <EditorView cutText={true} data={description} />}
      </div>
    </a>
  )
}

export default ContentMaterial
