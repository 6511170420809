import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Safeguard } from "../../routes/Safeguard";
const ProtectedRoute = (props) => {
  const {
    component: Component,
    location,
    sesionUser,
    validate,
    ...rest
  } = props;
  const { bool, route } = Safeguard(sesionUser, validate);
  function vredirect() {
    if (location.state) {
      if (location.state.from.pathname === props.path) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        if (bool) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: vredirect() ? route : "/",
                state: {
                  from: location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, null)(ProtectedRoute);
