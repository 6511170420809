import { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeLayout } from '../../../../store/actions'
import { ViewHeader } from '../../../../containers/catalogue'

const ProgrammingCourseData = {
  title: 'CURSOS DE PROGRAMACIÓN PARA NIÑOS',
  videosUrl: [
    'https://capacitaglobal.org/media/assets/vid_progra.mp4',
    'https://capacitaglobal.org/media/assets/vid_progra.mp4',
  ],
  background: '#ee7b3860',
  courses: [
    {
      id: 1,
      color: '#EE7C38',
      label: 'Cursos para niños de 7 a 9 años',
      image:
        'https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472658/assets/Group_2277_eprgz8_xgkcpt.png',
    },
    {
      id: 2,
      color: '#6A3081',
      label: 'Cursos para niños de 10 a 14 años',
      image:
        'https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472638/assets/Group_2392_fbifdj_orq4pk.png',
    },
  ],
}

const ProgrammingCourse = ({ changeLayout }) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])
  return (
    <ViewHeader dataCourse={ProgrammingCourseData} link="course-programming" />
  )
}

export default connect(null, { changeLayout })(ProgrammingCourse)
