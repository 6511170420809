import { DragList } from '../../../../../../components/beautifulDnd'
import { H4 } from '../../../../../../components/text'
import { useState } from 'react'
import './ConceptRelation.css'
const ConceptRelation = ({ concepts, setValues, name, initialValues }) => {
  const [state, setState] = useState({
    quotes: initialValues,
  })
  function handleDrag(event) {
    setState(event)
    setValues((a) => ({
      ...a,
      [name]: event.quotes,
    }))
  }
  return (
    <div className="ConceptRelation">
      <H4>Relaciona los conceptos correctamente</H4>
      <div className="ConceptRelation__main">
        <div className="ConceptRelation__List">
          {concepts.map((element) => (
            <div key={element.id} className="ConceptRelation__question">
              <span>
                <strong>{element.id}</strong>
              </span>
              <span>
                {element.FileId ? (
                  <img src={element.concept} alt="" />
                ) : (
                  element.concept
                )}
              </span>
            </div>
          ))}
        </div>
        <div className="ConceptRelation__Drag">
          <DragList
            className="ConceptRelation__drag"
            state={state}
            setState={handleDrag}
          />
        </div>
      </div>
    </div>
  )
}

export default ConceptRelation
