import { Form, Formik } from 'formik'
import React from 'react'
import { BoxFlex, ToolTip } from '../../../components/boxes'
import { FormikControl } from '../../../components/input'
import { TableFilterContainer } from '../../../components/table'
import { H2 } from '../../../components/text'
import { validateArray, validateStatus } from '../../../utils/Validation'
import * as Yup from 'yup'
import { useAxiosAuth } from '../../../hooks'
import { useState } from 'react'
import { ActionButton, RenderButton } from '../../../components/buttons'
import { Loading } from '../../../components/animation'
import { postAction } from '../../../services/action/ActionAuthorization'
export const FormInscriptionDiplomat = (props) => {
  const { openSnackbar, changeModal, onClose } = props
  const [load, setload] = useState(true)
  const [view, setview] = useState({
    status: true,
    advert: null,
  })
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: '/category/1/courses',
  })
  const diplomadosSelect = generateOptions()
  function generateOptions() {
    let programName = [{ key: 'Seleccione el diplomado', value: '' }]
    response?.programs.forEach((value) => {
      programName.push({ key: value.name, value: value.name })
    })
    return programName
  }
  const {
    response: paymentPlanData,
    loading: paymentPlanLo,
    reload: paymentPlanReload,
  } = useAxiosAuth({
    method: 'GET',
    url: 'PaymentPlan/onlyplan/104',
  })
  const paymentPlanSelect = generateOptionsPlan()
  function generateOptionsPlan() {
    let planOptions = [{ key: 'Seleccione el plan', value: '' }]
    paymentPlanData?.forEach((value) => {
      planOptions.push({ key: value, value: value })
    })
    return planOptions
  }

  const expeditionSelect = [
    {
      key: 'Expedicion:',
      value: '',
    },
    { key: 'Chuquisaca', value: 'CH' },
    { key: 'La Paz', value: 'LP' },
    { key: 'Cochabamba', value: 'CB' },
    { key: 'Oruro', value: 'OR' },
    { key: 'Potosí', value: 'PT' },
    { key: 'Tarija', value: 'TJ' },
    { key: 'Santa Cruz', value: 'SC' },
    { key: 'Beni', value: 'BE' },
    { key: 'Pando', value: 'PD' },
  ]
  const institutionSelect = [
    { key: 'Institución', value: '' },
    { key: 'Universidad Tecnológica Boliviana', value: 'UTB' },
    { key: 'Capacita Global Internacional', value: 'CGI' },
  ]
  function handleInitial(body, constant, empty) {
    if (body) {
      if (body[constant]) {
        return body[constant]
      } else {
        return empty
      }
    } else {
      return empty
    }
  }
  const initialValues = {
    name: handleInitial(view.advert, 'firstName', ''),
    lastName: handleInitial(view.advert, 'lastName', ''),
    ci: handleInitial(view.advert, 'ci', ''),
    expeditionCI: handleInitial(view.advert, 'expeditionCi', ''),
    email: handleInitial(view.advert, 'email', ''),
    phone: handleInitial(view.advert, 'telephone', ''),
    PaymentPlan: '',
    programName: '',
    amount: '',
    invoice: '',
    homeUniversity: '',
    author: '',
    institution: '',
  }
  const validationSchema = Yup.object({
    name: Yup.string().required('campo requerido'),
    lastName: Yup.string().required('campo requerido'),
    ci: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    expeditionCI: Yup.string(),
    email: Yup.string()
      .email('Formato de correo inválido')
      .required('Campo Requerido'),
    phone: Yup.number(),
    PaymentPlan: Yup.string().required('Campo requerido'),
    programName: Yup.string().required('Campo requerido'),
    amount: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo requerido'),
    invoice: Yup.number(),
    homeUniversity: Yup.string(),
    author: Yup.string(),
    institution: Yup.string().required('Campo requerido'),
  })
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction('/Program/FormDataRegister', {
        ...values,
        numIndex: '',
      }).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          if (response.data && !validateArray(response.data.error)) {
            onClose()
            openSnackbar('Estudiante Inscrito', true, true)
          } else {
            openSnackbar('Hubo errores al inscribir al estudiante', true, true)
          }
        } else {
          openSnackbar('No se pudo inscribir al estudiante', true, false)
        }
      })
    } catch (e) {
      setload(true)
    }
  }

  function searchUser(onClick) {
    changeModal(
      true,
      null,
      <TableFilterContainer
        param={`/institution/1/users`}
        header={[
          { key: 'firstName', name: 'Nombres' },
          { key: 'lastName', name: 'Apellidos' },
          { key: 'email', name: 'Correo' },
          { key: 'telephone', name: 'Teléfono' },
        ]}
        onClick={onClick}
      />,
      null,
    )
  }
  function handleAddUser(user) {
    changeModal(false, null)
    setview((a) => ({
      ...a,
      advert: {
        ...a.advert,
        ...user,
      },
    }))
  }

  return (
    <>
      <BoxFlex justify="space-between" wrap="nowrap">
        <H2 margin="0" color="var(--gray)">
          Inscribir a diplomados
        </H2>
        <ToolTip content="Buscar Estudiante">
          <div className="">
            <RenderButton onClick={() => searchUser(handleAddUser)}>
              <i className="fas fa-solid fa-user-tag"></i>
            </RenderButton>
          </div>
        </ToolTip>
      </BoxFlex>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Nombres"
                name="name"
                disabled={view.advert && view.advert.firstName}
              />
              <FormikControl
                control="input"
                type="text"
                label="Apellidos"
                name="lastName"
                disabled={view.advert && view.advert.lastName}
              />
              <FormikControl
                control="input"
                type="text"
                label="Cèdula de identidad"
                name="ci"
                disabled={view.advert && view.advert.ci}
              />
              <FormikControl
                control="select"
                label="Expedición"
                name="expeditionCI"
                options={expeditionSelect}
                disabled={view.advert && view.advert.expeditionCi}
              />
              <FormikControl
                control="input"
                type="text"
                label="Correo electrónico"
                name="email"
                disabled={view.advert && view.advert.email}
              />
              <FormikControl
                control="input"
                type="text"
                label="Télefono"
                name="phone"
                disabled={view.advert && view.advert.telephone}
              />
              {/* <FormikControl
                      control="getSelectList"
                      type="text"
                      label="Seleccione el plan"
                      name="PaymentPlan"
                      url="PaymentPlan/onlyplan/104"
                      disabled={view.advert && view.advert.PaymentPlan}
                      value="name"
                    /> */}
              <FormikControl
                control="select"
                label="Seleccione el plan"
                name="PaymentPlan"
                options={paymentPlanSelect}
                disabled={view.advert && view.advert.PaymentPlan}
              />
              <FormikControl
                control="select"
                label="Seleccione el diplomado"
                name="programName"
                options={diplomadosSelect}
                disabled={view.advert && view.advert.programName}
              />

              <FormikControl
                control="input"
                type="text"
                label="Monto"
                name="amount"
                disabled={view.advert && view.advert.amount}
              />
              <FormikControl
                control="input"
                type="text"
                label="Factura y/o Recibo"
                name="invoice"
                disabled={view.advert && view.advert.invoice}
              />
              <FormikControl
                control="input"
                type="text"
                label="Universidad de Origen"
                name="homeUniversity"
                disabled={view.advert && view.advert.homeUniversity}
              />
              <FormikControl
                control="input"
                type="text"
                label="Autor"
                name="author"
                disabled={view.advert && view.advert.author}
              />
              <FormikControl
                control="select"
                label="Institución"
                name="institution"
                options={institutionSelect}
                disabled={view.advert && view.advert.institution}
              />

              {load ? (
                <ActionButton
                  type="submit"
                  onCancel={onClose}
                  textBtn="Inscribir"
                  textCancel="Cancelar"
                />
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
