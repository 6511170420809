import styled from 'styled-components'
import { ToolTip } from '../boxes'
const IconStyle = styled.div`
  i {
    color: ${(props) => (props.color ? props.color : 'var(--green)')};
    border-radius: 5px;
    padding: ${(props) => (props.noBackground ? '5px' : '0 10px')};
    border: ${(props) =>
      props.noBackground ? 'nome' : '1px solid var(--green)'};
    font-size: 2rem;
    cursor: pointer;
  }
  & i:hover {
    background: #4695851a;
  }
`
const IconOptions = ({ icon, content, noBackground, color }) => {
  return (
    <ToolTip
      interactive={true}
      trigger="click"
      placement="right"
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {content}
        </div>
      }
    >
      <IconStyle color={color} noBackground={noBackground}>
        <i className={`fas fa-${icon}`}></i>
      </IconStyle>
    </ToolTip>
  )
}

export default IconOptions
