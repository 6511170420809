import { useState } from 'react'
import { ButtonNavigation } from '../../../../../components/buttons'
import LessonComments from './LessonComments'
import LessonMaterial from './LessonMaterial'
import LessonModules from './LessonModules'
import { openSnackbar } from '../../../../../store/actions'
import { connect } from 'react-redux'
import './style/VideoAside.css'
const buttons = [
  { id: 2, name: 'Contenido' },
  { id: 3, name: 'Materiales' },
]
const VideoAside = (props) => {
  const {
    params: { id },
    params,
    sesionUser,
    openSnackbar,
    noComments,
  } = props
  const [btns, setbtns] = useState({
    btn: noComments
      ? buttons
      : // eslint-disable-next-line no-sparse-arrays
        [
          {
            id: 1,
            name: (
              <>
                <i className="fas fa-comment-dots"></i>
                <span>Comentarios</span>
              </>
            ),
          },
          {
            id: 2,
            name: (
              <>
                <i className="fas fa-list-ol"></i>
                <span>Contenido</span>
              </>
            ),
          },
          {
            id: 3,
            name: (
              <>
                <i className="fas fa-folder-open"></i>
                <span>Materiales</span>
              </>
            ),
          },
          ,
        ],
    activate: {
      id: 2,
    },
  })
  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }))
    document.getElementById('VideoAside__Content').scrollIntoView()
  }
  return (
    <div className="VideoAside">
      <div className="VideoAside__btn">
        <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      </div>
      <div id="VideoAside__Content">
        {btns.activate ? (
          <>
            {btns.activate.id === 1 && (
              <LessonComments
                sesionUser={sesionUser}
                openSnackbar={openSnackbar}
                id={id}
              />
            )}
            {btns.activate.id === 2 && <LessonModules params={params} />}
            {btns.activate.id === 3 && <LessonMaterial params={params} />}
          </>
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
const mapDispatchToProps = {
  openSnackbar,
}
export default connect(mapStateToProps, mapDispatchToProps)(VideoAside)
