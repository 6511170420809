import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { changeLayout, changeModal, openSnackbar } from '../../store/actions'
import { useAxiosAuth } from '../../hooks'
import { convertDate } from '../../utils/ConvertDate'
import { H2, H3 } from '../../components/text'
import { Loading } from '../../components/animation'
import { CircleProgress } from '../../components/progress'
import { TabbedNavigation } from '../../components/navigation'
import { Resume, ListContacts } from '../../containers/contacts'
import { InstitutionsContainer } from '../../containers/contacts'
import './ResumeContact.css'
import { capitalizeFirstLetter } from '../../utils/Validation'

const ResumeContact = (props) => {
  const {
    changeLayout,
    sesionUser: { user },
  } = props
  const [inp, setinp] = useState({
    StartDate: null,
    EndDate: null,
  })

  const {
    response: goalsResp,
    loading: goalsLoad,
    reload: goalsReload,
  } = useAxiosAuth({
    method: 'GET',
    url: `/goal/goals?userId=${window.localStorage.getItem('id')}${
      inp.StartDate
        ? inp.EndDate
          ? `&Initial=${convertDate(
              inp.StartDate,
              'YYYY-MM-DDTHH:mm:ss',
            )}&Final=${convertDate(inp.EndDate, 'YYYY-MM-DDTHH:mm:ss')}`
          : `&Initial=${convertDate(inp.StartDate, 'YYYY-MM-DDTHH:mm:ss')}`
        : ''
    }`,
  })

  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  useEffect(() => {
    goalsReload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inp])

  return (
    <>
      <div className="ResumeContact">
        <div className="ResumeContact__user">
          <img className="ResumeContact__imgUser" src={user.photoUrl} alt="" />
          <div className="ResumeContact__main">
            <H2 fontSize="2rem" textAlign="center" color="var(--white)">
              {capitalizeFirstLetter(user.firstName)}{' '}
              {capitalizeFirstLetter(user.lastName)}
            </H2>
            <br />
            <br />
            {goalsLoad ? (
              <Loading />
            ) : (
              <>
                <H3 fontWeight="500" textAlign="center" color="var(--white)">
                  Ventas realizadas {goalsResp.goalArchieved}
                </H3>
                <H3 fontWeight="500" textAlign="center" color="var(--white)">
                  Ventas estimadas {goalsResp.goalSet}
                </H3>
                <br />
                {goalsResp.goalSet !== 0 && (
                  <CircleProgress
                    progress={
                      (goalsResp.goalArchieved * 100) / goalsResp.goalSet
                    }
                    colorCircle="var(--orange2)"
                    colorProgress="var(--green)"
                    colorBackground="#ffffffe1"
                    textColor="var(--white)"
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="ResumeContact__separe"></div>
        <div className="ResumeContact__content">
          <TabbedNavigation
            navigation={[
              {
                id: 1,
                component: <Resume inp={inp} setinp={setinp} user={user} />,
                param: 'resume',
                label: 'Resumen',
                icon: 'fas fa-chart-pie',
              },
              {
                id: 2,
                component: <ListContacts {...props} />,
                param: 'contacts',
                label: 'Contactos',
                icon: 'fas fa-address-card',
              },
              {
                id: 3,
                component: <InstitutionsContainer {...props} />,
                param: 'institutions',
                label: 'Instituciones',
                icon: 'fas fa-university',
              },
            ]}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  changeModal,
  openSnackbar,
})(ResumeContact)
