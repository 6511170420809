import { H2, H4 } from '../../../../../components/text'
import { UseOnSubmit } from '../../../../../hooks/useOnSubmit'
import { ActionButton, DefaultBtn } from '../../../../../components/buttons'
import { Loading } from '../../../../../components/animation'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'
import { FormikControl } from '../../../../../components/input'
import { CompModal } from '../../../../../components/modal'
import ListExtensionsLesson from './ListExtensionsLesson'
import { TableDefault } from '../../../../../components/table'
import { validateArray } from '../../../../../utils/Validation'
import './style/FormExtensionHomework.css'
const header = [
  { key: 'photoUrl', name: 'Avatar', type: 'img' },
  { key: 'name', name: 'Nombre Completo' },
  { key: 'email', name: 'Correo' },
  { key: 'score', name: 'Calificación' },
]
const FormExtensionHomework = (props) => {
  const { changeInfo, lid, users, openSnackbar, changeModal } = props
  const [load, setload] = useState(true)
  const initialValues = {
    OnlyUsers: [],
    ExceptUsers: [],
    Percentage: 100,
    StartDate: null,
    FinishDate: null,
    Status: false,
  }
  const validationSchema = Yup.object({
    Percentage: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .max(100, 'La cantidad máxima es de 100')
      .min(0, 'La cantidad mínima de de 0'),
    StartDate: Yup.string().required('Campo requerido').nullable(),
    FinishDate: Yup.string().required('Campo requerido').nullable(),
  })
  const onSubmit = async (values) => {
    setload(false)
    function generateUsers(users) {
      let aux = []
      users.forEach((us) => {
        aux.push(us.userId)
      })
      return aux
    }
    await UseOnSubmit({
      axiosParams: {
        method: 'POST',
        url: '/NoteWeighing',
        data: {
          ...values,
          OnlyUsers: generateUsers(values.OnlyUsers),
          ExceptUsers: generateUsers(values.ExceptUsers),
          Status: values.Status ? 'completed' : 'available',
          LessonId: lid,
        },
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Se creo la ponderación',
      textWrong: 'No se pudo crear',
      functionSuccess: () =>
        changeInfo(
          <ListExtensionsLesson
            users={users}
            openSnackbar={openSnackbar}
            changeInfo={changeInfo}
            lid={lid}
            changeModal={changeModal}
          />,
        ),
      process: true,
    })
    setload(true)
  }
  function RenderChildren(props) {
    const { values, id } = props
    return <div className="RenderChildren">{values[id].email}</div>
  }
  function CustomButton({ name, setValues, value }) {
    const [modal, setmodal] = useState(false)
    function handleInfo(user) {
      const found = value.find((element) => element.userId === user.userId)
      if (!found) {
        setValues((a) => ({ ...a, [name]: [...a[name], user] }))
      }
    }
    function handleDelete(index) {
      let aux = value
      aux.splice(index, 1)
      setValues((a) => ({ ...a, [name]: aux }))
    }
    return (
      <>
        {modal && (
          <CompModal onClose={() => setmodal(!modal)} closeOutside={true}>
            <div className="CustomButton__modal">
              <H2 textAlign="center">Editar usuarios</H2>
              <div className="CustomButton__list">
                {validateArray(value)
                  ? value.map((us, i) => (
                      <div className="CustomButton__Memail" key={us.userId}>
                        {us.email}{' '}
                        <i
                          onClick={() => handleDelete(i)}
                          className="fas fa-times"
                        ></i>
                      </div>
                    ))
                  : null}
              </div>
              <TableDefault
                handleInfo={handleInfo}
                header={header}
                main={users.homework}
              />
            </div>
          </CompModal>
        )}
        <center>
          <DefaultBtn type="button" onClick={() => setmodal(!modal)}>
            Adicionar estudiantes
          </DefaultBtn>
        </center>
      </>
    )
  }
  return (
    <div>
      <H2>Aplazar entrega</H2>
      <H4 fontSize="1.4rem" textAlign="justify">
        *Se aplicara el plazo para todo el curso a menos que agreges estuantes a
        los que quieras que se asignen plazos.
      </H4>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          console.log(formik.values)
          return (
            <Form>
              <FormikControl
                control="arrayInput"
                label="Aplazar entrega solo para:"
                name="OnlyUsers"
                renderChildren={RenderChildren}
                customButton={true}
              />
              <CustomButton
                name="OnlyUsers"
                setValues={formik.setValues}
                value={formik.values.OnlyUsers}
              />
              <br />
              <FormikControl
                control="arrayInput"
                label="Aplazar entrega excepto para:"
                name="ExceptUsers"
                renderChildren={RenderChildren}
                customButton={true}
              />
              <CustomButton
                name="ExceptUsers"
                setValues={formik.setValues}
                value={formik.values.ExceptUsers}
              />
              <br />
              <FormikControl
                control="input"
                type="number"
                label="Porcentaje de la nota"
                name="Percentage"
              />
              <FormikControl
                control="date"
                label="Fecha de inicio"
                name="StartDate"
                placeholderText="Fecha de inicio"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormikControl
                control="date"
                label="Fecha de conclusión"
                name="FinishDate"
                placeholderText="Fecha de conclusión"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormikControl
                control="input"
                type="checkbox"
                label="Activar la prórroga"
                name="Status"
              />
              {load ? (
                <ActionButton
                  onCancel={() =>
                    changeInfo(
                      <ListExtensionsLesson
                        users={users}
                        openSnackbar={openSnackbar}
                        changeInfo={changeInfo}
                        lid={lid}
                        changeModal={changeModal}
                      />,
                    )
                  }
                  textBtn="Guardar"
                  textCancel="Cancelar"
                  type="submit"
                />
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default FormExtensionHomework
