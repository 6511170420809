import "./style/LinkCard.css";
const LinkCard = (props) => {
  const { dataLink, type } = props;
  switch (type) {
    case "videourl":
      return (
        <a
          className="LinkCard"
          href={dataLink.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h4>{dataLink.description}</h4>
          <i className="fas fa-link"></i>
        </a>
      );

    case "material":
      return (
        <a
          download
          className="LinkCard"
          href={dataLink.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h4>{dataLink.description ? dataLink.description : dataLink.url}</h4>
          <i className="fas fa-link"></i>
        </a>
      );

    default:
      return null;
  }
};
export default LinkCard;
