import { useState } from 'react'
import { BoxFlex } from '../../components/boxes'

import { H3, H4 } from '../../components/text'
import { convertDate } from '../../utils/ConvertDate'
import { capitalizeFirstLetter } from '../../utils/Validation'
import UserInstitutionAdmin from '../institutions/UserInstitution/UserInstitutionAdmin'
import UserInstitutionAffiliate from '../institutions/UserInstitution/UserInstitutionAffiliate'

const General = (props) => {
  const preConfig = (list) => {
    list.forEach((l) => {
      l.programManagerName = (
        <>
          <H4 color="var(--purple)">
            {capitalizeFirstLetter(l.programManagerName)}
          </H4>{' '}
        </>
      )
      l.url = (
        <>
          <BoxFlex justify="space-around">
            <span>{l.count}</span>
          </BoxFlex>
        </>
      )

      l.quantitySold = (
        <>
          <BoxFlex justify="space-around">
            <span>{l.quantitySold}</span>
          </BoxFlex>
        </>
      )
    
    })
    return list
  }
  return (
    <div>
      <UserInstitutionAffiliate preConfig={preConfig} {...props} />
    </div>
  )
}

export default General
