import axios from "axios";
import config from "../config.json";
axios.defaults.baseURL = config.requestURL;

axios.interceptors.request.use(
  (config) => {
    const token_seguridad = window.localStorage.getItem("token_seguridad");
    if (token_seguridad) {
      config.headers.Authorization = "Bearer " + token_seguridad;
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const requestGeneric = {
  get: (url) => axios.get(url),
  post: (url, body) => axios.post(url, body),
  postEmpty: (url) => axios.post(url),
  put: (url, body) => axios.put(url, body),
  putEmpty: (url) => axios.put(url),
  delete: (url) => axios.delete(url),
};
export default requestGeneric;
