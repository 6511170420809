import { useTranslation } from "react-i18next";
const SnackbarError = ({ message }) => {
  const { t } = useTranslation("error");
  return (
    <div
      className="snackText"
      style={{ background: "#c93f3f", color: "var(--white)" }}
    >
      <i className="fas fa-times-circle"></i>
      {t(message)}
    </div>
  );
};

export default SnackbarError;
