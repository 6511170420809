import CompModal from "./CompModal";
import { NormalButton } from "../buttons";
import { withRouter } from "react-router-dom";
const PreRegisterModal = ({ handleModal, history, eid, handlebtn, gid }) => {
  function handleClose() {
    history.push(`/graduates/${gid}/enrollment/${eid}`);
    handleModal();
    handlebtn({ id: 3, name: "Pago" });
  }
  return (
    <CompModal onClose={() => handleClose()} closeOutside={false}>
      <div
        style={{
          background: "white",
          padding: "20px  10px",
          borderRadius: "10px",
        }}
      >
        <h3>Su registro ha sido exitoso</h3>
        <h4 style={{ fontSize: "1.6rem", fontWeight: "400" }}>
          Ya iniciamos sesión con los datos proporcionados y guardamos su
          proceso ahora puede continuar el proceso de inscripción.
        </h4>
        <h4 style={{ fontSize: "1.6rem", fontWeight: "400" }}>
          Si desea continuar con el proceso más tarde revise el correo donde se
          enviaron los datos de la cuenta.
        </h4>
        <NormalButton onClick={() => handleClose()}>
          Continuar con el pago
        </NormalButton>
      </div>
    </CompModal>
  );
};

export default withRouter(PreRegisterModal);
