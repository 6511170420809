import { connect } from 'react-redux'
import { useEffect } from 'react'
import { changeLayout } from '../../../../store/actions'
import { CourseInformation } from '../../../../containers/catalogue'
const InformationData = [
  {
    id: 1,
    name: 'PARA NIÑ@S de 7 a 13 AÑOS',
    image:
      'https://res.cloudinary.com/pataformacgi/image/upload/v1655472638/assets/Group_2388_ud17jf_r3li2m.png',
    color: 'var(--purple)',
    color2: 'var(--orange2)',
    section: {
      title: '¿POR QUÉ ES IMPORTANTE APRENDER AJEDREZ?',
      figure: 'rectangle4',
      content:
        'Cuando un niño/a empieza a practicar ajedrez analiza las múltiples posibilidades para ganar, desarrolla un pensamiento crítico que lo ayuda cuando deben resolver problemas y tomar decisiones con imaginación y creatividad en la vida diaria.',
    },
  },
  {
    id: 2,
    name: 'PARA NIÑ@S de 14 a 18 AÑOS',
    image:
      'https://res.cloudinary.com/pataformacgi/image/upload/v1655472637/assets/Group_2389_txwvha_kb6nid.png',
    color: 'var(--orange2)',
    color2: 'var(--green)',
    section: {
      title: '¿POR QUÉ ES IMPORTANTE APRENDER AJEDREZ?',
      figure: 'rectangle1',
      content:
        'Cuando un niño/a empieza a practicar ajedrez analiza las múltiples posibilidades para ganar, desarrolla un pensamiento crítico que lo ayuda cuando deben resolver problemas y tomar decisiones con imaginación y creatividad en la vida diaria.',
    },
  },
]

const description = [
  {
    title: 'VENTAJAS',
    figure: 'rectangle2',
    content:
      '<ul><li>Estimula las capacidades intelectuales, aumentando la concentración, la creatividad y el pensamiento lógico.</li><li>Potencia el desarrollo de las habilidades sociales (la empatía, el respeto y la comunicación).</li><li>Favorece las relaciones sociales y ayuda a los pequeños a integrarse en todo tipo de ambientes sin problema.</li><li>El niño/a puede expresar su genialidad al resolver problemas y tomar decisiones.</li></ul>',
  },
  {
    title: 'CARACTERÍSTICAS',
    figure: 'rectangle2',
    content:
      '<ul><li>Cursos presenciales 100% prácticos e interactivos.</li><li>Aulas ambientadas con materiales necesarios para la práctica de Ajedrez</li><li>Docentes especializados y con participaciones en competencias.</li><li>Seguimiento personalizado.</li></ul>',
  },
  {
    title: 'DURACIÓN',
    figure: 'rectangle3',
    content:
      '<ul><li>3 meses (curso regular)</li><li>6 meses (curso extendido)</li></ul>',
  },
]
const ProgrammingInfo = ({ changeLayout, match: { params } }) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  return (
    <div className="EnglishInfo">
      <CourseInformation
        type="Programming"
        description={description}
        didYouKnow="El ajedrez es un deporte considerado por el Comité Olímpico Internacional, ya que para jugarlo no solo se requiere destreza mental, sino la capacidad de crear estrategias y tácticas."
        course={InformationData[params.id > 4 ? 0 : params.id - 1]}
      />
    </div>
  )
}

export default connect(null, { changeLayout })(ProgrammingInfo)
