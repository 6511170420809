import './CardCategories.css'
import { withRouter } from 'react-router-dom'
import { validateArray } from '../../utils/Validation'
import { H4 } from '../../components/text'
import { Link, useHistory } from 'react-router-dom'

const CardCategories = ({ categories, flexColumn }) => {
  const images = [
    'https://res.cloudinary.com/pataformacgi/image/upload/v1655472642/assets/Untitled_mpjzuh_sr0u8z.png',
    'https://res.cloudinary.com/pataformacgi/image/upload/v1655472636/assets/Untitled_1_vsnhw4_zf314c.png',
    'https://res.cloudinary.com/pataformacgi/image/upload/v1655472636/assets/Untitled_2_jo3mwc_tetzb4.png',
    'https://res.cloudinary.com/pataformacgi/image/upload/v1655472642/assets/Untitled_mpjzuh_sr0u8z.png',
  ]

  const history = useHistory()
  const handleRedirect = (e, index) => {
    if (index === 3) {
      e.preventDefault()
      history.push('/auth/test-vocacional-register')
    }
  }
  return (
    <div className="CardCategories">
      <div
        style={{ flexDirection: flexColumn ? 'column' : '' }}
        className="CardCategories__list"
      >
        {validateArray(categories)
          ? categories.map((l, index) => {
              if (index > categories.length - 1) {
                return null
              } else {
                if (l.url.substring(0, 4) === 'http') {
                  return (
                    <a
                      href={l.url}
                      target="_blank"
                      style={{
                        background: l.color,
                      }}
                      key={index}
                      className="CardCategories__card"
                      rel="noreferrer"
                    >
                      <img src={images[index < 4 ? index : 0]} alt="" />
                      <H4 textAlign="center" margin="0" color="var(--white)">
                        {l.name}
                      </H4>
                    </a>
                  )
                } else {
                  return (
                    <Link
                      to={`/catalogue/${l.url}${
                        l.url === 'short-courses' ? '' : '/' + l.id
                      }`}
                      style={{
                        background: l.color,
                      }}
                      key={index}
                      className="CardCategories__card"
                      onClick={(e) => handleRedirect(e, index)}
                    >
                      <img src={images[index < 3 ? index : 0]} alt="" />
                      <H4 textAlign="center" margin="0" color="var(--white)">
                        {l.name}
                      </H4>
                    </Link>
                  )
                }
              }
            })
          : null}
      </div>
    </div>
  )
}

export default withRouter(CardCategories)
