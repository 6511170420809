import { ToolTip } from '../boxes'
const IconAction = (props) => {
  const { icon = 'times', color = '#c93f3f', onClick, content } = props

  if (content) {
    return (
      <ToolTip content={content}>
        <i
          style={{
            background: `${color}3e`,
            padding: '8px',
            borderRadius: '5px',
            fontSize: '1.6rem',
            color: color,
            cursor: 'pointer',
          }}
          onClick={onClick}
          className={`fas fa-${icon}`}
        ></i>
      </ToolTip>
    )
  } else {
    return (
      <i
        style={{
          background: `${color}3e`,
          padding: '8px',
          borderRadius: '5px',
          fontSize: '1.6rem',
          color: color,
          cursor: 'pointer',
        }}
        onClick={onClick}
        className={`fas fa-${icon}`}
      ></i>
    )
  }
}

export default IconAction
