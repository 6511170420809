import DefaultBtn from './DefaultBtn'
import './style/ActionButton.css'
const ActionButton = ({
  onClick,
  onCancel,
  textBtn,
  textCancel = 'Cancelar',
  type,
  disabled,
  width = '100%',
  color,
}) => {
  return (
    <div className="ActionButton">
      {onCancel && (
        <DefaultBtn
          width={width}
          onClick={onCancel}
          type="button"
          background={true}
        >
          {textCancel}
        </DefaultBtn>
      )}
      <DefaultBtn
        width={width}
        onClick={onClick}
        type={type ? type : 'button'}
        disabled={disabled ? disabled : false}
        color={color}
      >
        {textBtn}
      </DefaultBtn>
    </div>
  )
}

export default ActionButton
