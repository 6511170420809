import { useState } from 'react'
import { postAction } from '../../services/action/Action'
import { GeneralPreregister, Preregister } from '../../utils/FormParameters'
import { validateStatus } from '../../utils/Validation'
import { GlobalForm } from '../form'
import H4 from '../text/H4'
import { parsePhoneNumber } from 'react-phone-number-input'
import { Section } from '../text'
const DefaultPreregisterModal = ({ openSnackbar, onClose, interest = null }) => {
    const [load, setload] = useState(true)

    async function handleOnSubmit(values) {
        if (values.phone !== undefined) {
            const codeNumber = parsePhoneNumber(values.phone)
            let data = {
                ...values,
                Country: codeNumber.country,
                CountryCode: codeNumber.countryCallingCode,
                NationalNumber: codeNumber.nationalNumber,
                type: "tecnico-superior"
            }


            if (interest != null) {
                data = {
                    ...data,
                    ReferenceOfAcademicInterest: interest
                }
            }
            setload(false)
            await postAction(`/contact/external`, data).then((response) => {
                setload(true)
                if (validateStatus(response.status)) {
                    openSnackbar('Datos enviados', true, true)
                    onClose()
                } else {
                    openSnackbar('Error al enviar mensaje', true, false)
                }
            })
        } else {
            setload(true)
            openSnackbar('Datos del telefono incorrecto', true)
        }
    }

    return (
        <>
            <div style={{ textAlign: 'center' }} className="Contact__container_main">
                <img
                    src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472641/assets/logo_2_okkya4_zuaobh.png"
                    alt="Logotipo Capacita Global"
                />
                <H4
                    color="white"
                    fontSize="1.8rem"
                    textAlign="center"
                    fontWeight="bold"
                    margin="10px 0"
                >
                    Reserva tu cupo gratis!
                </H4>
                <Section
                    textAlign="center"
                    fontWeight="400"
                    margin="0 0 15px 0"
                    fontSize="1.5rem"
                    color="var(--white)"
                >
                    Registra tus datos para ponernos en contacto contigo!
                </Section>
                <div style={{ width: '300px', margin: '0 auto', padding: '0px', justifyContent: 'center' }}>
                    <GlobalForm
                        InputsJson={interest != null ? GeneralPreregister : Preregister}
                        textBtn="Registrar"
                        data={{}}
                        load={load}
                        onSubmit={handleOnSubmit}
                        colorBtn="var(--green)"
                        widthBtn="60%"
                        labelColor="var(--white)"
                    />
                </div>
            </div>
        </>
    )
}

export default DefaultPreregisterModal