import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../input'
import { useState } from 'react'
import { ActionButton } from '../buttons'
import { Loading } from '../animation'
import { postAction } from '../../services/action/ActionAuthorization'
import { openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'
import './style/NewUserProgram.css'
import { validateParam } from '../../utils/Validation'
import { dropdownGender } from '../../utils/Constant'
import { convertD, convertDate } from '../../utils/ConvertDate'
import { putAction } from '../../services/action/ActionAuthorization'
const NewUserProgram = (props) => {
  const {
    user,
    program,
    onClose,
    openSnackbar,
    changeInfo,
    textBtn,
    edit,
    changeModal,
    type,
  } = props
  const [load, setload] = useState(true)
  const initialValues = {
    LastName: validateParam('lastName', user),
    Name: validateParam('firstName', user),
    Ci: validateParam('ci', user),
    ExpeditionCi: validateParam('expeditionCi', user),
    Email: validateParam('email', user),
    NationalNumber: validateParam('telephone', user),
    DateOfBirth:
      user && user.dateOfBirth
        ? convertD(user.dateOfBirth, 'utc')
        : null || null,
    Gender: validateParam('gender', user),
    ProgramId: program.id,
  }
  const validationSchema = Yup.object({
    LastName: Yup.string().required('Campo Requerido'),
    Name: Yup.string().required('Campo Requerido'),
    Ci: user.email
      ? Yup.number()
          .typeError('Eso no parece un número')
          .required('Campo Requerido')
      : null,
    ExpeditionCi: user.email ? Yup.string().required('Campo Requerido') : null,
    Email: user.email
      ? Yup.string()
          .email('Formato de correo inválido')
          .required('Campo Requerido')
      : null,
    NationalNumber: user.email
      ? Yup.number()
          .typeError('Eso no parece un número de teléfono')
          .required('Campo Requerido')
          .max(99999999, 'Tu número de teléfono tiene muchos dígitos')
          .min(10000000, 'Tu número de teléfono tiene pocos dígitos')
      : null,
  })
  const dropdownOptions = [
    { key: 'Expedida en:', value: '' },
    { key: 'Chuquisaca', value: 'CH' },
    { key: 'La Paz', value: 'LP' },
    { key: 'Cochabamba', value: 'CB' },
    { key: 'Oruro', value: 'OR' },
    { key: 'Potosí', value: 'PT' },
    { key: 'Tarija', value: 'TJ' },
    { key: 'Santa Cruz', value: 'SC' },
    { key: 'Beni', value: 'BE' },
    { key: 'Pando', value: 'PD' },
  ]
  function save(res) {
    setload(true)
    if (res.status === 200) {
      openSnackbar('Registro Exitoso', true, true)
      onClose()
    } else {
      openSnackbar(res.data.title ? res.data.title : res.data, true, false)
    }
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction(`/${type ? type : 'program'}/${program.id}/EnrollUser`, {
        ...values,
        Telephone: values.NationalNumber,
        DateOfBirth: values.DateOfBirth
          ? convertDate(values.DateOfBirth)
          : values.DateOfBirth,
      }).then((response) => {
        save(response)
      })
    } catch (e) {
      setload(true)
    }
  }
  const onEditSubmit = async (values) => {
    try {
      setload(false)
      await putAction(`/users/${user.id}/UpdateRegister`, {
        ...values,
        DateOfBirth: values.DateOfBirth
          ? convertDate(values.DateOfBirth)
          : values.DateOfBirth,
      }).then((response) => {
        save(response)
      })
    } catch (e) {
      setload(true)
    }
  }

  function onValidate(values) {
    changeModal(
      true,
      '¿Estás seguro que quieres editar la información de este usuario?',
      '',
      'Editar',
      () => onEditSubmit(values),
    )
  }
  return (
    <div className="NewUserProgram">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={edit ? onValidate : onSubmit}
      >
        {(formik) => {
          console.log(formik.values)
          return (
            <Form>
              <FormikControl
                control="input"
                label="Apellidos"
                type="text"
                name="LastName"
                disabled={
                  edit ? false : validateParam('lastName', user) ? true : false
                }
              />
              <FormikControl
                control="input"
                type="text"
                label="Nombres"
                name="Name"
                disabled={
                  edit ? false : validateParam('firstName', user) ? true : false
                }
              />
              <FormikControl
                control="input"
                type="text"
                label="Documento de Identidad"
                name="Ci"
                disabled={
                  edit ? false : validateParam('ci', user) ? true : false
                }
              />
              <FormikControl
                control="select"
                label="Expedida en"
                name="ExpeditionCi"
                options={dropdownOptions}
                disabled={
                  edit
                    ? false
                    : validateParam('expeditionCi', user)
                    ? true
                    : false
                }
              />
              <FormikControl
                control="input"
                type="email"
                label="Correo Electrónico"
                name="Email"
                disabled={
                  edit ? false : validateParam('email', user) ? true : false
                }
              />
              <FormikControl
                control="input"
                type="text"
                label="Telefono de Contacto"
                name="NationalNumber"
                disabled={
                  edit ? false : validateParam('telephone', user) ? true : false
                }
              />
              <FormikControl
                control="date"
                label="Fecha de nacimiento"
                name="DateOfBirth"
                placeholderText="Fecha de nacimiento"
                dateFormat="MMMM d, yyyy"
                showMonthDropdown
                showYearDropdown
                disabled={
                  edit ? false : formik.initialValues.DateOfBirth ? true : false
                }
              />
              <FormikControl
                control="select"
                label="Genero"
                name="Gender"
                options={dropdownGender}
                disabled={
                  edit ? false : validateParam('gender', user) ? true : false
                }
              />
              {/* <NormalButton type="submit">Adicionar</NormalButton> */}
              {load ? (
                <ActionButton
                  onCancel={() => changeInfo(<></>)}
                  textBtn={textBtn ? textBtn : 'Adicionar'}
                  textCancel="Cancelar"
                  type="submit"
                />
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default connect(null, { openSnackbar })(NewUserProgram)
