import { ButtonIcon } from '../buttons'
import { Link } from 'react-router-dom'
import { LineBar } from '../progress'
import './style/CourseCard.css'
import { withRouter } from 'react-router-dom'
import CardMainOptions from './CardMainOptions'
import config from '../../config.json'

const CourseCard = (props) => {
  const {
    history,
    courseData: { id, name, programeUserToList, versionName },
  } = props

  const op = (
    <>
      <ButtonIcon
        content="Mis notas"
        onClick={() => history.push(`/program/${id}/score`)}
        icon="tasks"
      />
      <ButtonIcon
        content="Información"
        onClick={() => history.push('/programs/release')}
        icon="info-circle"
      />
    </>
  )

  const credendtials = {
    token: localStorage.getItem('token_seguridad'),
    id: localStorage.getItem('id'),
  }

  const courseContent = (
    <>
      <h3 className="CourseCard__title">
        {name} - {versionName}
      </h3>
      {programeUserToList ? (
        <>
          <div className="CourseCard__information">
            <span>{programeUserToList.currentCourseName}</span>
            <span>Unidad {programeUserToList.currentModuleId}</span>
          </div>
          <div className="CourseCard__progres">
            <LineBar
              progressline={
                programeUserToList ? programeUserToList.percentage : 50
              }
              border={true}
            />
            <span>
              {programeUserToList ? programeUserToList.percentage : '0'}%
            </span>
          </div>
        </>
      ) : null}
    </>
  )

  return (
    <CardMainOptions options={op}>
      {props.courseData.institutionName === 'UTB' ? (
        <a
          target="_self"
          href={`${config.domainURL2}/program/${id}?iduser=${credendtials.id}&token=${credendtials.token}`}
          className="CourseCard__main"
          rel="noreferrer"
        >
          {courseContent}
        </a>
      ) : (
        <Link to={`/program/${id}`} className="CourseCard__main">
          {courseContent}
        </Link>
      )}
    </CardMainOptions>
  )
}

export default withRouter(CourseCard)
