import CompModal from "./CompModal";
import "./style/StatementMain.css";
import { convertDate } from "../../utils/ConvertDate";
import Blocks from "editorjs-blocks-react-renderer";
import { DefaultBtn } from "../buttons";
const StatementMain = ({ modal, setmodal, content, children }) => {
  if (modal) {
    return (
      <CompModal onClose={() => setmodal(!modal)}>
        <div className="StatementMain">
          <div className="StatementMain__header">
            <img
              src="https://res.cloudinary.com/dzxmzxvbr/image/upload/v1614701400/si3r613tgi34yhdnbcug.png"
              alt=""
            />
            <img
              src="https://i1.wp.com/aulacapacitaglobal.com/wp-content/uploads/2020/08/cropped-LOGOTIPO-VECTOR-COLORES.png?resize=1024%2C368&ssl=1"
              alt=""
            />
          </div>
          <h2 className="StatementMain__title">
            {content.title ? content.title : "COMUNICADO"}
          </h2>
          <p className="StatementMain__date">
            LP. {convertDate(content.startDate, "L")}
          </p>
          <div className="StatementMain__content">
            {content.content && (
              <Blocks
                config={{
                  table: {
                    className: "table",
                  },
                }}
                data={JSON.parse(content.content.replace(/'/g, '"'))}
              />
            )}
          </div>
          {
            content.fileId &&
            <div className="StatementMain__img">
              <img src={content.path} alt="img" />
            </div>
          }
          {children}
          <center>
            <DefaultBtn
              width="40%"
              background={true}
              onClick={() => setmodal(!modal)}
            >
              Cerrar
            </DefaultBtn>
          </center>
          {/* <ul className="StatementMain__main">
            <li>Sres. Diplomantes:</li>
            <li>
              A partir de hoy{" "}
              <strong>miércoles 7 de abril a horas 15:00pm</strong> se habilita
              la plataforma para subir el{" "}
              <strong>
                exámen del módulo PEDAGOGÍA DE LA EDUCACIÓN SUPERIOR tema 1 con
                el 50% de la nota y el exámen 2 con el 70% de la nota con la
                Lic. Ledezma.
              </strong>{" "}
              Ambos exámenes estarán habilitados{" "}
              <strong>hasta el sábado 10 de abril a horas 23:59 pm.</strong>{" "}
              Pasado este tiempo los y las diplomantes no podrán recuperar la
              nota.
            </li>
          </ul> */}
          {/* <div className="StatementMain__fired">Gracias.</div> */}
        </div>
      </CompModal>
    );
  } else {
    return null;
  }
};

export default StatementMain;
