import "./style/Calendar.css";
import moment from "moment";
import "moment/locale/es";
import { useState, useEffect } from "react";
import { buildCalendar, dayStyles } from "../../utils/BuildCalendar";
import { CalendarHeader } from "./";
import { validateEventArray, validateEventBool } from "../../utils/ConvertDate";
import { getDay, getMount, getAgo } from "../../utils/ConvertDate";
moment.locale("es");
const Calendar = (props) => {
  const [calendar, setcalendar] = useState([]);
  const [value, setvalue] = useState(moment());
  const [events, setevents] = useState([]);
  const { calendarDates } = props;
  useEffect(() => {
    setcalendar(buildCalendar(value));
  }, [value]);
  const handleValue = (day) => {
    setvalue(day);
    setevents(validateEventArray(day, calendarDates));
  };
  return (
    <>
      <div className="Calendar">
        <CalendarHeader value={value} setvalue={setvalue} />
        <div className="Calendar__body">
          <div className="Calendar__header Calendar__week">
            {["L", "M", "M", "J", "V", "S", "D"].map((d, i) => (
              <div key={i}>{d}</div>
            ))}
          </div>
          {calendar.map((week, index) => (
            <div key={index}>
              {week.map(function (day, i) {
                return (
                  <div
                    key={i}
                    className="Calendar__day"
                    onClick={() => handleValue(day)}
                  >
                    <div className={`${dayStyles(day, value)} Calendar__text`}>
                      {/* {validateEventBool(day, calendarDates) && (
                        <div className="Calendar__point"></div>
                      )} */}
                      <span
                        className={
                          validateEventBool(day, calendarDates)
                            ? "Calendar__point"
                            : ""
                        }
                      >
                        {day.format("D").toString()}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <div className="Calendar__events">
        {events.map((l, i) => (
          <div className="TemplateAgenda__card" key={i}>
            <div className="TemplateAgenda__date">
              <h5>{getDay(l.date)}</h5>
              <h5>{getMount(l.date)}</h5>
            </div>
            <div className="TemplateAgenda__main">
              <h3>{l.name}</h3>
              <h4>
                {l.main} - {getAgo(l.date)}
              </h4>
            </div>
          </div>
        ))}
      </div>
      {/* <TemplateAgenda calendarDates={events} /> */}
    </>
  );
};

export default Calendar;
