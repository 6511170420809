import {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
  startSession,
} from '../../../../store/actions'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getProgram } from '../../../../services/action/InstitutionAction'
import { getAction } from '../../../../services/action/ActionAuthorization'
import { ButtonNavigation, ButtonIcon } from '../../../../components/buttons'
import { validateStatus } from '../../../../utils/Validation'
import {
  CourseProgram,
  InformationProgram,
  UsersProgram,
} from '../../../../containers/institutions/ProgramInstitution'
import { LoadCircle } from '../../../../components/animation'
import { H3, H2 } from '../../../../components/text'
import { BoxFlex } from '../../../../components/boxes'
import {
  FormEditProgram,
  OrderAside,
  FormEditParallel,
  FormEditDates,
  ManageCategory,
} from '../../../../containers/institutions/ProgramInstitution'
import FormCourse from '../../../../containers/institutions/institution/programInstitution/FormCourse'
import { ViewCourseSchedule } from '../../../../containers/institutions/institution/ViewCourseSchedule'
import { UsersUTB } from '../../../../containers/institutions'
import { useAxiosAuth } from '../../../../hooks'
import { LinkEnlace } from '../../../../components/buttons/LinkEnlace'
import UsersReprobados from '../../../../containers/institutions/ProgramInstitution/UsersReprobados'
const ProgramInstitution = (props) => {
  const {
    changeLayout,
    changeInfo,
    match,
    openSnackbar,
    changeModal,
    startSession,
    history,
  } = props
  const { pid, iid } = match.params
  const [request, setrequest] = useState({
    data: null,
    status: null,
  })
  const [btns, setbtns] = useState({
    btn: [
      { id: 1, name: 'Información' },
      { id: 2, name: 'Cursos' },
      { id: 3, name: 'Usuarios' },
      { id: 4, name: 'Pre registros' },
      { id: 5, name: 'Reprobados' },
    ],
    activate: {
      id: 2,
    },
  })
  useEffect(() => {
    if (request.data === null) {
      getProgram(pid).then((response) => {
        setrequest({ status: response.status, data: response.data })
      })
      changeLayout()
      changeInfo(
        <>
          <H2 textAlign="center" color="var(--gray)">
            Horario del programa
          </H2>
          <ViewCourseSchedule
            {...props}
            changeModal={changeModal}
            openSnackbar={openSnackbar}
          />
          ,
        </>,
      )
    }
  }, [pid, request, changeLayout, changeInfo])

  function handlebtn(btn) {
    changeInfo(<></>)
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }))
  }
  function reload() {
    changeInfo(<></>)
    setrequest({ data: null })
  }

  const { response: responseAllCoursee } = useAxiosAuth({
    method: 'GET',
    url: `/program/${pid}?showdeleted=true`,
  })
  console.log(responseAllCoursee)
  const [allCourses, setAllCourses] = useState(false)

  function handleAllCourses(e) {
    setAllCourses(e.target.checked)
  }

  async function handleDownload() {
    changeModal(true, 'Espere por favor...')
    await getAction(`/program/${pid}/califications/xlsx`).then((response) => {
      if (validateStatus(response.status)) {
        changeModal(
          true,
          'Notas del curso',
          <H3 textAlign="center">
            <a
              style={{ color: 'var(--green)' }}
              href={response.data.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Descargar archivo
            </a>
          </H3>,
          null,
        )
        window.open(response.data.url, '_blank')
      }
    })
  }
  const header = [
    { key: 'name', name: 'Nombre' },
    { key: 'lastName', name: 'Apellidos' },
    { key: 'authorName', name: 'Pregistrado Por' },
    { key: 'status', name: 'Estado' },
  ]
  return (
    <div className="ProgramInstitution">
      {request.data && (
        <BoxFlex justify="space-between" wrap="nowrap">
          <H3 fontSize="2rem" color="var(--black)">
            {request.data.name}
            <br />
            <span style={{ color: 'var(--gray)' }}>
              {request.data.versionName}
            </span>
          </H3>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <ViewCourseSchedule
                    {...props}
                    changeModal={changeModal}
                    openSnackbar={openSnackbar}
                  />,
                )
              }
              content="Horario"
              icon="calendar-check"
            />

            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <ManageCategory
                    pid={pid}
                    reload={reload}
                    program={request.data}
                    changeInfo={changeInfo}
                    openSnackbar={openSnackbar}
                  />,
                )
              }
              content="Asignar categoría"
              icon="layer-group"
            />
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormEditParallel
                    reload={reload}
                    openSnackbar={openSnackbar}
                    program={request.data}
                    pid={pid}
                  />,
                )
              }
              content="Asignar paralelo a un programa"
              icon="sitemap"
            />
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormEditDates
                    reload={reload}
                    openSnackbar={openSnackbar}
                    program={request.data}
                    pid={pid}
                    changeInfo={changeInfo}
                  />,
                )
              }
              content="Recorrer fechas"
              icon="calendar-alt"
            />
            <ButtonIcon
              onClick={handleDownload}
              content="Descargar notas del programa"
              icon="download"
            />
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormEditProgram
                    pid={pid}
                    program={request.data}
                    openSnackbar={openSnackbar}
                    changeInfo={changeInfo}
                    reload={reload}
                  />,
                )
              }
              content="Editar información"
              icon="edit"
            />
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormCourse
                    changeInfo={changeInfo}
                    openSnackbar={openSnackbar}
                    pid={pid}
                    // cid={course.id}
                    reload={reload}
                    // reload={() => {
                    //   changeInfo(<></>)
                    //   handleReload()
                    // }}
                  />,
                )
              }
              content="Adicionar curso"
              icon="plus"
            />
            <ButtonIcon
              onClick={() =>
                changeInfo(
                  <OrderAside
                    changeInfo={changeInfo}
                    openSnackbar={openSnackbar}
                    url={null}
                    putUrl={`/program/${pid}/orderCourses`}
                    handleReload={reload}
                    textTitle="cursos"
                    data={request.data.courses}
                  />,
                )
              }
              content="Ordenar cursos"
              icon="sort-numeric-down"
            />
            {/* FORMULARIO PARA EDITAR ELACES DE WHATSAPP */}
            {request?.data?.whatsappGroupUrl !== null &&
              request?.data?.whatsappGroupUrl !== '' &&
              request?.data?.whatsappGroupUrl !== undefined && (
                <LinkEnlace
                  content={'Enlace de WhatsApp'}
                  href={request?.data?.whatsappGroupUrl}
                  prefix="fab"
                  icon="whatsapp"
                  fontSize="16"
                />
              )}
            {/* <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormDefault
                    inputs={editWhatsAppCourseProgram}
                    onCancel={() => changeInfo(<></>)}
                    // method="PUT"
                    // url={`/course/${params.pid}`}
                    // openSnackbar={openSnackbar}
                    title="Editar enlace de whatsApp"
                    // handleReload={handleReload}
                    data={capitalizeJson(request.data)}
                    // processData={processData}
                  />,
                )
              }
              content="Editar Enlace de WhatsApp"
              icon="whatsapp"
              prefix="fab"
            /> */}
          </div>
        </BoxFlex>
      )}
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {validateStatus(request.status) && request.data ? (
        btns.activate ? (
          <>
            {btns.activate.id === 1 && (
              <InformationProgram program={request.data} />
            )}
            {btns.activate.id === 2 && (
              <>
                <BoxFlex gap="0" justifyContent="flex-end">
                  <label htmlFor="allCourses">
                    <H3 margin="0" fontWeight="600" fontSize="1.4rem">
                      Listar todos los cursos
                    </H3>
                  </label>
                  <input
                    onChange={handleAllCourses}
                    type="checkbox"
                    name="allCourses"
                    id="allCourses"
                  />
                </BoxFlex>
                <CourseProgram
                  courses={
                    allCourses
                      ? responseAllCoursee.courses
                      : request.data.courses
                  }
                  changeInfo={changeInfo}
                  openSnackbar={openSnackbar}
                  changeModal={changeModal}
                  reload={reload}
                />
              </>
            )}
            {btns.activate.id === 3 && (
              <UsersProgram
                type="program"
                url={`/program/${pid}/users`}
                iid={iid}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                changeModal={changeModal}
                pid={pid}
                program={request.data}
                startSession={startSession}
                history={history}
              />
            )}
            {btns.activate.id === 4 && (
              <UsersUTB
                param={`/PreRegisterQuiz/${props.match.params.pid}/${request.data.name}/list`}
                hideTitle={true}
                header={header}
                title={`Usuarios pregistrados a ${request.data.name.name}`}
              />
            )}
            {btns.activate.id === 5 && (
              <UsersReprobados
                type="program"
                url={`/program/${pid}/califications/reprobate`}
                iid={iid}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                changeModal={changeModal}
                pid={pid}
                program={request.data}
                startSession={startSession}
                history={history}
              />
            )}
          </>
        ) : null
      ) : (
        <LoadCircle />
      )}
    </div>
  )
}

export default connect(null, {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
  startSession,
})(withRouter(ProgramInstitution))
