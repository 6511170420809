import { useAxiosAuth } from '../../../../hooks'
import { validateArray } from '../../../../utils/Validation'
import { TypeLesson } from '../../../../utils/Constant'
import { Loading } from '../../../../components/animation'
import { H2, H3 } from '../../../../components/text'
import { BoxFlex } from '../../../../components/boxes'

const PendingQualify = (props) => {
  const { cid } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/course/${cid}/CoursePending`,
  })

  return (
    <>
      <H2 margin="0 0 10px" color="var(--gray)" textAlign="center">
        Pendientes a calificar
      </H2>
      {loading ? (
        <Loading />
      ) : (
        validateArray(response) &&
        response.map((item) => {
          if (validateArray(item.lesson)) {
            return <ModulePending key={item.idModule} module={item} cid={cid} />
          } else {
            return null
          }
        })
      )}
    </>
  )
}

const ModulePending = ({ module, cid }) => {
  console.log()
  if (validateArray(module.lesson.filter((lesson) => lesson.amount > 0))) {
    return (
      <>
        <H3 fontSize="1.4rem" margin="10px 0">
          {module.name}
        </H3>
        <div className="CourseContent__lessons">
          {module.lesson.map((lesson) => (
            <a
              key={lesson.idLesson}
              href={`/program/course/${cid}/module/1/${
                lesson.type === 'homework' ? 'home-work' : 'forum'
              }/${lesson.idLesson}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <BoxFlex justify="space-between">
                <H3 fontSize="1.4rem" margin="0">
                  Pendientes:{' '}
                  <span style={{ color: 'var(--red)' }}>{lesson.amount}</span>
                </H3>
                <H3
                  textTransform="uppercase"
                  textAlign="end"
                  fontSize="1.4rem"
                  margin="0"
                  color="var(--green)"
                >
                  {TypeLesson[lesson.type]}
                </H3>
              </BoxFlex>
              <H3 fontSize="1.4rem" color="var(--gray)" margin="10px 0 0">
                {lesson.nameLesson}
              </H3>
            </a>
          ))}
        </div>
      </>
    )
  } else {
    return null
  }
}

export default PendingQualify
