import './style/TemplateAgenda.css'
import { getDay, getMount, getAgo, isToday } from '../../utils/ConvertDate'
import { validateArray } from '../../utils/Validation'
import { Link } from 'react-router-dom'
import { ToolTip } from '../boxes'
import { ContentQuiz, ContentVirtualClass } from '../links'
import { H4 } from '../text'
const TemplateAgenda = ({ calendarDates }) => {
  function GenerateVirtualClass({ date }) {
    const contentData = {
      id: date.lessonId,
      startDatePerUser: date.startDate,
      finishDatePerUser: date.finishDate,
      ...date,
    }
    return (
      <>
        <H4 margin="8px 0 5px">Clase virtual</H4>
        <ContentVirtualClass
          contentData={contentData}
          className={`TemplateAgenda__Link virtual ${
            isToday(date.startDate) ? 'isToday' : null
          }`}
        >
          <div className="TemplateAgenda__date">
            <h5>{getDay(date.startDate)}</h5>
            <h5>{getMount(date.startDate)}</h5>
          </div>
          <div className="TemplateAgenda__main">
            <h3>{date.name}</h3>
            <h4>Reunión programada - {getAgo(date.startDate)}</h4>
          </div>
        </ContentVirtualClass>
      </>
    )
  }
  function GenerateQuiz({ date }) {
    const contentData = {
      id: date.lessonId,
      finishDatePerUser: date.finishDate,
      startDatePerUser: date.startDate,
      quizId: 0,
      ...date,
    }
    return (
      <>
        <H4 margin="8px 0 5px">Examen</H4>
        <ContentQuiz
          contentData={contentData}
          className={`TemplateAgenda__Link quiz ${
            isToday(date.finishDate) ? 'isToday' : null
          }`}
        >
          <div className="TemplateAgenda__date">
            <h5>{getDay(date.finishDate)}</h5>
            <h5>{getMount(date.finishDate)}</h5>
          </div>
          <div className="TemplateAgenda__main">
            <h3>{date.name}</h3>
            <h4>Fecha límite - {getAgo(date.finishDate)}</h4>
          </div>
        </ContentQuiz>
      </>
    )
  }
  function GenerateHomework({ date }) {
    const { courseId, moduleId, lessonId, type } = date
    return (
      <>
        <H4 margin="8px 0 5px">{type === 'forum' ? 'Foro' : 'Tarea'}</H4>
        <ToolTip content={type === 'forum' ? 'Ir al Foro' : 'Ir a la tarea'}>
          <Link
            className={`TemplateAgenda__Link homework ${
              isToday(date.finishDate) ? 'isToday' : null
            }`}
            to={`/program/course/${courseId}/module/${moduleId}/${
              type === 'forum' ? 'forum' : 'home-work'
            }/${lessonId}`}
          >
            <div className="TemplateAgenda__date">
              <h5>{getDay(date.finishDate)}</h5>
              <h5>{getMount(date.finishDate)}</h5>
            </div>
            <div className="TemplateAgenda__main">
              <h3>{date.name}</h3>
              <h4>Fecha límite - {getAgo(date.finishDate)}</h4>
            </div>
          </Link>
        </ToolTip>
      </>
    )
  }
  /* function GenerateModule({ date }) {
    const { courseId, moduleId } = date
    return (
      <>
        <H4 margin="8px 0 5px">Módulo</H4>
        <ToolTip content="Ir al módulo">
          <Link
            className="TemplateAgenda__card module"
            to={`/program/course/${courseId}/module/${moduleId}`}
          >
            <h3>{date.name}</h3>
            <div className="TemplateAgenda__date">
              <h5>
                {getDay(date.startDate)} {getMount(date.startDate)}
              </h5>
              <h4>Inicio del módulo - {getAgo(date.startDate)}</h4>
            </div>
            <br />
            <div className="TemplateAgenda__date">
              <h5>
                {getDay(date.finishDate)} {getMount(date.finishDate)}
              </h5>
              <h4>Fin del módulo - {getAgo(date.finishDate)}</h4>
            </div>
          </Link>
        </ToolTip>
      </>
    )
  } */
  return (
    <div className="TemplateAgenda">
      {/* <div className="TemplateAgenda__header">
        <h2>Eventos importantes</h2>
        <Icon handleModal={handleModal} ico="calendar-alt" />
      </div> */}
      {/* orderDates(calendarDates) */}
      {validateArray(calendarDates)
        ? calendarDates.map((date, i) => {
            switch (date.type) {
              case 'module':
                return null
              // return <GenerateModule key={i} date={date} />
              case 'forum':
              case 'homework':
                return <GenerateHomework key={i} date={date} />
              case 'virtualclass':
                return <GenerateVirtualClass key={i} date={date} />
              case 'quiz':
                return <GenerateQuiz key={i} date={date} />
              default:
                return null
            }
          })
        : null}
      {/* {modal && (
        <CompModal onClose={handleModal} closeOutside={true}>
          <div className="TemplateAgenda__modal">
            <h2>Eventos importantes</h2>
            <div className="TemplateAgenda__Mmain">
              <Calendar calendarDates={orderDates(calendarDates)} />
            </div>
          </div>
        </CompModal>
      )} */}
    </div>
  )
}

export default TemplateAgenda
