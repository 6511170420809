import { useActionParam } from '../../../../../hooks'
import { UseOnSubmit } from '../../../../../hooks/useOnSubmit'
import { validateArray, validateStatus } from '../../../../../utils/Validation'
import { convertD } from '../../../../../utils/ConvertDate'
import { H2, H4 } from '../../../../../components/text'
import {
  ActionButton,
  ButtonIcon,
  DefaultBtn,
} from '../../../../../components/buttons'
import { IconOptions } from '../../../../../components/buttonsIcon'
import { LoadCircle } from '../../../../../components/animation'
import {
  getAction,
  putAction,
  deleteAction,
} from '../../../../../services/action/ActionAuthorization'
import FormExtensionHomework from './FormExtensionHomework'
import { GlobalForm } from '../../../../../components/form'
import { useState } from 'react'
import './style/ListExtensionsLesson.css'
import * as Yup from 'yup'
const InputsEdit = [
  {
    name: 'percentage',
    initial: 0,
    validate: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .max(100, 'La cantidad máxima es de 100')
      .min(0, 'La cantidad mínima de de 0'),
    type: 'number',
    label: 'Porcentaje de la nota',
  },
  {
    name: 'status',
    initial: false,
    validate: Yup.boolean().required('Campo requerido'),
    type: 'checkbox',
    label: 'Activar la prórroga',
  },
  {
    name: 'StartDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de inicio',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
  {
    name: 'FinishDate',
    initial: null,
    validate: Yup.string().required('Campo requerido').nullable(),
    control: 'date',
    label: 'Fecha de conclusión',
    dateFormat: 'MMMM d, yyyy HH:mm',
    showTimeSelect: true,
    timeIntervals: 15,
  },
]
const ListExtensionsLesson = (props) => {
  const { lid, changeInfo, users, openSnackbar, changeModal } = props
  const { statusRequest, dataRequest, reload } = useActionParam({
    param: `lesson/${lid}/noteWeighings`,
    action: getAction,
  })
  function VerifyDelete(id) {
    changeModal(
      true,
      'Estás seguro que quieres eliminar esta prórroga',
      'Si la eliminas no tendrás forma de recuperarla',
      'Eliminar',
      () => deleteExtension(id),
    )
  }
  const deleteExtension = async (id) => {
    try {
      await deleteAction(`/noteWeighing/${id}`).then((response) => {
        if (validateStatus(response.status)) {
          openSnackbar('Se elimino la prórroga', true, true)
          reload()
        } else {
          openSnackbar('No se pudo eliminar', true, false)
        }
      })
    } catch (e) {
      console.log(e)
    }
  }
  function FormEditExtension(props) {
    const { changeInfo, openSnackbar, extension, ...rest } = props
    const [load, setload] = useState(true)
    const onSubmit = async (values) => {
      setload(false)
      const { percentage, status, ...rest } = values
      await UseOnSubmit({
        axiosParams: {
          method: 'PUT',
          url: `/noteWeighing/${extension.id}`,
          data: {
            ...rest,
            Percentage: percentage,
            Status: status ? 'completed' : 'available',
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: 'Se editó la prórroga',
        textWrong: 'No se pudo editar',
        functionSuccess: () =>
          changeInfo(
            <ListExtensionsLesson
              openSnackbar={openSnackbar}
              changeInfo={changeInfo}
              lid={lid}
              {...rest}
            />,
          ),
        process: true,
      })
      setload(true)
    }
    return (
      <GlobalForm
        InputsJson={InputsEdit}
        title="Editar prórroga"
        textBtn="Editar"
        data={{
          ...extension,
          status: extension.status === 'completed' ? true : false,
          StartDate: convertD(extension.startDate, 'utc'),
          FinishDate: convertD(extension.finishDate, 'utc'),
        }}
        onSubmit={onSubmit}
        load={load}
        onCancel={() =>
          changeInfo(
            <ListExtensionsLesson
              openSnackbar={openSnackbar}
              changeInfo={changeInfo}
              {...rest}
            />,
          )
        }
      />
    )
  }
  function FormEditUser(props) {
    const {
      lid,
      changeInfo,
      users,
      openSnackbar,
      extension,
      changeModal,
    } = props
    const [user, setuser] = useState(extension.usersList || [])
    function removeUser(us, index) {
      changeModal(
        true,
        'Estás seguro que quieres eliminar este usuario de la prórroga',
        '',
        'Eliminar',
        () => deleteUser(us, index),
      )
    }
    const deleteUser = async (us, index) => {
      try {
        await putAction('/noteWeighing/deleteUserWeighing', {
          NoteWeighingId: extension.id,
          LessonId: lid,
          UserId: us.id,
        }).then((response) => {
          if (validateStatus(response.status)) {
            openSnackbar('Se elimino el usuario', true, true)
            let aux = user
            aux.splice(index, 1)
            setuser([...aux])
          } else {
            openSnackbar('No se pudo eliminar', true, false)
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
    return (
      <div className="FormEditUser">
        <H2 textAlign="center">Usuarios con prórroga</H2>
        {validateArray(user)
          ? user.map((us, i) => (
              <div key={us.id} className="FormEditUser__user">
                <div className="FormEditUser__text">
                  <H4 margin="0" fontSize="1.4rem">
                    Correo: {us.email}
                  </H4>
                  <H4 margin="0" fontSize="1.4rem">
                    Nombre: {us.firstName} {us.lastName}
                  </H4>
                </div>
                <i
                  className="fas fa-times"
                  onClick={() => removeUser(us, i)}
                ></i>
              </div>
            ))
          : null}
        <DefaultBtn
          onClick={() =>
            changeInfo(
              <ListExtensionsLesson
                users={users}
                openSnackbar={openSnackbar}
                changeInfo={changeInfo}
                lid={lid}
                changeModal={changeModal}
              />,
            )
          }
        >
          Volver a la lista
        </DefaultBtn>
      </div>
    )
  }
  return (
    <div>
      <H2 textAlign="center">Prórrogas</H2>
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          dataRequest.map((extension) => (
            <div className="ListExtensionsLesson__card" key={extension.id}>
              <div>
                <H4 margin="0" fontSize="1.4rem">
                  Ponderación: {extension.percentage}%
                </H4>
                <H4 margin="0" fontSize="1.4rem">
                  Inicio: {convertD(extension.startDate, 'LLL')}
                </H4>
                <H4 margin="0" fontSize="1.4rem">
                  Fin: {convertD(extension.finishDate, 'LLL')}
                </H4>
              </div>
              {extension.status === 'completed' && (
                <i className="fas fa-check-circle ListExtensionsLesson__ico"></i>
              )}
              <IconOptions
                content={
                  <>
                    <ButtonIcon
                      content="Editar usuarios"
                      icon="users"
                      onClick={() =>
                        changeInfo(
                          <FormEditUser
                            lid={lid}
                            users={users}
                            extension={extension}
                            changeInfo={changeInfo}
                            openSnackbar={openSnackbar}
                            changeModal={changeModal}
                          />,
                        )
                      }
                    />
                    <ButtonIcon
                      content="Editar prórroga"
                      icon="edit"
                      onClick={() =>
                        changeInfo(
                          <FormEditExtension
                            lid={lid}
                            users={users}
                            extension={extension}
                            changeInfo={changeInfo}
                            openSnackbar={openSnackbar}
                            changeModal={changeModal}
                          />,
                        )
                      }
                    />
                    <ButtonIcon
                      content="Eliminar prórroga"
                      icon="trash"
                      onClick={() => VerifyDelete(extension.id)}
                    />
                  </>
                }
                noBackground={true}
                icon="ellipsis-v"
              />
            </div>
          ))
        ) : (
          <H4>Aun no tienes ponderaciones</H4>
        )
      ) : (
        <LoadCircle />
      )}
      <ActionButton
        onClick={() =>
          changeInfo(
            <FormExtensionHomework
              users={users}
              changeInfo={changeInfo}
              lid={lid}
              openSnackbar={openSnackbar}
              changeModal={changeModal}
            />,
          )
        }
        onCancel={() => changeInfo(<></>)}
        textBtn="Crear prórroga"
      />
    </div>
  )
}

export default ListExtensionsLesson
