import './ShortCourses.css'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { changeLayout, openSnackbar } from '../../store/actions'
import { H2, H3 } from '../../components/text'
import { DefaultBtn, RenderButton } from '../../components/buttons'
import { FooterComponent } from '../Home/Home'
import DefaultPreregisterModal from '../../components/modal/DefaultPreregisterModal'
import DefaultRenderModal from '../../components/modal/DefaultRenderModal'
const AdvancedTechnician = (props) => {
    const { changeLayout, openSnackbar, history } = props
    const [modal, setmodal] = useState(true)
    useEffect(() => {
        changeLayout('ContentOnly')
    }, [changeLayout])

    return (
        <div className="ShortCourses">
            <div className="ShortCourses__container">
                {modal && (
                    <DefaultRenderModal
                        onClose={() => setmodal(false)}
                        width="500px"
                        maxWidht="100%"
                        closeOutside={true}
                        background="linear-gradient(135deg,rgba(92, 214, 189, 1) 0%, var(--purple) 100%)"
                    >
                        <DefaultPreregisterModal
                            openSnackbar={openSnackbar}
                            onClose={() => setmodal(false)}
                        />
                    </DefaultRenderModal>
                )}
                <div className="AdvancedCourses__marginTop"></div>
                <RenderButton
                    padding="10px"
                    margin="0 10px 0"
                    fontSize="3rem"
                    background="var(--green)"
                    onClick={() => history.goBack()}
                >
                    <i className="fas fa-home"></i>
                </RenderButton>
                <div className="ShortCourses__header">
                    <div className="ShortCourses__Htext">
                        <div className="CourseSection__text" style={{ textAlign: 'left' }}>TÉCNICO <span>SUPERIOR</span></div>
                        {/* <H2 fontSize="3.8rem">¡TU FUTURO TE ESPERA!</H2> */}
                        <H3 fontSize="2rem" fontWeight="600">
                            Aprende con nuestros docentes especializados
                        </H3>
                        <div className="ShortCourses__Hbtns">
                            <DefaultBtn onClick={() => setmodal(!modal)}>
                                Regístrate gratis
                            </DefaultBtn>
                        </div>
                    </div>

                    <CardSquareImage
                        carosuel={true}
                        image={[
                            'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472648/assets/capacita_kyqppe_iqizgk.png',
                            'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472657/assets/dfdf_rwatxj_bapwzb.png',
                            'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472657/assets/capacita_main_bci8rm_cqrqsm.png',
                            'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472654/assets/capacita_main22_h7pbpx_yrcbwe.png',
                        ]}
                    />
                </div>
                <div className='margin-body'></div>
                <div className="ShortCourses__card">
                </div>
                {/* <div className="CourseSection__text" style={{ textAlign: 'center' }}>TÉCNICO <span>SUPERIOR</span></div> */}
                <div className="ShortCourses__Banner" style={{ background: 'linear-gradient(160deg,var(--purple) 1%, rgba(92, 214, 189, 1) 99%)' }}>
                    <div className="Card_list">
                        <CardAdvancedTechnician name='CONTADURÍA GENERAL' to="/tecnicosuperior/contabilidadgeneral" url="https://humanidades.com/wp-content/uploads/2016/06/informaci%C3%B3n-contable-e1558503095648.jpg" />
                        <CardAdvancedTechnician name='COMERCIO INTERNACIONAL' to="/tecnicosuperior/comerciointernacional" url="https://1.bp.blogspot.com/-WS-sVsAfSnA/WH-jCReZi_I/AAAAAAAAEH0/0X-E9V1aqz4plK7WXzxNv-rlN05BM-lUACLcB/s1600/Qu%25C3%25A9%2Bson%2Blas%2Bmuestras%2By%2Bc%25C3%25B3mo%2Brealizar%2Bsu%2Benvi%25C3%25B3%2Bal%2Bexterior.jpg" />
                    </div>
                </div>

                <H2
                    textAlign="center"
                    margin="50px 0 20px"
                    color="var(--purple)"
                    fontSize="3.8rem"
                >
                    ¿Por qué <br /> CAPACITA GLOBAL?
                </H2>
                <div className="ShortCourses__advantages">
                    <ShortCourseBenefit
                        text="Acceso 24 horas al día, sin horarios y a tu ritmo."
                        icon="far fa-clock"
                    />
                    <ShortCourseBenefit
                        text="Certificado digital personalizado por cada curso. "
                        icon="fas fa-certificate"
                    />
                    <ShortCourseBenefit
                        text="Acceso ilimitado para ver las lecciones todas las veces que necesites."
                        icon="fas fa-infinity"
                    />
                    <ShortCourseBenefit
                        text="Aprende desde la comodidad de tu hogar."
                        icon="fas fa-laptop-house"
                    />
                    <ShortCourseBenefit
                        text="Aprende con profesionales especialistas en el área."
                        icon="fas fa-chalkboard-teacher"
                    />
                    <ShortCourseBenefit
                        text="Cursos de actualización con alta demanda laboral"
                        icon="fas fa-briefcase"
                    />
                </div>
                <div style={{ height: '100px' }}></div>
                <div className="ShortCourses__section">
                    <CardSquareImage image="https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472661/assets/capacita12_ofuvmk_s8j8d0.png" />
                    <div className="ShortCourses__Htext">
                        <H2 fontSize="3.8rem" color="var(--purple)">
                            CAPACITA GLOBAL
                        </H2>
                        <H3 fontSize="2rem" fontWeight="500">
                            Capacita Global Internacional la primera plataforma virtual donde
                            los estudiantes puedan acceder a un sistema de capacitación y
                            formación, de manera responsable, amigable y sencilla
                        </H3>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        margin: '100px 0',
                        gap: '100px',
                    }}
                >
                    <div className="ShortCourses__section">
                        <div className="ShortCourses__Htext">
                            <H3 fontSize="2rem" fontWeight="500">
                                Se enfoca en la educación por resultados, dirigida a
                                especializaciones y conocimientos prácticos. Demostrarás a todos
                                que eres un profesional capaz de desafiarse a sí mismo.
                            </H3>
                        </div>
                        <CardSquareImage image="https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472653/assets/young-woman-attending-online-class_ylnkab_gli9ez.jpg" />
                    </div>
                </div>
                <FooterComponent />
            </div>
        </div >
    )
}

const CardSquareImage = ({ image, carosuel = false }) => {
    return (
        <div className="ShortCourses__Himage">
            <div
                style={{ background: 'var(--purple)', transform: 'rotate(-20deg)' }}
                className="ShortCourses__Hsquare"
            >
                <div
                    style={{
                        background: '#EE7E34',
                        transform: 'rotate(-30deg)',
                    }}
                    className="ShortCourses__Hsquare"
                >
                    <div
                        style={{
                            background: '#5CD6BD',
                            transform: 'rotate(50deg)',
                        }}
                        className="ShortCourses__Hsquare"
                    >
                        {carosuel === true ? (
                            <div className="ShortCourses__Imagecontainer">
                                <ul>
                                    {image.map((item, index) => (
                                        <li key={index}>
                                            <img src={item} alt="" />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div className="ShortCourses__Imageonly">
                                <img src={image} alt="" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ShortCourseBenefit = ({ icon, text }) => {
    return (
        <div className="ShortCourseBenefit">
            <i className={icon}></i>
            <H3 margin="0" fontWeight="500">
                {text}
            </H3>
        </div>
    )
}
const CardAdvancedTechnician = ({ name, to, url }) => {
    return (
        <Link
            style={{ textDecoration: 'none' }}
            to={to}
        >
            <div className="CardShortCourseMax">
                <div className="CardImageHeader">
                    {/* <div className="icon-badge">
                        <i class="fa fa-bookmark"></i>
                    </div> */}
                    <div className='CardImageSection'>
                        <div
                            className="CardShortCourse__image"
                            style={{
                                backgroundImage: `url(${url})`,
                            }}
                        ></div>
                        <div className='bg-front-transparent'></div>
                    </div>
                </div>
                <div className="CardShortCourse__content">
                    <h3 margin="">{name}</h3>
                    {/* <div className="CardShortCourse__teacher">
                        <H3 margin="0" fontWeight="600">
                            asdasd
                        </H3>
                    </div> */}
                    {/* <br /> */}
                    {/* <H3 fontWeight="500">description</H3> */}
                </div>
            </div>
        </Link>
    )
}

export default connect(null, { changeLayout, openSnackbar })(AdvancedTechnician)
