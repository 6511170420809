import { useState, useEffect } from "react";
const useQuestion = ({ action, param }) => {
  const [request, setRequest] = useState({
    dataRequest: null,
    statusRequest: null,
  });
  useEffect(() => {
    action(param).then((response) => {
      let statusRequest = 500;
      let dataRequest = undefined;
      if (response) {
        statusRequest = response.status;
        dataRequest = response.data;
      }
      setRequest({
        dataRequest,
        statusRequest,
      });
    });
  }, [action, param]);
  return request;
};

export default useQuestion;
