import { connect } from 'react-redux'
import { useEffect } from 'react'
import { changeLayout } from '../../../../store/actions'
import { CourseInformation } from '../../../../containers/catalogue'
const InformationData = [
  {
    id: 1,
    name: 'PARA NIÑ@S de 4 a 6 AÑOS',
    image:
      'https://res.cloudinary.com/pataformacgi/image/upload/v1655472641/assets/img6_gpcldd_hl61gv.png',
    color: 'var(--purple)',
    color2: 'var(--orange2)',
    section: {
      title: '¿POR QUÉ ES IMPORTANTE APRENDER INGLÉS?',
      figure: 'message1',
      content:
        'Ayudará a los pequeños a familiarizarse cada vez más con el idioma, mejorando su capacidad de concentración y facilidad para aprender.',
    },
  },
  {
    id: 2,
    name: 'PARA NIÑ@S de 7 a 9 AÑOS',
    image:
      'https://res.cloudinary.com/pataformacgi/image/upload/v1655472639/assets/img2_cpvbhz_rh3jvj.png',
    color: 'var(--orange2)',
    color2: 'var(--green)',
    section: {
      title: 'VENTAJAS DE APRENDER INGLES',
      content:
        'El acceso al mundo permite conocer otras culturas, y el hablar otro idioma es una habilidad que permitirá mejorar su confianza.',
    },
  },
  {
    id: 3,
    name: 'PARA NIÑ@S de 10 a 14 AÑOS',
    image:
      'https://res.cloudinary.com/pataformacgi/image/upload/v1655472641/assets/img3_y4zo6b_oqmsd0.png',
    color: 'var(--green)',
    color2: 'var(--purple)',
    section: {
      title: 'SABÍAS QUE...',
      content:
        'El aprendizaje de idiomas en la niñez y la juventud desarrolla la creatividad, las habilidades multitareas y la capacidad de memoria.',
    },
  },
]

const description = [
  {
    title: 'VENTAJAS',
    figure: 'message2',
    content:
      '<ul><li>Mayor facilidad de aprendizaje.</li><li>Impacto positivo en la salud mental.</li><li>Mayor capacidad de concentración.</li><li>Acceso a un mundo globalizado.</li><li>Mayor oportunidad en sus estudios.</li><li>Generan varias tareas al mismo tiempo con eficacia.</li><li>Mejoran su atención y su memoria.</li><li>Mayor agilidad y capacidad de resolución de problemas.</li></ul>',
  },
  {
    title: 'CARACTERÍSTICAS',
    figure: 'message3',
    content:
      '<ul><li>Cursos virtuales 100% prácticos.</li><li>Plataformas de aprendizaje interactivas.</li><li>Docentes certificados en lengua nativa.</li><li>Sólo cinco alumnos por curso.</li><li>Seguimiento personalizado.</li></ul>',
  },
  {
    title: 'DURACIÓN',
    figure: 'message4',
    content:
      '<ul><li>3 MESES (CURSO INICIAL)</li><li>6 MESES (CURSO INTERMEDIO)</li><li>9 MESES (CURSO AVANZADO )</li></ul>',
  },
]

const EnglishInfo = ({ changeLayout, match: { params } }) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  return (
    <div className="EnglishInfo">
      <CourseInformation
        type="English"
        description={description}
        course={InformationData[params.id > 4 ? 0 : params.id - 1]}
      />
    </div>
  )
}
export default connect(null, { changeLayout })(EnglishInfo)
