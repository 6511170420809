import './Academic.css'
import { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useAxiosAuth } from '../../../../hooks'
import { validateArray } from '../../../../utils/Validation'
import { TypeLesson, colors, TypeStatus } from '../../../../utils/Constant'
import { convertD, dataLock } from '../../../../utils/ConvertDate'
import { LoadCircle, Loading } from '../../../../components/animation'
import {
  Icon,
  NormalButton,
  RenderButton,
} from '../../../../components/buttons'
import { LineBar } from '../../../../components/progress'
import { BoxFlex, ToolTip } from '../../../../components/boxes'
import { H2, H3, H4, SubtitleData } from '../../../../components/text'
import {
  changeInfo,
  openSnackbar,
  changeLayout,
} from '../../../../store/actions'
import { ExtendDatesLesson } from '../../../../containers/programs'
import { AnchorIcon } from '../../../../components/anchorage'
import { FormDefault } from '../../../../components/form'
import { GetAnswer } from '../../../../containers/programs/courses/modules/lessons/AnswersUserAside'
import HomeWorkComments from '../../../../components/card/HomeWorkComments'
import * as Yup from 'yup'
import { getAction } from '../../../../services/action/ActionAuthorization'

const Academic = (props) => {
  const {
    match: { params },
    changeInfo,
    changeLayout,
  } = props

  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/academic/User/${params.uid}/${params.type}/${params.pid}/progress`,
  })

  const [programInfo, setProgramInfo] = useState(
    response ? response.programId : '',
  )

  useEffect(() => {
    changeInfo(<></>)
    changeLayout()
  }, [changeInfo, changeLayout])

  useEffect(() => {
    if (response && response.programId && !loading) {
      getAction(`/program/${response.programId}`).then((res) => {
        setProgramInfo(res.data.courses.sort((a, b) => a.order - b.order))
      })
    }
  }, [response, loading])

  return (
    <>
      {loading ? (
        <LoadCircle />
      ) : (
        <>
          <H3 margin="10px 0">
            {response.userName} {response.lastName}
          </H3>

          <H3 margin="10px 0">
            {response.programName || response.courseName} -{' '}
            {response.versionName}{' '}
          </H3>
        </>
      )}

      <H2 margin="10px 0">Progreso del estudiante</H2>
      {loading ? (
        <LoadCircle />
      ) : params.type === 'Program' ? (
        validateArray(response.courseUserDetailedProgress) ? (
          response.courseUserDetailedProgress
            .sort((a, b) => a.order - b.order)
            .map((course, i) => {
              return (
                <Fragment key={i}>
                  {programInfo[i] && (
                    <BoxFlex justify="flex-start">
                      <H3
                        border="1px solid gray"
                        padding="5px"
                        borderRadius="8px"
                        fontWeight="500"
                        margin="0 0 8px"
                      >
                        Docentes:{' '}
                        <strong>{programInfo[i].educatorCounter}</strong>
                      </H3>
                      {programInfo[i].mainEducator && (
                        <H3
                          border="1px solid gray"
                          padding="5px"
                          borderRadius="8px"
                          fontWeight="500"
                          margin="0 0 8px"
                        >
                          Encargado:{' '}
                          <strong>{programInfo[i].mainEducator}</strong>
                        </H3>
                      )}
                    </BoxFlex>
                  )}
                  <RenderCourse
                    key={course.courseId}
                    course={course}
                    params={params}
                    reload={reload}
                    viewCourse={i === 0}
                    {...props}
                  />
                </Fragment>
              )
            })
        ) : (
          <H3>No tiene cursos</H3>
        )
      ) : (
        <RenderCourse
          course={response}
          params={params}
          reload={reload}
          viewCourse={true}
          {...props}
        />
      )}
    </>
  )
}
export default connect(null, { changeLayout, changeInfo, openSnackbar })(
  Academic,
)

const RenderCourse = (props) => {
  const { course, params, viewCourse = false } = props
  const [view, setview] = useState(viewCourse)
  const [moduleId, setModuleId] = useState(null)

  const { response, reload: reloadModules } = useAxiosAuth({
    method: 'GET',
    url: `/Academic/User/${params.uid}/course/${course.courseId}/moduleProgres`,
  })

  return (
    <div className="ARenderCourse">
      <div onClick={() => setview(!view)} className="ARenderCourse__header">
        <H4>{course.courseName} </H4>
        <H4>
          Nota acumulada: <strong>{course.score}</strong>
          {course.recuperatoryScore ? ' - Nota del recuperatorio: ' : null}
          {course.recuperatoryScore ? (
            <strong>{course.recuperatoryScore}</strong>
          ) : null}
        </H4>
        <LineBar border progressline={course.percentage} />
      </div>
      {view && (
        <RenderModule
          course={course}
          params={params}
          moduleUser={response}
          reloadModules={reloadModules}
          moduleId={moduleId}
          setModuleId={setModuleId}
          {...props}
        />
      )}
    </div>
  )
}
const RenderModule = (props) => {
  const { moduleUser, course, moduleId, setModuleId } = props

  return (
    <>
      <div className="ARenderModule__list">
        {validateArray(moduleUser) ? (
          moduleUser
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((mod) => (
              <ToolTip key={mod.moduleId} content={mod.name}>
                <div
                  className={`ARenderModule__item ${
                    mod.moduleId === moduleId && 'active'
                  }`}
                  style={{
                    background: `transparent linear-gradient(90deg,
                    #${mod.moduleId === moduleId ? '458e7f' : '808080'}3e ${
                      mod.percentage
                    }%, white ${mod.percentage}%`,
                  }}
                  onClick={() =>
                    setModuleId((a) =>
                      a === mod.moduleId ? null : mod.moduleId,
                    )
                  }
                >
                  <H4 margin="10px 0" textAlign="center" fontSize="1.4rem">
                    {mod.orderNumber}
                  </H4>
                </div>
              </ToolTip>
            ))
        ) : (
          <H3>No tienes módulos</H3>
        )}
      </div>
      {moduleId && (
        <RenderLesson moduleId={moduleId} course={course} {...props} />
      )}
    </>
  )
}
const RenderLesson = (props) => {
  const { changeInfo, moduleId, course, params } = props
  const { response, reload: reloadLessons, loading } = useAxiosAuth({
    method: 'GET',
    url: `/Academic/User/${params.uid}/Module/${moduleId}/lessonProgres`,
  })

  useEffect(() => {
    reloadLessons()
  }, [moduleId])

  function handleStatus(status) {
    const statusColor = {
      available: 'red',
      completed: 'yellow',
      scored: 'green',
    }
    return statusColor[status] || statusColor['gray']
  }
  function handleLesson(lesson) {
    changeInfo(
      <LessonAside
        {...props}
        course={course}
        moduleId={moduleId}
        lesson={lesson}
        lessonReload={reloadLessons}
      />,
    )
  }
  const [filters, setFilters] = useState([
    { id: 0, name: 'video', status: true, type: 'lesson' },
    { id: 1, name: 'quiz', status: true, type: 'lesson' },
    { id: 2, name: 'forum', status: true, type: 'lesson' },
    { id: 3, name: 'homework', status: true, type: 'lesson' },
    { id: 4, name: 'virtualclass', status: true, type: 'lesson' },
    { id: 5, name: 'certificate', status: true, type: 'lesson' },
    { id: 6, name: 'available', status: true, type: 'status' },
    { id: 7, name: 'completed', status: true, type: 'status' },
    { id: 8, name: 'scored', status: true, type: 'status' },
  ])
  function handleFilter(index) {
    let aux = filters
    aux[index] = { ...aux[index], status: !aux[index].status }
    setFilters([...aux])
  }
  return (
    <>
      {/* <div
        className="ListFilters__item"
        style={{ width: 'fit-content', float: 'right', fontSize: '1.6rem' }}
      >
        Eliminar filtros <i className="fas fa-times"></i>
      </div> */}
      <div className="ListFilters">
        {filters
          .filter((f) => f.type === 'lesson')
          .map((filter) => (
            <div
              className={`ListFilters__item ${filter.status && 'active'}`}
              key={filter.id}
              onClick={() => handleFilter(filter.id)}
            >
              <H4
                color={filter.status && 'var(--white)'}
                margin="0"
                fontSize="1.4rem"
              >
                {TypeLesson[filter.name]}{' '}
                <i
                  className={`fas fa-${filter.status ? 'check' : 'times'}`}
                ></i>
              </H4>
            </div>
          ))}
      </div>
      <div className="ListFilters">
        {filters
          .filter((f) => f.type !== 'lesson')
          .map((filter) => (
            <div
              className={`ListFilters__item ${filter.status && 'active'}`}
              key={filter.id}
              onClick={() => handleFilter(filter.id)}
            >
              <H4
                color={filter.status && 'var(--white)'}
                margin="0"
                fontSize="1.4rem"
              >
                {TypeStatus[filter.name]}{' '}
                <i
                  className={`fas fa-${filter.status ? 'check' : 'times'}`}
                ></i>
              </H4>
            </div>
          ))}
      </div>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .filter((f) => {
            const result = filters.filter((filter) => {
              return (
                (filter.name === f.lessonType ||
                  filter.name === f.userStatus) &&
                filter.status === true
              )
            })
            return result?.length > 1
          })
          .map((lesson) => {
            return (
              <div
                className="ARenderLesson__item"
                key={lesson.lessonId}
                onClick={() => handleLesson(lesson)}
              >
                <H4 border="1px solid transparent" margin="0" fontSize="1.6rem">
                  {lesson.name}{' '}
                  {!dataLock(lesson.userStartDate) && (
                    <i className="fas fa-lock"></i>
                  )}
                </H4>
                <div>
                  {lesson.lessonType !== 'video' &&
                  lesson.lessonType !== 'virtualclass' ? (
                    <H4
                      border="1px solid var(--gray)"
                      margin="0"
                      fontSize="1.4rem"
                    >
                      {lesson.score === -1
                        ? 'Sin calificar'
                        : lesson.score.toFixed(2)}
                    </H4>
                  ) : null}
                  {/* <H4
                    border="1px solid var(--gray)"
                    margin="0"
                    fontSize="1.4rem"
                  >
                    {TypeStatus[lesson.userStatus]}
                  </H4> */}
                  <H4
                    border="1px solid transparent"
                    background={colors[handleStatus(lesson.userStatus)]}
                    margin="0"
                    fontSize="1.4rem"
                  >
                    {TypeLesson[lesson.lessonType]}
                  </H4>
                </div>
              </div>
            )
            // }
          })
      ) : (
        <H3>No tienes lecciones</H3>
      )}
    </>
  )
}
const LessonAside = (props) => {
  const {
    match: { params },
    changeInfo,
    lesson,
    moduleId,
    course,
    lessonReload,
  } = props

  const { response: dataLesson, reload, loading } = useAxiosAuth({
    method: 'GET',
    url: `/LessonUser/lesson/${lesson.lessonId}/user/${params.uid}/detailed`,
  })

  const { response, reload: reloadLesson } = useAxiosAuth({
    method: 'GET',
    url: `lessonuser/lesson/${lesson.lessonId}/user/${params.uid}/look`,
  })

  useEffect(() => {
    if (lesson.lessonType === 'homework' || lesson.lessonType === 'quiz') {
      reloadLesson()
    }
    if (!loading) {
      reload()
    }
  }, [lesson.lessonId])

  function handleAttemp() {
    changeInfo(
      <EditAttemp
        {...props}
        dataLesson={dataLesson}
        resultQuiz={response[0]}
        reload={lessonReload}
        onCancel={() => changeInfo(<LessonAside {...props} />)}
      />,
    )
  }

  function extendDates() {
    changeInfo(
      <ExtendDatesLesson
        {...props}
        lid={lesson.lessonId}
        user={{ userId: params.uid }}
        response={{
          startDate: dataLesson.userStartDate,
          finishDate: dataLesson.userFinishDate,
        }}
        onClose={() => changeInfo(<LessonAside {...props} />)}
        reload={lessonReload}
      />,
    )
  }
  function handleViewQuiz(qid) {
    changeInfo(
      <>
        <NormalButton onClick={() => changeInfo(<LessonAside {...props} />)}>
          Regresar
        </NormalButton>
        <GetAnswer userId={params.uid} qid={qid} />
      </>,
    )
  }
  const handleGotoLections = (url) => {
    const win = window.open(url, '_blank')
    win.focus()
  }

  if (loading) {
    return <Loading />
  }
  return (
    <>
      {dataLesson.lessonType === 'quiz' && (
        <>
          <BoxFlex margin="15px 0" gap="5px" justify="flex-end">
            {validateArray(response) && (
              <RenderButton
                onClick={handleAttemp}
                background="#fff"
                color="var(--green)"
                border="1px solid var(--green)"
              >
                Editar número de intentos
              </RenderButton>
            )}
            {dataLesson.userStatus === 'scored' && (
              <RenderButton
                background="var(--green)"
                onClick={() => handleViewQuiz(response[0].lessonId)}
              >
                Ver resultados
              </RenderButton>
            )}
          </BoxFlex>
        </>
      )}
      <H2 textAlign="center">{dataLesson.name}</H2>
      <SubtitleData subtitle="Tipo de lección">
        {TypeLesson[dataLesson.lessonType]}
      </SubtitleData>
      <SubtitleData subtitle="Fecha de inicio">
        {convertD(dataLesson.userStartDate, 'LLLL')}
      </SubtitleData>
      <SubtitleData subtitle="Fecha final">
        {convertD(dataLesson.userFinishDate, 'LLLL')}
      </SubtitleData>
      {dataLesson.lessonType === 'quiz' ? (
        <SubtitleData subtitle="Número de intentos">
          {dataLesson.attempsMade
            ? `${dataLesson.attempsMade}/${dataLesson.attemps}`
            : `0/${dataLesson.attemps}`}
        </SubtitleData>
      ) : null}
      <SubtitleData subtitle="Nota">
        {dataLesson.score === -1
          ? 'Sin calificar'
          : dataLesson.score.toFixed(2)}
      </SubtitleData>
      <SubtitleData subtitle="Estado">
        {TypeStatus[dataLesson.userStatus]}
      </SubtitleData>
      {dataLesson.lessonType === 'homework' && (
        <>
          {validateArray(response?.files) && (
            <>
              <H4 margin="5px 0" fontWeight="600">
                Archivos entregados:
              </H4>
              {response?.files?.map((file) => {
                return (
                  <AnchorIcon
                    key={file.id}
                    background="var(--green)"
                    color="var(--white)"
                    url={file.url}
                    dateAdded={file.dateAdded}
                    icon="link"
                  >
                    {file.description}
                  </AnchorIcon>
                )
              })}
            </>
          )}
          {validateArray(response?.files) && (
            <HomeWorkComments homeWorkId={response.files[0].homeworkId} />
          )}
        </>
      )}
      <BoxFlex margin="15px 0" gap="5px" justify="flex-end">
        {dataLesson.userStatus === 'available' && (
          <>
            <Icon ico="calendar-day" handleModal={extendDates}>
              Ampliar fechas
            </Icon>
          </>
        )}
        <RenderButton
          onClick={() =>
            handleGotoLections(
              `/program/${params.pid}/course/${course.courseId}/module/${moduleId}/admin`,
            )
          }
        >
          Ir a las lecciones
        </RenderButton>
      </BoxFlex>
    </>
  )
}

const EditAttemp = (props) => {
  const { onCancel, reload, openSnackbar, dataLesson } = props

  return (
    <>
      <H2 color="var(--gray)" textAlign="center">
        Formulario para editar número de intentos
      </H2>
      <FormDefault
        inputs={[
          {
            name: 'AddAttemps',
            initial: 0,
            validate: Yup.number()
              .typeError('Eso no parece un número')
              .required('Campo Requerido')
              .min(0, 'La cantidad mínima de nota es 0'),
            type: 'text',
            label: '',
          },
          {
            name: 'FinishDate',
            initial: null,
            validate: Yup.string().required('Campo requerido').nullable(),
            control: 'date',
            label: 'Fecha de conclusión',
            dateFormat: 'MMMM d, yyyy HH:mm',
            showTimeSelect: true,
            timeIntervals: 15,
          },
        ]}
        onCancel={onCancel}
        method="PUT"
        url={`/LessonUser/${dataLesson.lessonUserId}/EditAttempts`}
        openSnackbar={openSnackbar}
        handleReload={() => {
          reload()
          onCancel()
        }}
        data={{
          FinishDate: convertD(dataLesson.userFinishDate, 'utc'),
          AddAttemps: dataLesson.attemps,
        }}
        processData={(values) => ({
          ...values,
          FinishDate: convertD(values.FinishDate),
        })}
      />
    </>
  )
}
