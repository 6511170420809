import { RenderModal } from '../../components/modal'
import { H2, H4 } from '../../components/text'
import { ActionButton } from '../../components/buttons'
import Home from '../Home/Home'
const Redirect = (props) => {
  return (
    <>
      <Home />
      <RenderModal onClose={() => props.history.push('/')}>
        <H2 textAlign="center">RENOVAMOS NUESTRA PAGINA!!!</H2>
        <H4 fontSize="2rem">
          Para precautelar su información es necesario cambiar tu contraseña y
          ser parte de nuestra nueva estructura
        </H4>
        <ActionButton
          onClick={() => props.history.push('/auth/recover')}
          onCancel={() => props.history.push('/')}
          textBtn="Cambiar contraseña"
          textCancel="Volver al inicio"
        />
      </RenderModal>
    </>
  )
}

export default Redirect
