import { useState } from 'react'
import './style/ProgramCourse.css'
import { ButtonNavigation, ButtonIcon } from '../../../components/buttons'
import { BoxFlex } from '../../../components/boxes'
import { Loading } from '../../../components/animation'
import { useAxiosAuth } from '../../../hooks'
import DataSubtitle from '../../../components/dataTitle/DataSubtitle'
import { H3 } from '../../../components/text'
import CourseModules from '../institution/programInstitution/CourseModules'
import ListTeachers from '../institution/programInstitution/ListTeachers'
import { validateStatus } from '../../../utils/Validation'
import { AddEducatorForm, FormNewModule } from '../../../components/form'
import {
  getAction,
  putAction,
} from '../../../services/action/ActionAuthorization'
import { OrderAside, EditCourse, SendCopy } from '.'
import { withRouter } from 'react-router-dom'
import { ViewCourseSchedule } from '../institution/ViewCourseSchedule'
import { convertDate } from '../../../utils/ConvertDate'
import FormEditDatesCourse from './FormEditDatesCourse'

const ProgramCourse = (props) => {
  const {
    course,
    changeInfo,
    openSnackbar,
    reload,
    history,
    changeModal,
    match: { params },
  } = props

  const [btns, setbtns] = useState({
    btn: [
      { id: 1, name: 'Información' },
      { id: 2, name: 'Módulos' },
      { id: 3, name: 'Docentes' },
    ],
    activate: {
      id: 0,
    },
  })
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/course/${course.id}/ungradedHomework`,
  })

  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        id: btn.id === a.activate.id ? 0 : btn.id,
      },
    }))
  }
  function handleReload() {
    setbtns((a) => ({
      ...a,
      activate: {
        id: 0,
      },
    }))
  }
  async function handleDownload() {
    changeModal(true, 'Espere por favor...')
    await getAction(`/course/${course.id}/califications/xlsx`).then(
      (response) => {
        if (validateStatus(response.status)) {
          changeModal(
            true,
            'Notas del curso',
            <H3 textAlign="center">
              <a
                style={{ color: 'var(--green)' }}
                href={response.data.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar archivo
              </a>
            </H3>,
            null,
          )
          window.open(response.data.url, '_blank')
        }
      },
    )
  }

  async function handleDelete(course) {
    changeModal(
      true,
      '¿Estás seguro que quieres eliminar el curso?',
      <Loading />,
      null,
    )
    await putAction(`/course/deleteCourse/${course.id}`).then((response) => {
      changeModal(false, null)
      if (validateStatus(response.status)) {
        openSnackbar('Se elimino el curso', true, true)
        reload()
      } else {
        openSnackbar('No se pudo eliminar', true, false)
      }
    })
  }

  async function handleInclude(course) {
    changeModal(
      true,
      '¿Estás seguro que quieres volver a incluir el curso?',
      <Loading />,
      null,
    )
    await putAction(`/course/restoreCourse/${course.id}`).then((response) => {
      changeModal(false, null)
      if (validateStatus(response.status)) {
        openSnackbar('Se guardo con éxito', true, true)
        reload()
      } else {
        openSnackbar('No se pudo guardar', true, false)
      }
    })
  }

  return (
    <div
      className="ProgramCourse"
      style={{
        background:
          course.deleteDate === '0001-01-01T00:00:00'
            ? 'var(--white)'
            : 'transparent',
        boxShadow:
          course.deleteDate === '0001-01-01T00:00:00'
            ? '0 0 5px rgba(0, 0, 0, 0.2)'
            : '',
        border:
          course.deleteDate !== '0001-01-01T00:00:00'
            ? '1px solid rgba(0, 0, 0, 0.2)'
            : '',
      }}
    >
      <BoxFlex justify="flex-start">
        <H3
          border="1px solid gray"
          padding="5px"
          borderRadius="8px"
          fontWeight="500"
          margin="0 0 8px"
        >
          Docentes: <strong>{course.educatorCounter}</strong>
        </H3>
        {course.mainEducator && (
          <H3
            border="1px solid gray"
            padding="5px"
            borderRadius="8px"
            fontWeight="500"
            margin="0 0 8px"
          >
            Encargado: <strong>{course.mainEducator}</strong>
          </H3>
        )}
        {!loading && response ? (
          <>
            <H3
              border="1px solid gray"
              padding="5px"
              borderRadius="8px"
              fontWeight="500"
              margin="0 0 8px"
            >
              Cantidad de lecciones:{' '}
              <strong style={{ color: 'var(--red)' }}>{response.lessons}</strong>
            </H3>
            <H3
              border="1px solid gray"
              padding="5px"
              borderRadius="8px"
              fontWeight="500"
              margin="0 0 8px"
            >
              Lecciones públicas:{' '}
              <strong style={{ color: 'var(--red)' }}>{response.publics}</strong>
            </H3>
            <H3
              border="1px solid gray"
              padding="5px"
              borderRadius="8px"
              fontWeight="500"
              margin="0 0 8px"
            >
              Foros sin revisar:{' '}
              <strong style={{ color: 'var(--red)' }}>{response.forum}</strong>
            </H3>
            <H3
              border="1px solid gray"
              padding="5px"
              borderRadius="8px"
              fontWeight="500"
              margin="0 0 8px"
            >
              Tareas sin revisar:{' '}
              <strong style={{ color: 'var(--red)' }}>
                {response.homework}
              </strong>
            </H3>
          </>
        ) : null}
      </BoxFlex>
      <div>
        <H3 color="var(--black)" margin="0">
          {course.name}
          <br />
          <small style={{color:'gray'}}>
            Inicio: {course.startDate != null ? convertDate(course.startDate, "LL") : ""}
          </small>
        </H3>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonIcon
            onClick={() =>
              changeInfo(
                <AddEducatorForm
                  openSnackbar={openSnackbar}
                  course={course}
                  iid={params.iid}
                  cid={course.id}
                  handleReload={reload}
                  changeModal={changeModal}
                />,
              )
            }
            content="Adicionar docente"
            icon="user-plus"
          />
          <ButtonIcon
            onClick={() =>
              changeInfo(
                <ViewCourseSchedule
                  openSnackbar={openSnackbar}
                  changeInfo={changeInfo}
                  changeModal={changeModal}
                  // match: {
                  //   params: { cid },
                  // },
                  match={{ params: { cid: course.id } }}
                />,
              )
            }
            content="Horario"
            icon="calendar-check"
          />
          <ButtonIcon
            onClick={() =>
              changeInfo(
                <FormNewModule
                  changeInfo={changeInfo}
                  openSnackbar={openSnackbar}
                  cid={course.id}
                  reload={() => {
                    changeInfo(<></>)
                    handleReload()
                  }}
                />,
              )
            }
            content="Adicionar módulo"
            icon="plus"
          />
          <ButtonIcon
            onClick={() =>
              changeInfo(
                <EditCourse
                  changeInfo={changeInfo}
                  openSnackbar={openSnackbar}
                  course={course}
                  reload={reload}
                />,
              )
            }
            content="Editar información del curso"
            icon="edit"
          />
          <ButtonIcon
            onClick={() => history.push(`/course-notes/${course.id}`)}
            content="Notas de los estudiantes"
            icon="tasks"
          />
          <ButtonIcon
            onClick={() =>
              history.push(`/courses/${course.id}/course-progress`)
            }
            content="Avance de los estudiantes"
            icon="spinner"
          />
          <ButtonIcon
            onClick={handleDownload}
            content="Descargar nota del curso"
            icon="download"
          />
          <ButtonIcon
              onClick={() =>
                changeInfo(
                  <FormEditDatesCourse
                    reload={reload}
                    openSnackbar={openSnackbar}
                    course = {course}
                    cid={course.id}
                    changeInfo={changeInfo}
                  />,
                )
              }
              content="Recorrer fechasa"
              icon="calendar-alt"
            />
          <ButtonIcon
            onClick={() =>
              changeInfo(
                <SendCopy
                  params={params}
                  course={course}
                  reload={reload}
                  changeInfo={changeInfo}
                  changeModal={changeModal}
                  openSnackbar={openSnackbar}
                />,
              )
            }
            content="Enviar copia a un programa"
            icon="share-square"
          />
          <ButtonIcon
            onClick={() =>
              changeInfo(
                <OrderAside
                  changeInfo={changeInfo}
                  openSnackbar={openSnackbar}
                  url={`/course/${course.id}/Modules`}
                  putUrl={`/course/${course.id}/orderModules`}
                  handleReload={handleReload}
                  textTitle="módulos"
                  attribute="courseModulesDto"
                />,
              )
            }
            content="Ordenar módulos"
            icon="sort-numeric-down"
          />
          {course.deleteDate === '0001-01-01T00:00:00' ? (
            <ButtonIcon
              onClick={() =>
                changeModal(
                  true,
                  '¿Estás seguro que quieres eliminar el curso?',
                  '',
                  'Eliminar',
                  () => handleDelete(course),
                )
              }
              content="Eliminar"
              icon="trash"
              color="var(--red)"
            />
          ) : (
            <ButtonIcon
              onClick={() =>
                changeModal(
                  true,
                  '¿Estás seguro que quieres volver a incluir el curso?',
                  '',
                  'Incluir',
                  () => handleInclude(course),
                )
              }
              content="Incluir"
              icon="undo"
              color="var(--red)"
            />
          )}
        </div>
      </div>
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {btns.activate ? (
        <>
          {btns.activate.id === 1 && (
            <>
              <DataSubtitle title="Descripcion" type={'section'}>
                {course.description}
              </DataSubtitle>
              <DataSubtitle title="Inicia" type={'date'}>
                {course.startDate}
              </DataSubtitle>
              <DataSubtitle title="Termina" type={'date'}>
                {course.finishDate}
              </DataSubtitle>
            </>
          )}
          {btns.activate.id === 2 && (
            <CourseModules
              changeInfo={changeInfo}
              openSnackbar={openSnackbar}
              cid={course.id}
              changeModal={changeModal}
            />
          )}
          {btns.activate.id === 3 && (
            <ListTeachers
              changeModal={changeModal}
              changeInfo={changeInfo}
              openSnackbar={openSnackbar}
              cid={course.id}
            />
          )}
        </>
      ) : null}
    </div>
  )
}

export default withRouter(ProgramCourse)
