import './FilesForLesson.css'
import { useEffect, useState } from 'react'
import { useActionParam } from '../../../../hooks'
import { H2, H4 } from '../../../../components/text'
import { Icon } from '../../../../components/buttons'
import {
  getAction,
  postAction,
} from '../../../../services/action/ActionAuthorization'
import { LoadCircle } from '../../../../components/animation'
import { FormMaterialLesson } from '../../../../components/form'
import { validateStatus, validateArray } from '../../../../utils/Validation'
const FilesForLesson = ({ lid, openSnackbar, changeInfo, changeModal }) => {
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: `lesson/${lid}`,
  })

  const [newFile, setnewFile] = useState(false)

  // useEffect(() => {
  //   if (validateStatus(statusRequest) && dataRequest && dataRequest.id !== lid) {
  //     reload();
  //   }
  // }, [lid, dataRequest, statusRequest, reload]);

  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lid])

  const deleteMaterial = async (lessonFileId) => {
    try {
      await postAction(`/Lesson/${lid}/deleteFile`, {
        LessonFileId: lessonFileId,
      }).then((response) => {
        if (validateStatus(response.status)) {
          openSnackbar('Se elimino con éxito', true, true)
        } else {
          openSnackbar('No se pudo eliminar', true, false)
        }
        reload()
      })
    } catch (e) {
      console.log(e)
    }
  }

  function askDeleting(lessonFileId) {
    changeModal(
      true,
      '¿Estas seguro que quieres eliminar este material?',
      '',
      'Eliminar',
      () => deleteMaterial(lessonFileId),
    )
  }

  function FileView({ file }) {
    const [edit, setedit] = useState(false)
    if (edit) {
      return (
        <>
          <FormMaterialLesson
            lid={lid}
            openSnackbar={openSnackbar}
            changeInfo={changeInfo}
            changeModal={changeModal}
            handleCancel={() => setedit(!edit)}
            reload={reload}
            data={file}
          />
          <br />
        </>
      )
    } else {
      return (
        <div className="FilesForLesson__file">
          <a href={file.url} download target="_blank" rel="noopener noreferrer">
            {file.description}
          </a>
          <div className="FilesForLesson__btns">
            <button
              className="FilesForLesson__btns-edit"
              onClick={() => setedit(!edit)}
            >
              <i className="fas fa-edit"></i>
            </button>
            <button
              className="FilesForLesson__btns-delete"
              onClick={() => askDeleting(file.lessonFileId)}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      )
    }
  }
  return (
    <div className="FilesForLesson">
      <H2>Material complementario</H2>
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest.filesToList) ? (
          dataRequest.filesToList.map((file) => (
            <FileView key={file.lessonFileId} file={file} />
          ))
        ) : (
          <H4>No tienes materiales</H4>
        )
      ) : (
        <LoadCircle />
      )}
      {newFile && (
        <>
          <br />
          <H4 fontWeight="600" textAlign="center">
            Subir material
          </H4>
          <FormMaterialLesson
            lid={lid}
            openSnackbar={openSnackbar}
            changeInfo={changeInfo}
            handleCancel={() => setnewFile(!newFile)}
            reload={reload}
          />
          <br />
        </>
      )}
      <Icon
        handleModal={() => setnewFile(!newFile)}
        ico={newFile ? 'times' : 'plus'}
      >
        {newFile ? 'Cancelar' : 'Añadir material'}
      </Icon>
    </div>
  )
}

export default FilesForLesson
