import './style/CircleProgress.css'
import { H3 } from '../text'
const CircleProgress = ({
  progress,
  colorCircle,
  colorProgress,
  colorBackground,
  textColor,
  children,
}) => {
  return (
    <div className="CircleProgress">
      <div className="CircleProgress__circle">
        {/* <div style={{ background: colorCircle }} id="middle-circle">
          <H3 fontWeight="600" margin="2px 0 0" color={textColor}>
            {progress}%
          </H3> */}
        <div
          className="CircleProgress__text"
          style={{ background: colorCircle }}
          id="middle-circle"
        >
          {children ? (
            children
          ) : (
            <H3 fontWeight="600" margin="2px 0 0" color={textColor}>
              {progress}%
            </H3>
          )}
        </div>
        <div
          style={{
            background: `conic-gradient(${colorProgress} ${progress}%, ${colorBackground} ${progress}%)`,
          }}
          id="progress-spinner"
        ></div>
      </div>
    </div>
  )
}

export default CircleProgress
