import { convertD } from '../../utils/ConvertDate'
import { useState } from 'react'
import './DataSubtitle.css'
import { processUrlImage } from '../../utils/ProcessData'
const DataSubtitle = ({ title, children, type }) => {
  const [section, setsection] = useState(true)
  return (
    <div className={`DataSubtitle ${type}`}>
      <h4>{title}</h4>
      {children ? (
        <>
          {type === 'text' && <h3>{children}</h3>}
          {type === 'anchor' && (
            <a href={children} target="_blank" rel="noopener noreferrer">
              {title}
            </a>
          )}
          {type === 'img' && (
            <img src={processUrlImage(children, 0, 100)} alt={title} />
          )}
          {type === 'date' && <h3>{convertD(children, 'LLL')}</h3>}
          {type === 'section' && (
            <h3>
              {section ? children.substring(0, 100) + '...' : children + '   '}
              {children.length >= 100 && (
                <span
                  onClick={() => {
                    setsection(!section)
                  }}
                >
                  {section ? 'ver más' : 'ver menos'}
                </span>
              )}
            </h3>
          )}
        </>
      ) : (
        <h3>--</h3>
      )}
    </div>
  )
}
export default DataSubtitle
