import axios from 'axios'
const instancia = axios.create()
instancia.CancelToken = axios.CancelToken
instancia.isCancel = axios.isCancel
export const getAction = (url) => {
  return new Promise((resolve, eject) => {
    instancia
      .get(url)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const postAction = (url, body) => {
  return new Promise((resolve, eject) => {
    instancia
      .post(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
export const putAction = (url, body) => {
  return new Promise((resolve, eject) => {
    instancia
      .put(url, body)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        console.log(error.response)
        resolve(error.response)
      })
  })
}
