import { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormikControl, DropzoneInput } from "../input";
import { ActionButton } from "../buttons";
import { Loading } from "../animation";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import "./style/CreateLesson.css";

const dropdownEducator = [
  { key: "Tipo de contenido:", value: "" },
  { key: "Examen", value: "LessonQuiz" },
  { key: "Video", value: "LessonVideo" },
  { key: "Tarea", value: "LessonHomework" },
  { key: "Clases virtuales", value: "LessonVirtualclass" },
  { key: "Foro", value: "LessonForum" },
  { key: "Material", value: "Materials" },
  // { key: 'Clases virtuales con Jitsi', value: 'Jitsi' },
];
const dropdownOptions = [
  ...dropdownEducator,
  { key: "Evaluación docentes", value: "quizTeacher" },
  // { key: 'Certificado', value: 'LessonCertificate' },
];

const dropdownMaterial = [
  { key: "Enlace", value: "enlace" },
  { key: "Archivo", value: "archivo" },
];

const dropdownExtension = [
  { key: "Archivo PDF", value: ".pdf" },
  { key: "Archivo Word", value: ".docx" },
  { key: "Enlace", value: "url" },
  { key: "Archivo excel", value: ".xlsx, .xls, .csv" },
  { key: "Audio", value: "audio/*" },
  { key: "Todo tipo de archivos", value: "" },
];
const CreateLesson = (props) => {
  const { openSnackbar, mid, reload, modules, rol, changeInfo } = props;
  const [load, setload] = useState(true);
  const initialValues = {
    module: mid,
    content: "",
    Name: "",
    StartDate: null,
    FinishDate: null,
    Description: "",
    aditionalUrlResources: "",
    FileExtension: ".pdf",
    NumberFiles: 1,
    MinimumGrade: 60,
    TimeLimit: "00:30:00",
    Attemps: 1,
    MaterialType: "enlace",
    File: "",
  };
  const validationSchema = Yup.object({
    module: Yup.string().required("Campo Requerido"),
    content: Yup.string().required("Campo requerido"),
    Name: Yup.string().required("Campo requerido"),
    StartDate: Yup.string().required("Campo requerido").nullable(),
    FinishDate: Yup.string().required("Campo requerido").nullable(),
    aditionalUrlResources: Yup.string().when("content", {
      is: "LessonVirtualclass",
      then: Yup.string().required("Campo requerido."),
    }),
    TimeLimit: Yup.string().when("content", {
      is: "LessonQuiz",
      then: Yup.string()
        .required("Campo requerido")
        .matches(
          /^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/,
          'Tiempo no válido "formato valido: 00:00:00"'
        ),
    }),
  });
  const dropdownModules = generateOptions();
  function generateOptions() {
    let dropOptions = [{ key: "Módulo:", value: "" }];
    modules.forEach((m) => {
      dropOptions.push({ key: m.name, value: m.id });
    });
    return dropOptions;
  }
  const onSubmit = async (values) => {
    setload(false);
    const { module, content, Description, ...rest } = values;
    console.log(values);

    if (content === "Jitsi") {
      rest.Name = `${module} ${rest.Name}`;
    }
    /* TODO */
    if (values.content === "Materials" && values.MaterialType === "archivo") {
      let formData = new FormData();
      formData.append("ModuleId", values.module);
      formData.append("Content", values.content);
      formData.append("Name", values.Name);
      formData.append("StartDate", values.StartDate.toISOString());
      formData.append("FinishDate", values.FinishDate.toISOString());
      formData.append("Description", values.Description);
      formData.append("File", values.File);

      // console.log(formData)
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: `/module/${module}/${content}/file`,
          data: formData,
        },
        openSnackbar: openSnackbar,
        textSuccess: "Lección creada",
        textWrong: "No se pudo crear",
        functionSuccess: reload,
        process: true,
      });
    } else {
      console.log(values);
      await UseOnSubmit({
        axiosParams: {
          method: "POST",
          url: `/module/${module}/${content}`,
          data: {
            Description: Description
              ? JSON.stringify(Description).replace(/"/g, "'")
              : Description,
            ...rest,
          },
        },
        openSnackbar: openSnackbar,
        textSuccess: "Lección creada",
        textWrong: "No se pudo crear",
        functionSuccess: reload,
        process: true,
      });
    }
    setload(true);
  };

  return (
    <div className="ContentUpload">
      <h3>Subir contenido</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="select"
                label="Módulo donde añadir:"
                name="module"
                options={dropdownModules}
              />
              <FormikControl
                control="select"
                label="Tipo de contenido"
                name="content"
                options={
                  rol === "educator" ? dropdownEducator : dropdownOptions
                }
              />
              <FormikControl
                control="input"
                type="text"
                label="Nombre del contenido"
                name="Name"
              />
              {formik.values.content === "LessonHomework" && (
                <>
                  <FormikControl
                    control="select"
                    label="Tipo de archivo permitido"
                    name="FileExtension"
                    options={dropdownExtension}
                  />
                  <FormikControl
                    control="input"
                    type="number"
                    label="Numero de archivos permitidos"
                    name="NumberFiles"
                  />
                </>
              )}
              <FormikControl
                control="date"
                label="Fecha de inicio"
                name="StartDate"
                placeholderText="Fecha de inicio"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormikControl
                control="date"
                label="Fecha de conclusión"
                name="FinishDate"
                placeholderText="Fecha de conclusión"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              {formik.values.content === "LessonVirtualclass" && (
                <FormikControl
                  control="input"
                  type="text"
                  label="Enlace de la clase virtual"
                  name="aditionalUrlResources"
                />
              )}
              {formik.values.content === "Materials" && (
                <>
                  <FormikControl
                    control="select"
                    label="Tipo de material"
                    name="MaterialType"
                    options={dropdownMaterial}
                    value={formik.values.MaterialType}
                    // disabled={data && data.description ? true : false}
                  />
                  {formik.values.MaterialType === "enlace" ? (
                    <FormikControl
                      control="input"
                      type="text"
                      label="Enlace"
                      name="aditionalUrlResources"
                    />
                  ) : (
                    <DropzoneInput
                      name="File"
                      small={true}
                      label="Subir archivo"
                      uploadFiles={formik.setValues}
                    />
                  )}
                </>
              )}
              {formik.values.content === "LessonQuiz" && (
                <>
                  <FormikControl
                    control="input"
                    type="number"
                    label="Nota mínima de aprobación"
                    name="MinimumGrade"
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="Tiempo limite"
                    name="TimeLimit"
                  />
                  <FormikControl
                    control="input"
                    type="number"
                    label="Numero de intentos"
                    name="Attemps"
                  />
                </>
              )}
              <FormikControl
                control="editor"
                label="Descripción"
                name="Description"
                onChange={formik.setValues}
              />
              <br />
              <br />
              {load ? (
                <ActionButton
                  type="submit"
                  disabled={!formik.isValid}
                  textBtn="Subir contenido"
                  onCancel={() => changeInfo(<></>)}
                />
              ) : (
                <Loading />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CreateLesson;
