import { ModalRegister } from '../shopping/ShoppingAside'
const ModalPayment = (props) => {
  const { payment, onCancel } = props
  return (
    <ModalRegister
      {...props}
      dataShopping={{ total: payment.amount, currency: payment.currency }}
      onClose={onCancel}
      urlPost="/membership"
      orderResult="payment-result"
      textClose="Volver a los planes"
      dataPayment={{ Plan: payment.type }}
    />
  )
}

export default ModalPayment
