import './About.css'
import { H2, Section } from '../../../components/text'
const About = () => {
  return (
    <div className="About">
      <div className="About__title">
        <H2 textAlign="center" color="var(--white)">
          ¿Quiénes somos?
        </H2>
      </div>
      <div className="About__main">
        <div className="About__lines">
          <div style={{ background: 'var(--orange2)' }}></div>
          <div style={{ background: 'var(--purple)' }}></div>
          <div style={{ background: 'var(--green)' }}></div>
          <div style={{ background: 'var(--orange2)' }}></div>
        </div>
        <div className="About__content">
          <div className="About__flex">
            <div className="">
              <H2 color="var(--orange2)">BREVE RESEÑA DE LA INSTITUCIÓN</H2>
              <div className="About__hr"></div>
            </div>
            <img
              src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472657/assets/Group_2285_vwbbtc_lcsxyu.png"
              alt="Figura Libro"
            />
          </div>
          <br />
          <Section>
            CAPACITA GLOBAL INTERNACIONAL ES UNA EMPRESA MULTIDISCIPLINARIA. EL
            OBJETIVO PRINCIPAL ES BRINDAR UNA PLATAFORMA VIRTUAL DONDE LAS
            PERSONAS Y LAS EMPRESAS PUEDAN ACCEDER A UN SISTEMA DE CAPACITACIÓN
            Y FORMACIÓN DESDE LA WEB, DE MANERA RESPONSABLE, AMIGABLE Y FÁCIL.
            PARA ESTE EFECTO, Y EN EL TRANSCURSO DE ESTOS AÑOS, HEMOS
            ESTABLECIDO UN EQUIPO DE DOCENTES DE GRAN TRAYECTORIA, CUYA
            EXPERIENCIA ES CONOCIDA DENTRO DEL SISTEMA UNIVERSITARIO BOLIVIANO.
            TRASCIENDE LAS FRONTERAS, APORTANDO UN EXCELENTE NIVEL DE CALIDAD EN
            LA RESOLUCIÓN DE PROBLEMAS ACTUALES. ESTAMOS DEDICADOS AL DESARROLLO
            PROFESIONAL, PERSONAL Y EMPRESARIAL A TRAVÉS DEL SERVICIO DE
            CAPACITACIONES VIRTUALES Y SEMIPRESENCIALES. CREANDO Y DESARROLLANDO
            COMPETENCIAS ESPECÍFICAS EN NUESTROS USUARIOS DEL PREGRADO, POSGRADO
            Y FORMACIÓN INTEGRAL DEL TALENTO HUMANO EN LAS EMPRESAS QUE LO
            REQUIERAN.
          </Section>
          <br />
          <br />
          <div className="About__cards">
            <div className="About__card">
              <div className="About__head">
                <img
                  src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472658/assets/Group_2288_as8jtu_e9cojg.png"
                  alt="Figura de un cometa"
                />
                <H2 textAlign="center" color="var(--purple)">
                  Misión
                </H2>
              </div>
              <Section textAlign="center">
                Ofrecer servicios de capacitación virtual de alta calidad a
                personas y empresas en diferentes áreas (académicas y
                empresariales), personalizadas y acorde a sus necesidades para
                fortalecer y mejorar sus competencias laborales, favoreciendo el
                crecimiento profesional y humano.
              </Section>
            </div>
            <hr />
            <div className="About__card">
              <div className="About__head">
                <img
                  src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472636/assets/Group_2289_vmtver_svetd9.png"
                  alt="Figura de un cometa"
                />
                <H2 textAlign="center" color="var(--orange2)">
                  visión
                </H2>
              </div>
              <Section textAlign="center">
                Ser la empresa líder en la oferta y desarrollo de procesos de
                capacitación virtual de excelencia en el mercado virtual y
                semipresencial, que contribuya con innovación constante y de
                alta calidad a la resolución de las necesidades del cliente de
                manera amigable, fácil y con la mejor tecnología disponible de
                acceso en el mercado.
              </Section>
            </div>
            <hr />
            <div className="About__card">
              <div className="About__head">
                <img
                  src="https://res.cloudinary.com/pataformacgi/image/upload/v1655472636/assets/Group_2290_kq4tvf_kzrz1c.png"
                  alt="Figura de un cometa"
                />
                <H2 color="var(--green)" margin="0">
                  valores
                </H2>
              </div>
              <ul>
                <li>Profesionalismo</li>
                <li>Responsabilidad</li>
                <li>Colaboración</li>
                <li>Calidad</li>
                <li>Honestidad</li>
                <li>Servicio</li>
                <li>Respeto</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
