import { changeLayout, changeInfo } from '../../../store/actions'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { useActionParam } from '../../../hooks'
import { getAction } from '../../../services/action/ActionAuthorization'
import { TableDefault } from '../../../components/table'
import { H2, SubtitleData } from '../../../components/text'
import { LoadCircle } from '../../../components/animation'
import { convertD } from '../../../utils/ConvertDate'
const header = [{ key: 'name', name: 'Nombre' }]
const CourseProgress = (props) => {
  const {
    changeLayout,
    changeInfo,
    match: {
      params: { cid },
    },
  } = props
  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: `/course/${cid}/CompletedLessons`,
  })
  useEffect(() => {
    changeLayout('BigInformation')
    changeInfo(<></>)
  }, [changeLayout, changeInfo])
  function LessonProgress(data) {
    const { lesson } = data
    const headerLesson = [
      { key: 'name', name: 'Lección' },
      { key: 'completedUsers', name: 'Estudiantes que lo completaron' },
      { key: 'totalUsers', name: 'Total estudiantes' },
    ]
    return (
      <>
        <H2 textAlign="center">Datos del estudiantes</H2>
        <SubtitleData subtitle="Nombre del curso">{lesson.name}</SubtitleData>
        <SubtitleData subtitle="Fecha de inicio">
          {convertD(lesson.startDate, 'LL')}
        </SubtitleData>
        <SubtitleData subtitle="Fecha de finalizaron">
          {convertD(lesson.finishDate, 'LL')}
        </SubtitleData>
        <TableDefault header={headerLesson} main={lesson.lessonList} />
      </>
    )
  }
  return (
    <>
      <H2 textAlign="center">Lista de estudiantes</H2>
      {statusRequest ? (
        <TableDefault
          handleInfo={(lesson) =>
            changeInfo(<LessonProgress lesson={lesson} />)
          }
          header={header}
          main={dataRequest}
        />
      ) : (
        <LoadCircle />
      )}
    </>
  )
}

export default connect(null, { changeLayout, changeInfo })(CourseProgress)
