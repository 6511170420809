import { useState } from 'react'
import { H2, H4 } from '../../text'
import { Formik, Form } from 'formik'
import { Loading } from '../../animation'
import { FormikControl } from '../../input'
import { Icon, ActionButton } from '../../buttons'
import { UseOnSubmit } from '../../../hooks/useOnSubmit'
import { convertD, convertDate, addToDate } from '../../../utils/ConvertDate'

const dropdowMaterialType = [
  { key: 'Material', value: 'material' },
  { key: 'Enlace', value: 'url' },
]
const MaterialPutLesson = (props) => {
  const { lesson, openSnackbar, changeModal, reload, changeInfo } = props
  const [load, setload] = useState(true)
  const [status, setstatus] = useState({
    material: false,
  })
  const initialValues = {
    Name: lesson.name || '',
    Description: lesson.description || '',
    StartDate: convertD(lesson.startDatePerUser, 'utc') || null,
    FinishDate: convertD(lesson.finishDatePerUser, 'utc') || null,
    Public: lesson.public || false,
    MaterialType: 'material',
    MaterialUrl: '',
    MaterialFile: null,
  }
  const onSubmit = async (values) => {
    setload(false)
    let formData = new FormData()
    formData.append(
      'Description',
      values.Description
        ? JSON.stringify(values.Description).replace(/"/g, "'")
        : values.Description,
    )
    formData.append('Name', values.Name)
    formData.append('Public', values.Public)
    formData.append(
      'StartDate',
      values.StartDate ? convertDate(values.StartDate) : values.StartDate,
    )
    formData.append(
      'FinishDate',
      values.FinishDate
        ? convertDate(values.FinishDate)
        : addToDate(values.StartDate, 'days', 7),
    )
    if (values.MaterialType === 'url') {
      formData.append('MaterialUrl', values.MaterialUrl)
      formData.append('AditionalUrlResources', values.MaterialUrl)
    } else {
      formData.append('MaterialFile', values.MaterialFile)
    }
    formData.append('MaterialType', values.MaterialType)

    await UseOnSubmit({
      axiosParams: {
        method: 'PUT',
        url: `/lesson/${lesson.id}`,
        data: formData,
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Se guardaron tus cambios',
      textWrong: 'No se pudo guardar',
      functionSuccess: reload,
    })
    setload(true)
  }
  function confirmEdit(values) {
    changeModal(
      true,
      '¿Estás seguro que quieres editar la lección?',
      '',
      'Editar',
      () => onSubmit(values),
    )
  }
  return (
    <>
      <H2 textAlign="center">Editar material</H2>

      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={confirmEdit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Nombre del material"
                name="Name"
              />
              <FormikControl
                control="date"
                label="Fecha de inicio"
                name="StartDate"
                placeholderText="Fecha de inicio"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
              />
              <FormikControl
                control="date"
                label="Fecha de conclusión"
                name="FinishDate"
                placeholderText="Fecha de conclusión"
                dateFormat="MMMM d, yyyy HH:mm"
                showTimeSelect
                timeIntervals={15}
                selected={
                  formik.values.StartDate
                    ? new Date(addToDate(formik.values.StartDate, 'days', 7))
                    : null
                }
              />

              <FormikControl
                name="MaterialType"
                label="Opciones para subir el material"
                control="select"
                options={dropdowMaterialType}
              />
              {formik.values.MaterialType === 'url' && (
                <FormikControl
                  control="input"
                  type="text"
                  label="Enlace del material"
                  name="MaterialUrl"
                />
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                {formik.values.MaterialType === 'material' && (
                  <>
                    {status.material && (
                      <FormikControl
                        control="file"
                        name="MaterialFile"
                        small={true}
                        label="Archivo de material"
                        uploadFiles={formik.setValues}
                        type=".jpg, .jpeg, .pdf, .docx, .xlsx, .pptx, .png, video/*"
                      />
                    )}
                    <Icon
                      handleModal={() =>
                        setstatus((a) => ({ ...a, material: !status.material }))
                      }
                      ico={status.material ? 'times' : 'edit'}
                    >
                      {status.material ? 'Cancelar' : 'Editar material'}
                    </Icon>
                  </>
                )}

                <FormikControl
                  control="editor"
                  label="Descripción"
                  name="Description"
                  onChange={formik.setValues}
                  data={lesson.description}
                />
                <FormikControl
                  control="input"
                  type="checkbox"
                  label="Publicar el material"
                  name="Public"
                />
              </div>
              <br />
              {load ? (
                <ActionButton
                  onCancel={() => changeInfo(<></>)}
                  textBtn="Actualizar el material"
                  type="submit"
                  disabled={!formik.isValid}
                />
              ) : (
                <>
                  <Loading />
                  {formik.values.MaterialFile && (
                    <H4>Esto puede tardar según el tamaño del material</H4>
                  )}
                </>
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default MaterialPutLesson
