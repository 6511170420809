import './GraduatesUTB.css'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import { openSnackbar } from '../../../store/actions'
import { GraduateCard } from '../../../components/card'
import { LoadCircle } from '../../../components/animation'
import { useAxios } from '../../../hooks'
import { useAxiosAuth } from '../../../hooks'
import { validateArray } from '../../../utils/Validation'
import { H2 } from '../../../components/text'
import './GraduatesUTB.css'
const fakeData = {
  addressedTo:
    'El Diplomado está dirigido a todos aquellos profesionales que cuenten con aptitud y habilidad para la investigación y enseñanza sin discriminación de área formativa o perfil profesional, haciendo énfasis en el área de derecho, contabilidad, y auditoria.',
  bestSeller: false,
  courseAchievements:
    'Desarrollar analíticamente los distintos órganos y procedimientos administrativos que pautan las distintas fases de aplicación de los tributos y los procedimientos jurídicos en defensa de la ciudadanía y de la institución.',
  description:
    'El diplomado desarrolla, actualiza y refuerza los conocimientos del profesional en progresar el estudio de problemas y casos prácticos de Derecho Tributario, relacionando sus normas reguladoras, requerimientos administrativos y ubicarlos en cada fase procedimental mediante el conocimiento exhaustivo y completo de cada procedimiento tributario, además de desarrollar las distintas vías de impugnación para iniciar un procedimiento administrativo o un procedimiento jurisdiccional en el sistema impositivo nacional.',
  finishDate: '2021-01-17T00:00:00',
  id: 123,
  programManagerId: 123,
  mainPrice: null,
  name: 'DIPLOMADO EN BIG DATA Y MACHINE LEARNING',
  paymentUrl:
    'https://aulacapacitaglobal.com/finalizar-compra/?add-to-cart=19133',
  photoUrl:
    'https://res.cloudinary.com/pataformacgi/image/upload/h_600,c_scale,q_auto,f_auto/v1655472643/assets/Mask_Group_52_e4hbs0_ph079m.png',
  startDate: '2021-01-10T00:00:00',
  unlimitedAccess: true,
  videoUrl: '',
}
const GraduatesUTB = (props) => {
  const { openSnackbar } = props
  const { response: dataRequest, loading: statusRequest } = useAxios({
    method: 'GET',
    url: '/category/1/courses',
  })

  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: window.localStorage.getItem('id') ? '/Shoppingcart' : '',
  })
  const [view, setview] = useState(false)
  const [shopping, setshopping] = useState([])

  function reloadShopping() {
    reload()
    setshopping(getShopping())
  }
  function getShopping() {
    if (window.localStorage.getItem('id')) {
      return response ? response : []
    } else {
      const storageShop = window.localStorage.getItem('shopping')
        ? JSON.parse(window.localStorage.getItem('shopping'))
        : [] || []
      return storageShop
    }
  }
  useEffect(() => {
    setshopping(getShopping())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, window.localStorage.getItem('shopping')])
  return (
    <>
      <H2 margin="0">Postgrados</H2>
      <div className="Catalogue__navigation">
        {/* <NavCatalog booleanStyle={!view} onClick={() => setview(true)}>
          <img
            src="https://res.cloudinary.com/dsgaf5zoj/image/upload/h_60,c_scale,q_auto,f_auto/v1622464998/assets/rfv01ekxbahamdtnirg2.png"
            alt=""
          />
        </NavCatalog> */}
        <NavCatalog booleanStyle={view} onClick={() => setview(false)}>
          <img
            src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472644/assets/Rectangle_339_y2ol2t_em6bju.png"
            alt=""
          />
        </NavCatalog>
      </div>
      {/* <div className="Catalogue__head">
        <div className="Catalogue__navigation">
          <NavCatalogue color="#d17742" url="/catalogue/graduatesUTB">
            Postgrados
          </NavCatalogue>
          <NavCatalogue
            color="#6a3081"
            type="anchor"
            url="https://aulacapacitaglobal.com/cursos-cortos-utb/"
          >
            PREGRADOS
          </NavCatalogue>
          <NavCatalogue url="/catalogue/courses-children">
            CURSOS - NIÑ@S
          </NavCatalogue>
        </div>
      </div> */}
      {view ? (
        <div className="GraduatesUTB">
          {loading && statusRequest ? (
            <LoadCircle />
          ) : validateArray(dataRequest?.programs) ? (
            dataRequest.programs.map((l, index) => {
              return (
                <GraduateCard
                  url="graduates"
                  key={l.id}
                  course={l}
                  color={
                    index % 2 === 0 ? dataRequest.category.color : '#919192'
                  }
                  openSnackbar={openSnackbar}
                  shopBoolean
                  shopping={shopping}
                  reloadShopping={reloadShopping}
                />
              )
            })
          ) : (
            <h3>Los diplomados no estan disponibles</h3>
          )}
        </div>
      ) : (
        <div className="GraduatesUTB">
          <GraduateCard
            url="UAGRM/big-data"
            course={fakeData}
            color="#193765"
            openSnackbar={openSnackbar}
            shopBoolean
            shopping={shopping}
            reloadShopping={reloadShopping}
          />
        </div>
      )}
    </>
  )
}
function NavCatalog({ children, onClick, booleanStyle }) {
  return (
    <div
      className={`NavCatalog ${booleanStyle ? '' : 'activate'}`}
      onClick={onClick}
    >
      {children}
    </div>
  )
}
export default connect(null, { openSnackbar })(GraduatesUTB)
