import { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeLayout } from '../../../../store/actions'
import { ViewHeader } from '../../../../containers/catalogue'

const ProgrammingCourseData = {
  title: 'CURSOS DE PROGRAMACIÓN PARA NIÑOS',
  videosUrl: [
    'https://res.cloudinary.com/dsgaf5zoj/video/upload/v1638550627/assets/vunmzfl8qt6cmyxkdw39.mp4',
    'https://res.cloudinary.com/dsgaf5zoj/video/upload/v1638550627/assets/vunmzfl8qt6cmyxkdw39.mp4',
  ],
  courses: [
    {
      id: 1,
      color: '#6A3081',
      label: 'Curso para niños de 7 a 13 años',
      image:
        'https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472637/assets/Group_2390_inz1wv_pthhxg.png',
    },
    {
      id: 2,
      color: '#EE7C38',
      label: 'Curso para niños de 14 a 18 años',
      image:
        'https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472657/assets/Group_2276_tqtab4_bmvvor.png',
    },
  ],
}

const ChessCourse = ({ changeLayout }) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])
  return <ViewHeader dataCourse={ProgrammingCourseData} link="course-chess" />
}

export default connect(null, { changeLayout })(ChessCourse)
