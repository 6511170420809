import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useAnswers } from "../../../../../hooks";
import { validateArray, validateStatus } from "../../../../../utils/Validation";
import { transformTextJson } from "../../../../../utils/ProcessData";
import "./style/AnswersContent.css";
import { connect } from "react-redux";
import { changeLayout } from "../../../../../store/actions";
import { NormalLink } from "../../../../../components/buttons";
import { H4 } from "../../../../../components/text";

const AnswersContent = ({ match, changeLayout }) => {
  const uid = window.localStorage.getItem("id");
  const { aid, cid, mid, pid } = match.params;
  const { dataRequest, statusRequest } = useAnswers(uid, aid);

  useEffect(() => {
    changeLayout("FullScreen");
  }, [changeLayout]);

  return (
    <div className="AnswersContent">
      {validateStatus(statusRequest) && dataRequest ? (
        <>
          <div className="AnswersContent__header">
            <div className="AnswersContent__information">
              <h2>Mis Respuestas</h2>
              {dataRequest.lessonUserAnswersDto.type ===
              "quizTeacher" ? null : (
                <ul>
                  <li>Puedes presentar el examen solo una vez.</li>
                  <li>
                    Necesitas una calificación mayor a{" "}
                    <strong>
                      {dataRequest.lessonUserAnswersDto.minimunGrade}
                    </strong>{" "}
                    para aprobar.
                  </li>
                </ul>
              )}
            </div>
            {dataRequest.lessonUserAnswersDto.type === "quizTeacher" ? null : (
              <div
                className={`AnswersContent__grades ${
                  dataRequest.lessonUserAnswersDto.finishScore >=
                  dataRequest.lessonUserAnswersDto.minimunGrade
                    ? "correct"
                    : "wrong"
                }`}
              >
                <div className="AnswersContent__Qualification">
                  <h3>
                    {dataRequest.lessonUserAnswersDto.finishScore.toFixed(2)}
                  </h3>
                  <p>Calificación</p>
                </div>
                <div className="AnswersContent__hr"></div>
                <div className="AnswersContent__Hits">
                  <h3>
                    {dataRequest.lessonUserAnswersDto.hits} /{" "}
                    {dataRequest.lessonUserAnswersDto.numberQuestions}
                  </h3>
                  <p>Aciertos</p>
                </div>
                <div className="AnswersContent__hr"></div>
                <div className="AnswersContent__Hits">
                  <h3>
                    {dataRequest.lessonUserAnswersDto.finishScore >=
                    dataRequest.lessonUserAnswersDto.minimunGrade
                      ? "Aprobado"
                      : "Reprobado"}
                  </h3>
                  <p>Estado</p>
                </div>
              </div>
            )}
          </div>
          {!(
            dataRequest.lessonUserAnswersDto.finishScore >=
            dataRequest.lessonUserAnswersDto.minimunGrade
          ) && dataRequest.remainingAttempts > 0 ? (
            <div className="AnswersContent__btn">
              <H4 fontSize="2.5rem" textAlign="center">
                Nro. de intentos: {dataRequest.remainingAttempts}
              </H4>
              <br />
              <NormalLink
                to={`/program/${pid}/course/${cid}/module/${mid}/quiz/${aid}`}
              >
                Volver a dar el examen
              </NormalLink>
              <br />
              <br />
            </div>
          ) : null}
          {validateArray(dataRequest.lessonUserAnswersDto.verifyAnswers)
            ? dataRequest.lessonUserAnswersDto.verifyAnswers.map((question) => {
                let userResponses;
                if (question.type === "conceptRelation") {
                  userResponses = question.userResponses.split("-");
                  return (
                    <div
                      className={`AnswersContent__query ${
                        question.rightAnswer ? "correct" : "wrong"
                      }`}
                      key={question.id}
                    >
                      <QuestionRelation
                        question={question}
                        userResponses={userResponses}
                      />
                    </div>
                  );
                } else if (question.type === "multipleChoice") {
                  userResponses = question.userResponses.split(";");
                } else {
                  userResponses = [question.userResponses];
                }
                return (
                  <div
                    className={`AnswersContent__query ${
                      dataRequest.lessonUserAnswersDto.type === "quizTeacher"
                        ? ""
                        : question.rightAnswer
                        ? "correct"
                        : "wrong"
                    }`}
                    key={question.id}
                  >
                    <Question
                      type={dataRequest.lessonUserAnswersDto.type}
                      question={question}
                      userResponses={userResponses}
                    />
                  </div>
                );
              })
            : null}
          <br />
          <br />
          <center>
            <NormalLink to={`/program/${pid}/course/${cid}/module/${mid}`}>
              Regresar
            </NormalLink>
          </center>
        </>
      ) : null}
      <br />
      <br />
    </div>
  );
};

const Question = ({ question, userResponses, type }) => {
  function validateRightAnswer(respose) {
    let classResp = "";
    if (userResponses.indexOf(respose + "") > -1) {
      classResp =
        type === "quizTeacher"
          ? "correct"
          : question.rightAnswer
          ? "correct"
          : "wrong";
    }
    return classResp;
  }

  return (
    <>
      <H4 innerHTML fontSize="2rem">
        {question.inquiry}
      </H4>
      {question.fileId && (
        <center>
          <img className="Question__img" src={question.url} alt="" />
        </center>
      )}
      {question.type === "openQuestion" ? (
        <div className="AnswersContent__questions">
          <div className="AnswersContent__question correct">
            <h2>{question.userResponses}</h2>
          </div>
        </div>
      ) : (
        <div className="AnswersContent__questions">
          {validateArray(transformTextJson(question.responses))
            ? transformTextJson(question.responses).map((a) => (
                <div
                  className={`AnswersContent__question ${validateRightAnswer(
                    a.id
                  )}`}
                  key={a.id}
                >
                  {a.FileId ? (
                    <img src={a.response} alt="" />
                  ) : (
                    <h2>{a.response}</h2>
                  )}
                  {a.id.toString() === question.userResponses && (
                    <i
                      className={`fas fa-${
                        type === "quizTeacher"
                          ? "check"
                          : question.rightAnswer
                          ? "check"
                          : "times"
                      }`}
                    ></i>
                  )}
                </div>
              ))
            : null}
        </div>
      )}
    </>
  );
};

function QuestionRelation({ question, userResponses }) {
  const inquiry = transformTextJson(question.inquiry);
  const responses = transformTextJson(question.responses);
  return (
    <>
      <h2>Relación de concepto</h2>
      <div className="AnswersContent__questions">
        {validateArray(inquiry)
          ? inquiry.map((a, index) => {
              const usResp = userResponses[index].split(";")[1];
              return (
                <div className="AnswersContent__relation" key={a.id}>
                  <div className="AnswersContent__question">
                    {a.FileId ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <h2>{index + 1}. </h2> <img src={a.concept} alt="" />{" "}
                      </div>
                    ) : (
                      <h2>
                        {index + 1}. {a.concept}
                      </h2>
                    )}
                  </div>
                  <div
                    className={`AnswersContent__question ${
                      usResp === index + 1 + "" ? "correct" : "wrong"
                    }`}
                  >
                    {responses[usResp - 1].FileId ? (
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        <h2>{index + 1}. </h2>{" "}
                        <img src={responses[usResp - 1].response} alt="" />{" "}
                      </div>
                    ) : (
                      <h2>
                        {index + 1}. {responses[usResp - 1].response}
                      </h2>
                    )}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </>
  );
}

export default connect(null, { changeLayout })(withRouter(AnswersContent));
