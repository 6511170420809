import { VideoPlayer } from '../../../components/common'
import { H2, Section } from '../../../components/text'
import { ToolTip } from '../../../components/boxes'
import { validateArray } from '../../../utils/Validation'
import './ViewHeader.css'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ControlSVG } from '../../../components/figure'
const ViewHeader = ({ dataCourse, link }) => {
  const {
    title = '',
    videosUrl = [],
    courses = [],
    background = '#6a308191',
  } = dataCourse

  function CardCourse({ url, color, id, label }) {
    return (
      <ToolTip content={label ? label : ''}>
        <Link
          to={`/${link}/${id}`}
          className="CardCourse"
          style={{ background: color, width: `calc(100% / ${courses.length})` }}
        >
          <img src={url} alt="" />
        </Link>
      </ToolTip>
    )
  }

  return (
    <HeaderStyle
      size={courses.length}
      background={background}
      height={`calc(100vh - ${courses.length * 100}px)`}
    >
      <div className="ViewHeader__head">
        {validateArray(videosUrl)
          ? videosUrl.map((video, index) => (
              <VideoPlayer
                key={index}
                className={`video${index + 1}`}
                url={video}
                width="100%"
                controls={false}
                height="100%"
                // muted={true}
                playing={true}
              />
            ))
          : null}
        <div className="ViewHeader__img"></div>
        <div className="ViewHeader__background"></div>
        <div className="ViewHeader__card">
          <ControlSVG
            color="var(--purple)"
            widthText="70%"
            width="250px"
            figure="message1"
          >
            <Section fontSize="2rem" color="var(--purple)">
              Para mayor información haz un click en las edades de tu interés
            </Section>
          </ControlSVG>
        </div>
        <div className="ViewHeader__text">
          <H2 color="var(--white)" textAlign="center">
            {title}
          </H2>
        </div>
      </div>
      <div className="ViewHeader__main" id="ViewHeaderMain">
        {validateArray(videosUrl)
          ? courses.map((course) => (
              <CardCourse
                key={course.id}
                id={course.id}
                color={course.color}
                url={course.image}
                label={course.label}
              />
            ))
          : null}
      </div>
    </HeaderStyle>
  )
}

const HeaderStyle = styled.div`
  height: 100vh;
  .ViewHeader__background {
    background: ${(props) => props.background};
  }
  @media screen and (max-width: 900px) {
    .ViewHeader__head {
      height: ${(props) => props.height};
    }
    .ViewHeader__img {
      height: ${(props) => props.height};
    }
    .ViewHeader__background {
      height: ${(props) => props.height};
    }
    .ViewHeader__main {
      flex-direction: column;
      padding: 0;
      height: ${(props) => `${props.size * 100}px`};
    }
  }
`
export default ViewHeader
