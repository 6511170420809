import { GraduateCard } from '../../../components/card'
import { H2 } from '../../../components/text'
const seminar = {
  name: 'SEMINARIO EN DATA SCIENCE',
  photoUrl:
    'https://res.cloudinary.com/dsgaf5zoj/image/upload/h_600,c_scale,q_auto,f_auto/v1622226794/assets/gk20ly7fkp1zrb5yze5j.png',
}
const Seminars = () => {
  return (
    <>
      <H2>Seminarios</H2>
      <GraduateCard url="seminar/big-data" course={seminar} color="#193765" />
    </>
  )
}

export default Seminars
