import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useAxiosAuth } from '../../hooks'
import { convertD } from '../../utils/ConvertDate'
import { capitalizeFirstLetter, validateArray } from '../../utils/Validation'
import { Loading } from '../../components/animation'
import { showIcon, TableDefault } from '../../components/table'
import { H2, H3, H4, SubtitleData } from '../../components/text'
import {
  changeInfo,
  changeLayout,
  changeModal,
  openSnackbar,
} from '../../store/actions'
import RenderModal from '../../components/modal/RenderModal'
import { ViewUser } from '../Contacts/Contacts'
import { Reports, setDateStartAndEnd } from './Reports'
import { RangeDate } from '../../components/input'

const Manager = (props) => {
  const { changeLayout } = props
  const [inp, setinp] = useState({
    StartDate: null,
    EndDate: null,
  })
  function handleDateRange(update) {
    setinp((a) => ({
      ...a,
      StartDate: update[0],
      EndDate: update[1],
    }))
  }
  useEffect(() => {
    changeLayout('FullScreen')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <H2>Reportes</H2>
      <H3 margin="0" fontSize="1.5rem">
        Rango de consulta
      </H3>
      <div className="Reports__rangeDate">
        <RangeDate
          label=""
          dateRange={[
            inp.StartDate ? inp.StartDate : null,
            inp.EndDate ? inp.EndDate : null,
          ]}
          setDateRange={handleDateRange}
        />
      </div>
      <Reports {...props} inp={inp} />
    </>
  )
}

export const SellerDetail = (props) => {
  const { seller, changeModal, openSnackbar, inp } = props
  const [state, setState] = useState({
    modal: false,
    user: null,
    component: null,
  })

  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    /* contact/46/list?DayStart=10&MonthStart=2&YearStart=2022&DayEnd=9&MonthEnd=3&YearEnd=2022 */
    url: `/contact/${seller.UserId}/list/${setDateStartAndEnd(inp)}`,
  })
  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller.userId])

  const contactHeader = [
    { key: 'index', name: 'N°' },
    { key: 'nameAndAge', name: 'Nombres/Edad' },
    { key: 'information', name: 'Ciudad/Contactos' },
    { key: 'availableTimes', name: 'Disponibilidad' },
    { key: 'observation', name: 'Observación' },
    { key: 'description', name: 'Descripción' },
  ]

  function convertToBool(item) {
    return parseInt(item) === 1
  }
  function preConfig(list) {
    let aux = []
    let index = 1
    list.forEach((element) => {
      const availableTimesList = element.availableTimes.split(';')
      aux.push({
        ...element,
        index: index,
        availableTimes: availableTimesList.map((a, i) => {
          element.Morning = convertToBool(availableTimesList[i])
          element.Afternoon = convertToBool(availableTimesList[i])
          element.Night = convertToBool(availableTimesList[i])
          return showIcon(i, a)
        }),

        nameAndAge: (
          <>
            <H2 color="var(--orange2)">
              {capitalizeFirstLetter(element.name)}{' '}
              {capitalizeFirstLetter(element.lastname)}
            </H2>
            <H3>{element.age}</H3>
          </>
        ),
        information: (
          <>
            <H4>{element.city}</H4>
            <H4>{element.phone}</H4>
            <H4>{element.email}</H4>
          </>
        ),
      })
      index++
    })
    return aux
  }
  const handleModal = (user) => {
    setState({
      modal: true,
      component: (
        <ViewUser
          changeModal={changeModal}
          openSnackbar={openSnackbar}
          user={user}
          handleReload={reload}
          changeInfo={handleView}
          rol={'seller'}
          onClose={() =>
            setState({
              ...state,
              modal: false,
              component: null,
            })
          }
        />
      ),
    })
  }

  const handleView = (Component) => {
    setState({
      modal: true,
      component: Component,
    })
  }
  return (
    <>
      <H2 textAlign="center">Lista de contactos</H2>
      <SubtitleData subtitle="Nombres">
        {seller.Name} {seller.LastName}
      </SubtitleData>
      {seller.expeditionCi && (
        <SubtitleData subtitle="Carnet de identidad">
          {seller.ci} {seller.expeditionCi}
        </SubtitleData>
      )}
      <SubtitleData subtitle="Ultima conexión">
        {convertD(seller.lastActive, 'LLLL')}
      </SubtitleData>
      <br />
      {loading ? (
        <Loading />
      ) : (
        <>
          {response !== 0 && (
            <H4 textAlign="center" fontWeight="600" margin="0">
              Cantidad de contactos: {response.length}
            </H4>
          )}
          {validateArray(response) ? (
            <>
              <TableDefault
                handleInfo={handleModal}
                header={contactHeader}
                borderBottom={true}
                main={preConfig(response)}
              />
              {state.modal && state.component != null ? (
                <RenderModal>
                  {/* <ViewUser user={state.user} changeInfo={handleView} /> */}
                  {state.component}
                </RenderModal>
              ) : null}
            </>
          ) : (
            <H3 margin="10px 0">El usuario no tiene contactos</H3>
          )}
        </>
      )}
    </>
  )
}
// const ListContact = (props) => {
//   const { seller } = props

//   if (loading) {
//     return <Loading />
//   } else {
//     return <div></div>
//   }
// }
export default connect(null, {
  changeInfo,
  openSnackbar,
  changeModal,
  changeLayout,
})(Manager)
