import './style/Certificates.css'
import { H3, H2 } from '../../../components/text'
import { useAxiosAuth } from '../../../hooks'
import { validateArray } from '../../../utils/Validation'
import { BoxFlex, ToolTip } from '../../../components/boxes'
import { Loading } from '../../../components/animation'
import { Link } from 'react-router-dom'
const Certificates = () => {
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: '/certificate/user/all',
  })
  return (
    <div>
      <H2 textAlign="center">Mis certificados</H2>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((certificate) => (
          <CardCertificate key={certificate.id} certificate={certificate} />
        ))
      ) : (
        <H3>Aun no tienes certificados</H3>
      )}
    </div>
  )
}
const CardCertificate = ({ certificate }) => {
  const { Course, Url, LessonUserId } = certificate
  return (
    <div className="CardCertificate">
      <div className="CardCertificate__text">
        <Link
          style={{ textDecoration: 'none' }}
          to={`/certificate/${LessonUserId}`}
        >
          <H3 margin="0" textTransform="uppercase" color="var(--green)">
            {Course}
          </H3>
        </Link>
      </div>
      <BoxFlex className="CardCertificate__btns" gap="20px">
        <ToolTip content="Descarga tu certificado">
          <a
            href={Url}
            className="CardCertificate__anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fas fa-download"></i>
          </a>
        </ToolTip>
        <ToolTip content="Pagina de verificación">
          <Link
            className="CardCertificate__btnDetail"
            to={`/certificate/${LessonUserId}`}
          >
            <i className="fas fa-angle-right"></i>
          </Link>
        </ToolTip>
      </BoxFlex>
    </div>
  )
}
export default Certificates
