import "./style/ContentVirtualClass.css";
import { convertD, dataLock, subtractDate } from "../../utils/ConvertDate";
import { CompModal } from "../../components/modal";
import { NormalButton } from "../../components/buttons";
import { useState } from "react";
import { ToolTip } from "../boxes";
import { completeLection } from "../../services/action/ProgramAction";
import EditorView from "../editorjs/EditorView";
const ContentVirtualClass = ({ contentData, children, className }) => {
  const {
    id,
    name,
    description,
    startDatePerUser,
    aditionalUrlResources,
    status,
  } = contentData;
  const [modal, setmodal] = useState(false);
  const startDate = dataLock(subtractDate(startDatePerUser, 30, "minutes"));
  function completeLesson() {
    completeLection(id).then((r) => console.log(r));
  }
  function HandleaClick({ children, className }) {
    return (
      <>
        <ToolTip content="Clase virtual">
          {startDate ? (
            <a
              className={className}
              href={aditionalUrlResources}
              target="_blank"
              rel="noopener noreferrer"
              onClick={status === "available" ? () => completeLesson() : null}
            >
              {children}
            </a>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              className={className}
              onClick={() => setmodal(!modal)}
            >
              {children}
            </div>
          )}
        </ToolTip>
        {modal && (
          <CompModal onClose={() => setmodal(!modal)} closeOutside={true}>
            <div className="ContentVirtualClass__modal">
              <h3>
                La reunión inicia el: {convertD(startDatePerUser, "LLLL")}
              </h3>
              <h2>
                Debes esperar el enlace estará habilitado 30 minutos antes de la
                fecha indicada
              </h2>
              <div className="ContentQuiz__btns">
                <NormalButton
                  onClick={() => setmodal(!modal)}
                  background={true}
                >
                  Cerrar
                </NormalButton>
              </div>
            </div>
          </CompModal>
        )}
      </>
    );
  }
  if (children) {
    return <HandleaClick className={className}>{children}</HandleaClick>;
  } else {
    return (
      <HandleaClick className="ContentVirtualClass">
        <div
          className={`ContentVirtualClass__content ${
            status ? status : "available"
          }`}
        >
          <h3>
            Reunión programada para el: {convertD(startDatePerUser, "LLLL")}
          </h3>
          <h2>{name}</h2>
          {description && <EditorView cutText={true} data={description} />}
        </div>
      </HandleaClick>
    );
  }
};

export default ContentVirtualClass;
