import { GraduateCard } from '../../../components/card'
import { BoxFlex } from '../../../components/boxes'
import { H2 } from '../../../components/text'
const seminar = {
  id: '',
  name: 'CURSO DE PROGRAMACIÓN',
  photoUrl:
    'https://images.unsplash.com/photo-1610484826967-09c5720778c7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
}
const seminar2 = {
  id: '',
  name: 'CURSO DE INGLÉS',
  photoUrl:
    'https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1418&q=80',
}
const seminar3 = {
  id: '',
  name: 'CURSO DE AJEDREZ',
  photoUrl:
    'https://res.cloudinary.com/pataformacgi/image/upload/h_300,c_scale,q_auto,f_auto/v1655472643/assets/photo_2021-12-02_13-37-13_kkrhni_kod8z8.jpg',
}
const course = {
  id: '',
  name: 'CURSO WONDERMATH',
  photoUrl:
    'https://images.unsplash.com/photo-1568828668638-b1b4014d91a2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1790&q=80',
}

const CoursesChildren = () => {
  return (
    <>
      <H2>Cursos para niñ@s</H2>
      <BoxFlex wrap="nowrap">
        <GraduateCard url="course-english" course={seminar2} color="#773B8E" />
        <GraduateCard
          url="course-programming"
          course={seminar}
          color="#EE7C38"
        />
        <br />
      </BoxFlex>
      <BoxFlex wrap="nowrap">
        <GraduateCard
          href="https://wondermath.capacitaglobal.org/"
          course={course}
          color="#458e7f"
        />
        <GraduateCard url="course-chess" course={seminar3} color="#773B8E" />
      </BoxFlex>
    </>
  )
}

export default CoursesChildren
