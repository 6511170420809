import { useState } from 'react'
import { GlobalForm } from '../../../../../components/form'
import { InputsExtend } from '../../../../../utils/FormParameters'
import { convertD, convertDate } from '../../../../../utils/ConvertDate'
import { putAction } from '../../../../../services/action/ActionAuthorization'
import { validateStatus } from '../../../../../utils/Validation'

const ExtendDatesLesson = (props) => {
  const { lid, user, openSnackbar, onClose, response, reload } = props
  const [load, setload] = useState(true)
  const onSubmit = (value) => {
    setload(false)
    putAction(`/lesson/${lid}/user/${user.userId}/extend`, {
      StartDate: convertDate(value.StartDate),
      FinishDate: convertDate(value.FinishDate),
    }).then((response) => {
      setload(true)
      if (response && validateStatus(response.status)) {
        onClose()
        openSnackbar('Se guardaron los cambios', true, true)
        if (reload) {
          reload()
        }
      } else {
        openSnackbar('No se puede completar', true, false)
      }
    })
  }
  return (
    <>
      <GlobalForm
        InputsJson={InputsExtend}
        textBtn="Guardar cambios"
        title="Ampliar fechas"
        data={{
          StartDate: convertD(response.startDate, 'utc'),
          FinishDate: convertD(response.finishDate, 'utc'),
        }}
        load={load}
        onSubmit={onSubmit}
        onCancel={() => onClose()}
      />
    </>
  )
}

export default ExtendDatesLesson
