import './PendingsList.css'
import { useAxiosAuth } from '../../hooks'
import { H2, H3 } from '../../components/text'
import { Loading } from '../../components/animation'
import { BoxFlex } from '../../components/boxes'
import { validateArray } from '../../utils/Validation'
import { TypeLesson } from '../../utils/Constant'

const PendingsList = () => {
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/professor/pending/${window.localStorage.getItem('id')}`,
  })

  return (
    <div>
      <H2 textAlign="center" color="var(--gray)">
        Lista de pendientes
      </H2>
      {loading ? (
        <Loading />
      ) : validateArray(response) ? (
        response.map((course) => {
          if (validateArray(course.lesson)) {
            return <CourseContent key={course.courseId} course={course} />
          } else {
            return null
          }
        })
      ) : (
        <H3>No tiene pendientes</H3>
      )}
    </div>
  )
}

const CourseContent = (props) => {
  const { course } = props
  return (
    <>
      <H3 fontSize="1.4rem" margin="10px 0">
        {course.courseName}
      </H3>
      <div className="CourseContent__lessons">
        {course.lesson.map((lesson) => (
          <a
            key={lesson.lessonId}
            href={`/program/course/${course.courseId}/module/1/${
              lesson.type === 'homework' ? 'home-work' : 'forum'
            }/${lesson.lessonId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <BoxFlex justify="space-between">
              <H3 fontSize="1.4rem" margin="0">
                Pendientes:{' '}
                <span style={{ color: 'var(--red)' }}>{lesson.amount}</span>
              </H3>
              <H3
                textTransform="uppercase"
                textAlign="end"
                fontSize="1.4rem"
                margin="0"
                color="var(--green)"
              >
                {TypeLesson[lesson.type]}
              </H3>
            </BoxFlex>
            <H3 fontSize="1.4rem" color="var(--gray)" margin="10px 0 0">
              {lesson.lessonName}
            </H3>
          </a>
        ))}
      </div>
    </>
  )
}
// amount: 23
// lessonId: 85
// lessonName: "Trabajo de monografía, Capítulo I"
// type: "homework"

export default PendingsList
