import './Institution.css'
import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { ButtonNavigation, Icon } from '../../../components/buttons'
import {
  UserInstitution,
  UsersUTB,
  InstitutionCategory,
  OrdersInstitution,
  InstitutionTracking,
} from '../../../containers/institutions'
import { ProgramManager } from '../../../containers/institutions/institution/ProgramManager'
import {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
  startSession,
} from '../../../store/actions'
import { connect } from 'react-redux'
import { BoxFlex } from '../../../components/boxes'
import { H2 } from '../../../components/text'
import {
  ReleaseList,
  CouponAside,
  FormInscription,
  SearchLesson,
} from '../../../containers/institutions'
import FormReportWM from '../../../containers/institutions/institution/FormReportWM'

const Institution = (props) => {
  const {
    changeLayout,
    changeInfo,
    openSnackbar,
    startSession,
    // sesionUser,
    changeModal,
    match: {
      params: { iid, name },
    },
  } = props
  const [btns, setbtns] = useState({
    btn:
      iid === '1'
        ? [
            { id: 1, name: 'Programas' },
            { id: 2, name: 'Categorías' },
            { id: 3, name: 'Usuarios' },
            { id: 4, name: 'Pre-registros' },
            { id: 5, name: 'Pedidos' },
            // { id: 6, name: 'Seguimiento' },
          ]
        : [
            { id: 1, name: 'Programas' },
            { id: 3, name: 'Usuarios' },
            // { id: 6, name: 'Seguimiento' },
          ],
    activate: {
      id: 1,
    },
  })
  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }))
  }
  useEffect(() => {
    changeInfo(<></>)
    changeLayout()
  }, [changeLayout, changeInfo, btns])

  return (
    <div className="Institution">
      <BoxFlex gap="10px 0" justify="flex-end">
        {iid === '1' && (
          <>
            <Icon
              ico="chart-pie"
              handleModal={() =>
                changeInfo(
                  <FormReportWM
                    changeInfo={changeInfo}
                    openSnackbar={openSnackbar}
                    changeModal={changeModal}
                  />,
                )
              }
            >
              Reporte de WonderMath
            </Icon>
            <Icon
              ico="ticket-alt"
              handleModal={() =>
                changeInfo(
                  <CouponAside
                    changeInfo={changeInfo}
                    openSnackbar={openSnackbar}
                  />,
                )
              }
            >
              Listar cupones
            </Icon>
          </>
        )}
        <Icon
          ico="comment-alt"
          handleModal={() =>
            changeInfo(
              <ReleaseList
                courses={null}
                programs={`/institution/${iid}/programs`}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
              />,
            )
          }
        >
          Lista de anuncios
        </Icon>
        <Icon
          ico="search"
          handleModal={() =>
            changeInfo(
              <SearchLesson
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
              />,
            )
          }
        >
          Buscar lección
        </Icon>
        <Icon
          ico="file-excel"
          handleModal={() =>
            changeInfo(
              <FormInscription
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                changeModal={changeModal}
              />,
            )
          }
        >
          Formulario de inscripción
        </Icon>
      </BoxFlex>

      <H2 margin="10px 0">{name}</H2>
      {/* <H2 margin="10px 0">{sesionUser.institutions[0].name}</H2> */}
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {btns.activate ? (
        <>
          {btns.activate.id === 1 && (
            <ProgramManager
              iid={iid}
              changeInfo={changeInfo}
              openSnackbar={openSnackbar}
              changeModal={changeModal}
            />
          )}
          {btns.activate.id === 2 && (
            <InstitutionCategory
              iid={iid}
              changeInfo={changeInfo}
              openSnackbar={openSnackbar}
            />
          )}
          {btns.activate.id === 3 && (
            <UserInstitution
              iid={iid}
              changeInfo={changeInfo}
              changeModal={changeModal}
              startSession={startSession}
              openSnackbar={openSnackbar}
            />
          )}
          {btns.activate.id === 4 && <UsersUTB />}
          {btns.activate.id === 5 && (
            <OrdersInstitution
              iid={iid}
              changeInfo={changeInfo}
              openSnackbar={openSnackbar}
              changeModal={changeModal}
            />
          )}
          {btns.activate.id === 6 && (
            <InstitutionTracking
              iid={iid}
              changeInfo={changeInfo}
              openSnackbar={openSnackbar}
              changeModal={changeModal}
            />
          )}
        </>
      ) : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
  startSession,
})(withRouter(Institution))
