import React from 'react'
import styled from 'styled-components'
const CirclePointerStyled = styled.div`
  width: ${(props) => (props.width ? props.width : '20px')};
  height: ${(props) => (props.width ? props.width : '20px')};
  border-radius: 50%;
  background: ${(props) => (props.background ? props.background : '--gray')};
  margin-right: 5px;
`
const CirclePointer = ({ background, width }) => {
  return <CirclePointerStyled background={background} width={width} />
}

export default CirclePointer
