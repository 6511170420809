import { useState, useEffect } from 'react'
import PlanContainer from './PlanContainer'
import {
  InputsCreateParallel,
  InputsParallel,
} from '../../../../utils/Constant'
import {
  Icon,
  RenderButton,
  DefaultBtn,
  ActionButton,
} from '../../../../components/buttons'
import { Loading } from '../../../../components/animation'
import { capitalizeJson } from '../../../../utils/ProcessData'
import { BoxFlex, ToolTip } from '../../../../components/boxes'
import { inputEditParallel } from '../../../../utils/FormParameters'
import { FormDefault, GlobalForm } from '../../../../components/form'
import { H2, H3, H4, SubtitleData } from '../../../../components/text'
import { useAxiosAuth } from '../../../../hooks'
import { validateArray, validateStatus } from '../../../../utils/Validation'
import { postAction, postEmptyAction, putAction } from '../../../../services/action/ActionAuthorization'

const ProgramContainer = (props) => {
  const {
    program: { id, name, description },
    program,
    changeInfo,
    iid,
    openSnackbar,
    changeModal,
  } = props
  const { response: programs, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/programManager/${id}/versions`,
  })
  const [newState, setNewState] = useState(program.new)
  const [featuredState, setFeaturedState] = useState(program.status)
  useEffect(() => {

    if (!loading) {
      reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  useEffect(() => {
    setNewState(program.new)
    setFeaturedState(program.status)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload])


  const NewProgramManager = async () => {
    await putAction(
      `/ProgramManager/${program.id}/newStateToggle`,
    ).then((res) => {
      setNewState(!newState)
      program.new = !program.new
      reloadHandle()
      if (!validateStatus(res.status)) {
        openSnackbar('Hubo un error al añadir rol', true, false)
      }
      if (validateStatus(res.status)) {
      }
    })
  }

  const FeaturedProgramManager = async () => {
    await putAction(
      `/ProgramManager/${program.id}/featuredStateToggle`,
    ).then((res) => {
      setFeaturedState(featuredState === "featured" ? null : "featured")
      program.status = program.status === "featured" ? null : "featured"
      reloadHandle()
      if (!validateStatus(res.status)) {
        openSnackbar('Hubo un error al añadir rol', true, false)
      }
      if (validateStatus(res.status)) {
      }
    })
  }

  function reloadHandle() {
    reload()
    changeModal(false, null)
  }
  function handleEdit(type, course) {
    changeModal(
      true,
      '',
      <FormDefault
        inputs={inputEditParallel}
        onCancel={() => changeModal(false, null)}
        method="PUT"
        url={`/${type}/${course.id}/edit`}
        openSnackbar={openSnackbar}
        title="Editar paralelo"
        handleReload={reloadHandle}
        data={capitalizeJson(course)}
      />,
      null,
    )
  }

  // function handleDowloadListStudent(type, course) {
  //   changeModal(
  //     true,
  //     '',
  //     <FormDefault
  //       inputs={[]}
  //       onCancel={() => changeModal(false, null)}
  //       method="POST"
  //       url={`/${type}/${course.id}/studentList/xlsx`}
  //       openSnackbar={openSnackbar}
  //       title="Descargar lista de estudiantes"
  //       handleReload={reloadHandle}
  //       data={capitalizeJson(course)}
  //       textBtn="Descargar"
  //       textSuccess='Se descargo con éxito'
  //       textWrong='No se pudo descargar'
  //     />,
  //     null,
  //   )
  // }

  async function handleDownload(type, course) {
    changeModal(true, 'Espere por favor...')
    await postEmptyAction(
      `/${type}/${course.id}/studentList/xlsx`,
    ).then((response) => {
      if (validateStatus(response.status)) {
        changeModal(
          true,
          'Lista de estudiantes',
          <H3 textAlign="center">
            <a
              style={{ color: 'var(--green)' }}
              href={response.data.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Descargar archivo
            </a>
          </H3>,
          null,
        )
        window.open(response.data.url, '_blank')
      }
      else {
        changeModal(
          true,
          'No se pudo generar el archivo',
          null,
        )
      }
    })
  }

  const Parallel = (props) => {
    const { parallels, title } = props
    return (
      validateArray(parallels) && (
        <>
          <H3>{title}</H3>
          <div className="ProgramAside__list">
            {parallels.map((prog) => (
              <BoxFlex key={prog.id} wrap="nowrap">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/institution/${iid}/${prog.order || prog.order === 0 ? 'course' : 'program'
                    }/${prog.id}`}
                  className="ProgramAside__link"
                >
                  <span>
                    {prog.name} - <strong>{prog.versionName}</strong>
                  </span>
                  {prog.defaultEnroll && (
                    <ToolTip content="Inscripción predeterminada">
                      <i className="fas fa-star"></i>
                    </ToolTip>
                  )}
                  {prog.isRoot && (
                    <ToolTip content="Plantilla">
                      <i className="fas fa-clone"></i>
                    </ToolTip>
                  )}
                </a>
                <div style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, auto)',
                  gap: '5px'
                }}>
                  <div className="col">
                    <ToolTip content="Editar">
                      <div className="">
                        <RenderButton
                          onClick={() =>
                            handleEdit(
                              prog.order || prog.order === 0 ? 'course' : 'program',
                              prog,
                            )
                          }
                          background="var(--green)"
                        >
                          <i className="fas fa-edit"></i>
                        </RenderButton>
                      </div>
                    </ToolTip>
                  </div>
                  {
                    prog.order == null &&
                    <div className="col">
                      <ToolTip content="Descargar lista de estudiantes">
                        <div className="">
                          <RenderButton
                            onClick={() =>
                              handleDownload(
                                'program',
                                prog,
                              )
                            }
                            background="var(--green)"
                          >
                            <i class="fas fa-tasks"></i>
                          </RenderButton>
                        </div>
                      </ToolTip>
                    </div>
                  }
                </div>
              </BoxFlex>
            ))}
          </div>
          <br />
        </>
      )
    )
  }

  return (
    <>
      <Icon
        ico="coins"
        handleModal={() => changeInfo(<PlanContainer {...props} />)}
      >
        Lista de planes
      </Icon>
      {
        newState ?
          <RenderButton
            onClick={() => NewProgramManager()}
            background={"var(--green)"}
            color="#4695851"
            margin-bottom="10px"
            margin-right="5px"
          >
            Nuevo <i className="fa fa-certificate" style={{ color: "gold" }}></i>
          </RenderButton>
          :
          <RenderButton
            onClick={() => NewProgramManager()}
            background={"#4695851a"}
            color="var(--green)"
            margin-bottom="10px"
            margin-right="5px"
          >
            Nuevo <i className="fa fa-certificate" style={{ color: "var(--green)" }}></i>
          </RenderButton>
      }
      {
        featuredState === "featured" ?
          <RenderButton
            onClick={() => FeaturedProgramManager()}
            background={"var(--green)"}
            color="#4695851"
            margin-bottom="10px"
            margin-right="5px"
          >
            Destacado <i className="fa fa-bookmark" style={{ color: "gold" }}></i>
          </RenderButton>
          :
          <RenderButton
            onClick={() => FeaturedProgramManager()}
            background={"#4695851a"}
            color="var(--green)"
            margin-bottom="10px"
            margin-right="5px"
          >
            Destacado <i className="fa fa-bookmark" style={{ color: "var(--green)" }}></i>
          </RenderButton>
      }
      <br />
      <H2 color="var(--gray)" textTransform="uppercase" textAlign="center">
        {name}
      </H2>
      {loading ? (
        <Loading />
      ) : validateArray(programs.completedCourse) ||
        validateArray(programs.unfinishedCourse) ||
        validateArray(programs.completedProgram) ||
        validateArray(programs.unfinishedProgram) ? (
        <>
          <Parallel
            title="Paralelos en curso"
            parallels={programs.unfinishedCourse}
          />
          <Parallel
            title="Paralelos finalizados"
            parallels={programs.completedCourse}
          />
          <Parallel
            title="Paralelos en curso"
            parallels={programs.unfinishedProgram}
          />
          <Parallel
            title="Paralelos finalizados"
            parallels={programs.completedProgram}
          />
          <SubtitleData subtitle="Descripción">{description}</SubtitleData>
          <br />
          <ActionButton
            onClick={() =>
              changeInfo(<CreateParallel {...props} program={program} />)
            }
            onCancel={() => changeInfo(<></>)}
            textBtn="Crear paralelo"
            textCancel="Cancelar"
          />
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <H4 textAlign="center">No hay paralelos</H4>
          <DefaultBtn
            onClick={() =>
              changeInfo(
                <FormAddParallel
                  {...props}
                  param="program"
                  openSnackbar={openSnackbar}
                  program={program}
                />,
              )
            }
            width="100%"
          >
            Crear curso extendido
          </DefaultBtn>
          <DefaultBtn
            onClick={() =>
              changeInfo(
                <FormAddParallel {...props} param="course" program={program} />,
              )
            }
            width="100%"
          >
            Crear curso
          </DefaultBtn>
          <DefaultBtn
            onClick={() => changeInfo(<></>)}
            width="100%"
            background={true}
          >
            Cancelar
          </DefaultBtn>
        </div>
      )}
    </>
  )
}

function CreateParallel(props) {
  const { program, openSnackbar, changeInfo } = props
  const [load, setload] = useState(true)
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction(`/programManager/${program.id}/duplicate`, values).then(
        (response) => {
          setload(true)
          if (validateStatus(response.status)) {
            changeInfo(<ProgramContainer {...props} />)
            openSnackbar('Se creó el paralelo', true, true)
          } else {
            openSnackbar('No se pudo crear', true, false)
          }
        },
      )
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <GlobalForm
      InputsJson={InputsCreateParallel}
      title="Crear paralelo"
      textBtn="Crear"
      data={{}}
      onSubmit={onSubmit}
      load={load}
      onCancel={() => changeInfo(<ProgramContainer {...props} />)}
    />
  )
}

function FormAddParallel(props) {
  const { program, param, iid, openSnackbar, changeInfo } = props
  const { name, id } = program
  const [load, setload] = useState(true)
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction(`/${param}`, {
        ...values,
        ProgramManagerId: id,
        InstitutionId: parseInt(iid),
        IsIndependent: true,
      }).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar(
            'Se creó el' + param === 'program' ? 'paralelo' : 'curso',
            true,
            true,
          )
          changeInfo(<ProgramContainer {...props} />)
        } else {
          openSnackbar('No se pudo crear', true, false)
        }
      })
    } catch (e) {
      setload(true)
    }
  }
  return (
    <>
      <H2 textAlign="center">
        Crear {param === 'program' ? 'paralelo' : 'curso'}
      </H2>
      <SubtitleData subtitle="Programa">{name}</SubtitleData>
      <GlobalForm
        InputsJson={InputsParallel}
        title=""
        textBtn="Crear"
        data={{ Name: name }}
        onSubmit={onSubmit}
        load={load}
        onCancel={() =>
          changeInfo(<ProgramContainer program={program} {...props} />)
        }
      />
    </>
  )
}
export default ProgramContainer
