import { useState, useEffect, Fragment } from 'react'
import './style/InstitutionPrereg.css'
import { useAxiosAuth } from '../../hooks'
import config from '../../config.json'
import { convertD } from '../../utils/ConvertDate'
import { NormalButton, RenderButton } from '../../components/buttons'
import { H2, H3, SubtitleData } from '../../components/text'
import { Loading, LoadCircle } from '../../components/animation'
import { validateStatus, validateArray } from '../../utils/Validation'
import { postProgramEnroll } from '../../services/action/InstitutionAction'
import {
  postAction,
  putAction,
} from '../../services/action/ActionAuthorization'
const InstitutionPrereg = (props) => {
  const { user, openSnackbar, reload: handleReload } = props
  const [load, setload] = useState(true)

  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/preregisterquiz/${user.id}`,
  })

  const {
    response: respExternal,
    loading: loadExternal,
    reload: reloadExternal,
  } = useAxiosAuth({
    method: 'GET',
    url: user.registeredExternalUserId
      ? `${config.requestURL2}/users/${user.registeredExternalUserId}`
      : null,
  })
  useEffect(() => {
    reload()
    reloadExternal()
  }, [user])

  const unsubscribe = async (user) => {
    try {
      setload(false)
      await postAction(`/program/${user.signedProgramId}/DelistUser`, {
        UserId: response.registerFromRepo.userId,
        ProgramId: user.signedProgramId,
        CourseId: response.registerFromRepo.signedCourse
          ? response.registerFromRepo.signedCourse.id
          : 0,
      }).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          reload()
          reloadExternal()
          handleReload()
          openSnackbar('Se dio de baja al usuario', true, true)
        } else {
          openSnackbar('No se pudo completar', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  const send = async (id) => {
    setload(false)
    await postProgramEnroll(id).then((res) => {
      setload(true)
      if (res.status === 200) {
        openSnackbar('Inscripción completada', true, true)
        handleReload()
        reload()
      } else {
        openSnackbar('Inscripción fallida', true)
      }
    })
    if (
      user.registeredExternalUserId &&
      user.registeredExternalUserId !== null
    ) {
      await putAction(
        `${config.requestURL2}/users/${user.registeredExternalUserId}/directPayment`,
        {},
      ).then((res) => {
        setload(true)
        if (res.status === 200) {
          openSnackbar('Inscripción completada en WM', true, true)
          handleReload()
          reloadExternal()
        } else {
          openSnackbar('Inscripción fallida en WM', true)
        }
      })
    }
  }

  return (
    <div className="InstitutionPrereg">
      <H2 color="var(--gray)" margin="0 0 15px" textAlign="center">
        Completar Inscripción
      </H2>
      {!loading && !loadExternal ? (
        <>
          {response.userInfo.tutorId && (
            <CardInformation
              title="Padre o tutor"
              AdditionalInformation={
                <>
                  <SubtitleData subtitle="Correo Electronico:">
                    {response.registerFromRepo.email}
                  </SubtitleData>
                  <SubtitleData subtitle="Carnet de identidad:">
                    {response.registerFromRepo.idDocument}{' '}
                    {response.registerFromRepo.idExpedition}
                  </SubtitleData>
                </>
              }
            >
              <SubtitleData subtitle="Nombre Completo:">
                {response.userInfo.tutorName} {response.userInfo.tutorLastName}
              </SubtitleData>
              <SubtitleData subtitle="Nro de celular:">
                {response.registerFromRepo.phoneNumber}
              </SubtitleData>
            </CardInformation>
          )}
          <CardInformation
            title={`Datos del ${
              respExternal
                ? 'padre o tutor'
                : response.userInfo.tutorId
                ? 'niño'
                : 'estudiante'
            }`}
            AdditionalInformation={
              <>
                {response.userInfo.tutorId ? null : (
                  <>
                    <SubtitleData subtitle="Correo Electronico:">
                      {response.registerFromRepo.email}
                    </SubtitleData>
                    <SubtitleData subtitle="Carnet de identidad:">
                      {response.registerFromRepo.idDocument}{' '}
                      {response.registerFromRepo.idExpedition}
                    </SubtitleData>
                  </>
                )}
                {user.dateOfBirth !== '0001-01-01T00:00:00' && (
                  <SubtitleData subtitle="Fecha de nacimiento:">
                    {convertD(user.dateOfBirth, 'LL')}
                  </SubtitleData>
                )}
              </>
            }
          >
            <SubtitleData subtitle="Nombre Completo:">
              {response.userInfo.name} {response.userInfo.lastname}
            </SubtitleData>
            <SubtitleData subtitle="Edad:">{user.age}</SubtitleData>
            {response.userInfo.tutorId ? null : (
              <SubtitleData subtitle="Nro de celular:">
                {response.registerFromRepo.phoneNumber}
              </SubtitleData>
            )}
          </CardInformation>

          {respExternal && (
            <CardInformation title="Datos del niño">
              <SubtitleData subtitle="Nombre Completo:">
                {respExternal.firstName} {respExternal.lastName}
              </SubtitleData>
              {respExternal.age ? (
                <SubtitleData subtitle="Edad:">
                  {respExternal.age} años
                </SubtitleData>
              ) : null}
            </CardInformation>
          )}
          {validateArray(response.registerFromRepo.signedList) ? (
            <ListCourse signedList={response.registerFromRepo.signedList} />
          ) : (
            <DetailCourse
              registerFromRepo={response.registerFromRepo}
              user={user}
            />
          )}
          <CardInformation
            title="Datos de la inscripción"
            AdditionalInformation={
              <>
                <SubtitleData subtitle="Fecha de pre-inscripción:">
                  {convertD(response.registerFromRepo.registerDate, 'LLL')}
                </SubtitleData>
              </>
            }
          >
            {response.registerFromRepo.salesAuthor && (
              <>
                <SubtitleData subtitle="Nombre del vendedor:">
                  {response.registerFromRepo.salesAuthor.name}{' '}
                  {response.registerFromRepo.salesAuthor.lastName}
                </SubtitleData>
              </>
            )}
            <SubtitleData subtitle="Estado de pre-inscripción:">
              {response.registerFromRepo.status !== 'completed'
                ? 'Por verificar'
                : 'Verificado'}
            </SubtitleData>
          </CardInformation>
          {validateArray(response.fileResume) ? (
            <div style={{ transform: 'translateY(-12px)' }}>
              <H3 fontSize="1.8rem" margin="0" color="var(--gray)">
                Documentos adjuntos
              </H3>
              <br />
              {response.fileResume.map((item, index) => (
                <CardInformation key={index} title="Informacion del NIT">
                  <SubtitleData subtitle="NIT">{item.nit}</SubtitleData>
                  <SubtitleData subtitle="Nombre de NIT">
                    {item.nitName}
                  </SubtitleData>
                  <SubtitleData subtitle="Observación: ">
                    {item.observation}
                  </SubtitleData>
                  <SubtitleData subtitle="Observacion: "></SubtitleData>
                  <SubtitleData subtitle="">
                    <div className="ListDocument__item">
                      <a
                        key={item.id}
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.description}
                      </a>
                    </div>
                  </SubtitleData>
                </CardInformation>
              ))}
            </div>
          ) : null}
          {!load ? (
            <Loading />
          ) : (
            <div className="InstitutionPrereg__btns">
              <NormalButton
                disabled={
                  response.registerFromRepo.status !== 'completed'
                    ? true
                    : false
                }
                onClick={() => unsubscribe(user)}
                background={true}
              >
                Dar de baja
              </NormalButton>
              <NormalButton
                disabled={
                  response.registerFromRepo.status === 'completed'
                    ? true
                    : false
                }
                onClick={() => send(user.id)}
              >
                Completar Inscripción
              </NormalButton>
            </div>
          )}
        </>
      ) : (
        <LoadCircle />
      )}
    </div>
  )
}

const ListCourse = ({ signedList }) => {
  return (
    <div>
      <H3>Datos de los cursos</H3>
      {signedList.map((item) => (
        <CardInformation
          title={item.name}
          AdditionalInformation={
            <>
              {item.courseDetail && validateArray(item.courseDetail) ? (
                <SubtitleData subtitle="Horario:">
                  {item.courseDetail.map((detail, index) => (
                    <div key={index} style={{ margin: '10px 0' }}>
                      {detail.classDay} de{' '}
                      {convertD(detail.startClass, 'HH:mm', 'HH:mm:ss')} a{' '}
                      {convertD(detail.finishClass, 'HH:mm', 'HH:mm:ss')}
                    </div>
                  ))}
                </SubtitleData>
              ) : null}
            </>
          }
        >
          {item.versionName && (
            <SubtitleData subtitle="Paralelo:">{item.versionName}</SubtitleData>
          )}
          {item.programManager && (
            <H3 fontWeight="500" color="var(--black)" textAlign="center">
              {item.programManager.name}
            </H3>
          )}
          {validateArray(item.teachers) ? (
            <SubtitleData subtitle="Docentes:">
              {item.teachers.map((item, index) => (
                <div key={index} style={{ margin: '10px 0' }}>
                  {item.name} {item.lastName}
                </div>
              ))}
            </SubtitleData>
          ) : null}
        </CardInformation>
      ))}
    </div>
  )
}

const DetailCourse = ({ registerFromRepo, user }) => {
  return (
    <CardInformation
      title="Datos del curso"
      AdditionalInformation={
        <>
          {registerFromRepo.courseDetail &&
          validateArray(registerFromRepo.courseDetail) ? (
            <SubtitleData subtitle="Horario:">
              {registerFromRepo.courseDetail.map((item, index) => (
                <div key={index} style={{ margin: '10px 0' }}>
                  {item.classDay} de{' '}
                  {convertD(item.startClass, 'HH:mm', 'HH:mm:ss')} a{' '}
                  {convertD(item.finishClass, 'HH:mm', 'HH:mm:ss')}
                </div>
              ))}
            </SubtitleData>
          ) : null}
          {registerFromRepo.signedProgram ? (
            <SubtitleData subtitle="Paralelo:">
              {registerFromRepo.signedProgram.versionName}
            </SubtitleData>
          ) : (
            <SubtitleData subtitle="Paralelo:">
              {registerFromRepo.signedCourse.versionName}
            </SubtitleData>
          )}
        </>
      }
    >
      {registerFromRepo.signedCourse && (
        <H3 fontWeight="500" color="var(--black)" textAlign="center">
          {registerFromRepo.signedCourse.programManager.name}
        </H3>
      )}
      <SubtitleData subtitle="Curso:">
        {user.signedProgramManagerName}
      </SubtitleData>
      {registerFromRepo.teachers && validateArray(registerFromRepo.teachers) ? (
        <SubtitleData subtitle="Docentes:">
          {registerFromRepo.teachers.map((item, index) => (
            <div key={index} style={{ margin: '10px 0' }}>
              {item.name} {item.lastName}
            </div>
          ))}
        </SubtitleData>
      ) : null}
    </CardInformation>
  )
}

export const CardInformation = (props) => {
  const { children, AdditionalInformation, title } = props

  const [view, setview] = useState(false)
  return (
    <div className="CardInformation">
      <div className="CardInformation__title">
        <H3 margin="0">{title}</H3>
      </div>
      <div className="CardInformation__main">
        {children}
        {view && AdditionalInformation}
      </div>
      {AdditionalInformation !== null && AdditionalInformation !== undefined ? (
        <div className="CardInformation__btn">
          <RenderButton
            onClick={() => setview(!view)}
            background="var(--gray)"
            borderRadius="50px"
            padding="5px 10px"
          >
            <i className={`fas fa-angle-${view ? 'up' : 'down'}`}></i>
          </RenderButton>
        </div>
      ) : null}
    </div>
  )
}

export default InstitutionPrereg
