import "./CourseInfo.css";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  changeInfo,
  changeLayout,
  openSnackbar,
  startSession,
  changeModal,
} from "../../../../store/actions";
import { RenderButton } from "../../../../components/buttons";
import { validateArray } from "../../../../utils/Validation";
import { H2, H3, H4, Section } from "../../../../components/text";
import { LoadingScreen } from "../../../../components/common";
import { useAxiosAuth } from "../../../../hooks";
import { RenderModal } from "../../../../components/modal";
import ModalRegisterOEA from "../../../../containers/catalogue/courseInfo/ModalRegisterOEA";

const AccountingAdjustments = (props) => {
  const { changeLayout } = props;
  const [modal, setmodal] = useState(false);
  const course = {
    id: 17,
    name: "AJUSTES CONTABLES",
    photoUrl:
      "https://res.cloudinary.com/pataformacgi/image/upload/q_auto,f_auto/v1687534341/lggjmqko0to1ybftgorf.jpg",
    description:
      "El curso de Ajustes Contables está diseñado para proporcionar a los profesionales de la contabilidad y a aquellos interesados en el campo financiero los conocimientos y habilidades necesarios para realizar ajustes contables precisos y eficientes. Durante el curso, los participantes adquirirán una comprensión profunda de los conceptos fundamentales de los ajustes contables y su importancia en la generación de estados financieros precisos y confiables.",
    courseAchievements: "",
    addressedTo:
      "Este curso es ideal para contadores, auditores, analistas financieros, estudiantes de contabilidad y cualquier persona interesada en mejorar sus habilidades contables y su comprensión de los ajustes contables. No se requieren conocimientos previos específicos, pero se recomienda tener una base en contabilidad básica y conocimientos generales de los principios contables.",
    courses: [],
    // courses: [
    //   {
    //     id: 1,
    //     name: "Introducción",
    //     modules: [
    //       {
    //         id: 1,
    //         name: "Justificación",
    //       },
    //       {
    //         id: 2,
    //         name: "¿Qué es una cadena de suministro?",
    //       },
    //       {
    //         id: 3,
    //         name: "Protección de la cadena de suministro",
    //       },
    //       {
    //         id: 4,
    //         name: "Mejores prácticas para la gestión de la cadena de suministro",
    //       },
    //     ],
    //   },
    //   {
    //     id: 2,
    //     name: "Estructura normativa de OEA",
    //     modules: [
    //       {
    //         id: 1,
    //         name: "Objeto y campo de aplicación",
    //       },
    //       {
    //         id: 2,
    //         name: "Principales elementos de ISO 9001",
    //       },
    //       {
    //         id: 3,
    //         name: "Principales elementos de ISO 27001",
    //       },
    //       {
    //         id: 4,
    //         name: "Principales elementos de ISO 28000",
    //       },
    //       {
    //         id: 5,
    //         name: "Principales elementos de ISO 37001",
    //       },
    //       {
    //         id: 6,
    //         name: "Principales elementos de ISO 39001",
    //       },
    //       {
    //         id: 7,
    //         name: "Términos y definiciones",
    //       },
    //     ],
    //   },
    //   {
    //     id: 3,
    //     name: "Ámbito de aplicación OEA en Bolivia",
    //     modules: [
    //       {
    //         id: 1,
    //         name: "Ámbito de aplicación",
    //       },
    //       {
    //         id: 2,
    //         name: "Declaración de aplicación",
    //       },
    //       {
    //         id: 3,
    //         name: "Socios estratégicos",
    //       },
    //       {
    //         id: 4,
    //         name: "Certificados o aprobaciones aceptadas internacionalmente",
    //       },
    //       {
    //         id: 5,
    //         name: "Extensión de los socios del requisito de declaración de seguridad",
    //       },
    //       {
    //         id: 6,
    //         name: "Revisiones de seguridad de los socios",
    //       },
    //     ],
    //   },
    //   {
    //     id: 4,
    //     name: "Identificación, realización y desarrollo de procesos de seguridad",
    //     modules: [
    //       {
    //         id: 1,
    //         name: "Identificación del alcance de la evaluación de seguridad",
    //       },
    //       {
    //         id: 2,
    //         name: "Realización de la evaluación de seguridad",
    //       },
    //       {
    //         id: 3,
    //         name: "Desarrollo del plan de seguridad de la cadena de suministro",
    //       },
    //     ],
    //   },
    //   {
    //     id: 5,
    //     name: "Ejecución, documentación, seguimiento e información de procesos de seguridad. ",
    //     modules: [
    //       {
    //         id: 1,
    //         name: "Ejecución del plan de seguridad de la cadena de suministro",
    //       },
    //       {
    //         id: 2,
    //         name: "Documentación y seguimiento de los procesos de seguridad para la cadena de suministro",
    //       },
    //       {
    //         id: 3,
    //         name: "Acciones requeridas después de un incidente de seguridad",
    //       },
    //       {
    //         id: 4,
    //         name: "Protección de la seguridad",
    //       },
    //     ],
    //   },
    //   {
    //     id: 6,
    //     name: "Evaluación del desempeño y certificación ante Aduana",
    //     modules: [
    //       {
    //         id: 1,
    //         name: "Preparación de la postulación.",
    //       },
    //       {
    //         id: 2,
    //         name: "Demostrar la conformidad con la norma OEA mediante la auditoría",
    //       },
    //       {
    //         id: 3,
    //         name: "Softwares para implementación (Trazos / Sofidya)",
    //       },
    //     ],
    //   },
    // ],
  };
  const uId = window.localStorage.getItem("id");
  const { response, loading } = useAxiosAuth({
    method: "GET",
    url: uId ? `/users/${uId}/preregister` : null,
  });

  useEffect(() => {
    changeLayout("FullScreen");
  }, [changeLayout]);

  const preRegistrations = response
    ? response.filter((pre) => {
        return pre.signedProgramId === 17;
      })
    : null;

  if (!loading) {
    return (
      <>
        <div className="CourseInfo CourseInfo--oea">
          <div className="CourseInfo__header">
            <div className="CourseInfo__Htitle">
              <H2 fontSize="2.2rem" color="var(--gray)">
                {course.name}
              </H2>
              <br />
              <div className="CourseInfo__Banner">
                <img
                  className="CourseInfo__Himg"
                  src={course.photoUrl}
                  alt="Imagen de portada"
                />
                <div
                  style={{ border: "1px solid #6a3081" }}
                  className="CourseInfo__BText"
                >
                  <div>
                    <H2 color="var(--purple)" margin="0">
                      CERTIFICACIÓN
                    </H2>
                    {/* <H3 fontWeight="500" color="var(--gray)" margin="0">
                      Nivel nacional e internacional
                    </H3> */}
                    <H3 fontWeight="500" margin="0" color="var(--purple)">
                      CON RESOLUCIÓN MINISTERIAL NRO. 1151/2022
                    </H3>
                  </div>
                  {/* <div>
                    <H3
                      textAlign="end"
                      fontWeight="500"
                      color="var(--purple)"
                      margin="0 0 2px 0"
                    >
                      <strong>15</strong> Cupos disponibles
                    </H3>
                    <H3 fontWeight="500" color="gray" margin="0">
                      Inscripciones abiertas
                    </H3>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="CourseInfo__Hcontact CourseInfo__Hcontact--oea">
              <ContactHeader
                modal={modal}
                setmodal={setmodal}
                preRegistrations={preRegistrations}
              />
            </div>
          </div>
          <div className="InfoOEA__teachers">
            <TeacherOEA
              name={
                <>
                  <span style={{ color: "var(--green)" }}>Mg. Sc. Edwin</span>{" "}
                  <span style={{ color: "var(--gray)" }}>Gutierrez Zapana</span>
                </>
              }
              description="El Mg. Sc. Edwin Gutiérrez Zapana es un destacado docente en la carrera de Contaduría Pública tanto en la Universidad Mayor de San Andrés (UMSA) como en la Universidad Pública de El Alto (UPEA). Además de su dedicación a la enseñanza en pregrado, también imparte clases en programas de postgrado en varias universidades privadas. El Mg. Sc. Edwin Gutiérrez Zapana posee una sólida formación académica respaldada por sus títulos de postgrado obtenidos. Ha obtenido una Maestría en Auditoría y Control Financiero, lo que le brinda un amplio conocimiento y experiencia en el ámbito de la auditoría y el control financiero. Asimismo, cuenta con una Maestría en Auditoría Basado Sobre Riesgos, lo cual demuestra su especialización en la evaluación de riesgos y la implementación de estrategias de auditoría efectivas."
              photoUrl="https://res.cloudinary.com/dv4mdvthq/image/upload/v1687866957/assets/foto_r0hwl9.jpg"
            />
            {/* <TeacherOEA
              name={
                <>
                  <span style={{ color: "var(--red)" }}>María</span>{" "}
                  <span style={{ color: "var(--gray)" }}>Marcano</span>
                </>
              }
              description="Ing. María Marcano, Ingeniera Industrial de profesión con Formación certificada internacional en Sistemas de Gestión de la Seguridad en la Cadena de Suministro. ISO 28000:2022 y Operador Económico Autorizado (OEA). Es Consultora y Auditora en Sistemas de Gestión Integrado como parte de los expertos de INTEDYA en Bolivia. Con experiencia en la implementación de sistemas de gestión para la acreditación de ISO9001:2015 y OEA según legislación y normativas Bolivianas."
              photoUrl="https://res.cloudinary.com/dv4mdvthq/image/upload/v1685736784/MARIA_MARCANO_xyg4z4.jpg"
            /> */}
          </div>
          <div className="CourseInfo__content">
            <div className="CourseInfo__information">
              <ListCard list={course ? course.description : ""}>
                Descripción del curso
              </ListCard>
              {/* <ListCard list={course ? course.courseAchievements : ""}>
                {t("learn")}
              </ListCard> */}
              <ListCard list={course ? course.addressedTo : ""}>
                ¿Para quien es este curso?
              </ListCard>
            </div>
            {validateArray(course.courses) ? (
              <div className="CourseInfo__Content">
                <H4
                  color="var(--red)"
                  fontWeight="600"
                  margin="0"
                  fontSize="1.8rem"
                >
                  CONTENIDO DEL CURSO
                </H4>
                <hr
                  style={{
                    border: "1px solid var(--green)",
                    marginBottom: "10px",
                  }}
                />
                {course
                  ? course.courses
                      .sort((a, b) => a.order - b.order)
                      .map(function (l) {
                        return (
                          <CardModule
                            key={l.id}
                            modules={l.modules}
                            description={l.description}
                          >
                            {l.name}
                          </CardModule>
                        );
                      })
                  : null}
              </div>
            ) : null}
          </div>
        </div>
        {modal && (
          <RenderModal
            width="700px"
            onClose={() => setmodal(!modal)}
            closeOutside
          >
            <ModalRegisterOEA
              {...props}
              onClose={() => setmodal(!modal)}
              dataShopping={{
                couponDiscount: 0,
                total: 100,
                totalAmount: 100,
                priceProgramManager: [
                  {
                    name: "Ajustes Contables",
                    programManagerId: 224,
                    totalAmount: 100,
                  },
                ],
              }}
              orders={[
                {
                  CodeAffiliate: null,
                  PaymentPlanId: 125,
                  ProgramManagerId: 224,
                },
              ]}
              handleCoupon={null}
              // history={}
              urlPost="/order"
              textClose="Volver al pedido"
              // dataPayment={}
              orderResult="order-result"
            />
          </RenderModal>
        )}
      </>
    );
  } else {
    return <LoadingScreen />;
  }
};

function ContactHeader({ setmodal, modal }) {
  return (
    <div className="ContactHeader">
      <div
        className=""
        style={{
          background: "#6a308120",
          borderRadius: "20px",
          padding: "10px",
        }}
      >
        <H2 textAlign="center" color="var(--gray)">
          Contacto
        </H2>
        <H3 fontWeight="500" color="var(--gray)">
          Para mayor información sobre nuestra promoción y planes de pago
          contáctanos
        </H3>
        <H4 fontWeight="400" textAlign="center">
          <i className="fab fa-whatsapp"></i> <span>+591 70140353</span>
        </H4>
        {/* <H4 textAlign="center" fontWeight="400">
          <i className="fab fa-whatsapp"></i> <span>+591 77712429</span>
        </H4> */}
        <div className="ContactHeader__btn">
          <RenderButton
            background="#6a3081"
            borderRadius="10px"
            padding="8px 20px"
            fontSize="1.8rem"
            fontWeight="700"
            onClick={() => setmodal(!modal)}
          >
            Inscríbete <i className="fas fa-check-circle"></i>
          </RenderButton>
        </div>
        {/* <H3 fontWeight="500" color="red" margin="10px 0 0 0" textAlign="center">
          Últimos días de inscripción
        </H3> */}
      </div>
      <br />
      <br />
      <H2 textAlign="center" color="var(--gray)">
        Información general
      </H2>
      <H3 fontWeight="500" color="var(--gray)">
        <strong>Inicio:</strong> 30 de junio de 2023.
      </H3>
      <H3 fontWeight="500" color="var(--gray)">
        <strong>Duración:</strong> 3 días; 30 de junio, 1 y 2 de julio.
      </H3>
      {/* <H3 fontWeight="500" color="var(--gray)">
        <strong>Modalidad:</strong> Semipresencial
      </H3> */}
      <H3 fontWeight="500" color="var(--gray)">
        <strong>Horarios:</strong> Viernes 20:30 a 22:30 ; Sábado y domingo
        09:30 a 11:30
      </H3>
      <H3 fontWeight="500" color="var(--gray)">
        <strong>Docente:</strong> Mg. Sc. Edwin Gutierrez Zapana
      </H3>
      <H3 fontSize="1.8rem" fontWeight="500" color="var(--gray)">
        <strong>Inversión:</strong> 140 Bs. el mismo dia del evento y{" "}
        <span style={{ color: "var(--purple)" }}>
          100 Bs. preventa hasta el 28 de junio
        </span>
      </H3>
    </div>
  );
}

const ListCard = (props) => {
  return (
    <div className="ListCard">
      <H4 color="var(--green)" fontWeight="600" margin="0" fontSize="1.8rem">
        {props.children}
      </H4>
      <hr />
      <div style={{ margin: "8px 0" }}>
        <Section>{props.list}</Section>
      </div>
    </div>
  );
};

function CardModule({ children, description, modules }) {
  const [view, setView] = useState(false);
  return (
    <div className="CardModule">
      <div
        style={{ background: "var(--green)" }}
        className="CardModule__card"
        onClick={() => {
          setView(!view);
        }}
      >
        <div className="CardModule__title">
          <H4
            textTransform="uppercase"
            margin="0"
            fontSize="1.6rem"
            color="var(--white)"
          >
            {children}
          </H4>
          <i className={`fas fa-angle-${view ? "down" : "right"}`}></i>
        </div>
        {view ? <Section color="var(--white)">{description}</Section> : null}
      </div>
      {view ? (
        <div className="CardModule__list">
          {validateArray(modules)
            ? modules
                .sort((a, b) => a.orderNumber - b.orderNumber)
                .map((module) => (
                  <div key={module.id} className="CardLesson">
                    <H4
                      textTransform="uppercase"
                      margin="0"
                      fontSize="1.4rem"
                      color="var(--black)"
                    >
                      {module.name}
                    </H4>
                  </div>
                ))
            : null}
        </div>
      ) : null}
    </div>
  );
}

const TeacherOEA = (props) => {
  const { name, description, photoUrl } = props;
  const [view, setview] = useState(true);
  return (
    <div className="TeacherOEA">
      <div
        style={{
          backgroundImage: `url(${photoUrl})`,
          border: "4px solid var(--green)",
        }}
        className="TeacherOEA__img"
      ></div>
      <div className="TeacherOEA__card">
        <h4 className="TeacherOEA__text">{name}</h4>
        <H4 margin="5px 0">
          <span className="CardPunctuation__main">
            {view ? description.substring(0, 230) + "..." : description + "   "}
          </span>
          <span
            className="CardPunctuation__span"
            onClick={() => {
              setview(!view);
            }}
          >
            {view ? "ver más" : "ver menos"}
          </span>
        </H4>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, {
  changeInfo,
  changeLayout,
  openSnackbar,
  startSession,
  changeModal,
})(AccountingAdjustments);
