import H3 from './H3'
import H4 from './H4'
const SubtitleData = ({ subtitle, children, color }) => {
  if (children) {
    return (
      <div>
        <H3 margin="0 0 5px" color={color}>
          {subtitle}
        </H3>
        <H4 color="var(--gray)" textAlign="center">
          {children}
        </H4>
      </div>
    )
  } else {
    return null
  }
}

export default SubtitleData
