import { useState } from 'react'
import { GlobalForm } from '../../../components/form'
import { H3 } from '../../../components/text'
import { UseOnSubmit } from '../../../hooks/useOnSubmit'
import { InputsReportWM } from '../../../utils/FormParameters'
import { convertD } from '../../../utils/ConvertDate'
import config from '../../../config.json'

const FormReportWM = (props) => {
  const { changeInfo, openSnackbar, changeModal } = props
  const [load, setload] = useState(true)
  function functionSuccess(data) {
    window.open(data.url, '_blank')
    changeModal(
      true,
      'Reporte de alumnos inscritos de WonderMath',
      <H3 textAlign="center">
        <a
          style={{ color: 'var(--green)' }}
          href={data.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Descargar archivo
        </a>
      </H3>,
      null,
    )
    changeInfo(<></>)
  }

  async function onSubmit(values) {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: 'POST',
        url: `${config.requestURL2}/report`,
        data: {
          InitDate: convertD(values.InitDate, 'YYYY-MM-DD'),
          FinishDate: convertD(values.FinishDate, 'YYYY-MM-DD'),
        },
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Categoría creada',
      textWrong: 'No se pudo crear',
      functionSuccess: functionSuccess,
    })
    setload(true)
  }
  return (
    <GlobalForm
      InputsJson={InputsReportWM}
      title="Generar reporte de alumnos inscritos de WonderMath"
      textBtn="Crear reporte"
      data={{}}
      onSubmit={onSubmit}
      load={load}
      onCancel={() => changeInfo(<></>)}
    />
  )
}

export default FormReportWM
