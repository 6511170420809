import "./style/ButtonIconDelete.css";
import { UseOnSubmit } from '../../hooks/useOnSubmit'
import { ToolTip } from "../boxes";
import { useState } from 'react'
import { Loading } from "../animation";
const ButtonIconDelete = (props) => {
  const { idprogram,idorder, changeModal} = props
  const [load, setload] = useState(false);
  const onSubmit = async () => {
    setload(true);
    try {
      await UseOnSubmit({
        axiosParams: {
          method: 'PUT',
          url: `/order/${idorder}/removeProgramManager/${idprogram}/`
        },
        openSnackbar: props.openSnackbar,
        textSuccess: 'Programa Eliminado',
        textWrong: 'No se pudo eliminar',
        functionSuccess: props.reloadPedido,
        process: true,
      }).then(() => {
        setload(false);
      });
    } catch (e) {
      console.log(e);
    }
  }
  function onValidate(values) {
    changeModal(
      true,
      "¿Estás seguro que quieres eliminar ese programa del pedido?",
      "",
      "Eliminar",
      () => onSubmit(values)
    );
  }
    return (
      <>{!load?<ToolTip content={props.content}>
        <button disabled={load} onClick={onValidate} className="ButtonIconDelete">
          <i className={`fas fa-${props.icon}`}></i>
        </button>
      </ToolTip>:<Loading/>}
      </>
    )
};

export default ButtonIconDelete;
