import React, { useEffect, useState } from 'react'
import { validateArray, validateStatus } from '../../../../utils/Validation'
import { LoadCircle } from '../../../../components/animation'
import { DefaultBtn, ActionButton } from '../../../../components/buttons'
import { H2, H3, H4 } from '../../../../components/text'
import {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
} from '../../../../store/actions'
import { connect } from 'react-redux'
import { CreateLesson } from '../../../../components/form'
import {
  ModulesContainer,
  EditWeighting,
  Deletelessons,
  PendingQualify,
} from '../../../../containers/programs'
import { useActionParam } from '../../../../hooks'
import { getAction, putEmptyAction } from '../../../../services/action/ActionAuthorization'
import './ModulesRolUser.css'

import { ViewCourseSchedule } from '../../../../containers/institutions/institution/ViewCourseSchedule'
// import { UseOnSubmit } from '../../../../hooks/useOnSubmit'

const ModulesRolUser = (props) => {
  const {
    match: {
      params: { cid, mid, rol },
    },
    changeInfo,
    openSnackbar,
    changeLayout,
    changeModal,
  } = props

  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: `/course/${cid}/Modules`,
  })

  useEffect(() => {
    changeLayout()
    changeInfo(<PendingQualify cid={cid} {...props} />)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLayout, changeInfo])

  function requestReload() {
    changeInfo(<></>)
    reload()
  }

  return (
    <>
      {validateStatus(statusRequest) ? (
        <>
          <H2 margin="0 0 10px" textAlign="center">
            {dataRequest.courseName}
          </H2>
          {dataRequest.parallel && (
            <H2 color="gray" margin="0 0 20px" textAlign="center">
              {dataRequest.parallel}
            </H2>
          )}
          <div className="ModulesRolUser__option">
            <DefaultBtn
              background={true}
              onClick={() =>
                changeModal(
                  true,
                  '',
                  <Deletelessons {...props} cid={cid} />,
                  null,
                )
              }
            >
              Lecciones eliminadas <i className="fas fa-trash"></i>
            </DefaultBtn>
            {
              rol === "admin" &&
              <DefaultBtn
                onClick={() =>
                  changeInfo(
                    <ParallelReplicate
                      cid={cid}
                      openSnackbar={openSnackbar}
                      changeInfo={changeInfo}
                      modules={dataRequest.courseModulesDto}
                      changeLayout={changeLayout}
                      reload={reload}
                    />,
                  )
                }
              >
                Replicar de otro paralelo <i className="fas fa-clone"></i>
              </DefaultBtn>
            }
            <DefaultBtn
              onClick={() =>
                changeInfo(
                  <PercentageGrades
                    cid={cid}
                    openSnackbar={openSnackbar}
                    changeInfo={changeInfo}
                  />,
                )
              }
            >
              Ponderación de las lecciones
            </DefaultBtn>
            <DefaultBtn
              onClick={() =>
                changeInfo(
                  <CreateLesson
                    cid={cid}
                    mid={mid}
                    rol={rol}
                    reload={requestReload}
                    openSnackbar={openSnackbar}
                    modules={dataRequest.courseModulesDto}
                    changeInfo={changeInfo}
                  />,
                )
              }
            >
              Subir contenido <i className="fas fa-plus"></i>
            </DefaultBtn>
            <DefaultBtn
              onClick={() =>
                changeInfo(
                  <ViewCourseSchedule
                    changeModal={changeModal}
                    openSnackbar={openSnackbar}
                    reload={reload}
                    {...props}
                  />,
                )
              }
            >
              Horario <i className="fas fa-calendar-alt"></i>
            </DefaultBtn>
            <DefaultBtn
              onClick={() =>
                changeInfo(<PendingQualify cid={cid} {...props} />)
              }
            >
              Pendientes por calificar
            </DefaultBtn>
          </div>
          <br />
          {validateArray(dataRequest.courseModulesDto)
            ? dataRequest.courseModulesDto
              .sort((a, b) => a.orderNumber - b.orderNumber)
              .map((mod) => (
                <React.Fragment key={mod.id}>
                  <ModulesContainer
                    mod={mod}
                    rol={rol}
                    mid={mid}
                    reload={requestReload}
                    changeInfo={changeInfo}
                    openSnackbar={openSnackbar}
                    changeModal={changeModal}
                  />
                </React.Fragment>
              ))
            : null}
        </>
      ) : (
        <LoadCircle />
      )}
    </>
  )
}

export default connect(null, {
  changeInfo,
  openSnackbar,
  changeLayout,
  changeModal,
})(ModulesRolUser)

const PercentageGrades = ({ cid, changeInfo, openSnackbar }) => {
  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: `/Course/${cid}/ScoredLessons`,
  })
  function handleCancel() {
    changeInfo(
      <PercentageGrades
        cid={cid}
        openSnackbar={openSnackbar}
        changeInfo={changeInfo}
      />,
    )
  }
  function handleEdit() {
    const result = dataRequest.filter((r) => {
      return r.scorePercentage !== 0
    })
    if (result.length === 0) {
      let score = (100 / dataRequest.length).toFixed(2)
      let total = 0
      dataRequest.forEach((r, index) => {
        if (index === dataRequest.length - 1) {
          r.scorePercentage = 100 - total
        } else {
          total = total + parseFloat(score)
          r.scorePercentage = score
        }
      })
    }
    changeInfo(
      <EditWeighting
        cid={cid}
        openSnackbar={openSnackbar}
        changeInfo={changeInfo}
        lessons={dataRequest}
        onCancel={handleCancel}
      />,
    )
  }
  return (
    <div className="PercentageGrades">
      <H2 textAlign="center">Ponderación de las lecciones</H2>
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          <>
            {dataRequest.map((lesson) => (
              <React.Fragment key={lesson.id}>
                <div className="PercentageGrades__lesson">
                  <H4 margin="10px 0">{lesson.name}</H4>
                  <H3 margin="10px 0">{lesson.scorePercentage}%</H3>
                </div>
                <hr />
              </React.Fragment>
            ))}
            <ActionButton
              onClick={handleEdit}
              onCancel={() => changeInfo(<></>)}
              textBtn="Editar ponderación"
              textCancel="Cancelar"
            />
          </>
        ) : null
      ) : (
        <LoadCircle />
      )}
    </div>
  )
}

const ParallelReplicate = ({ cid, changeInfo, openSnackbar, modules, reload }) => {
  // const [load, setload] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: `/Course/${cid}/parallels`,
  })

  const dropdownModules = generateOptions()
  function generateOptions() {
    let dropOptions = [{ key: 'Módulo:', value: '' }]
    modules.forEach((m) => {
      dropOptions.push({ key: m.name, value: m.id })
    })
    return dropOptions
  }
  async function handleSubmitReplicated(e) {
    e.preventDefault()
    setDisabled(true)
    function FunctionSuccess() {
      changeInfo(<></>)
      reload()
      setDisabled(false)
    }
    // await UseOnSubmit({
    //   axiosParams: {
    //     method: 'PUT',
    //     url: `programManager/module/${e.target.moduleId.value}/parallel/${e.target.parallelId.value}/replicated`,
    //   },
    //   openSnackbar: openSnackbar,
    //   textSuccess: 'Categoría creada',
    //   textWrong: 'No se pudo crear',
    //   functionSuccess: FunctionSuccess,
    //   // process:true
    //   // functionWrong: 
    // })
    await putEmptyAction(`programManager/module/${e.target.moduleId.value}/parallel/${e.target.parallelId.value}/replicated`).then((resp) => {
      if (validateStatus(resp.status)) {
        FunctionSuccess()
      }
      else {
        console.log("error")
      }
    })
    console.log(e.target.parallelId.value)
    console.log(e.target.moduleId.value)
  }
  return (
    <div className="PercentageGrades">
      <H2 textAlign="center">Replicar lecciones de otro paralelo</H2>
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          <>
            <form onSubmit={handleSubmitReplicated}>
              <div className="form-control">
                <select name="moduleId" >
                  {
                    dropdownModules.map((mod, index) => (
                      <option
                        key={index} name="moduleId" id={mod.value} value={mod.value}>
                        {mod.key}
                      </option>
                    ))
                  }
                </select>
              </div>
              <div className="form-control">
                <select name="parallelId">
                  {
                    dataRequest.length && dataRequest.map((parallel, index) => (
                      <option
                        key={index} name="moduleId" id={parallel.id} value={parallel.id}>
                        {parallel.versionName}
                      </option>
                    ))
                  }
                </select>
              </div>
              <ActionButton
                onCancel={() => changeInfo(<></>)}
                textBtn="Replicar"
                textCancel="Cancelar"
                type='submit'
                disabled={disabled}
              />
            </form>
          </>
        ) : null
      ) : (
        <LoadCircle />
      )}
    </div >
  )
}
