import "./style/CardMainOptions.css";
const CardMainOptions = (props) => {
  return (
    <div className={`CardMainOptions ${props.className}`}>
      <div className={`CardMainOptions__main ${props.classN}`}>
        {props.children}
      </div>
      <div className="CardMainOptions__options">{props.options}</div>
    </div>
  );
};

export default CardMainOptions;
