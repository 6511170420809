import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../input'
import './style/GraduateSignUp.css'
import { NormalButton } from '../buttons'
import { preSignUp, preSignUpAuth } from '../../services/action/CourseAction'
import { Loading } from '../animation'
import { openSnackbar } from '../../store/actions'
import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import { getPreSignUp } from '../../services/action/CourseAction'
import { useActionParam } from '../../hooks'

const dropdownOptions = [
  { key: 'Expedida en:', value: '' },
  { key: 'Chuquisaca', value: 'CH' },
  { key: 'La Paz', value: 'LP' },
  { key: 'Cochabamba', value: 'CB' },
  { key: 'Oruro', value: 'OR' },
  { key: 'Potosí', value: 'PT' },
  { key: 'Tarija', value: 'TJ' },
  { key: 'Santa Cruz', value: 'SC' },
  { key: 'Beni', value: 'BE' },
  { key: 'Pando', value: 'PD' },
]

const GraduateSignUp = (props) => {
  const {
    sesionUser: { user },
    eid,
    openSnackbar,
    handleProgress,
    graduate,
    // gid,
    handelSend,
  } = props

  const [load, setload] = useState(true)

  const { dataRequest } = useActionParam({
    action: getPreSignUp,
    param: eid,
  })
  useEffect(() => {
    if (dataRequest) {
      handleProgress(0)
      if (dataRequest.files.length >= 3) {
        handleProgress(1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRequest])

  function validate(param, json) {
    let aux = ''
    if (json) {
      if (json[param]) {
        aux = json[param]
      }
    }
    return aux
  }
  function save(res) {
    setload(true)
    let msj = 'register_successful'
    if (res.status === 201) {
      openSnackbar(msj, true, true)
      handelSend(res.data)
    } else {
      if (res.data !== 'email_exists_login') {
        msj = res.data
      } else {
        msj = 'pre_register_exist'
      }
      openSnackbar(msj, true, false)
    }
  }

  const onSubmit = async (values) => {
    try {
      setload(false)
      if (window.localStorage.getItem('token_seguridad')) {
        const userId = window.localStorage.getItem('id')
        await preSignUpAuth({
          userId,
          SignedProgramId: graduate.id,
          ...values,
        }).then((response) => {
          console.log(' response', response)
          save(response)
        })
      } else {
        await preSignUp({ ...values, SignedProgramId: graduate.id }).then(
          (response) => {
            console.log(' response', response)
            save(response)
          },
        )
      }
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  const initialValues = {
    Name: validate('name', dataRequest) || validate('firstName', user),
    LastName: validate('lastName', dataRequest) || validate('lastName', user),
    IdDocument:
      validate('idDocument', dataRequest) || validate('idDocument', user),
    IdExpedition:
      validate('idExpedition', dataRequest) || validate('idExpedition', user),
    PhoneNumber:
      validate('phoneNumber', dataRequest) || validate('telephone', user),
    Email: validate('email', dataRequest) || validate('email', user),
    // CtctName: validate("name", dataRequest ? dataRequest.contact : undefined),
    // CtctLastName: validate(
    //   "lastName",
    //   dataRequest ? dataRequest.contact : undefined
    // ),
    // CtctPhoneNumber: validate(
    //   "phoneNumber",
    //   dataRequest ? dataRequest.contact : undefined
    // ),
    // CtctEmail: validate("email", dataRequest ? dataRequest.contact : undefined),
    // SignedProgramId: gid,
    // SignedProgramId: graduate.id,
  }
  const validationSchema = Yup.object({
    Name: Yup.string().required('Campo Requerido'),
    LastName: Yup.string().required('Campo Requerido'),
    IdDocument: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido'),
    IdExpedition: Yup.string().required('Campo Requerido'),
    PhoneNumber: Yup.number()
      .typeError('Eso no parece un número de teléfono')
      .required('Campo Requerido'),
    Email: Yup.string()
      .email('Formato de correo inválido')
      .required('Campo Requerido'),
    // CtctName: Yup.string().required('Campo Requerido'),
    // CtctLastName: Yup.string().required('Campo Requerido'),
    // CtctPhoneNumber: Yup.number()
    //   .typeError('Eso no parece un número de teléfono')
    //   .required('Campo Requerido'),
    // CtctEmail: Yup.string()
    //   .email('Formato de correo inválido')
    //   .required('Campo de correo Requerido'),
  })

  return (
    <div className="GraduateSignUp">
      <h2 className="GraduateSignUp__title">
        Formulario de pre-registro de estudiantes
      </h2>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form className="GraduateSignUp__form">
              <h4>Datos Generales</h4>
              <div className="GraduateSignUp__Fname">
                <FormikControl
                  control="input"
                  type="text"
                  label="Apellidos"
                  name="LastName"
                  disabled={
                    validate('lastName', dataRequest) ||
                    validate('lastName', user)
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Nombres"
                  name="Name"
                  disabled={
                    validate('name', dataRequest) || validate('firstName', user)
                      ? true
                      : false
                  }
                />
              </div>
              <div className="GraduateSignUp__Fci">
                <FormikControl
                  control="input"
                  type="text"
                  label="Documento de Identidad"
                  name="IdDocument"
                  disabled={
                    validate('idDocument', dataRequest) ||
                    validate('idDocument', user)
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="select"
                  label="Expedida en"
                  name="IdExpedition"
                  options={dropdownOptions}
                  disabled={
                    validate('idExpedition', dataRequest) ||
                    validate('idExpedition', user)
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Telefono de Contacto"
                  name="PhoneNumber"
                  disabled={
                    validate('phoneNumber', dataRequest) ||
                    validate('phoneNumber', user)
                      ? true
                      : false
                  }
                />
              </div>
              <div className="GraduateSignUp__Fonly">
                <FormikControl
                  control="input"
                  type="email"
                  label="Correo Electrónico"
                  name="Email"
                  disabled={
                    validate('email', dataRequest) || validate('email', user)
                      ? true
                      : false
                  }
                />
              </div>
              {/* <h4>Datos Complementarios</h4> */}
              {/* <h5>En caso de emergencia contactarse con:</h5> */}
              {/* <div className="GraduateSignUp__Fname">
                <FormikControl
                  control="input"
                  type="text"
                  label="Apellidos"
                  name="CtctLastName"
                  disabled={
                    validate(
                      'name',
                      dataRequest ? dataRequest.contact : undefined,
                    )
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Nombres"
                  name="CtctName"
                  disabled={
                    validate(
                      'lastName',
                      dataRequest ? dataRequest.contact : undefined,
                    )
                      ? true
                      : false
                  }
                />
              </div>
              <div className="GraduateSignUp__Fci">
                <FormikControl
                  control="input"
                  type="text"
                  label="Teléfono de Contacto"
                  name="CtctPhoneNumber"
                  disabled={
                    validate(
                      'phoneNumber',
                      dataRequest ? dataRequest.contact : undefined,
                    )
                      ? true
                      : false
                  }
                />
                <FormikControl
                  control="input"
                  type="email"
                  label="Correo Electrónico"
                  name="CtctEmail"
                  disabled={
                    validate(
                      'email',
                      dataRequest ? dataRequest.contact : undefined,
                    )
                      ? true
                      : false
                  }
                />
              </div> */}
              <h4>Datos del diplomado</h4>
              <h5>Diplomado al que se está Inscribiendo:</h5>
              <h3>{graduate ? graduate.name : ''}</h3>
              {load ? (
                <NormalButton type="submit">
                  Enviar Datos de Registro
                </NormalButton>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { openSnackbar })(GraduateSignUp)
