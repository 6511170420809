import "./style/CircleBar.css";
import { ToolTip } from "../boxes";
const CircleBar = (props) => {
  const circleStyle = {
    border: `${props.status ? "3px solid var(--green)" : "1px solid #494949"}`,
  };

  return (
    <div className="CircleBar">
      {!props.lock ? (
        <i className="fas fa-lock CircleBar__iconLock"></i>
      ) : (
        <div className="icondisabled"></div>
      )}
      <ToolTip content={props.content}>
        <div
          onClick={props.onClick}
          className="CircleBar__main"
          style={circleStyle}
        >
          {props.order}
        </div>
      </ToolTip>
      {props.date ? (
        <h3 className="CircleBar__date">{props.date}</h3>
      ) : (
        // <i className="fas fa-chevron-down CircleBar__iconArrow"></i>
        <div className="icondisabled"></div>
      )}
    </div>
  );
};

export default CircleBar;
