import HttpClient from "../HttpClient";
export const postEducatorCreate = (cid, uid, data) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(`/course/${cid}/Educator/${uid}`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const putEducatorCreate = (userId, data) => {
  return new Promise((resolve, eject) => {
    /* HttpClient.post(`/course/${cid}/Educator/${uid}`, data) */
    HttpClient.put(`/users/${userId}/Educator`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
