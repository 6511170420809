import './style/TabbedNavigation.css'
import { useState, Fragment } from 'react'
import { validateArray } from '../../utils/Validation'
import { H3 } from '../../components/text'
import { withRouter } from 'react-router-dom'
const TabbedNavigation = (props) => {
  const { navigation, history, location } = props
  const [active, setactive] = useState(handleActive())
  function handleActive() {
    const params = new URLSearchParams(location.search)
    const nav = params.get('navigation')
    if (nav) {
      const activeParam = navigation.filter((item) => item.param === nav)
      if (validateArray(activeParam)) {
        return {
          id: activeParam[0].id,
          param: activeParam[0].param,
        }
      }
    } else if (validateArray(navigation)) {
      return {
        id: navigation[0].id,
        param: navigation[0].param,
      }
    }
    return {
      id: null,
      param: null,
    }
  }

  function handleNavigation(id, param) {
    history.push(`?navigation=${param}`)
    setactive({
      id: id,
      param: param,
    })
    document.getElementById('TabbedNavigationContent').scrollIntoView()
  }
  return (
    <div className="TabbedNavigation">
      <div className="TabbedNavigation__header">
        {validateArray(navigation) &&
          navigation.map((item) => (
            <div
              key={item.id}
              className={`TabbedNavigation__tab ${
                item.id === active.id && 'activate'
              }`}
              onClick={() => handleNavigation(item.id, item.param)}
            >
              <H3
                color=""
                margin="0"
                textAlign="center"
                fontWeight={item.id === active.id ? '600' : '500'}
              >
                <span>{item.label}</span>
                <i className={item.icon}></i>
              </H3>
            </div>
          ))}
      </div>
      <div id="TabbedNavigationContent" className="TabbedNavigation__content">
        {active.id !== null && validateArray(navigation)
          ? navigation
              .filter((item) => item.id === active.id)
              .map((nav) => <Fragment key={nav.id}>{nav.component}</Fragment>)
          : null}
      </div>
    </div>
  )
}

export default withRouter(TabbedNavigation)
