import { H2, H4 } from "../../components/text";
import { ColoredBoxes } from "../../components/boxes";
import { LoadCircle } from "../../components/animation";
import { FormDefault } from "../../components/form";
import { useAxiosAuth } from "../../hooks";
import { convertD } from "../../utils/ConvertDate";
import { connect } from "react-redux";
import { changeInfo, changeLayout, openSnackbar } from "../../store/actions";
import { useEffect } from "react";
import * as Yup from "yup";

const Order = (props) => {
  const {
    match: { params },
    changeInfo,
    changeLayout,
    openSnackbar,
  } = props;

  useEffect(() => {
    changeLayout();
    changeInfo(
      <>
        <H2>Nuestros detalles bancarios</H2>
        {/* <H4 fontWeight="700">CAPACITA GLOBAL INTERNACIONAL:</H4>
        <H4 textAlign="center">Banco Unión</H4>
        <H4 textAlign="center">NÚMERO DE CUENTA:</H4>
        <H4 textAlign="center">10000019222970</H4>
        <H4 fontWeight="700">CAPACITA GLOBAL INTERNACIONAL:</H4>
        <H4 textAlign="center">Banco Económico</H4>
        <H4 textAlign="center">NÚMERO DE CUENTA:</H4>
        <H4 textAlign="center">2081518492</H4>
        <H4 fontWeight="700">CAPACITA GLOBAL:</H4>
        <H4 textAlign="center">Banco Fassil</H4>
        <H4 textAlign="center">NÚMERO DE CUENTA:</H4>
        <H4 textAlign="center">40555153</H4> */}
        <H4 fontWeight="700">CAPACITA GLOBAL INTERNACIONAL:</H4>
        <H4 textAlign="center">Banco FIE</H4>
        <H4 textAlign="center">NÚMERO DE CUENTA:</H4>
        <H4 textAlign="center">40013171401</H4>
        {params.type && params.type === "oea" ? (
          <div className="">
            <H2>QR de pago</H2>
            <img
              style={{ width: "80%" }}
              src="https://res.cloudinary.com/dv4mdvthq/image/upload/v1685629646/qr-OEA_d3ld8g.jpg"
              alt=""
            />
          </div>
        ) : null}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, response } = useAxiosAuth({
    method: "GET",
    url: `/order/${params.oid}/userDetailed`,
  });

  const {
    loading: loadOrder,
    response: respOrder,
    reload,
  } = useAxiosAuth({
    method: "GET",
    url: `/order/list/${params.oid}`,
  });

  function processDataImg(values) {
    let formData = new FormData();
    formData.append("OrderId", params.oid);
    formData.append("Voucher", values.File);
    return formData;
  }

  function handleSuccess() {
    reload();
  }

  return (
    <div>
      <br />
      <H2>Detalles de su orden</H2>
      <H4>
        Su cuenta de usuario ha sido creada, por favor verifique el correo
        electrónico enviado con sus datos de usuario y contraseña.
      </H4>
      <H4>Los pasos para finalizar la inscripción son los siguientes:</H4>
      <H4>
        – Realizar depósito a una de las siguientes cuentas que se muestran en
        la parte inferior
      </H4>
      <H4>
        – Una vez realizada la transferencia enviar el comprobante de pago por
        whatsapp al número{" "}
        <strong style={{ color: "var(--red)" }}>77712429</strong>
      </H4>
      <br />
      {loading ? (
        <LoadCircle />
      ) : (
        <ColoredBoxes
          boxes={[
            {
              title: params.oid,
              subTitle: "Numero de pedido",
              status: "gray",
            },
            {
              title: convertD(response.creationDate, "L"),
              subTitle: "Fecha",
              status: "gray",
            },
            {
              title: response.email,
              subTitle: "Correo electrónico",
              status: "gray",
            },
            {
              title: `${response.totalAmount} ${
                response.currency ? response.currency : "Bs."
              }`,
              subTitle: "Total",
              status: "gray",
            },
          ]}
        />
      )}
      <br />
      <br />

      {loadOrder ? (
        <LoadCircle />
      ) : (
        <>
          {respOrder.voucher ? (
            <>
              <H2>Comprobante que adjunto</H2>
              <div style={{ maxWidth: "600px", margin: "0 auto" }}>
                <img
                  style={{ maxWidth: "600px", width: "90%", margin: "0 auto" }}
                  src={respOrder.voucher}
                  alt=""
                />
              </div>
            </>
          ) : null}
          <H2>
            {respOrder.voucher
              ? "Editar comprobante"
              : "¿Ya realizo su pago? Adjunte su comprobante"}{" "}
          </H2>
          <div style={{ maxWidth: "600px", margin: "0 auto" }}>
            <FormDefault
              inputs={[
                {
                  name: "File",
                  control: "file",
                  initial: null,
                  validate: Yup.string().required("Campo requerido").nullable(),
                  label: "Selecciona el comprobante",
                  // type: 'image/*',
                },
              ]}
              // onCancel={() => changeInfo(<></>)}
              method="POST"
              url="/order/voucher"
              openSnackbar={openSnackbar}
              handleReload={handleSuccess}
              data={{}}
              textBtn="Enviar comprobante de pago"
              processData={processDataImg}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default connect(null, {
  changeInfo,
  changeLayout,
  openSnackbar,
})(Order);
