import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import 'moment/locale/es'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
  changeInfo,
  changeLayout,
  changeModal,
  openSnackbar,
} from '../../../store/actions'
import { connect } from 'react-redux'
import { BoxFlex, CirclePointer } from '../../../components/boxes'
import { H2, H3, SubtitleData } from '../../../components/text'
import { useAxiosAuth } from '../../../hooks'
import { useEffect, useState, Fragment } from 'react'
import { Loading } from '../../../components/animation'
import './ScheduleView.css'
import { CardInformation } from '../InstitutionPrereg'
import { convertD } from '../../../utils/ConvertDate'
import { validateArray } from '../../../utils/Validation'
import { InformationTeacher } from '../../../components/card'
import { IconAction } from '../../../components/buttonsIcon'
import ScheduleEdit from './scheduleContainer/ScheduleEdit'

const localizer = momentLocalizer(moment)
moment.locale('es')

// let allViews = Object.keys(Views).map((k) => Views[k])

const ScheduleView = (props) => {
  const {
    currentEvents = [],
    showCircles = true,
    showOnlyAllSchedules = true,
    changeLayout,
    changeInfo,
    changeModal,
    changeView = true,
    openSnackbar,
  } = props
  const today = new Date()

  const [dateView, setdateView] = useState({
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  })

  useEffect(() => {
    changeLayout()
    changeInfo(<></>)
  }, [changeLayout])

  const {
    response: responseAllSchedule,
    loading: loadingAllSchedule,
    reload,
  } = useAxiosAuth(
    {
      method: 'GET',
      url: `courseSchedule/all?Month=${dateView.month}&Year=${dateView.year}`,
    },
    false,
  )

  useEffect(() => {
    reload()
  }, [dateView])

  const {
    response: virtualClassResponse,
    loading: loadingVirtualClass,
  } = useAxiosAuth({
    method: 'GET',
    url: `virtualClassRoom`,
  })

  function generateCurrentEvents() {
    return currentEvents.map((element) => ({
      start: moment(element.classDayDate)
        .set({
          hour: element.startClass.split(':')[0],
          minute: element.startClass.split(':')[1],
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      end: moment(element.classDayDate)
        .set({
          hour: element.finishClass.split(':')[0],
          minute: element.finishClass.split(':')[1],
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      title: `${element.progrmaName} ${element.parallel}`,
      classDayDate: element.classDayDate,
      ...element,
    }))
  }

  function generatedAllCalenderInfo() {
    return responseAllSchedule.map((element) => ({
      ...element,
      start: moment(element.classDayDate)
        .set({
          hour: element.startClass.split(':')[0],
          minute: element.startClass.split(':')[1],
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      end: moment(element.classDayDate)
        .set({
          hour: element.finishClass.split(':')[0],
          minute: element.finishClass.split(':')[1],
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      title: `${element.progrmaName} ${element.parallel} `,
      classDayDate: element.classDayDate,
      ownerSchedule: currentEvents.find((current) => current.id === element.id)
        ? true
        : false,

      colour: element.virtualClassRoom
        ? element.virtualClassRoom.colour
        : '#9E9E9E',
    }))
  }

  function convertTime(time) {
    return time.substring(0, 5)
  }
  function realoadAll() {
    reload()
    changeInfo(<></>)
  }
  function handleEdit(schedule) {
    changeModal(
      true,
      'Editar horario',
      <ScheduleEdit
        openSnackbar={openSnackbar}
        item={schedule}
        changeModal={changeModal}
        realoadAll={realoadAll}
      />,
      null,
    )
  }

  function onClickEvent(event) {
    /* if (event.ownerSchedule) {
      handleSheduleEdit({
        startClass: moment(event.start).format('HH:mm:ss'),
        finishClass: moment(event.end).format('HH:mm:ss'),
        classDayDate: moment(event.classDayDate),
      })
    } */

    if (changeView) {
      changeInfo(
        <>
          <BoxFlex justify="space-between">
            <H2 margin="0" textAlign="center">
              Información del horario
            </H2>
            <IconAction
              onClick={() => handleEdit(event)}
              icon="edit"
              color="#458e7f"
            >
              Editar horario
            </IconAction>
          </BoxFlex>
          <br />
          <CardInformation title={event.progrmaName}>
            <SubtitleData subtitle="Paralelo">{event.parallel}</SubtitleData>
            <SubtitleData subtitle="Horario de clase">
              {convertTime(event.startClass)} - {convertTime(event.finishClass)}
            </SubtitleData>

            <SubtitleData subtitle="Dia de la clase">
              {convertD(event.classDayDate, 'LL')}
            </SubtitleData>
          </CardInformation>

          <CardInformation title="Sala">
            <SubtitleData subtitle="Nombre de la sala">
              {event.virtualClassRoom.roomName}
            </SubtitleData>
            <SubtitleData subtitle="Tipo de sala">
              {event.virtualClassRoom.roomType}
            </SubtitleData>
            <SubtitleData subtitle="Enlace de la reunión">
              {event.link ? (
                <a
                  style={{
                    wordWrap: 'break-word',
                    color: 'var(--orange2)',
                    textDecoration: 'none',
                  }}
                  href={event.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Enlace de la reunión
                </a>
              ) : (
                '--'
              )}
            </SubtitleData>
          </CardInformation>
          <CardInformation title={`Docentes ${event.teachers.length}`}>
            {validateArray(event.teachers) ? (
              event.teachers.map((t, i) => (
                <InformationTeacher key={i} actions={false} teacher={t} />
              ))
            ) : (
              <H3>El curso no tiene docentes asignados</H3>
            )}
          </CardInformation>
        </>,
      )
    } else {
      return
    }
  }

  function eventStyleGetter(event, start, end, isSelected) {
    const style = {
      color: 'white',
      backgroundColor: showOnlyAllSchedules
        ? event.virtualClassRoom.colour
          ? event.virtualClassRoom.colour
          : '#9E9E9E'
        : '#9E9E9E',

      border: event.ownerSchedule && '4px solid #f57c00',
    }

    return {
      style: style,
    }
  }
  function onNavigate(date, _, action) {
    if (action !== 'DATE') {
      setdateView({
        month: date.getMonth() + 1,
        year: date.getFullYear(),
      })
    }
  }
  return (
    <>
      {!loadingAllSchedule && !loadingVirtualClass ? (
        <>
          {showCircles && (
            <BoxFlex>
              <br />
              <BoxFlex justify="start">
                {virtualClassResponse.map((element, index) => (
                  <Fragment key={index}>
                    <H3 margin="0px">
                      {element.roomType} {element.roomName}
                    </H3>
                    <CirclePointer background={element.colour} />
                  </Fragment>
                ))}
              </BoxFlex>
            </BoxFlex>
          )}

          <Calendar
            localizer={localizer}
            events={
              showOnlyAllSchedules
                ? generatedAllCalenderInfo()
                : generateCurrentEvents()
            }
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100vh' }}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={onClickEvent}
            onNavigate={onNavigate}
            defaultView={Views.WEEK}
            messages={{
              allDay: 'Todo el día',
              previous: '<',
              next: '>',
              today: 'Hoy',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
              agenda: 'Agenda',
              date: 'Fecha',
              time: 'Hora',
              event: 'Evento',
              noEventsInRange: 'No hay eventos en este rango',
              showMore: (total) => `+ Ver más (${total})`,
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </>
  )
}
const mapDispatchToProps = {
  changeLayout,
  changeInfo,
  changeModal,
  openSnackbar,
}
export default connect(null, mapDispatchToProps)(ScheduleView)
