const H3 = (props) => {
  const {
    children,
    margin,
    fontWeight,
    textAlign = 'start',
    color = 'gray',
    ...rest
  } = props
  return (
    <h3
      className="H3"
      style={{
        fontSize: '1.6rem',
        margin: margin ? margin : '0 0 10px',
        textAlign: textAlign,
        color: color,
        fontWeight: fontWeight,
        ...rest,
      }}
    >
      {children}
    </h3>
  )
}

export default H3
