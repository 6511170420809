import './style/CardComment.css'
import { useActionParam } from '../../hooks'
import { getAction } from '../../services/action/ActionAuthorization'
import { validateArray, validateStatus } from '../../utils/Validation'
import CardComment from './CardComment'
import { LoadCircle } from '../animation'
import { useEffect } from 'react'
const HomeWorkComments = ({ homeWorkId }) => {
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: `/homework/${homeWorkId}/Reviews`,
  })
  // console.log(dataRequest);
  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeWorkId])
  return (
    <>
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          dataRequest.map((review) =>
            review.description ? (
              <CommentReview key={review.id} review={review} />
            ) : null,
          )
        ) : null
      ) : (
        <LoadCircle />
      )}
    </>
  )
}

export default HomeWorkComments

function CommentReview({ review }) {
  return (
    <div className="CommentReview">
      <CardComment
        dataComment={{ ...review, commentText: review.description }}
      />
    </div>
  )
}
