import "./Shopping.css";
import { useAxiosAuth } from "../../hooks";
import { useEffect, useState } from "react";
import { NormalLink, RenderButton } from "../../components/buttons";
import {
  changeLayout,
  changeInfo,
  openSnackbar,
  startSession,
  changeModal,
} from "../../store/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAction } from "../../services/action/Action";
import { validateArray, validateStatus } from "../../utils/Validation";
import ShoppingAside from "../../containers/shopping/ShoppingAside";
import { H2, H3, H4 } from "../../components/text";
import { LoadCircle } from "../../components/animation";
import { IconAction } from "../../components/buttonsIcon";
import { UseOnSubmit } from "../../hooks/useOnSubmit";
import { putAction } from "../../services/action/Action";
import { GlobalForm } from "../../components/form";
import { BoxFlex } from "../../components/boxes";
import { InputsCoupon } from "../../utils/FormParameters";
// import { SubCategories } from "../../view";
import { withRouter } from "react-router-dom";

const Shopping = (props) => {
  const { changeLayout, changeInfo, changeModal } = props;
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: window.localStorage.getItem("id") ? "/Shoppingcart" : "",
  });
  const [shopping, setshopping] = useState([]);
  const [chosenPlan, setchosenPlan] = useState({});
  const [handleCoupon, sethandleCoupon] = useState(
    window.localStorage.getItem("couponCode")
      ? { code: window.localStorage.getItem("couponCode") }
      : null
  );
  function getShopping() {
    if (window.localStorage.getItem("id")) {
      return response ? response : [];
    } else {
      const storageShop = window.localStorage.getItem("shopping")
        ? JSON.parse(window.localStorage.getItem("shopping"))
        : [] || [];

      return storageShop;
    }
  }
  function reloadShopping() {
    reload();
    const listShop = getShopping();
    setshopping(listShop);
    setchosenPlan({});
    let aux = {};
    listShop.forEach((item) => {
      if (chosenPlan[item.programManagerId]) {
        aux = {
          ...aux,
          [item.programManagerId]: chosenPlan[item.programManagerId],
        };
      } else {
        getAction(`/paymentplan/plans/${item.programManagerId}`).then((res) => {
          if (res && validateStatus(res.status) && validateArray(res.data)) {
            const isMain = res.data.filter((p) => p.isMain);
            if (validateArray(isMain)) {
              setchosenPlan((a) => ({
                ...a,
                [item.programManagerId]: isMain[0],
              }));
            } else {
              setchosenPlan((a) => ({
                ...a,
                [item.programManagerId]: res.data[0],
              }));
            }
          }
        });
      }
    });
  }
  useEffect(() => {
    function handlePlan(listShop) {
      let aux = {};
      listShop.forEach((item) => {
        if (chosenPlan[item.programManagerId]) {
          aux = {
            ...aux,
            [item.programManagerId]: chosenPlan[item.programManagerId],
          };
        } else {
          getAction(`/paymentplan/plans/${item.programManagerId}`).then(
            (res) => {
              if (
                res &&
                validateStatus(res.status) &&
                validateArray(res.data)
              ) {
                const isMain = res.data.filter((p) => p.isMain);
                if (validateArray(isMain)) {
                  setchosenPlan((a) => ({
                    ...a,
                    [item.programManagerId]: isMain[0],
                  }));
                } else {
                  setchosenPlan((a) => ({
                    ...a,
                    [item.programManagerId]: res.data[0],
                  }));
                }
              }
            }
          );
        }
      });
    }
    const listShop = getShopping();
    setshopping(listShop);
    handlePlan(listShop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, window.localStorage.getItem("shopping")]);

  useEffect(() => {
    changeLayout();
    changeInfo(
      <ShoppingAside
        {...props}
        chosenPlan={{ ...chosenPlan }}
        handleCoupon={handleCoupon}
        shopping={shopping}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLayout, changeInfo, chosenPlan, handleCoupon, shopping]);

  useEffect(() => {
    if (!window.localStorage.getItem("couponCode")) {
      changeModal(
        true,
        <CouponRegister
          shopping={shopping}
          sethandleCoupon={sethandleCoupon}
          {...props}
        />,
        null,
        null
      );
    }
  }, []);

  return (
    <>
      <br />
      <H2>Pedidos</H2>
      {loading ? (
        <LoadCircle />
      ) : validateArray(shopping) ? (
        <>
          {shopping.map((program) => (
            <CourseShop
              {...props}
              program={program}
              shopping={shopping}
              key={program.programManagerId}
              reloadShopping={reloadShopping}
              chosenPlan={chosenPlan[program.programManagerId]}
            />
          ))}
          {handleCoupon ? (
            <BoxFlex justify="start">
              <H4 border="none" margin="0">
                Cupón aplicado: <strong>{handleCoupon.code}</strong>
              </H4>{" "}
              <RenderButton
                onClick={() => {
                  window.localStorage.removeItem("couponCode");
                  sethandleCoupon(null);
                }}
              >
                <i className="fas fa-times"></i>
              </RenderButton>
            </BoxFlex>
          ) : (
            <RenderCoupon
              shopping={shopping}
              sethandleCoupon={sethandleCoupon}
              {...props}
            />
          )}
        </>
      ) : (
        <>
          <H3>No tienes cursos en el carrito</H3>
          <center>
            <NormalLink to="/catalogue/short-courses/4">Ver cursos</NormalLink>
          </center>
        </>
      )}
    </>
  );
};

const CouponRegister = (props) => {
  const { shopping, sethandleCoupon, changeModal, openSnackbar } = props;
  const [load, setload] = useState(true);

  async function onSubmit(values) {
    setload(false);
    await getAction(`/coupon/detail?coupon=${values.CouponCode}`).then(
      (response) => {
        if (validateStatus(response.status)) {
          sethandleCoupon(response.data);
          window.localStorage.setItem("couponCode", values.CouponCode);
          openSnackbar("Cupón valido", true, true);
          changeModal(
            true,
            <CouponSuccess {...props} coupon={response.data} />,
            null,
            null
          );
        } else {
          openSnackbar("Cupón invalido", true, false);
        }
      }
    );
    setload(true);
  }

  return (
    <div>
      <H3 color="#39991c">Utiliza tu código promocional aquí</H3>
      <GlobalForm
        load={load}
        textBtn="Adicionar cupón"
        onSubmit={onSubmit}
        InputsJson={InputsCoupon}
        data={
          {
            // CouponCode: "ad5Fx87i",
          }
        }
      />
    </div>
  );
};

const CouponSuccess = (props) => {
  const { coupon, changeModal } = props;
  return (
    <div>
      <H3 textAlign="center">¡Cupón registrado con éxito!</H3>
      <H4>
        Este cupón es válido para un{" "}
        <strong style={{ color: "var(--red)" }}>
          máximo de {coupon.productQuantity} cursos.
        </strong>
      </H4>
      <H4 colo="var(--gray)">
        Como siguiente paso, deberás seleccionar los cursos de tu <br />
        preferencia de nuestra lista disponible.{" "}
        <Link
          style={{ color: "var(--orange2)" }}
          to="/catalogue/short-courses/4"
          onClick={() => changeModal(false, null)}
        >
          Ver los cursos
        </Link>
      </H4>
    </div>
  );
};

function CourseShop(props) {
  const { program, reloadShopping, openSnackbar, shopping, chosenPlan } = props;
  async function handleRemove() {
    if (window.localStorage.getItem("id")) {
      await UseOnSubmit({
        axiosParams: {
          method: "DELETE",
          url: `/Shoppingcart/${program.id}`,
        },
        openSnackbar: openSnackbar,
        textSuccess: "Se elimino del carrito",
        textWrong: "No se pudo eliminar",
      });
    } else {
      const shoppingList = shopping.filter(
        (f) => f.programManagerId !== program.programManagerId
      );
      window.localStorage.setItem(
        "shopping",
        JSON.stringify([...shoppingList])
      );
      openSnackbar("Se elimino del carrito", true, true);
    }
    reloadShopping();
  }
  return (
    <div className="CourseShop">
      <div className="CourseShop__container">
        <div className="CourseShop__title">
          <H4>Curso</H4>
          <H4 fontWeight="600">
            {program.name ? program.name : program.programManagerName}
          </H4>
        </div>
        {/* <div className="CourseShop__item">
          <H4>Nro de pagos</H4>
          <H4 fontWeight="600">
            {chosenPlan?.partialAmounts
              ? chosenPlan?.partialAmounts.length > 0
                ? chosenPlan?.partialAmounts.length
                : 0
              : '-'}
          </H4>
        </div> */}
        <div className="CourseShop__item">
          <H4>Precio</H4>
          <H4 fontWeight="600">
            {chosenPlan?.amount
              ? chosenPlan.amount +
                " " +
                `${chosenPlan.currency === "BOB" ? "Bs." : chosenPlan.currency}`
              : "-"}
          </H4>

          {program.codeAffiliate && <H4>Codigo Afiliado</H4>}
          <H4 fontWeight="600">
            {program.codeAffiliate ? program.codeAffiliate : ""}
          </H4>
        </div>
        <IconAction onClick={handleRemove} />
      </div>
      <br />
    </div>
  );
}
const RenderCoupon = (props) => {
  const { shopping, openSnackbar, sethandleCoupon } = props;
  const [load, setload] = useState(true);
  async function onSubmit(values) {
    setload(false);
    await putAction(`/order/validateCoupon`, {
      Orders: shopping.map((item) => {
        return { ProgramManagerId: item.programManagerId };
      }),
      CouponCode: values.CouponCode,
    }).then((response) => {
      if (validateStatus(response.status)) {
        window.localStorage.setItem("couponCode", values.CouponCode);
        sethandleCoupon(response.data);
        openSnackbar("Cupón valido", true, true);
      } else {
        openSnackbar("Cupón invalido", true, false);
      }
    });
    setload(true);
  }
  return (
    <>
      <GlobalForm
        load={load}
        textBtn="Adicionar cupón"
        onSubmit={onSubmit}
        InputsJson={InputsCoupon}
        data={{}}
        // data={{
        //   CouponCode: window.localStorage.getItem("couponCode") ?? "",
        // }}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  };
};
export default connect(mapStateToProps, {
  changeInfo,
  changeLayout,
  openSnackbar,
  startSession,
  changeModal,
})(withRouter(Shopping));
