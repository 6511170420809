import './ContainerKid.css'
import { useState, useEffect } from 'react'
import { H2, H3, H4 } from '../../components/text'
import { Loading } from '../../components/animation'
import { BoxFlex, ToolTip } from '../../components/boxes'
import { RenderButton } from '../../components/buttons'
import { TableFilterContainer } from '../../components/table'
import { FormDefault } from '../../components/form'
import { ListTracing } from '../../components/card'
import { TabbedNavigation } from '../../components/navigation'
import { avatarChild, TypeLesson, colors } from '../../utils/Constant'
import { inputChangePass } from '../../utils/FormParameters'
import { validateArray, validateStatus } from '../../utils/Validation'
import { convertD } from '../../utils/ConvertDate'
import { useAxiosAuth } from '../../hooks'
import { postAction } from '../../services/action/ActionAuthorization'

const ContainerKid = ({ kid, changeModal, openSnackbar }) => {
  function handleSession() {
    postAction(`/auth/login/kid/${kid.id}`).then((res) => {
      // console.log(kid.id)
      if (validateStatus(res.status)) {
        // console.log(res)
        window.location = `https://www.capacitakids.com/?token=${res.data.token}&id=${kid.id}`
        openSnackbar('Sesión iniciada', true, true)
      } else {
        openSnackbar('No se pudo iniciar', true)
      }
    })
  }
  function handlePassword() {
    changeModal(
      true,
      '',
      <FormDefault
        inputs={inputChangePass}
        onCancel={() => changeModal(false, null)}
        method="PUT"
        url={`/users/ChangeChildPassword`}
        openSnackbar={openSnackbar}
        title="Formulario para cambiar contraseña"
        data={{}}
        processData={(values) => ({ ...values, ChildId: kid.id })}
      />,
      null,
    )
  }
  return (
    <>
      <BoxFlex justify="space-between" className="ContainerKid">
        <BoxFlex>
          <div
            className="ContainerKid__photo"
            style={{
              backgroundImage: `url("${
                kid.photoUrl ? kid.photoUrl : avatarChild
              }")`,
            }}
          ></div>
          <div>
            <H3>{kid.name}</H3>
            <H3>{kid.lastName}</H3>
          </div>
        </BoxFlex>
        <div className="ContainerKid__buttons">
          <RenderButton onClick={handleSession} background="var(--green)">
            Iniciar sesión
          </RenderButton>
          <RenderButton
            onClick={handlePassword}
            background="var(--white)"
            color="var(--green)"
          >
            Cambiar contraseña
          </RenderButton>
        </div>
      </BoxFlex>
      <br />
      <TabbedNavigation
        navigation={[
          {
            id: 1,
            component: <KidListCourses kid={kid} changeModal={changeModal} />,
            param: 'course',
            label: 'Cursos',
            icon: 'fas fa-chart-pie',
          },
          {
            id: 2,
            component: <KidHistory kid={kid} />,
            param: 'history',
            label: 'Historial',
            icon: 'fas fa-address-card',
          },
        ]}
      />
    </>
  )
}

const KidListCourses = ({ kid, changeModal }) => {
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/course/kid/${kid.id}`,
  })
  if (loading) {
    return <Loading />
  } else {
    return validateArray(response) ? (
      response.map((course, index) => (
        <KidCourse
          kid={kid}
          status={index === 0}
          course={course}
          changeModal={changeModal}
        />
      ))
    ) : (
      <H3 color="var(--gray)" margin="0">
        El usuario no tiene cursos asignados
      </H3>
    )
  }
}

const KidCourse = (props) => {
  const { kid, course, changeModal, status } = props
  const [state, setstate] = useState(status)
  const [moduleActivate, setmoduleActivate] = useState(
    validateArray(course.moduleList) ? course.moduleList[0] : null,
  )

  function handleListLesson() {
    changeModal(
      true,
      '',
      <ListLessonKid {...props} module={moduleActivate} />,
      null,
    )
  }
  return (
    <div
      className="KidCourse"
      style={{ border: `1px solid ${state ? 'var(--green)' : 'gray'}` }}
    >
      <div onClick={() => setstate(!state)} className="KidCourse__head">
        <div className="">
          <H3 color={`var(--${state ? 'green' : 'gray'})`} margin="0">
            {course.name} - {course.versionName}
          </H3>
          {course.teacherName && (
            <H3
              color="var(--gray)"
              // color={`var(--${state ? 'green' : 'gray'})`}
              fontWeight="500"
              margin="5px 0 0"
            >
              <strong>Docente:</strong> {course.teacherName}
            </H3>
          )}
        </div>
        <H3 color={`var(--${state ? 'green' : 'gray'})`} x margin="0">
          <i className="fas fa-angle-down"></i>
        </H3>
      </div>
      {state && (
        <>
          <H3 color="var(--gray)" fontWeight="500" margin="5px 0 0">
            <strong>Fecha de inicio:</strong> {convertD(course.startDate, 'LL')}
          </H3>
          <H3 color="var(--gray)" fontWeight="500" margin="5px 0 0">
            <strong>Fecha de finalización:</strong>{' '}
            {convertD(course.finishDate, 'LL')}
          </H3>
          <br />
          <div className="KidCourse__listModule">
            {validateArray(course.moduleList) && course.moduleList.length > 1
              ? course.moduleList
                  .sort((a, b) => a.orderNumber - b.orderNumber)
                  .map((module) => (
                    <ToolTip key={module.id} content={module.name}>
                      <button
                        style={{
                          //   background: `transparent linear-gradient(90deg,
                          // #${
                          //   module === moludeId ? '458e7f' : '458e7f65'
                          // } ${'50'}%, #80808065 ${'50'}%`,
                          backgroundColor:
                            moduleActivate.id === module.id
                              ? '#458e7f'
                              : '#fff',
                          color:
                            moduleActivate.id === module.id
                              ? 'var(--white)'
                              : 'var(--gray)',
                        }}
                        onClick={() => setmoduleActivate(module)}
                      >
                        Módulo {module.orderNumber}
                        {/* {module.name} (50%) */}
                      </button>
                    </ToolTip>
                  ))
              : null}
          </div>
          {moduleActivate ? (
            <ModuleDetail
              kid={kid}
              course={course}
              module={moduleActivate}
              onClickProgress={handleListLesson}
            />
          ) : (
            <>
              <H3 textAlign="center" color="var(--gray)" margin="0">
                Lista de seguimiento
              </H3>
              <ListTracing user={kid} pid={course.id} />
            </>
          )}
        </>
      )}
    </div>
  )
}

const ListLessonKid = (props) => {
  const { module, course, kid } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/module/${module.id}/kid/${kid.id}/progress`,
  })
  function handleStatus(status) {
    const statusColor = {
      available: 'red',
      completed: 'yellow',
      scored: 'green',
    }
    return statusColor[status] || 'red'
  }

  return (
    <div>
      <H2 color="var(--gray)">Avance del módulo</H2>
      <H3 color="var(--gray)">Curso: {course.name}</H3>
      <H3 color="var(--gray)">Paralelo: {course.versionName}</H3>
      <H3 color="var(--gray)">Módulo: {module.name}</H3>
      {loading ? (
        <Loading />
      ) : validateArray(response.lessonList) ? (
        response.lessonList.map((lesson) => (
          <BoxFlex
            justify="space-between"
            border="1px solid gray"
            padding="5px"
            margin="0 0 5px"
            borderRadius="10px"
          >
            <H3 fontWeight="500" margin="0">
              {lesson.name}
            </H3>
            <BoxFlex gap="5px">
              {lesson.lessonType !== 'video' &&
              lesson.lessonType !== 'virtualclass' ? (
                <H4 border="1px solid var(--gray)" margin="0" fontSize="1.4rem">
                  {lesson.score === -1
                    ? 'Sin calificar'
                    : lesson.score.toFixed(2)}
                </H4>
              ) : null}
              <H4
                border="1px solid transparent"
                background={colors[handleStatus(lesson.status)]}
                margin="0"
                fontSize="1.4rem"
              >
                {TypeLesson[lesson.lessonType]}
              </H4>
            </BoxFlex>
          </BoxFlex>
        ))
      ) : (
        <H3 textAlign="center" fontWeight="500" margin="0 0 10px">
          Este módulo no tiene lecciones
        </H3>
      )}
    </div>
  )
}

const ModuleDetail = ({ module, kid, onClickProgress }) => {
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/followUp/Notes?userId=${kid.id}&&moduleId=${module.id}`,
  })

  useEffect(() => {
    if (!loading) {
      reload()
    }
  }, [module])

  return (
    <div className="ModuleDetail">
      <BoxFlex justify="space-between" margin="10px 0 0">
        <H3 textTransform="uppercase" color="var(--gray)" margin="0">
          {module.name}
        </H3>
        <RenderButton onClick={onClickProgress}>Avance</RenderButton>
      </BoxFlex>
      ¨
      {loading ? (
        <Loading />
      ) : (
        <BoxFlex justify="flex-start" alignItems="flex-start" margin="10px 0 0">
          {validateArray(response.followUp) ? (
            response.followUp.map((item, index) => (
              <CardTracing key={index} tracing={item} />
            ))
          ) : (
            <H3 fontWeight="500" margin="0 0 10px">
              No se registro en la asistencia
            </H3>
          )}
        </BoxFlex>
      )}
    </div>
  )
}

const CardTracing = ({ tracing }) => {
  function TextTracking({ children, status, content }) {
    return (
      <ToolTip content={content}>
        <div>
          <H3
            height="25px"
            width="25px"
            color="var(--white)"
            background={status ? 'var(--green)' : 'var(--red)'}
            margin="0"
            textAlign="center"
            lineHeight="25px"
            padding="5px 2.5px 0"
            borderRadius="50%"
          >
            {children}
          </H3>
        </div>
      </ToolTip>
    )
  }
  return (
    <div className="CardTracing">
      <H3 color="var(--gray)" margin="0">
        {convertD(tracing.dateCourse, 'LL')}
      </H3>
      <BoxFlex margin="10px 0">
        <TextTracking content="Asistencia" status={tracing.assistance}>
          <i className="fas fa-check"></i>
        </TextTracking>
        <TextTracking
          content="Participation"
          status={tracing.participation !== 0}
        >
          <i className="fas fa-spell-check"></i>
        </TextTracking>
        <TextTracking content="Tarea" status={tracing.homework}>
          <i className="fas fa-seedling"></i>
        </TextTracking>
      </BoxFlex>
      {tracing.observation && (
        <H3 color="var(--gray)" fontWeight="500" margin="10px 0 0">
          <strong>Observación:</strong> {tracing.observation}
        </H3>
      )}
      {tracing.subjectEvaluation && (
        <H3 color="var(--gray)" fontWeight="500" margin="10px 0 0">
          <strong>Evaluación:</strong> {tracing.subjectEvaluation}
        </H3>
      )}
    </div>
  )
}
const KidHistory = ({ kid }) => {
  function preProcess(data, key) {
    return (
      <H4
        innerHTML
        color="var(--gray)"
      >{`${data[key]} (<strong>${data.referenceName}</strong>)`}</H4>
    )
  }
  return (
    <div style={{ textAlign: 'start' }}>
      <TableFilterContainer
        param={`/userAction/student/${kid.id}`}
        header={[
          {
            key: 'name',
            name: 'Acción',
            type: 'preProcess',
            validate: preProcess,
          },
          { key: 'createDate', name: 'Fecha', type: 'date' },
        ]}
        boxFilters={[
          {
            type: 'getSelect',
            label: 'Acción:',
            name: 'action',
            url: '/action',
            urlKey: 'id',
            urlLabel: 'name',
            initial: 'Todas las acciones',
          },
        ]}
      />
    </div>
  )
}
export default ContainerKid
