import { useEffect, useState } from 'react'
import { Loading } from '../../components/animation'
import { BoxFlex } from '../../components/boxes'
import { ButtonIcon } from '../../components/buttons'
import { GlobalForm } from '../../components/form'
import TabbedNavigation from '../../components/navigation/TabbedNavigation'
import { TableDefault } from '../../components/table/'
import { H2, H3, H4 } from '../../components/text'
import { useAxiosAuth } from '../../hooks'
import { postAction } from '../../services/action/ActionAuthorization'
import { convertDate } from '../../utils/ConvertDate'
import { SetGoal } from '../../utils/FormParameters'
import {
  capitalizeFirstLetter,
  validateArray,
  validateStatus,
} from '../../utils/Validation'
import { SellerDetail } from './Manager'
import { Bar } from 'react-chartjs-2'
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Chart,
  ArcElement,
} from 'chart.js'
import './Reports.css'
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
)

export const setDateStartAndEnd = (inp, extraParams) => {
  return inp.StartDate
    ? inp.EndDate
      ? `?DayStart=${convertDate(inp.StartDate, 'DD')}&MonthStart=${convertDate(
          inp.StartDate,
          'MM',
        )}&YearStart=${convertDate(inp.StartDate, 'YYYY')}&DayEnd=${convertDate(
          inp.EndDate,
          'DD',
        )}&MonthEnd=${convertDate(inp.EndDate, 'MM')}&
YearEnd=${convertDate(inp.EndDate, 'YYYY')} ${
          extraParams ? '&' + extraParams : ''
        }`
      : `?Initial=${convertDate(inp.StartDate, 'MM-DD-YY')}`
    : ''
}

export const Reports = (props) => {
  return (
    <TabbedNavigation
      navigation={[
        {
          id: 1,
          component: <ReportByDate {...props} />,
          param: 'report',
          label: 'Reportes',
          icon: 'fas fa-chart-pie',
        },
        {
          id: 2,
          component: <SellersTable {...props} />,
          param: 'sellers',
          label: 'Vendedores',
          icon: 'fas fa-address-card',
        },
      ]}
    />
  )
}

export const SellersTable = (props) => {
  const [load, setload] = useState(true)

  const { changeModal, openSnackbar, changeInfo, inp } = props

  const {
    response: goalSeller,
    loading: goalSellerLoad,
    reload: goalSellerReload,
  } = useAxiosAuth({
    method: 'GET',
    url: `/Goal/allSeller${setDateStartAndEnd(inp)}`,
  })
  useEffect(() => {
    goalSellerReload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inp])

  async function handleOnSubmit(values, user) {
    setload(false)
    await postAction(
      `/Goal/user/${user.UserId}/goalSet/${values.GoalSet}/assign`,
      values,
    ).then((response) => {
      setload(true)
      if (validateStatus(response.status)) {
        openSnackbar('Metas registrados correctamente', true, true)
        changeModal(false, null)
        goalSellerReload()
      } else {
        openSnackbar('Hubo un error', true, false)
      }
    })
  }
  const setGoal = (user) => {
    changeModal(
      true,
      <>
        <H3 textAlign="center" color="var(--orange2)">
          Establecer metas para {user.Name}
        </H3>
        <GlobalForm
          InputsJson={SetGoal}
          textBtn="Enviar"
          data={user}
          load={load}
          onSubmit={(values) => handleOnSubmit(values, user)}
          colorBtn="var(--green)"
          widthBtn="100%"
        />
      </>,
    )
  }
  const headerTable = [
    { key: 'namePhone', name: 'Nombre/celular' },
    { key: 'sell', name: 'Ventas' },
    { key: 'contacts', name: 'Contactos' },
    { key: 'Percentage', name: 'Meta de ventas' },
    {
      key: 'action',
      name: 'Acciones',
      type: 'actions',
      actions: [
        {
          id: 1,
          label: 'Asignar Meta',
          action: setGoal,
          icon: 'fas fa-chart-pie',
        },
      ],
    },
  ]

  function handleClick(values) {
    changeModal(
      true,
      '',
      <SellerDetail
        seller={{ ...values, userId: values.UserId }}
        inp={inp}
        changeModal={changeModal}
        changeInfo={changeInfo}
        {...props}
      />,
      '',
      null,
      '900px',
    )
  }

  /* const handleView = (Component) => {
    setState({
      modal: true,
      component: Component,
    })
  } */
  /* const handleModal = (user) => {
    setState({
      modal: true,
      component: (
        <ViewUser
          user={user}
          changeInfo={handleView}
          handleReload={() =>
            setState({
              ...state,
              modal: false,
            })
          }
        />
      ),
    })
  } */
  const preConfig = (list) => {
    list.forEach((l) => {
      l.namePhone = (
        <BoxFlex justify="flex-start">
          <H2 color="var(--purple)">
            {capitalizeFirstLetter(l.Name)} {capitalizeFirstLetter(l.LastName)}
          </H2>{' '}
          <H3 color="gray">{l.Telephone}</H3>
        </BoxFlex>
      )
      l.sell = (
        <>
          <BoxFlex justify="space-around" direction="row">
            <span>Ventas estimadas</span>
            <H3>{l.GoalSet}</H3>
          </BoxFlex>

          <BoxFlex justify="space-around" direction="row">
            <span>Ventas realizadas</span>
            <H3>{l.TotalAchieved}</H3>
          </BoxFlex>
        </>
      )
      l.contacts = (
        <>
          <BoxFlex justify="space-between" direction="row" wrap="no-wrap">
            <H3 width="auto">Nuevos contactos</H3>
            <span>{l.NewContact}</span>
          </BoxFlex>

          <BoxFlex justify="space-between" direction="row">
            <H3 width="auto">Pregistrados</H3>
            <span>{l.NewPreregistered}</span>
          </BoxFlex>

          <BoxFlex justify="space-between" direction="row">
            <H3 width="auto">Ventas</H3>
            <span>{l.NewPreregisteredSold}</span>
          </BoxFlex>
          <BoxFlex>
            <ButtonIcon
              backButton={true}
              content={`Lista de contactos de ${l.Name} ${l.LastName}`}
              icon="address-book"
              onClick={() => handleClick(l)}
            />
          </BoxFlex>
        </>
      )
      l.Percentage = (
        <H3 textAlign="center" fontSize="2.5rem" color="var(--purple)">
          {l.Percentage}
        </H3>
      )
    })
    return list
  }

  return (
    <>
      {goalSellerLoad ? (
        <Loading />
      ) : (
        <>
          {validateArray(goalSeller) ? (
            <TableDefault
              borderBottom={true}
              handleInfo={handleClick}
              header={headerTable}
              main={preConfig(goalSeller)}
            />
          ) : (
            <H3 margin="10px 0">El usuario no tiene contactos</H3>
          )}
        </>
      )}
    </>
  )
}

const ReportByDate = ({ inp }) => {
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',

    url: `/goal/report${setDateStartAndEnd(inp, 'Goal=150')}`,
  })

  useEffect(() => {
    reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inp])

  const CardInformation = ({ information }) => {
    return (
      <>
        <BoxFlex alignItems="strech" margin="1rem 0px" justify="space-evenly">
          <CardRegister
            number={information.goal}
            background="var(--green)"
            icon="bullseye"
            text="Meta de ventas"
          />
          <CardRegister
            number={information.contacts}
            background="var(--purple)"
            icon="user-plus"
            text="Contactos nuevos"
          />
          <CardRegister
            number={information.interests}
            background="var(--green)"
            icon="plus"
            text="Interesados"
          />
          <CardRegister
            number={information.sales}
            background="var(--orange2)"
            icon="user-check"
            text="Ventas"
          />
        </BoxFlex>
        <ReportGraph information={information} />
      </>
    )
  }
  return (
    <>
      {loading ? (
        <Loading />
      ) : response !== undefined ? (
        <CardInformation information={response} />
      ) : null}
    </>
  )
}
export const CardRegister = ({ number, background, icon, text }) => {
  return (
    <div className="CardRegister" style={{ background: background }}>
      <BoxFlex justify="space-around">
        {icon && (
          <H3 width="min-content" fontSize="2rem" color="var(--white)">
            <i className={`fas fa-${icon}`}></i>
          </H3>
        )}
        <BoxFlex justify="flex-end">
          <H3 width="min-content" color="var(--white)" fontSize="2.5rem">
            {number}
          </H3>
          <H4 textAlign="end" color="var(--white)">
            {text}
          </H4>
        </BoxFlex>
      </BoxFlex>
    </div>
  )
}

export const ReportGraph = ({ information }) => {
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: true,
        text: `Consultas de ventas`,
      },
    },
  }
  const colors = [
    'RGB(69,142,127)',
    'RGB(106,48,129)',
    'RGB(209,119,66)',
    'RGB(69,142,127)',
  ]
  const labels = ['Meta de ventas', 'Contactos nuevos', 'Interesados', 'Ventas']
  const data = {
    labels: labels.map((label) => label),
    /*  */
    datasets: [
      {
        label: `Datos del equipo de ventas`,
        data: [
          information.goal,
          information.contacts,
          information.interests,
          information.sales,
        ],
        backgroundColor: colors.map((color) => color),
        /* borderColor: stringTitle.map(() => getRandomColor()), */
        borderWidth: 1,
      },
    ],
  }

  return (
    <BoxFlex justify="center" margin="1.5rem 0">
      <div className="ReportGraph_container">
        <Bar data={data} options={options} />
      </div>
    </BoxFlex>
  )
}
