import styled from 'styled-components'

const IconBnack = styled.div`
  font-size: ${(props) => props.fontSize};
  margin-bottom: ${(props) => props.marginBottom};
  cursor: pointer;
  transition: ease 0.3s all;
  &:hover {
    color: var(--orange2);
  }
}
`

const BackIcon = ({
  onClick,
  textIcon = 'volver',
  fontSize = '1rem',
  marginBottom = '1rem',
}) => {
  return (
    <IconBnack
      onClick={onClick}
      fontSize={fontSize}
      className="InstitutionProgram__icon"
      marginBottom={marginBottom}
    >
      <i className="fas fa-chevron-left"></i> <span>{textIcon}</span>
    </IconBnack>
  )
}

export default BackIcon
