import { SimpleInput } from '../../input'
import { RenderImage } from './QuestionForm'
import { DefaultBtn } from '../../buttons'
const ConceptRelation = (props) => {
  const {
    index,
    question,
    setquestion,
    response,
    handleImg,
    onClose,
    handleImgChange,
  } = props

  const handleResponse = (event, type) => {
    const { name, value } = event.target
    let newResponses = question[type]
    newResponses[name] = value
    setquestion((a) => ({ ...a, [type]: newResponses }))
  }
  function remove(index) {
    let newResponses = question.responses
    let newConcepts = question.concepts
    newResponses.splice(index, 1)
    newConcepts.splice(index, 1)
    setquestion((a) => ({
      ...a,
      responses: newResponses,
      concepts: newConcepts,
    }))
  }
  function handleText(type) {
    handleImgChange(null, index, type, false)
  }
  function handleResp(values, type) {
    handleImgChange(values.ImageFile, index, type, true)
    onClose()
  }
  return (
    <div className="QuestionForm__response">
      {question.concepImg[index].isImage ? (
        <RenderImage file={question.concepImg[index].file} />
      ) : (
        <SimpleInput
          name={index}
          type="text"
          label="Concepto"
          onChange={(event) => handleResponse(event, 'concepts')}
          value={question.concepts[index]}
          err={question.concepts[index] === '' ? 'Campo requerido' : ''}
        />
      )}
      <DefaultBtn
        width="100%"
        type="button"
        background={true}
        onClick={
          question.concepImg[index].isImage
            ? () => handleText('concepImg')
            : () => handleImg((values) => handleResp(values, 'concepImg'))
        }
      >
        <i
          className={
            question.concepImg[index].isImage
              ? 'fas fa-heading'
              : 'far fa-image'
          }
        ></i>
      </DefaultBtn>

      {question.respImg[index].isImage ? (
        <RenderImage file={question.respImg[index].file} />
      ) : (
        <SimpleInput
          name={index}
          type="text"
          label="Relación"
          onChange={(event) => handleResponse(event, 'responses')}
          value={response}
          err={response === '' ? 'Campo requerido' : ''}
        />
      )}
      <DefaultBtn
        width="100%"
        type="button"
        background={true}
        onClick={
          question.respImg[index].isImage
            ? () => handleText('respImg')
            : () => handleImg((values) => handleResp(values, 'respImg'))
        }
      >
        <i
          className={
            question.respImg[index].isImage ? 'fas fa-heading' : 'far fa-image'
          }
        ></i>
      </DefaultBtn>

      {index > 0 && (
        <button type="button" onClick={() => remove(index)}>
          <i className="fas fa-times"></i>
        </button>
      )}
    </div>
  )
}

export default ConceptRelation
