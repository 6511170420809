import { BoxFlex } from '../../../components/boxes'
import { H2 } from '../../../components/text'
import { FormDefault } from '../../../components/form'
import { RenderButton } from '../../../components/buttons'
import { InputRegisterTutor } from '../../../utils/FormParameters'
import { TableFilterContainer } from '../../../components/table'
import AddUserExist from './AddUserExist'
import FormNewChild from './FormNewChild'

const AddChildren = (props) => {
  const { changeInfo, changeModal, iid } = props

  function createUser() {
    changeInfo(<CreateUser {...props} searchUser={searchUser} />)
  }

  function searchUser(onClick) {
    changeModal(
      true,
      null,
      <TableFilterContainer
        param={`/institution/${iid}/users`}
        header={[
          { key: 'firstName', name: 'Nombres' },
          { key: 'lastName', name: 'Apellidos' },
          { key: 'email', name: 'Correo' },
          { key: 'telephone', name: 'Teléfono' },
        ]}
        onClick={onClick}
      />,
      null,
    )
  }
  function handleAddUser(user) {
    changeModal(false, null)
    changeInfo(
      <AddUserExist
        {...props}
        user={user}
        onCancel={() => changeInfo(<AddChildren {...props} />)}
      />,
    )
  }

  return (
    <div>
      <BoxFlex gap="10px" justify="flex-start" margin="0 0 15px">
        <RenderButton
          opacity="0.6"
          background="var(--red)"
          borderRadius="50%"
          padding="5px 8px 4px"
          content="Cerrar"
          onClick={() => changeInfo(<></>)}
        >
          <i className="fas fa-times"></i>
        </RenderButton>
        <H2 color="var(--gray)" margin="0">
          Inscribir Usuario
        </H2>
      </BoxFlex>
      <BoxFlex flexDirection="column" gap="10px">
        <RenderButton onClick={() => searchUser(handleAddUser)} minWidth="60%">
          Buscar usuarios
        </RenderButton>
        <RenderButton
          border="1px solid var(--orange2)"
          background="var(--white)"
          color="var(--orange2)"
          minWidth="60%"
          onClick={createUser}
        >
          Usuario nuevo
        </RenderButton>
      </BoxFlex>
    </div>
  )
}

const CreateUser = (props) => {
  const { changeInfo, openSnackbar, searchUser, changeModal } = props

  function backContainer() {
    changeInfo(<AddChildren {...props} />)
  }
  function registerTutorSuccess(values) {
    changeInfo(
      <FormNewChild
        {...props}
        father={values}
        onClose={() => changeInfo(<AddChildren {...props} />)}
      />,
    )
  }

  function handleSelectTutor(user) {
    changeModal(false, null)
    changeInfo(
      <FormNewChild
        {...props}
        father={user}
        onClose={() => changeInfo(<AddChildren {...props} />)}
      />,
    )
  }

  return (
    <div>
      <BoxFlex margin="0 0 10px" justify="space-between">
        <RenderButton
          background="var(--green)"
          onClick={backContainer}
          textAlign="end"
        >
          <i className="fas fa-arrow-left"></i>
        </RenderButton>
        <RenderButton
          content="Buscar tutor"
          onClick={() => searchUser(handleSelectTutor)}
          textAlign="end"
        >
          Buscar tutor <i className="fas fa-search"></i>
        </RenderButton>
      </BoxFlex>
      <H2 color="var(--gray)" margin="0">
        Formulario para registrar al tutor
      </H2>
      <FormDefault
        inputs={InputRegisterTutor}
        onCancel={backContainer}
        method="POST"
        url="/users/RegisterFather"
        openSnackbar={openSnackbar}
        handleReload={registerTutorSuccess}
      />
    </div>
  )
}

export default AddChildren
