import { useState, useEffect } from "react";
import { getQuiz } from "../services/action/AccountCourses";
const useAnswers = (uid, aid) => {
  const [request, setRequest] = useState({
    dataRequest: null,
    statusRequest: null,
  });
  useEffect(() => {
    getQuiz(uid, aid).then((response) => {
      let statusRequest = 500;
      let dataRequest = undefined;
      if (response) {
        statusRequest = response.status;
        dataRequest = response.data;
      }
      setRequest({
        dataRequest,
        statusRequest,
      });
    });
  }, [uid, aid]);
  return request;
};
export default useAnswers;
