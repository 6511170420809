import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './store/reducers'
const header = (
  <img
    src="https://res.cloudinary.com/pataformacgi/image/upload/h_60,c_scale,q_auto,f_auto/v1655472641/assets/logo_2_okkya4_zuaobh.png"
    alt="Logotipo Capacita Global"
  />
)
const initialState = {
  sesionUser: {
    user: {},
    rols: [],
    institutions: [],
    authenticate: false,
  },
  membership: null,
  snackbarReducer: {
    open: false,
    message: '',
    kind: true,
  },
  modalReducer: {
    status: false,
    title: '',
    message: '',
    butttonText: '',
    onClick: null,
    width: null,
  },
  informationComponent: null,
  themeReducer: {
    white: '#ffffff',
    white2: '#F8F8F8',
    orange1: '#f5b38a',
    orange2: '#d17742',
    yellow: '#e3ad2d',
    green: '#458e7f',
    purple: '#6a3081',
    black: '#000000',
    gray: '#5F5961',
    red: '#c93f3f',
  },
  headerTitle: header,
  layoutReducer: {
    areas: `"Navbar Navbar Navbar Navbar" "Conten Conten Conten Inf"`,
    responsive: `"Navbar" "Conten" "Inf" "Inf"`,
    navigation: true,
  },
}
const store = createStore(reducer, initialState)
const container = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  container,
)
