import React from "react";
import "./style/CompModal.css";
const CompModal = (props) => {
  return (
    <div className="CompModal">
      <button className="CompModal__close-button" onClick={props.onClose}>
        X
      </button>
      <div className="CompModal__container">
        {props.closeOutside && (
          <div className="CompModal__close" onClick={props.onClose}></div>
        )}
        <div className="CompModal__video">{props.children}</div>
      </div>
    </div>
  );
};

export default CompModal;
