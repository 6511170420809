import './style/ContentHomework.css'
import { Link } from 'react-router-dom'
import { convertD, dataLock } from '../../utils/ConvertDate'
import { withRouter } from 'react-router-dom'
import { ToolTip } from '../boxes'
import EditorView from '../editorjs/EditorView'
const ContentHomework = (props) => {
  const {
    contentData: {
      name,
      description,
      startDatePerUser,
      finishDatePerUser,
      id,
      status,
      lessonType,
    },
    match: {
      params: { pid, cid, mid },
    },
  } = props
  return (
    <div className="ContentVIdeo__act">
      {!dataLock(startDatePerUser) && (
        <div className="ContentVIdeo__block">
          <h3>Fecha de inicio: {convertD(startDatePerUser, 'LL')}</h3>
          <i className="fas fa-lock"></i>
          <div></div>
        </div>
      )}
      <ToolTip content={lessonType === 'forum' ? 'Foro' : 'Tarea'}>
        <Link
          to={`/program/${pid}/course/${cid}/module/${mid}/${
            lessonType === 'forum' ? 'forum' : 'home-work'
          }/${id}`}
          className="ContentHomework__link"
        >
          <div
            className="ContentHomework"
            style={{
              background: `${
                status !== 'available' ? '#469585A1' : 'var(--white)'
              }`,
            }}
          >
            <h2 className="ContentHomework__date">
              Fecha Limite: {convertD(finishDatePerUser, 'LLL')}
            </h2>
            <h2 className="ContentHomework__name">{name}</h2>
            {description && <EditorView cutText={true} data={description} />}
          </div>
        </Link>
      </ToolTip>
    </div>
  )
}

export default withRouter(ContentHomework)
