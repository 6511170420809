import './CoursesTeacher.css'
import { useActionParam, useAxiosAuth } from '../../hooks'
import { validateArray, validateStatus } from '../../utils/Validation'
import { CourseModule } from '../../components/card'
import { H2, H3 } from '../../components/text'
import { connect } from 'react-redux'
import {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
} from '../../store/actions'
import { useEffect } from 'react'
import { CardMainOptions } from '../../components/card'
import { ButtonIcon, Icon } from '../../components/buttons'
import { TableDefault } from '../../components/table'
import { Loading, LoadCircle } from '../../components/animation'
import { getAction } from '../../services/action/ActionAuthorization'
import { useState } from 'react'
import { urlAvatar } from '../../utils/Constant'
import { CompModal } from '../../components/modal'
import moment from 'moment'
import { ReleaseList } from '../../containers/institutions'
import { BoxFlex } from '../../components/boxes'
import { FormNewModule } from '../../components/form'
import { PendingsList, ScheduleList } from '../../containers/coursesTeacher'
import { ViewCourseSchedule } from '../../containers/institutions/institution/ViewCourseSchedule'
import { CourseTracking } from '../../containers/institutions'
import { LinkEnlace } from '../../components/buttons/LinkEnlace'

const CoursesTeacher = (props) => {
  const { changeLayout, changeInfo, openSnackbar, history, changeModal } = props
  const [modal, setmodal] = useState({
    view: false,
    mid: -1,
  })
  // const { dataRequest,  } = useAxiosAuth({
  //   action: getAction,
  //   param: '/course/coursesPerProfessor',
  // })
  const { response: dataRequest, reload } = useAxiosAuth({
    method: 'GET',
    url: '/course/coursesPerProfessor',
  })
  useEffect(() => {
    changeLayout('')
    changeInfo(<PendingsList />)
  }, [changeLayout, changeInfo])
  function ListStudents({ mid }) {
    const { dataRequest, statusRequest } = useActionParam({
      action: getAction,
      param: `/course/${mid}/studentList`,
    })
    const [load, setload] = useState(true)
    function downloadList() {
      setload(false)
      getAction(`/course/${mid}/studentListPdf`).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          window.open(response.data, '_blank')
        }
      })
    }
    const header = [
      { key: 'photoUrl', name: 'Avatar', type: 'img' },
      { key: 'firstName', name: 'Nombres' },
      { key: 'lastName', name: 'Apellidos' },
      { key: 'email', name: 'Correo' },
    ]
    function preConfig(list) {
      list.forEach((u) => {
        if (!u.firstName) {
          u.firstName = 'N/A'
        }
        if (!u.lastName) {
          u.lastName = 'N/A'
        }
        if (!u.photoUrl) {
          u.photoUrl = urlAvatar
        }
      })
      return list
    }
    return (
      <div className="ListStudents">
        <div className="ListStudents__header">
          <H2>Estudiantes</H2>
          {load ? (
            <Icon ico="list" handleModal={() => downloadList()}>
              Descargar lista
            </Icon>
          ) : (
            <Loading />
          )}
        </div>
        {validateStatus(statusRequest) ? (
          validateArray(dataRequest) ? (
            <TableDefault
              // handleInfo={handleInfo}
              header={header}
              main={preConfig(dataRequest)}
            />
          ) : (
            <H3>No hay estudiantes inscritos</H3>
          )
        ) : (
          <LoadCircle />
        )}
      </div>
    )
  }
  function CardModuleOption({ moduleData, rol }) {
    const op = (
      <>
        <ButtonIcon
          onClick={() => setmodal({ view: !modal.view, mid: moduleData.id })}
          content="Estudiantes"
          icon="users"
        />
        {moduleData.whatsappGroupUrl && (
          <ButtonIcon
            onClick={moduleData.whatsappGroupUrl}
            content="Grupo de WhatsApp"
            prefix="fab"
            icon="whatsapp"
            type="link"
          />
        )}
        <ButtonIcon
          onClick={() =>
            changeInfo(
              <ViewCourseSchedule
                {...props}
                reload={reload}
                changeModal={changeModal}
                openSnackbar={openSnackbar}
                match={{
                  params: { cid: moduleData.id },
                }}
              />,
            )
          }
          content="Horario"
          icon="calendar-alt"
        />
        <ButtonIcon
          onClick={() => history.push(`/course-notes/${moduleData.id}`)}
          content="Notas de los estudiantes"
          icon="tasks"
        />
        <ButtonIcon
          onClick={() =>
            history.push(`/courses/${moduleData.id}/course-progress`)
          }
          content="Avance de los estudiantes"
          icon="spinner"
        />
        <ButtonIcon
          onClick={() =>
            changeInfo(
              <FormNewModule
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                cid={moduleData.id}
                reload={handleReload}
              />,
            )
          }
          content="Crear módulo"
          icon="plus"
        />
        <ButtonIcon
          onClick={() =>
            changeInfo(
              <CourseTracking
                pid={moduleData.id}
                iid={moduleData.institutionId}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                changeModal={changeModal}
              />,
            )
          }
          content="Ver listas de seguimiento"
          icon="list"
        />
      </>
    )
    return (
      <CardMainOptions className="CardModuleOption" options={op}>
        <CourseModule moduleData={moduleData} rol={rol} />;
      </CardMainOptions>
    )
  }
  function handleReload() {
    changeInfo(<></>)
    reload()
  }
  return (
    <div className="CoursesTeacher">
      <BoxFlex justify="space-between">
        <H2>Mis Cursos</H2>
        <div className="">
          <Icon
            ico="comment-alt"
            handleModal={() =>
              changeInfo(
                <ReleaseList
                  courses="/course/coursesPerProfessor"
                  programs={null}
                  changeInfo={changeInfo}
                  openSnackbar={openSnackbar}
                />,
              )
            }
          >
            Lista de anuncios
          </Icon>
          <Icon
            ico="tasks"
            handleModal={() =>
              changeInfo(
                <PendingsList
                  changeInfo={changeInfo}
                  openSnackbar={openSnackbar}
                />,
              )
            }
          >
            Pendientes para calificar
          </Icon>
          <Icon
            ico="calendar-alt"
            handleModal={() =>
              changeInfo(
                <ScheduleList
                  changeInfo={changeInfo}
                  openSnackbar={openSnackbar}
                />,
              )
            }
          >
            Horario
          </Icon>
        </div>
      </BoxFlex>
      <br />
      <div className="CoursesTeacher__list">
        {validateArray(dataRequest) ? (
          dataRequest
            .sort(function (left, right) {
              return moment
                .utc(left.startDate)
                .diff(moment.utc(right.startDate))
            })
            .map((l) => (
              <CardModuleOption
                moduleData={{ ...l, moduleToList: l.modules }}
                key={l.id}
                rol="educator"
              />
            ))
        ) : (
          <h3>Aún no hay módulos en el curso</h3>
        )}
      </div>
      {modal.view && modal.mid !== -1 ? (
        <CompModal
          onClose={() => setmodal({ view: !modal.view })}
          closeOutside={true}
        >
          <ListStudents mid={modal.mid} />
        </CompModal>
      ) : null}
    </div>
  )
}

export default connect(null, {
  changeLayout,
  changeInfo,
  openSnackbar,
  changeModal,
})(CoursesTeacher)
