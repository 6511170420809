import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../input'
import { ActionButton, DefaultBtn, RenderButton } from '../buttons'
import { Loading } from '../animation'
import { deleteCharacteres, removeQuote } from '../../utils/ProcessData'
import { validateArray, convertString } from '../../utils/Validation'
import { BoxFlex, ToolTip } from '../boxes'
import { H2 } from '../text'
// import { changeInfo, changeModal } from '../../store/actions'
import { TableFilterContainer } from '../table'
import AddUserExist from '../../containers/institutions/CoursesInstitution/AddUserExist'
import AddChildren from '../../containers/institutions/CoursesInstitution/AddChildren'
const GlobalForm = (props) => {
  const {
    InputsJson,
    title = '',
    textBtn,
    data,
    onSubmit,
    load,
    onCancel,
    colorBtn,
    widthBtn,
    backgroundBtn,
    labelColor = '#000',
    // disabled = null,
  } = props
  function initialGeneration(InputsJson) {
    let init = {}
    let validate = {}
    if (validateArray(InputsJson)) {
      InputsJson.forEach((input) => {
        if (input.type === 'severalInputs') {
          if (validateArray(input.inputs)) {
            input.inputs.forEach((i, index) => {
              if (input.name) {
                init = {
                  ...init,
                  [i.name]: data[input.name].split('T')[index],
                }
              } else {
                init = { ...init, [i.name]: data[i.name] || i.initial }
              }
              validate = { ...validate, [i.name]: i.validate }
            })
          }
        } else {
          // console.log(data[input.name])

          init = {
            ...init,
            [input.name]: data[input.name] ? data[input.name] : input.initial,
          }
          validate = { ...validate, [input.name]: input.validate }
        }
      })
    }
    return { init, validate }
  }
  const { init, validate } = initialGeneration(InputsJson)
  const initialValues = init
  const validationSchema = Yup.object(validate)

  return (
    <div>
      {title && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <H2 textAlign="center" color="var(--gray)">
            {title}
          </H2>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        // onSubmit={onCancel ? onSubmit : null}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formik) => {
          return (
            <Form>
              {validateArray(InputsJson)
                ? InputsJson.map((inpt, index) => {

                  switch (inpt.control) {
                    case 'date':
                      return (
                        <FormikControl
                          {...inpt}
                          key={index}
                          control={inpt.control}
                          type={inpt.type}
                          label={inpt.label}
                          name={inpt.name}
                          placeholderText={inpt.label}
                          showTimeSelectOnly={inpt.showTimeSelectOnly}
                          dateFormat={inpt.dateFormat}
                          showTimeSelect={inpt.showTimeSelect ? true : false}
                          timeIntervals={inpt.timeIntervals}
                        />
                      )
                    case 'file':
                      return (
                        <FormikControl
                          key={index}
                          control={inpt.control}
                          name={inpt.name}
                          small={true}
                          label={inpt.label}
                          uploadFiles={formik.setValues}
                          type={inpt.type}
                        />
                      )
                    case 'listInput':
                      return (
                        <FormikControl
                          key={index}
                          control={inpt.control}
                          name={inpt.name}
                          label={inpt.label}
                          uploadValues={formik.setValues}
                          type={inpt.type}
                          buttonLabel={inpt.buttonLabel}
                        />
                      )
                    case 'Phone':
                      return (
                        <FormikControl
                          key={index}
                          labelColor={labelColor}
                          type={inpt.type}
                          label={inpt.label}
                          name={inpt.name}
                          control={inpt.control}
                          uploadValues={formik.setValues}
                        />
                      )
                    case 'editor':
                    case 'editorSimple':
                      let aux = null
                      if (formik.initialValues[inpt.name]) {
                        aux = formik.initialValues[inpt.name].replace(
                          /'/g,
                          '"',
                        )
                        aux = removeQuote(deleteCharacteres(aux))
                        aux = convertString(aux)
                      }
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            marginBottom: '10px',
                          }}
                          key={index}
                        >
                          <FormikControl
                            control={inpt.control}
                            label={inpt.label}
                            name={inpt.name}
                            onChange={formik.setValues}
                            data={aux}
                          // data={convertString(
                          //   formik.initialValues[inpt.name],
                          // )}
                          />
                        </div>
                      )
                    case 'select':
                      return (
                        <FormikControl
                          labelColor={labelColor}
                          key={index}
                          control={inpt.control ? inpt.control : 'input'}
                          options={inpt.options}
                          label={inpt.label}
                          name={inpt.name}
                        />
                      )
                    case 'selectGet':
                    case 'getSelect':
                      return (
                        <FormikControl
                          key={index}
                          control={inpt.control}
                          label={inpt.label}
                          name={inpt.name}
                          url={inpt.url}
                          nameList={inpt.nameList || null}
                          keyOption={inpt.keyOption || 'name'}
                          value={inpt.value || 'name'}
                        // complementUrl={inpt.complementUrl||''}
                        />
                      )
                    case 'getSelectList':
                      return (
                        <FormikControl
                          key={index}
                          control={inpt.control}
                          label={inpt.label}
                          name={inpt.name}
                          url={inpt.url}
                          nameList={inpt.nameList || null}
                          keyOption={inpt.keyOption || 'name'}
                          value={inpt.value || 'name'}
                          typeSelect="List"
                        />
                      )
                    case 'getCheckbox':
                      return (
                        <FormikControl
                          key={index}
                          control={inpt.control}
                          label={inpt.label}
                          name={inpt.name}
                          url={inpt.url}
                          nameList={inpt.nameList}
                          keyOption={inpt.keyOption}
                          value={inpt.value}
                        />
                      )
                    default:
                      if (inpt.type === 'severalInputs') {
                        return (
                          <BoxFlex key={index}>
                            {inpt.inputs.map((i, j) => (
                              <FormikControl
                                key={j}
                                control="input"
                                type={i.type}
                                label={i.label}
                                name={i.name}
                              />
                            ))}
                          </BoxFlex>
                        )
                      } else {
                        return (
                          <FormikControl
                            labelColor={labelColor}
                            key={index}
                            control={inpt.control ? inpt.control : 'input'}
                            type={inpt.type}
                            label={inpt.label}
                            name={inpt.name}
                          // disabled={disabled}
                          />
                        )
                      }
                  }
                })
                : null}
              {load ? (
                onCancel ? (
                  <ActionButton
                    // onClick={() => onSubmit(formik.values, InputsJson)}
                    type="submit"
                    onCancel={onCancel}
                    disabled={!formik.isValid}
                    textBtn={textBtn}
                    textCancel="Cancelar"
                  />
                ) : (
                  <center>
                    <DefaultBtn
                      type="submit"
                      color={colorBtn}
                      width={widthBtn}
                      background={backgroundBtn}
                      disabled={!formik.isValid}
                    // onClick={() => onSubmit(formik.values, InputsJson)}
                    // type="button"
                    >
                      {textBtn}
                    </DefaultBtn>
                  </center>
                )
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default GlobalForm
