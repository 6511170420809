import { convertD } from '../../utils/ConvertDate'
import { ButtonIcon } from '../buttons'
import { CardMainOptions } from '../card'
import EditorView from '../editorjs/EditorView'
import { sendMail } from './SendMail'
const ContentCertificateRol = (props) => {
  const {
    contentData,
    overt,
    ChangePublic,
    changeModal,
    openSnackbar,
    PutLesson,
  } = props
  const { id, name, description, startDatePerUser } = contentData
  const op = (
    <>
      <ButtonIcon
        content={overt ? 'Anular publicación' : 'Publicar'}
        onClick={ChangePublic}
        icon={overt ? 'eye' : 'eye-slash'}
      />
      {overt && (
        <ButtonIcon
          onClick={() => sendMail(id, changeModal, openSnackbar)}
          content="Enviar correo"
          icon="envelope"
        />
      )}
      <ButtonIcon onClick={PutLesson} content="Editar" icon="edit" />
    </>
  )

  return (
    <CardMainOptions options={op}>
      <div className="ContentCertificate">
        <div className="ContentCertificate__content">
          <div className="ContentCertificate__head">
            <h3>Disponible a partir del {convertD(startDatePerUser, 'LL')}</h3>
            <div className="ContentCertificate__imgs">
              {/* <img
                src="https://res.cloudinary.com/dzxmzxvbr/image/upload/v1615169311/hbyrsin1hwlcfchgqbaf.png"
                alt="Logotipo Capacita"
              />
              <img
                src="https://res.cloudinary.com/dzxmzxvbr/image/upload/v1614797906/DHvrNJpN_400x400_g8st0w.jpg"
                alt="Logotipo de La Paz"
              /> */}
            </div>
          </div>
          <h2>{name}</h2>
          {description && <EditorView cutText={true} data={description} />}
        </div>
      </div>
    </CardMainOptions>
  )
}

export default ContentCertificateRol
