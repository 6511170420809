import HttpClient from "../HttpClient";
export const getCommentsL = (lid, uid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`comment/user/${uid}/lesson/${lid}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const postCommentL = (comment) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(`comment`, comment)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const postQuiz = (uid, lid, quiz) => {
  return new Promise((resolve, eject) => {
    HttpClient.put(`quiz/lesson/${lid}/user/${uid}/answers`, quiz)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const getQuiz = (uid, aid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`quiz/${aid}/user/${uid}/answers`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
