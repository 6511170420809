import { useState } from 'react'
import { urlAvatar } from '../../utils/Constant'
import { Loading } from '../../components/animation'
import { H2 } from '../../components/text'
import { validateStatus } from '../../utils/Validation'
import { postAction } from '../../services/action/ActionAuthorization'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateUser } from '../../store/actions'
import './style/ChangeImage.css'
function ChangeImage(props) {
  const user = urlAvatar
  const { sesionUser } = props
  const [imgPreview, setImgPreview] = useState(sesionUser.user.photoUrl)
  const { t } = useTranslation('ChangeImg')
  const [name, setname] = useState(null)
  const [load, setload] = useState(true)

  const handleImageChange = (e) => {
    const selected = e.target.files[0]
    setname(selected)
    const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/jpg']
    if (selected && ALLOWED_TYPES.includes(selected.type)) {
      let reader = new FileReader()
      reader.onloadend = () => {
        setImgPreview(reader.result)
      }
      reader.readAsDataURL(selected)
    } else {
      console.log('file not suport')
    }
  }
  function handleUpload(e) {
    e.preventDefault()
    let formdata = new FormData()
    formdata.append('File', name)
    setload(false)
    postAction(`/users/${sesionUser.user.id}/photos`, formdata).then(
      (response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          props.updateUser({
            ...sesionUser.user,
            photoUrl: response.data.url,
          })
        }
      },
    )
  }
  return (
    <div className="change">
      <div className="change__container">
        <H2 textAlign="center" color="var(--gray)">
          {t('ciChange')}
        </H2>
        <div
          style={{
            backgroundImage: imgPreview
              ? `url("${imgPreview}")`
              : `url("${user}")`,
          }}
          className="change__containerImg"
        ></div>
      </div>
      <form action="">
        <input type="file" id="inputFile" onChange={handleImageChange} />
        <div className="label">
          <label htmlFor="inputFile" className="imageUp">
            {t('ciSelect')}
            <i className="fas fa-file-import"></i>
          </label>
        </div>
        {load ? (
          <button
            className="change__btn"
            disabled={!imgPreview || imgPreview === sesionUser.user.photoUrl}
            onClick={(e) => handleUpload(e)}
          >
            {t('ciSave')}
          </button>
        ) : (
          <Loading />
        )}
      </form>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, { updateUser })(ChangeImage)
