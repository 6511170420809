import * as Yup from 'yup'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import { useAxiosAuth } from '../../../hooks'
import { UseOnSubmit } from '../../../hooks/useOnSubmit'
import { H2, H3, H4 } from '../../../components/text'
import { Loading } from '../../../components/animation'
import { FormikControl } from '../../../components/input'
import { validateArray } from '../../../utils/Validation'
import { convertDate, convertD } from '../../../utils/ConvertDate'
import { LoadCircle } from '../../../components/animation'
import { IconOptions } from '../../../components/buttonsIcon'
import { ActionButton, ButtonIcon } from '../../../components/buttons'
import './CouponAside.css'
const CouponAside = (props) => {
  const { changeInfo } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: '/coupon',
  })
  function reload() {
    changeInfo(<CouponAside {...props} />)
  }
  return (
    <>
      <H2 textAlign="center">Cupones</H2>
      {loading ? (
        <LoadCircle />
      ) : validateArray(response) ? (
        response.map((coupon) => (
          <div key={coupon.id} className="CouponAside__item">
            <H4 margin="0">{coupon.code}</H4>
            <IconOptions
              content={
                <>
                  <ButtonIcon
                    onClick={() =>
                      changeInfo(
                        <CouponForm
                          coupon={coupon}
                          reload={reload}
                          {...props}
                        />,
                      )
                    }
                    content="Editar cupón"
                    icon="edit"
                  />
                  <ButtonIcon
                    onClick={() =>
                      changeInfo(
                        <CouponCategory
                          coupon={coupon}
                          reload={reload}
                          {...props}
                        />,
                      )
                    }
                    content="Asignar categorías"
                    icon="sitemap"
                  />
                  <ButtonIcon
                    onClick={() => {}}
                    content="Eliminar cupón"
                    icon="trash-alt"
                  />
                </>
              }
              icon="ellipsis-v"
              noBackground={true}
            />
          </div>
        ))
      ) : (
        <H3>No tienes cupones</H3>
      )}
      <ActionButton
        onClick={() => changeInfo(<CouponForm {...props} reload={reload} />)}
        onCancel={() => changeInfo(<></>)}
        textBtn="Crear cupón"
      />
    </>
  )
}
const CouponCategory = (props) => {
  const { coupon, changeInfo, reload } = props
  return (
    <>
      <H2 textAlign="center">Asignar cupón</H2>
      {validateArray(coupon.couponCategories) ? (
        <></>
      ) : (
        <H3>No tienes asignado ninguna categoría</H3>
      )}
      <ActionButton
        onClick={() => changeInfo(<CouponForm {...props} />)}
        onCancel={reload}
        textBtn="Editar asignación"
      />
    </>
  )
}

const CouponForm = (props) => {
  const { openSnackbar, coupon, reload } = props
  const [load, setload] = useState(true)
  const initialValues = {
    Code: coupon?.code ? coupon.code : '',
    Currency: coupon?.currency ? coupon.currency : 'BOB',
    Type: coupon?.type ? coupon.type : 'amount',
    Amount: coupon?.amount ? coupon.amount : 0,
    Status: coupon?.status ? coupon.status : 'public',
    ExpenseType: coupon?.expenseType ? coupon.expenseType : 'countProduct',
    productQuantity: coupon?.productQuantity ? coupon.productQuantity : 0,
    MinimumExpense: coupon?.minimumExpense ? coupon.minimumExpense : 0,
    DateExpiry: coupon?.dateExpiry ? convertD(coupon.dateExpiry, 'utc') : null,
    Description: coupon?.description ? coupon.description : '',
  }

  const validationSchema = Yup.object({
    Code: Yup.string().required('Campo requerido'),
    Amount: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(0, 'La cantidad mínima es 0'),
    Type: Yup.string().required('Campo requerido'),
    Currency: Yup.string().required('Campo requerido'),
    Status: Yup.string().required('Campo requerido'),
    ExpenseType: Yup.string().required('Campo requerido'),
    productQuantity: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(0, 'La cantidad mínima es 0'),
    MinimumExpense: Yup.number()
      .typeError('Eso no parece un número')
      .required('Campo Requerido')
      .min(0, 'La cantidad mínima es 0'),
    DateExpiry: Yup.string().required('Campo requerido').nullable(),
    Description: Yup.string(),
  })
  const onSubmit = async (values) => {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: coupon ? 'PUT' : 'POST',
        url: coupon ? `/coupon/${coupon.id}` : '/coupon',
        data: {
          ...values,
          DateExpiry: convertDate(values.DateExpiry),
        },
      },
      openSnackbar: openSnackbar,
      textSuccess: `Cupón ${coupon ? 'editado' : 'creado'}`,
      textWrong: `No se pudo ${coupon ? 'editar' : 'crear'}`,
      functionSuccess: reload,
    })
    setload(true)
  }
  return (
    <>
      <H2 textAlign="center">{coupon ? 'Editar' : 'Crear'} cupón</H2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Código del cupón"
                name="Code"
              />
              <FormikControl
                control="select"
                label="Moneda"
                name="Currency"
                options={[
                  { key: 'Moneda:', value: '' },
                  { key: 'Bolivianos', value: 'BOB' },
                  { key: 'Dolares', value: 'SUS' },
                ]}
              />
              <FormikControl
                control="select"
                label="Tipo de descuento"
                name="Type"
                options={[
                  { key: 'Moneda:', value: '' },
                  { key: 'Descuento fijo', value: 'amount' },
                  { key: 'Descuento por porcentaje', value: 'percentage' },
                ]}
              />
              <FormikControl
                control="input"
                type="number"
                label={
                  formik.values.Type === 'amount'
                    ? 'Monto'
                    : 'Porcentaje de descuento'
                }
                name="Amount"
              />
              <FormikControl
                control="select"
                label="Estado del cupón"
                name="Status"
                options={[
                  { key: 'Estado del cupón', value: '' },
                  { key: 'Privado', value: 'private' },
                  { key: 'Publico', value: 'public' },
                ]}
              />
              <FormikControl
                control="select"
                label="Realizar el descuento por"
                name="ExpenseType"
                options={[
                  { key: 'Tipo de descuento', value: '' },
                  { key: 'Por producto', value: 'countProduct' },
                  { key: 'Por monto', value: 'minimunExpense' },
                ]}
              />
              {formik.values.ExpenseType === 'countProduct' ? (
                <FormikControl
                  control="input"
                  type="number"
                  label="Producto mínimo"
                  name="productQuantity"
                />
              ) : (
                <FormikControl
                  control="input"
                  type="number"
                  label="Precio mínimo"
                  name="MinimumExpense"
                />
              )}
              <FormikControl
                control="date"
                label="Fecha de expiración"
                name="DateExpiry"
                placeholderText="Fecha de expiración"
                dateFormat="MMMM d, yyyy"
              />
              <FormikControl
                control="textarea"
                type="text"
                label="Descripción"
                name="Description"
              />
              {load ? (
                <ActionButton
                  onCancel={() => reload()}
                  textBtn="Guardar"
                  type="submit"
                />
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
export default CouponAside
