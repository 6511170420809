import HttpClient from "../HttpClient";
export const completeLection = (lid) => {
  return new Promise((resolve, eject) => {
    HttpClient.postEmpty(`/lesson/${lid}/complete`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
