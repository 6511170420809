import { LoadCircle, Loading } from '../../../components/animation'
import { SimpleSelect } from '../../../components/input'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { useAxiosAuth } from '../../../hooks'
import { H2, SubtitleData } from '../../../components/text'
import { ActionButton, RenderButton } from '../../../components/buttons'
import { TableFilterContainer } from '../../../components/table'
import { useState, useEffect } from 'react'
import { postEmptyAction } from '../../../services/action/ActionAuthorization'

const SendCopy = (props) => {
  const { course, changeInfo, openSnackbar, params, changeModal } = props
  const [load, setload] = useState(true)
  const [send, setsend] = useState({
    programManager: '',
    paralel: '',
  })

  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: '/category/1/courses',
  })

  const {
    response: programs,
    loading: loadProg,
    reload: reloadProg,
  } = useAxiosAuth({
    method: 'GET',
    url: send.programManager
      ? `programManager/${send.programManager.id}/Paralel`
      : null,
  })

  useEffect(() => {
    reloadProg()
  }, [send.programManager])

  const onSubmit = async () => {
    setload(false)
    postEmptyAction(
      `/course/${course.id}/duplicateToProgram/${send.paralel}`,
    ).then((response) => {
      setload(true)
      if (response && validateStatus(response.status)) {
        openSnackbar('Se guardaron los cambios', true, true)
        changeInfo(<></>)
      } else {
        openSnackbar('No se pudo guardar', true, false)
      }
    })
  }
  function onChangeRole(e) {
    const { name, value } = e.target
    setsend((a) => ({ ...a, [name]: value }))
  }
  function getDropdown(categories, key, value, init = 'Categoría:') {
    let aux = [{ key: init, value: '' }]
    if (validateArray(categories)) {
      categories.forEach((category) => {
        aux.push({ key: category[key], value: category[value] })
      })
    }
    return aux
  }

  function selectedProgram(program) {
    onChangeRole({ target: { name: 'programManager', value: program } })
    changeModal(false, null)
  }

  if (loading) {
    return <LoadCircle />
  } else {
    return (
      <>
        <H2 textAlign="center">Enviar copia del curso a un programa</H2>
        <SubtitleData subtitle="Curso a enviar:">{course.name}</SubtitleData>
        {send.programManager && !loadProg ? (
          <>
            <SubtitleData subtitle="Programa:">
              {send.programManager.name}
            </SubtitleData>
            <SimpleSelect
              name="paralel"
              label="Paralelo:"
              options={getDropdown(programs, 'versionName', 'id', 'Paralelo:')}
              onChange={onChangeRole}
            />
          </>
        ) : null}
        <RenderButton
          onClick={() => {
            changeModal(
              true,
              <div className="UserInstitution">
                <TableFilterContainer
                  param="/ProgramManager"
                  addParam={`InstitutionId=${params.iid}`}
                  header={[
                    { key: 'name', name: 'Nombre' },
                    {
                      key: 'creationDate',
                      name: 'Fecha de creación',
                      type: 'date',
                    },
                  ]}
                  boxFilters={[
                    {
                      type: 'select',
                      label: 'Ordenar por:',
                      name: 'OrderBy',
                      options: [
                        { label: 'Nombre', name: '' },
                        { label: 'Fecha de creación', name: 'created' },
                      ],
                    },
                    {
                      type: 'getSelect',
                      label: 'Categoría:',
                      name: 'CategoryId',
                      url: '/category',
                      urlKey: 'id',
                      urlLabel: 'name',
                      initial: 'Todas las categorías',
                    },
                  ]}
                  onClick={selectedProgram}
                />
              </div>,
              null,
              null,
            )
          }}
        >
          Elegir programa
        </RenderButton>
        {load ? (
          <ActionButton
            onClick={onSubmit}
            onCancel={() => changeInfo(<></>)}
            textBtn="Enviar"
            disabled={send.programManager === '' && send.paralel === ''}
          />
        ) : (
          <Loading />
        )}
      </>
    )
  }
}

export default SendCopy
