import CircleBar from "./CircleBar";
import "./style/ProgressBar.css";
import { validateArray } from "../../utils/Validation";
const ProgressBar = ({ steps, handlebtn }) => {
  return (
    <div className="ProgressBar">
      <h2>Progreso de tu pre-registro</h2>
      {validateArray(steps)
        ? steps.map((e, i) => (
            <div
              key={i}
              className="ProgressBar__step"
              onClick={() => handlebtn({ id: e.id, name: e.name })}
            >
              <CircleBar
                status={e.status}
                order={<i className={`fas fa-${e.icon}`}></i>}
                lock={true}
              />
              <div className="ProgressBar__head">
                <h3 className="ProgressBar__name">{e.name}</h3>
                <h4 className="ProgressBar__status">
                  {e.status ? "Completado" : "Incompleto"}
                </h4>
              </div>
            </div>
          ))
        : null}
    </div>
  );
};

export default ProgressBar;
