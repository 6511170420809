import "./SeminarAside.css";
import { InformationTeacher } from "../../../../components/card";
import { ButtonNavigation } from "../../../../components/buttons";
import { useState } from "react";
const teacher = {
  name: "José Rafael",
  lastName: "Pérez Gutiérrez",
  photoUrl:
    "https://res.cloudinary.com/dzxmzxvbr/image/upload/v1614798824/Jos%C3%A9_Rafael_P%C3%A9rez_Guti%C3%A9rrez_gpjcbc.png",
  profession: "Fundador y CEO de The Re-Creation Workshop",
  description: `José es Fundador y CEO de "The Re-Creation Workshop" una compañía que desarrolla
  productos de madera, especializados en muebles modulares y juegos de jardín gigantes en El
  Salvador.<br />
  Ganador de la beca"Young Leaders of the Americas Initiative" (YLAI), del Departamento de
  Estado de Estados Unidos de América, se destacó desde joven aprendiendo el idioma inglés y
  luego el idioma alemán, siendo su lengua natal el idioma español.<br />
  Su iniciativa ya desde los 17 años, le motivó a formarse en Ingeniería Industrial, en la afamada
  Escuela Especializada en Ingeniería ITCA-FEPADE de El Salvador, y mas tarde continuaría
  estudios en Ingeniería civil en la Universidad Centroamericana José Simeón Cañas (UCA)
  En base a su formación profesional, José desarrollo, Manuales de Procedimientos referentes a
  la Seguridad Industrial y de Salud Ocupacional en diferentes empresas de El Salvador
  Se destaca actualmente como Consultor en Liderazgo y mejora personal, basada en el modelo
  de Network Marketing.<br />
  Coordinador Académico del Departamento de inglés para los alumnos del Internado en FESA
  (Fundación Educando a un salvadoreño) y Capacitador de Maestros en Pedagogía, en el Marco
  del programa COMPITE, del Plan Nacional 2021, para el Ministerio de Educación. (2008 - 2009)<br />
  El Salvador<br />
  Es también Capacitador de jóvenes en el examen SAT, para optar a una beca en universidades
  de E.E.U.U. y Capacitador en el programa Young Leaders of the Americas Initiative – YLAI`,
};
const SeminarAside = () => {
  const [btns, setbtns] = useState({
    btn: [
      { id: 1, name: "Información" },
      { id: 2, name: "Docente" },
      { id: 3, name: "Fecha" },
    ],
    activate: {
      id: 2,
    },
  });
  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }));
  }
  return (
    <div className="SeminarAside">
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {btns.activate ? (
        <>
          {btns.activate.id === 1 && (
            <p>
              El taller es desarrollado por el experto internacional en el área
              de emprendimiento José Pérez Gutiérrez, de San Salvador, quien te
              transmitirá las claves necesarias para que puedas desarrollar tu
              negocio y emprendimiento en estos tiempos de pandemia. Únete e
              inscríbete a esta conferencia gratuita patrocinada por CECI y el
              GAMLP en el link y obtén tu certificado de participación.
            </p>
          )}
          {btns.activate.id === 2 && <InformationTeacher teacher={teacher} />}
          {btns.activate.id === 3 && (
            <p>Conferencia programada para el 12 de marzo horario 20:00</p>
          )}
        </>
      ) : null}
    </div>
  );
};

export default SeminarAside;
