import { useAxiosAuth } from '../../../../../hooks'
import { H2, H3, H4 } from '../../../../../components/text'
import {
  validateArray,
  validateEducator,
  validateInstitution,
} from '../../../../../utils/Validation'
import { urlAvatar } from '../../../../../utils/Constant'
import { convertD, dataLock } from '../../../../../utils/ConvertDate'
import { LoadCircle } from '../../../../../components/animation'
import EditorView from '../../../../../components/editorjs/EditorView'
import {
  changeInfo,
  changeLayout,
  openSnackbar,
  changeModal,
} from '../../../../../store/actions'
import { connect } from 'react-redux'
import { useState, useEffect } from 'react'
import { VideoAside } from '../../../../../containers/programs'
import { ImgDefault } from '../../../../../components/image'
import { BoxFlex, ColoredBoxes } from '../../../../../components/boxes'
import { RenderComment, FormComment } from '../../../../../containers/programs'
import './style/Forum.css'
const Forum = (props) => {
  const {
    match: { params },
    changeLayout,
    changeInfo,
    sesionUser,
  } = props
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `lesson/${params.id}`,
  })
  const isEducator =
    validateEducator(sesionUser.rols, params.cid) ||
    validateInstitution(sesionUser.institutions, 'admin').length > 0
  const isAdmin = false
  // validateInstitution(sesionUser.institutions, 'admin').length > 0
  //   ? true
  //   : false

  useEffect(() => {
    changeLayout()
  }, [changeLayout])

  useEffect(() => {
    changeInfo(<VideoAside noComments={true} params={params} />)
  }, [changeInfo, params])
  return (
    <>
      {loading ? (
        <LoadCircle />
      ) : (
        <>
          <H3 margin="5px 0">
            Fecha Limite: {convertD(response.finishDate, 'LLLL')}
          </H3>
          <BoxFlex justify={isEducator ? 'center' : 'space-between'}>
            <H2 margin="10px 0">{response.name}</H2>
            {!isEducator && (
              <ColoredBoxes
                boxes={[
                  {
                    title: response.score,
                    subTitle: 'Calificación',
                    status: response.score > 50 ? 'green' : 'red',
                  },
                  {
                    title: response.status === 'available' ? 'No' : 'Si',
                    subTitle: 'aportaste',
                    status: response.status === 'available' ? 'red' : 'green',
                  },
                ]}
              />
            )}
          </BoxFlex>
          {response.description && <EditorView data={response.description} />}
          <Participations
            forumId={response.forumId}
            isFinishDate={dataLock(response.finishDate)}
            isEducator={isEducator}
            isAdmin={isAdmin}
            {...props}
          />
        </>
      )}
    </>
  )
}

const Participations = (props) => {
  const {
    forumId,
    openSnackbar,
    sesionUser,
    isEducator,
    isAdmin,
    isFinishDate,
  } = props
  const [formView, setformView] = useState(true)
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/forum/${forumId}/mainComments`,
  })
  function handleView() {
    setformView(!formView)
  }
  return (
    <>
      {isFinishDate || isEducator || isAdmin ? null : (
        <div style={{ background: '#458e7f3e' }} className="Comment__Mcard">
          {formView ? (
            <>
              <div className="Comment__Mheader">
                <ImgDefault
                  url={
                    sesionUser?.user?.photoUrl
                      ? sesionUser.user.photoUrl
                      : urlAvatar
                  }
                  alt="avatar"
                  currentSize={{ width: 35 }}
                />
                <div className="Comment__Mborder" onClick={handleView}>
                  <H4 fontSize="1.4rem" color="var(--green)" margin="0">
                    Escribe tu aporte
                  </H4>
                </div>
              </div>
            </>
          ) : (
            <FormComment
              url={`/forum/${forumId}/mainComment`}
              reload={reload}
              handleClose={handleView}
              textBtn="Enviar aporte"
              openSnackbar={openSnackbar}
            />
          )}
        </div>
      )}
      {loading ? (
        <LoadCircle />
      ) : validateArray(response) ? (
        response
          .map((comment) => (
            <Comment
              {...props}
              dataComment={comment}
              upReload={reload}
              key={comment.participationId}
            />
          ))
          .reverse()
      ) : (
        <H3>Sé el primero en participar</H3>
      )}
    </>
  )
}
const Comment = (props) => {
  const { dataComment, forumId, openSnackbar, sesionUser, isFinishDate } = props
  const [viewForm, setviewForm] = useState(true)
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/forum/participation/${dataComment.participationId}/subComments`,
  })
  function handleViewForm() {
    setviewForm(!viewForm)
  }
  return (
    <div className="Comment">
      <div className="Comment__main">
        <RenderComment
          commentData={dataComment}
          sizeResponse={response?.length}
          reload={reload}
          myComment={dataComment.userId === sesionUser.user.id}
          main
          {...props}
        />
        {!isFinishDate && (
          <>
            {viewForm ? (
              <h5 onClick={handleViewForm} className="Comment__Manchor">
                <i className="fas fa-reply"></i> Responder
              </h5>
            ) : (
              <div className="Comment__Mcard">
                <FormComment
                  url={`/forum/${forumId}/mainParticipation/${dataComment.participationId}/subComment`}
                  reload={reload}
                  handleClose={handleViewForm}
                  textBtn="Enviar aporte"
                  openSnackbar={openSnackbar}
                />
              </div>
            )}
          </>
        )}
        {loading ? (
          <LoadCircle />
        ) : validateArray(response) ? (
          <div className="Comment__responses">
            {response
              .map((comment, index) => (
                <RenderComment
                  key={index}
                  commentData={comment}
                  reload={reload}
                  myComment={comment.userId === sesionUser.user.id}
                  {...props}
                />
              ))
              .reverse()}
          </div>
        ) : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeInfo,
  changeLayout,
  openSnackbar,
  changeModal,
})(Forum)
