import { useEffect, useRef } from 'react'
import ReactPlayer from 'react-player'

const VideoPlayer = (props) => {
  const {
    loop = true,
    onEnded,
    url,
    onProgress = () => {},
    startSecond = 0,
  } = props
  const playerRef = useRef(null)
  function validateUrl(url) {
    const splitUrl = url.split('/')
    const idUrl = splitUrl[splitUrl.length - 1]
    return `https://player.vimeo.com/video/${idUrl}?h=7d54e6bfb9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
  }
  useEffect(() => {
    if (startSecond !== 0) {
      playerRef.current.seekTo(startSecond, 'seconds')
    }
  }, [startSecond])

  return (
    <ReactPlayer
      ref={playerRef}
      className={`reactPlayerC ${props.className ? props.className : ''}`}
      url={url.includes('vimeo') ? validateUrl(url) : url}
      width={props.width}
      height={props.height}
      controls={props.controls ? true : false}
      muted={props.muted ? props.muted : false}
      playing={props.playing ? props.playing : false}
      onEnded={onEnded}
      loop={loop}
      onProgress={onProgress}
    />
  )
}

export default VideoPlayer
