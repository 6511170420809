import './style/ContentVirtualClass.css'
import { convertD, dataLock, subtractDate } from '../../utils/ConvertDate'
import { ButtonIcon } from '../buttons'
import { CardMainOptions } from '../card'
import { IconText, H2, H4 } from '../../components/text'
import EditorView from '../editorjs/EditorView'
import { sendMail } from './SendMail'
const ContentVirtualClassRol = (props) => {
  const {
    contentData,
    overt,
    ChangePublic,
    PutLesson,
    changeModal,
    openSnackbar,
    deleteLesson,
  } = props
  const {
    id,
    name,
    description,
    startDatePerUser,
    aditionalUrlResources,
  } = contentData

  const copyCurrentLink = () => {
    navigator.clipboard.writeText(aditionalUrlResources)
    openSnackbar('Enlace copiado', true, true)
  }

  const startDate = dataLock(subtractDate(startDatePerUser, 30, 'minutes'))
  const op = (
    <>
      <ButtonIcon
        content={overt ? 'Anular publicación' : 'Publicar'}
        onClick={ChangePublic}
        icon={overt ? 'eye' : 'eye-slash'}
      />
      {overt && (
        <ButtonIcon
          onClick={() => sendMail(id, changeModal, openSnackbar)}
          content="Enviar correo"
          icon="envelope"
        />
      )}
      <ButtonIcon onClick={PutLesson} content="Editar" icon="edit" />
      <ButtonIcon
        onClick={copyCurrentLink}
        content="Copiar enlace"
        icon="copy"
      />
      <ButtonIcon
        onClick={() => deleteLesson(id, name)}
        content="Eliminar lección"
        icon="trash"
        style={{ color: 'var(--red)' }}
      />
    </>
  )

  return (
    <CardMainOptions options={op}>
      <a
        className="ContentVirtualClass"
        href={startDate ? aditionalUrlResources : '#'}
        target={startDate ? '_blank' : ''}
        rel={startDate ? 'noopener noreferrer' : ''}
      >
        <div className="ContentVirtualClass__content">
          <div className="ContentHomework__head">
            <H2>{name}</H2>
            <H4
              margin="0"
              fontSize="1.6rem"
              textAlign="end"
              border="1px solid var(--gray)"
            >
              Clase virtual
            </H4>
          </div>
          <IconText icon="calendar-day">
            {convertD(startDatePerUser, 'LLLL')} (Fecha Inicio)
          </IconText>
          {description && <EditorView cutText={true} data={description} />}
        </div>
      </a>
    </CardMainOptions>
  )
}

export default ContentVirtualClassRol
