import { useState, useEffect } from 'react'
import './Enrollment.css'
import { ButtonNavigation } from '../../../../../components/buttons'
import { ProgressBar } from '../../../../../components/progress'
import {
  GraduateSignUp,
  SubmissionDoc,
  PaymentInfo,
} from '../../../../../components/form'
import {
  changeInfo,
  startSession,
  changeLayout,
} from '../../../../../store/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getAction } from '../../../../../services/action/Action'
import { PreRegisterModal } from '../../../../../components/modal'

const buttons3 = [
  {
    id: 1,
    name: 'Pre-Registro',
    icon: 'address-card',
    status: false,
  },
  {
    id: 2,
    name: 'Documentación',
    icon: 'folder-open',
    status: false,
  },
  {
    id: 3,
    name: 'Pago',
    icon: 'shopping-cart',
    status: false,
  },
]
const buttons2 = [
  {
    id: 1,
    name: 'Pre-Registro',
    icon: 'address-card',
    status: false,
  },
  {
    id: 3,
    name: 'Pago',
    icon: 'shopping-cart',
    status: false,
  },
]
const Enrollment = (props) => {
  const {
    changeInfo,
    match: {
      params: { gid, eid },
    },
    history,
    startSession,
    changeLayout,
  } = props

  const [graduate, setgraduate] = useState(null)
  const [modal, setmodal] = useState({
    status: false,
    content: 0,
    enrollment: null,
  })
  function handleModal() {
    setmodal((a) => ({ ...a, status: !a.status }))
  }
  const [progress, setprogress] = useState([])
  function handleProgress(id, status) {
    let prog = progress
    prog[id].status = status ? status : true
    setprogress([...prog])
    changeInfo(<ProgressBar handlebtn={handlebtn} steps={progress} />)
  }
  const handelSend = (user) => {
    setmodal((a) => ({ ...a, enrollment: user.preregistration.id }))
    startSession(
      { ...user.user, father: user.father },
      user.courses,
      user.institutions,
      true,
    )
    handleModal()
    changeInfo(<ProgressBar handlebtn={handlebtn} steps={buttons3} />)
  }

  useEffect(() => {
    if (eid && !window.localStorage.getItem('id')) {
      history.push({
        pathname: '/auth/login',
        state: {
          from: history.location,
        },
      })
    } else {
      if (!graduate) {
        getAction(`/Course/${gid}`)
          .then((response) => {
            if (response.status === 200 && response) {
              setgraduate(response.data)
            }
          })
          .catch((e) => console.log(e))
        changeInfo(<ProgressBar handlebtn={handlebtn} steps={progress} />)
      }
    }
  }, [graduate, gid, changeInfo, progress, eid, history])

  useEffect(() => {
    if (eid) {
      setprogress(buttons3)
      setbtns((a) => ({ ...a, btn: buttons3 }))
    } else {
      setprogress([...buttons2])
      setbtns((a) => ({ ...a, btn: buttons2 }))
    }
  }, [eid])
  useEffect(() => {
    changeLayout()
  }, [changeLayout])

  const [btns, setbtns] = useState({
    btn: [],
    activate: {
      id: 1,
      name: 'Pre-Registro',
    },
  })
  function handlebtn(btn) {
    setbtns((a) => ({
      ...a,
      activate: {
        ...btn,
      },
    }))
  }
  return (
    <div className="Enrollment">
      <ButtonNavigation btns={btns} handlebtn={handlebtn} />
      {btns.activate ? (
        <>
          {btns.activate.id === 1 && (
            <GraduateSignUp
              handelSend={handelSend}
              graduate={graduate}
              gid={gid}
              eid={eid}
              handleProgress={handleProgress}
            />
          )}
          {btns.activate.id === 2 && (
            <SubmissionDoc handleProgress={handleProgress} eid={eid} />
          )}
          {btns.activate.id === 3 && (
            <PaymentInfo gid={gid} graduate={graduate} />
          )}
        </>
      ) : null}
      {modal.status ? (
        <>
          {modal.content === 0 && (
            <PreRegisterModal
              handlebtn={handlebtn}
              handleModal={handleModal}
              handleProgress={handleProgress}
              eid={eid ? eid : modal.enrollment}
              gid={gid}
            />
          )}
        </>
      ) : null}
    </div>
  )
}

export default connect(null, { changeInfo, startSession, changeLayout })(
  withRouter(Enrollment),
)
