import { useState } from 'react'
import { GlobalForm } from './'
import { UseOnSubmit } from '../../hooks/useOnSubmit'

const FormDefault = (props) => {
  const {
    inputs = [],
    onCancel,
    method,
    url,
    openSnackbar,
    processData,
    textSuccess = 'Se guardo con éxito',
    textWrong = 'No se pudo guardar',
    textBtn = 'Guardar',
    title = '',
    data = null,
    handleReload = null,
    functionWrong = null,
    tokenAuth,
  } = props

  const [load, setload] = useState(true)
  async function handleOnSubmit(values) {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: method,
        url: url,
        data: processData ? processData(values) : values,
      },
      openSnackbar: openSnackbar,
      textSuccess: textSuccess,
      textWrong: textWrong,
      functionSuccess: handleReload ? handleReload : onCancel,
      functionWrong: functionWrong,
      tokenAuth: tokenAuth,
    })
    setload(true)
  }
  return (
    <div style={{ marginRight: '10px' }}>
      <GlobalForm
        InputsJson={inputs}
        textBtn={textBtn}
        title={title}
        data={data ? data : {}}
        load={load}
        onSubmit={handleOnSubmit}
        onCancel={onCancel}
      />
    </div>
  )
}

export default FormDefault
