import TableMain from './TableMain'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import { useState } from 'react'
import { useActionParam } from '../../hooks'
import { getAction } from '../../services/action/ActionAuthorization'
import { urlAvatar } from '../../utils/Constant'
import { validateArray, validateStatus } from '../../utils/Validation'
import { LoadCircle } from '../animation'
import { H3 } from '../text'
import { useEffect } from 'react'
import { filterByColors } from '../../utils/ProcessData'

const TableOptions = (props) => {
  const {
    header,
    handleInfo,
    buttonAdd,
    choice,
    param,
    handleReload = null,
    listOrder = [],
    headerSearch,
    listStatus,
    listProgram = {},
    setcategory0,
    category0,

    preConfig: preConfigProp,
    showItemsByPage = false,
    listRols = [],
    listInstitution = [],
    setInstitutionId,
    showPriorityfilter = false,
  } = props

  const headerSelector = generateHeader(headerSearch ? headerSearch : header)

  function generateHeader(header) {
    let aux = []
    header.forEach((element) => {
      if (!element.type) {
        aux.push(element)
      }
    })
    return aux
  }
  const [url, seturl] = useState({
    PageNumber: 1,
    OrderBy: '',
    pageSize: 10,
    rolId: null,
  })
  const [status, setstatus] = useState('')
  const [filterColor, setFilterColor] = useState({
    colorName: filterByColors[0].priority,
    priority: filterByColors[0].priority,
  })

  const [search, setsearch] = useState({
    field: headerSelector[0].search || headerSelector[0].key,
    /* rolId: headerRolSelector[0].search || headerRolSelector[0].key, */
    search: '',
  })
  function onChange(e) {
    const { name, value } = e.target
    setsearch((a) => ({ ...a, [name]: value }))
  }

  function onSearch() {
    seturl((a) => ({
      ...a,
      PageNumber: 1,
    }))
    reload()
  }
  const { dataRequest, statusRequest, headersRequest, reload } = useActionParam(
    {
      action: getAction,
      param:
        param +
        `?PageNumber=${url.PageNumber}` +
        `${url.OrderBy ? '&OrderBy=' + url.OrderBy : ''}` +
        `${
          search?.search
            ? '&ForQuery=' + search.search + '&TypeQuery=' + search.field
            : ''
        }` +
        `${status ? '&status=' + status : ''}` +
        `${url.pageSize ? '&pageSize=' + url.pageSize : ''}` +
        `${url.rolId ? '&RolId=' + url.rolId : ''}` +
        `${category0 ? '&CategoryId=' + category0 : ''}` +
        `${filterColor ? '&Priority=' + filterColor.priority : ''}`,
    },
  )
  useEffect(() => {
    if (handleReload !== null) {
      reload()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleReload])
  function preConfig(list) {
    if (preConfigProp) {
      return preConfigProp(list)
    } else {
      list.forEach((u) => {
        if (u.lastActive === '0001-01-01T00:00:00') {
          u.lastActive = ''
        }
        if (!u.photoUrl) {
          u.photoUrl = urlAvatar
        }
      })
      return list
    }
  }
  return (
    <>
      <TableHeader
        search={search}
        onChange={onChange}
        options={headerSelector}
        optionsRols={listRols}
        seturl={seturl}
        url={url.OrderBy}
        // header={header}
        buttonAdd={buttonAdd}
        choice={choice}
        onSearch={onSearch}
        listOrder={listOrder}
        reload={reload}
        listStatus={listStatus}
        setstatus={setstatus}
        status={status}
        //-------
        listProgram={listProgram}
        setcategory0={setcategory0}
        category0={category0}
        listRols={listRols}
        listInstitution={listInstitution}
        setInstitutionId={setInstitutionId}
        //Filter priority props
        showPriorityfilter={showPriorityfilter}
        filterColor={filterColor}
        setFilterColor={setFilterColor}
      />
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          <>
            <TableMain
              header={header}
              main={preConfig(dataRequest)}
              handleInfo={handleInfo}
              reload={reload}
            />
            <TableFooter
              seturl={seturl}
              url={url}
              reload={reload}
              pageInfo={headersRequest}
              amount={dataRequest.length}
              showItemsByPage={showItemsByPage}
            />
          </>
        ) : (
          <>
            <br />
            <H3>No tienes registros</H3>
          </>
        )
      ) : (
        <LoadCircle />
      )}
    </>
  )
}

export default TableOptions
