import './style/AnchorIcon.css'
import { H3 } from '../text'
import { convertD } from '../../utils/ConvertDate'
const AnchorIcon = ({ url, children, icon, background, color, dateAdded }) => {
  const styleAnchor = {
    background: `${background}`,
    color: `${color}`,
  }
  return (
    <div className="AnchorIcon">
      <a
        style={styleAnchor}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>{children}</span> <i className={`fas fa-${icon}`}></i>
      </a>
      <H3
        margin="8px 0"
        // color="var(--black)"
        fontSize="1.5rem"
        fontWeight="500"
      >
        <strong>Fecha de entrega:</strong> {convertD(dateAdded, 'lll')}
      </H3>
    </div>
  )
}

export default AnchorIcon
