import { useActionParam } from '../../../../hooks'
import { validateStatus, validateArray } from '../../../../utils/Validation'
import { getAction } from '../../../../services/action/ActionAuthorization'
import { LoadCircle } from '../../../../components/animation'
import './CourseModules.css'
import { withRouter } from 'react-router-dom'
import CardModule from '../CardModule'
const CourseModules = (props) => {
  const {
    cid,
    history,
    changeInfo,
    openSnackbar,
    changeModal,
    match: {
      params: { pid },
    },
  } = props
  const { dataRequest, statusRequest, reload } = useActionParam({
    action: getAction,
    param: `/course/${cid}/Modules`,
  })
  return (
    <div className="CourseModules">
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest.courseModulesDto) ? (
          dataRequest.courseModulesDto
            .sort((a, b) => a.orderNumber - b.orderNumber)
            .map((m) => (
              <CardModule
                cid={cid}
                pid={pid}
                key={m.id}
                module={m}
                reload={reload}
                history={history}
                changeInfo={changeInfo}
                openSnackbar={openSnackbar}
                changeModal={changeModal}
              />
            ))
        ) : (
          <h3>No hay módulos en este curso</h3>
        )
      ) : (
        <LoadCircle />
      )}
    </div>
  )
}

export default withRouter(CourseModules)
