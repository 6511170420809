import { GlobalForm } from '../../../components/form'
import { putAction } from '../../../services/action/ActionAuthorization'
import { useState } from 'react'
import { inputreallocateSell } from '../../../utils/FormParameters'
import { validateStatus } from '../../../utils/Validation'
const ReallocateSell = (props) => {
  const { onCancel, openSnackbar, user, handleReload } = props
  const [load, setload] = useState(true)
  function onSubmit(values) {
    putAction('/contact/reasign', {
      ...values,
      ReasignFromId: window.localStorage.getItem('id'),
      ContactId: user.contactId,
    })
      .then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Se guardaron los cambios', true, true)
          handleReload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
      .catch((e) => openSnackbar('No se pudo guardar', true, false))
  }
  return (
    <div>
      <GlobalForm
        InputsJson={inputreallocateSell}
        textBtn="Elegir"
        title="Reasignar contacto"
        data={{}}
        load={load}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  )
}

export default ReallocateSell
