import * as Yup from 'yup'
import { GlobalForm } from '..'
import { TableOptions } from '../../table'
import { useEffect, useState } from 'react'
import { BackIcon } from '../../buttonsIcon'
import { LoadCircle } from '../../animation'
import { useAxiosAuth } from '../../../hooks'
import {
  postEducatorCreate,
  putEducatorCreate,
} from '../../../services/action/ModuleAction'
import { InformationTeacher } from '../../card'
import { H2, H3, SubtitleData } from '../../text'
import { ActionButton, NormalButton } from '../../buttons'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { deleteAction } from '../../../services/action/ActionAuthorization'

const InputsJson = [
  {
    name: 'Name',
    initial: '',
    validate: Yup.string().required('Campo Requerido'),
    type: 'text',
    label: 'Nombres',
  },
  {
    name: 'lastName',
    initial: '',
    validate: Yup.string().required('Campo Requerido'),
    type: 'text',
    label: 'Apellidos',
  },
  {
    name: 'Profession',
    initial: '',
    validate: Yup.string().required('Campo Requerido'),
    type: 'text',
    label: 'Profesión',
  },
  {
    name: 'Description',
    initial: '',
    validate: Yup.string(),
    control: 'textarea',
    label: 'Descripción',
  },
]

const AddEducatorForm = (props) => {
  const { course, handleReload, openSnackbar, changeModal, cid, iid } = props
  const { response, loading, reload: teachReload } = useAxiosAuth({
    method: 'GET',
    url: `/course/${cid}/teachers`,
  })
  function reload() {
    teachReload()
    handleReload()
  }

  useEffect(() => {
    if (!loading) {
      teachReload()
    }
  }, [cid])

  const [state, setstate] = useState({ step: 1, user: null, edit: false })
  const [load, setload] = useState(true)
  function handelStep(data, st, edit = false) {
    setstate({
      user: data,
      step: st,
      edit,
    })
  }
  const onHandleEdit = async (values) => {
    handelStep(values, 2, true)
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      await postEducatorCreate(course.id, state.user.id, values).then(
        (response) => {
          setload(true)
          if (validateStatus(response.status)) {
            openSnackbar('Educador adicionado', true, true)
            reload()
          } else {
            openSnackbar('No se pudo adicionar', true, false)
          }
        },
      )
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  const onEdit = async (values) => {
    try {
      setload(false)
      await putEducatorCreate(state.user.id, values).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Educador adicionado', true, true)
          reload()
        } else {
          openSnackbar('No se pudo adicionar', true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  const header = [
    { key: 'firstName', name: 'Nombres' },
    { key: 'lastName', name: 'Apellidos' },
    { key: 'email', name: 'Correo' },
    { key: 'telephone', name: 'Teléfono' },
  ]
  const listOrder = [
    { key: 'created', name: 'Fecha de creación' },
    { key: 'lastName', name: 'Apellidos' },
    { key: 'lastActive', name: 'Última conexión' },
  ]
  const handleDelete = (idUser, courseId) => {
    const deleteTeacher = () => {
      deleteAction(`course/${courseId}/Educator/${idUser}`).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          openSnackbar('Se guardaron tus cambios', true, true)
          reload()
        } else {
          openSnackbar('No se pudo guardar', true, false)
        }
      })
      changeModal(false, '')
    }
    changeModal(
      true,
      '¿Estás seguro que quieres eliminar al educador?',
      <ActionButton
        textBtn={'Eliminar'}
        onClick={deleteTeacher}
        onCancel={() => changeModal(false, '')}
      />,
      false,
    )
  }
  const selectedEducator = (user) => {
    handelStep({ ...user, name: user.firstName }, 2)
    changeModal(false, <></>)
  }
  return (
    <div className="AddEducatorForm">
      <H2 textAlign="center" color="var(--gray)" textTransform="uppercase">
        Adicionar Educador
      </H2>
      <SubtitleData subtitle="Nombre del curso:">
        {course ? course.name : ''}
      </SubtitleData>
      {!loading ? (
        validateArray(response) ? (
          response.map((t, i) => (
            <InformationTeacher
              key={i}
              teacher={t}
              actions
              handleDelete={() => handleDelete(t.id, course.id)}
              handleEdit={() => onHandleEdit(t)}
            />
          ))
        ) : (
          <H3>Aún no hay docentes asignados</H3>
        )
      ) : (
        <LoadCircle />
      )}
      <br />
      {state.step === 1 && (
        <NormalButton
          type="submit"
          onClick={() => {
            changeModal(
              true,
              <div className="UserInstitution">
                <TableOptions
                  param={`/institution/${iid}/users`}
                  header={header}
                  listOrder={listOrder}
                  choice={true} 
                  handleReload={reload}
                  handleInfo={selectedEducator}
                />
              </div>,
              () => {},
            )
          }}
        >
          Buscar educador
        </NormalButton>
      )}
      {state.step === 2 && (
        <div className="AddEducatorForm__main">
          {state.user ? (
            <>
              <BackIcon
                textIcon="Cancelar"
                fontSize="1.5rem"
                onClick={() => setstate({ step: 1 })}
              />
              <SubtitleData subtitle="Correo electrónico">
                {state.user.email}
              </SubtitleData>
              <GlobalForm
                load={load}
                textBtn={
                  state.edit ? 'Editar informacion' : 'Adicionar educador'
                }
                onSubmit={state.edit ? onEdit : onSubmit}
                InputsJson={InputsJson}
                data={{
                  ...state.user,
                  Name: state.user.name || '',
                  Description: state.user?.description || '',
                  Profession: state.user?.profession || '',
                }}
              />
              <br />
              {/* <NormalButton
                width="20%"
                fontSize="1.6rem"
                onClick={() => {
                  setstate({ ...state, step: 1 })
                }}
              >
                Cancelar
              </NormalButton> */}

              {/* <SubtitleData subtitle="Nombres">
                {state.user.firstName}
              </SubtitleData>
              <SubtitleData subtitle="Apellidos">
                {state.user.lastName}
              </SubtitleData> */}
              {/* {load ? (
                <NormalButton onClick={() => onSubmit()}>
                  Adicionar Educador
                </NormalButton>
              ) : (
                <Loading />
              )} */}
            </>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default AddEducatorForm
