import React from "react";

const Section = (props) => {
  const {
    children,
    textAlign = "start",
    fontWeight = "400",
    margin = "0 0 10px",
    fontSize = "1.6rem",
    color = "var(--black)",
  } = props;

  return (
    <p
      className="Section"
      dangerouslySetInnerHTML={{
        __html: children,
      }}
      style={{
        width: "100%",
        color: color,
        // fontFamily: "'Roboto', sans-serif",
        fontSize: fontSize,
        margin: margin,
        fontWeight: fontWeight,
        textAlign: textAlign,
      }}
    ></p>
  );
};

export default Section;
