import { useState, useEffect } from "react";
const useAction = ({ action }) => {
  const [request, setRequest] = useState({
    dataRequest: null,
    statusRequest: null,
  });
  function reload() {
    setRequest({ dataRequest: null });
  }
  useEffect(() => {
    if (request.dataRequest === null) {
      action().then((response) => {
        let dataRequest;
        let statusRequest;
        if (response) {
          statusRequest = response.status;
          dataRequest = response.data;
        } else {
          statusRequest = 500;
          dataRequest = undefined;
        }
        setRequest({
          dataRequest,
          statusRequest,
        });
      });
    }
  }, [action, request]);
  return { ...request, reload };
};

export default useAction;
