import TableMain from './TableMain'
import TableHeader from './TableHeader'
import TableFooter from './TableFooter'
import { useState } from 'react'
import { useActionParam } from '../../hooks'
import { getAction } from '../../services/action/ActionAuthorization'
import { urlAvatar } from '../../utils/Constant'
import { validateArray, validateStatus } from '../../utils/Validation'
import { LoadCircle } from '../animation'
import { H3 } from '../text'

const TableDefault = (props) => {
  const {
    header,
    main,
    handleInfo,
    buttonAdd,
    choice,
    pageInfo,
    param,
    keyOrder,
    borderBottom,
  } = props
  const headerSelector = generteHeader(header)
  function generteHeader(header) {
    let aux = []
    header.forEach((element) => {
      if (!element.type) {
        aux.push(element)
      }
    })
    return aux
  }
  // const [search, setsearch] = useState({
  //   field: headerSelector[0].key,
  //   search: "",
  // });
  function onChange(e) {
    console.log(e)
  }
  function TableMainParam() {
    const [url, seturl] = useState({
      PageNumber: 1,
      OrderBy: '',
    })
    const {
      dataRequest,
      statusRequest,
      headersRequest,
      reload,
    } = useActionParam({
      action: getAction,
      param:
        param +
        `?PageNumber=${url.PageNumber}` +
        `${url.OrderBy ? '&OrderBy=$' + url.OrderBy : ''}`,
    })
    function preConfig(list) {
      list.forEach((u) => {
        if (u.lastActive === '0001-01-01T00:00:00') {
          u.lastActive = ''
        }
        if (!u.photoUrl) {
          u.photoUrl = urlAvatar
        }
      })
      return list
    }
    return (
      <div className="">
        <TableHeader
          // search={search}
          onChange={onChange}
          options={headerSelector}
          header={header}
          buttonAdd={buttonAdd}
          choice={choice}
          //listOrder={listOrder}
        />
        {validateStatus(statusRequest) ? (
          validateArray(dataRequest) ? (
            <>
              <TableMain
                header={header}
                main={preConfig(dataRequest)}
                handleInfo={handleInfo}
                keyOrder={keyOrder}
                borderBottom={borderBottom}
              />
              <TableFooter
                seturl={seturl}
                url={url}
                reload={reload}
                pageInfo={headersRequest}
                amount={dataRequest.length}
              />
            </>
          ) : (
            <>
              <br />
              <H3>No tienes registros</H3>
            </>
          )
        ) : (
          <LoadCircle />
        )}
      </div>
    )
  }
  return (
    <>
      {param ? (
        <TableMainParam />
      ) : (
        <>
          <TableHeader
            // search={search}
            onChange={onChange}
            options={headerSelector}
            buttonAdd={buttonAdd}
            choice={choice}
          />
          <TableMain
            borderBottom={borderBottom}
            keyOrder={keyOrder}
            header={header}
            main={main}
            handleInfo={handleInfo}
          />
          <TableFooter pageInfo={pageInfo} amount={main.length} />
        </>
      )}
    </>
  )
}

export default TableDefault
