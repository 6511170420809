import './style/Icon.css'
import RenderButton from './RenderButton'
const Icon = ({ handleModal, ico, children, disabled, content }) => {
  return (
    <RenderButton
      background="#4695851a"
      color="var(--green)"
      onClick={handleModal}
      margin="0 5px"
      type="button"
      content={content}
      disabled={disabled ? disabled : false}
    >
      {children} {ico && <i className={`fas fa-${ico}`}></i>}
    </RenderButton>
  )
}

export default Icon
