import { validateArray, validateStatus } from '../../../../../utils/Validation'
import { getAction } from '../../../../../services/action/ActionAuthorization'
import { UnitsAside } from '../../../../../components/unit'
import { useActionParam } from '../../../../../hooks'
const LessonModules = ({ params }) => {
  const { dataRequest, statusRequest } = useActionParam({
    action: getAction,
    param: `course/${params.cid}/LessonDetail`,
  })
  return (
    <div className="ComentContent__Mcontent">
      {validateStatus(statusRequest) &&
      validateArray(dataRequest.lessonDetail) ? (
        dataRequest.lessonDetail
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((l) => <UnitsAside dataComment={l} key={l.id} params={params} />)
      ) : (
        <h3>Aún no hay contenido en el curso</h3>
      )}
    </div>
  )
}

export default LessonModules
