import './Contents.css'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { changeLayout } from '../../store/actions'

const Contents = (props) => {
  const { changeLayout } = props
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])
  return (
    <div className="Contents">
      <div className="Contents__section"></div>
      <div className="Contents__section"></div>
    </div>
  )
}

export default connect(null, {
  changeLayout,
})(Contents)
