import styled from "styled-components";

const AnimateCircle = styled.div`
  display: inline-block;
  width: ${(props) => props.size + 10};
  height: ${(props) => props.size + 10};
  &:after {
    content: " ";
    display: block;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    margin: 8px;
    border-radius: 50%;
    border: 4px solid var(--green);
    border-color: var(--green)
      ${(props) => (props.semicircle ? "transparent" : "var(--green)")}
      var(--green) transparent;
    animation: lds-dual-ring ${(props) => props.time} linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default AnimateCircle;
