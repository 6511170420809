import { useEffect } from "react";
import { H2, H4 } from "../../components/text";
import { changeLayout } from "../../store/actions";
import { connect } from "react-redux";
const OnlyPaymentResult = ({ changeLayout }) => {
  useEffect(() => {
    changeLayout("FullScreen");
  }, []);

  return (
    <div>
      <H2 textAlign="center">Su pago se realizo con éxito</H2>
      {/* <H4>
        Para verificar que la transacción se realizo con éxito comuníquese con
        nuestros asesores en ventas
      </H4> */}
      <H4>
        Una vez realizada la transferencia enviar el comprobante de pago por
        WhatsApp al número <strong>+591 77712429</strong>
      </H4>
    </div>
  );
};

export default connect(null, {
  changeLayout,
})(OnlyPaymentResult);
