import "./UserInstitution.css";
import { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { useAxiosAuth } from "../../../hooks";
import { convertD } from "../../../utils/ConvertDate";
import { TableFilterContainer } from "../../../components/table";
import { validateArray, validateStatus } from "../../../utils/Validation";
import { postAction } from "../../../services/action/ActionAuthorization";
import { LoadCircle, Loading } from "../../../components/animation";
import { Icon, DefaultBtn } from "../../../components/buttons";
import { H2, H3, SubtitleData } from "../../../components/text";
import { ViewEditUserRols } from "../../../components/form/ViewEditUserRols";
import { gender, rolName } from "../../../utils/Constant";

const UserInstitutionAdmin = (props) => {
  const { changeInfo, changeModal, openSnackbar, iid = 1 } = props;

  const header = [
    {
      key: "firstName",
      name: "Nombres y apellidos",
      type: "preProcess",
      validate: validName,
    },
    {
      key: "directionAndPhone",
      name: "Email y teléfono",
      type: "preProcess",
      validate: validPhone,
    },
    { key: "ci", name: "C.I.", type: "text" },
    { key: "rols", name: "Roles de usuario", type: "stringArray" },
  ];

  function validPhone(data) {
    return (
      <>
        <H3 fontSize="1.4rem" fontWeight="500">
          {data.email}
        </H3>
        <H3 fontSize="1.4rem" color="">
          {data.telephone}
        </H3>
      </>
    );
  }

  function validName(data) {
    return (
      <>
        <H3 fontSize="1.4rem" fontWeight="500">
          {data.firstName || data.lastName
            ? `${data.firstName || ""} ${data.lastName || ""}`
            : "--"}
        </H3>
      </>
    );
  }

  return (
    <div className="UserInstitution">
      <TableFilterContainer
        header={header}
        param={`/UserInstitution/${iid}/users`}
        addHeader={[
          { key: "firstName", name: "Nombres y apellidos" },
          { key: "telephone", name: "Telefóno" },
          { key: "email", name: "Correo electrónico" },
        ]}
        boxFilters={[
          {
            type: "getSelect",
            label: "Rol:",
            name: "RolId",
            url: "/rol",
            urlKey: "id",
            urlLabel: "name",
            initial: "Todos los roles",
            token: true,
            translate: rolName,
          },
          {
            type: "select",
            label: "Ordenar por:",
            name: "OrderBy",
            options: [
              // { name: 'alphabetic', label: 'Orden alfabético' },
              { name: "", label: "Orden alfabético" },
              { name: "created", label: "Fecha de creación" },
              { name: "lastActive", label: "Última conexión" },
            ],
          },
        ]}
        onClick={(user, reload) =>
          changeModal(
            true,
            <ViewUser
              user={user}
              changeModal={changeModal}
              changeInfo={changeInfo}
              handleReload={reload}
              openSnackbar={openSnackbar}
              iid={iid}
              {...props}
            />,
            null,
            null
          )
        }
      />
    </div>
  );
};
export default withRouter(UserInstitutionAdmin);

function ViewUser({ user, changeModal, openSnackbar, handleReload, iid }) {
  const [edit, setedit] = useState(true);
  const { response, loading, reload } = useAxiosAuth({
    method: "GET",
    url: `course/user/${user.id}/admin`,
  });
  const {
    response: userResponse,
    loading: userLoading,
    reload: userReload,
  } = useAxiosAuth({
    method: "GET",
    url: `userinstitution/${user.id}/institution/${iid}/detail`,
  });

  function onClose() {
    setedit(!edit);
  }
  const [loadBtn, setloadBtn] = useState(true);

  function resendUser(user) {
    changeModal(
      true,
      "¿Estás seguro que quieres reenviar las credenciales del usuario?",
      "",
      "Enviar",
      () => handleResendUser(user)
    );
  }

  function handleResendUser(user) {
    setloadBtn(false);
    postAction(`/email/${user.id}/ResendCredentials`).then((response) => {
      if (response && validateStatus(response.status)) {
        openSnackbar("Se volvio a enviar el mensaje", true, true);
        changeModal(
          true,
          "Se Reenviaron las credenciales exitosamente...",
          "",
          "",
          null
        );
      } else {
        openSnackbar("No se pudo enviar el mensaje", true, false);
        changeModal(true, "No se Reenviaron las credenciales...", "", "", null);
      }
      setloadBtn(true);
    });
  }
  useEffect(() => {
    reload();
    userReload();
    setedit(true);
  }, [user.id]);

  const handleReloadUser = () => {
    reload();
    userReload();
  };
  function ListCourses({ courses, type }) {
    return (
      <div className="ListCourses">
        {validateArray(courses)
          ? courses.map((course) => (
              <Link
                className="ListCourses__item"
                to={`/program/${course.id}/academic/${user.id}/${type}`}
                key={course.id}
              >
                {course.name} - {course.versionName}
              </Link>
            ))
          : null}
      </div>
    );
  }

  return (
    <>
      {edit ? (
        <>
          <div>
            {loadBtn ? (
              <Icon ico="address-card" handleModal={() => resendUser(user)}>
                Enviar credenciales
              </Icon>
            ) : (
              <Loading />
            )}
          </div>
          <br />
          {!userLoading ? (
            <>
              <H2 textAlign="center">Información del usuario</H2>
              <SubtitleData subtitle="Foto de perfil">
                <div
                  className="imageProfile"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "10px 0",
                  }}
                >
                  <img
                    style={{
                      borderRadius: "10px",
                      width: "120px",
                      height: "120px",
                    }}
                    src={
                      userResponse.photoUrl
                        ? userResponse.photoUrl
                        : "https://res.cloudinary.com/dzxmzxvbr/image/upload/w_120,c_scale,q_auto,f_auto/v1614259401/user_h6o64n.jpg"
                    }
                    alt=""
                  />
                </div>
              </SubtitleData>

              <SubtitleData subtitle="Usuario">
                {userResponse.username}
              </SubtitleData>
              <SubtitleData subtitle="Nombres">
                {userResponse.firstName} {userResponse.lastName}
              </SubtitleData>
              <SubtitleData subtitle="Correo electrónico">
                {userResponse.email}
              </SubtitleData>
              <SubtitleData subtitle="Carnet de Identidad">
                {userResponse.ci}
              </SubtitleData>
              <SubtitleData subtitle="Teléfono">
                {userResponse.telephone !== "0" && userResponse.telephone}
              </SubtitleData>
              <SubtitleData subtitle="Genero">
                {gender[userResponse.gender]}
              </SubtitleData>
              <SubtitleData subtitle="Última conexión">
                {user.lastActive && user.lastActive !== "0001-01-01T00:00:00"
                  ? convertD(user.lastActive, "LLLL")
                  : "N/A"}
              </SubtitleData>

              <SubtitleData subtitle="Niños registrados">
                {userResponse.children && userResponse.children.length > 0 && (
                  <H3>Hijos Registrados</H3>
                )}
              </SubtitleData>
              <SubtitleData subtitle="Roles de usuario">
                {validateArray(userResponse.rols) ? (
                  userResponse.rols.map((rol) => (
                    <H3 color="var(--purple)" key={rol.id}>
                      {rolName[rol.name] || rol.name}
                    </H3>
                  ))
                ) : (
                  <H3>El usuario no tiene roles</H3>
                )}
              </SubtitleData>
            </>
          ) : null}
          <DefaultBtn
            width="100%"
            onClick={() => {
              setedit(false);
            }}
          >
            Editar Informacion del Usuario
          </DefaultBtn>
          <br />
          <H2 textAlign="center">Cursos inscritos</H2>
          {loading ? (
            <LoadCircle />
          ) : (
            <>
              <ListCourses courses={response.programesPerUser} type="Program" />
              <ListCourses courses={response.coursesPerUser} type="course" />
              {response.programesPerUser?.length === 0 &&
              response.coursesPerUser?.length === 0 ? (
                <H3 textAlign="center">No esta inscrito a ningún curso</H3>
              ) : null}
            </>
          )}
        </>
      ) : (
        <ViewEditUserRols
          iid={iid}
          onClose={onClose}
          handleReload={handleReload}
          user={{ ...user, ...userResponse }}
          changeModal={changeModal}
          setedit={setedit}
          openSnackbar={openSnackbar}
          reload={handleReloadUser}
        />
      )}
    </>
  );
}
