import HttpClient from "../HttpClient";
import axios from "axios";
const instancia = axios.create();
instancia.CancelToken = axios.CancelToken;
instancia.isCancel = axios.isCancel;

export const getCoursesAction = () => {
  return new Promise((resolve, eject) => {
    instancia
      .get("/Course")
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
export const getPreSignUp = (prid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/PreRegisterQuiz/${prid}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
export const preSignUp = (usuario) => {
  return new Promise((resolve, eject) => {
    instancia
      .post(`/PreRegisterQuiz`, usuario)
      .then((response) => {
        resolve(response);
        window.localStorage.setItem("id", response.data.user.id);
        window.localStorage.setItem("token_seguridad", response.data.token);
        console.log("then", response);
      })
      .catch((error) => {
        console.log("error", error.response);
        resolve(error.response);
      });
  });
};
export const preSignUpAuth = (usuario) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(`/PreRegisterQuiz`, usuario)
      .then((response) => {
        resolve(response);
        console.log("then", response);
      })
      .catch((error) => {
        console.log("error", error.response);
        resolve(error.response);
      });
  });
};
export const preSignUpList = () => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/PreRegisterQuiz`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log("error", error.response);
        resolve(error.response);
      });
  });
};
export const preFileUpload = (pdi, file) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(`/preregisterquiz/${pdi}/uploadFile`, file)
      .then((response) => {
        resolve(response);
        console.log("then", response);
      })
      .catch((error) => {
        console.log("error", error.response);
        resolve(error.response);
      });
  });
};
