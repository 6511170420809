import { useActionParam, useAxiosAuth } from '../../../../../hooks'
import { Loading } from '../../../../../components/animation'
import { useJitsi } from 'react-jutsu'
import { useEffect } from 'react'
import { changeLayout } from '../../../../../store/actions'
import { connect } from 'react-redux'
// import CourseNotFound from '../../../../404/CourseNotFound'
import {
  getAction,
  putAction,
} from '../../../../../services/action/ActionAuthorization'

const JitsiContent = (props) => {
  const {
    match: { params },
    changeLayout,
  } = props
  const { response, loading } = useAxiosAuth({
    method: 'POST',
    url: `/Jitsi/course/${params.cid}/lesson/${params.id}/token`,
  })
  useActionParam({
    action: getAction,
    param: `lesson/${params.id}`,
  })

  useEffect(() => {
    changeLayout('FullContent')
  }, [changeLayout])
  useEffect(() => {
    const header = document.querySelector('.Header')
    const backButton = document.querySelector('.BackButton')
    header.style.display = 'none'
    backButton.style.display = 'none'
    return () => {
      header.style.display = 'flex'
      backButton.style.display = 'block'
    }
  }, [])

  const assistJitsi = async () => {
    try {
      await putAction(
        `/Jitsi/accessHistory/${response.accessHistoryJitsiId}/lesson/${response.lessonId}/update`,
      ).then(() => {})
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    const unloadCallback = async (ev) => {
      await assistJitsi()
      ev.preventDefault()

      return (ev.returnValue = 'Estás seguro que quieres abandonar la página?')
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!loading && response !== undefined) {
    return (
      <RenderMeet response={response} {...props} assistJitsi={assistJitsi} />
    )
  } else {
    return <Loading />
  }
}

const RenderMeet = (props) => {
  const { assistJitsi } = props

  const { user } = props.sesionUser

  const { token, vpass, roomName } = props.response

  useEffect(() => {
    const unloadCallback = async (ev) => {
      ev.preventDefault()
      await assistJitsi()
      return (ev.returnValue = 'Estás seguro que quieres abandonar la página?')
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const goBack = () => {
    assistJitsi()
    window.close()
  }

  const jitsiConfig = {
    roomName: `${vpass}/${roomName}`,
    jwt: token,
    domain: '8x8.vc',
    width: '100%',
    height: '100%',
    displayName: `${user.firstName} ${user.lastName}`,
    onMeetingEnd: () => {
      goBack()
    },
    parentNode: 'jitsi-container',
  }
  const { loading, error } = useJitsi(jitsiConfig)

  return (
    <>
      {loading && <Loading />}
      {error && <p>{error}</p>}
      <div style={{ height: '100vh' }} id={jitsiConfig.parentNode} />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}

export default connect(mapStateToProps, { changeLayout })(JitsiContent)
