import { useState } from "react";
import { H2, H4 } from "../../../components/text";
import { LoadCircle } from "../../../components/animation";
import { ActionButton, ButtonIcon } from "../../../components/buttons";
import { useActionParam } from "../../../hooks";
import { validateArray, validateStatus } from "../../../utils/Validation";
import { IconOptions } from "../../../components/buttonsIcon";
import { StatementMain } from "../../../components/modal";
import {
  getAction,
  putEmptyAction,
} from "../../../services/action/ActionAuthorization";
import "./ReleaseList.css";
import FormRelease from "./FormRelease";

const ReleaseList = ({ changeInfo, openSnackbar, courses, programs }) => {
  const [view, setview] = useState({
    status: true,
    advert: null,
  });
  function handleView(status, ad) {
    setview((a) => ({
      ...a,
      status: status,
      advert: ad,
    }));
  }
  function Release({ ad }) {
    const [state, setstate] = useState(ad.public);
    const [modal, setmodal] = useState(false);
    const handleSubmit = async () => {
      try {
        await putEmptyAction(`/announce/${ad.id}/publishOrUnpublish`).then(
          (response) => {
            if (response && validateStatus(response.status)) {
              setstate(!state);
              openSnackbar(
                `Se ${state ? "publico" : "anulo la publicación"}`,
                true,
                true
              );
            } else {
              openSnackbar("No se pudo guardar", true, false);
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    };
    return (
      <>
        <div className="ReleaseList__ad" key={ad.id}>
          <h3>{ad.title ? ad.title : "N/A"}</h3>
          <IconOptions
            content={
              <>
                <ButtonIcon
                  content="Ver anuncio"
                  icon="external-link-square-alt"
                  onClick={() => setmodal(!modal)}
                />
                <ButtonIcon
                  content={state ? "Anular publicación" : "publicar"}
                  icon={state ? "eye" : "eye-slash"}
                  onClick={() => handleSubmit()}
                />
                <ButtonIcon
                  content="Editar anuncio"
                  icon="edit"
                  onClick={() =>
                    handleView(!view.status, { ...ad, public: state })
                  }
                />
              </>
            }
            noBackground={true}
            icon="ellipsis-v"
          />
        </div>
        <StatementMain modal={modal} setmodal={setmodal} content={ad} />
      </>
    );
  }
  function ListRelease() {
    const { dataRequest, statusRequest } = useActionParam({
      action: getAction,
      param: "/announce",
    });
    return (
      <div>
        <ActionButton
          onClick={() => handleView(!view.status, null)}
          onCancel={() => changeInfo(<></>)}
          textBtn="Crear anuncio"
          textCancel="Cancelar"
        />
        <br />
        <H2 textAlign="center">Lista de anuncios</H2>
        {validateStatus(statusRequest) ? (
          validateArray(dataRequest) ? (
            dataRequest.map((ad) => <Release key={ad.id} ad={ad} />)
          ) : (
            <H4>No tienes anuncios</H4>
          )
        ) : (
          <LoadCircle />
        )}
      </div>
    );
  }

  if (view.status) {
    return <ListRelease />;
  } else {
    return (
      <FormRelease
        openSnackbar={openSnackbar}
        handleView={handleView}
        view={view}
        courses={courses}
        programs={programs}
      />
    );
  }
};

export default ReleaseList;
