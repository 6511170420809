import { Formik, Form } from 'formik'
import { Loading } from '../../animation/index'
import { postAction } from '../../../services/action/Action'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { openSnackbar, startSession } from '../../../store/actions'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormikControl } from '../../input'
import { RenderButton } from '../../buttons'
import config from '../../../config.json'

import '../../../containers/account/style/EditPerfil.css'
import * as Yup from 'yup'
const Register = (props) => {
  const [load, setload] = useState(true)
  const { t } = useTranslation('Register')
  const initialValues = {
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    number: '',
  }
  const validationSchema = Yup.object({
    username: Yup.string().required(t('fieldObligatory')),
    email: Yup.string().email(t('invalidEmail')).required(t('fieldObligatory')),
    password: Yup.string()
      .required(t('fieldObligatory'))
      .min(4, t('charactersRequired')),
    password_confirmation: Yup.string()
      .required(t('fieldObligatory'))
      .min(4, t('charactersRequired'))
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
  })

  function save(res) {
    setload(true)
    if (res.status === 201) {
      props.openSnackbar('Registro Exitoso', true, true)
      const { rolsUserToReturn, userToReturn, token, father } = res.data
      window.localStorage.setItem('id', userToReturn.id)
      window.localStorage.setItem('token_seguridad', token)
      props.startSession(
        { ...userToReturn, father: father },
        rolsUserToReturn,
        [],
        true,
      )
      if (props.location.state) {
        props.history.push(props.location.state.from.pathname)
      } else {
        props.history.push('/')
      }
    } else {
      props.openSnackbar('Error al guardar', true)
    }
  }

  const onSubmit = async (values) => {
    try {
      setload(false)
      await postAction(
        '/auth/register',
        config.domainURL === 'https://utbaulavirtual.com'
          ? { ...values, institutionId: 3 }
          : values,
      ).then((response) => {
        save(response)
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  const location = props.location.pathname

  return (
    <>
      {/* <span className="form__span">{t('signIn')}</span> */}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label={t('user')}
                name="username"
              />
              <FormikControl
                control="input"
                type="email"
                label={t('email')}
                name="email"
              />
              <FormikControl
                control="input"
                type="password"
                label={t('password')}
                name="password"
              />
              <FormikControl
                control="input"
                type="password"
                label={t('confirmPassword')}
                name="password_confirmation"
              />
              {location === '/auth/test-vocacional-register' && (
                <>
                  <FormikControl
                    control="input"
                    type="text"
                    label={t('number')}
                    name="number"
                  />
                </>
              )}
              {load ? (
                <RenderButton
                  type="submit"
                  fontSize="1.8rem"
                  background="#6a308180"
                  width="100%"
                  borderRadius="10px"
                  disabled={!formik.isValid}
                >
                  {t('createAccount')}
                </RenderButton>
              ) : (
                <Loading />
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}
const mapDispatchToProps = {
  openSnackbar,
  startSession,
}
export default connect(null, mapDispatchToProps)(withRouter(Register))
