import { withRouter } from 'react-router-dom'
import './style/InformationTeacher.css'
import { urlAvatar } from '../../utils/Constant'
import { useState } from 'react'
import { processUrlImage } from '../../utils/ProcessData'
import { NormalButton } from '../buttons'
const InformationTeacher = (props) => {
  const { actions } = props
  const { name, lastName, photoUrl, profession, description } = props.teacher
  const [desc, setdesc] = useState(true)
  return (
    <div className="InformationTeacher">
      <div className="InformationTeacher__header">
        <img
          src={processUrlImage(photoUrl ? photoUrl : urlAvatar, 50)}
          alt="avatar"
        />
        <div>
          <h2>
            {name} {lastName}
          </h2>
          <h3>{profession}</h3>
        </div>
      </div>
      {description && (
        <div className="InformationTeacher__main">
          <h2>Sobre el docente:</h2>
          <h3>
            {desc ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: description.substring(0, 150) + '...',
                }}
              ></span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: description + '   ',
                }}
              ></span>
            )}
            <span
              className="InformationTeacher__span"
              onClick={() => {
                setdesc(!desc)
              }}
            >
              {desc ? 'ver más' : 'ver menos'}
            </span>
          </h3>
        </div>
      )}
      {actions && (
        <div className="InformationTeacher__actions">
          <NormalButton onClick={props.handleEdit}>
            <i className="fas fa-pen"></i>
            {'   '}
            Editar
          </NormalButton>

          <NormalButton
            onClick={() => {
              props.handleDelete()
            }}
            background="red"
          >
            <i className="fas fa-trash"></i>
            {'   '}
            Eliminar
          </NormalButton>
        </div>
      )}
    </div>
  )
}

export default withRouter(InformationTeacher)
