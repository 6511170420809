import { Formik, Form } from "formik";
import * as Yup from "yup";
import { NormalButton } from "../../../../../components/buttons";
import { Loading } from "../../../../../components/animation";
import { CardComment } from "../../../../../components/card";
import { useState, useEffect } from "react";
import { validateArray } from "../../../../../utils/Validation";
import {
  getCommentsL,
  postCommentL,
} from "../../../../../services/action/AccountCourses";
import "./style/LessonComments.css";
import { FormikControl } from "../../../../../components/input";
const initialValues = {
  CommentText: "",
};
const validationSchema = Yup.object({
  CommentText: Yup.string().required("Campo requerido"),
});

const LessonComments = (props) => {
  const { id: lid, sesionUser, openSnackbar } = props;
  const [load, setload] = useState(true);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    getCommentsL(lid, window.localStorage.getItem("id")).then((response) => {
      if (response.status === 200) {
        setRequests(response.data);
      }
    });
  }, [lid]);

  const onSubmit = async (values) => {
    try {
      setload(false);
      const val = {
        ...values,
        CommentType: "lesson",
        Valoration: 4,
        UserId: window.localStorage.getItem("id"),
        LessonId: lid,
      };
      await postCommentL(val).then((response) => {
        save(response);
      });
    } catch (e) {
      setload(true);
      console.log(e);
    }
  };
  function save(res) {
    setload(true);
    if (res.status === 200) {
      openSnackbar("Publicado", true, true);
      const { id, firstName, lastName, photoUrl, username } = sesionUser;
      const comm = {
        ...res.data,
        user: {
          id,
          uid: window.localStorage.getItem("id"),
          firstName,
          lastName,
          photoUrl,
          username,
        },
      };
      setRequests((a) => [...a, comm]);
    } else {
      openSnackbar("Error al publicar", true);
    }
  }
  return (
    <div className="LessonComments__main">
      <div className="LessonComments__Mwrite">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formik) => {
            return (
              <Form>
                <FormikControl
                  control="textarea"
                  label="Escribe aquí tu comentarios"
                  name="CommentText"
                />
                {load ? (
                  <NormalButton type="submit" disabled={!formik.isValid}>
                    Publicar
                  </NormalButton>
                ) : (
                  <Loading />
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
      {validateArray(requests) ? (
        requests
          .map(function (l, i) {
            return <CardComment dataComment={l} key={i} />;
          })
          .reverse()
      ) : (
        <h3>Aún no hay comentarios</h3>
      )}
    </div>
  );
};
export default LessonComments;
