import { useEffect, useState } from 'react'
import { useAxiosAuth } from '../../../hooks'
import { BoxFlex } from '../../../components/boxes'
import { RangeDate } from '../../../components/input'
import { H2, H3, H4 } from '../../../components/text'
import { RenderModal } from '../../../components/modal'
import { Loading } from '../../../components/animation'
import { DefaultBtn } from '../../../components/buttons'
import { convertDate } from '../../../utils/ConvertDate'
import { CircleProgress } from '../../../components/progress'
import { capitalizeFirstLetter } from '../../../utils/Validation'
const Resume = (props) => {
  const { inp, setinp, user } = props
  const [modal, setmodal] = useState(false)
  const { response, loading, reload } = useAxiosAuth({
    method: 'GET',
    url: `/contact/${window.localStorage.getItem('id')}/resume${
      inp.StartDate
        ? inp.EndDate
          ? `?Initial=${convertDate(
              inp.StartDate,
              'YYYY-MM-DDTHH:mm:ss',
            )}&Final=${convertDate(inp.EndDate, 'YYYY-MM-DDTHH:mm:ss')}`
          : `?Initial=${convertDate(inp.StartDate, 'YYYY-MM-DDTHH:mm:ss')}`
        : ''
    }`,
  })

  const {
    response: courseResponse,
    loading: courseLoad,
    reload: courseReload,
  } = useAxiosAuth({
    method: 'GET',
    url: `/goal/course?userId=${window.localStorage.getItem('id')}${
      inp.StartDate
        ? inp.EndDate
          ? `&Initial=${convertDate(
              inp.StartDate,
              'YYYY-MM-DDTHH:mm:ss',
            )}&Final=${convertDate(inp.EndDate, 'YYYY-MM-DDTHH:mm:ss')}`
          : `&Initial=${convertDate(inp.StartDate, 'YYYY-MM-DDTHH:mm:ss')}`
        : ''
    }`,
  })

  useEffect(() => {
    reload()
    courseReload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inp])

  function handleDateRange(update) {
    setinp((a) => ({
      ...a,
      StartDate: update[0],
      EndDate: update[1],
    }))
  }

  return (
    <>
      <div className="ResumeContact__rangeDate">
        <H3 margin="0" fontSize="2rem">
          Rango de consulta
        </H3>
        <RangeDate
          label=""
          dateRange={[
            inp?.StartDate ? inp.StartDate : null,
            inp?.EndDate ? inp.EndDate : null,
          ]}
          setDateRange={handleDateRange}
        />
      </div>
      <H4 fontSize="1.8rem" color="var(--purple)">
        Registros de {capitalizeFirstLetter(user.firstName)}{' '}
        {capitalizeFirstLetter(user.lastName)}
      </H4>
      {loading ? (
        <Loading />
      ) : (
        <BoxFlex alignItems="strech">
          <CardRegister
            number={response.contactCount}
            background="var(--green)"
            icon="user-plus"
            text="Contactos nuevos"
          />
          <CardRegister
            number={response.preRegisteredCount}
            background="var(--purple)"
            icon="address-book"
            text="Nuevos pregistros"
          />
          <CardRegister
            number={response.soldCount}
            background="var(--orange2)"
            text="Ventas hasta la fecha"
          />
        </BoxFlex>
      )}
      <br />
      <div className="ResumeContact__detail">
        <DefaultBtn onClick={() => setmodal(!modal)}>Ver detalles</DefaultBtn>
      </div>
      {courseLoad ? (
        <Loading />
      ) : (
        <PersonalProgress goals={courseResponse} user={user} />
      )}

      {modal && (
        <RenderModal
          closeOutside
          onClose={() => setmodal(!modal)}
          width="600px"
        >
          <SalesAdvance />
        </RenderModal>
      )}
    </>
  )
}

const SalesAdvance = () => {
  const { response, loading } = useAxiosAuth({
    method: 'GET',
    url: `/goal`,
  })
  return (
    <>
      <H2 textAlign="center" color="var(--green)">
        Avance de las ventas
      </H2>
      {loading ? <Loading /> : <TeamProgress goals={response} />}
    </>
  )
}
const CardRegister = ({ number, background, icon, text }) => {
  return (
    <div className="CardRegister" style={{ background: background }}>
      <BoxFlex justify="space-around">
        <H3 width="min-content" color="var(--white)" fontSize="2.5rem">
          {number}
        </H3>
        {icon && (
          <H3 width="min-content" fontSize="2rem" color="var(--white)">
            <i className={`fas fa-${icon}`}></i>
          </H3>
        )}
      </BoxFlex>
      <H4 textAlign="center" color="var(--white)">
        {text}
      </H4>
    </div>
  )
}
const PersonalProgress = ({ goals }) => {
  const percentage = (quantity, goalSet) => {
    return (quantity * 100) / goalSet
  }
  return (
    <div className="PersonalProgress">
      {goals.length === 0 ? (
        <H3 textAlign="center" color="var(--green)" fontSize="2rem">
          Avances de ventas de los cursos
        </H3>
      ) : (
        <H3 textAlign="center" color="var(--green)" fontSize="2rem">
          Avances de ventas de los cursos
          {/* de los meses de{' '}
            {convertDateLiteral(goals[0].sinceDate, 'MM-YYYY')} hasta{' '}
            {convertDateLiteral(goals[0].tillDate, 'MM-YYYY')} */}
        </H3>
      )}
      {goals.length > 0 ? (
        <div className="PersonalProgress__list">
          {goals.map((goal) => (
            <div className="PersonalProgress__item">
              <H3 fontWeight="500" fontSize="1.6rem" margin="5px 0">
                {goal.nameCourse}
              </H3>
              <BoxFlex>
                <div
                  style={{
                    width: '100%',
                    background: 'rgba(0, 0, 0, 0.2)',
                    height: '8px',
                    borderRadius: '20px',
                  }}
                >
                  <div
                    style={{
                      width: percentage(goal.quantity, goal.goalSet) + '%',
                      background: 'var(--green)',
                      height: '8px',
                      borderRadius: '20px',
                    }}
                  ></div>
                </div>
                <H3
                  min-width="fit-content"
                  margin="0"
                  fontWeight="500"
                  fontSize="1.4rem"
                >
                  {goal.quantity} / {goal.goalSet}
                </H3>
              </BoxFlex>
            </div>
          ))}
        </div>
      ) : (
        <H3 textAlign="center">No hay avances de ventas</H3>
      )}
    </div>
  )
}

const TeamProgress = ({ goals }) => {
  const {
    goalTeamPercentage,
    newContacts,
    saleCurrent,
    setSaleCurrent,
    setNewContacts,
    newPreregisters,
    setNewPreregisters,
    sold,
    setSold,
  } = goals

  return (
    <div className="TeamProgress">
      <H2 textAlign="center" color="var(--purple)">
        Estadísticas del equipo de ventas
      </H2>
      <BoxFlex justify="center">
        <CircleProgress
          progress={goalTeamPercentage}
          colorCircle="var(--white)"
          colorProgress="var(--green)"
          colorBackground="#ffffffe1"
          textColor="var(--purple)"
        />
        <H3>Metas del equipo de ventas</H3>
      </BoxFlex>
      <br />
      <H4>
        Ventas hasta ahora: {saleCurrent}/{setSaleCurrent}
      </H4>
      <H4>
        Nuevos contactos: {newContacts}/{setNewContacts}
      </H4>
      <H4>
        Nuevos pregistros: {newPreregisters}/{setNewPreregisters}
      </H4>
      <H4>
        Nuevos Completados : {sold}/{setSold}
      </H4>
    </div>
  )
}
export default Resume
