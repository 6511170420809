import { useTranslation } from 'react-i18next'
import './style/Search.css'
import { DefaultBtn } from '../buttons'
import { validateArray } from '../../utils/Validation'
function Search({ options, onChange, onClick }) {
  const { t } = useTranslation('header')
  return (
    <>
      <div className="searchContainer">
        <i className="fas fa-search"></i>
        <input
          type="text"
          name="search"
          className="searchContainer__input"
          placeholder={t('searchHead')}
          onChange={onChange}
        />
        <select className="Search__select" onChange={onChange} name="field">
          {validateArray(options)
            ? options.map((option) => {
                return (
                  <option key={option.key} value={option.key}>
                    {option.name}
                  </option>
                )
              })
            : null}
        </select>
      </div>
      <DefaultBtn onClick={onClick} color="var(--green)">
        Buscar <i className="fas fa-search"></i>
      </DefaultBtn>
    </>
  )
}

export default Search
