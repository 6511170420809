import "./style/ContentQuiz.css";
import { withRouter } from "react-router-dom";
import { convertD, dataLock } from "../../utils/ConvertDate";
import { useState } from "react";
import { QuizLesson } from "../../containers/programs";
import { ToolTip } from "../boxes";
import { changeModal } from "../../store/actions";
import { connect } from "react-redux";
const ContentQuiz = (props) => {
  const {
    contentData: {
      name,
      finishDatePerUser,
      startDatePerUser,
      status,
      // unlimited,
      lessonType,
    },
    contentData,
    match: {
      params: { pid, cid, mid },
    },
    history,
    children,
    className,
    changeModal,
  } = props;
  function handelQuiz(qid) {
    if (children) {
      const { courseId, moduleId } = contentData;
      history.push(
        `/program/course/${courseId}/module/${moduleId}/quiz/${qid}`
      );
    } else {
      history.push(
        `/program${
          pid ? "/" + pid : ""
        }/course/${cid}/module/${mid}/quiz/${qid}`
      );
    }
  }
  function handelAnswer() {
    if (!dataLock(finishDatePerUser)) {
      changeModal(
        true,
        "Felicidades por haber acabado el examen.",
        `Los resultados estaran disponibles a partir del ${convertD(
          finishDatePerUser,
          "LLLL"
        )}.`
      );
    } else {
      history.push(
        `/program${pid ? "/" + pid : ""}/course/${cid}/module/${mid}/answer/${
          contentData.id
        }`
      );
    }
  }
  function HandleOnClick({ children, className }) {
    const [modal, setmodal] = useState(false);
    function handelModal() {
      setmodal(!modal);
    }
    return (
      <>
        <ToolTip
          content={
            lessonType === "quizTeacher" ? "Evaluación al docente" : "Examen"
          }
        >
          <div
            onClick={() =>
              status === "available" ? handelModal() : handelAnswer()
            }
            className={className}
          >
            {children}
          </div>
        </ToolTip>
        <QuizLesson
          dataLock={dataLock}
          handelQuiz={handelQuiz}
          convertD={convertD}
          contentData={contentData}
          handelModal={handelModal}
          modal={modal}
        />
      </>
    );
  }

  if (children) {
    return <HandleOnClick className={className}>{children}</HandleOnClick>;
  } else {
    return (
      <div className="ContentQuiz__act">
        {!dataLock(startDatePerUser) && (
          <div className="ContentQuiz__block">
            <h3>Fecha de inicio: {convertD(startDatePerUser, "LLLL")}</h3>
            <i className="fas fa-lock"></i>
          </div>
        )}
        <HandleOnClick
          className={`ContentQuiz ${status ? status : "available"}`}
        >
          {/* {unlimited && (
            <h3>Fecha Limite: {convertD(finishDatePerUser, "LLLL")}</h3>
          )} */}
          <h3>Fecha Limite: {convertD(finishDatePerUser, "LLLL")}</h3>
          {lessonType !== "quizTeacher" ? (
            <>
              <h3>Tiempo limite: {contentData.timeLimit} minutos</h3>
              <h3>Nota minima de aprobación: {contentData.minimumGrade}</h3>
            </>
          ) : null}
          <h2>{name}</h2>
        </HandleOnClick>
      </div>
    );
  }
};
export default connect(null, { changeModal })(withRouter(ContentQuiz));
