import { useEffect } from 'react'
import { H3 } from '../../components/text'
import { useAxiosAuth } from '../../hooks'
import { validateStatus } from '../../utils/Validation'
import { Loading } from '../../components/animation'
import { putAction } from '../../services/action/ActionAuthorization'
import { connect } from 'react-redux'
import { changeMembership } from '../../store/actions'
const PaymentResult = (props) => {
  const { changeMembership } = props
  const params = new URLSearchParams(props.location.search)
  const { response, loading, error } = useAxiosAuth({
    method: 'GET',
    url: `/membership/user/${window.localStorage.getItem('id')}`,
  })

  useEffect(() => {
    async function onSubmit() {
      await putAction('/membership/completed', {
        Order_id: params.get('result'),
        Transaction_id: params.get('order_id'),
        UserId: window.localStorage.getItem('id'),
        MembershipId: response.membershipId,
        PaymentMethod: 'onlinePayment',
      }).then((res) => {
        if (validateStatus(res.status)) {
          changeMembership({
            id: response.membershipId,
            plan: response.plan,
            status: 'completed',
          })
          props.history.push('/catalogue/short-courses/4')
        } else {
          props.history.push('/payment')
        }
      })
    }
    if (params && validateStatus(error) && response) {
      onSubmit()
    }
  }, [params, response, error])

  return (
    <>
      <br />
      {loading ? (
        <>
          <H3>Estamos validando tu compra...</H3>
          <Loading />
        </>
      ) : (
        <H3>Encontramos tu pedido...</H3>
      )}
    </>
  )
}

export default connect(null, { changeMembership })(PaymentResult)
