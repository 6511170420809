import "./style/QuizAside.css";
import { validateArray } from "../../../../../utils/Validation";
const QuizAside = (props) => {
  const { answers } = props;
  return (
    <div className="QuizAside">
      <h3>Seleccione la respuesta correcta</h3>
      <div className="QuizAside__response">
        {validateArray(answers)
          ? answers.map((l, i) => (
              <div key={i} className="">
                {l.reply}
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default QuizAside;
