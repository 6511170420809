import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FooterComponent } from "./Home";
import { changeLayout } from "../../store/actions";
import { H3 } from "../../components/text";
import { BoxFlex } from "../../components/boxes";
import { Link } from "react-router-dom";
import "./NewHome.css";

const NewHome = (props) => {
  const { changeLayout } = props;
  const [modal, setmodal] = useState(true);

  useEffect(() => {
    changeLayout("ContentOnly");
  }, [changeLayout]);

  function clickHandler(e, element) {
    e.preventDefault();
    document
      .getElementById(element)
      .scrollIntoView({ block: "start", behavior: "smooth" });
  }

  return (
    <div className="">
    {/* {modal ? (
      <div className="NewHome__coupon">
        <i className="fas fa-times" onClick={() => setmodal(!modal)}></i>
        <Link className="NewHome__CLink" to="/shopping-cart">
          <div className="NewHome__CImg">
            <img
              src="https://res.cloudinary.com/dv4mdvthq/image/upload/v1689791187/assets/logo_2_okkya4_zuaobh_dwgf0a.png"
              alt=""
            />
          </div>
          <div className="NewHome__CHr"></div>
          <div>
            <h4 className="">
              CUPÓN
              <br />
              <span>3 X 1</span>
            </h4>
            <p>
              Con tu código promocional llévate <br /> a <span>50 Bs.</span>{" "}
              los cursos que quieras.
            </p>
          </div>
        </Link> 
      </div>
    ) : null} */}
      <div id="contents" className="NewHome__content">
        <CourseSection
          icon="fas fa-book"
          background="#6a3081ff"
          backgroundImage="https://res.cloudinary.com/pataformacgi/image/upload/v1674760868/assets/CURSOS_CORTOS_kreh1u.png"
          text={
            <>
              <h4 className="">
                CURSOS <br /> <span>CORTOS</span>
              </h4>
              <p>
                ¡Desarrolla tus habilidades sin restricciones y sin fronteras!
                Descubre un universo de conocimiento a tu alcance con nuestra
                amplia selección de más de 80 cursos en línea.
              </p>
              <Link style={{ width: "70%" }} to="/catalogue/short-courses">
                <button className="CourseSection__button">
                  NUESTROS CURSOS
                </button>
              </Link>
            </>
          }
        />
        <CourseSection
          icon="fas fa-ship"
          // icon="fas fa-shipping-fast"
          courseIsNew={true}
          backgroundImage="https://res.cloudinary.com/pataformacgi/image/upload/v1674760871/assets/TECNICO_SUPERIOR_edjwfn.png"
          background="#cf112b"
          text={
            <>
              <H3 fontSize="2.2rem" margin="0" color="white">
                ¡Últimos días de inscripción!
                {/* Este curso te brindará la oportunidad de adquirir conocimientos
              sobre la Norma OEA, la cual se enfoca en empresas del sector,
              incluyendo importadores, exportadores y servicios relacionados. */}
              </H3>
              <h4 className="">
                OPERADOR ECONÓMICO
                <br /> <span>AUTORIZADO (OEA)</span>
              </h4>
              <Link style={{ width: "70%" }} to="/intedya/oea">
                <button
                  // style={{ background: 'white', color: 'black' }}
                  className="CourseSection__button CourseSection__button--activate"
                >
                  IR AL CURSO
                </button>
              </Link>
            </>
          }
        />
        <CourseSection
          icon="fas fa-graduation-cap"
          background="#458e7fff"
          backgroundImage="https://res.cloudinary.com/pataformacgi/image/upload/v1674760871/assets/TECNICO_SUPERIOR_edjwfn.png"
          text={
            <>
              <h4 className="">
                TÉCNICO <br /> <span>SUPERIOR</span>
              </h4>
              <p>
                Nuestros cursos están diseñados para brindarte una formación
                integral y práctica que te permitirá crecer profesionalmente en
                contabilidad general y comercio internacional. No hay límites
                para lo que puedes lograr, así que únete a nosotros y expande
                tus horizontes.
              </p>
              <Link
                to="/tecnicosuperior/contabilidadgeneral"
                style={{ width: "70%" }}
              >
                <button className="CourseSection__button">
                  CONTADURÍA GENERAL
                </button>
              </Link>
              <Link
                to="/tecnicosuperior/comerciointernacional"
                style={{ width: "70%" }}
              >
                <button className="CourseSection__button">
                  COMERCIO INTERNACIONAL
                </button>
              </Link>

              <Link
                to="/catalogue/advanced-technician"
                style={{ width: "70%" }}
              >
                <button className="CourseSection__button CourseSection__button--gradient">
                  RESERVA TU CUPO
                </button>
              </Link>
            </>
          }
        />
      </div>
      <div id="contact" className="NewHome__contactContain">
        <div className="NewHome__contactMap">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3824.6664006696315!2d-68.07812600008762!3d-16.54293100001865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTbCsDMyJzM0LjYiUyA2OMKwMDQnNDEuMyJX!5e0!3m2!1ses-419!2sbo!4v1674762953455!5m2!1ses-419!2sbo"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="NewHome__contact">
          <H3 color="var(--green)" fontSize="2.5rem" fontWeight="700">
            CONTACTANOS
          </H3>
          <p>
            <strong>Dirección: </strong>
            Edif L&M, Av. Montenegro #905, San Miguel (Lado de Subway)
          </p>
          <BoxFlex>
            <a
              href={`https://wa.me/59170140353?text=Hola%20Capacita%20Global%20`}
              target="_blank"
              rel="noopener noreferrer"
              className="CourseInformation__btn green"
            >
              <i className="fab fa-whatsapp"></i> <span>WhatsApp</span>
            </a>
            <a
              href="https://www.facebook.com/CapcitaGlobal"
              target="_blank"
              rel="noopener noreferrer"
              className="CourseInformation__btn blue"
            >
              <i className="fab fa-facebook-messenger"></i>{" "}
              <span>Messenger</span>
            </a>
          </BoxFlex>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

const CourseSection = ({
  backgroundImage,
  background,
  icon,
  text,
  courseIsNew = false,
}) => {
  return (
    <div
      style={{
        backgroundImage: `url("${backgroundImage}")`,
      }}
      className="CourseSection"
    >
      <div
        style={{
          background: background,
        }}
        className={`CourseSection__back ${
          courseIsNew ? "CourseSection__back--isNew" : ""
        }`}
      >
        <div className="CourseSection__icon">
          <i className={icon}></i>
        </div>
        <br />
        <div className="CourseSection__text">{text}</div>
      </div>
    </div>
  );
};

const CourseSection2 = ({ backgroundImage, icon, paddingIcon, text }) => {
  return (
    <div
      style={{
        backgroundImage: `url("${backgroundImage}")`,
      }}
      className="CourseSection"
    >
      {/* <Link to="/catalogue/advanced-technician"> */}
      <div className="CourseSection__icon">
        <i style={{ padding: paddingIcon }} className={icon}></i>
      </div>
      {/* </Link> */}
      <div className="CourseSection__text">{text}</div>
      <Link to="/tecnicosuperior/contabilidadgeneral" className="link">
        Contaduría General
      </Link>
      <Link to="/tecnicosuperior/comerciointernacional" className="link">
        Comercio Internacional
      </Link>
      <Link to="/catalogue/advanced-technician" className="link-success">
        Reserva tu cupo sin tarjeta!
      </Link>
    </div>
  );
};

export default connect(null, {
  changeLayout,
})(NewHome);
