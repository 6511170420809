import styled from 'styled-components'
import { ToolTip } from '../boxes'

const ButtonStyle = styled.button`
  padding: 8px;
  border-radius: 5px;
  font-size: 1.6rem;
  cursor: pointer;
  background: ${(props) =>
    props.background ? props.background : 'var(--orange2)'};
  color: ${(props) => (props.color ? props.color : 'var(--white)')};
  &:hover {
    opacity: 0.8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  &:disabled {
    opacity: 0.2;
  }
`
const AnchorStyle = styled.a`
  padding: 8px;
  border-radius: 5px;
  font-size: 1.6rem;
  cursor: pointer;
  background: ${(props) =>
    props.background ? props.background : 'var(--orange2)'};
  color: ${(props) => (props.color ? props.color : 'var(--white)')};
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.2;
  }
`
const RenderButton = (props) => {
  const {
    children,
    type,
    onClick,
    disabled,
    typeBtn = 'button',
    href,
    content,
    ...rest
  } = props

  function ContentToolTip({ children }) {
    if (content) {
      return (
        <ToolTip content={content}>
          <div>{children}</div>
        </ToolTip>
      )
    }
    return children
  }

  if (typeBtn === 'button') {
    return (
      <ContentToolTip>
        <ButtonStyle
          className="RenderButton"
          disabled={disabled}
          onClick={onClick}
          type={type}
          style={{ ...rest }}
        >
          {children}
        </ButtonStyle>
      </ContentToolTip>
    )
  } else {
    return (
      <ContentToolTip>
        <div
          className="RenderButton"
          style={{ display: 'flex' }}
          onClick={onClick}
        >
          <AnchorStyle
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ ...rest }}
          >
            {children}
          </AnchorStyle>
        </div>
      </ContentToolTip>
    )
  }
}

export default RenderButton
