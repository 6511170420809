import { connect } from 'react-redux'
import { useEffect } from 'react'
import { changeLayout } from '../../../../store/actions'
import { CourseInformation } from '../../../../containers/catalogue'
const InformationData = [
  {
    id: 1,
    name: 'PARA NIÑ@S de 7 a 9 AÑOS',
    image:
      'https://res.cloudinary.com/pataformacgi/image/upload/v1655472657/assets/Group_2271_tth1xc_qnepaf.png',
    color: 'var(--purple)',
    color2: 'var(--orange2)',
    section: {
      title: '¿POR QUÉ ES IMPORTANTE APRENDER PROGRAMACIÓN?',
      figure: 'rectangle4',
      content:
        'Pretendemos alcanzar, principalmente, a niñ@s entre 7 y 14 años de edad, con el fin de derribar pre conceptos de género y animar a las niñ@s a la posibilidad de estudiar carreras técnicas.',
    },
  },
  {
    id: 2,
    name: 'PARA NIÑ@S de 10 a 14 AÑOS',
    image:
      'https://res.cloudinary.com/pataformacgi/image/upload/v1655472659/assets/Group_2287_kdt7fu_pujm5y.png',
    color: 'var(--orange2)',
    color2: 'var(--green)',
    section: {
      title: '¿POR QUÉ ES IMPORTANTE APRENDER PROGRAMACIÓN?',
      figure: 'rectangle1',
      content:
        'Pretendemos alcanzar, principalmente, a niñ@s entre 7 y 14 años de edad, con el fin de derribar pre conceptos de género y animar a las niñ@s a la posibilidad de estudiar carreras técnicas.',
    },
  },
]

const description = [
  {
    title: 'VENTAJAS',
    figure: 'rectangle2',
    content:
      '<ul><li>Desarrolla la visualización de conceptos abstractos.</li><li>Aprenden cómo aplicar las matemáticas a problemas del mundo real y formular soluciones creativas.</li><li>Fomenta la creatividad en la resolución de problemas.</li><li>Desarrollan el pensamiento analítico y lógico.</li><li>Desenvuelven nuevas habilidades.</li><li>Precio económico.</li><li>Retos progresivos.</li></ul>',
  },
  {
    title: 'CARACTERÍSTICAS',
    figure: 'rectangle2',
    content:
      '<ul><li>Cursos virtuales 100% prácticos.</li><li>Plataformas de aprendizaje interactivas.</li><li>Docentes certificados en lengua nativa.</li><li>Sólo cinco alumnos por curso.</li><li>Seguimiento personalizado.</li></ul>',
  },
  {
    title: 'DURACIÓN',
    figure: 'rectangle3',
    content:
      '<ul><li>3 meses (curso regular)</li><li>6 meses (curso extendido)</li></ul>',
  },
]
const ProgrammingInfo = ({ changeLayout, match: { params } }) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  return (
    <div className="EnglishInfo">
      <CourseInformation
        type="Programming"
        description={description}
        course={InformationData[params.id > 4 ? 0 : params.id - 1]}
      />
    </div>
  )
}

export default connect(null, { changeLayout })(ProgrammingInfo)
