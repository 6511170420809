import { useActionParam } from '../../../../../hooks'
import {
  capitalizeFirstLetter,
  validateArray,
  validateStatus,
} from '../../../../../utils/Validation'
import { LoadCircle } from '../../../../../components/animation'
import { CardComment } from '../../../../../components/card'
import {
  getAction,
  putAction,
} from '../../../../../services/action/ActionAuthorization'
import { useEffect, useState } from 'react'
import {
  CommentInput,
  InputsCheckIt,
} from '../../../../../utils/FormParameters'
import { GlobalForm } from '../../../../../components/form'
import { SubtitleData } from '../../../../../components/text'
const ListReviews = (props) => {
  const {
    homeworks,
    type,
    openSnackbar,
    reloadTable,
    score,
    changeInfo,
    isAdmin,
    sethomeWorkIdUser = () => {},
    // dataRequest,
    // statusRequest,
    // reloadReview,
  } = props
  const [load, setload] = useState(true)
  const { dataRequest, statusRequest, reload: reloadReview } = useActionParam({
    action: getAction,
    param: `/homework/${homeworks[0].id}/Reviews`,
  })

  useEffect(() => {
    reloadReview()
  }, [homeworks])

  useEffect(() => {
    if (dataRequest !== null && validateArray(dataRequest)) {
      sethomeWorkIdUser(dataRequest[0].id)
    }
  }, [dataRequest])

  function CommentReview({ review }) {
    return (
      <div className="CommentReview">
        <CardComment
          dataComment={{ ...review, commentText: review.description }}
        />
      </div>
    )
  }
  const onSubmitComment = async (values) => {
    if (values.Description) {
      setload(false)
      putAction(`/homework/${homeworks[0].id}/CalificationStudent`, {
        Description: JSON.stringify(values.Description).replace(/"/g, "'"),
      }).then((response) => {
        setload(true)
        if (response && validateStatus(response.status)) {
          openSnackbar('Educador adicionado', true, true)
          reloadReview()
        } else {
          openSnackbar('No se pudo adicionar', true, false)
        }
      })
    }
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      let body = {
        Score: values.score,
        Type: type,
      }
      if (values.Description) {
        body = {
          ...body,
          Description: JSON.stringify(values.Description).replace(/"/g, "'"),
        }
      }
      await putAction(`/homework/${homeworks[0].id}/Calification`, body).then(
        (response) => {
          setload(true)
          if (validateStatus(response.status)) {
            openSnackbar('Inicio Exitoso', true, true)
            reloadTable()
            reloadReview()
          } else {
            openSnackbar(
              response.data.title
                ? response.data.title
                : 'No se pudo completar la calificación',
              true,
              false,
            )
          }
        },
      )
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <div className="ListReviews">
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          dataRequest.map((review) =>
            review.description ? (
              <CommentReview key={review.id} review={review} />
            ) : null,
          )
        ) : null
      ) : (
        <LoadCircle />
      )}

      <>
        {type === 'teacher_review' ? (
          <>
            {isAdmin && (
              <div
                style={{
                  margin: '2rem 0',
                }}
              >
                {dataRequest && dataRequest[0] && dataRequest[0].user && (
                  <SubtitleData subtitle="Calificado por: ">
                    {capitalizeFirstLetter(dataRequest[0].user.firstName)}{' '}
                    {capitalizeFirstLetter(dataRequest[0].user.lastName)}
                  </SubtitleData>
                )}
              </div>
            )}

            <GlobalForm
              data={{
                score: score,
              }}
              load={load}
              textBtn="Calificar"
              onSubmit={onSubmit}
              InputsJson={InputsCheckIt}
              onCancel={() => changeInfo(<></>)}
            />
          </>
        ) : (
          homeworks[0].lessonUser.score !== -1 && (
            <GlobalForm
              InputsJson={CommentInput}
              textBtn="Enviar comentario"
              title=""
              data={{ Description: null }}
              load={load}
              onSubmit={onSubmitComment}
            />
          )
        )}
      </>
    </div>
  )
}

export default ListReviews
