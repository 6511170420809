import { validateArray, validateStatus } from "../../../../utils/Validation";
import { BoxFlex } from "../../../../components/boxes";
import { RenderButton } from "../../../../components/buttons";
import { putAction } from "../../../../services/action/ActionAuthorization";
import { OrderAside } from "../../../../containers/institutions/ProgramInstitution";
import { useState } from "react";
import "./ModulesContainer.css";
import ModuleContainer from "./ModuleContainer";
const ModulesContainer = ({
  mod,
  mid,
  rol,
  reload,
  openSnackbar,
  changeInfo,
  changeModal,
  type,
}) => {
  const [status, setstatus] = useState(
    mod.id.toString() === mid ? true : false
  );
  function handleOrder() {
    changeInfo(
      <OrderAside
        changeInfo={changeInfo}
        openSnackbar={openSnackbar}
        url={null}
        putUrl={`/module/${mod.id}/OrderLessons`}
        handleReload={reload}
        textTitle="lecciones"
        data={mod.lessons}
      />
    );
  }

  function askDeleting(lessonFileId) {
    changeModal(
      true,
      "¿Estas seguro que quieres eliminar este módulo?",
      "",
      "Eliminar",
      () => handleDelete(lessonFileId)
    );
  }

  async function handleDelete() {
    try {
      await putAction(`/module/${mod.id}/delete`).then((response) => {
        if (validateStatus(response.status)) {
          openSnackbar("Se elimino con éxito", true, true);
        } else {
          openSnackbar("No se pudo eliminar", true, false);
        }
        reload();
      });
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="ModuleLections">
      <BoxFlex wrap="nowrap">
        <BoxFlex width="100%" wrap="nowrap">
          <div className="Course__subTitle" onClick={() => setstatus(!status)}>
            <h3>{mod.name}</h3>
            <i className={`fas fa-chevron-${status ? "down" : "right"}`}></i>
          </div>
          {/* <ToolTip content="Ordenar lecciones">
          <div className="">
            <RenderButton onClick={handleOrder} fontSize="2rem">
              <i className={`fas fa-sort-numeric-down`}></i>
            </RenderButton>
          </div>
        </ToolTip> */}
        </BoxFlex>
        {rol ? (
          <RenderButton onClick={askDeleting}>
            <i className="fas fa-trash"></i>
          </RenderButton>
        ) : null}
      </BoxFlex>
      {status && (
        <div className="Course__main">
          {validateArray(mod.lessons) ? (
            mod.lessons
              .sort((a, b) => a.orderNumber - b.orderNumber)
              .map((lection) => (
                <ModuleContainer
                  key={lection.id}
                  type={type}
                  lection={lection}
                  rol={rol}
                  reload={reload}
                  openSnackbar={openSnackbar}
                  changeInfo={changeInfo}
                  changeModal={changeModal}
                />
              ))
          ) : (
            <h3 className="ModuleLections__h3">
              Aún no hay contenido en el curso
            </h3>
          )}
        </div>
      )}
    </div>
  );
};

export default ModulesContainer;
