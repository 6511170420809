import RenderModal from './RenderModal'
import { H2 } from '../text'
import { connect } from 'react-redux'
import { changeModal } from '../../store/actions'
import { RenderButton } from '../buttons'
import './style/GlobalModal.css'
const GlobalModal = ({ modalReducer, changeModal }) => {
  function handelModal() {
    changeModal(!modalReducer.status, '', '', '', null)
  }
  function handelButtonModal() {
    changeModal(!modalReducer.status, '', '', '', null)
    modalReducer.onClick()
  }
  if (modalReducer.status) {
    return (
      <RenderModal
        width={modalReducer.width ? modalReducer.width : 'auto'}
        minWidth="400px"
        onClose={handelModal}
      >
        {/* <div className="GlobalModal"> */}
        <H2 color="var(--gray)" textAlign="center">
          {modalReducer.title}
        </H2>
        <div className="GlobalModal__p">{modalReducer.message}</div>
        <div
          className="GlobalModal__btns"
          style={{
            textAlign: 'center',
          }}
        >
          {modalReducer.butttonText !== null && (
            <RenderButton
              background="var(--white)"
              color="var(--orange2)"
              border="1px solid var(--orange2)"
              width="40%"
              // fontSize="2rem"
              onClick={handelModal}
            >
              Cerrar
            </RenderButton>
          )}
          {modalReducer.butttonText && (
            <RenderButton
              width="40%"
              // fontSize="2rem"
              onClick={handelButtonModal}
            >
              {modalReducer.butttonText}
            </RenderButton>
          )}
        </div>
        {/* </div> */}
      </RenderModal>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    modalReducer: state.modalReducer,
  }
}
export default connect(mapStateToProps, { changeModal })(GlobalModal)
