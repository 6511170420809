import { H4 } from '../../../components/text'
import { NormalLink, DefaultBtn } from '../../../components/buttons'
import { Loading } from '../../../components/animation'
import { validateArray, validateStatus } from '../../../utils/Validation'
import { RenderModal } from '../../../components/modal'
import { postAction } from '../../../services/action/ActionAuthorization'
import { useState } from 'react'
const ContactHeader = (props) => {
  const { dataRequest = [], course, id, type, membership, history } = props
  const [modal, setmodal] = useState(false)
  const preRegistrations = dataRequest
    ? dataRequest.filter((pre) => {
        return pre.signedProgramId === parseInt(id)
      })
    : null
  const onSubmit = async () => {
    try {
      setmodal(true)
      await postAction(`/course/${course.id}/Enroll`, {}).then((response) => {
        setmodal(false)
        if (validateStatus(response.status)) {
          history.push(`/program/course/${course.id}/module/1`)
        }
      })
    } catch (e) {
      setmodal(false)
      console.log(e)
    }
  }
  return (
    <>
      {/* <H2 textAlign="center" color="var(--gray)">
        Contacto
      </H2>
      <Section>
        Para mayor información sobre nuestra promoción y planes de pago
        contáctanos
      </Section>
      <H4 fontWeight="400" textAlign="center">
        <i className="fas fa-headset"></i> <span>+591 2-2312135</span>
      </H4>
      <H4 textAlign="center" fontWeight="400">
        <i className="fab fa-whatsapp"></i> <span>+591 77712429</span>
      </H4>
      <H4 textAlign="center" fontWeight="400">
        <i className="fab fa-whatsapp"></i> <span>+591 76702369</span>
      </H4> */}
      <div className="ContactHeader__btn">
        {type && membership && membership.status === 'completed' ? (
          <DefaultBtn onClick={onSubmit}>
            Inscríbete al {type === 'course' ? 'curso' : 'diplomado'}
          </DefaultBtn>
        ) : type && membership && membership.status !== 'completed' ? null : (
          !type && (
            <NormalLink
              background="var(--purple)"
              to={`/graduates/${course.programManagerId}/enrollment${
                validateArray(preRegistrations)
                  ? '/' + preRegistrations[0].id
                  : ''
              }`}
            >
              Inscríbete al {type === 'course' ? 'curso' : 'diplomado'}
            </NormalLink>
          )
        )}
      </div>
      {modal && (
        <RenderModal onClose={() => setmodal(false)} width="300px">
          <H4 fontSize="2rem" fontWeight="600" textAlign="center">
            Gracias por inscribirte
          </H4>
          <H4 fontSize="2rem" fontWeight="500" textAlign="center">
            Estamos trabajando en su inscripción
          </H4>
          <Loading />
        </RenderModal>
      )}
    </>
  )
}
export default ContactHeader
