const H2 = (props) => {
  const {
    children,
    textAlign,
    margin,
    color,
    fontSize = '2rem',
    fontWeight,
    ...rest
  } = props
  return (
    <h2
      className="H2"
      style={{
        color: color ? color : 'var(--black)',
        fontSize: fontSize,
        fontWeight: fontWeight,
        margin: margin ? margin : '0 0 10px',
        textAlign: textAlign ? textAlign : 'start',
        ...rest,
      }}
    >
      {children}
    </h2>
  )
}

export default H2
