import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { changeInfo, changeLayout, changeModal } from '../../../store/actions'
import './Courses.css'
import { StatementMain } from '../../../components/modal'
import { CourseModule } from '../../../components/card'
import { BoxFlex } from '../../../components/boxes'
import { ModulesAside } from '../../../containers/programs'
import { validateStatus } from '../../../utils/Validation'
import { H2 } from '../../../components/text'
import { LoadCircle } from '../../../components/animation'
import { validateArray, validateInstitution } from '../../../utils/Validation'
import { withRouter } from 'react-router-dom'
import {
  getAction,
  putEmptyAction,
} from '../../../services/action/ActionAuthorization'
import { useActionParam, useAxiosAuth } from '../../../hooks'
import { SimpleInput } from '../../../components/input'
import { Icon } from '../../../components/buttons'
import ScheduleView from '../../../containers/institutions/institution/ScheduleView'
const Courses = (props) => {
  const {
    changeInfo,
    changeLayout,
    changeModal,
    match: {
      params: { id },
    },
    history,
    sesionUser: { institutions },
  } = props
  const [courseArrayList, setCourseArrayList] = useState([])
  const {
    dataRequest: modulesData,
    loading: loadingModules,
    statusRequest: modulesStatus,
  } = useActionParam({
    param: `course/PerProgram/${id}/user/${window.localStorage.getItem('id')}`,
    action: getAction,
  })
  const { response, reload } = useAxiosAuth({
    method: 'POST',
    url: '/courseSchedule/course/list',
    data: courseArrayList,
  })
  useEffect(() => {
    if (!loadingModules && modulesData != null) {
      setCourseArrayList(modulesData.map((item) => item.id))
    }
    // eslint-disable-next-line
  }, [modulesData])

  useEffect(() => {
    setCourseArrayList(modulesData)
    // eslint-disable-next-line
  }, [response])
  useEffect(() => {
    reload()
    // eslint-disable-next-line
  }, [courseArrayList])

  const { dataRequest, statusRequest } = useActionParam({
    param: `announce/Program/${id}/PerUser`,
    action: getAction,
  })
  useEffect(() => {
    if (modulesData) {
      changeInfo(<ModulesAside courseModule={modulesData} pid={id} />)
    }
    changeLayout()
  }, [changeInfo, modulesData, id, history, changeLayout])

  function validateRol() {
    const isAdmin = validateInstitution(institutions, 'admin')
    const isEducator = validateInstitution(institutions, 'educator')
    if (isAdmin.length > 0) {
      return 'admin'
    }
    if (isEducator.length > 0) {
      return 'educator'
    }
    return null
  }
  function ModalRelease({ advert }) {
    const [modal, setmodal] = useState(true)
    const [status, setstatus] = useState(false)
    const handleModal = async (mod) => {
      try {
        if (status) {
          await putEmptyAction(`/announce/${advert.id}/View`).then(
            (response) => {
              if (validateStatus(response.status)) {
                console.log(response)
              }
            },
          )
        }
        setmodal(mod)
      } catch (e) {
        console.log(e)
      }
    }
    return (
      <StatementMain modal={modal} setmodal={handleModal} content={advert}>
        <div className="">
          <SimpleInput
            name="Publish"
            type="checkbox"
            label="Dejar de ver este anuncio"
            onChange={(e) => setstatus(e.target.checked)}
            value={status}
            // err={question.inquiry === "" ? "Campo requerido" : ""}
          />
        </div>
      </StatementMain>
    )
  }
  function handleModal() {
    changeModal(
      true,
      'Horarios',
      <ScheduleView
        /* events={ResponseCourseAllSchedule}
        handleSheduleEdit={handleSheduleEdit}
        virtualClass={virtualClassResponse}
        currentEvents={response} */
        showCircles={false}
        showOnlyAllSchedules={false}
      />,
      null,
      null,
      '80%',
    )
  }
  return (
    <div className="Modules">
      <BoxFlex justify="space-between">
        <H2 margin="0">MÓDULOS</H2>
        <BoxFlex>
          <Icon
            ico="tasks"
            handleModal={() => history.push(`/program/${id}/score`)}
          >
            Mis notas
          </Icon>
        </BoxFlex>
      </BoxFlex>
      <br />
      {validateStatus(modulesStatus) ? (
        <div className="Modules__cardModules">
          {validateArray(modulesData) ? (
            modulesData
              .sort((a, b) => a.order - b.order)
              .map((l) => (
                <CourseModule
                  rol={validateRol()}
                  moduleData={l}
                  key={l.id}
                  pid={id}
                />
              ))
          ) : (
            <h3>Aún no hay módulos en el curso</h3>
          )}
        </div>
      ) : (
        <LoadCircle />
      )}
      {validateStatus(statusRequest)
        ? validateArray(dataRequest)
          ? dataRequest.map((advert) => (
              <ModalRelease key={advert.id} advert={advert} />
            ))
          : null
        : null}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sesionUser: state.sesionUser,
  }
}
export default connect(mapStateToProps, {
  changeInfo,
  changeLayout,
  changeModal,
})(withRouter(Courses))
