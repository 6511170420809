import { useState } from 'react'
import { showIcon, TableOptions } from '../../../components/table'
import { DefaultBtn } from '../../../components/buttons'
import { validateInstitution, validateArray } from '../../../utils/Validation'
import FormContact from './FormContact'
import FollowUp from './FollowUp'
import CoursesInterested from './CoursesInterested'
import ReallocateSell from './ReallocateSell'
import { H3, H4 } from '../../../components/text'
import { CirclePointer } from '../../../components/boxes'
import { setColorPoint } from '../../../utils/ProcessData'
import { capitalizeJson } from '../../../utils/ProcessData'

const ListContacts = (props) => {
  const { changeModal, sesionUser } = props
  const { institutions } = sesionUser
  const isSocialnetwork = validateInstitution(institutions, 'socialnetwork')
  const [reload, setreload] = useState(false)

  function handleFollowUp(item) {
    changeModal(
      true,
      '',
      <FollowUp
        {...props}
        user={item}
        onCancel={() => changeModal(false, null)}
      />,
      null,
    )
  }
  function handleCoursesInterested(item) {
    changeModal(
      true,
      '',
      <CoursesInterested
        {...props}
        user={item}
        handleReload={handleReload}
        onClose={() => changeModal(false, null)}
      />,
      null,
    )
  }
  function handleReallocate(item) {
    changeModal(
      true,
      '',
      <ReallocateSell
        {...props}
        user={item}
        handleReload={handleReload}
        onCancel={() => changeModal(false, null)}
      />,
      null,
    )
  }
  function handleEditContact(user) {
    changeModal(
      true,
      '',
      <FormContact
        {...props}
        user={capitalizeJson(user)}
        handleReload={handleReload}
        onClose={() => changeModal(false, null)}
      />,
      null,
    )
  }
  const actionSales = [
    {
      id: 1,
      label: 'Cursos interesados',
      action: handleCoursesInterested,
      icon: 'fas fa-tasks',
    },
    {
      id: 2,
      label: 'Editar contacto',
      action: handleEditContact,
      icon: 'fas fa-pen',
    },
  ]
  const actionSocialnetwork = [
    ...actionSales,
    {
      id: 3,
      label: 'Reasignar vendedor',
      action: handleReallocate,
      icon: 'fas fa-user-tag',
    },
  ]

  const header = [
    { key: 'nameAndPhone', name: 'Nombres y teléfono' },
    { key: 'programNames', name: 'Cursos de interés', type: 'list' },
    { key: 'creationDate', name: 'Fecha registro', type: 'date' },
    { key: 'description', name: 'Descripción' },
    { key: 'availableTimes_', name: 'Horarios disponibles' },
    validateArray(isSocialnetwork)
      ? { key: 'assignedTo', name: 'Asignado a' }
      : { key: 'ci', name: 'Carnet de identidad' },
    {
      key: 'action',
      name: 'Acciones',
      type: 'actions',
      actions: validateArray(isSocialnetwork)
        ? actionSocialnetwork
        : actionSales,
    },
  ]
  const headerSearch = [
    { key: 'phone', name: 'Teléfono' },
    { key: 'name', name: 'Nombres' },
    { key: 'email', name: 'Email' },
  ]

  const buttonAddContact = (
    <DefaultBtn
      onClick={() =>
        changeModal(
          true,
          '',
          <div style={{ paddingRight: '10px' }}>
            <FormContact
              {...props}
              onClose={() => changeModal(false, null)}
              handleReload={handleReload}
            />
          </div>,
          null,
        )
      }
    >
      Adicionar contacto{' '}
      <i style={{ fontSize: '1.4rem' }} className="fas fa-plus"></i>
    </DefaultBtn>
  )

  function handleReload() {
    changeModal(false, null)
    setreload(!reload)
  }

  function preConfig(list) {
    let aux = []

    list.forEach((l) => {
      if (l.availableTimes === null) {
        l.availableTimes = '0;0;0'
      }

      const availableTimesList = l.availableTimes.split(';')

      aux.push({
        ...l,
        availableTimes_: availableTimesList.map((a, i) => {
          l.Morning = availableTimesList[i] === 1 ? true : false
          l.Afternoon = availableTimesList[i] === 1 ? true : false
          l.Night = availableTimesList[i] === 1 ? true : false
          return showIcon(i, a)
        }),
        description:
          l.informationMedium === 'direct' ? (
            l.description === 'Matemáticas WonderMath' ? (
              <H3 fontSize="1.4rem" color="var(--green)" fontWeight="600">
                {l.description}
              </H3>
            ) : (
              <H3 fontSize="1.4rem" color="var(--green)" fontWeight="600">
                Cursos cortos
              </H3>
            )
          ) : (
            l.description
          ),
        nameAndPhone: (
          <div className="NameAndPoint">
            <div style={{ minWidth: 'fit-content' }}>
              <H4 color="var(--orange2)">
                {l.name} {l.lastname}
              </H4>
              <H3 fontSize="1.4rem" fontWeight="600">
                {l.phone}
              </H3>
            </div>
            <CirclePointer background={setColorPoint(l.priority)} />
          </div>
        ),
      })
    })
    return aux
  }

  return (
    <TableOptions
      param={`/contact/${window.localStorage.getItem('id')}/contacts`}
      header={header}
      buttonAdd={buttonAddContact}
      choice={true}
      headerSearch={headerSearch}
      handleReload={reload}
      preConfig={preConfig}
      handleInfo={
        handleFollowUp
        /* changeModal(
          true,
          '',
          <ViewUser
            {...props}
            user={user}
            handleReload={handleReload}
            rol={isSocialnetwork.length > 0 ? isSocialnetwork[0].rol : ''}
          />,
          null,
        ) */
      }
      showPriorityfilter={true}
    />
  )
}

export default ListContacts
