import {
  DefaultBtn,
  Icon,
  RenderButton,
} from '../../../../../components/buttons'
import { validateArray, validateStatus } from '../../../../../utils/Validation'
import { convertD } from '../../../../../utils/ConvertDate'
import { AnchorIcon } from '../../../../../components/anchorage'
import { ListReviews } from '../../../../../containers/programs'
import { SubtitleData, H2, H4 } from '../../../../../components/text'
import { LoadCircle } from '../../../../../components/animation'
import { FormDefault } from '../../../../../components/form'
import { useActionParam, useAxiosAuth } from '../../../../../hooks'
import ExtendDatesLesson from './ExtendDatesLesson'
import { useEffect } from 'react'
import * as Yup from 'yup'
import {
  getAction,
  putAction,
} from '../../../../../services/action/ActionAuthorization'
import { useState } from 'react'
const Homeworkcheckit = (props) => {
  const {
    user,
    reload,
    openSnackbar,
    changeInfo,
    isAdmin,
    lid,
    changeModal,
  } = props
  const { response, loading, reload: relDate } = useAxiosAuth({
    method: 'GET',
    url: `/lesson/${lid}/user/${user.userId}/datetimes`,
  })
  // const { dataRequest, statusRequest, reload: reloadReview } = useActionParam({
  //   action: getAction,
  //   param: `/homework/${user.homeworks[0].id}/Reviews`,
  // })
  useEffect(() => {
    relDate()
  }, [user.userId])

  const [homeWorkIdUser, setHomeWorkIdUser] = useState()
  function extendDates() {
    changeInfo(
      <ExtendDatesLesson
        {...props}
        response={response}
        onClose={() => changeInfo(<Homeworkcheckit {...props} />)}
      />,
    )
  }
  function processData(values) {
    let formData = new FormData()
    formData.append('File', values.File)
    formData.append('Description', values.File.name)
    formData.append('Type', 'homework')
    return formData
  }
  function handleReload() {
    reload()
    changeModal(false, null)
    changeInfo(<></>)
  }
  function handleUploadFile() {
    changeModal(
      true,
      '',
      <FormDefault
        inputs={[
          {
            name: 'File',
            control: 'file',
            initial: null,
            validate: Yup.string().required('Campo requerido').nullable(),
            label: 'Selecciona el archivo',
            type: '',
          },
        ]}
        onCancel={() => changeModal(false, null)}
        data={{}}
        method="PUT"
        url={`/lesson/${lid}/user/${user.userId}/UploadHomework`}
        openSnackbar={openSnackbar}
        handleReload={handleReload}
        processData={processData}
        title="Subir tarea"
      />,
      null,
    )
  }
  async function deleteHomework() {
    try {
      await putAction(`homework/removeScore/${homeWorkIdUser}`).then(
        (response) => {
          if (validateStatus(response.status)) {
            changeModal(false, null)
            openSnackbar('Se anulo la tarea', true, true)
            handleReload()
          } else {
            openSnackbar('No se pudo anular la tarea', true, false)
            handleReload()
          }
        },
      )
    } catch (e) {
      handleReload()
    }
  }
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <Icon ico="calendar-day" handleModal={extendDates}>
          Ampliar fechas para el usuario
        </Icon>
        {user.score !== 'Sin calificar' && (
          <>
            <DefaultBtn
              onClick={deleteHomework}
              type={'button'}
              // disabled={disabled ? disabled : false}
              // color={color}
            >
              Anular Calificación
            </DefaultBtn>
          </>
        )}
      </div>
      <br />
      <br />
      <H2 textAlign="center">Calificar tarea</H2>
      <SubtitleData subtitle="Nombre Completo:">{user.name}</SubtitleData>
      <SubtitleData subtitle="Correo electronico:">{user.email}</SubtitleData>
      {loading ? (
        <LoadCircle />
      ) : (
        <>
          <SubtitleData subtitle="Fecha de inicio:">
            {convertD(response.startDate, 'LLL')}
          </SubtitleData>
          <SubtitleData subtitle="Fecha final:">
            {convertD(response.finishDate, 'LLL')}
          </SubtitleData>
        </>
      )}
      <SubtitleData subtitle="Calificación:">{user.score}</SubtitleData>
      {!validateArray(user.homeworks) ? (
        <>
          <SubtitleData subtitle="Archivo entregado:">
            Aun no entregado
          </SubtitleData>
          <RenderButton onClick={handleUploadFile}>
            Subir tarea del estudiante
          </RenderButton>
        </>
      ) : (
        <>
          <H4 margin="5px 0" fontWeight="600">
            Archivos entregados:
          </H4>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {user.homeworks.map((file) => (
              <AnchorIcon
                key={file.id}
                background="var(--green)"
                color="var(--white)"
                url={file.file.url}
                dateAdded={file.file.dateAdded}
                icon="link"
              >
                {file.file.description}
              </AnchorIcon>
            ))}
          </div>
          <ListReviews
            score={user.score === 'Sin calificar' ? 0 : user.score}
            reloadTable={reload}
            type="teacher_review"
            homeworks={user.homeworks}
            openSnackbar={openSnackbar}
            changeInfo={changeInfo}
            isAdmin={isAdmin}
            sethomeWorkIdUser={setHomeWorkIdUser}
            // dataRequest={dataRequest}
            // statusRequest={statusRequest}
            // reloadReview={reloadReview}
          />
        </>
      )}
    </>
  )
}
export default Homeworkcheckit
