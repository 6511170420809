import {
  isAuthenticated,
  accessCourse,
  accessInstitution,
  noAuthenticate,
} from './Protected'
export function Safeguard(user, validations) {
  let sw = { bool: true, route: '/' }
  for (let i = 0; i < validations.length; i++) {
    switch (validations[i]) {
      case 'isAuthenticated':
        sw = isAuthenticated(user)
        break
      case 'accessCourse':
        sw = accessCourse(user)
        break
      case 'accessInstitution':
        sw = accessInstitution(user)
        break
      case 'notAuthenticated':
        sw = noAuthenticate(user)
        break
      default:
    }
    if (!sw) {
      i = validations.length
    }
  }
  return sw
}
