import './style/ContentHomework.css'
import { Link } from 'react-router-dom'
import { convertD } from '../../utils/ConvertDate'
import { withRouter } from 'react-router-dom'
import { ButtonIcon } from '../buttons'
import { CardMainOptions } from '../card'
import { IconText, H2, H4 } from '../../components/text'
import { filesExtension } from '../../utils/Constant'
import { sendMail } from './SendMail'
import EditorView from '../editorjs/EditorView'
const ContentHomeworkRol = (props) => {
  const {
    contentData: {
      name,
      description,
      startDatePerUser,
      finishDatePerUser,
      id,
      lessonType,
      fileExtension,
      numberFiles,
    },
    match: {
      params: { pid, cid, mid },
    },
    overt,
    ChangePublic,
    PutLesson,
    ViewFiles,
    changeModal,
    openSnackbar,
    deleteLesson,
  } = props
  const copyCurrentLink = () => {
    const url = `/program/${pid}/course/${cid}/module/${mid}/${
      lessonType === 'forum' ? 'forum' : 'home-work'
    }/${id}`
    navigator.clipboard.writeText(`${window.location.origin}${url}`)
    openSnackbar('Enlace copiado', true, true)
  }
  const op = (
    <>
      <ButtonIcon onClick={PutLesson} content="Editar" icon="edit" />
      <ButtonIcon
        content={overt ? 'Anular publicación' : 'Publicar'}
        onClick={ChangePublic}
        icon={overt ? 'eye' : 'eye-slash'}
      />
      {overt && (
        <ButtonIcon
          onClick={() => sendMail(id, changeModal, openSnackbar)}
          content="Enviar correo"
          icon="envelope"
        />
      )}
      <ButtonIcon
        onClick={ViewFiles}
        content="Materiales complementarios"
        icon="folder"
      />
      <ButtonIcon
        onClick={copyCurrentLink}
        content="Copiar enlace"
        icon="copy"
      />
      <ButtonIcon
        onClick={() => deleteLesson(id, name)}
        content="Eliminar lección"
        icon="trash"
        style={{ color: 'var(--red)' }}
      />
    </>
  )
  return (
    <CardMainOptions options={op}>
      <Link
        to={`/program/${pid}/course/${cid}/module/${mid}/${
          lessonType === 'forum' ? 'forum' : 'home-work'
        }/${id}`}
        className="ContentHomework__link"
      >
        <div className="ContentHomework">
          <div className="ContentHomework__head">
            <H2>{name}</H2>
            <H4
              margin="0"
              fontSize="1.6rem"
              textAlign="end"
              border="1px solid var(--gray)"
            >
              {lessonType === 'forum' ? 'Foro' : 'Tarea'}
            </H4>
          </div>
          <IconText icon="calendar-day">
            {convertD(startDatePerUser, 'LLLL')} (Fecha Inicio)
          </IconText>
          <IconText icon="calendar-times">
            {convertD(finishDatePerUser, 'LLLL')} (Fecha Limite)
          </IconText>
          {lessonType === 'homework' && (
            <>
              <IconText icon="calendar-times">
                {filesExtension[fileExtension] || 'Todo tipo de archivos'} (Tipo
                de archivo a entregar)
              </IconText>
              <IconText icon="calendar-times">
                {numberFiles} archivos permitidos
              </IconText>
            </>
          )}
          {description && <EditorView cutText={true} data={description} />}
        </div>
      </Link>
    </CardMainOptions>
  )
}

export default withRouter(ContentHomeworkRol)
