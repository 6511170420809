import "./style/CardComment.css";
import { urlAvatar } from "../../utils/Constant";
import { processUrlImage } from "../../utils/ProcessData";
import EditorView from "../editorjs/EditorView";
const CardComment = (props) => {
  const {
    user: { firstName, lastName, photoUrl, nickname },
    commentText,
  } = props.dataComment;
  return (
    <div className="CardComment">
      <div className="CardComment__header">
        <div className="CardComment__avatar">
          <img
            className="CardComment__img"
            src={processUrlImage(photoUrl ? photoUrl : urlAvatar, 40)}
            alt="avatar"
          />
          <h5 className="CardComment__name">
            {firstName} {lastName}
          </h5>
        </div>
        <h5 className="CardComment__rol">{nickname}</h5>
        {/* <span className="CardComment__date">{date}</span> */}
      </div>
      <div className="CardComment__main">
        <EditorView data={commentText} />
      </div>
    </div>
  );
};

export default CardComment;
