import { GlobalForm } from '../../../components/form'
import { H4 } from '../../../components/text'
import { useAction } from '../../../hooks'
import { UseOnSubmit } from '../../../hooks/useOnSubmit'
import { getProgramManager } from '../../../services/action/ProgramManagerAction'
import { useState } from 'react'
import { validateStatus, validateArray } from '../../../utils/Validation'
import { capitalizeJson } from '../../../utils/ProcessData'
import * as Yup from 'yup'
const FormEditParallel = ({ pid, program, reload, openSnackbar }) => {
  const { dataRequest, statusRequest } = useAction({
    action: getProgramManager,
  })
  function InputsJson() {
    let dropdownProgram = [{ key: 'Programa:', value: '' }]
    dataRequest.forEach((prog) => {
      dropdownProgram.push({ key: prog.name, value: prog.id })
    })
    return [
      {
        name: 'ProgramManagerId',
        initial: '',
        validate: Yup.string().required('Campo requerido'),
        control: 'select',
        label: 'Programa del paralelo',
        options: dropdownProgram,
      },
      {
        name: 'VersionName',
        initial: '',
        validate: Yup.string().required('Campo requerido'),
        type: 'text',
        label: 'Nombre de la versión',
      },
      {
        name: 'IsRoot',
        initial: false,
        validate: Yup.boolean().required('Campo requerido'),
        type: 'checkbox',
        label: 'Version Principal',
      },
    ]
  }
  const [load, setload] = useState(true)
  const onSubmit = async (values) => {
    setload(false)
    await UseOnSubmit({
      axiosParams: {
        method: 'PUT',
        url: `/program/${pid}`,
        data: {
          ...capitalizeJson(program),
          LanguageCode: program.language?.code,
          ...values,
        },
      },
      openSnackbar: openSnackbar,
      textSuccess: 'Se editó la programa',
      textWrong: 'No se pudo editar',
      functionSuccess: reload,
      process: true,
    })
    setload(true)
  }
  return (
    <div>
      {validateStatus(statusRequest) ? (
        validateArray(dataRequest) ? (
          <GlobalForm
            InputsJson={InputsJson()}
            title="Editar paralelo"
            textBtn="Editar"
            data={{
              ...program,
              VersionName: program.versionName,
              IsRoot: program.isRoot,
              ProgramManagerId: program.programManager
                ? program.programManager.id
                : null,
            }}
            onSubmit={onSubmit}
            load={load}
          />
        ) : (
          <H4>No tienes programas</H4>
        )
      ) : (
        <H4>Los programas no están disponibles</H4>
      )}
    </div>
  )
}

export default FormEditParallel
