import styled from 'styled-components'
const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  .RenderModal__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 10px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    color: var(--white);
    font-size: 1.8rem;
    background: #c93f3fa0;
    border-radius: 5px;
    margin: 5px;
  }
  .RenderModal__container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    max-width:calc(100vw - 10px);
  }
  .RenderModal__main {
    position: absolute;
    z-index: 1;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    background: ${(props) => props.background};
    padding: 10px 20px;
    border-radius: 10px;
    overflow-y: auto;
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
  }
  .RenderModal__main::-webkit-scrollbar {
    width: 1rem;
  }
  .RenderModal__main::-webkit-scrollbar-track {
    margin: 10px 0;
  }
  .RenderModal__main::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 25px;
  }
  .RenderModal__close {
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: 0;
  }
  .RenderModal__close-button:hover {
    background-color: var(--red);
  }
  @media (max-width: ${(props) => props.width}) {
    .RenderModal__main {
      width: calc(100% - 20px);
      max-height: calc(100vh - 10px);
      min-width: auto;
    }
    .RenderModal__close-button {
      border: 1px solid var(--black);
    }
  }
`
const DefaultRenderModal = (props) => {
  const {
    onClose,
    children,
    minWidth,
    maxWidht,
    width = '900px',
    closeOutside = false,
    background = 'var(--white)',
  } = props

  return (
    <ModalStyle background={background} width={width} minWidth={minWidth}>
      <div className="RenderModal__container">
        {closeOutside && (
          <div className="RenderModal__close" onClick={onClose}></div>
        )}
        <div className="RenderModal__main">
          <button className="RenderModal__close-button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
          {children}
        </div>
      </div>
    </ModalStyle>
  )
}

export default DefaultRenderModal
