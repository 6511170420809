import React, { useState } from "react";
import "./style/CardPunctuation.css";
const CardPunctuation = (props) => {
  const [description, setdescription] = useState(true);
  return (
    <div className="CardPunctuation">
      <div className="CardPunctuation__header">
        <img
          className="CardPunctuation__Havatar"
          src={props.img}
          alt="avatar"
        />
        <div className="CardPunctuation__Hcontent">
          <h4 className="CardPunctuation__Hname">{props.name}</h4>
          <div className="CardPunctuation__Hicons">
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="far fa-star"></i>
          </div>
        </div>
      </div>
      <br />
      <span className="CardPunctuation__main">
        {description
          ? props.opinion.substring(0, 50) + "..."
          : props.opinion + "   "}
      </span>
      <span
        className="CardPunctuation__span"
        onClick={() => {
          setdescription(!description);
        }}
      >
        {description ? "ver más" : "ver menos"}
      </span>
    </div>
  );
};

export default CardPunctuation;
