import React from 'react'
import { connect } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
const Theme = (props) => {
  const { themeReducer } = props
  return (
    <div>
      <ThemeStyle color={themeReducer} />
      {props.children}
    </div>
  )
}

const ThemeStyle = createGlobalStyle`
    :root {
        --white: ${(props) => props.color.white};
        --white2: ${(props) => props.color.white2};
        --orange1: ${(props) => props.color.orange1};
        --orange2: ${(props) => props.color.orange2};
        --yellow: ${(props) => props.color.yellow};
        --green: ${(props) => props.color.green};
        --purple: ${(props) => props.color.purple};
        --black: ${(props) => props.color.black};
        --gray: ${(props) => props.color.gray};
        --red: ${(props) => props.color.red};
    }
`

const mapStateToProps = (state) => {
  return {
    themeReducer: state.themeReducer,
  }
}
export default connect(mapStateToProps, null)(Theme)
