import { useState } from 'react'
import { convertD } from '../../../../utils/ConvertDate'
import { inputEditSchedule } from '../../../../utils/FormParameters'
import { GlobalForm } from '../../../../components/form'
import { validateStatus } from '../../../../utils/Validation'
import { putAction } from '../../../../services/action/ActionAuthorization'
const ScheduleEdit = ({ item, openSnackbar, changeModal, realoadAll }) => {
  const [load, setload] = useState(true)

  function getFormattedDate(dateConvert) {
    const getHoursAndMinutes = dateConvert.split(':')
    var date = new Date()
    date.setHours(getHoursAndMinutes[0], getHoursAndMinutes[1], 0)
    return date
  }
  const data = {
    StartClass: getFormattedDate(item.startClass),
    FinishClass: getFormattedDate(item.finishClass),
    ClassDayDate: new Date(item.classDayDate),
  }
  const onSubmit = async (values) => {
    try {
      setload(false)
      await putAction(`courseSchedule/${item.id}/update`, {
        ...values,
        StartClass: convertD(values.StartClass, 'HH:mm'),
        FinishClass: convertD(values.FinishClass, 'HH:mm'),
      }).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Editado correctamente', true, true)
          realoadAll()
          changeModal(false, '')
        } else {
          openSnackbar(response.data, true, false)
        }
      })
    } catch (e) {
      setload(true)
      console.log(e)
    }
  }
  return (
    <GlobalForm
      load={load}
      textBtn="Editar"
      onSubmit={onSubmit}
      InputsJson={inputEditSchedule}
      data={data}
      widthBtn="100%"
      onCancel={() => changeModal(false, '')}
    />
  )
}

export default ScheduleEdit
