import HttpClient from "../HttpClient";
export const getSearchUser = (email) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/users/${email}/search`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const getProgramsInstitution = (iid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/institution/${iid}/programs`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const getCoursesProgram = (iid, pid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/institution/${iid}/program/${pid}/courses`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};

export const postProgramEnroll = (eid) => {
  return new Promise((resolve, eject) => {
    HttpClient.postEmpty(`/PreRegisterQuiz/${eid}/enroll`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const getProgram = (pid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/program/${pid}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const getProgramUsers = (pid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/program/${pid}/users`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
export const getInstitutionUsers = (iid) => {
  return new Promise((resolve, eject) => {
    HttpClient.get(`/institution/${iid}/users`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};

export const newUserProgram = (pid, user) => {
  return new Promise((resolve, eject) => {
    HttpClient.post(`/course/${pid}/EnrollUser`, user)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.log(error.response);
        resolve(error.response);
      });
  });
};
