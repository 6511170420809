import { useState, useEffect } from 'react'
import { GlobalForm } from '../../../components/form'
import { validateStatus } from '../../../utils/Validation'
import {
  InputsAddContact,
  InputsEditContact,
} from '../../../utils/FormParameters'
import {
  postAction,
  putAction,
} from '../../../services/action/ActionAuthorization'
import { ActionButton } from '../../../components/buttons'
const FormContact = (props) => {
  const { openSnackbar, handleReload, user, onClose, changeModal } = props

  const [load, setload] = useState(true)
  const [errorField, setErrorField] = useState('Field')
  const element = document.getElementById(errorField)

  useEffect(() => {
    if (element) {
      element.style.border = '2px solid var(--red)'
    }
    return () => {
      if (element) {
        element.style.border = '1px solid #b1b1b1'
      }
    }
  }, [errorField, element])

  const assignContactToMyself = (response) => {
    putAction(`/contact/reasign`, {
      ContactId: response.contactId,
      ReasignFromId: parseInt(response.sellerUserId),
      ReasignToId: parseInt(window.localStorage.getItem('id')),
    }).then((response) => {
      if (validateStatus(response.status)) {
        openSnackbar('Contacto reasignado correctamente', true, true)
        handleReload()
      } else {
        openSnackbar(errorMessage(response.data), true, false)
      }
    })
  }
  function errorMessage(error, response) {
    switch (error) {
      case 'phone_or_email_required':
        setErrorField('Email')
        return `El correo electrónico o teléfono es requerido`
      case 'contact_email_exist':
        setErrorField('Email')
        return `EL correo electrónico ya fue registrado a otro contacto`
      case 'contact_phone_exist':
        setErrorField('Phone')
        return `EL teléfono ya fue registrado a otro contacto`

      case 'contact_phone_exist_with_active_seller':
        setErrorField('Phone')
        if (!response.activeSeller) {
          changeModal(
            true,
            'Este contacto esta asignado a un vendedor inactivo ¿Deseas asignarte este contactos?',
            <ActionButton
              textCancel="cancelar"
              textBtn="Asignar"
              onClick={() => assignContactToMyself(response)}
            />,
            null,
          )
        }
        return `EL teléfono ya fue registrado a otro contacto`
      default:
        return 'Hubo un error al registrar el contacto'
    }
  }

  const onSubmit = async (values, action) => {
    const changeType = (state) => {
      return state ? 1 : 0
    }
    const AvailableTimes = `${changeType(values.Morning)};${changeType(
      values.Afternoon,
    )};${changeType(values.Night)}`

    try {
      setload(false)
      await action(user ? `/contact/${user.ContactId}` : '/contact', {
        ...values,

        AvailableTimes,
        /* Priority: values.Priority ? values.Priority : 'no definidos', */
      }).then((response) => {
        setload(true)
        if (validateStatus(response.status)) {
          openSnackbar('Se guardo el contacto', true, true)
          handleReload()
        } else {
          openSnackbar(
            errorMessage(
              response.data.errorMessage
                ? response.data.errorMessage
                : response.data,
              response.data,
            ),
            true,
            false,
          )
        }
      })
    } catch (e) {
      console.log(e)
      setload(true)
    }
  }

  return (
    <GlobalForm
      InputsJson={user ? InputsEditContact : InputsAddContact}
      textBtn={user ? 'Editar' : 'Adicionar'}
      title={user ? 'Editar contacto' : 'Adicionar contacto'}
      data={user ? user : {}}
      onSubmit={(values) => onSubmit(values, user ? putAction : postAction)}
      load={load}
      onCancel={onClose}
    />
  )
}
export default FormContact
