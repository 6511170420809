import { withRouter } from 'react-router-dom'
import { CourseSignUp } from '../../../../components/form'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { changeInfo, startSession } from '../../../../store/actions'
import { SeminarAside } from '../../../../containers/catalogue'
import { CompModal } from '../../../../components/modal'
import { DefaultBtn } from '../../../../components/buttons'
import './Seminar.css'
const Seminar = ({ match, changeInfo, startSession, history }) => {
  const [modal, setmodal] = useState(true)
  // const [seminar, setseminar] = useState({
  //   course: null,
  //   module: null,
  // });
  const userId = window.localStorage.getItem('id')
  useEffect(() => {
    changeInfo(<SeminarAside />)
  }, [changeInfo])
  function handelSend(data) {
    // const { courseFirstModule, id } = data;
    if (!userId) {
      const { institutions, courses, user, father } = data.inscriptionProcess
      window.localStorage.setItem('id', data.inscriptionProcess.user.id)
      window.localStorage.setItem(
        'token_seguridad',
        data.inscriptionProcess.token,
      )
      startSession({ ...user, father: father }, courses, institutions, true)
    }
    setmodal(!modal)
  }
  return (
    <div className="Seminar">
      <CourseSignUp id={match.params.id} handelSend={handelSend} />
      {modal && (
        <CompModal onClose={() => history.push('/')} closeOutside={false}>
          <div className="Seminar__modal">
            <h3>El seminario ha concluido</h3>
            <h4>Ya no te puedes registrar a este seminario.</h4>
            {/* <h4>
              Si desea continuar con el proceso mas tarde revise el correo donde
              se enviaron los datos de la cuenta.
            </h4> */}
            <div className="Seminar__Mbtns">
              {/* <NormalButton background={true} onClick={() => setmodal(!modal)}>
                Cerrar
              </NormalButton> */}
              <center>
                <DefaultBtn onClick={() => history.push('/')}>
                  Volver al inicio
                </DefaultBtn>
              </center>
            </div>
          </div>
        </CompModal>
      )}
    </div>
  )
}

export default connect(null, { changeInfo, startSession })(withRouter(Seminar))
