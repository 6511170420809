import { useEffect } from 'react'
import { changeLayout } from '../../../store/actions'
import { H2, H3, H4 } from '../../../components/text'
import { RenderButton } from '../../../components/buttons'
import { connect } from 'react-redux'
import './Registered.css'

const Registered = ({ changeLayout }) => {
  useEffect(() => {
    changeLayout('ContentOnly')
  }, [changeLayout])

  return (
    <div className="Registered">
      <div className="Registered__description">
        <H3 textAlign="center" fontSize="3rem" color="#fb7420">
          ¡Felicidades, tu lugar está reservado!
        </H3>
        <H4 textAlign="center" fontSize="1.8rem" color="gray">
          Ya estás registrad@ al Webinar Gratuito para que generes ingresos
          desde casa en menos de 90 minutos.
        </H4>
      </div>
      <div className="Registered__step">
        <H2 textAlign="center" fontSize="3rem" color="#fb7420">
          Pasos A Seguir Antes Del Webinar
        </H2>
        <div className="Registered__stepContainer">
          <div className="Registered__list">
            <div
              style={{
                border: '1px solid #fe6143',
                padding: '15px',
                borderRadius: '10px',
              }}
            >
              <H3 fontWeight="500" fontSize="2rem" color="gray">
                <strong># 1 Únete al grupo exclusivo de Whatsapp</strong> si
                quieres recibir el recordatorio de la clase directo a tu
                celular, da clic en el botón de aquí abajo.
              </H3>
              <RenderButton
                typeBtn="link"
                background="#fe6143"
                textDecoration="none"
                color="white"
                padding="15px 30px"
                margin="auto"
                fontWeight="700"
                fontSize="2rem"
                href="https://chat.whatsapp.com/F6bppbDYIrvBnvD5XypY6o"
              >
                ÚNETE AQUÍ AL WHATSAPP
              </RenderButton>
            </div>
            <br />
            <br />
            <H3 fontWeight="500" fontSize="2rem" color="gray">
              <strong># 2 Revisa tu correo.</strong>
              <br /> Vas a recibir un mail con el enlace de acceso al webinar al
              correo con el que te registraste. Lo recibirás dentro de los
              siguientes 15 minutos o menos, confirma que lo tengas. Ahí
              encontrarás el enlace al curso y grupo de WhatsApp.
            </H3>
            <H3 marginTop="15px" fontWeight="500" fontSize="2rem" color="gray">
              IMPORTANTE: si no ves el mail, revisa tu carpeta de SPAM, no
              deseados o promociones (sobre todo si tienes Gmail o Hotmail).
            </H3>
            <br />
            <br />
            <H3 fontWeight="500" fontSize="2rem" color="gray">
              <strong>
                # 3 Anótalo en tu agenda y pon una alarma en tu celular.
              </strong>
              <br /> No me gustaría que te olvides de nuestra cita, tengo mucha
              información preparada para ti, bloquea el día y hora que
              seleccionaste y programa una alarma en tu celular.
            </H3>
            {/* <br />
            <H3 fontWeight="500" fontSize="2rem" color="gray">
              <strong># 4 Organízate para el día de webinar.</strong>
              <br /> Como recomendación, organiza que alguien te ayude con tus
              hijos durante los 90 minutos que estaremos conectadas, para
              aprovecharlo al máximo. Puedes invitar a tu pareja para verlo
              juntos.
            </H3> */}
          </div>
          <div className="Registered__images">
            <img
              src="https://mamaenlatorre.com/wp-content/uploads/2020/03/Home-office-para-mam%C3%A1s.jpg"
              alt=""
            />
            <img
              src="https://uvn-brightspot.s3.amazonaws.com/assets/vixes/t/trabajar-home-office-en-la-playa.jpg"
              alt=""
            />
            {/* <img
              src="https://tvcrecer.com/wp-content/uploads/2015/04/Trabajo-y-lactancia-materna-compatibles_ampliacion-700x515.jpg"
              alt=""
            /> */}
          </div>
        </div>
      </div>
      <div className="Registered__footer">
        <H3 textAlign="center" fontWeight="700" fontSize="2rem" color="white">
          Si lo deseas, puedes compartir el webinar con alguien que necesite
          <br /> esta información.
        </H3>
        <H3 textAlign="center" fontWeight="700" fontSize="2rem" color="white">
          Comparte el siguiente enlace {'>> '}
          <a
            href="https://capacitaglobal.org/webinar"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.capacitaglobal.org/webinar
          </a>
        </H3>
        <br />
        <br />
        <H3 textAlign="center" fontWeight="700" fontSize="2rem" color="white">
          Gracias por ser parte y regalarte este tiempo para ti.
        </H3>
        <H2 textAlign="center" fontWeight="700" fontSize="3rem" color="white">
          Bienvenid@, ¡Te Veo En El Webinar!
        </H2>
      </div>
    </div>
  )
}

export default connect(null, {
  changeLayout,
})(Registered)
